import {
  isLatLngQueryString,
  createGeolocationFeature,
} from "utils/geolocation"
import { useEffect, useRef, useState } from "react"
import { isEmpty } from "lodash"
import { useQuery } from "./useQuery"
import {
  addDurationToDate,
  getCurrentDateISOString,
  isBeforeDate,
  calculateDateDifference,
} from "utils/date"

import {
  get as getPersistValue,
  set as setPersistValue,
} from "utils/persist_store"

const DEFAULT_STALE_TIME = 1000 * 60 * 5 // 5 minutes

const KEY_USER_LOCATION_EXPIRED_ON = "user_location_expired_on"
const KEY_USER_LOCATION_VALUE = "user_location_value"
const KEY_LAST_SCANNED_LOCATION_VALUE = "last_scanned_location_value"

const getter = (key) => JSON.parse(getPersistValue(key))
const setter = (key, value) => setPersistValue(key, JSON.stringify(value))

const updateStoreLocationToLastScannedLocation = () => {
  const storedUserLocationValue = getPersistValue(KEY_USER_LOCATION_VALUE)
  if (!isEmpty(storedUserLocationValue)) {
    setPersistValue(KEY_LAST_SCANNED_LOCATION_VALUE, storedUserLocationValue)
  }
  setPersistValue(KEY_USER_LOCATION_VALUE, null)
  setPersistValue(KEY_USER_LOCATION_EXPIRED_ON, null)
}

export const useUserLocation = (initialLocation, options = {}) => {
  const {
    onUpdate = () => {},
    staleTime = DEFAULT_STALE_TIME,
    update = true,
    enable = true,
  } = options
  const { userLocation } = useQuery()
  const [isLoading, setIsLoading] = useState(true)
  const [userLocationState, setUserLocationState] = useState(initialLocation)
  const timer = useRef(null)

  useEffect(() => {
    setUserLocationState(initialLocation)
  }, [initialLocation])

  // Handle Location local storage when there is query provided
  useEffect(() => {
    if (!enable) return
    const storedUserLocationValue = getter(KEY_USER_LOCATION_VALUE)?.value
    const storedLastScannedLocationValue = getter(
      KEY_LAST_SCANNED_LOCATION_VALUE
    )?.value
    const storedUserLocationExpiryDate = getter(KEY_USER_LOCATION_EXPIRED_ON)

    const currentDate = getCurrentDateISOString()
    const isLocalLocationExpired = !isBeforeDate(
      currentDate,
      storedUserLocationExpiryDate
    )
    const expireDate = addDurationToDate(currentDate, staleTime)
    // ถ้ามี Query String
    if (userLocation) {
      const isSameWithLocalLocationValue =
        storedUserLocationValue === userLocation

      const isSameWithLastScannedLocalValue =
        storedLastScannedLocationValue === userLocation

      if (storedUserLocationValue && isSameWithLocalLocationValue) {
        // มี local User Location อยู่แล้ว แต่ซ้ำกับ query location
        // => reset เวลาหมดอายุ
        setter(KEY_USER_LOCATION_EXPIRED_ON, expireDate)
      } else if (
        storedLastScannedLocationValue &&
        isSameWithLastScannedLocalValue
      ) {
        // ถ้ามี last scanned และซ้ำกับ query location
        // ย้ายค่า local user location ไปเป็น last scanned
        // และ Update local user location เป็นค่าใหม่จาก Query string
        setter(KEY_LAST_SCANNED_LOCATION_VALUE, {
          value: storedUserLocationValue,
        })
        setter(KEY_USER_LOCATION_VALUE, { value: userLocation })
        setter(KEY_USER_LOCATION_EXPIRED_ON, expireDate)
      } else if (storedUserLocationValue && isLocalLocationExpired) {
        // มี local User Location อยู่แล้ว แต่หมดอายุ
        // => ย้ายไปเป็น Last scanned
        setter(KEY_LAST_SCANNED_LOCATION_VALUE, {
          value: storedUserLocationValue,
        })
        setter(KEY_USER_LOCATION_VALUE, null)
        setter(KEY_USER_LOCATION_EXPIRED_ON, null)
      } else if (storedUserLocationValue) {
        setter(KEY_LAST_SCANNED_LOCATION_VALUE, {
          value: storedUserLocationValue,
        })
        setter(KEY_USER_LOCATION_VALUE, { value: userLocation })
        setter(KEY_USER_LOCATION_EXPIRED_ON, expireDate)
      } else {
        // ไม่มี local User Location แต่มี Last scanned
        // => set user location
        setter(KEY_USER_LOCATION_VALUE, { value: userLocation })
        setter(KEY_USER_LOCATION_EXPIRED_ON, expireDate)
      }
    }
  }, [userLocation, staleTime, enable])

  // Handle Location local storage
  useEffect(() => {
    if (!enable) return

    const storedUserLocationValue = getter(KEY_USER_LOCATION_VALUE)?.value
    const storedUserLocationExpiryDate = getter(KEY_USER_LOCATION_EXPIRED_ON)

    const currentDate = getCurrentDateISOString()
    const isLocalLocationExpired = !isBeforeDate(
      currentDate,
      storedUserLocationExpiryDate
    )
    // TODO: May be relocate this logic to othere place i.e. Provider or another hook
    // Handle Case มี Location อยู่แล้ว
    if (storedUserLocationValue && isLocalLocationExpired) {
      // มี local User Location อยู่แล้ว แต่หมดอายุ => ย้ายไปเป็น Last scanned
      // => ย้ายไปเป็น Last scanned และเคลียร์ค่าเก่า
      setter(KEY_LAST_SCANNED_LOCATION_VALUE, {
        value: storedUserLocationValue,
      })
      setter(KEY_USER_LOCATION_VALUE, null)
      setter(KEY_USER_LOCATION_EXPIRED_ON, null)
    }
    // มี local User Location อยู่แล้วและยังไม่หมดอายุ => ไม่ต้องทำอะไร
    // ไม่มี local User Location อยู่แล้วและยังไม่หมดอายุ => ไม่ต้องทำอะไร
  }, [enable])

  useEffect(() => {
    if (!enable) return

    const userLocationValue = getter(KEY_USER_LOCATION_VALUE)?.value
    const storedLastScanLocationValue = getter(
      KEY_LAST_SCANNED_LOCATION_VALUE
    )?.value
    const userLocationExpiryDate = getter(KEY_USER_LOCATION_EXPIRED_ON)
    if (
      storedLastScanLocationValue &&
      isLatLngQueryString(storedLastScanLocationValue)
    ) {
      const lastScannedLocationFeature = createGeolocationFeature(
        storedLastScanLocationValue,
        { name: "Last Scanned" }
      )
      onUpdate(lastScannedLocationFeature)
    }
    if (
      userLocationValue &&
      userLocationExpiryDate &&
      isLatLngQueryString(userLocationValue)
    ) {
      // Calculate the expiration time and stored in local storage
      const currentDate = getCurrentDateISOString()
      const isLocationFreshed = isBeforeDate(
        currentDate,
        userLocationExpiryDate
      )

      // If Location is still freshed update state user location
      if (isLocationFreshed) {
        const userLocationFeature = createGeolocationFeature(
          userLocationValue,
          { name: "Your Location" }
        )
        setUserLocationState(userLocationFeature)
      }
    }
    setIsLoading(false)
  }, [onUpdate, enable])

  // Handle user location update
  useEffect(() => {
    if (!enable) return

    // Update Location
    if (timer.current !== null) {
      clearTimeout(timer.current)
      timer.current = null
    }

    if (userLocationState) {
      const currentDate = getCurrentDateISOString()
      const userLocationExpiryDate = getter(KEY_USER_LOCATION_EXPIRED_ON)
      const remainingValidityTime = calculateDateDifference(
        currentDate,
        userLocationExpiryDate
      )
      if (update) {
        timer.current = setTimeout(() => {
          updateStoreLocationToLastScannedLocation()
          onUpdate(userLocationState)
          setUserLocationState(null)
        }, remainingValidityTime)
      }
    }
  }, [userLocationState, onUpdate, update, enable])

  return {
    isLoading,
    userLocation: userLocationState,
  }
}
useUserLocation.whyDidYouRender = true
