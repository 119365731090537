import { useCallback, useEffect } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { useTheme, Box } from "@mui/material"
import _ from "lodash"

import { useQuery as useQueryString } from "hooks"
import { useMap } from "hooks/map/useMap"
import { useOrdinalMap } from "hooks/ordinal/useOrdinalMap"

import { useAppUI } from "providers/ui"
import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import { useGeoLocation } from "providers/geolocation"

import { pluralize } from "utils/string"

import { OrdinalSelector } from "components/OrdinalSelector"
import { AppNavigation } from "containers/kiosk-v"
import { VenueSelector } from "components/VenueSelector"
import { OrdinalSelectMenu } from "components/OrdinalSelector/OrdinalSelectMenu"
import { FeatureChipToggleBar } from "components/domains/features/ChipToggleBar"
import { LanguageButton } from "components/Language"
import { SearchToggleButton } from "components/domains/search"

export const MapOrdinalKioskVPage = () => {
  const { isLoading, userLocation, locationVenue } = useGeoLocation()
  const navigate = useNavigate()
  const { ordinal } = useParams()
  const { search: searchQueryString } = useLocation()
  const { userLocation: userLocationParams } = useQueryString()
  const { openSearch } = useAppUI()
  const { t } = useTranslation()
  const { venues, isMultiOrdinalVenue, isMultiVenueProject } = useVenue()
  const {
    viewingVenue,
    viewingVenueId,
    levelInVenue,
    quickSearchCategories,
    onChangeVenue,
    locationVenueId,
  } = useMap()

  const parsedOrdinal = Number(ordinal)

  useOrdinalMap(parsedOrdinal, viewingVenue)

  const theme = useTheme()
  const { styleOverrides = {} } = theme.components[`AppMap`] || {}

  const {
    actionButton,
    actionContainer,
    toolbarContainer,
    quickLinkContainer, //TODO:: planning about quick search's working flow later
    venueSelector,
    navigationWrapper,
    ordinalSelectMenu,
    ordinalSelector,
    searchBarContainer,
  } = styleOverrides

  const onClickOrdinal = useCallback(
    (ordinal) => {
      navigate(
        `/maps/${
          ordinal !== null ? `ordinal/${ordinal}${searchQueryString}` : ""
        }`,
        { replace: true }
      )
    },
    [navigate, searchQueryString]
  )

  const handleClickQuickSearch = useCallback(
    (type, category) => {
      //This condition prevent navigation when ordinal or viewingVenueId is undefined
      if (_.isNil(ordinal) || _.isNil(viewingVenueId)) return
      const pluralType = pluralize(type)
      navigate(
        `/maps/${pluralType}/${category}?from-ordinal=${ordinal}&venue=${viewingVenueId}`
      )
    },
    [navigate, ordinal, viewingVenueId]
  )

  useEffect(() => {
    if (
      !isLoading &&
      userLocationParams &&
      userLocation &&
      !_.isNil(locationVenue?.id)
    ) {
      const {
        properties: { ordinal: userOrdinal },
      } = userLocation
      navigate(`/maps/ordinal/${userOrdinal}?venue=${locationVenue?.id}`, {
        replace: true,
      })
    } else if (
      !isLoading &&
      userLocationParams &&
      !_.isNil(locationVenue?.id)
    ) {
      navigate(`/maps/ordinal/${ordinal}?venue=${locationVenue?.id}`, {
        replace: true,
      })
    }
  }, [
    navigate,
    ordinal,
    isLoading,
    userLocation,
    locationVenue,
    userLocationParams,
  ])

  return (
    <>
      {isMultiOrdinalVenue && (
        <Box sx={ordinalSelector}>
          <OrdinalSelector
            selectedOrdinal={parsedOrdinal}
            levels={levelInVenue}
            onClickOrdinal={onClickOrdinal}
          />
        </Box>
      )}
      {isMultiVenueProject && (
        <Box sx={venueSelector}>
          <VenueSelector
            selectedVenue={viewingVenueId}
            venues={venues}
            onClickVenue={onChangeVenue}
            currentVenue={locationVenueId}
          />
        </Box>
      )}
      {isMultiOrdinalVenue && (
        <Box sx={ordinalSelectMenu}>
          <OrdinalSelectMenu
            selectedOrdinal={parsedOrdinal}
            levels={levelInVenue}
            onChangeOrdinal={onClickOrdinal}
          />
        </Box>
      )}
      <Box key="app-navigation" sx={navigationWrapper}>
        <Box sx={quickLinkContainer}>
          <FeatureChipToggleBar
            categories={quickSearchCategories}
            onClick={handleClickQuickSearch}
          />
        </Box>
        <Box sx={toolbarContainer}>
          <Box sx={actionContainer}>
            <LanguageButton variant="contained" sx={actionButton} />
            {/* //TODO:: Add a wheel chair's mode handler function later */}
            {/*  <Button
            variant="contained"
            sx={actionButton}
            onClick={() => {}}
            startIcon={<RestroomWheelchairIcon />}
          /> */}
          </Box>
          <Box sx={searchBarContainer}>
            <SearchToggleButton
              placeholder={t("Search shops and more...")}
              onClick={openSearch}
            />
          </Box>
        </Box>
        <AppNavigation />
      </Box>
    </>
  )
}
