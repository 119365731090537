import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { HelmetProvider } from "react-helmet-async"
import { BrowserRouter, MemoryRouter } from "react-router-dom"

import "simplebar-react/dist/simplebar.min.css"
// Providers
import { VenueProvider } from "./providers/venue"
import { AppUIProvider } from "./providers/ui"
import { RouteProvider } from "./providers/route/RouteProvider"
import { GeoLocationProvider } from "./providers/geolocation"

import { FallbackError } from "components/error/FallbackError"

import config from "./config"
import { StandardApp } from "./StandardApp"
import { KioskHApp } from "./KioskHApp"
import { KioskVApp } from "./KioskVApp"
import { IndoorMapProvider } from "providers/venue/modules/indoormap/IndoorMapProvider"

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || process.env.NODE_ENV,
  integrations: [new BrowserTracing()],
})

/** NOTE: IIFE Self-Invoking Functions
 * คือการประกาศ function แล้วเรียกใช้ทันที
 *
 * (() => {
 *   ....
 * })()
 *
 */

const VenueApp = (() => {
  switch (config("device")) {
    case "kiosk-h":
      return KioskHApp
    case "kiosk-v":
      return KioskVApp
    case "mobile":
    default:
      return StandardApp
  }
})()

/**
 * How do I do virtual routing? (Electron)
 * https://www.electronforge.io/config/plugins/webpack#how-do-i-do-virtual-routing
 */
const AppRouter =
  config("appType") === "electron" ? MemoryRouter : BrowserRouter

function Main() {
  return (
    <Sentry.ErrorBoundary fallback={<FallbackError />}>
      <HelmetProvider>
        <VenueProvider>
          <IndoorMapProvider>
            <RouteProvider>
              <AppRouter>
                <AppUIProvider>
                  <GeoLocationProvider>
                    <VenueApp />
                  </GeoLocationProvider>
                </AppUIProvider>
              </AppRouter>
            </RouteProvider>
          </IndoorMapProvider>
        </VenueProvider>
      </HelmetProvider>
    </Sentry.ErrorBoundary>
  )
}

export default Main
