import { useCallback, useMemo } from "react"
import { useVenue } from "providers/venue"
import config from "config"
import { useNavigate } from "react-router-dom"
import { ISponsoredContent } from "providers/venue/types"

interface IUseSponsoredContentResult extends ISponsoredContent {
  navigateToSponsoredLink: (link: string) => void
}

export const useSponsoredContent = (): IUseSponsoredContentResult => {
  const { sponsoredContent } = useVenue()
  const navigate = useNavigate()
  const isKioskMode = useMemo(
    () => ["kiosk-v", "kiosk-h"].includes(config("device")),
    []
  )
  const navigateToSponsoredLink = useCallback(
    (link) => {
      // isVenueURL : /maps/...
      // !isVenueURL : www.google.com
      const isVenueURL = link.startsWith("/")
      if (isVenueURL) return navigate(link)
      //return if it's external URL on kiosk Mode
      if (isKioskMode) return
      window.open(link)
    },
    [isKioskMode, navigate]
  )

  return { ...sponsoredContent, navigateToSponsoredLink }
}
