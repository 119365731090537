import { useCallback } from "react"
import { useQuery } from "@tanstack/react-query"
import { useGetImdf } from "../useGetImdf"
import { fetchGeojson } from "../../../utils/fetchUtils"
import { preparePromotion } from "./utils/preparePromotion"
import { Promotion, PromotionData } from "../../../types"

export const useGetPromotions = () => {
  const { data: imdfData } = useGetImdf()
  const findOne = useCallback(
    (id) => imdfData.features.find((f) => f.id === id),
    [imdfData]
  )
  return useQuery<Promotion[], unknown, PromotionData[]>({
    queryKey: ["promotions"],
    queryFn: async () => {
      const response = await fetchGeojson<Promotion>("promotion")
      return response.data
    },
    placeholderData: [],
    refetchOnWindowFocus: false,
    retry: true,
    select: (data: Promotion[]): PromotionData[] =>
      data.map((promotion) => preparePromotion(promotion, findOne)),
  })
}
