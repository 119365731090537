import React from "react"

import { useAppUI } from "providers/ui"

import { Navigation } from "../components/domains/navigation"

export const AppNavigation = (props) => {
  const { mobileNavigations, mobileMainNavigations, matchedPath } = useAppUI()

  return (
    <Navigation
      value={matchedPath?.to}
      bottomNavigations={mobileMainNavigations}
      navigations={mobileNavigations}
      {...props}
    />
  )
}
