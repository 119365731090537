type GetVisiblePaginationIndexRange = (
  currentIndex: number,
  totalPagination: number,
  limitPaginations: number
) => { startIndex: number; endIndex: number }

export const getVisiblePaginationIndexRange: GetVisiblePaginationIndexRange = (
  currentIndex,
  totalPagination,
  limitPaginations
) => {
  // Step 1: Calculate the middle dot index.
  const middlePaginationIndex = Math.floor(limitPaginations / 2)

  // Step 2: Calculate the start index of the displayed dots.
  // Ensure startIndex is not less than 0.
  let startIndex = Math.max(0, currentIndex - middlePaginationIndex)

  // Step 3: Calculate the end index of the displayed dots.
  // Ensure endIndex does not exceed the last slide index.
  const endIndex = Math.min(
    totalPagination - 1,
    startIndex + limitPaginations - 1
  )

  // Step 4: If there are not enough slides to fill limitPaginations, adjust startDot to include limitPaginations dots.
  if (startIndex - endIndex < limitPaginations - 1) {
    startIndex = Math.max(0, endIndex - limitPaginations + 1)
  }

  return { startIndex, endIndex }
}
