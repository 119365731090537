import { Box, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"

export const ContentWithAside = ({ children, aside, ...props }) => {
  const theme = useTheme()
  const { sx, ...restProps } = props
  const styleOverrides =
    theme.components[`AppContentWithAside`]?.styleOverrides || {}
  const { root, contentWrapper, asideWrapper } = styleOverrides
  return (
    <Box
      sx={deepmerge(root, sx)}
      className="content-with-aside-layout"
      {...restProps}
    >
      <Box sx={contentWrapper} className="content">
        {children}
      </Box>
      <Box sx={asideWrapper} className="aside">
        {aside}
      </Box>
    </Box>
  )
}
