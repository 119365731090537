import SimpleBar from "simplebar-react"

import { Box, List, useTheme } from "@mui/material"
import { SearchListItem } from "./SearchListItem"
import { NoSearchResult } from "./NoSearchResult"

export const SearchResult = ({
  startAdornment,
  popupType,
  noSearchResult = <NoSearchResult />,
  renderSearchItem: RenderSearchItem = SearchListItem,
  items,
  onSelect,
}) => {
  const theme = useTheme()
  const styles = theme.components[`VuiSearchResult`]?.styleOverrides || {}

  return (
    <Box
      className="VuiSearchResult-root"
      sx={[styles.root, styles[`root${popupType}`]]}
    >
      {items.length > 0 ? (
        <SimpleBar style={{ width: "100%", height: "100%" }}>
          {startAdornment}
          <List sx={styles.list}>
            {items.map((item, index) => (
              <RenderSearchItem
                sx={styles.listItem}
                key={index}
                data={item}
                onClick={() => onSelect(item)}
                endAdornment={item.venueLogo}
              />
            ))}
          </List>
        </SimpleBar>
      ) : (
        noSearchResult
      )}
    </Box>
  )
}
