import { useState, useEffect } from "react"
import {
  isLatLngQueryString,
  createGeolocationFeature,
} from "utils/geolocation"
import { useQuery } from "./useQuery"
import {
  get as getPersistValue,
  set as setPersistValue,
} from "utils/persist_store"
import { VENUE_DEVICE_LOCATION_KEY, VENUE_DEVICE_NAME_KEY } from "constant"

export const useDeviceLocation = () => {
  const [deviceLocationState, setDeviceLocationState] = useState(null)
  const [deviceLocationNameState, setDeviceLocationNameState] = useState(null)

  const { deviceLocation, locationName } = useQuery()

  useEffect(() => {
    const locationPath = getPersistValue(VENUE_DEVICE_LOCATION_KEY)
    const locationName = getPersistValue(VENUE_DEVICE_NAME_KEY)
    setDeviceLocationState(
      createGeolocationFeature(locationPath, {
        name: "Your Location",
      })
    )
    setDeviceLocationNameState(locationName)
  }, [])

  useEffect(() => {
    if (deviceLocation && isLatLngQueryString(deviceLocation)) {
      setPersistValue(VENUE_DEVICE_LOCATION_KEY, deviceLocation)
      setDeviceLocationState(
        createGeolocationFeature(getPersistValue(VENUE_DEVICE_LOCATION_KEY), {
          name: "Your Location",
        })
      )
    }
  }, [deviceLocation, locationName])

  useEffect(() => {
    //TODO finding a better position for setPersistValue VENUE_DEVICE_NAME
    if (locationName) {
      setPersistValue(VENUE_DEVICE_NAME_KEY, locationName)
      setDeviceLocationNameState(locationName)
    }
  }, [locationName])

  return {
    deviceLocation: deviceLocationState,
    deviceLocationName: deviceLocationNameState,
  }
}
