import { useMemo, useRef, useCallback, useEffect } from "react"
import { useParams } from "react-router-dom"
import _ from "lodash"
import {
  useTheme,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
} from "@mui/material"
import SavedSearchRoundedIcon from "@mui/icons-material/SavedSearchRounded"

import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import { getIcon } from "utils/icon"

export const FeatureChipToggleBar = (props) => {
  const { categories = [], onClick = () => {} } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const { mapTheme } = useVenue()

  const { root, button, text } =
    theme.components[`FeatureChipToggleBar`]?.styleOverrides || {}
  // TODO: Remove useParams
  const { category } = useParams()

  const scrollRef = useRef([])
  const scrollToSection = useCallback((index) => {
    if (scrollRef.current.length) {
      scrollRef.current[index]?.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  useEffect(() => {
    if (category && categories.length) {
      const index = categories.indexOf(category)
      scrollToSection(index)
    }
  }, [scrollToSection, category, categories])

  const getFeatureToggleButtonProps = useCallback(
    (featureCategory) => {
      const { type, category } = featureCategory
      const iconPath = _.get(
        mapTheme,
        `${type}.${category}.label.marker.symbol.markerPath`
      )
      const image = _.get(
        mapTheme,
        `${category}.${category}.label.marker.symbol.markerFile`
      )
      return iconPath
        ? { type, category: category, icon_path: iconPath }
        : { type, category: category, image }
    },
    [mapTheme]
  )

  const amenityButtonProps = useMemo(
    () => categories.map((amenity) => getFeatureToggleButtonProps(amenity)),
    [categories, getFeatureToggleButtonProps]
  )
  return (
    <ToggleButtonGroup color="primary" value={category} exclusive sx={root}>
      {amenityButtonProps.map((buttonProp, index) => {
        const { type, category } = buttonProp
        return (
          <ToggleButton
            key={`${type}-quick-search-${category}`}
            value={category}
            sx={button}
            onClick={() => {
              onClick(type, category)
            }}
            ref={(ref) => (scrollRef.current[index] = ref)}
          >
            {getIcon(buttonProp) || <SavedSearchRoundedIcon />}
            <Typography variant="subtitle" component="span" sx={text}>
              {t(`${type}.${category}`)}
            </Typography>
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}
