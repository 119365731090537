import React from "react"
import {
  IconButton,
  ListItem,
  ListItemText,
  Step,
  StepLabel,
  useTheme,
} from "@mui/material"
import { deepmerge } from "@mui/utils"
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded"
import { Link } from "react-router-dom"

export const DirectionStepItem = (props) => {
  const {
    navigateTo = null,
    primaryLabel = "",
    secondaryLabel = "",
    stepLabelProps = {},
    sx = {},
    ...restProps
  } = props

  const navigateProps = navigateTo
    ? {
        component: Link,
        to: navigateTo,
        secondaryAction: (
          <IconButton aria-label="go to step" color="inherit" size="small">
            <ArrowForwardIosRoundedIcon fontSize="medium" />
          </IconButton>
        ),
      }
    : {}

  const theme = useTheme()
  const { styleOverrides } = theme.components?.[`DirectionStepItem`] || {}
  const { root, listItem } = styleOverrides

  return (
    <Step sx={deepmerge(root, sx)} active={true} {...restProps}>
      <StepLabel {...stepLabelProps}>
        <ListItem
          color="inherit"
          disablePadding
          sx={listItem}
          {...navigateProps}
        >
          <ListItemText primary={primaryLabel} secondary={secondaryLabel} />
        </ListItem>
      </StepLabel>
    </Step>
  )
}
