import { useCallback, useMemo, useState } from "react"
import {
  Box,
  Stepper,
  Typography,
  FormControlLabel,
  Paper,
  Switch,
  useTheme,
  Fab,
  Slide,
  Backdrop,
  CircularProgress,
  Avatar,
} from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useTranslation } from "providers/i18n"
import { Trans } from "react-i18next"
import _ from "lodash"

import { useDirectionOverview } from "hooks/direction/useDirectionOverview"
import { useDirectionOverviewMap } from "hooks/direction/useDirectionOverviewMap"
import { useAppUI } from "providers/ui"
import { getIcon } from "utils/icon"

import { CustomIcon } from "components/icons"
import {
  DirectionStepItem,
  DirectionCardActions,
} from "components/domains/direction"
import { DestinationMarkerIcon } from "components/icons"
import { BorderLinearProgress } from "components/Progress/BorderLinearProgress"
import { DirectionStepIcon } from "extensions/one-siam/components/domains/direction"
import { OrdinalSelector } from "components/OrdinalSelector"

export const DirectionOverviewKioskVPage = () => {
  const [open, setOpen] = useState(false)
  const {
    isCalculatingRoute,
    steps,
    duration,
    distance,
    currentOrdinal,
    originData,
    originName,
    originLogo,
    destinationData,
    destinationName,
    destinationLogo,
    originCategoryIconPath,
    elevatorPreferredMode,
    destinationCategoryIconPath,
    route,
    relatedLevelWithSteps,
    toggleElevatorPreference,
    startNavigate,
    changeOrdinal,
  } = useDirectionOverview()

  const stepsAvailable = steps && steps.length > 0

  useDirectionOverviewMap(route, currentOrdinal, {
    offset: { bottom: 411 },
    enabled: !!stepsAvailable,
  })

  const { t } = useTranslation()

  const { goBack } = useAppUI()

  const theme = useTheme()

  const { paper, formControlLabel } =
    theme.components[`AppDirectionSearch`]?.styleOverrides || {}
  const {
    root,
    // Direction Overview
    actionButtonWrapper,
    container,
    kioskBackButton,
    cardContainer,
    cardBody,
    cardAction,
    headerWrapper,
    locationContainer,
    primaryText,
    secondaryText,
    durationText,
    // Step Overview
    stepCardContainer,
    stepper,
    stepCardBody,
    originStep,
    destinationStep,
    stepIconContainer,
    stepAvatar,
    ordinalSelector,
  } = theme.components[`AppDirectionOverview`]?.styleOverrides || {}

  const { originIcon, destinationIcon, highlightedText } =
    theme.components[`DirectionStep`]?.styleOverrides || {}

  const {
    overviewProgressContainer,
    originIconContainer,
    destinationIconContainer,
    locationAvatar,
  } = theme.components[`AppNavigationStep`]?.styleOverrides || {}

  const highlightedStepText = (
    <Typography variant="subTitle" component="span" sx={highlightedText} />
  )

  const originIconComp = useMemo(() => {
    const categoryIcon = {
      icon_path: originCategoryIconPath || null,
    }
    const icon = _.get(theme, "assets.navigation.origin", categoryIcon)
    return (
      getIcon(icon, originIcon) || <DestinationMarkerIcon sx={originIcon} />
    )
  }, [theme, originIcon, originCategoryIconPath])

  const destinationIconComp = useMemo(() => {
    const categoryIcon = {
      icon_path: destinationCategoryIconPath || null,
    }
    const icon = _.get(theme, "assets.navigation.destination", categoryIcon)
    return (
      getIcon(icon, destinationIcon) || (
        <DestinationMarkerIcon sx={destinationIcon} />
      )
    )
  }, [theme, destinationIcon, destinationCategoryIconPath])

  const handleClickBack = useCallback(() => {
    goBack()
  }, [goBack])

  const handleCloseDrawer = useCallback(() => {
    setOpen(false)
  }, [])

  const backIcon = useMemo(
    () => getIcon(theme?.assets?.backIcon) || <ArrowBackIcon />,
    [theme]
  )

  const handleClickStep = useCallback(() => setOpen((prev) => !prev), [])
  return (
    <>
      <Backdrop open={isCalculatingRoute}>
        <CircularProgress />
      </Backdrop>
      <Box sx={root}>
        {stepsAvailable && (
          <>
            {/* Direction Overview */}
            <Slide
              direction="up"
              in={stepsAvailable && !open}
              mountOnEnter
              unmountOnExit
            >
              <Box sx={container}>
                <Box sx={ordinalSelector}>
                  <OrdinalSelector
                    selectedOrdinal={currentOrdinal}
                    levels={relatedLevelWithSteps}
                    onClickOrdinal={changeOrdinal}
                  />
                </Box>
                <Box sx={actionButtonWrapper}>
                  <Fab
                    variant="rounded"
                    sx={kioskBackButton}
                    onClick={handleClickBack}
                  >
                    {backIcon}
                  </Fab>
                  <Paper elevation={1} sx={paper}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={elevatorPreferredMode}
                          onChange={toggleElevatorPreference}
                        />
                      }
                      label={t("Avoid Steps")}
                      componentsProps={{ typography: { variant: "body2" } }}
                      sx={formControlLabel}
                    />
                  </Paper>
                </Box>

                <Paper sx={cardContainer}>
                  <Box sx={cardBody}>
                    <Box sx={headerWrapper}>
                      <Box sx={locationContainer}>
                        <Typography textAlign="start" sx={secondaryText}>
                          {t("Origin")}
                        </Typography>
                        <Typography
                          textAlign="start"
                          variant="h4"
                          sx={primaryText}
                        >
                          {t(originName)}
                        </Typography>
                      </Box>

                      <Box sx={locationContainer}>
                        <Typography textAlign="end" sx={secondaryText}>
                          {t("Destination")}
                        </Typography>
                        <Typography
                          textAlign="end"
                          variant="h4"
                          sx={primaryText}
                        >
                          {t(destinationName)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={overviewProgressContainer}>
                      <Box sx={originIconContainer}>
                        <Avatar
                          variant="circular-logo"
                          sx={locationAvatar}
                          src={originLogo}
                        >
                          {originCategoryIconPath ? (
                            <CustomIcon path={originCategoryIconPath} />
                          ) : (
                            originIconComp
                          )}
                        </Avatar>
                      </Box>
                      <BorderLinearProgress variant="determinate" value={0} />
                      <Box sx={destinationIconContainer}>
                        <Avatar
                          variant="circular-logo"
                          sx={locationAvatar}
                          src={destinationLogo}
                        >
                          {destinationCategoryIconPath ? (
                            <CustomIcon path={destinationCategoryIconPath} />
                          ) : (
                            destinationIconComp
                          )}
                        </Avatar>
                      </Box>
                      <Typography variant="caption" sx={durationText}>
                        {t("Travel", {
                          duration,
                          distance,
                        })}
                      </Typography>
                    </Box>
                  </Box>
                  <DirectionCardActions
                    sx={cardAction}
                    stepButtonProps={{ variant: "outlined" }}
                    onStart={startNavigate}
                    onStep={handleClickStep}
                  />
                </Paper>
              </Box>
            </Slide>

            {/* Step Overview */}
            <Slide direction="up" in={open} mountOnEnter unmountOnExit>
              <Box sx={container}>
                <Box sx={actionButtonWrapper}>
                  <Fab
                    variant="rounded"
                    sx={kioskBackButton}
                    onClick={handleCloseDrawer}
                  >
                    {backIcon}
                  </Fab>
                </Box>
                <Paper sx={stepCardContainer}>
                  <Box sx={stepCardBody}>
                    <Stepper
                      sx={stepper}
                      orientation="vertical"
                      connector={null}
                    >
                      {originData && (
                        <DirectionStepItem
                          sx={originStep}
                          primaryLabel={t("Origin")}
                          secondaryLabel={t("Location Floor", {
                            location: t(originName),
                            level: originData.level,
                          })}
                          stepLabelProps={{
                            icon: (
                              <Box sx={stepIconContainer}>
                                <Avatar
                                  variant="circular-logo"
                                  sx={stepAvatar}
                                  src={originLogo}
                                >
                                  {originCategoryIconPath ? (
                                    <CustomIcon path={originCategoryIconPath} />
                                  ) : (
                                    originIconComp
                                  )}
                                </Avatar>
                              </Box>
                            ),
                          }}
                        />
                      )}
                      {steps.map((step, index) => {
                        const { description } = step
                        return (
                          <DirectionStepItem
                            index={index}
                            navigateTo={`navigate/steps/${index + 1}`}
                            primaryLabel={
                              <Trans
                                components={{
                                  category: highlightedStepText,
                                  destination: highlightedStepText,
                                  landmark: highlightedStepText,
                                }}
                              >
                                {t(description.text, {
                                  category: t(description.options?.category),
                                  destination: t(
                                    description.options?.destination
                                  ),
                                  landmark: t(description.options?.landmark),
                                })}
                              </Trans>
                            }
                            stepLabelProps={{
                              StepIconComponent: DirectionStepIcon,
                            }}
                            key={`step-description-${index}`}
                          />
                        )
                      })}
                      {destinationData && (
                        <DirectionStepItem
                          sx={destinationStep}
                          primaryLabel={t("Destination")}
                          secondaryLabel={t("Location Floor", {
                            location: t(destinationName),
                            level: destinationData.level,
                          })}
                          stepLabelProps={{
                            icon: (
                              <Box sx={stepIconContainer}>
                                <Avatar
                                  variant="circular-logo"
                                  sx={stepAvatar}
                                  src={destinationLogo}
                                >
                                  {destinationCategoryIconPath ? (
                                    <CustomIcon
                                      path={destinationCategoryIconPath}
                                    />
                                  ) : (
                                    destinationIconComp
                                  )}
                                </Avatar>
                              </Box>
                            ),
                          }}
                        />
                      )}
                    </Stepper>
                  </Box>
                </Paper>
              </Box>
            </Slide>
          </>
        )}
      </Box>
    </>
  )
}
