import React, { useCallback, useMemo } from "react"
import { Box, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"

import { getIcon } from "utils/icon"
import { useTranslation } from "providers/i18n"
import { usePromotion } from "hooks/promotion/usePromotion"

import { PageCard, PageCardContent } from "components/card"
import { PercentIcon } from "components/icons"
import { AppNavigation } from "containers/kiosk-h/AppNavigation"
import { NavigationControl } from "components/kioskHorizontal/navigation"
import { VenueSelector } from "components/VenueSelector"
import { Carousel, CarouselItem } from "components/Carousel"
import { PromotionCard } from "components/domains/promotions"
import { EmptyData } from "components/EmptyData"
import { HorizontalScrollPane } from "components/HorizontalScrollPane"
import {
  CategoryFilterBar,
  CategoryFilterChip,
} from "components/CategoryFilterBar"

export const PromotionsKioskHPage = ({ Layout = Box }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    venues,
    isMultiVenueProject,
    locationVenueId,
    venueFilter,
    filteredPromotions,
    promotionCategories,
    parentCategoryId,
    showCategoryFilter,
    changeVenueFilter,
    handleChangeParentCategory,
  } = usePromotion()
  const theme = useTheme()

  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.promotions) || <PercentIcon />,
    [theme]
  )

  const { styleOverrides: bannerStyleOverrides = {} } =
    theme.components[`AppBanner`] || {}
  const sideBarStyleOverrides =
    theme.components[`AppSidebar`]?.styleOverrides || {}
  const { venueSelector } = theme.components[`AppMap`]?.styleOverrides || {}

  const { root: bannerRoot } = bannerStyleOverrides
  const { wrapper, body, footer } = sideBarStyleOverrides

  const { styleOverrides } = theme.components?.[`PromotionPage`] || {}
  const {
    carouselContainer,
    carousel,
    promotionCard,
    emptyDataWrapper,
    subCategoryFilterBar,
    scrollPane,
  } = styleOverrides

  const handleClickSlide = useCallback(
    (_splide, { slide }, _event) => {
      navigate(slide.getAttribute("link"))
    },
    [navigate]
  )

  return (
    <Layout
      aside={
        <PageCard>
          <Box sx={wrapper}>
            <Box sx={bannerRoot}></Box>
            <Box sx={body}>
              <AppNavigation />
            </Box>
            <Box sx={footer}>
              <NavigationControl BackButtonProps={{ hidden: true }} />
            </Box>
          </Box>
        </PageCard>
      }
    >
      <PageCard>
        <PageCardContent>
          <Box sx={carouselContainer}>
            {filteredPromotions && filteredPromotions.length > 0 ? (
              <Carousel
                onClick={handleClickSlide}
                options={{
                  type: "loop",
                  pagination: true,
                  focus: "center",
                  autoplay: true,
                  gap: "0",
                }}
                sx={carousel}
              >
                {filteredPromotions.map((promotion) => {
                  const sponsoredId = promotion?.properties?.sponsoredId || ""
                  return (
                    <CarouselItem
                      link={`/promotions/${promotion.id}`}
                      key={`${sponsoredId}${promotion.id}`}
                    >
                      <PromotionCard promotion={promotion} sx={promotionCard} />
                    </CarouselItem>
                  )
                })}
              </Carousel>
            ) : (
              <Box sx={emptyDataWrapper}>
                <EmptyData
                  icon={emptyIcon}
                  primary={t("Coming soon")}
                  secondary={t("Check Back Soon")}
                />
              </Box>
            )}
          </Box>

          {isMultiVenueProject && (
            <Box sx={venueSelector}>
              <VenueSelector
                selectedVenue={venueFilter}
                venues={venues}
                onClickVenue={changeVenueFilter}
                currentVenue={locationVenueId}
              />
            </Box>
          )}

          {showCategoryFilter && (
            <Box sx={subCategoryFilterBar}>
              <HorizontalScrollPane
                stackProps={{
                  sx: scrollPane,
                }}
              >
                <CategoryFilterBar
                  activeCategory={parentCategoryId}
                  categories={promotionCategories}
                  renderFilterButton={CategoryFilterChip}
                  onChange={handleChangeParentCategory}
                />
              </HorizontalScrollPane>
            </Box>
          )}
        </PageCardContent>
      </PageCard>
    </Layout>
  )
}
