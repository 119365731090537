export const ESCALATOR_DISTANCE = 1000
export const ELEVATOR_DISTANCE = 2000
export const STAIR_DISTANCE = 999999
export const BASE_POI_DISTANCE = 9999999

export const OBSTACLE_FEATURE_TYPES = ["kiosk", "fixture"]
export const OBSTACLE_CATEGORIES = [
  "nonpublic",
  "opentobelow",
  "elevator",
  "escalator",
  "stairs",
  "room",
  "unspecified",
]
// Walkable category should be further update in the future
export const WALKABLE_CATEGORY = [
  "walkway",
  "parking",
  "room",
  "unenclosedarea",
  "unspecified",
]
