import React, { useCallback, useMemo, useState } from "react"
import _ from "lodash"
import { Typography, Card, Box, Divider, useTheme } from "@mui/material"

import { useTranslation } from "providers/i18n"
import { Carousel, CarouselItem } from "components/Carousel"
import { Image, ImageModal } from "components/Image"
import { Link } from "react-router-dom"

export const FeatureDetail = ({ feature, loading, ...props }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    root,
    section,
    galleryImage,
    promotionImage,
    divider,
    sectionTitle,
    descriptionText,
    galleryCarousel,
    galleryCarouselItem,
    promotionCarousel,
    promotionCarouselItem,
  } = theme.components[`AppFeatureDetail`]?.styleOverrides || {}

  const [openModal, setOpenModal] = useState(false)
  const [activeImage, setActiveImage] = useState()

  const { promotions, description, gallery } = props

  const featurePromotions = useMemo(
    () =>
      promotions
        ?.filter(({ properties }) =>
          _.includes(["store", "shop"], properties.category)
        )
        .map(({ id, properties }) => ({
          id,
          image: properties?.cover_image,
        })) || [],
    [promotions]
  )

  const handleClose = useCallback(() => {
    setOpenModal(false)
  }, [])

  const handleImage = useCallback((value) => {
    setActiveImage(value)
    setOpenModal(true)
  }, [])

  if (loading) return null

  return (
    <>
      <Card sx={root}>
        <Divider sx={divider} />
        {description && (
          <Box sx={section}>
            <Typography variant="body2" sx={descriptionText}>
              {t(description)}
            </Typography>
          </Box>
        )}
        {gallery && (
          <Box sx={section}>
            <Typography component="h2" sx={sectionTitle}>
              {t("Gallery")}
            </Typography>
            <Carousel
              sx={galleryCarousel}
              options={{
                fixedWidth: "163px",
                fixedHeight: "92px",
                perPage: 2,
                gap: 8,
                padding: { left: 20, right: 20 },
              }}
            >
              {gallery?.map((image, idx) => (
                <CarouselItem
                  key={`gallery-${image.name}-${idx}`}
                  sx={galleryCarouselItem}
                >
                  <Image
                    onClick={() => handleImage(image.url)}
                    src={image.url}
                    alt={`${image.name}-${idx}`}
                    sx={galleryImage}
                  />
                </CarouselItem>
              ))}
            </Carousel>
          </Box>
        )}
        {featurePromotions?.length > 0 && (
          <Box sx={section}>
            <Typography component="h2" sx={sectionTitle}>
              {t("Promotions")}
            </Typography>
            <Carousel
              sx={promotionCarousel}
              options={{
                fixedWidth: "84px",
                fixedHeight: "152px",
                perPage: 2,
                gap: 8,
                padding: { left: 20, right: 20 },
              }}
            >
              {featurePromotions?.map(({ id, image }) => (
                <CarouselItem
                  key={`promotion-${image.name}-${id}`}
                  sx={promotionCarouselItem}
                >
                  <Link to={`/promotions/${id}?redirectTo=-1`}>
                    <Image
                      src={image.url}
                      alt={`${image.name}-${id}`}
                      sx={promotionImage}
                    />
                  </Link>
                </CarouselItem>
              ))}
            </Carousel>
          </Box>
        )}
      </Card>
      <ImageModal
        open={openModal}
        imageSrc={activeImage}
        onClose={handleClose}
      />
    </>
  )
}
