import React from "react"
import { Box, Dialog, useTheme } from "@mui/material"
import { FeatureSummary } from "./Summary"
import { FeatureDetail } from "./Detail"

export const DesktopFeatureDialog = ({
  open,
  onClose,
  directionButtonProps = {},
  loading = true,
  data = {},
  onNavigate,
  isMultiOrdinalVenue,
  categoryIconProp = {},
  ...props
}) => {
  const theme = useTheme()
  const styleOverrides =
    theme.components[`AppDesktopFeatureDialog`]?.styleOverrides || {}
  const { dialog, contentContainer } = styleOverrides

  const {
    feature,
    zone,
    logo,
    category,
    level,
    name,
    hours,
    id,
    websiteLink,
    gallery,
    promotions,
    privileges,
    description,
  } = data

  return (
    <Dialog
      open={open}
      onClose={onClose}
      hideBackdrop
      anchor="left"
      disableEnforceFocus
      sx={dialog}
      {...props}
    >
      <Box sx={contentContainer}>
        <FeatureSummary
          id={id}
          name={name}
          hours={hours}
          level={isMultiOrdinalVenue && level}
          category={category}
          logo={logo}
          zone={zone}
          loading={loading}
          onNavigate={onNavigate}
          DirectionButtonProps={directionButtonProps}
          categoryIconProp={categoryIconProp}
        />
        <FeatureDetail
          feature={feature}
          onClose={onClose}
          websiteLink={websiteLink}
          gallery={gallery}
          promotions={promotions}
          privileges={privileges}
          description={description}
          loading={loading}
        />
      </Box>
    </Dialog>
  )
}
