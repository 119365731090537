import { Box, Typography, useTheme } from "@mui/material"

export const FeatureLabel = ({ label, value }) => {
  const theme = useTheme()
  const styleOverrides =
    theme.components[`VuiDescriptionList`]?.styleOverrides || {}
  const { root, labelText, contentText } = styleOverrides

  return (
    <Box sx={root}>
      <Typography variant="h6" sx={labelText}>
        {label}
      </Typography>
      <Typography variant="subtitle2" sx={contentText}>
        {value}
      </Typography>
    </Box>
  )
}
