import React, { useMemo } from "react"
import { Helmet } from "react-helmet-async"
import { useVenue } from "providers/venue"
import { get } from "lodash"
import { useTranslation } from "providers/i18n/useTranslation"

export const Head = (props) => {
  const { title = "", description = "", suffixTitle } = props
  const { appManifest } = useVenue()
  const { t } = useTranslation()
  const defaultSuffixTitle = useMemo(
    () => ` - ${t(get(appManifest, "name", "Venue Indoor Map"))}`,
    [appManifest, t]
  )

  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{`${title}${suffixTitle || defaultSuffixTitle}`}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
    </Helmet>
  )
}
