import { Dayjs } from "dayjs"
import { TFunction } from "i18next"

type GetOpeningText = (startTime: Dayjs, endTime: Dayjs, t: TFunction) => string

type IsValidTime = (time: string) => boolean

export const getOpeningText: GetOpeningText = (startTime, endTime, t) => {
  try {
    if (!startTime && !endTime) return t("All Day")

    if (startTime && endTime) return `${startTime} - ${endTime}`

    if (!startTime && endTime) return `${t("From Now")} - ${endTime}`

    if (startTime && !endTime)
      return t("From Start Onwards", {
        startTime: startTime,
      })
  } catch (error) {
    throw error
  }
}

export const isValidTime: IsValidTime = (time) => {
  if (!time) return false
  const timeRegex = /(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/
  return timeRegex.test(time)
}
