import React, { useCallback, useMemo } from "react"
import {
  Avatar,
  Box,
  Card,
  CardMedia,
  Divider,
  Typography,
  useTheme,
} from "@mui/material"
import { deepmerge } from "@mui/utils"
import { useNavigate } from "react-router-dom"
import get from "lodash/get"

import { getFeatureDetail } from "utils/feature"
import { useTranslation } from "providers/i18n"

import { CustomIcon } from "components/icons"
import { RestaurantIcon } from "../../icons"

export const DiningCard = ({ data, sx, endAdornment, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const categoryIcon = useMemo(
    () => get(data, "properties.local_categories[0].properties.icon_path"),
    [data]
  )
  const { name, logo, level, venueLogo, featuredImage } = useMemo(
    () => getFeatureDetail(data),
    [data]
  )

  const handleClick = useCallback(
    () => navigate(`/maps/place/${data.id}`),
    [navigate, data?.id]
  )

  const theme = useTheme()
  const { styleOverrides = {} } = theme.components?.[`DiningCard`] || {}
  const {
    root,
    occupantLogo,
    occupantLogoFallback,
    occupantFeaturedImage,
    featuredImageFallback,
    cardContent,
    levelContainer,
    divider,
    venueLogo: venueLogoStyle,
    titleText,
    headerContainer,
    titleContainer,
    detailWrapper,
    infoContainer,
  } = styleOverrides

  return (
    <Card sx={deepmerge(root, sx)} onClick={handleClick} {...props}>
      <Box sx={occupantFeaturedImage}>
        <Avatar src={featuredImage}>
          {categoryIcon ? (
            <CustomIcon path={categoryIcon} sx={featuredImageFallback} />
          ) : (
            <RestaurantIcon sx={featuredImageFallback} />
          )}
        </Avatar>
      </Box>
      <Box sx={cardContent}>
        <Box sx={headerContainer}>
          <Avatar sx={occupantLogo} src={logo}>
            {!logo &&
              (categoryIcon ? (
                <CustomIcon path={categoryIcon} sx={occupantLogoFallback} />
              ) : (
                <RestaurantIcon />
              ))}
          </Avatar>
          <Box sx={titleContainer}>
            <Typography sx={titleText}>{t(name)}</Typography>
          </Box>
        </Box>
        <Box sx={detailWrapper}>
          <Divider sx={divider} />
          <Box sx={infoContainer}>
            <Box sx={levelContainer}>
              <Typography variant="h6">{t(level)}</Typography>
            </Box>
            <CardMedia component="img" image={venueLogo} sx={venueLogoStyle} />
          </Box>
        </Box>
      </Box>
    </Card>
  )
}
