import { useMemo, useState, useCallback } from "react"
import _ from "lodash"

import dayjs from "config/dayjs"

import { useGeoLocation } from "providers/geolocation"
import { useVenue } from "providers/venue"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useVenueController } from "hooks/venue/useVenueController"
import { useRecommendedEvent } from "hooks/sponsored-content/recommended"

import { Q_VENUE } from "constant"
import { filterCurrentFeature, filterUpcomingFeature } from "utils/feature"

const sortEventsByDate = (events) => {
  return events.sort(
    (firstEvent, secondEvent) =>
      dayjs(firstEvent.properties.start_date).valueOf() -
      dayjs(secondEvent.properties.start_date).valueOf()
  )
}

export const useEvent = () => {
  const { events, venues, isMultiVenueProject } = useVenue()
  const navigate = useNavigate()
  const { locationVenue } = useGeoLocation()
  const locationVenueId = _.get(locationVenue, "id")
  const { viewingVenue: venueFilter } = useVenueController(locationVenueId)
  const [searchParams] = useSearchParams()
  const [activeGroupEvent, setActiveGroupEvent] = useState("current")
  const { recommendedEvents } = useRecommendedEvent()

  const changeVenueFilter = useCallback(
    (venueId) => {
      if (venueId) searchParams.set(Q_VENUE, venueId)
      navigate(`/events/?${searchParams.toString()}`, { replace: true })
    },
    [navigate, searchParams]
  )

  const currentEvents = useMemo(
    () => events.filter(filterCurrentFeature),
    [events]
  )

  const currentRecommendedEvents = useMemo(
    () => recommendedEvents.data.filter(filterCurrentFeature),
    [recommendedEvents]
  )

  const viewingVenueCurrentEvent = useMemo(
    () =>
      currentEvents?.filter(
        ({ properties }) => properties.venue_id === venueFilter
      ),
    [currentEvents, venueFilter]
  )

  const viewingVenueCurrentRecommendedEvent = useMemo(
    () =>
      currentRecommendedEvents?.filter(
        ({ properties }) => properties.venue_id === venueFilter
      ),
    [currentRecommendedEvents, venueFilter]
  )

  const upcomingEvents = useMemo(
    () => events.filter(filterUpcomingFeature),
    [events]
  )

  const upcomingRecommendedEvents = useMemo(
    () => recommendedEvents.data.filter(filterUpcomingFeature),
    [recommendedEvents]
  )

  const viewingVenueUpcomingEvents = useMemo(
    () =>
      upcomingEvents?.filter(
        ({ properties }) => properties.venue_id === venueFilter
      ),
    [upcomingEvents, venueFilter]
  )

  const viewingVenueUpcomingRecommendedEvents = useMemo(
    () =>
      upcomingRecommendedEvents?.filter(
        ({ properties }) => properties.venue_id === venueFilter
      ),
    [upcomingRecommendedEvents, venueFilter]
  )

  const groupEvents = useMemo(
    () => ({
      current: [
        ...viewingVenueCurrentRecommendedEvent,
        ...sortEventsByDate(viewingVenueCurrentEvent),
      ],
      upcoming: [
        ...viewingVenueUpcomingRecommendedEvents,
        ...sortEventsByDate(viewingVenueUpcomingEvents),
      ],
    }),
    [
      viewingVenueCurrentRecommendedEvent,
      viewingVenueUpcomingRecommendedEvents,
      viewingVenueCurrentEvent,
      viewingVenueUpcomingEvents,
    ]
  )

  const handleChange = useCallback((e, eventGroup) => {
    if (eventGroup === null) return
    setActiveGroupEvent(eventGroup)
  }, [])

  return {
    venues,
    venueFilter,
    locationVenueId,
    isMultiVenueProject,
    groupEvents,
    activeGroupEvent,
    handleChange,
    changeVenueFilter,
  }
}
