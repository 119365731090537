import { flatten, get, isNil, merge } from "lodash"

export const filterSearchResult = (feature) => {
  const { feature_type, properties } = feature

  // If occupant doesn't have anchor. It can't be shown on map
  if (feature_type === "occupant" && !properties.anchor) return false

  return true
}

export const filterOccupant = (feature) => {
  const { feature_type, properties } = feature
  return feature_type === "occupant" && properties.anchor
}

export const getTaxonomyFunction =
  (field: string) =>
  ({ id, properties }) => {
    try {
      const targetFieldArr = get(properties, field, [])
      const categoryLocaleObjs = targetFieldArr.map((category) =>
        get(category, "properties.name")
      )
      return categoryLocaleObjs?.length
        ? flatten(
            categoryLocaleObjs.map((localizedName) =>
              Object.values(localizedName)
            )
          )
        : ""
    } catch (error) {
      console.log("error : while getting taxonomy name from feature id : ", id)
    }
  }

const getFeatureName =
  (field: string) =>
  ({ properties }) => {
    const targetField = get(properties, field)
    return targetField ? Object.values(targetField) : ""
  }

const KEY_CONFIGS = {
  name: {
    name: "name",
    weight: 4,
    getFn: getFeatureName("name"),
  },
  category: {
    name: "category",
    weight: 0.25,
    getFn: ({ properties }) => properties?.category || "",
  },
  groups: {
    name: "groups",
    getFn: getTaxonomyFunction("groups"),
    weight: 0.4,
  },
  local_categories: {
    name: "local_categories",
    getFn: getTaxonomyFunction("local_categories"),
    weight: 0.25,
  },
  "properties.keyword": {
    name: "properties.keyword",
    weight: 0.25,
  },
  feature_type: {
    name: "feature_type",
    weight: 1,
  },
  "kiosk.name": {
    name: "kiosk.name",
    weight: 0.25,
    getFn: getFeatureName("kiosk.properties.name"),
  },
  "unit.name": {
    name: "unit.name",
    weight: 0.25,
    getFn: getFeatureName("unit.properties.name"),
  },
}

export const createSearchConfig = (config) => {
  const keys = get(config, "keys")
  const featureTypes = get(config, "featureTypes")

  if (isNil(keys) || isNil(featureTypes))
    throw new Error(
      "The search configuration must include specified 'keys' and 'featureTypes'."
    )

  return {
    keys: keys
      .filter(({ name }) => KEY_CONFIGS[name])
      .map((k) => merge(KEY_CONFIGS[k.name], k)),
    featureTypes,
  }
}

export const createSearchPattern = (value) => {
  return {
    "=amenity": {
      $and: [
        {
          feature_type: "=amenity",
        },
        { category: `=${value}` },
      ],
    },
    "=occupant": {
      $and: [
        {
          feature_type: "=occupant",
        },
        {
          $or: [
            {
              name: value,
            },
            {
              category: value,
            },
            {
              $path: ["properties.keyword"],
              $val: value,
            },
            {
              groups: value,
            },
            {
              local_categories: value,
            },
            {
              "kiosk.name": value,
            },
            {
              "unit.name": value,
            },
          ],
        },
      ],
    },
    "=occupant | =amenity": {
      $and: [
        {
          feature_type: "=occupant | =amenity",
        },
        {
          $or: [
            {
              name: value,
            },
            {
              category: value,
            },
            {
              $path: ["properties.keyword"],
              $val: value,
            },
            {
              groups: value,
            },
            {
              local_categories: value,
            },
          ],
        },
      ],
    },
  }
}
