import { Button, Stack, useMediaQuery, useTheme } from "@mui/material"
import { ShareButton } from "components/Button"
import { useTranslation } from "react-i18next"
import { PlaceIcon } from "components/icons"

export const FeatureActionContainer = ({
  originId = "unknown",
  destinationId,
  ShareProps,
  DirectionButtonProps,
  onNavigate,
}) => {
  const { t } = useTranslation()
  const { icon = <PlaceIcon />, label = "Directions" } =
    DirectionButtonProps || {}
  const isMobileOrTablet = useMediaQuery("(max-width: 991px)")
  const buttonSize = isMobileOrTablet ? "small" : "medium"
  const theme = useTheme()
  const { shortInfoActionWrapper, navigationButton, actionButton } =
    theme.components[`FeatureActionContainer`]?.styleOverrides || {}

  return (
    <Stack direction="row" spacing={1} sx={shortInfoActionWrapper}>
      <Button
        variant="contained"
        startIcon={icon}
        size={buttonSize}
        {...DirectionButtonProps}
        onClick={onNavigate}
        sx={navigationButton}
      >
        {t(label)}
      </Button>
      {/* Remove Call button for now */}
      {/* {phone && <CallButton size={buttonSize} phoneNumber={phone} sx={actionButton} />} */}
      {navigator.share && (
        <ShareButton
          size={buttonSize}
          url={window.location.href}
          {...ShareProps}
          sx={actionButton}
        />
      )}
    </Stack>
  )
}
