export const loadingScreen = {
  initial: { opacity: 1 },
  enter: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.75,
      delay: 0.5,
      easing: "easeInOut",
    },
  },
}

export const loadingLogo = {
  animate: {
    opacity: [0.5, 1, 0.5],
    scale: [0.9, 1, 0.9],
    transition: {
      duration: 1.5,
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

export const showContent = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.5 } },
}
