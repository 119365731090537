import { useEffect, useMemo } from "react"
import _ from "lodash"
import { getSuitablyValueBetweenBearings } from "components/IndoorMap"
import { useIndoorMap } from "providers/venue/modules/indoormap/hooks/useIndoorMap"
import { useVenue } from "providers/venue"
import { useGeoLocation } from "providers/geolocation"
import { VenueData } from "providers/venue/types"

const DEFAULT_OPTIONS = { enabled: true }

export const useOrdinalMap = (
  ordinal: number,
  viewingVenue: VenueData,
  options = DEFAULT_OPTIONS
) => {
  const { dataLoaded, mapConfig } = useVenue()
  const { indoorRef, mapLoaded, defaultBearing, defaultPitch } = useIndoorMap()
  const { userLocation, locationVenue, viewPoint } = useGeoLocation()
  const { enabled } = { ...DEFAULT_OPTIONS, ...options }

  const viewingVenueId = useMemo(
    () => _.get(viewingVenue, "id"),
    [viewingVenue]
  )
  const mapReady = enabled && indoorRef?.current && mapLoaded && dataLoaded

  useEffect(() => {
    if (mapReady) {
      indoorRef.current.clearNavigationGeometries()
      indoorRef.current.enableClick()
      indoorRef.current.setFeatureObject3DsOpacity(1)
      indoorRef.current.showVenueObjects()

      indoorRef.current.clearHighlightElements()
      indoorRef.current.clearHighlightObject()
    }
  }, [indoorRef, mapReady])

  useEffect(() => {
    if (!mapReady) return

    indoorRef.current.changeLevelByOrdinal(ordinal)

    const venueDefaultZoom =
      _.get(viewingVenue, "properties.zoom") || _.get(mapConfig, "default_zoom")
    const venueDisplayCoordinates = _.get(
      viewingVenue,
      "properties.display_point.coordinates"
    )

    if (
      viewPoint &&
      ordinal === viewPoint?.properties?.ordinal &&
      viewingVenueId === locationVenue?.id
    ) {
      //! Temporary solution to prevent flyTo from not finishing animation at the correct camera view when immediately changing the viewport between the home screen and floorplan menu (kiosk-v)
      setTimeout(() => {
        const currentBearing = indoorRef.current.getBearing()
        const bearing = getSuitablyValueBetweenBearings(
          defaultBearing,
          currentBearing
        )
        const pitch = defaultPitch
        const locationExtent = indoorRef.current.getFeatureExtent(viewPoint)
        const venueExtent = indoorRef.current.getFeatureExtent(locationVenue)
        const center = indoorRef.current.getExtentCenter(locationExtent)
        const zoom =
          venueDefaultZoom || indoorRef.current.getExtentZoom(venueExtent) + 1 //adjust offset

        indoorRef.current.flyTo(center, {
          zoom,
          bearing,
          pitch,
        })
      }, 150)
      return
    }

    if (
      userLocation &&
      ordinal === userLocation?.properties?.ordinal &&
      viewingVenueId === locationVenue?.id
    ) {
      //! Temporary solution to prevent flyTo from not finishing animation at the correct camera view when immediately changing the viewport between the home screen and floorplan menu (kiosk-v)
      setTimeout(() => {
        const currentBearing = indoorRef.current.getBearing()
        const bearing = getSuitablyValueBetweenBearings(
          defaultBearing,
          currentBearing
        )
        const pitch = defaultPitch
        const locationExtent = indoorRef.current.getFeatureExtent(userLocation)
        const venueExtent = indoorRef.current.getFeatureExtent(locationVenue)
        const center = indoorRef.current.getExtentCenter(locationExtent)
        const zoom =
          venueDefaultZoom || indoorRef.current.getExtentZoom(venueExtent) + 1 //adjust offset
        indoorRef.current.flyTo(center, {
          zoom,
          bearing,
          pitch,
        })
      }, 150)
    } else if (viewingVenue && viewingVenue.feature_type === "venue") {
      const currentBearing = indoorRef.current.getBearing()
      const bearing = getSuitablyValueBetweenBearings(
        defaultBearing,
        currentBearing
      )
      const pitch = defaultPitch
      const extent = indoorRef.current.getFeatureExtent(viewingVenue)
      const center =
        venueDisplayCoordinates || indoorRef.current.getExtentCenter(extent)
      const zoom =
        venueDefaultZoom || indoorRef.current.getExtentZoom(extent) + 1 //adjust offset
      indoorRef.current.flyTo(center, {
        zoom,
        bearing,
        pitch,
      })
    }
  }, [
    defaultBearing,
    defaultPitch,
    indoorRef,
    locationVenue,
    userLocation,
    viewPoint,
    viewingVenue,
    mapReady,
    ordinal,
    viewingVenueId,
    mapConfig,
  ])
}
