import { Box, Button, Stack, styled, Typography, Zoom } from "@mui/material"
import { useCallback } from "react"
import Div100vh from "react-div-100vh"
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded"
import { useTranslation } from "providers/i18n"

const Container = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

export const FallbackError = () => {
  const { t } = useTranslation()
  const handleClick = useCallback(() => {
    const url = window.location.origin
    if (window.electron) {
      window.electron.sendMessage("relaunch")
    } else {
      window.location.replace(url)
    }
  }, [])

  return (
    <Div100vh
      style={{
        margin: "-10px -8px",
        background: "#fff",
      }}
    >
      <Container>
        <Stack spacing={2}>
          <Stack alignItems="center" maxWidth={280}>
            <Typography variant="h1" fontWeight="bold" textAlign="center">
              404
            </Typography>
            <Typography
              variant="h5"
              textTransform="uppercase"
              textAlign="center"
            >
              {t("Looks like you're lost", { ns: "error" })}
            </Typography>
            <Typography variant="caption" textAlign="center">
              {t(
                "We can't seem to find the page your're looking for. Try going back to the home page.",
                { ns: "error" }
              )}
            </Typography>
          </Stack>
          <Stack alignItems="center">
            <Zoom in={true} style={{ transitionDelay: "800ms" }}>
              <Button
                variant="text"
                onClick={handleClick}
                endIcon={<ArrowForwardRoundedIcon fontSize="inherit" />}
              >
                {t("Back to Home")}
              </Button>
            </Zoom>
          </Stack>
        </Stack>
      </Container>
    </Div100vh>
  )
}
