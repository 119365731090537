import _get from "lodash/get"
import _compact from "lodash/compact"

const configOption = {
  venueId: process.env.REACT_APP_VENUE_ID,
  projectId: process.env.REACT_APP_PROJECT_ID,
  themeName: process.env.REACT_APP_THEME_NAME,
  host: process.env.REACT_APP_API_HOST,
  realtimeHost: process.env.REACT_APP_REALTIME_API_HOST,
  apiKey: process.env.REACT_APP_API_KEY,
  realtimeApiKey: process.env.REACT_APP_REALTIME_API_KEY,
  venueExtension: _compact(
    (process.env.REACT_APP_VENUE_EXTENSION || "standard").split(",")
  ),
  device: process.env.REACT_APP_DEVICE,
  appType: process.env.REACT_APP_TYPE || "webapp",
  mapThemeName: process.env.REACT_APP_MAP_THEME_NAME,
  env: process.env.NODE_ENV,
  mobileBaseUrl: process.env.REACT_APP_MOBILE_BASE_URL,
  userLocationStaleTime: process.env.REACT_APP_USER_LOCATION_STALE_TIME,
  appConfigId: process.env.REACT_APP_CONFIG_ID,
  i18nNameSpace: process.env.REACT_APP_I18N_NAME_SPACE,
  liveTrafficApiKey: process.env.REACT_APP_LIVE_TRAFFIC_API_KEY,
  defaultVenueId: process.env.REACT_APP_DEFAULT_VENUE_ID,
  googleAnalyticsTrackingId: process.env.REACT_APP_GTM_TRACKING_ID,
  googleAnalyticsSecretKey: process.env.REACT_APP_GTM_SECRET_KEY,
}

const config = (key, defaultValue = undefined) => {
  return _get(configOption, key, defaultValue)
}

export default config
