import { useCallback, useEffect, useMemo, useState } from "react"
import { AppUIContext } from "./AppUIContext"
import config from "config"
import { useNavigationHistory } from "./hooks"
import { useAppNavigation } from "./hooks/useAppNavigation/useAppNavigation"
import { useVenueController } from "hooks/venue/useVenueController"
import { useIndoorMap } from "providers/venue/modules/indoormap/hooks/useIndoorMap"

export const AppUIProvider = ({ children }) => {
  const { initVenueController } = useVenueController()
  const [openSearchState, setOpenSearchState] = useState(false)
  const [sidebarState, setSidebarState] = useState(false)
  const { mapLoading, mapLoaded: mapReady } = useIndoorMap()

  const { goBack } = useNavigationHistory()
  const {
    navigations,
    mobileNavigations,
    mobileMainNavigations,
    kioskNavigation,
    kioskMainNavigations,
    matchedPath,
  } = useAppNavigation()

  const openSearch = useCallback(() => {
    setOpenSearchState(true)
  }, [])

  const closeSearch = useCallback(() => {
    setOpenSearchState(false)
  }, [])

  const toggleSearch = useCallback(() => {
    setOpenSearchState((prev) => !prev)
  }, [])

  const isKioskMode = useMemo(
    () => ["kiosk-v", "kiosk-h"].includes(config("device")),
    []
  )

  // Disable right click on the screen when application is in kiosk mode
  useEffect(() => {
    if (config("env") === "production" && isKioskMode) {
      window.addEventListener("contextmenu", (event) => event.preventDefault())
    }
  }, [isKioskMode])

  useEffect(() => {
    initVenueController()
  }, [initVenueController])

  const value = {
    openSearchState,
    sidebarState,
    mapReady,
    mapLoading,
    isKioskMode,
    // Navigation
    navigations,
    mobileNavigations,
    mobileMainNavigations,
    kioskNavigation,
    kioskMainNavigations,
    matchedPath,
    // functions
    openSearch,
    closeSearch,
    toggleSearch,
    setOpenSearchState,
    setSidebarState,
    goBack,
  }

  return <AppUIContext.Provider value={value}>{children}</AppUIContext.Provider>
}
