import { useCallback, useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import _ from "lodash"

import { useVenue } from "providers/venue"
import { useGetPromotion } from "providers/venue/modules/data"
import { useTranslation } from "providers/i18n"
import { useGeoLocation } from "providers/geolocation"
import config from "config"
import dayjs from "config/dayjs"

import { getPromotionDetail } from "utils/feature"
import { replaceLineBreak } from "utils/string"
import { getOpeningText } from "utils/time"
import { getDurationDate } from "utils/date"

import { pushDataLayer } from "utils/googletagmanager/pushDataLayer"
import { GTM_VIEW_PROMOTION_DETAIL, GTM_PROMOTION_LOCATION } from "constant"
import { IFeature } from "interfaces"

//TODO:: replace logic in this hook by using useFeatureDetail in future
export const usePromotionDetail = (promotionId) => {
  const { t } = useTranslation()
  const { data: promotionData } = useGetPromotion(promotionId)
  const currentTime = dayjs()
  const navigate = useNavigate()
  const { promotions, findFeatureById } = useVenue()
  const location = useLocation()
  const mobileBaseUrl = config("mobileBaseUrl")
  const { deviceLocation, deviceLocationName } = useGeoLocation()

  const promotionDetailMobileUrl = useMemo(
    () =>
      `${mobileBaseUrl}${location.pathname}?user-location=${
        _.get(deviceLocation, "id") || ""
      }&location-name=${deviceLocationName || ""}`,
    [mobileBaseUrl, deviceLocationName, deviceLocation, location]
  )

  const promotion = useMemo(() => {
    const feature = promotionData as unknown as IFeature
    if (!feature?.properties) return
    const promotionDetail = getPromotionDetail(feature)

    return {
      ...promotionDetail,
      feature,
      descriptionText: replaceLineBreak(t(promotionDetail.description)),
      openingText: getOpeningText(
        promotionDetail.startTime,
        promotionDetail.endTime,
        t
      ),
      durationDate: getDurationDate(
        promotionDetail.startDate,
        promotionDetail.endDate
      ),
    }
  }, [promotionData, t])

  const morePromotions = useMemo(() => {
    const targetCategories = _.get(
      promotion,
      "feature.properties.local_category_ids",
      []
    )
    return _(promotions)
      .filter((promotion) => {
        const { id, properties } = promotion
        const endDate = dayjs(properties?.end_date)
        const promotionCategories = _.get(properties, "local_category_ids", [])
        const isMatchedCategory =
          _.intersection(targetCategories, promotionCategories).length > 0
        return (
          id !== promotionId &&
          isMatchedCategory &&
          currentTime.isBefore(endDate)
        )
      })
      .orderBy(
        [
          "properties.is_featured",
          "properties.start_date",
          "properties.start_end",
        ],
        ["desc", "asc", "asc"]
      )
      .take(4)
      .value()
  }, [currentTime, promotion, promotions, promotionId])

  const navigateToLocation = useCallback(() => {
    if (promotion?.locationId) {
      pushDataLayer({
        event: GTM_PROMOTION_LOCATION,
        promotion_location_name: _.get(
          findFeatureById(promotion?.locationId),
          "properties.name.en"
        ),
      })
      navigate(`/maps/place/${promotion?.locationId}`)
    }
  }, [navigate, promotion?.locationId, findFeatureById])

  const openWebsiteLink = useCallback(
    () =>
      promotion?.websiteLink && window.open(promotion?.websiteLink, "_blank"),
    [promotion?.websiteLink]
  )

  useEffect(() => {
    if (promotion) {
      pushDataLayer({
        event: GTM_VIEW_PROMOTION_DETAIL,
        promotion_id: _.get(promotion, "id"),
        promotion_name: _.get(promotion, "name.en"),
        promotion_category: _.get(promotion, "category") as string,
      })
    }
  }, [promotion])

  return {
    promotion,
    promotionDetailMobileUrl,
    morePromotions,
    navigateToLocation,
    openWebsiteLink,
  }
}
