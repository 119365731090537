import { Box, useTheme } from "@mui/material"
import { Link } from "react-router-dom"

import { homeNavigations } from "standard"

import { useTranslation } from "providers/i18n"
import { useHome } from "hooks/home/useHome"
import { useHomeMap } from "hooks/home/useHomeMap"

import { FullScreenOverlayLayout } from "layouts/FullScreenOverlayLayout"

import { Head } from "components/Head"
import { Carousel, CarouselItem } from "components/Carousel"
import { HeaderLogo } from "components/Header"
import { FullScreenStart } from "extensions/one-siam/components/domains/home/FullScreenStart"

import { FeatureCard } from "extensions/one-siam/components/domains/features/card/FeatureCard"

import { Header } from "extensions/one-siam/components/Header/Header"
import { AppNavigation } from "extensions/one-siam/container/AppNavigation"

export const HomePage = ({ navigations = homeNavigations }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { featuredEventPromotion, venues } = useHome()
  useHomeMap(venues)

  const {
    root,
    contentContainer,
    featureCarouselWrapper,
    headerWrapper,
    navigationWrapper,
    carousel,
    carouselItem,
  } = theme.components[`HomePage`]?.styleOverrides || {}

  return (
    <>
      <Head title={t("Indoor map")} />
      <FullScreenOverlayLayout sx={root}>
        <Box sx={headerWrapper}>
          <Header logo={<HeaderLogo />} />
        </Box>
        <Box sx={contentContainer}>
          <FullScreenStart />
        </Box>
        {featuredEventPromotion.length > 0 && (
          <Box sx={featureCarouselWrapper}>
            <Carousel
              options={{
                gap: theme.spacing(1.25),
                pagination: true,
                focus: "center",
                arrows: false,
                mediaQuery: "min",
                breakpoints: {
                  640: {
                    perPage: 2,
                  },
                  900: {
                    perPage: 3,
                  },
                },
              }}
              sx={carousel}
            >
              {featuredEventPromotion.map((feature) => {
                const { feature_type, id } = feature
                return (
                  <CarouselItem key={id} sx={carouselItem}>
                    {/*
                     * @Example: Event => events/event-id, Promotion => promotions/promotion-id
                     */}
                    <Link to={`${feature_type}s/${id}`}>
                      <FeatureCard feature={feature} />
                    </Link>
                  </CarouselItem>
                )
              })}
            </Carousel>
          </Box>
        )}
        <Box sx={navigationWrapper}>
          <AppNavigation show={false} />
        </Box>
      </FullScreenOverlayLayout>
    </>
  )
}
