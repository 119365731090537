import { useSearchParams } from "react-router-dom"
import camelCase from "lodash/camelCase"

export const useQuery = () => {
  const [searchParams] = useSearchParams()
  const queryString = {}
  for (const entry of searchParams.entries()) {
    const [paramKey, value] = entry
    queryString[camelCase(paramKey)] = value
  }
  return queryString
}
