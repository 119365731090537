import React, { useCallback, useMemo } from "react"
import { Routes, Route } from "react-router-dom"
import { AnimatePresence } from "framer-motion"

import _ from "lodash"

import config from "config"

import { useVenue } from "providers/venue"
import { useAppUI } from "providers/ui"
import { useSponsoredPopupBanner } from "hooks/sponsored-content/useSponsoredPopupBanner"

// Extensions
import {
  getAllExtensionRoutes,
  getExtensionThemeFactories,
  mergeRoutes,
  registerRouteByConfig,
} from "utils/extension"
import { extensionConfigs } from "extensions"

// Theme
import { generateTheme } from "theme/generateTheme"
import { appThemeOptionFactory as directoryThemeOptionFactory } from "theme/app/directory"
import { ThemeProvider } from "providers/mui-extended/ThemeProvider"

// Routes
import { homeNavigations, standardRoutes } from "standard"

// Pages
import { HomePage } from "pages/home"
import { Layout as MapLayout } from "pages/maps/_layout"

// Components, Layouts
import { AppLayout } from "layouts/AppLayout"
import { ScrollToTop } from "components/ScrollToTop"
import { LoadingScreen, MapUpdatingScreen } from "components/LoadingScreen"
import { MotionDiv, showContent } from "components/motions"
import { AdImageCarousel, AdModal } from "components/domains/ads"
import { DEVICE_MOBILE } from "constant"

// Extension routes
const extensionRoutes = getAllExtensionRoutes(
  config("venueExtension"),
  extensionConfigs,
  DEVICE_MOBILE
)

// Merge Standard + Extension routes
const { pageRoutes, mapRoutes, baseRoutes } = mergeRoutes(
  _.get(standardRoutes, DEVICE_MOBILE),
  extensionRoutes
)

// Extension Themes
const extensionThemeConfig = getExtensionThemeFactories(
  config("venueExtension"),
  extensionConfigs
)

// App Theme Factory
const appThemeOptionFactory = _.get(directoryThemeOptionFactory, DEVICE_MOBILE)

export const StandardApp = () => {
  // Generate a venue theme
  const { themeOptions: venueThemeOptions, dataLoaded } = useVenue()
  const { mapReady, mapLoading } = useAppUI()
  const { popupBanners, navigateToSponsoredLink, popupOpen, closePopup } =
    useSponsoredPopupBanner()
  const theme = generateTheme(venueThemeOptions, appThemeOptionFactory, {
    extendThemeFactories: extensionThemeConfig.themeFactories,
    ignoreAppThemeFactory: extensionThemeConfig.ignoreAppThemeFactory,
  })

  const isAppReady = useMemo(
    () => mapReady && dataLoaded,
    [mapReady, dataLoaded]
  )

  const onClickSlide = useCallback(
    (_splide, { slide }, _event) => {
      navigateToSponsoredLink(slide.getAttribute("link"))
      closePopup()
    },
    [navigateToSponsoredLink, closePopup]
  )

  return (
    <ThemeProvider theme={theme}>
      <ScrollToTop />
      <AnimatePresence mode="sync">
        {!isAppReady && <LoadingScreen key="loading-screen" />}
        <MapUpdatingScreen key="map-updating" open={mapLoading} />
        <MotionDiv
          key="content"
          variants={showContent}
          initial="initial"
          animate="animate"
        >
          <Routes>
            <Route path="/" element={<AppLayout />}>
              {baseRoutes.map(registerRouteByConfig)}
              <Route
                index
                element={<HomePage navigations={homeNavigations} />}
              />
              <Route path="/*">
                {/* Add or override specific route extension pages here. */}
                {pageRoutes.map(registerRouteByConfig)}
              </Route>
              <Route path="/maps/*" element={<MapLayout />}>
                {/* The indoorPage parent router treats the route child parameter as the same parameter as its own. */}
                {mapRoutes.map(registerRouteByConfig)}
              </Route>
            </Route>
          </Routes>
        </MotionDiv>
      </AnimatePresence>
      <AdModal open={popupOpen} onClose={closePopup}>
        <AdImageCarousel data={popupBanners} onClickSlide={onClickSlide} />
      </AdModal>
    </ThemeProvider>
  )
}
