import { useCallback, useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Avatar, Box, Button, Typography, useTheme } from "@mui/material"

import { getIcon } from "utils/icon"

import { useOccupantPromotion } from "hooks/promotion/useOccupantPromotion"
import { useSponsoredFooterBanner } from "hooks/sponsored-content/useSponsoredFooterBanner"
import { AppNavigation } from "containers/kiosk-v"

import { useTranslation } from "providers/i18n"
import { useAppUI } from "providers/ui"

import { PromotionCard } from "components/domains/promotions"
import { SearchToggleButton } from "components/domains/search"
import { LanguageButton } from "components/Language"
import { Head } from "components/Head"
import { Header, HeaderLogo, HeaderTitle } from "components/Header"
import { Clock } from "components/Clock"
import { AdMediaFooterCarousel } from "components/domains/ads"
import { PercentIcon, StoreIcon } from "components/icons"
import { Footer } from "components/Footer"
import { EmptyData } from "components/EmptyData"
import { Carousel, CarouselItem } from "components/Carousel"

export const OccupantPromotionKioskVPage = ({ Layout = Box }) => {
  const navigate = useNavigate()
  const { goBack, openSearch } = useAppUI()
  const { occupantId } = useParams()
  const { t } = useTranslation()
  const { footerBanners, footerBannerConfig } = useSponsoredFooterBanner()

  const { occupantName, logo, promotions } = useOccupantPromotion(occupantId)

  const theme = useTheme()
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.promotions) || <PercentIcon />,
    [theme]
  )

  const { styleOverrides } = theme.components?.[`OccupantPromotionPage`] || {}
  const {
    root,
    carouselContainer,
    carousel,
    emptyDataContainer,
    headerContainer,
    headerLogo,
    headerTitle,
    actionButtonGroupContainer,
    button,
    actionButton,
    actionContainer,
    searchBarContainer,
    toolbarContainer,
    navigationWrapper,
  } = styleOverrides

  const handleClickSlide = useCallback(
    (_splide, { slide }, _event) => {
      navigate(slide.getAttribute("link"))
    },
    [navigate]
  )

  return (
    <>
      <Head title={t("Promotions")} />
      <Layout
        header={
          <Header
            logo={<HeaderLogo />}
            title={<HeaderTitle />}
            clock={<Clock />}
          />
        }
        footer={
          <>
            {footerBanners.length > 0 ? (
              <AdMediaFooterCarousel
                data={footerBanners}
                carouselOptions={footerBannerConfig}
              />
            ) : (
              <Footer />
            )}
          </>
        }
      >
        <Box sx={root}>
          <Box sx={headerContainer}>
            <Avatar variant="circular-logo" sx={headerLogo} src={logo}>
              <StoreIcon />
            </Avatar>
            <Typography gutterBottom variant="h5" sx={headerTitle}>
              {t(occupantName)}
            </Typography>
          </Box>
          {!promotions || promotions?.length === 0 ? (
            <Box sx={emptyDataContainer}>
              <EmptyData
                icon={emptyIcon}
                primary={t("Coming soon")}
                secondary={t("Check Back Soon")}
              />
            </Box>
          ) : (
            <>
              <Box sx={carouselContainer}>
                {promotions && promotions.length > 0 ? (
                  <Carousel
                    onClick={handleClickSlide}
                    options={{
                      type: "loop",
                      pagination: true,
                      focus: "center",
                      autoplay: true,
                      gap: "0",
                    }}
                    sx={carousel}
                  >
                    {promotions.map((promotion) => {
                      const sponsoredId =
                        promotion?.properties?.sponsoredId || ""
                      return (
                        <CarouselItem
                          link={`/promotions/${promotion.id}`}
                          key={`${sponsoredId}${promotion.id}`}
                        >
                          <PromotionCard promotion={promotion} />
                        </CarouselItem>
                      )
                    })}
                  </Carousel>
                ) : (
                  ""
                )}
              </Box>
            </>
          )}
          <Box sx={actionButtonGroupContainer}>
            <Button sx={button} variant="outlined" onClick={goBack}>
              {t("Back")}
            </Button>
          </Box>
          <Box key="app-navigation" sx={navigationWrapper}>
            <Box sx={toolbarContainer}>
              <Box sx={actionContainer}>
                <LanguageButton variant="contained" sx={actionButton} />
                {/* //TODO:: Add a wheel chair's mode handler function later */}
                {/*  <Button
            variant="contained"
            sx={actionButton}
            onClick={() => {}}
            startIcon={<RestroomWheelchairIcon />}
          /> */}
              </Box>
              <Box sx={searchBarContainer}>
                <SearchToggleButton
                  placeholder={t("Search shops and more...")}
                  onClick={openSearch}
                />
              </Box>
            </Box>
            <AppNavigation />
          </Box>
        </Box>
      </Layout>
    </>
  )
}
