const days = ["mo", "tu", "we", "th", "fr", "sa", "su"]

const parseNormalOpening = (openingText) => {
  let result = {}
  for (let k = 0; k < days.length; k++) {
    result[days[k]] = null
  }
  let modified_some_days = false
  /* eslint-disable no-useless-escape */
  const dayregex = /^(mo|tu|we|th|fr|sa|su)\-?(mo|tu|we|th|fr|sa|su)?$/
  const timeregex = /^\s*(\d\d:\d\d)\-(\d\d:\d\d)\s*$/
  const dayranges = openingText.toLowerCase().split(/\s*;\s*/)
  let dayrange
  while ((dayrange = dayranges.shift())) {
    let daytimes = dayrange.trim().split(/\s+/),
      daytime,
      startday = 0,
      endday = 6,
      starttime,
      endtime
    while ((daytime = daytimes.shift())) {
      if (dayregex.test(daytime)) {
        const daymatches = daytime.match(dayregex)

        if (daymatches.length === 3) {
          startday = days.indexOf(daymatches[1])
          if (daymatches[2]) {
            endday = days.indexOf(daymatches[2])
          } else {
            endday = startday
          }
        } else {
          return null
        }
      } else if (timeregex.test(daytime)) {
        const timematches = daytime.match(timeregex)

        if (timematches.length === 3) {
          starttime = timematches[1]
          endtime = timematches[2]
        } else {
          return null
        }
      } else {
        return null
      }
    }

    for (var j = startday; j <= endday; j++) {
      if (!starttime || !endtime) continue
      result[days[j]] = [starttime, endtime]
      modified_some_days = true
    }

    if (!modified_some_days) {
      result = null
    }
    return result
  }
}

;(function (exports) {
  exports.compute = function (text) {
    let result = null
    switch (text) {
      case "":
      case null:
        result = null
        break
      case "24/7":
        result = { "24/7": true }
        break
      case "seasonal":
        result = { seasonal: true }
        break
      default: {
        result = parseNormalOpening(text, result)
      }
    }
    return result
  }
})(typeof exports === "undefined" ? (this["jsopeninghours"] = {}) : exports)
