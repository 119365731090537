import openingHour from "utils/opening_hours"
import dayjs from "config/dayjs"
import _ from "lodash"
import { Dayjs, ManipulateType, OpUnitType, QUnitType } from "dayjs"

export const days = ["mo", "tu", "we", "th", "fr", "sa", "su"]

interface IOpeningHours {
  mo?: [Dayjs, Dayjs]
  tu?: [Dayjs, Dayjs]
  we?: [Dayjs, Dayjs]
  th?: [Dayjs, Dayjs]
  fr?: [Dayjs, Dayjs]
  sa?: [Dayjs, Dayjs]
  su?: [Dayjs, Dayjs]
}

type ParseHoursToDayjs = (hours: string) => Dayjs | null

type ParseDayjsToHours = (date: Dayjs) => string | undefined

type GetCurrentDay = () => string

type CreateOpeningHoursObj = (osmDate: string | null) => IOpeningHours | null

type FindTodayOpeningHours = (openingHours: IOpeningHours) => {
  day: string
  open?: string
  close?: string
}

type FindNextOpeningTime = (openingHours: IOpeningHours) => {
  day: string
  open?: string
}

type IsOpen = (openingHours: IOpeningHours) => boolean

// May change if v is changed to ...DateSpan
type GetTimeRelativeToSpanOfDates = (startDate: Dayjs, endDate: Dayjs) => string

// GetSpanOfDates [OKAY, ASK COUNCIL (GetDateSpan?)]
type GetDurationDate = (
  startDate: Dayjs,
  endDate: Dayjs,
  options?: { dayFormat?: string; monthFormat?: string }
) => string

type GetDisplayDateTime = (startDate: Dayjs, endDate: Dayjs) => string

type GetCurrentDateISOString = () => string

type IsBeforeDate = (mainDate: string, comparingDate: string | null) => boolean

type AddDurationToDate = (
  dateISOString: string,
  duration: number,
  unit?: ManipulateType
) => string

type CalculateDateDifference = (
  mainDateISOString: string,
  comparisonDateISOString: string,
  unit?: QUnitType | OpUnitType
) => number

const OPEN_TEXT = "Open"
const CLOSE_TEXT = "Close"

const parseHoursToDayjs: ParseHoursToDayjs = (hours) => {
  if (!hours) return null
  const [hour, minute, second] = hours.split(":").map(_.toNumber)
  return dayjs()
    .set("hour", hour)
    .set("minute", minute || 0)
    .set("second", second || 0)
}

const parseDayjsToHours: ParseDayjsToHours = (date) => {
  return date?.format("HH:mm")
}

export const getCurrentDay: GetCurrentDay = () => {
  return days[dayjs().get("day")]
}

export const createOpeningHoursObj: CreateOpeningHoursObj = (osmDate) => {
  try {
    const openingHours = openingHour.compute(osmDate)
    if (_.isNull(openingHours)) return null
    return days.reduce(
      (acc, day) => ({
        [day]:
          openingHours[day]?.map((hour: string) => parseHoursToDayjs(hour)) ||
          null,
        ...acc,
      }),
      {}
    )
  } catch (error) {
    return null
  }
}

export const findTodayOpeningHours: FindTodayOpeningHours = (openingHours) => {
  const currentTime = dayjs()
  const currentDateIdx = currentTime.get("day")
  const currentDay = days[currentDateIdx]

  // Check if current time is before today's opening
  if (_.get(openingHours, currentDay)) {
    return {
      day: _.capitalize(currentDay),
      open: parseDayjsToHours(openingHours[currentDay][0]),
      close: parseDayjsToHours(openingHours[currentDay][1]),
    }
  }
}

export const findNextOpeningTime: FindNextOpeningTime = (openingHours) => {
  const currentTime = dayjs()
  const currentDateIdx = currentTime.get("day")
  const currentDay = days[currentDateIdx]
  const currentDayopeningHours = _.get(openingHours, currentDay)
  // Check if current time is before today's opening
  if (
    currentDayopeningHours &&
    currentTime.isBefore(currentDayopeningHours[0])
  ) {
    return {
      day: _.capitalize(currentDay),
      open: parseDayjsToHours(currentDayopeningHours[0]),
    }
  }

  const newDays = days
    .slice(currentDateIdx, days.length)
    .concat(days.slice(0, currentDateIdx))

  const nextOpenDay = newDays.find((day) => _.get(openingHours, day))
  // Next Opening Day
  return {
    day: _.capitalize(nextOpenDay),
    open: parseDayjsToHours(openingHours[nextOpenDay][0]),
  }
}

export const isOpen: IsOpen = (openingHours) => {
  const currentTime = dayjs()
  const dayIdx = currentTime.get("day")

  const dateShortName = days[dayIdx]
  //   If not available in osm that day it is closed
  if (!_.get(openingHours, dateShortName)) {
    return false
  }
  const [openOn, closeOn] = openingHours[dateShortName]

  return dayjs().isBefore(closeOn) && dayjs().isAfter(openOn)
}

// Used in cases when startDate and endDate occurs on the same day
export const getTimeRelativeToSpanOfDates: GetTimeRelativeToSpanOfDates = (
  startDate,
  endDate
) => {
  return dayjs().isAfter(startDate)
    ? `Ending ${dayjs().to(dayjs(endDate))}`
    : `Starting ${dayjs(startDate).fromNow()}`
}

export const getDurationDate: GetDurationDate = (
  startDate,
  endDate,
  options
) => {
  if (!startDate || !endDate) return
  const { dayFormat = "DD", monthFormat = "MMM" } = options || {
    dayFormat: "DD",
    monthFormat: "MMM",
  }
  if (
    endDate.year() > startDate.year() ||
    endDate.month() > startDate.month()
  ) {
    const format = `${dayFormat} ${monthFormat}`
    return `${startDate.format(format)} - ${endDate.format(format)}`
  }

  return `${startDate.format(dayFormat)} - ${endDate.format(
    `${dayFormat} ${monthFormat}`
  )}`
}

export const getDisplayDateTime: GetDisplayDateTime = (startDate, endDate) => {
  return dayjs(endDate).isToday()
    ? getTimeRelativeToSpanOfDates(startDate, endDate)
    : getDurationDate(startDate, endDate)
}

export const getCurrentDateISOString: GetCurrentDateISOString = () => {
  return dayjs().toISOString()
}

export const isBeforeDate: IsBeforeDate = (mainDate, comparingDate) => {
  return dayjs(mainDate).isBefore(comparingDate)
}

export const addDurationToDate: AddDurationToDate = (
  dateISOString,
  duration,
  unit = "millisecond"
) => {
  const currentTime = dayjs(dateISOString)
  const expireTime = currentTime.add(duration, unit)
  return expireTime.toISOString()
}

export const calculateDateDifference: CalculateDateDifference = (
  mainDateISOString,
  comparisonDateISOString,
  unit = "millisecond"
) => {
  // Define two date objects
  const mainDate = dayjs(mainDateISOString)
  const comparisonDate = dayjs(comparisonDateISOString)

  // Calculate the difference between the two dates
  return comparisonDate.diff(mainDate, unit)
}

export const createOpeningHours = (hours: string) => {
  const openingHours = createOpeningHoursObj(hours)

  if (_.isNull(openingHours)) return { isValid: false }

  const isOpening = isOpen(openingHours)
  const { close, open } = findTodayOpeningHours(openingHours) || {}
  const { day } = findNextOpeningTime(openingHours)
  const currentDay = getCurrentDay()
  const dayToOpen = day.toLowerCase() === currentDay ? "" : day
  const openingStatus = isOpening ? OPEN_TEXT : CLOSE_TEXT
  const nextOpeningStatus =
    openingStatus === CLOSE_TEXT ? OPEN_TEXT : CLOSE_TEXT

  return {
    openingStatus,
    nextOpeningStatus,
    dayToOpen,
    isOpening,
    close,
    open,
    isValid: true,
  }
}
