import { useEffect } from "react"
import { featureCollection } from "@turf/helpers"
import { get } from "lodash"

import { useVenue } from "providers/venue"
import { useIndoorMap } from "providers/venue/modules/indoormap/hooks/useIndoorMap"
import { VenueData } from "providers/venue/types"

import { getSuitablyValueBetweenBearings } from "components/IndoorMap"
import { HOMEPAGE_ANIMATION } from "constant"

export const useHomeMap = (venues: VenueData[]) => {
  const { dataLoaded, mapConfig } = useVenue()
  const { indoorRef, mapLoaded, defaultBearing } = useIndoorMap()

  const mapReady = indoorRef?.current && mapLoaded && dataLoaded

  useEffect(() => {
    const indoorMap = indoorRef.current
    if (mapReady && indoorMap) {
      indoorMap.showVenueObjects()
      const currentBearing = indoorMap.getBearing()
      const bearing = getSuitablyValueBetweenBearings(
        defaultBearing,
        currentBearing
      )
      const venuesCollection = featureCollection(venues)

      const extent = indoorMap.getFeatureExtent(venuesCollection)

      const center = indoorMap.getExtentCenter(extent)
      const zoom = indoorMap.getExtentZoom(extent)
      const animationSpeed = get(
        mapConfig,
        `animation.${HOMEPAGE_ANIMATION}.speed`,
        0.3
      )

      indoorMap.flyTo(center, {
        zoom,
        bearing,
      })
      indoorMap.clearAnimations()
      indoorMap.addAnimations({
        id: HOMEPAGE_ANIMATION,
        callback: () => {
          const currentBearing = indoorMap.getBearing()
          const newBearing = (currentBearing + animationSpeed) % 360
          indoorMap.setBearing(newBearing)
        },
      })
      indoorMap.changeLevelByOrdinal(0)
      indoorMap.clearHighlightElements()
      indoorMap.clearHighlightObject()
      return () => {
        indoorMap.removeAnimationById(HOMEPAGE_ANIMATION)
      }
    }
  }, [defaultBearing, indoorRef, mapReady, mapConfig, venues])
}
