import { useCallback, useMemo, useState } from "react"
import {
  Box,
  Stepper,
  Typography,
  FormControlLabel,
  Paper,
  Switch,
  useTheme,
  Fab,
  Slide,
  Backdrop,
  CircularProgress,
} from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useTranslation } from "providers/i18n"
import { Trans } from "react-i18next"

import { useVenue } from "providers/venue"
import { OrdinalSelector } from "components/OrdinalSelector"
import { OriginMarkerIcon } from "components/icons"
import {
  DirectionSummary,
  DirectionStepItem,
  DirectionCardActions,
} from "components/domains/direction"
import { DestinationMarkerIcon } from "../../../../../components/icons"
import { useDirectionOverview } from "hooks/direction/useDirectionOverview"
import { useDirectionOverviewMap } from "hooks/direction/useDirectionOverviewMap"
import { useAppUI } from "providers/ui"
import { getIcon } from "utils/icon"

export const DirectionOverviewKioskVPage = () => {
  const [open, setOpen] = useState(false)
  const { isMultiOrdinalVenue } = useVenue()

  const {
    isCalculatingRoute,
    steps,
    duration,
    distance,
    currentOrdinal,
    originData,
    originName,
    originLevelName,
    destinationData,
    destinationName,
    destinationLevelName,
    elevatorPreferredMode,
    relatedLevelWithSteps,
    route,
    changeOrdinal,
    toggleElevatorPreference,
    startNavigate,
  } = useDirectionOverview()

  const stepsAvailable = steps && steps.length > 0

  useDirectionOverviewMap(route, currentOrdinal, {
    offset: { bottom: 411 },
    enabled: !!stepsAvailable,
  })

  const { t } = useTranslation()

  const { goBack } = useAppUI()

  const theme = useTheme()

  const { paper, formControlLabel } =
    theme.components[`AppDirectionSearch`]?.styleOverrides || {}
  const {
    actionButtonWrapper,
    container,
    ordinalSelectorWrapper,
    stepper,
    stepOverviewContainer,
    wrapper,
  } = theme.components[`AppDirectionOverview`]?.styleOverrides || {}

  const { originIcon, destinationIcon, highlightedText } =
    theme.components[`DirectionStep`]?.styleOverrides || {}

  const originFloorText = isMultiOrdinalVenue
    ? `(${t("At Floor", {
        value: originLevelName,
      })})`
    : ""
  const destinationFloorText = isMultiOrdinalVenue
    ? `(${t("At Floor", {
        value: destinationLevelName,
      })})`
    : ""
  const highlightedStepText = (
    <Typography variant="subTitle" component="span" sx={highlightedText} />
  )

  const originIconComp = useMemo(
    () =>
      getIcon(theme?.assets?.navigation?.origin, originIcon) || (
        <OriginMarkerIcon sx={originIcon} />
      ),
    [theme, originIcon]
  )

  const destinationIconComp = useMemo(
    () =>
      getIcon(theme?.assets?.navigation?.destination, destinationIcon) || (
        <DestinationMarkerIcon sx={destinationIcon} />
      ),
    [theme, destinationIcon]
  )

  const handleClickBack = useCallback(() => {
    goBack()
  }, [goBack])

  const handleCloseDrawer = useCallback(() => {
    setOpen(false)
  }, [])

  const backIcon = useMemo(
    () => getIcon(theme?.assets?.backIcon) || <ArrowBackIcon />,
    [theme]
  )

  const handleClickStep = useCallback(() => setOpen((prev) => !prev), [])
  return (
    <>
      <Backdrop open={isCalculatingRoute}>
        <CircularProgress />
      </Backdrop>
      {stepsAvailable && (
        <>
          <Slide
            direction="up"
            in={stepsAvailable && !open}
            mountOnEnter
            unmountOnExit
          >
            <Box sx={container}>
              <Box sx={ordinalSelectorWrapper}>
                <OrdinalSelector
                  levels={relatedLevelWithSteps}
                  onClickOrdinal={changeOrdinal}
                  selectedOrdinal={currentOrdinal}
                />
              </Box>
              <Box sx={actionButtonWrapper}>
                <Fab variant="rounded" onClick={handleClickBack}>
                  {backIcon}
                </Fab>
                <Paper elevation={1} sx={paper}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={elevatorPreferredMode}
                        onChange={toggleElevatorPreference}
                      />
                    }
                    label={t("Avoid Steps")}
                    componentsProps={{ typography: { variant: "body2" } }}
                    sx={formControlLabel}
                  />
                </Paper>
              </Box>
              <Paper sx={{ width: "100%" }}>
                <Box sx={wrapper}>
                  <DirectionSummary
                    primary={t("Travel", {
                      duration,
                      distance,
                    })}
                    secondary={
                      <Trans
                        components={{
                          origin: highlightedStepText,
                          destination: highlightedStepText,
                        }}
                      >
                        {t("Origin To Destination", {
                          origin: `${t(originName)} ${originFloorText}`,
                          destination: `${t(
                            destinationName
                          )} ${destinationFloorText}`,
                        })}
                      </Trans>
                    }
                  />
                  <DirectionCardActions
                    onStart={startNavigate}
                    onStep={handleClickStep}
                  />
                </Box>
              </Paper>
            </Box>
          </Slide>

          <Slide direction="up" in={open} mountOnEnter unmountOnExit>
            <Box sx={container}>
              <Box sx={actionButtonWrapper}>
                <Fab variant="rounded" onClick={handleCloseDrawer}>
                  <ArrowBackIcon />
                </Fab>
              </Box>
              <Paper sx={{ width: "100%" }}>
                <Box sx={stepOverviewContainer}>
                  <Stepper sx={stepper} orientation="vertical" connector={null}>
                    {originData && (
                      <DirectionStepItem
                        primaryLabel={
                          <Trans
                            components={{
                              location: highlightedStepText,
                            }}
                          >
                            {isMultiOrdinalVenue
                              ? t("Location Floor", {
                                  location: t(originName),
                                  level: originData.level,
                                })
                              : t(originName)}
                          </Trans>
                        }
                        secondaryLabel={t("Your last scanned location")}
                        stepLabelProps={{
                          icon: originIconComp,
                        }}
                      />
                    )}
                    {steps.map((step, index) => {
                      const { description } = step
                      return (
                        <DirectionStepItem
                          index={index}
                          navigateTo={`navigate/steps/${index + 1}`}
                          primaryLabel={
                            <Trans
                              components={{
                                category: highlightedStepText,
                                destination: highlightedStepText,
                                landmark: highlightedStepText,
                              }}
                            >
                              {t(description.text, {
                                category: t(description.options?.category),
                                destination: t(
                                  description.options?.destination
                                ),
                                landmark: t(description.options?.landmark),
                              })}
                            </Trans>
                          }
                          key={`step-description-${index}`}
                        />
                      )
                    })}
                    {destinationData && (
                      <DirectionStepItem
                        primaryLabel={
                          <Trans
                            components={{
                              location: highlightedStepText,
                            }}
                          >
                            {isMultiOrdinalVenue
                              ? t("Location Floor", {
                                  location: t(destinationName),
                                  level: destinationData.level,
                                })
                              : t(destinationName)}
                          </Trans>
                        }
                        secondaryLabel={t("Your Destination")}
                        stepLabelProps={{
                          icon: destinationIconComp,
                        }}
                      />
                    )}
                  </Stepper>
                </Box>
              </Paper>
            </Box>
          </Slide>
        </>
      )}
    </>
  )
}
