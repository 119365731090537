type ReplaceLineBreak = (string: string, replaceWith?: string) => string

export const replaceLineBreak: ReplaceLineBreak = (
  string,
  replaceWith = "<br/>"
) => {
  return string && string.split("\n").join(replaceWith)
}

export const pluralize = (word: string): string => {
  if (word.substr(-1, 1) === "y") return word.replace(/y$/, "ies")
  return `${word}s`
}
