import {
  Box,
  IconButton,
  InputAdornment,
  InputBase,
  useTheme,
  ToggleButtonGroup,
} from "@mui/material"
import { deepmerge } from "@mui/utils"
import { useTranslation } from "providers/i18n"
import { SearchIcon, CloseIcon as CrossIcon } from "components/icons"

import { SearchBasePopup } from "components/domains/search/SearchBasePopup"
import { SearchResult } from "components/domains/search/SearchResult"

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import { SearchKeyboard } from "./SearchKeyboard"
import { HorizontalScrollPane } from "components/HorizontalScrollPane"

import { CategoryFilterChip } from "components/CategoryFilterBar/index.ts"
import { CustomIcon } from "components/icons"
import StarIcon from "@mui/icons-material/Star"
import { getFeatureName } from "utils/feature"
import { AdSuggestList } from "components/domains/ads"

export const SearchPopupContent = ({
  openSearchState,
  onClose,
  popupType,
  onChange,
  onChangeKeyboard,
  onSelectResult,
  onClickSuggestedItem,
  searchResultItems,
  searchInputRef,
  keyboardRef,
  showOnscreenKeyboard,
  searchValue,
  inputProps = {},
  closeButtonProps = {},
  closeIconProps = {},
  searchBasePopupProps = {},
  closeIcon: CloseIcon = KeyboardBackspaceIcon,
  searchKeyboardProps = {},
  searchResultProps = {},
  clearSearchValue,
  onClickClearSearchValue,
  categories = [],
  suggestedListItems = [],
  onClickCategory = () => {},
}) => {
  const { sx: closeButtonSxProps } = closeButtonProps
  const { sx: closeIconSxProps } = closeIconProps
  const { sx: inputSxProps } = inputProps
  const { t } = useTranslation({ defaultValue: null })
  // Theme & Styling
  const theme = useTheme()
  const searchBarStyles =
    theme.components[`VuiSearchPopupContent`]?.styleOverrides || {}
  const categoryQuickSearchStyles =
    theme.components[`CategoryQuickSearch`]?.styleOverrides || {}

  return (
    <SearchBasePopup
      {...searchBasePopupProps}
      open={openSearchState}
      onClose={onClose}
      type={popupType}
    >
      {/* SearchBar */}
      <Box
        sx={searchBarStyles.root}
        className={`popupType-${popupType} VuiSearchPopupContent-searchBar`}
      >
        <IconButton
          {...closeButtonProps}
          className="VuiSearchPopupContent-closeButton"
          sx={deepmerge(searchBarStyles.closeButton, closeButtonSxProps)}
          onClick={onClose}
        >
          <CloseIcon
            {...closeIconProps}
            sx={deepmerge(searchBarStyles.closeButtonIcon, closeIconSxProps)}
          />
        </IconButton>
        <InputBase
          className={`popupType-${popupType} VuiSearchPopupContent-searchBarInput`}
          fullWidth
          placeholder={t("Search")}
          onChange={onChange}
          inputRef={searchInputRef}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <CrossIcon onClick={onClickClearSearchValue} />
            </InputAdornment>
          }
          {...inputProps}
          sx={deepmerge(searchBarStyles.searchInput, inputSxProps)}
        />
      </Box>
      {/* End of SearchBar */}

      {/* SearchResult + Suggested Brand*/}
      <SearchResult
        {...searchResultProps}
        startAdornment={
          suggestedListItems.length > 0 &&
          !searchValue && (
            <AdSuggestList
              sx={searchBarStyles.suggestedList}
              suggestedListItems={suggestedListItems}
              onClickSuggestedItem={onClickSuggestedItem}
            />
          )
        }
        popupType={popupType}
        items={searchResultItems}
        onSelect={onSelectResult}
      />
      {/* End of SearchResult + Sugguested Brand */}

      {/* Category Quick Search */}
      <Box
        sx={categoryQuickSearchStyles.categoryFilterBarContainer}
        className={`popupType-${popupType} VuiSearchPopupContent-categoryFilterBar`}
      >
        <HorizontalScrollPane
          stackProps={{
            sx: categoryQuickSearchStyles.scrollPane,
          }}
        >
          <ToggleButtonGroup
            sx={categoryQuickSearchStyles.categoryFilterBar}
            exclusive
            variant="borderSeperate"
          >
            {categories.map((category) => (
              <CategoryFilterChip
                className={`${
                  category?.properties?.is_featured ? "highlight" : ""
                }`}
                value={category.id}
                key={`category-filter-${category.id}`}
                Icon={
                  category?.properties?.is_featured ? (
                    <StarIcon />
                  ) : (
                    <CustomIcon path={category.properties?.icon_path} />
                  )
                }
                onClick={() => onClickCategory(category)}
                sx={categoryQuickSearchStyles.categoryFilterChip}
              >
                {t(getFeatureName(category))}
              </CategoryFilterChip>
            ))}
          </ToggleButtonGroup>
        </HorizontalScrollPane>
      </Box>
      {/* End of Category Quick Search */}

      {showOnscreenKeyboard && (
        <SearchKeyboard
          {...searchKeyboardProps}
          keyboardRef={keyboardRef}
          value={searchValue}
          onChange={onChangeKeyboard}
        />
      )}
    </SearchBasePopup>
  )
}
