import React, { useMemo } from "react"
import {
  Box,
  Dialog,
  InputAdornment,
  InputBase,
  Slide,
  ToggleButtonGroup,
  useTheme,
} from "@mui/material"

import { useSearchContainer } from "hooks/search/useSearchContainer"
import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import { useGeoLocation } from "providers/geolocation"

import { getIcon } from "utils/icon"
import { getFeatureName } from "utils/feature"

import { SearchIcon } from "components/icons"
import { CategoryFilterButton } from "components/CategoryFilterBar"
import { HorizontalScrollPane } from "components/HorizontalScrollPane"
import { VenueSelector } from "components/VenueSelector"
import { SearchResult } from "components/domains/search"
import { AdSuggestList } from "components/domains/ads"
import { BackIconButton } from "components/Button"

import { Header } from "extensions/one-siam/components/Header"
import { CancelRoundedIcon } from "extensions/one-siam/components/icons"
import { PageLayout } from "extensions/one-siam/layouts/PageLayout"
import { MotionDiv, showContent } from "components/motions"

const SlideInRightTransition = React.forwardRef(function Transition(
  props,
  ref
) {
  return <Slide direction="left" ref={ref} {...props} />
})

export const SearchContainer = ({ Layout = PageLayout }) => {
  const { t } = useTranslation()
  //TODO:: move to new hook
  const { locationVenue } = useGeoLocation()
  const {
    //  isMultiVenueProject,
    venues,
    defaultVenue,
  } = useVenue()
  const locationVenueId = locationVenue?.id || defaultVenue?.id

  const theme = useTheme()
  const {
    dialog,
    // Search Result
    root,
    listContainer,
    // Toolbar
    toolbarContainer,
    categoryFilterBarContainer,
    scrollPane,
    categoryFilterBar,
    categoryFilterButtonGroup,
    categoryFilterButton,
    searchBarContainer,
    searchInput,
    suggestedList,
    headerWrapper,
    navigationWrapper,
  } = theme.components[`SearchContainer`]?.styleOverrides || {}

  const searchIcon = useMemo(
    () => getIcon(theme?.assets?.search) || <SearchIcon />,
    [theme]
  )

  const {
    searchInputRef,
    searchResultItems,
    venueFilter,
    sortedParentCategoryWithOccupant,
    suggestedListItems,
    searchValue,
    openSearchState,
    onClose,
    onChange,
    onClickCategory,
    onSelectResult,
    onClickClearSearchValue,
    setVenueFilter,
    onClickSuggestedItem,
  } = useSearchContainer({ initialVenueFilter: locationVenueId })

  return (
    <Dialog
      fullScreen
      open={openSearchState}
      onClose={onClose}
      title={t("Menu")}
      TransitionComponent={SlideInRightTransition}
      sx={dialog}
    >
      <Layout
        header={
          <Box sx={headerWrapper}>
            <Header
              title={t("Search")}
              backButton={<BackIconButton onClick={onClose} />}
            />
          </Box>
        }
      >
        <MotionDiv
          sx={root}
          variants={showContent}
          initial="initial"
          animate="animate"
        >
          <Box sx={toolbarContainer}>
            <Box sx={searchBarContainer}>
              <InputBase
                fullWidth
                placeholder={t("Search shops and more...")}
                onChange={onChange}
                inputRef={searchInputRef}
                startAdornment={
                  <InputAdornment position="start">{searchIcon}</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <CancelRoundedIcon onClick={onClickClearSearchValue} />
                  </InputAdornment>
                }
                sx={searchInput}
              />
            </Box>
            <Box sx={categoryFilterBarContainer}>
              <HorizontalScrollPane
                sx={categoryFilterBar}
                stackProps={{
                  sx: scrollPane,
                }}
              >
                <ToggleButtonGroup
                  sx={categoryFilterButtonGroup}
                  exclusive
                  variant="borderSeperate"
                >
                  {sortedParentCategoryWithOccupant.map((category) => (
                    <CategoryFilterButton
                      className={`${
                        category?.properties?.is_featured ? "highlight" : ""
                      }`}
                      value={category.id}
                      key={`category-filter-${category.id}`}
                      Icon={null}
                      onClick={() => onClickCategory(category)}
                      sx={categoryFilterButton}
                    >
                      {t(getFeatureName(category))}
                    </CategoryFilterButton>
                  ))}
                </ToggleButtonGroup>
              </HorizontalScrollPane>
            </Box>
          </Box>
          <Box sx={listContainer}>
            {/* //TODO:: update research result style later */}
            <SearchResult
              startAdornment={
                suggestedListItems.length > 0 &&
                !searchValue && (
                  <AdSuggestList
                    sx={suggestedList}
                    suggestedListItems={suggestedListItems}
                    onClickSuggestedItem={onClickSuggestedItem}
                  />
                )
              }
              items={searchResultItems}
              onSelect={onSelectResult}
            />
          </Box>
        </MotionDiv>
        <Box sx={navigationWrapper}>
          <VenueSelector
            selectedVenue={venueFilter}
            venues={venues}
            onClickVenue={setVenueFilter}
            currentVenue={locationVenueId}
          />
        </Box>
      </Layout>
    </Dialog>
  )
}
