import { deepmerge } from "@mui/utils"
import { createTheme } from "@mui/material"
import _ from "lodash"

import { defaultThemeOptions } from "./default.theme"
import { venueMobileUIThemeOptionFactory } from "./venue-mobile-ui-theme"
import { venueKioskVUIThemeOptionFactory } from "./venue-kiosk-v-ui-theme"
import { venueKioskHUIThemeOptionFactory } from "./venue-kiosk-h-ui-theme"
import config from "../config"

const DEVICE_UI_THEME_OPTION_FACTORIES = {
  mobile: venueMobileUIThemeOptionFactory,
  "kiosk-v": venueKioskVUIThemeOptionFactory,
  "kiosk-h": venueKioskHUIThemeOptionFactory,
}

/**
 *
 * @param {*} venueThemeOptions
 * @param {*} appThemeOptionFactory App Specific themeOption factory
 * @param {*} options // { extendThemeFactories: [], ignoreAppThemeFactory: boolean }
 * @returns
 */

const DEFAULT_OPTIONS = {
  extendThemeFactories: [],
  // When 'ignoreAppThemeFactory' is set to True, the merging of 'appThemeFactory' will be ignored.
  ignoreAppThemeFactory: false,
}

export const generateTheme = (
  venueThemeOptions,
  appThemeOptionFactory,
  extendThemeOptions = DEFAULT_OPTIONS
) => {
  const { extendThemeFactories, ignoreAppThemeFactory } = _.merge(
    DEFAULT_OPTIONS,
    extendThemeOptions
  )

  // # Step 1: Create CoreThemeOptions (palette, typography, shadows, etc) by combining venue's themeOption with defaultCore.
  const { components: CoreThemeComponents = {}, ...CoreThemeOptions } =
    deepmerge(defaultThemeOptions, venueThemeOptions)
  let theme = createTheme(CoreThemeOptions)

  // # Step 2: Add Base VenueUI themes for mobile, Vertical Kiosk (kiosk-v), and Horizontal Kiosk (kiosk-h) devices.
  const deviceThemeOptionFactory = _.get(
    DEVICE_UI_THEME_OPTION_FACTORIES,
    config("device")
  )
  if (!_.isNil(deviceThemeOptionFactory))
    theme = createTheme(theme, deviceThemeOptionFactory(theme))

  // # Step 3: Add App theme.
  if (!ignoreAppThemeFactory && !_.isNil(appThemeOptionFactory)) {
    theme = createTheme(theme, appThemeOptionFactory(theme))
  }

  // # Step 4: Add extension themes.
  if (!_.isEmpty(extendThemeFactories) && _.isArray(extendThemeFactories)) {
    for (const themeFactory of extendThemeFactories) {
      theme = createTheme(theme, themeFactory(theme))
    }
  }

  // # Step 5: Add Core Theme Components (Ensure that the venue's override component theme always applies.)
  theme = createTheme(theme, { components: CoreThemeComponents })
  return theme
}
