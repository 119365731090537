import React, { useCallback, useEffect } from "react"
import { useTheme, Box } from "@mui/material"
import _ from "lodash"

import { pushDataLayer } from "utils/googletagmanager/pushDataLayer"
import { GTM_AMENITY_SEARCH } from "constant"
import { pluralize } from "utils/string"

import { useVenue } from "providers/venue"
import { useMap } from "hooks/map/useMap"
import { useHighLightFeaturesMap } from "hooks/feature/useHighLightFeaturesMap"

import { FeatureChipToggleBar } from "components/domains/features/ChipToggleBar"
import { HeaderLogo } from "components/Header"

import { AppNavigation } from "extensions/one-siam/container/AppNavigation"
import {
  Header,
  HeaderMenuButton,
  HeaderSearchButton,
} from "extensions/one-siam/components/Header"
import { OrdinalSelector } from "extensions/one-siam/components/OrdinalSelector"

export const FeatureByCategory = ({ Layout = Box }) => {
  const { isMultiOrdinalVenue, dataLoaded } = useVenue()

  const {
    category,
    viewingOrdinal,
    viewingVenueId,
    levelInVenue,
    quickSearchFeatures,
    quickSearchCategories,
    searchParams,
    comingFromOrdinal,
    findNearestOrdinal,
    setSearchParams,
    toggleQuickSearch,
  } = useMap()

  const enableMap =
    !_.isNil(viewingOrdinal) && _.isNil(comingFromOrdinal) && dataLoaded

  const handleClickQuickSearch = useCallback(
    (featureType, category) => {
      const pluralType = pluralize(featureType)
      toggleQuickSearch(pluralType, category)
    },
    [toggleQuickSearch]
  )

  const onClickOrdinal = useCallback(
    (ordinal) => {
      const currentParams = Object.fromEntries(searchParams.entries())
      setSearchParams({ ...currentParams, ordinal })
    },
    [setSearchParams, searchParams]
  )

  useEffect(() => {
    // When ordinal navigate from different page
    if (viewingVenueId && dataLoaded && !_.isNil(comingFromOrdinal)) {
      const nearestOrdinal = findNearestOrdinal(comingFromOrdinal)
      setSearchParams(
        { venue: viewingVenueId, ordinal: nearestOrdinal },
        { replace: true }
      )
    } else if (viewingVenueId && dataLoaded && _.isNil(viewingOrdinal)) {
      // When ordinal is not provided
      const nearestOrdinal = findNearestOrdinal(0)
      setSearchParams(
        { venue: viewingVenueId, ordinal: nearestOrdinal },
        { replace: true }
      )
    }
  }, [
    dataLoaded,
    category,
    comingFromOrdinal,
    viewingVenueId,
    viewingOrdinal,
    findNearestOrdinal,
    setSearchParams,
  ])

  useHighLightFeaturesMap(quickSearchFeatures, Number(viewingOrdinal), {
    enabled: enableMap,
  })

  useEffect(() => {
    if (category) {
      pushDataLayer({
        event: GTM_AMENITY_SEARCH,
        amenity_category: category,
      })
    }
  }, [category])

  const theme = useTheme()
  const {
    headerWrapper,
    navigationWrapper,
    ordinalSelectMenu,
    quickLinkContainer, //TODO:: planning about quick search's working flow later
  } = theme.components[`AppMap`]?.styleOverrides || {}

  return (
    <Layout
      header={
        <Box sx={headerWrapper}>
          <Header
            logo={<HeaderLogo />}
            menu={<HeaderMenuButton />}
            search={<HeaderSearchButton />}
          />
        </Box>
      }
    >
      {isMultiOrdinalVenue && (
        <Box sx={ordinalSelectMenu}>
          <OrdinalSelector
            selectedOrdinal={Number(viewingOrdinal)}
            levels={levelInVenue}
            onChangeOrdinal={onClickOrdinal}
          />
        </Box>
      )}
      <Box sx={navigationWrapper}>
        <Box sx={quickLinkContainer}>
          <FeatureChipToggleBar
            categories={quickSearchCategories}
            onClick={handleClickQuickSearch}
          />
        </Box>
        <AppNavigation />
      </Box>
    </Layout>
  )
}
