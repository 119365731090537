import React, { useCallback, useEffect } from "react"
import _ from "lodash"
import { useTheme, Box } from "@mui/material"
import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import { AppNavigation } from "containers/AppNavigation"
import { OrdinalSelector } from "components/OrdinalSelector"
import { OrdinalSelectMenu } from "components/OrdinalSelector/OrdinalSelectMenu"
import { VenueSelector } from "components/VenueSelector"
import { SearchToggleButton } from "components/domains/search/SearchToggleButton"
import { useAppUI } from "providers/ui"
import { FeatureChipToggleBar } from "../../../components/domains/features/ChipToggleBar"
import { useMap } from "hooks/map/useMap"
import { useHighLightFeaturesMap } from "hooks/feature/useHighLightFeaturesMap"

import { pushDataLayer } from "utils/googletagmanager/pushDataLayer"
import { GTM_AMENITY_SEARCH } from "constant"
import { pluralize } from "utils/string"

export const FeatureByCategory = () => {
  const { t } = useTranslation()
  const { openSearch } = useAppUI()
  const { venues, isMultiOrdinalVenue, isMultiVenueProject, dataLoaded } =
    useVenue()

  const {
    category,
    viewingOrdinal,
    viewingVenueId,
    levelInVenue,
    quickSearchFeatures,
    quickSearchCategories,
    searchParams,
    comingFromOrdinal,
    locationVenueId,
    onChangeVenue,
    findNearestOrdinal,
    setSearchParams,
    toggleQuickSearch,
  } = useMap()

  const handleClickQuickSearch = useCallback(
    (featureType, category) => {
      const pluralType = pluralize(featureType)
      toggleQuickSearch(pluralType, category)
    },
    [toggleQuickSearch]
  )

  const onClickOrdinal = useCallback(
    (ordinal) => {
      const currentParams = Object.fromEntries(searchParams.entries())
      setSearchParams({ ...currentParams, ordinal })
    },
    [setSearchParams, searchParams]
  )

  useEffect(() => {
    // When ordinal navigate from different page
    if (viewingVenueId && dataLoaded && !_.isNil(comingFromOrdinal)) {
      const nearestOrdinal = findNearestOrdinal(comingFromOrdinal)
      setSearchParams(
        { venue: viewingVenueId, ordinal: nearestOrdinal },
        { replace: true }
      )
    } else if (viewingVenueId && dataLoaded && _.isNil(viewingOrdinal)) {
      // When ordinal is not provided
      const nearestOrdinal = findNearestOrdinal(0)
      setSearchParams(
        { venue: viewingVenueId, ordinal: nearestOrdinal },
        { replace: true }
      )
    }
  }, [
    dataLoaded,
    category,
    comingFromOrdinal,
    viewingVenueId,
    viewingOrdinal,
    findNearestOrdinal,
    setSearchParams,
  ])

  useHighLightFeaturesMap(quickSearchFeatures, Number(viewingOrdinal))

  useEffect(() => {
    if (category) {
      pushDataLayer({
        event: GTM_AMENITY_SEARCH,
        amenity_category: category,
      })
    }
  }, [category])

  const theme = useTheme()
  const { styleOverrides = {} } = theme.components[`AppMap`] || {}
  const {
    navigationContainer,
    ordinalSelectMenu,
    ordinalSelector,
    quickLinkContainer, //TODO:: planning about quick search's working flow later
    searchBarContainer,
    venueSelector,
  } = styleOverrides

  return (
    <>
      <Box sx={searchBarContainer}>
        <SearchToggleButton
          placeholder={t("Search for your destination")}
          onClick={openSearch}
        />
      </Box>

      <Box sx={quickLinkContainer}>
        <FeatureChipToggleBar
          categories={quickSearchCategories}
          onClick={handleClickQuickSearch}
        />
      </Box>
      {isMultiOrdinalVenue && (
        <Box sx={ordinalSelector}>
          <OrdinalSelector
            selectedOrdinal={Number(viewingOrdinal)}
            levels={levelInVenue}
            onClickOrdinal={onClickOrdinal}
          />
        </Box>
      )}
      {isMultiOrdinalVenue && (
        <Box sx={ordinalSelectMenu}>
          <OrdinalSelectMenu
            selectedOrdinal={Number(viewingOrdinal)}
            levels={levelInVenue}
            onChangeOrdinal={onClickOrdinal}
          />
        </Box>
      )}

      {isMultiVenueProject && (
        <Box sx={venueSelector}>
          <VenueSelector
            selectedVenue={viewingVenueId}
            venues={venues}
            onClickVenue={onChangeVenue}
            currentVenue={locationVenueId}
          />
        </Box>
      )}
      <Box sx={navigationContainer}>
        <AppNavigation />
      </Box>
    </>
  )
}
