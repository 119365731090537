import { useMemo, useState, useCallback, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import _ from "lodash"

import { IFeature } from "interfaces"
import {
  GTM_VIEW_DINING_CATEGORY,
  Q_VENUE,
  DEFAULT_FILTER_VALUE,
} from "constant"

import { useVenue } from "providers/venue"
import { useGeoLocation } from "providers/geolocation"
import { useTranslation } from "providers/i18n"
import { useGetOccupants } from "providers/venue/modules/data/occupants/useGetOccupants"
import { useVenueController } from "hooks/venue/useVenueController"

import { pushDataLayer } from "utils/googletagmanager/pushDataLayer"
import { useInfiniteScroll } from "hooks/useInfiniteScroll"
import { createSortingConfigFeatureByName } from "utils/feature"
import { useInfiniteGetOccupants } from "providers/venue/modules/data/occupants/useInfiniteGetOccupants"

type FilterByVenueId = (venueId: string) => void
type FilterByCategoryId = (id: string) => void
type FilterByOrdinal = (ordinal: number) => void
type OrdinalFilterValue = number | "ALL"

const DINING_QUERY_KEY = ["dining"]

export const useDining = () => {
  const {
    i18n: { language: chosenLanguage },
  } = useTranslation()
  const {
    isMultiVenueProject,
    levels,
    venues,
    occupants,
    findFeatureById,
    dataLoaded,
  } = useVenue()
  const [category, setCategory] = useState(DEFAULT_FILTER_VALUE)
  const [ordinal, setOrdinal] =
    useState<OrdinalFilterValue>(DEFAULT_FILTER_VALUE)
  const { locationVenue } = useGeoLocation()
  const locationVenueId = _.get(locationVenue, "id")
  const { viewingVenue: venueFilter } = useVenueController(locationVenueId)
  const [, setSearchParams] = useSearchParams()

  const filterByVenueId: FilterByVenueId = useCallback(
    (venueId) => {
      if (venueId) setSearchParams({ [Q_VENUE]: venueId }, { replace: true })
    },
    [setSearchParams]
  )

  /**
   * Result All Filtered Operating Restaurants on viewing venue
   */
  const { data: viewingVenueOperatingRestaurants } = useGetOccupants({
    queryKey: DINING_QUERY_KEY,
    pagination: {
      perPage: _.size(occupants),
    },
    sort: [
      {
        field: "properties.is_featured",
        order: "desc",
      },
      {
        field: "properties.featured_image",
        order: "asc",
      },
      {
        field: "properties.logo",
        order: "asc",
      },
      ...createSortingConfigFeatureByName(chosenLanguage),
    ],
    filter: {
      properties: {
        anchor: {
          //@ts-ignore
          $exists: true,
        },
        category: "restaurant",
        //@ts-ignore
        venue: {
          id: venueFilter,
        },
      },
    },
    enabled: dataLoaded,
  })

  /**
   * Result All Filtered Restaurants (without ordinal filter)
   * - Used to create an array of unique levels of restaurants that match the category
   * - Used as the source data for the useInfiniteGetOccupants hook
   */
  const { data: allFilteredRestaurantsSorted } = useGetOccupants({
    queryKey: DINING_QUERY_KEY,
    pagination: {
      perPage: _.size(viewingVenueOperatingRestaurants),
    },
    sort: [
      {
        field: "properties.is_featured",
        order: "desc",
      },
      {
        field: "properties.featured_image",
        order: "asc",
      },
      {
        field: "properties.logo",
        order: "asc",
      },
      ...createSortingConfigFeatureByName(chosenLanguage),
    ],
    filter: {
      properties: {
        local_categories: {
          $elemMatch: {
            id: category,
          },
        },
        anchor: {
          //@ts-ignore
          $exists: true,
        },
        category: "restaurant",
        //@ts-ignore
        venue: {
          id: venueFilter,
        },
      },
    },
    enabled: !!viewingVenueOperatingRestaurants,
  })

  /**
   * Result Infinite Filtered Restaurants
   * - Used to display data in an infinite scroll version
   */
  const {
    data: filteredRestaurantsSorted,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteGetOccupants({
    queryKey: DINING_QUERY_KEY,
    pagination: {
      perPage: 25,
    },
    sort: [
      {
        field: "properties.is_featured",
        order: "desc",
      },
      {
        field: "properties.featured_image",
        order: "asc",
      },
      {
        field: "properties.logo",
        order: "asc",
      },
      ...createSortingConfigFeatureByName(chosenLanguage),
    ],
    filter: {
      properties: {
        local_categories: {
          $elemMatch: {
            id: category,
          },
        },
        anchor: {
          //@ts-ignore
          $exists: true,
        },
        category: "restaurant",
        //@ts-ignore
        venue: {
          id: venueFilter,
        },
        //@ts-ignore
        ordinal,
      },
    },
    enabled: !!allFilteredRestaurantsSorted,
  })

  const restaurantSubCategories = useMemo(
    () =>
      _(viewingVenueOperatingRestaurants)
        //@ts-ignore
        .map((restaurant) => restaurant.properties.local_categories)
        .compact()
        .flatten()
        .uniqBy((category) => category.id)
        .filter((category) => category.properties.parent_id !== null)
        .value(),
    [viewingVenueOperatingRestaurants]
  )

  const filteredLevels = useMemo(
    () =>
      _(allFilteredRestaurantsSorted)
        .uniqBy("properties.ordinal")
        .filter((item) => !_.isNil(item.properties.ordinal))
        .map(({ properties: { ordinal } }) =>
          levels.find((level: IFeature) => level.properties.ordinal === ordinal)
        )
        .sortBy("properties.ordinal")
        .value(),
    [levels, allFilteredRestaurantsSorted]
  )

  const hasData = _.size(filteredRestaurantsSorted?.pages[0]?.data) > 0

  const filterByCategoryId: FilterByCategoryId = useCallback((id: string) => {
    if (id === null) return
    setCategory(id)
  }, [])

  const filterByOrdinal: FilterByOrdinal = useCallback(
    (ordinal: OrdinalFilterValue) => {
      if (ordinal === null) return
      setOrdinal(ordinal)
    },
    []
  )

  const onReachEndScroll = useCallback(
    (inView) => {
      if (inView && hasNextPage) fetchNextPage()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasNextPage]
  )

  const { endScrollRef } = useInfiniteScroll({
    onReachEndScroll,
  })

  useEffect(() => {
    if (category && category !== DEFAULT_FILTER_VALUE) {
      pushDataLayer({
        event: GTM_VIEW_DINING_CATEGORY,
        dining_category_id: category,
        dining_category_name: _.get(
          findFeatureById(category),
          "properties.name.en"
        ),
      })
    }
  }, [findFeatureById, category])

  return {
    restaurants: filteredRestaurantsSorted,
    categories: restaurantSubCategories,
    currentCategory: category,
    levels: filteredLevels,
    isMultiVenueProject,
    locationVenueId,
    venues,
    venueFilter,
    hasNextPage,
    hasData,
    // Function
    endScrollRef,
    filterByCategoryId,
    filterByOrdinal,
    filterByVenueId,
  }
}
