import { useCallback, useMemo, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Box,
  Typography,
  IconButton,
  Slide,
  Button,
  useTheme,
  Paper,
  Avatar,
} from "@mui/material"
import { Trans } from "react-i18next"
import _ from "lodash"

import { getIcon } from "utils/icon"
import { sanitizePublicPath } from "providers/venue/utils/fetchUtils"

import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import { useStepByStep } from "hooks/direction/useStepByStep"
import { useStepByStepMap } from "hooks/direction/useStepByStepMap"

import {
  PrevStepIcon,
  NextStepIcon,
  MarkerIcon,
  OriginMarkerIcon,
  CustomIcon,
} from "components/icons"

import { StepProgressBar } from "components/Progress/StepProgressBar"
import { VerticalDirectionImageDialog } from "extensions/one-siam/components/Dialog/VerticalStepImage/VerticalDirectionImageDialog"

export const StepByStep = () => {
  const navigate = useNavigate()
  const contentRef = useRef(null)
  const [slideEntered, setSlideEntered] = useState(false)
  const offsetBottom = +contentRef.current?.clientHeight || 0

  const { dataLoaded } = useVenue()

  const {
    steps,
    destinationParam,
    originParam,
    currentStepNumber,
    currentStep,
    route,
    destinationLogo,
    originLogo,
    originCategoryIconPath,
    destinationCategoryIconPath,
    currentVerticalStepMethod,
    originLevelName,
    destinationLevelName,
    hasNextStep,
    hasPrevStep,
    goToNextStep,
    goToPrevStep,
    isReady,
  } = useStepByStep()
  const stepsAvailable = steps && steps.length > 0

  useStepByStepMap(route, currentStepNumber, {
    offset: { bottom: offsetBottom },
    enabled: isReady && slideEntered,
  })

  const { t } = useTranslation()

  const theme = useTheme()

  const {
    root,
    container,
    originIcon,
    destinationIcon,
    stepProgressBar,
    originIconContainer,
    destinationIconContainer,
    overviewProgressContainer,
    locationAvatar,
    headerWrapper,
    cardContainer,
    cardBody,
    cardAction,
    actionButtonGroupContainer,
    descriptionContainer,
    descriptionText,
    exitButton,
    iconNavButton,
  } = theme.components[`AppNavigationStep`]?.styleOverrides || {}

  const { highlightedText } =
    theme.components[`DirectionStep`]?.styleOverrides || {}

  const highlightedStepText = (
    <Typography variant="subTitle" component="span" sx={highlightedText} />
  )

  const originIconComp = useMemo(
    () =>
      getIcon(theme?.assets?.navigation?.origin, originIcon) || (
        <OriginMarkerIcon sx={originIcon} />
      ),
    [theme, originIcon]
  )

  const destinationIconComp = useMemo(
    () =>
      getIcon(theme?.assets?.navigation?.destination, destinationIcon) || (
        <MarkerIcon sx={destinationIcon} />
      ),
    [theme, destinationIcon]
  )

  const handleClickStep = useCallback(
    (index) => {
      navigate(
        `/maps/dir/${originParam}/${destinationParam}/navigate/steps/${
          index + 1
        }`,
        { replace: true }
      )
    },
    [originParam, destinationParam, navigate]
  )

  const handleSlideEnter = useCallback(
    () => !slideEntered && setSlideEntered(true),
    [slideEntered]
  )

  const verticalDirectionImageUrl = useMemo(
    () =>
      sanitizePublicPath(
        _.get(
          theme?.assets?.verticalStep?.[currentVerticalStepMethod],
          "url",
          ""
        )
      ),
    [currentVerticalStepMethod, theme?.assets?.verticalStep]
  )

  return (
    <>
      <VerticalDirectionImageDialog
        imageSrc={verticalDirectionImageUrl}
        description={currentStep?.description}
        originLevelName={originLevelName}
        destinationLevelName={destinationLevelName}
      />
      <Box sx={root}>
        <Slide
          direction="up"
          in={dataLoaded && steps.length > 0}
          mountOnEnter
          unmountOnExit
          onEnter={handleSlideEnter}
        >
          <Box sx={container}>
            <Paper sx={cardContainer}>
              <Box sx={cardBody}>
                <Box sx={headerWrapper}>
                  <Box sx={descriptionContainer}>
                    <Typography variant="caption" sx={descriptionText}>
                      <Trans
                        components={{
                          category: highlightedStepText,
                          destination: highlightedStepText,
                          landmark: highlightedStepText,
                        }}
                      >
                        {t(currentStep?.description.text, {
                          category: t(
                            currentStep?.description.options?.category
                          ),
                          destination: t(
                            currentStep?.description.options?.destination
                          ),
                          landmark: t(
                            currentStep?.description.options?.landmark
                          ),
                        })}
                      </Trans>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={overviewProgressContainer}>
                  <Box sx={originIconContainer}>
                    <Avatar
                      variant="circular-logo"
                      sx={locationAvatar}
                      src={originLogo}
                    >
                      {originCategoryIconPath ? (
                        <CustomIcon path={originCategoryIconPath} />
                      ) : (
                        originIconComp
                      )}
                    </Avatar>
                  </Box>
                  <StepProgressBar
                    steps={steps}
                    currentStep={currentStepNumber}
                    sx={stepProgressBar}
                    handleClickStep={handleClickStep}
                    enableAnimateScroll={slideEntered && stepsAvailable}
                  />
                  <Box
                    className={!hasNextStep ? "is-current-step" : ""}
                    sx={destinationIconContainer}
                  >
                    <Avatar
                      variant="circular-logo"
                      sx={locationAvatar}
                      src={destinationLogo}
                    >
                      {destinationCategoryIconPath ? (
                        <CustomIcon path={destinationCategoryIconPath} />
                      ) : (
                        destinationIconComp
                      )}
                    </Avatar>
                  </Box>
                </Box>
              </Box>
              <Box sx={cardAction}>
                <IconButton
                  disabled={!hasPrevStep}
                  sx={iconNavButton}
                  onClick={goToPrevStep}
                >
                  <PrevStepIcon fontSize="inherit" />
                </IconButton>
                <Box sx={actionButtonGroupContainer}>
                  <Button
                    component={Link}
                    to="/maps"
                    sx={exitButton}
                    variant="outlined"
                    color="error"
                  >
                    {t("Exit")}
                  </Button>
                </Box>
                <IconButton
                  disabled={!hasNextStep}
                  sx={iconNavButton}
                  onClick={goToNextStep}
                >
                  <NextStepIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Paper>
          </Box>
        </Slide>
      </Box>
    </>
  )
}
