import { IFeature } from "interfaces"
import _ from "lodash"

type SortFeatureByLocaleKey = (
  features: IFeature[],
  pathToField: string,
  chosenLanguage: string,
  fallbackLanguage?: string
) => IFeature[]

/*
    features: Array of objects
    pathToField: The path to obtain the key-value for sorting (e.g. ".properties.name" from features.properties.name) 
    chosenLanguage: Language chosen by the user (e.g. "en" or "th")
    fallbackLanguage: Language to be used for sorting if the one chosen by user is not available (e.g. "en" or "th")
*/

export const sortFeatureByLocaleKey: SortFeatureByLocaleKey = (
  features,
  pathToField,
  chosenLanguage,
  fallbackLanguage = "en"
) => {
  return [...features].sort((currFeature, nextFeature) => {
    const [currValue, nextValue] = [
      _.get(currFeature, pathToField),
      _.get(nextFeature, pathToField),
    ]
    const [currLocalisedValue, nextLocalisedValue] = [
      currValue[chosenLanguage],
      nextValue[chosenLanguage],
    ]
    const [currFallbackValue, nextFallbackValue] = [
      currValue[fallbackLanguage],
      nextValue[fallbackLanguage],
    ]
    const isCurrValLocalised = _.isEmpty(currLocalisedValue)
    const isNextValLocalised = _.isEmpty(nextLocalisedValue)

    if (isCurrValLocalised && isNextValLocalised) {
      return currFallbackValue.localeCompare(
        nextFallbackValue,
        fallbackLanguage,
        { numeric: true }
      )
    }
    // Only currOccupant doesn't have name in the chosen language, put it behind nextOccupant
    if (isCurrValLocalised) {
      return 1
    }
    // Only nextOccupant doesn't have name in the chosen language, put it behind currOccupant
    if (isNextValLocalised) {
      return -1
    }
    return currLocalisedValue.localeCompare(
      nextLocalisedValue,
      chosenLanguage,
      { numeric: true }
    )
  })
}
