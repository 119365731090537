import { useCallback, useEffect } from "react"
import { useParams, useNavigate, useLocation, Link } from "react-router-dom"
import { useTheme, Box } from "@mui/material"
import _ from "lodash"

import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import { useGeoLocation } from "providers/geolocation"
import { useQuery as useQueryString } from "hooks"
import { useMap } from "hooks/map/useMap"
import { useOrdinalMap } from "hooks/ordinal/useOrdinalMap"

import { pluralize } from "utils/string"

import { FooterAction } from "extensions/one-siam/components/domains/navigation/FooterAction"

import { AppNavigation } from "containers/kiosk-v"
import { FeatureChipToggleBar } from "components/domains/features/ChipToggleBar"
import { LanguageButton } from "components/Language"
import { SearchToggleButton } from "components/domains/search"
import { SearchIcon } from "extensions/one-siam/components/icons"
import { OrdinalSelectSplide } from "extensions/one-siam/components/OrdinalSelector/OrdinalSelectSplide"

export const MapOrdinalKioskVPage = () => {
  const { isLoading, userLocation, locationVenue } = useGeoLocation()
  const navigate = useNavigate()
  const { ordinal } = useParams()
  const { search: searchQueryString } = useLocation()
  const { userLocation: userLocationParams } = useQueryString()
  const { t } = useTranslation()
  const { isMultiOrdinalVenue } = useVenue()
  const { viewingVenue, viewingVenueId, levelInVenue, quickSearchCategories } =
    useMap()

  const parsedOrdinal = Number(ordinal)
  const enableMap = !_.isNil(viewingVenue) && !_.isNil(ordinal)
  useOrdinalMap(parsedOrdinal, viewingVenue, { enabled: enableMap })

  const theme = useTheme()
  const {
    actionButton,
    actionContainer,
    toolbarContainer,
    quickLinkContainer, //TODO:: planning about quick search's working flow later
    navigationWrapper,
    ordinalSelectMenu,
    searchBarContainer,
    mainMenuContainer,
    footerActionContainer,
  } = theme.components[`AppMap`]?.styleOverrides || {}

  const onClickOrdinal = useCallback(
    (ordinal) => {
      navigate(
        `/maps/${
          ordinal !== null ? `ordinal/${ordinal}${searchQueryString}` : ""
        }`,
        { replace: true }
      )
    },
    [navigate, searchQueryString]
  )

  const handleClickQuickSearch = useCallback(
    (type, category) => {
      const pluralType = pluralize(type)
      navigate(
        `/maps/${pluralType}/${category}?from-ordinal=${ordinal}&venue=${viewingVenueId}`
      )
    },
    [navigate, ordinal, viewingVenueId]
  )

  useEffect(() => {
    if (
      !isLoading &&
      userLocationParams &&
      userLocation &&
      !_.isNil(locationVenue?.id)
    ) {
      const {
        properties: { ordinal: userOrdinal },
      } = userLocation
      navigate(`/maps/ordinal/${userOrdinal}?venue=${locationVenue?.id}`, {
        replace: true,
      })
    } else if (
      !isLoading &&
      userLocationParams &&
      !_.isNil(locationVenue?.id)
    ) {
      navigate(`/maps/ordinal/${ordinal}?venue=${locationVenue?.id}`, {
        replace: true,
      })
    }
  }, [
    navigate,
    ordinal,
    isLoading,
    userLocation,
    locationVenue,
    userLocationParams,
  ])

  return (
    <>
      {isMultiOrdinalVenue && (
        <Box sx={ordinalSelectMenu}>
          <OrdinalSelectSplide
            selectedOrdinal={parsedOrdinal}
            levels={levelInVenue}
            onChangeOrdinal={onClickOrdinal}
          />
        </Box>
      )}
      <Box key="app-navigation" sx={navigationWrapper}>
        <Box sx={quickLinkContainer}>
          <FeatureChipToggleBar
            categories={quickSearchCategories}
            onClick={handleClickQuickSearch}
          />
        </Box>
        <Box sx={toolbarContainer}>
          <Box sx={actionContainer}>
            <LanguageButton variant="contained" sx={actionButton} />
          </Box>
          <Box sx={searchBarContainer}>
            <SearchToggleButton
              icon={SearchIcon}
              placeholder={t("Search shops and more...")}
              LinkComponent={Link}
              to="/search"
            />
          </Box>
        </Box>
        <Box sx={mainMenuContainer}>
          <AppNavigation />
          <FooterAction sx={footerActionContainer} />
        </Box>
      </Box>
    </>
  )
}
