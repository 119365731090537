const VERTICAL_TRAVERSAL_CATEGORY = ["elevator", "escalator", "stairs", "ramp"]
const VERTICAL_KIOSK_BREAKPOINT = "@media (min-height:1920px)"

const Q_AUTOPLAY = "autoplay"
const Q_PARENT_CATEGORY_ID = "parentCategoryId"
const Q_SUB_CATEGORY_ID = "subCategoryId"
const Q_ORDINAL = "ordinal"
const Q_VENUE = "venue"

const PROMOTION_TAXONOMY = "promotion_category"

const GTM_SEARCH_KEYWORD = "search_keyword"
const GTM_CATEGORY_QUICK_SEARCH = "category_quick_search"
const GTM_GET_DIRECTION = "get_direction"
const GTM_VIEW_EVENT_DETAIL = "view_event_detail"
const GTM_AMENITY_SEARCH = "amenity_search"
const GTM_VIEW_PROMOTION_DETAIL = "view_promotion_detail"
const GTM_VIEW_FEATURE_DETAIL = "view_feature_detail"
const GTM_VIEW_DIRECTORY_CATEGORY = "view_directory_category"
const GTM_NO_RESULT_VALUE = "no result"
const GTM_PROMOTION_LOCATION = "view_promotion_location"
const GTM_EVENT_LOCATION = "view_event_location"
const GTM_VIEW_DINING_CATEGORY = "view_dining_category"
const GTM_COUNT_KIOSK_USERS = "count_kiosk_users"

const DEVICE_MOBILE = "mobile"
const DEVICE_KIOSK_V = "kiosk-v"
const DEVICE_KIOSK_H = "kiosk-h"

const HOMEPAGE_ANIMATION = "homepage-animation"

const DEFAULT_SEARCH_LIST_ITEM_SECONDARY_TEXT_KEY = [
  "categoryText",
  "floorText",
  "groupText",
]

const DEFAULT_VENUE_FILTER = "ALL"
const DEFAULT_FILTER_VALUE = DEFAULT_VENUE_FILTER

const VENUE_DEVICE_LOCATION_KEY = "venue_device_location"
const VENUE_DEVICE_VIEW_POINT_KEY = "venue_device_view_point"
const VENUE_DEVICE_NAME_KEY = "venue_device_name"

const DEFAULT_FILTER_OPTIONS = {
  sanitizeValues: [DEFAULT_FILTER_VALUE],
}

const SKELETON_COUNT = 5
const SKELETON_INDEX_TO_REF = SKELETON_COUNT - 1

export {
  // Query String
  Q_AUTOPLAY,
  Q_PARENT_CATEGORY_ID,
  Q_SUB_CATEGORY_ID,
  Q_ORDINAL,
  Q_VENUE,
  // Google Analytics
  GTM_SEARCH_KEYWORD,
  GTM_CATEGORY_QUICK_SEARCH,
  GTM_GET_DIRECTION,
  GTM_VIEW_EVENT_DETAIL,
  GTM_AMENITY_SEARCH,
  GTM_VIEW_PROMOTION_DETAIL,
  GTM_VIEW_FEATURE_DETAIL,
  GTM_VIEW_DIRECTORY_CATEGORY,
  GTM_NO_RESULT_VALUE,
  GTM_PROMOTION_LOCATION,
  GTM_EVENT_LOCATION,
  GTM_VIEW_DINING_CATEGORY,
  GTM_COUNT_KIOSK_USERS,
  // Pages
  HOMEPAGE_ANIMATION,
  // Filters
  DEFAULT_VENUE_FILTER,
  DEFAULT_FILTER_VALUE,
  VENUE_DEVICE_LOCATION_KEY,
  VENUE_DEVICE_VIEW_POINT_KEY,
  VENUE_DEVICE_NAME_KEY,
  DEFAULT_FILTER_OPTIONS,
  // Others
  DEVICE_MOBILE,
  DEVICE_KIOSK_V,
  DEVICE_KIOSK_H,
  DEFAULT_SEARCH_LIST_ITEM_SECONDARY_TEXT_KEY,
  PROMOTION_TAXONOMY,
  VERTICAL_TRAVERSAL_CATEGORY,
  VERTICAL_KIOSK_BREAKPOINT,
  SKELETON_COUNT,
  SKELETON_INDEX_TO_REF,
}
