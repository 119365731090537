import {
  StyledEngineProvider,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material"

import GlobalStyles from "@mui/material/GlobalStyles"

export const ThemeProvider = ({ theme = {}, children, ...props }) => {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme} {...props}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            a: {
              textDecoration: "none",
            },
          }}
        />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
}
