import _ from "lodash"
import { IFeatureCategoryObj } from "../interfaces"

const sortCategoryObjByOrder = (
  categoryObj: IFeatureCategoryObj,
  config: string[] = []
) => {
  const { type, category } = categoryObj
  const indexOrder = config.findIndex(
    (categoryOrder) => categoryOrder === `${type}.${category}`
  )
  // If category not found it will be sent to last item
  return indexOrder !== -1 ? indexOrder : 9999
}

export const getCategoriesFromFeatures = (features = [], config = []) => {
  return _(features)
    .uniqBy((feature) => feature.properties.category)
    .filter(
      (feature) =>
        feature.properties.category &&
        config.includes(
          `${feature.feature_type}.${feature.properties.category}`
        )
    )
    .map(
      (feature): IFeatureCategoryObj => ({
        type: feature.feature_type,
        category: feature.properties.category,
      })
    )
    .orderBy([
      // First Sort by prioritize order from config
      (categoryObj: IFeatureCategoryObj) =>
        sortCategoryObjByOrder(categoryObj, config),
      // When order is the same sort by alphabet
      (categoryObj: IFeatureCategoryObj) => categoryObj.category.toLowerCase(),
    ])
    .value()
}
