import { useSponsoredContent } from "../useSponsoredContent"
import { ISponsoredContentConfig } from "providers/venue/types"

interface IUseRecommendedEventResult {
  recommendedEvents: ISponsoredContentConfig
}

type IUseRecommendedEvent = () => IUseRecommendedEventResult

export const useRecommendedEvent: IUseRecommendedEvent =
  (): IUseRecommendedEventResult => {
    const { recommendedEvent: recommendedEvents } = useSponsoredContent()

    return {
      recommendedEvents,
    }
  }
