import { Box, Typography, useTheme } from "@mui/material"
import { useTranslation } from "providers/i18n"

export const NoSearchResult = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { root, noResultText } =
    theme.components[`VuiNoSearchResult`]?.styleOverrides || {}

  return (
    <Box sx={root}>
      <Typography sx={noResultText}>{t("No result")}</Typography>
    </Box>
  )
}
