import { useCallback, useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import _ from "lodash"

import config from "config"
import dayjs from "config/dayjs"

import { useVenue } from "providers/venue"
import { useTranslation } from "providers/i18n"
import { useGetEvent } from "providers/venue/modules/data"
import { useGeoLocation } from "providers/geolocation"

import { getEventDetail } from "utils/feature"
import { replaceLineBreak } from "utils/string"
import { getOpeningText } from "utils/time"
import { getDurationDate } from "utils/date"

import { pushDataLayer } from "utils/googletagmanager/pushDataLayer"
import { GTM_VIEW_EVENT_DETAIL, GTM_EVENT_LOCATION } from "constant"
import { IFeature } from "interfaces"

//TODO:: replace logic in this hook by using useFeatureDetail in future
export const useEventDetail = (eventId) => {
  const { data } = useGetEvent(eventId)
  const { t } = useTranslation()
  const currentTime = dayjs()
  const navigate = useNavigate()
  const { events, findFeatureById } = useVenue()
  const location = useLocation()
  const mobileBaseUrl = config("mobileBaseUrl")
  const { deviceLocation, deviceLocationName } = useGeoLocation()

  const eventDetailMobileUrl = useMemo(
    () =>
      `${mobileBaseUrl}${location.pathname}?user-location=${
        _.get(deviceLocation, "id") || ""
      }&location-name=${deviceLocationName || ""}`,
    [mobileBaseUrl, deviceLocationName, deviceLocation, location]
  )

  const event = useMemo(() => {
    // Temporary cast type
    const feature = data as unknown as IFeature
    if (!feature?.properties) return
    const eventDetail = getEventDetail(feature)
    return {
      ...eventDetail,
      feature,
      descriptionText: replaceLineBreak(t(eventDetail.description)),
      openingText: getOpeningText(
        eventDetail.startTime,
        eventDetail.endTime,
        t
      ),
      durationDate: getDurationDate(eventDetail.startDate, eventDetail.endDate),
    }
  }, [data, t])

  const moreEvents = useMemo(
    () =>
      _(events)
        .filter((event) => {
          const { id, properties } = event
          const endDate = dayjs(properties?.end_date)
          return id !== eventId && currentTime.isBefore(endDate)
        })
        .orderBy(
          [
            "properties.is_featured",
            "properties.start_date",
            "properties.start_end",
          ],
          ["desc", "asc", "asc"]
        )
        .take(4)
        .value(),
    [currentTime, events, eventId]
  )

  const navigateToLocation = useCallback(() => {
    if (event?.locationId) {
      pushDataLayer({
        event: GTM_EVENT_LOCATION,
        event_location_name: _.get(
          findFeatureById(event?.locationId),
          "properties.name.en"
        ),
      })
      navigate(`/maps/place/${event?.locationId}`)
    }
  }, [navigate, event?.locationId, findFeatureById])

  const openWebsiteLink = useCallback(
    () => event?.websiteLink && window.open(event?.websiteLink, "_blank"),
    [event?.websiteLink]
  )

  useEffect(() => {
    if (event) {
      pushDataLayer({
        event: GTM_VIEW_EVENT_DETAIL,
        event_id: _.get(event, "id"),
        event_name: _.get(event, "name.en"),
      })
    }
  }, [event])
  return {
    event,
    eventDetailMobileUrl,
    moreEvents,
    navigateToLocation,
    openWebsiteLink,
  }
}
