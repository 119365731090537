import { useMemo } from "react"
import { Box, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"
import StarIcon from "@mui/icons-material/Star"

import { Image } from "components/Image"
import { getIcon } from "utils/icon"

export const CarouselImageCard = ({
  src,
  alt,
  sx = {},
  isHighlight = false,
  ...props
}) => {
  const theme = useTheme()
  const { root, image, imageWrapper, highlightBanner } =
    theme.components[`CarouselImageCard`]?.styleOverrides || {}

  const highlightIcon = useMemo(
    () => getIcon(theme?.assets?.carouselImageCard?.highlight) || <StarIcon />,
    [theme]
  )

  return (
    <Box sx={deepmerge(root, sx)} {...props}>
      <Box sx={imageWrapper}>
        <Image
          className="CarouselImageCard-image"
          src={src}
          alt={alt}
          sx={image}
        />
        {isHighlight && (
          <Box
            className="CarouselImageCard-highlightBanner"
            sx={highlightBanner}
          >
            {highlightIcon}
          </Box>
        )}
      </Box>
    </Box>
  )
}
