import React, { useCallback } from "react"
import { List, ListItemButton, useTheme } from "@mui/material"

export const OrdinalSelector = (props) => {
  const {
    levels = [],
    onClickOrdinal = () => {},
    selectedOrdinal,
    ...restProps
  } = props

  const theme = useTheme()
  const { root, listItemButton } =
    theme.components[`AppOrdinalSelector`]?.styleOverrides || {}

  const handleClick = useCallback(
    (ordinal) => () => {
      onClickOrdinal(ordinal)
    },
    [onClickOrdinal]
  )

  if (levels.length === 0) return null

  return (
    <List sx={root} {...restProps}>
      {levels.map((level) => (
        <ListItemButton
          sx={listItemButton}
          selected={level.properties.ordinal === selectedOrdinal}
          key={`ordinal-selector-${level.id}`}
          onClick={handleClick(level.properties.ordinal)}
        >
          {level.properties.name?.en}
        </ListItemButton>
      ))}
    </List>
  )
}
