import React, { useCallback, useMemo, useState } from "react"
import { Box, Button, List, ListItemButton, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

import { useVenue } from "providers/venue"

import { useTranslation } from "providers/i18n"

import { Dialog } from "../Dialog"

export const LanguageButton = ({ sx = {}, ...props }) => {
  const { t, i18n } = useTranslation()
  const { locales = [] } = useVenue()
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const {
    root,
    dialog,
    dialogContent,
    listContainer,
    list,
    listItemButton,
    highlightedText,
  } = theme.components[`OSLanguageButton`]?.styleOverrides || {}

  const handleClickOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const switchLanguage = useCallback(
    (lang) => (e) => {
      i18n.changeLanguage(lang)
      setOpen(false)
    },
    [i18n]
  )

  const activeLocale = useMemo(() => {
    if (!i18n.resolvedLanguage) return
    return locales.find((locale) => i18n.resolvedLanguage === locale.localeKey)
  }, [locales, i18n.resolvedLanguage])

  const { localeKey: activeLocaleKey, localizedName: activeLocaleName } =
    activeLocale || {}

  if (locales.length <= 0) return null

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="outlined"
        sx={deepmerge(root, sx)}
        endIcon={<KeyboardArrowDownIcon fontSize="inherit" />}
        {...props}
      >
        {`${t("Languages")}:`}
        <Box sx={highlightedText}>
          {`${t(
            `localization.select.item.${activeLocaleKey}`,
            activeLocaleName
          )}`}
        </Box>
      </Button>
      <Dialog
        sx={dialog}
        open={open}
        onClose={handleClose}
        title={t("Select Languages")}
      >
        <Box sx={dialogContent}>
          <Box sx={listContainer}>
            <List sx={list}>
              {locales.map((locale) => (
                <ListItemButton
                  sx={listItemButton}
                  selected={activeLocaleKey === locale.localeKey}
                  key={`language-selector-${locale.localeKey}`}
                  onClick={switchLanguage(locale.localeKey)}
                >
                  {t(
                    `localization.select.item.${locale.localeKey}`,
                    locale.localizedName
                  )}
                </ListItemButton>
              ))}
            </List>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}
