import { useEffect, useMemo, useState, useCallback } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { get as getPersistValue } from "utils/persist_store"
import _ from "lodash"

import { useRoute } from "providers/route"
import { useVenue } from "providers/venue"
import { gtmPushDataLayerGetDirection } from "utils/googletagmanager/directionEvent"
import { transformGTMDirectionData } from "utils/direction"

const debounceGtmPushDataLayerGetDirection = _.debounce(
  gtmPushDataLayerGetDirection,
  800
)

export const useDirection = () => {
  const [isCalculatingRoute, setIsCalculatingRoute] = useState(false)
  const [isError, setIsError] = useState(false)
  const { findRoute, clearRoute, route, findElevatorPreferredRoute } =
    useRoute()
  const { transformLocationToFeature, relationshipGraphLoaded } = useVenue()
  const { origin: originParams, destination: destinationParams } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const origin = useMemo(
    () => transformLocationToFeature(originParams),
    [originParams, transformLocationToFeature]
  )
  const locationName = getPersistValue("venue_device_name")

  const destination = useMemo(
    () => transformLocationToFeature(destinationParams),
    [destinationParams, transformLocationToFeature]
  )

  const elevatorPreferredMode = useMemo(() => {
    const isAvoidSteps = searchParams.get("avoidSteps")
    return isAvoidSteps === "true"
  }, [searchParams])

  const toggleElevatorPreference = useCallback(() => {
    const currentParams = Object.fromEntries(searchParams.entries())
    setSearchParams(
      { ...currentParams, avoidSteps: !elevatorPreferredMode },
      { replace: true }
    )
  }, [elevatorPreferredMode, searchParams, setSearchParams])

  useEffect(() => {
    if (relationshipGraphLoaded && origin && destination) {
      //NOTE: this is temporary solution
      //TODO: find better solution for direction overview loading screen
      setIsCalculatingRoute(true)
      setTimeout(() => {
        try {
          if (!elevatorPreferredMode) {
            findRoute(origin, destination)
          }
          if (elevatorPreferredMode) {
            findElevatorPreferredRoute(origin, destination)
          }
          debounceGtmPushDataLayerGetDirection(
            transformGTMDirectionData(origin),
            transformGTMDirectionData(destination),
            locationName,
            elevatorPreferredMode
          )
          setIsCalculatingRoute(false)
        } catch (error) {
          setIsError(true)
        }
      }, 500)
    }
    return () => clearRoute()
  }, [
    origin,
    destination,
    relationshipGraphLoaded,
    clearRoute,
    findRoute,
    elevatorPreferredMode,
    findElevatorPreferredRoute,
    locationName,
  ])

  return {
    route,
    isError,
    isCalculatingRoute,
    origin,
    destination,
    // Function
    elevatorPreferredMode,
    toggleElevatorPreference,
  }
}
