import { useState, useCallback } from "react"
import { useVenue } from "providers/venue"
import { RouteContext } from "./RouteContext"

export const RouteProvider = ({ children }) => {
  const [route, setRoute] = useState(null)
  const { findRoute, findElevatorPreferredRoute } = useVenue()

  const calculateRoute = useCallback(
    (origin, destination) => {
      const route = findRoute(origin, destination)
      if (!route) return setRoute({ origin, destination })
      return setRoute(route)
    },
    [findRoute]
  )

  const calculateElevatorPreferredRoute = useCallback(
    (origin, destination) => {
      const route = findElevatorPreferredRoute(origin, destination)
      if (!route) return setRoute({ origin, destination })
      return setRoute(route)
    },
    [findElevatorPreferredRoute]
  )

  const clearRoute = useCallback(() => {
    setRoute(null)
  }, [])

  const value = {
    route,
    findRoute: calculateRoute,
    findElevatorPreferredRoute: calculateElevatorPreferredRoute,
    clearRoute,
  }
  return <RouteContext.Provider value={value}>{children}</RouteContext.Provider>
}
