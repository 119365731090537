import React, { useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Box, useTheme, Fab } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

import { useVenue } from "providers/venue"
import { useTranslation } from "providers/i18n"
import { useAppUI } from "providers/ui"
import { useFeatureDetail } from "hooks/feature/useFeatureDetail"
import { usePlaceDetailMap } from "hooks/place/usePlaceDetailMap"

import { FeatureDetail } from "extensions/one-siam/components/domains/features/detail/Detail"
import { FeatureSummary } from "extensions/one-siam/components/domains/features/detail/Summary"
import { SwipeableDrawer } from "components/SwipeableDrawer"
import { Head } from "components/Head"
import { getIcon } from "utils/icon"

export const PlaceDetailPage = () => {
  const theme = useTheme()
  const { dataLoaded, ...data } = useFeatureDetail()

  const {
    feature,
    zone,
    logo,
    venueLogo,
    category,
    level,
    name,
    hours,
    id,
    websiteLink,
    gallery,
    promotions,
    privileges,
    description,
    group,
    categoryIconProp,
  } = data

  usePlaceDetailMap(feature, {
    offset: { bottom: 180 },
    enabled: !!feature,
  })

  const {
    root,
    bleedWrapper,
    contentWrapper,
    backButton,
    headerWrapper,
    swipeableDrawer,
    swipeableDrawerGlobal,
  } = theme.components[`AppFeatureDetail`]?.styleOverrides || {}

  const { isMultiOrdinalVenue, isMultiVenueProject } = useVenue()
  const { goBack } = useAppUI()
  const navigate = useNavigate()

  const onNavigate = useCallback(() => {
    const locationId = feature?.properties?.feature?.id || id
    navigate(`/maps/dir/unknown/${locationId}`)
  }, [navigate, feature, id])

  const { t } = useTranslation()
  const handleClickBack = useCallback(() => {
    goBack()
  }, [goBack])

  const backIcon = useMemo(
    () => getIcon(theme?.assets?.backIcon) || <ArrowBackIcon />,
    [theme]
  )

  return (
    <>
      <Head title={`${t(name)} (${t("Place Detail")})`} />
      <Box sx={root}>
        <Box sx={headerWrapper}>
          <Fab variant="rounded" sx={backButton} onClick={handleClickBack}>
            {backIcon}
          </Fab>
        </Box>
        <SwipeableDrawer
          sx={swipeableDrawer}
          globalProps={{
            sx: swipeableDrawerGlobal,
          }}
          drawerBleeding={theme.mixins.placeDetail.drawerBleeding.height}
        >
          <Box sx={bleedWrapper}>
            <FeatureSummary
              id={id}
              name={name}
              hours={hours}
              level={isMultiOrdinalVenue && level}
              category={category}
              logo={logo}
              venueLogo={isMultiVenueProject && venueLogo}
              zone={zone}
              group={group}
              onNavigate={onNavigate}
              loading={!dataLoaded}
              categoryIconProp={categoryIconProp}
            />
          </Box>
          <Box sx={contentWrapper}>
            <FeatureDetail
              feature={feature}
              websiteLink={websiteLink}
              gallery={gallery}
              promotions={promotions}
              privileges={privileges}
              description={description}
              loading={!dataLoaded}
            />
          </Box>
        </SwipeableDrawer>
      </Box>
    </>
  )
}
