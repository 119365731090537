import center from "@turf/center"
import { Geometry } from "@turf/helpers"
import _ from "lodash"

// Calculates the center point of a GeoJSON geometry and Return as a Coordinates.
export const getCenterFromGeometry = (geometry?: Geometry): number[] | null => {
  try {
    const { type = null, coordinates = null } = geometry
    if (!type || !coordinates) return null
    const centerPoint = center(geometry)
    return _.get(centerPoint, "geometry.coordinates") // Coordinates
  } catch (error) {
    return null
  }
}
