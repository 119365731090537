import React from "react"
import {
  styled,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemButton,
} from "@mui/material"
import StoreIcon from "@mui/icons-material/Store"

const OccupantListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderBottom: "1px solid",
  borderColor: theme.palette.grey[300],
  padding: theme.spacing(2, 1),
  "&:last-child": {
    borderBottom: "none",
  },
}))

const OccupantAvatar = styled(Avatar)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius / 2,
  width: 56,
  height: 56,
}))

const OccupantListItemText = styled(ListItemText)(({ theme }) => ({
  marginLeft: theme.spacing(2.5),
  "&.MuiListItemText-multiline .MuiTypography-root:not(:last-child)": {
    marginBottom: theme.spacing(1),
  },
  "& .MuiTypography-root": {
    fontSize: theme.typography.subtitle2.fontSize,
  },
}))

const StyledListItemAvatar = styled(ListItemAvatar)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

export const OccupantListItem = ({
  primary,
  secondary,
  logo,
  icon = <StoreIcon fontSize="large" />,
  onClick,
}) => {
  return (
    <OccupantListItemButton disableGutters onClick={onClick}>
      <StyledListItemAvatar>
        <OccupantAvatar src={logo}>{icon}</OccupantAvatar>
      </StyledListItemAvatar>
      <OccupantListItemText primary={primary} secondary={secondary} />
    </OccupantListItemButton>
  )
}
