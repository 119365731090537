import { Box, Button, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"

import { useTranslation } from "providers/i18n"

import { LanguageButton } from "components/Language"
import { ServiceIcon } from "../../icons"
import { Link, matchPath, useLocation } from "react-router-dom"

export const FooterAction = ({ sx = {}, ...props }) => {
  const location = useLocation()
  const { t } = useTranslation()
  const theme = useTheme()
  const { root, footerActionButton } =
    theme.components[`FooterAction`]?.styleOverrides || {}
  const isServicePage = matchPath("/services", location.pathname)

  return (
    <Box sx={deepmerge(root, sx)} {...props}>
      <LanguageButton sx={footerActionButton} label="Languages" />
      <Button
        component={Link}
        to="/services"
        className={isServicePage ? "Mui-active" : ""}
        sx={footerActionButton}
        startIcon={<ServiceIcon />}
      >
        {t("Service & Privilege")}
      </Button>
    </Box>
  )
}
