import { Fragment, useMemo } from "react"
import { Link } from "react-router-dom"
import { Box, useTheme } from "@mui/material"

import { SKELETON_COUNT, SKELETON_INDEX_TO_REF } from "constant"
import { useDirectory } from "hooks"
import { useTranslation } from "providers/i18n"

import {
  CategoryFilterBar,
  CategoryFilterChip,
} from "components/CategoryFilterBar"
import { SearchToggleButton } from "components/domains/search"
import { EmptyData } from "components/EmptyData"
import { VenueSelector } from "components/VenueSelector"
import { AppNavigation } from "containers/kiosk-v"
import { HorizontalScrollPane } from "components/HorizontalScrollPane"
import { Head } from "components/Head"
import {
  DirectoryOccupantCard,
  DirectorySkeletonCard,
} from "../../components/domains/directory"
import { MotionDiv, showContent } from "components/motions"
import { getIcon } from "utils/icon"

import { FooterAction } from "extensions/one-siam/components/domains/navigation/FooterAction"
import { SearchIcon, ShopIcon } from "extensions/one-siam/components/icons"

export const DirectoryKioskVPage = ({ Layout = Box }) => {
  const { t } = useTranslation()
  const {
    handleChangeParentCategory,
    handleChangeSubCategory,
    changeVenueFilter,
    handleOnClick,
    filteredOccupantsSorted,
    sortedParentCategoryWithOccupant,
    subCategoryWithActiveParentCategory,
    parentCategoryId,
    subCategoryId,
    venueFilter,
    ref,
    endScrollRef,
    hasNextPage,
    showSubCategoryFilter,
    venues,
    isMultiVenueProject,
    locationVenueId,
    hasData,
  } = useDirectory()

  const theme = useTheme()
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.directory) || <ShopIcon />,
    [theme]
  )
  const styleOverrides = theme.components[`DirectoryPage`]?.styleOverrides || {}
  const {
    root,
    filterSection,
    scrollPane,
    gridContainer,
    gridItem,
    categoryFilterBar,
    subCategoryFilterBar,
    emptyDataWrapper,
    navigationWrapper,
    mainMenuContainer,
    toolbarContainer,
    actionContainer,
    searchBarContainer,
    footerActionContainer,
  } = styleOverrides

  return (
    <>
      <Head title={t("Directory")} />
      <Layout>
        <Box ref={ref} sx={root}>
          {hasData ? (
            <MotionDiv
              variants={showContent}
              initial="initial"
              animate="animate"
              sx={gridContainer}
            >
              {filteredOccupantsSorted.map((occupant, index) => (
                <DirectoryOccupantCard
                  className={
                    occupant?.properties?.is_highlight ? "is-highlight" : ""
                  }
                  key={`directory-item-${index}`}
                  sx={gridItem}
                  onClick={handleOnClick}
                  data={occupant}
                />
              ))}
              {hasNextPage &&
                Array.from({ length: SKELETON_COUNT }).map((_, index) => (
                  <DirectorySkeletonCard
                    ref={index === SKELETON_INDEX_TO_REF ? endScrollRef : null}
                    key={index}
                    sx={gridItem}
                  />
                ))}
            </MotionDiv>
          ) : (
            <MotionDiv
              variants={showContent}
              initial="initial"
              animate="animate"
              sx={emptyDataWrapper}
            >
              <EmptyData
                icon={emptyIcon}
                primary={t("Coming soon")}
                secondary={t("Stay tuned for the update")}
              />
            </MotionDiv>
          )}
          <Box sx={filterSection}>
            <HorizontalScrollPane
              className="category-filterbar"
              stackProps={{
                sx: scrollPane,
              }}
            >
              <CategoryFilterBar
                sx={categoryFilterBar}
                activeCategory={parentCategoryId}
                categories={sortedParentCategoryWithOccupant}
                onChange={handleChangeParentCategory}
              />
            </HorizontalScrollPane>
            {showSubCategoryFilter && (
              <HorizontalScrollPane
                className="subCategory-filterbar"
                stackProps={{
                  sx: scrollPane,
                }}
              >
                <CategoryFilterBar
                  sx={subCategoryFilterBar}
                  activeCategory={subCategoryId}
                  categories={subCategoryWithActiveParentCategory}
                  onChange={handleChangeSubCategory}
                  renderFilterButton={CategoryFilterChip}
                />
              </HorizontalScrollPane>
            )}
          </Box>
          <Box key="app-navigation" sx={navigationWrapper}>
            <Box sx={toolbarContainer}>
              <Box sx={actionContainer}>
                {isMultiVenueProject && (
                  <VenueSelector
                    selectedVenue={venueFilter}
                    venues={venues}
                    onClickVenue={changeVenueFilter}
                    currentVenue={locationVenueId}
                  />
                )}
              </Box>
              <Box sx={searchBarContainer}>
                <SearchToggleButton
                  icon={SearchIcon}
                  placeholder={t("Search shops and more...")}
                  LinkComponent={Link}
                  to="/search"
                />
              </Box>
            </Box>
            <Box sx={mainMenuContainer}>
              <AppNavigation />
              <FooterAction sx={footerActionContainer} />
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  )
}
