import React, { useCallback, useMemo } from "react"
import { List, ListItemButton, useTheme } from "@mui/material"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import "@splidejs/react-splide/css"

export const OrdinalSelectSplide = (props) => {
  const {
    levels = [],
    onChangeOrdinal = () => {},
    selectedOrdinal = 0,
    options = {},
  } = props
  const theme = useTheme()
  const styleOverrides =
    theme.components[`OrdinalSelectSplide`]?.styleOverrides || {}
  const { listItemButton, root } = styleOverrides

  const handleChange = useCallback(
    (ordinal) => () => {
      onChangeOrdinal(ordinal)
    },
    [onChangeOrdinal]
  )
  const selectedLevelIndex = useMemo(
    () =>
      levels.findIndex(
        (level) => level.properties?.ordinal === selectedOrdinal
      ),
    [levels, selectedOrdinal]
  )

  const handleDragging = useCallback((splide) => {
    const slides = splide.root.querySelectorAll(" .splide__slide")
    slides.forEach((slide) => {
      slide.classList.add("is-dragging")
    })
  }, [])

  const handleDragged = useCallback((splide) => {
    const slides = splide.root.querySelectorAll(" .splide__slide")
    slides.forEach((slide) => {
      slide.classList.remove("is-dragging")
    })
  }, [])

  const handleChangeActiveSlide = useCallback(() => {
    const ordinal = document
      .querySelector(".splide__slide.is-active")
      .getAttribute("data-ordinal")
    onChangeOrdinal(+ordinal)
  }, [onChangeOrdinal])

  const handleOnReady = useCallback(
    (splide) => {
      //Move to current ordinal slide
      splide.go(selectedLevelIndex)
    },
    [selectedLevelIndex]
  )

  const handleClickSlide = useCallback((splide, { index }) => {
    splide.go(index)
  }, [])

  if (levels.length <= 0) return null
  return (
    <List sx={root}>
      <Splide
        options={{
          type: "slide",
          perPage: 5,
          pagination: false,
          arrows: false,
          focus: "center",
          drag: "free",
          snap: true,
          trimSpace: false,
          direction: "ttb",
          height: "352px",
          gap: "24px",
          updateOnMove: true,
          start: selectedLevelIndex,
          ...options,
        }}
        aria-label="Slide"
        onDrag={handleDragging}
        onActive={handleChangeActiveSlide}
        onDragged={handleDragged}
        onReady={handleOnReady}
        onClick={handleClickSlide}
      >
        {levels.map((level) => {
          return (
            <SplideSlide
              key={`ordinal-selector-${level.id}`}
              data-ordinal={level.properties.ordinal}
            >
              <ListItemButton
                sx={listItemButton}
                selected={level.properties?.ordinal === selectedOrdinal}
                onClick={handleChange(level.properties.ordinal)}
              >
                {level.properties.name?.en}
              </ListItemButton>
            </SplideSlide>
          )
        })}
      </Splide>
    </List>
  )
}
