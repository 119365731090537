import { useCallback, useMemo } from "react"
import { Box, ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"

import { getIcon } from "utils/icon"
import { useTranslation } from "providers/i18n"
import { useEvent } from "hooks/event/useEvent"

import { AppNavigation } from "containers/kiosk-v"
import { Carousel, CarouselItem } from "components/Carousel"
import { Head } from "components/Head"
import { EmptyData } from "components/EmptyData"
import { CalendarStarIcon } from "components/icons"
import { SearchToggleButton } from "components/domains/search"
import { Image } from "components/Image"
import { MotionDiv, showContent } from "components/motions"
import { FooterAction } from "extensions/one-siam/components/domains/navigation/FooterAction"
import { SearchIcon } from "extensions/one-siam/components/icons"

export const EventsKioskVPage = ({ Layout = Box }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { groupEvents, activeGroupEvent, handleChange } = useEvent()

  const theme = useTheme()
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.events) || <CalendarStarIcon />,
    [theme]
  )
  const { styleOverrides } = theme.components?.[`EventPage`] || {}
  const {
    root,
    carouselContainer,
    carousel,
    eventImage,
    eventGroupSelectorButton,
    emptyDataWrapper,
    navigationWrapper,
    toolbarContainer,
    searchBarContainer,
    toggleButtonGroup,
    toggleButton,
    mainMenuContainer,
    footerActionContainer,
  } = styleOverrides

  const handleClickSlide = useCallback(
    (_splide, { slide }, _event) => {
      navigate(slide.getAttribute("link"))
    },
    [navigate]
  )

  const activeEvents = groupEvents[activeGroupEvent]
  const slidePerPage = 3
  const carouselType =
    activeEvents && activeEvents.length < slidePerPage ? "slide" : "loop"
  return (
    <>
      <Head title={t("Events & Activities")} />
      <Layout>
        <Box sx={root}>
          <Box>
            <MotionDiv
              variants={showContent}
              initial="initial"
              animate="animate"
              sx={carouselContainer}
            >
              {activeEvents && activeEvents.length > 0 ? (
                <Carousel
                  onClick={handleClickSlide}
                  options={{
                    type: carouselType,
                    perPage: slidePerPage,
                    pagination: true,
                    focus: "center",
                    autoplay: true,
                    gap: "0",
                  }}
                  sx={carousel}
                >
                  {groupEvents[activeGroupEvent].map((event) => {
                    const sponsoredId = event?.properties?.sponsoredId
                    const coverImageSrc = event.properties.cover_image?.url
                    return (
                      <CarouselItem
                        link={`/events/${event.id}`}
                        key={`${sponsoredId || ""}${event.id}`}
                      >
                        <Image src={coverImageSrc} sx={eventImage} />
                      </CarouselItem>
                    )
                  })}
                </Carousel>
              ) : (
                <Box sx={emptyDataWrapper}>
                  <EmptyData icon={emptyIcon} primary={t("No Event")} />
                </Box>
              )}
              <Box sx={eventGroupSelectorButton}>
                <ToggleButtonGroup
                  value={activeGroupEvent}
                  onChange={handleChange}
                  exclusive
                  sx={toggleButtonGroup}
                >
                  <ToggleButton value="current" sx={toggleButton}>
                    {t("Current Events")}
                  </ToggleButton>
                  <ToggleButton value="upcoming" sx={toggleButton}>
                    {t("Upcoming Events")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </MotionDiv>
          </Box>
          <Box key="app-navigation" sx={navigationWrapper}>
            <Box sx={toolbarContainer}>
              <Box sx={searchBarContainer}>
                <SearchToggleButton
                  icon={SearchIcon}
                  placeholder={t("Search shops and more...")}
                  LinkComponent={Link}
                  to="/search"
                />
              </Box>
            </Box>
            <Box sx={mainMenuContainer}>
              <AppNavigation />
              <FooterAction sx={footerActionContainer} />
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  )
}
