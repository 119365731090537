import { SvgIcon, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"
import DOMPurify from "dompurify"
import parse from "html-react-parser"
import isSvg from "is-svg"

import { ReactComponent as KeyboardBackspaceIconSVG } from "assets/svg/keyboard-backspace-icon.svg"
import { ReactComponent as PhoneSVG } from "assets/svg/phone.svg"
import { ReactComponent as ShareSVG } from "assets/svg/share.svg"
import { ReactComponent as EscalatorSVG } from "assets/svg/escalator.svg"
import { ReactComponent as ElevatorSVG } from "assets/svg/elevator.svg"
import { ReactComponent as StairsSVG } from "assets/svg/stairs.svg"
import { ReactComponent as ATMSVG } from "assets/svg/atm.svg"
import { ReactComponent as CurrencyExchangeSVG } from "assets/svg/currencyexchange.svg"
import { ReactComponent as RestroomFemaleSVG } from "assets/svg/restroom.female.svg"
import { ReactComponent as RestroomMaleSVG } from "assets/svg/restroom.male.svg"
import { ReactComponent as RestroomSVG } from "assets/svg/restroom.svg"
import { ReactComponent as RestroomWheelchairSVG } from "assets/svg/restroom.wheelchair.svg"
import { ReactComponent as ParkingSVG } from "assets/svg/parking.svg"
import { ReactComponent as InformationSVG } from "assets/svg/information.svg"
import { ReactComponent as ArrowLeftSVG } from "assets/svg/arrow-left.svg"
import { ReactComponent as ArrowRightSVG } from "assets/svg/arrow-right.svg"
import { ReactComponent as ArrowPreviousSVG } from "assets/svg/arrow-previous.svg"
import { ReactComponent as ArrowNextSVG } from "assets/svg/arrow-next.svg"
import { ReactComponent as PrayerroomSVG } from "assets/svg/prayerroom.svg"
import { ReactComponent as FirstaidSVG } from "assets/svg/firstaid.svg"
import { ReactComponent as CurrentLocationSVG } from "assets/svg/current-location.svg"
import { ReactComponent as OutlineStarSVG } from "assets/svg/outline-star.svg"
import { ReactComponent as NearMeSVG } from "assets/svg/nearme.svg"
import { ReactComponent as ListSVG } from "assets/svg/list.svg"
import { ReactComponent as OriginMarkerSVG } from "assets/svg/origin-marker.svg"
import { ReactComponent as CalendarStarSVG } from "assets/svg/calendar-star.svg"
import { ReactComponent as LocationSVG } from "assets/svg/location.svg"
import { ReactComponent as CalendarSVG } from "assets/svg/calendar.svg"
import { ReactComponent as StarSVG } from "assets/svg/star.svg"
import { ReactComponent as MoreSVG } from "assets/svg/more-square.svg"
import { ReactComponent as EmptyCalendarSVG } from "assets/svg/empty-calendar.svg"
import { ReactComponent as CloseIconSVG } from "assets/svg/close.svg"
import { ReactComponent as BikeSVG } from "assets/svg/bike.svg"
import { ReactComponent as CigaretteSVG } from "assets/svg/cigarette.svg"
import { ReactComponent as MotorcycleSVG } from "assets/svg/motorcycle.svg"
import { ReactComponent as WheelchairSVG } from "assets/svg/wheelchair.svg"
import { ReactComponent as TaxiSVG } from "assets/svg/taxi.svg"
import { ReactComponent as BusSVG } from "assets/svg/bus.svg"
import { ReactComponent as SearchSVG } from "assets/svg/search.svg"
import { ReactComponent as ExitSVG } from "assets/svg/exit.svg"

import { ReactComponent as MapSVG } from "assets/svg/map.svg"
import { ReactComponent as DirectorySVG } from "assets/svg/directory.svg"
import { ReactComponent as HomeSVG } from "assets/svg/home.svg"
import { ReactComponent as PercentSVG } from "assets/svg/percentage.svg"
import { ReactComponent as ShopSVG } from "assets/svg/shop.svg"
import { ReactComponent as StoreSVG } from "assets/svg/store.svg"
import { ReactComponent as DiamondSVG } from "assets/svg/diamond.svg"
import { ReactComponent as DiningSVG } from "assets/svg/dining.svg"
import { ReactComponent as WebsiteSVG } from "assets/svg/website.svg"
import { ReactComponent as MarkerSVG } from "assets/svg/marker.svg"
import { ReactComponent as TabSVG } from "assets/svg/tab.svg"
import { ReactComponent as KioskSVG } from "assets/svg/kiosk.svg"
import { ReactComponent as ScanQRCodeSVG } from "assets/svg/scan-qr-code.svg"
import { ReactComponent as YouAreHereChipSVG } from "assets/svg/you-are-here-chip.svg"
import { ReactComponent as CompareArrowsSVG } from "assets/svg/comparearrows.svg"
import { ReactComponent as YouAreHereMarkerSVG } from "assets/svg/you-are-here-marker.svg"
import { ReactComponent as PauseSVG } from "assets/svg/pause.svg"
import { ReactComponent as RefreshArrowSVG } from "assets/svg/refresh-arrow.svg"
import { ReactComponent as PlayArrowSVG } from "assets/svg/play-arrow.svg"

import { getIconPathString } from "./pathString"

/**
 * Curated list of Icons that we use in the UI
 */
export { default as BackIcon } from "@mui/icons-material/ArrowBack"
export { default as PlaceIcon } from "@mui/icons-material/Place"

export const ArrowLeftIcon = (props) => (
  <SvgIcon component={ArrowLeftSVG} inheritViewBox {...props} />
)

export { default as CloseCircleOutlineIcon } from "@mui/icons-material/HighlightOff"

export const PrevStepIcon = (props) => (
  <SvgIcon component={ArrowLeftSVG} inheritViewBox {...props} />
)

export const NextStepIcon = (props) => (
  <SvgIcon component={ArrowRightSVG} inheritViewBox {...props} />
)

export const CurrentLocationIcon = (props) => (
  <SvgIcon component={CurrentLocationSVG} inheritViewBox {...props} />
)

export const ShareIcon = (props) => (
  <SvgIcon component={ShareSVG} inheritViewBox {...props} />
)
export const PhoneIcon = (props) => (
  <SvgIcon component={PhoneSVG} inheritViewBox {...props} />
)

export const EscalatorIcon = (props) => (
  <SvgIcon component={EscalatorSVG} inheritViewBox {...props} />
)
export const ElevatorIcon = (props) => (
  <SvgIcon component={ElevatorSVG} inheritViewBox {...props} />
)
export const StairsIcon = (props) => (
  <SvgIcon component={StairsSVG} inheritViewBox {...props} />
)
export const ATMIcon = (props) => (
  <SvgIcon component={ATMSVG} inheritViewBox {...props} />
)
export const CurrencyExchangeIcon = (props) => (
  <SvgIcon component={CurrencyExchangeSVG} inheritViewBox {...props} />
)
export const RestroomFemaleIcon = (props) => (
  <SvgIcon component={RestroomFemaleSVG} inheritViewBox {...props} />
)
export const RestroomMaleIcon = (props) => (
  <SvgIcon component={RestroomMaleSVG} inheritViewBox {...props} />
)
export const RestroomIcon = (props) => (
  <SvgIcon component={RestroomSVG} inheritViewBox {...props} />
)
export const RestroomWheelchairIcon = (props) => (
  <SvgIcon component={RestroomWheelchairSVG} inheritViewBox {...props} />
)
export const ParkingIcon = (props) => (
  <SvgIcon component={ParkingSVG} inheritViewBox {...props} />
)
export const InformationIcon = (props) => (
  <SvgIcon component={InformationSVG} inheritViewBox {...props} />
)

export const PrayerroomIcon = (props) => (
  <SvgIcon component={PrayerroomSVG} inheritViewBox {...props} />
)

export const FirstaidIcon = (props) => (
  <SvgIcon component={FirstaidSVG} inheritViewBox {...props} />
)

export const OutlineStarIcon = (props) => (
  <SvgIcon component={OutlineStarSVG} inheritViewBox {...props} />
)

export const NearMeIcon = (props) => (
  <SvgIcon component={NearMeSVG} inheritViewBox {...props} />
)

export const ListIcon = (props) => (
  <SvgIcon component={ListSVG} inheritViewBox {...props} />
)

export const OriginMarkerIcon = (props) => (
  <SvgIcon component={OriginMarkerSVG} inheritViewBox {...props} />
)

export const DestinationMarkerIcon = (props) => (
  <SvgIcon component={MarkerSVG} inheritViewBox {...props} />
)

export const CalendarStarIcon = (props) => (
  <SvgIcon component={CalendarStarSVG} inheritViewBox {...props} />
)

export const LocationIcon = (props) => (
  <SvgIcon component={LocationSVG} inheritViewBox {...props} />
)

export const CalendarIcon = (props) => (
  <SvgIcon component={CalendarSVG} inheritViewBox {...props} />
)

export const StarIcon = (props) => (
  <SvgIcon component={StarSVG} inheritViewBox {...props} />
)

export const MoreIcon = (props) => (
  <SvgIcon component={MoreSVG} inheritViewBox {...props} />
)

export const EmptyCalendarIcon = (props) => (
  <SvgIcon component={EmptyCalendarSVG} inheritViewBox {...props} />
)

export const CustomIcon = ({ path = "", ...props }) => {
  return (
    <SvgIcon {...props}>
      {isSvg(path) ? parse(DOMPurify.sanitize(path)) : <path d={path} />}
    </SvgIcon>
  )
}

export const CustomImageIcon = ({ src, ...props }) => (
  <SvgIcon {...props}>
    <image href={src} height="100%" width="100%" />
  </SvgIcon>
)

export const MapIcon = (props) => (
  <SvgIcon component={MapSVG} inheritViewBox {...props} />
)

export const DirectoryIcon = (props) => (
  <SvgIcon component={DirectorySVG} inheritViewBox {...props} />
)

export const HomeIcon = (props) => (
  <SvgIcon component={HomeSVG} inheritViewBox {...props} />
)

export const ExitIcon = (props) => (
  <SvgIcon component={ExitSVG} inheritViewBox {...props} />
)

export const PercentIcon = (props) => (
  <SvgIcon component={PercentSVG} inheritViewBox {...props} />
)

export const ShopIcon = (props) => (
  <SvgIcon component={ShopSVG} inheritViewBox {...props} />
)

export const StoreIcon = (props) => (
  <SvgIcon component={StoreSVG} inheritViewBox {...props} />
)

export const DiamondIcon = (props) => (
  <SvgIcon component={DiamondSVG} inheritViewBox {...props} />
)

export const DiningIcon = (props) => (
  <SvgIcon component={DiningSVG} inheritViewBox {...props} />
)

export const WebsiteIcon = (props) => (
  <SvgIcon component={WebsiteSVG} inheritViewBox {...props} />
)

export const MarkerIcon = (props) => (
  <SvgIcon component={MarkerSVG} inheritViewBox {...props} />
)

export const KeyboardBackspaceIcon = (props) => (
  <SvgIcon component={KeyboardBackspaceIconSVG} inheritViewBox {...props} />
)
export const CloseIcon = (props) => (
  <SvgIcon component={CloseIconSVG} inheritViewBox {...props} />
)

export const WheelchairIcon = (props) => (
  <SvgIcon component={WheelchairSVG} inheritViewBox {...props} />
)
export const BikeIcon = (props) => (
  <SvgIcon component={BikeSVG} inheritViewBox {...props} />
)
export const CigaretteIcon = (props) => (
  <SvgIcon component={CigaretteSVG} inheritViewBox {...props} />
)
export const MotorcycleIcon = (props) => (
  <SvgIcon component={MotorcycleSVG} inheritViewBox {...props} />
)
export const TaxiIcon = (props) => (
  <SvgIcon component={TaxiSVG} inheritViewBox {...props} />
)
export const BusIcon = (props) => (
  <SvgIcon component={BusSVG} inheritViewBox {...props} />
)
export const SearchIcon = (props) => (
  <SvgIcon component={SearchSVG} inheritViewBox {...props} />
)
export const CompareArrowsIcon = (props) => (
  <SvgIcon component={CompareArrowsSVG} inheritViewBox {...props} />
)
export const ArrowPreviousIcon = (props) => (
  <SvgIcon component={ArrowPreviousSVG} inheritViewBox {...props} />
)
export const ArrowNextIcon = (props) => (
  <SvgIcon component={ArrowNextSVG} inheritViewBox {...props} />
)
export const PauseIcon = (props) => (
  <SvgIcon component={PauseSVG} inheritViewBox {...props} />
)
export const RefreshArrowIcon = (props) => (
  <SvgIcon component={RefreshArrowSVG} inheritViewBox {...props} />
)
export const PlayArrowIcon = (props) => (
  <SvgIcon component={PlayArrowSVG} inheritViewBox {...props} />
)

export const NewStoreIcon = ({ fill, ...props }) => {
  const theme = useTheme()
  const fillColor = fill || theme.palette.primary.main
  return (
    <SvgIcon inheritViewBox {...props}>
      <svg
        width="291"
        height="219"
        viewBox="0 0 291 219"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M206.623 187.573C206.623 187.573 255.194 170.154 279.154 134.012C304.033 96.485 287.743 40.0613 227.275 33.1516C189.691 28.8568 168.88 36.2128 142.031 61.338C132.75 70.024 123.17 78.6589 110.974 82.9367C83.8149 92.4631 49.8774 85.7027 26.0468 103.628C17.7609 109.86 -1.48137 121.375 5.49528 153.756C11.7 182.554 38.7739 187.573 38.7739 187.573H206.623Z"
          fill="#FFECEF"
        />
        <path
          d="M221.249 58.9609H60.8477V184.96H221.249V58.9609Z"
          fill="#F9F7F6"
        />
        <path
          d="M223.078 177.177H59.0212V184.962H223.078V177.177Z"
          fill="#F9F7F6"
        />
        <path
          d="M224.848 50.958H57.249V58.9658H224.848V50.958Z"
          fill="#F7FAFC"
        />
        <path
          d="M224.848 56.3564H57.249V58.9657H224.848V56.3564Z"
          fill="#D5E4F5"
        />
        <path
          d="M229.708 50.9584H52.3887V43.4103C52.3887 42.8082 52.877 42.3213 53.4781 42.3213H228.619C229.221 42.3213 229.708 42.8095 229.708 43.4103V50.9584Z"
          fill="#F7FAFC"
        />
        <path
          d="M229.708 48.3496H52.3887V50.9589H229.708V48.3496Z"
          fill="#D5E4F5"
        />
        <path
          d="M224.587 64.7905V84.9174H54.4587V67.9359L61.0602 61.1104H221.03L224.587 64.7905Z"
          fill={fillColor}
        />
        <path
          opacity="0.5"
          d="M221.249 84.9248V94.0306C221.187 93.5684 221.16 93.0931 221.16 92.6166C221.153 98.9073 216.06 103.999 209.767 103.999H209.663C203.376 103.999 198.276 98.9086 198.27 92.6166C198.27 98.9073 193.163 103.999 186.876 103.999H186.773C180.48 103.999 175.379 98.9086 175.379 92.6166C175.379 98.9073 170.279 103.999 163.98 103.999H163.882C157.596 103.999 152.489 98.9086 152.489 92.6166C152.483 98.9073 147.383 103.999 141.096 103.999H140.992C134.699 103.999 129.605 98.9086 129.599 92.6166C129.599 98.9073 124.492 103.999 118.199 103.999H118.102C111.809 103.999 106.709 98.9086 106.709 92.6166C106.702 98.9073 101.609 103.999 95.3154 103.999H95.2143C88.9276 103.999 83.821 98.9086 83.8146 92.6166C83.8146 98.9073 78.7145 103.999 72.4213 103.999H72.3241C66.0309 103.999 60.9308 98.9086 60.9308 92.6166C60.9308 93.0789 60.9036 93.5347 60.8479 93.975V84.9248H221.249V84.9248Z"
          fill={fillColor}
        />
        <path
          d="M96.6133 58.918L83.8235 82.7224H60.9307L78.84 58.918H96.6133Z"
          fill="#F9F7F6"
        />
        <path
          d="M132.159 58.918L129.603 82.7224H106.708L114.385 58.918H132.159Z"
          fill="#F9F7F6"
        />
        <path
          d="M167.712 58.918L175.381 82.7224H152.496L149.94 58.918H167.712Z"
          fill="#F9F7F6"
        />
        <path
          d="M203.257 58.918L221.167 82.7224H198.275L185.485 58.918H203.257Z"
          fill="#F9F7F6"
        />
        <path
          d="M78.84 58.918L60.9307 82.7224H38.0378L61.0602 58.918H78.84Z"
          fill={fillColor}
        />
        <path
          d="M114.385 58.918L106.709 82.7224H83.8235L96.6133 58.918H114.385Z"
          fill={fillColor}
        />
        <path
          d="M149.94 58.918L152.496 82.7224H129.603L132.159 58.918H149.94Z"
          fill={fillColor}
        />
        <path
          d="M185.485 58.918L198.275 82.7224H175.381L167.712 58.918H185.485Z"
          fill={fillColor}
        />
        <path
          d="M221.03 58.918L244.06 82.7224H221.167L203.257 58.918H221.03Z"
          fill={fillColor}
        />
        <path
          d="M232.655 101.807H232.556C226.262 101.807 221.16 96.7074 221.16 90.4155V82.7275H244.05V90.4155C244.051 96.7074 238.949 101.807 232.655 101.807Z"
          fill={fillColor}
        />
        <path
          d="M209.765 101.807H209.665C203.372 101.807 198.27 96.7074 198.27 90.4155V82.7275H221.161V90.4155C221.161 96.7074 216.058 101.807 209.765 101.807Z"
          fill="#EAE5D8"
        />
        <path
          d="M186.875 101.807H186.775C180.482 101.807 175.379 96.7074 175.379 90.4155V82.7275H198.269V90.4155C198.271 96.7074 193.168 101.807 186.875 101.807Z"
          fill={fillColor}
        />
        <path
          d="M163.985 101.807H163.885C157.592 101.807 152.489 96.7074 152.489 90.4155V82.7275H175.381V90.4155C175.381 96.7074 170.278 101.807 163.985 101.807Z"
          fill="#EAE5D8"
        />
        <path
          d="M141.095 101.807H140.995C134.702 101.807 129.599 96.7074 129.599 90.4155V82.7275H152.489V90.4155C152.489 96.7074 147.388 101.807 141.095 101.807Z"
          fill={fillColor}
        />
        <path
          d="M118.204 101.807H118.104C111.811 101.807 106.708 96.7074 106.708 90.4155V82.7275H129.599V90.4155C129.599 96.7074 124.497 101.807 118.204 101.807Z"
          fill="#EAE5D8"
        />
        <path
          d="M95.314 101.807H95.2142C88.9211 101.807 83.8184 96.7074 83.8184 90.4155V82.7275H106.709V90.4155C106.709 96.7074 101.607 101.807 95.314 101.807Z"
          fill={fillColor}
        />
        <path
          d="M72.4236 101.807H72.3239C66.0307 101.807 60.928 96.7074 60.928 90.4155V82.7275H83.8182V90.4155C83.8182 96.7074 78.7168 101.807 72.4236 101.807Z"
          fill="#EAE5D8"
        />
        <path
          d="M49.5335 101.807H49.4337C43.1405 101.807 38.0378 96.7074 38.0378 90.4155V82.7275H60.9281V90.4155C60.9281 96.7074 55.8267 101.807 49.5335 101.807Z"
          fill={fillColor}
        />
        <path
          d="M116.746 105.844H76.4214V184.962H116.746V105.844Z"
          fill={fillColor}
        />
        <path
          d="M114.045 108.992H79.1199V184.96H114.045V108.992Z"
          fill={fillColor}
        />
        <path
          d="M113.354 109.682H79.1199V184.96H113.354V109.682Z"
          fill={fillColor}
        />
        <path
          d="M113.355 109.682H79.8103V184.96H113.355V109.682Z"
          fill={fillColor}
        />
        <path
          d="M88.1076 148.619C88.1076 150.109 86.899 151.317 85.4079 151.317C83.9169 151.317 82.7083 150.109 82.7083 148.619C82.7083 147.128 83.9169 145.92 85.4079 145.92C86.899 145.92 88.1076 147.128 88.1076 148.619Z"
          fill="#F7F7FF"
        />
        <path
          d="M88.798 147.928C88.798 149.419 87.5894 150.627 86.0984 150.627C84.6073 150.627 83.3987 149.419 83.3987 147.928C83.3987 146.438 84.6073 145.229 86.0984 145.229C87.5894 145.229 88.798 146.438 88.798 147.928Z"
          fill="#F7FAFB"
        />
        <path
          d="M86.0984 150.323C87.422 150.323 88.495 149.251 88.495 147.928C88.495 146.605 87.422 145.532 86.0984 145.532C84.7749 145.532 83.7019 146.605 83.7019 147.928C83.7019 149.251 84.7749 150.323 86.0984 150.323Z"
          fill="#D7E8EF"
        />
        <path
          d="M86.1541 149.03C87.0799 149.03 87.8304 148.28 87.8304 147.354C87.8304 146.429 87.0799 145.679 86.1541 145.679C85.2283 145.679 84.4778 146.429 84.4778 147.354C84.4778 148.28 85.2283 149.03 86.1541 149.03Z"
          fill="#F7FAFB"
        />
        <path
          d="M208.887 163.906V106.862H128.958V163.906H126.708V169.124H211.407V163.906H208.887Z"
          fill={fillColor}
        />
        <path
          d="M210.266 105.483H130.337V165.407H210.266V105.483Z"
          fill={fillColor}
        />
        <path
          d="M208.014 107.553H132.589V163.717H208.014V107.553Z"
          fill={fillColor}
        />
        <path
          d="M212.786 162.527H128.087V167.746H212.786V162.527Z"
          fill={fillColor}
        />
        <path
          d="M111.174 111.973H81.9905V143.391H111.174V111.973Z"
          fill="#18E3F9"
        />
        <path
          d="M97.5926 111.63H95.5718V143.732H97.5926V111.63Z"
          fill={fillColor}
        />
        <path
          d="M112.64 126.672H80.5254V128.692H112.64V126.672Z"
          fill={fillColor}
        />
        <path
          d="M111.174 152.538H81.9905V182.66H111.174V152.538Z"
          stroke="#F7F7FF"
          stroke-miterlimit="10"
        />
        <path
          d="M236.419 184.961H37.5728V191.006H236.419V184.961Z"
          fill={fillColor}
        />
      </svg>
    </SvgIcon>
  )
}

export const MegaPhoneIcon = ({ fill, ...props }) => {
  const theme = useTheme()
  const fillColor = fill || theme.palette.primary.main
  const contrastColor = theme.palette.background.paper
  return (
    <SvgIcon inheritViewBox {...props}>
      <svg
        width="250"
        height="175"
        viewBox="0 0 250 175"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_261_3804)">
          <path
            opacity="0.2"
            d="M223.5 126.5C218.859 140.01 209.03 146.076 201 150.124C177.729 163.55 155 140.102 117 154C92.319 172.102 78.183 172.007 43.0006 165.124C24.314 162.764 5.21203 129.665 12.4994 114.5C23.3115 91.9995 31.5777 86.5182 30.375 58.8897C28.9256 25.6071 51.9372 13.4964 97.8193 31.3546C121.65 40.6303 175.797 12.1144 190.681 30.2301C203.614 45.9718 195.748 67.642 210.532 74.8759C227.797 83.3277 228.142 112.989 223.5 126.5Z"
            fill={fillColor}
          />
          <g clipPath="url(#clip1_261_3804)">
            <path
              d="M63.6729 126.283L80.1776 121.378L80.8969 123.817C81.4403 125.661 80.3904 127.601 78.5547 128.147L68.7055 131.074C66.8697 131.62 64.9369 130.566 64.3935 128.723L63.6742 126.283L63.6729 126.283Z"
              fill={contrastColor}
            />
            <path
              d="M83.9499 163.594L83.393 163.76C80.32 164.673 77.0924 162.913 76.1832 159.827L67.7871 131.349L79.472 127.876L87.8681 156.354C88.7773 159.44 87.0243 162.681 83.9513 163.594H83.9499Z"
              fill={fillColor}
            />
            <path
              d="M64.9202 90.777L67.6938 100.185L72.1787 115.4L74.9523 124.807C98.2372 117.887 123.736 120.447 148.153 132.157C152.472 134.229 156.117 129.598 154.497 124.098L145.507 93.6053L141.022 78.3903L132.033 47.8973C130.412 42.3973 124.844 40.5057 122.344 44.6048C108.209 67.7734 88.2064 83.8567 64.9216 90.777H64.9202Z"
              fill={contrastColor}
            />
            <path
              d="M66.9 127.407L83.3191 122.746L73.0558 87.9346L56.7468 92.9662C46.166 96.109 39.8627 106.369 42.6656 115.879C45.4698 125.39 56.3193 130.55 66.8987 127.406L66.9 127.407Z"
              fill={fillColor}
            />
            <path
              d="M152.434 133.94L151.852 134.114C148.647 135.066 145.28 133.23 144.332 130.012L120.299 48.4912C119.351 45.2729 121.179 41.8924 124.385 40.9403L124.967 40.767C128.172 39.8149 131.539 41.6506 132.487 44.8689L156.52 126.39C157.468 129.608 155.64 132.988 152.434 133.94Z"
              fill={fillColor}
            />
            <path
              d="M161.442 79.5181C160.144 79.5181 158.948 78.6681 158.561 77.3553C158.09 75.7573 158.998 74.0783 160.589 73.6043L182.898 66.9733C184.49 66.5008 186.162 67.4123 186.634 69.0103C187.105 70.6083 186.197 72.2874 184.605 72.7613L162.297 79.3923C162.012 79.4762 161.724 79.5167 161.442 79.5167V79.5181Z"
              fill={contrastColor}
            />
            <path
              d="M149.846 59.0576C149.172 59.0576 148.495 58.8311 147.935 58.3683C146.654 57.3072 146.472 55.4059 147.529 54.1197L162.33 36.0973C163.385 34.8111 165.28 34.628 166.561 35.6891C167.842 36.7502 168.025 38.6516 166.968 39.9378L152.167 57.9601C151.572 58.6843 150.713 59.0576 149.846 59.0576Z"
              fill={contrastColor}
            />
            <path
              d="M184.994 110.046C184.694 110.046 184.389 110.002 184.089 109.905L161.902 102.877C160.319 102.375 159.441 100.681 159.941 99.0912C160.44 97.5016 162.128 96.6194 163.711 97.1213L185.897 104.149C187.481 104.651 188.359 106.346 187.859 107.935C187.454 109.223 186.271 110.046 184.994 110.046Z"
              fill={contrastColor}
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_261_3804">
            <rect width="250" height="175" fill={contrastColor} />
          </clipPath>
          <clipPath id="clip1_261_3804">
            <rect
              width="146"
              height="129"
              fill="white"
              transform="translate(42 35)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export const TabIcon = (props) => (
  <SvgIcon component={TabSVG} inheritViewBox {...props} />
)

export const KioskIcon = (props) => (
  <SvgIcon component={KioskSVG} inheritViewBox {...props} />
)

export const ScanQRCodeIcon = (props) => (
  <SvgIcon component={ScanQRCodeSVG} inheritViewBox {...props} />
)

export const YouAreHereChipIcon = (props) => (
  <SvgIcon component={YouAreHereChipSVG} inheritViewBox {...props} />
)

export const YouAreHereMarkerIcon = ({ sx, ...props }) => {
  const theme = useTheme()
  const { root } = theme.components[`AppYouAreHereMarker`]?.styleOverrides || {}
  return (
    <SvgIcon
      sx={deepmerge(root, sx)}
      component={YouAreHereMarkerSVG}
      inheritViewBox
      {...props}
    />
  )
}

// TODO: Replace all amenity icon by retrieving path from getIconPathString
export const MeetingPointIcon = (props) => (
  <SvgIcon {...props}>
    <path d={getIconPathString("amenity.meetingpoint")} />
  </SvgIcon>
)
