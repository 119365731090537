import { useEffect, useMemo } from "react"
import _ from "lodash"

import { useVenue } from "providers/venue"
import { useIndoorMap } from "providers/venue/modules/indoormap/hooks/useIndoorMap"

import { getSuitablyValueBetweenBearings } from "components/IndoorMap"
import { AmenityData, OccupantData } from "providers/venue/types"
import { useGeoLocation } from "providers/geolocation"

const DEFAULT_OPTIONS = {
  offset: { top: 0, left: 0, right: 0, bottom: 0 },
  enabled: true,
}

export const useHighLightFeaturesMap = (
  features: (OccupantData | AmenityData)[],
  ordinal: number,
  options = DEFAULT_OPTIONS
) => {
  const { indoorRef, mapLoaded } = useIndoorMap()
  const { dataLoaded } = useVenue()
  const { userLocation } = useGeoLocation()
  const { offset, enabled } = { ...DEFAULT_OPTIONS, ...options }
  const mapReady = enabled && indoorRef.current && mapLoaded && dataLoaded

  const { bottom, top, left, right } = offset

  const offsetOption = useMemo(
    () => ({ bottom, top, left, right }),
    [bottom, top, left, right]
  )

  useEffect(() => {
    if (mapReady) {
      indoorRef.current.clearAnimations()
      indoorRef.current.hideVenueObjects()
      indoorRef.current.clearNavigationGeometries()
      indoorRef.current.enableClick()
      indoorRef.current.setFeatureObject3DsOpacity(1)
    }
  }, [mapReady, indoorRef])

  useEffect(() => {
    const indoorMap = indoorRef.current
    if (mapReady && indoorMap) {
      // clear all highlight in current level before change ordinal
      indoorRef.current.clearHighlightElements()
      indoorRef.current.clearHighlightObject()
      indoorRef.current.changeLevelByOrdinal(ordinal)

      const featuresOnOrdinal = features.filter(
        (feature) => feature.properties.ordinal === ordinal
      )
      const filteredFeatureIds = featuresOnOrdinal.map((feature) => feature.id)
      try {
        const currentBearing = indoorRef.current.getBearing()
        const defaultBearing = indoorRef.current.defaultBearing
        const newBearing = getSuitablyValueBetweenBearings(
          defaultBearing,
          currentBearing
        )

        const highlightedFeatures =
          indoorRef.current.setHighlightElementIds(filteredFeatureIds)

        if (highlightedFeatures && !_.isEmpty(highlightedFeatures.features)) {
          // Handle Fly to animation
          userLocation &&
            userLocation.properties.ordinal === ordinal &&
            highlightedFeatures.features.push(userLocation)

          const extent = indoorRef.current.getFeatureExtent(highlightedFeatures)
          const zoom = indoorRef.current.getExtentZoom(extent, {
            isFraction: true,
            paddingLeft: 64,
            paddingRight: 64,
            paddingTop: 64,
            paddingBottom: 64,
          })
          const center = indoorRef.current.getExtentCenter(extent)
          const newCenter = indoorRef.current.getTargetViewCenter(
            {
              bearing: newBearing,
              center,
              pitch: 0,
              zoom: zoom - 0.5,
            },
            { offset: offsetOption }
          )
          indoorRef.current.flyTo(newCenter, {
            zoom,
          })
        } else {
          const defaultZoom = indoorRef.current.defaultZoom
          const defaultCenter = indoorRef.current.defaultCenter
          indoorRef.current.flyTo(defaultCenter, {
            zoom: defaultZoom,
            bearing: newBearing,
            pitch: 45,
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [features, indoorRef, mapReady, ordinal, userLocation, offsetOption])
}
