import React, { useCallback, useEffect, useMemo } from "react"
import { useLocation } from "react-router"
import { IconButton, useTheme } from "@mui/material"

import { getIcon } from "utils/icon"

import { useAppUI } from "providers/ui"

import { MenuIcon } from "../icons"

export const HeaderMenuButton = ({ size = "large", ...props }) => {
  const theme = useTheme()
  const location = useLocation()
  const { setSidebarState } = useAppUI()
  const styleOverrides =
    theme.components[`AppHeaderMenuButton`]?.styleOverrides || {}
  const { root, icon } = styleOverrides

  const menuIcon = useMemo(
    () => getIcon(theme?.assets?.menu, icon) || <MenuIcon />,
    [theme, icon]
  )

  useEffect(() => {
    setSidebarState(false)
  }, [location.pathname, setSidebarState])

  const openSidebar = useCallback(() => {
    setSidebarState(true)
  }, [setSidebarState])

  return (
    <IconButton
      size={size}
      edge="start"
      color="inherit"
      aria-label="back"
      sx={root}
      onClick={openSidebar}
      {...props}
    >
      {menuIcon}
    </IconButton>
  )
}
