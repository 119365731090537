import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import uniqBy from "lodash/uniqBy"
import get from "lodash/get"
import orderBy from "lodash/orderBy"

import { useVenue } from "providers/venue"
import { generateMenuConfig } from "./utils"
import {
  navigations as defaultNavigations,
  bottomNavigations as defaultBottomNavigations,
  kioskNavigations as defaultKioskNavigations,
  kioskMainNavigations as defaultKioskMainNavigations,
} from "standard"

export const useAppNavigation = () => {
  const location = useLocation()
  const { appConfig } = useVenue()
  const {
    mobileNavigations = [],
    mobileMainNavigations = [],
    kioskNavigation = [],
    kioskMainNavigations = [],
  } = useMemo(
    () => ({
      mobileNavigations: generateMenuConfig(
        defaultNavigations,
        get(appConfig, "mobile.navigation.menus")
      ),
      mobileMainNavigations: generateMenuConfig(
        defaultBottomNavigations,
        get(appConfig, "mobile.navigation.main")
      ),
      kioskNavigation: generateMenuConfig(
        defaultKioskNavigations,
        get(appConfig, "kiosk.navigation.menus")
      ),
      kioskMainNavigations: generateMenuConfig(
        defaultKioskMainNavigations,
        get(appConfig, "kiosk.navigation.main")
      ),
    }),
    [appConfig]
  )

  const allNavigations = useMemo(
    () =>
      uniqBy([...mobileNavigations, ...kioskNavigation], (navigation) =>
        get(navigation, "to")
      ),
    [mobileNavigations, kioskNavigation]
  )

  /** Find matched path to add "select" state to navs
   *  - add /* so that all sub-pages will match too, (eg. /maps/ordinal/1, /maps/place/:placeId will also set `/maps` to selected)
   */
  const orderedMenus = useMemo(
    () => orderBy(allNavigations, ["to"], ["desc"]),
    [allNavigations]
  )

  const matchedPath = useMemo(() => {
    const { pathname: currentPathName, search = "" } = location
    const currentQueryParams = new URLSearchParams(search)
    return orderedMenus.find((nav) => {
      const [navPathname, navQueryString = ""] = nav.to.split("?")
      const navQueryParams = new URLSearchParams(navQueryString)
      const isMatchedPath = currentPathName.indexOf(navPathname) === 0

      if (!isMatchedPath) return false

      //@ts-ignore
      if (navQueryParams.size === 0) return isMatchedPath

      // If the config path includes a query string, we will prioritize matching the query string.
      for (const p of navQueryParams) {
        const [key, value] = p
        //@ts-ignore
        if (!currentQueryParams.has(key, value)) return false
      }

      return true
    })
  }, [orderedMenus, location])

  return {
    navigations: allNavigations,
    matchedPath,
    mobileNavigations,
    mobileMainNavigations,
    kioskNavigation,
    kioskMainNavigations,
  }
}
