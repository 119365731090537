import React from "react"
import { Outlet } from "react-router-dom"
import Div100vh from "react-div-100vh"
import { Box, useTheme } from "@mui/material"
import { get } from "lodash"

import { useSponsoredFooterBanner } from "hooks/sponsored-content/useSponsoredFooterBanner"
import { useElectronConfig } from "hooks/useElectronConfig"

import { IndoorMapContainer } from "containers/IndoorMapContainer"
/* import { SearchContainer } from "containers/SearchContainer"
 */
import { AdMediaFooterCarousel } from "components/domains/ads"
/* import { SearchPopupContent } from "components/domains/search/kiosk-v/SearchPopupContent"
 */
import { Header, HeaderLogo } from "components/Header"
import { Clock } from "components/Clock"
import { Footer } from "components/Footer"
import { Image } from "components/Image"

export const AppLayout = () => {
  const theme = useTheme()
  const styleOverrides = theme.components[`AppLayout`]?.styleOverrides || {}
  const { root, headerWrapper, contentWrapper, footerWrapper } = styleOverrides
  const { footerBanners, footerBannerConfig } = useSponsoredFooterBanner()
  const { config } = useElectronConfig()

  const imageSrc = get(config, "venue_header_image", "")

  return (
    <Div100vh data-name="AppLayout" style={root} className="app-layout">
      <Box sx={headerWrapper} className="app-header">
        <Header
          logo={<HeaderLogo />}
          title={<Image src={imageSrc} />}
          clock={<Clock />}
        />
      </Box>
      <Box sx={contentWrapper}>
        <IndoorMapContainer />
        <Outlet />
        {/*  <SearchContainer renderComponent={SearchPopupContent} /> */}
      </Box>
      <Box sx={footerWrapper}>
        <>
          {footerBanners.length > 0 ? (
            <AdMediaFooterCarousel
              data={footerBanners}
              carouselOptions={footerBannerConfig}
            />
          ) : (
            <Footer />
          )}
        </>
      </Box>
    </Div100vh>
  )
}
