import {
  Avatar,
  Box,
  Button,
  Typography,
  useTheme,
  CardMedia,
  Card,
} from "@mui/material"
import size from "lodash/size"
import { useTranslation } from "providers/i18n"
import { HorizontalScrollPane } from "components/HorizontalScrollPane/HorizontalScrollPane"
import { FeatureLabel } from "./FeatureLabel"
import { CloseIconButton } from "components/Button/CloseIconButton"
import { QRCode } from "components/QRCode"
import { CloseCircleOutlineIcon } from "../../icons"
import { CustomIcon, StoreIcon } from "components/icons"

export const FeatureCard = ({
  open,
  onClose,
  directionButtonProps = {},
  promotionButtonProps = {},
  loading = true,
  data = {},
  onNavigate = () => {},
  onClickPromotionButton = () => {},
  categoryIconProp = {},
  childrenProps = {},
  ...props
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const {
    logo,
    category,
    name,
    hours,
    gallery,
    description,
    location,
    placeDetailMobileUrl,
    promotions,
  } = data
  const galleryImageLength = size(gallery)

  const styleOverrides = theme.components[`FeatureCard`]?.styleOverrides || {}
  const {
    root,
    primarySection,
    secondarySection,
    header,
    content,
    avatar,
    imageListContainer,
    imageList,
    imageItem,
    actionContainer,
    listContainer,
    qrCodeContainer,
    closeButton,
  } = styleOverrides

  const { iconPath, image } = categoryIconProp
  return (
    <Card sx={root} {...childrenProps}>
      <CloseIconButton
        sx={closeButton}
        icon={<CloseCircleOutlineIcon fontSize="inherit" />}
        onClick={onClose}
        size="large"
      />
      <Box sx={primarySection}>
        <Box sx={header}>
          <Avatar variant="circular-logo" sx={avatar} src={logo || image}>
            {iconPath ? <CustomIcon path={iconPath} /> : <StoreIcon />}
          </Avatar>
          <Typography gutterBottom variant="h5">
            {t(name)}
          </Typography>
        </Box>
        {description && (
          <Box sx={content}>
            <Typography variant="body2" color="text.secondary">
              {t(description)}
            </Typography>
          </Box>
        )}
        {galleryImageLength > 0 && (
          <Box sx={imageListContainer}>
            <HorizontalScrollPane
              stackProps={{
                sx: {
                  ...imageList,
                  justifyContent:
                    galleryImageLength <= 2 ? "center" : "flex-start",
                },
              }}
            >
              {gallery.map(({ name, url }, idx) => (
                <CardMedia
                  key={`${name}-${idx}`}
                  sx={imageItem}
                  image={url}
                  title={name}
                />
              ))}
            </HorizontalScrollPane>
          </Box>
        )}
        <Box sx={actionContainer}>
          {promotions.length > 0 && (
            <Button
              variant="outlined"
              onClick={onClickPromotionButton}
              {...promotionButtonProps}
            >
              {t("Promotion")}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={onNavigate}
            {...directionButtonProps}
          >
            {t("Get direction")}
          </Button>
        </Box>
      </Box>
      <Box sx={secondarySection}>
        <Box sx={listContainer}>
          <FeatureLabel label={t("Location")} value={t(location)} />
          <FeatureLabel label={t("Category")} value={t(category)} />
          <FeatureLabel label={t("Operation Time")} value={hours || "-"} />
          {placeDetailMobileUrl && (
            <QRCode
              sx={qrCodeContainer}
              url={placeDetailMobileUrl}
              caption={t("Send to mobile")}
            />
          )}
        </Box>
      </Box>
    </Card>
  )
}
