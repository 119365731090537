import { useCallback, useMemo } from "react"
import { useTranslation as useI18nTranslation } from "react-i18next"
import isString from "lodash/isString"
import isObject from "lodash/isObject"
import { generateLocalizeValueFn } from "utils/localize"
import _ from "lodash"

export const useTranslation = (generateLocalizeOptions) => {
  const { defaultValue } = generateLocalizeOptions || {}
  const { t: i18nTranslate, i18n, ...rest } = useI18nTranslation()
  const localizeValue = useMemo(
    () => generateLocalizeValueFn(i18n, { defaultValue }),
    [i18n, defaultValue]
  )

  // Extend the t function for supporting the locale object data, e.g. '{ name: { en: "Title", th: "หัวข้อ" } }'
  const t = useCallback(
    (key, options) => {
      try {
        if (isString(key) || _.isArray(key)) return i18nTranslate(key, options)
        if (isObject(key) || _.isNil(key)) return localizeValue(key, options)
        return key
      } catch (error) {
        return key
      }
    },
    [i18nTranslate, localizeValue]
  )

  return {
    t,
    i18n,
    ...rest,
  }
}
