import { useMemo } from "react"
import { Box, useTheme } from "@mui/material"
import { Link } from "react-router-dom"

import { getIcon } from "utils/icon"
import { useVenue } from "providers/venue"
import { useTranslation } from "providers/i18n"
import { usePromotion } from "hooks/promotion/usePromotion"

import { PageLayout } from "layouts/PageLayout"

import { PromotionCard } from "components/domains/promotions"
import { EmptyData } from "components/EmptyData"
import { PercentIcon } from "components/icons"
import { Head } from "components/Head"
import { VenueSelector } from "components/VenueSelector"
import {
  CategoryFilterBar,
  CategoryFilterChip,
} from "components/CategoryFilterBar"

export const Promotions = ({ Layout = PageLayout }) => {
  const { t } = useTranslation()
  const {
    filteredPromotions,
    changeVenueFilter,
    locationVenueId,
    venueFilter,
  } = usePromotion()
  const { venues, isMultiVenueProject } = useVenue()

  const theme = useTheme()
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.promotions) || <PercentIcon />,
    [theme]
  )

  const { styleOverrides } = theme.components?.[`PromotionPage`] || {}
  const {
    root,
    cardListContainer,
    categoryFilterBarContainer,
    emptyDataContainer,
    venueSelector,
  } = styleOverrides

  if (!filteredPromotions || filteredPromotions?.length === 0)
    return (
      <>
        <Head title={t("Promotions")} />
        <Layout>
          <Box sx={emptyDataContainer}>
            <EmptyData
              icon={emptyIcon}
              primary={t("Coming soon")}
              secondary={t("Check Back Soon")}
            />
            {isMultiVenueProject && (
              <Box sx={venueSelector}>
                <VenueSelector
                  selectedVenue={venueFilter}
                  venues={venues}
                  onClickVenue={changeVenueFilter}
                  currentVenue={locationVenueId}
                />
              </Box>
            )}
          </Box>
        </Layout>
      </>
    )
  return (
    <>
      <Head title={t("Promotions")} />
      <Layout>
        <Box sx={root}>
          <Box sx={categoryFilterBarContainer}>
            {
              // TODO: Add a condition
              false && (
                <CategoryFilterBar renderFilterButton={CategoryFilterChip} />
              )
            }
          </Box>
          <Box sx={cardListContainer}>
            {filteredPromotions.map((promotion) => {
              const sponsoredId = promotion?.properties?.sponsoredId || ""
              return (
                <Link
                  to={`/promotions/${promotion.id}`}
                  key={`${sponsoredId}${promotion.id}`}
                >
                  <PromotionCard size="large" promotion={promotion} />
                </Link>
              )
            })}
          </Box>
          {isMultiVenueProject && (
            <Box sx={venueSelector}>
              <VenueSelector
                selectedVenue={venueFilter}
                venues={venues}
                onClickVenue={changeVenueFilter}
                currentVenue={locationVenueId}
              />
            </Box>
          )}
        </Box>
      </Layout>
    </>
  )
}
