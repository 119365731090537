import { useTheme } from "@emotion/react"
import {
  Box,
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  Chip,
} from "@mui/material"
import React from "react"
import { deepmerge } from "@mui/utils"

export const ImageCard = ({
  title,
  subheader,
  image,
  onClick,
  avatar,
  fallbackImage = null,
  tag,
  sx,
  ...props
}) => {
  const theme = useTheme()
  const { chip, chipContainer, root } =
    theme.components[`ImageCard`]?.styleOverrides || {}

  return (
    <Card variant="image" {...props} sx={deepmerge(root, sx)}>
      <CardActionArea onClick={onClick}>
        <Box sx={chipContainer}>
          {typeof tag === "string" ? (
            <Chip label={tag} size="small" sx={chip} />
          ) : (
            tag
          )}
        </Box>
        <CardMedia component="img" image={image || fallbackImage} />
        <CardHeader title={title} subheader={subheader} avatar={avatar} />
      </CardActionArea>
    </Card>
  )
}
