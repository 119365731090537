import { Box } from "@mui/material"
import React from "react"

import { PageCard, PageCardContent } from "components/card"

export const NewShopsKioskHPage = ({ Layout = Box }) => {
  return (
    <Layout aside={<></>}>
      <PageCard>
        <PageCardContent>New Shops Page</PageCardContent>
      </PageCard>
    </Layout>
  )
}
