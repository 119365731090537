import { Outlet } from "react-router-dom"
import { Box, useTheme } from "@mui/material"
import Div100vh from "react-div-100vh"
import get from "lodash/get"

import { Header, HeaderLogo, HeaderTitle } from "components/Header"
import { Clock } from "components/Clock"
import { IndoorMapContainer } from "containers/IndoorMapContainer"
/* import { SearchContainer } from "containers/SearchContainer"
import { SearchPopupContent } from "components/domains/search/kiosk-h" */
import { NotificationContainer } from "containers/NotificationContainer"

export const AppLayout = (props) => {
  const theme = useTheme()
  const {
    header = (
      <Header logo={<HeaderLogo />} title={<HeaderTitle />} clock={<Clock />} />
    ),
  } = props
  const styleOverrides = get(theme, "components.AppLayout.styleOverrides", {
    headerWrapper: {},
    contentWrapper: {},
    indoorMapWrapper: {},
  })
  const {
    headerWrapper = {},
    contentWrapper = {},
    indoorMapWrapper = {},
  } = styleOverrides

  return (
    <Div100vh data-name="AppLayout">
      <Box sx={headerWrapper}>{header}</Box>
      <Box sx={indoorMapWrapper}>
        <IndoorMapContainer />
      </Box>
      <Box sx={contentWrapper}>
        <Outlet />
      </Box>
      {/* <SearchContainer renderComponent={SearchPopupContent} /> */}
      <NotificationContainer />
    </Div100vh>
  )
}
