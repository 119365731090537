import { ISortPayload } from "../types"

export type SerializeSortQuery = (sort: ISortPayload | ISortPayload[]) => {
  fields: string[]
  orders: Array<boolean | "asc" | "desc">
}

export const serializeSortQuery: SerializeSortQuery = (sort) => {
  const sortQuery = Array.isArray(sort) ? sort : [sort]
  return sortQuery.reduce(
    (result, sortObj) => {
      result.fields.push(`${sortObj?.field}`)
      result.orders.push(`${sortObj?.order}`.toLowerCase())
      return result
    },
    { fields: [], orders: [] }
  )
}
