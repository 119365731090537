import {
  Card,
  CardContent,
  CardProps,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { deepmerge } from "@mui/utils"
import ReactQRCode, { QRCodeProps as ReactQRCodeProps } from "react-qr-code"

import { useTranslation } from "providers/i18n"

const StyledReactQRCode = styled(ReactQRCode)(() => ({}))

export interface QRCodeProps extends CardProps {
  url: string
  caption?: string
  reactQRCodeProps?: Omit<ReactQRCodeProps, "value" | "ref">
}

export const QRCode: React.FC<QRCodeProps> = ({
  url = "",
  caption,
  sx,
  reactQRCodeProps = {},
  ...props
}) => {
  const theme = useTheme()
  const styleOverrides = theme.components[`AppQRCode`]?.styleOverrides || {}
  const { root, qrCodeContent, qrCodeCaption, qrCode } = styleOverrides

  const { t } = useTranslation()

  return (
    <Card className="AppQRCode-root" sx={deepmerge(root, sx)} {...props}>
      <CardContent className="AppQRCode-content" sx={qrCodeContent}>
        <StyledReactQRCode
          className="AppQRCode-qrcode"
          {...reactQRCodeProps}
          sx={qrCode}
          value={url}
        />
      </CardContent>
      {caption && (
        <Typography
          className="AppQRCode-caption"
          sx={qrCodeCaption}
          variant="caption"
        >
          {t(caption)}
        </Typography>
      )}
    </Card>
  )
}
