import React, { useEffect } from "react"
import { useParams, useNavigate, Outlet } from "react-router-dom"
import { Box, useTheme } from "@mui/material"

import { useTranslation } from "providers/i18n"
import { useGeoLocation } from "providers/geolocation"
import { Head } from "components/Head"

export const Layout = () => {
  const { isLoading, userLocation, scannedLocations, deviceLocation } =
    useGeoLocation()
  const { t } = useTranslation()
  const params = useParams()
  const path = params["*"]
  const navigate = useNavigate()

  const theme = useTheme()
  const styleOverrides = theme.components[`AppMapLayout`]?.styleOverrides || {}
  const { mapContainer, outletWrapper } = styleOverrides

  useEffect(() => {
    if (isLoading) return
    switch (path) {
      case "":
        if (userLocation) {
          const {
            properties: { ordinal },
          } = userLocation
          navigate(`/maps/ordinal/${ordinal}`, { replace: true })
        } else {
          /** If user enter /maps (without any user-location query string), redirect to /maps/ordinal/0
           * replace = true, so when user click "back", it will go back to previous page instead of `/maps`
           */
          navigate("/maps/ordinal/0", { replace: true })
        }
        break
      default:
        break
    }
  }, [path, navigate, isLoading, userLocation])

  return (
    <>
      <Head title={t("Map")} />
      <Box sx={mapContainer}>
        <Box sx={outletWrapper}>
          <Outlet
            context={
              {
                scannedLocations,
                userLocation,
                deviceLocation,
              } || {}
            }
          />
        </Box>
      </Box>
    </>
  )
}
