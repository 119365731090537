import React, { useCallback, useMemo } from "react"
import { Box, ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"

import { getIcon } from "utils/icon"
import { useAppUI } from "providers/ui"
import { useTranslation } from "providers/i18n"
import { useEvent } from "hooks/event/useEvent"
import { useSponsoredFooterBanner } from "hooks/sponsored-content/useSponsoredFooterBanner"

import { AppNavigation } from "containers/kiosk-v"
import { Carousel, CarouselItem } from "components/Carousel"
import { Head } from "components/Head"
import { EmptyData } from "components/EmptyData"
import { CalendarStarIcon } from "components/icons"
import { VerticalEventCard } from "components/domains/events"
import { VenueSelector } from "components/VenueSelector"
import { LanguageButton } from "components/Language"
import { SearchToggleButton } from "components/domains/search"
import { Header, HeaderLogo, HeaderTitle } from "components/Header"
import { Clock } from "components/Clock"
import { AdMediaFooterCarousel } from "components/domains/ads"
import { Footer } from "components/Footer"
import { MotionDiv, showContent } from "components/motions"

export const EventsKioskVPage = ({ Layout = Box }) => {
  const { openSearch } = useAppUI()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    isMultiVenueProject,
    venueFilter,
    venues,
    locationVenueId,
    groupEvents,
    activeGroupEvent,
    handleChange,
    changeVenueFilter,
  } = useEvent()
  const { footerBanners, footerBannerConfig } = useSponsoredFooterBanner()

  const theme = useTheme()
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.events) || <CalendarStarIcon />,
    [theme]
  )
  const { styleOverrides } = theme.components?.[`EventPage`] || {}
  const {
    root,
    carouselContainer,
    carousel,
    eventGroupSelectorButton,
    emptyDataWrapper,
    navigationWrapper,
    venueSelector,
    toolbarContainer,
    actionButton,
    actionContainer,
    searchBarContainer,
  } = styleOverrides

  const handleClickSlide = useCallback(
    (_splide, { slide }, _event) => {
      navigate(slide.getAttribute("link"))
    },
    [navigate]
  )

  return (
    <>
      <Head title={t("Events & Activities")} />
      <Layout
        header={
          <Header
            logo={<HeaderLogo />}
            title={<HeaderTitle />}
            clock={<Clock />}
          />
        }
        footer={
          <>
            {footerBanners.length > 0 ? (
              <AdMediaFooterCarousel
                data={footerBanners}
                carouselOptions={footerBannerConfig}
              />
            ) : (
              <Footer />
            )}
          </>
        }
      >
        <Box sx={root}>
          <MotionDiv
            animate="animate"
            initial="initial"
            variants={showContent}
            sx={carouselContainer}
          >
            {groupEvents[activeGroupEvent] &&
            groupEvents[activeGroupEvent].length > 0 ? (
              <Carousel
                onClick={handleClickSlide}
                options={{
                  type: "loop",
                  pagination: true,
                  focus: "center",
                  autoplay: true,
                  gap: "0",
                }}
                sx={carousel}
              >
                {groupEvents[activeGroupEvent].map((event) => {
                  const sponsoredId = event?.properties?.sponsoredId
                  return (
                    <CarouselItem
                      link={`/events/${event.id}`}
                      key={`${sponsoredId || ""}${event.id}`}
                    >
                      <VerticalEventCard dense={true} event={event} />
                    </CarouselItem>
                  )
                })}
              </Carousel>
            ) : (
              <Box sx={emptyDataWrapper}>
                <EmptyData
                  icon={emptyIcon}
                  primary={
                    activeGroupEvent === "current"
                      ? t(`No Current Events`)
                      : t(`No Upcoming Events`)
                  }
                  secondary={t("Check Back Soon")}
                />
              </Box>
            )}
            <Box sx={eventGroupSelectorButton}>
              <ToggleButtonGroup
                value={activeGroupEvent}
                onChange={handleChange}
                exclusive
              >
                <ToggleButton value="current">
                  {t("Current Events")}
                </ToggleButton>
                <ToggleButton value="upcoming">
                  {t("Upcoming Events")}
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </MotionDiv>
          {isMultiVenueProject && (
            <Box sx={venueSelector}>
              <VenueSelector
                selectedVenue={venueFilter}
                venues={venues}
                onClickVenue={changeVenueFilter}
                currentVenue={locationVenueId}
              />
            </Box>
          )}
          <Box key="app-navigation" sx={navigationWrapper}>
            <Box sx={toolbarContainer}>
              <Box sx={actionContainer}>
                <LanguageButton variant="contained" sx={actionButton} />
                {/* //TODO:: Add a wheel chair's mode handler function later */}
                {/*  <Button
            variant="contained"
            sx={actionButton}
            onClick={() => {}}
            startIcon={<RestroomWheelchairIcon />}
          /> */}
              </Box>
              <Box sx={searchBarContainer}>
                <SearchToggleButton
                  placeholder={t("Search shops and more...")}
                  onClick={openSearch}
                />
              </Box>
            </Box>
            <AppNavigation />
          </Box>
        </Box>
      </Layout>
    </>
  )
}
