import dayjs from "config/dayjs"
import { useVenue } from "providers/venue"
import { useMemo } from "react"

export const useNewShops = () => {
  const { occupants = [] } = useVenue()
  const currentDate = dayjs()

  const getOccupantsByStartDate = useMemo(() => {
    return occupants.filter(({ properties }) => properties.start_date)
  }, [occupants])

  const newOccupants = ({ properties }) =>
    currentDate.isSameOrAfter(properties.start_date) &&
    currentDate.diff(dayjs(properties.start_date), "day") <= 30

  const comingSoonOccupants = (feature) =>
    currentDate.isBefore(dayjs(feature.properties.start_date))

  return {
    newOccupants: getOccupantsByStartDate.filter(newOccupants),
    comingSoonOccupants: getOccupantsByStartDate.filter(comingSoonOccupants),
  }
}
