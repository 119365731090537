import { ReactNode } from "react"
import { Box, BoxProps, Typography, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"

export interface IInfoItemProps extends BoxProps {
  icon?: ReactNode
  label: string
}

export const InfoItem: React.FC<IInfoItemProps> = ({
  icon,
  label,
  sx = {},
  ...props
}) => {
  const theme = useTheme()
  const { styleOverrides = {} } = theme.components[`InfoItem`] || {}
  const { root, iconContainer, labelContainer } = styleOverrides

  return (
    <Box sx={deepmerge(root, sx)} {...props}>
      {icon && <Box sx={iconContainer}>{icon}</Box>}
      <Typography sx={labelContainer}>{label}</Typography>
    </Box>
  )
}
