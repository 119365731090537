import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { deepmerge } from "@mui/utils"
import { useVenue } from "providers/venue"
import React, { useCallback, useMemo, useState } from "react"
import { useTranslation } from "providers/i18n"

export const LanguageButton = (props) => {
  const { t, i18n } = useTranslation()
  const { locales = [] } = useVenue()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const { sx = {}, label: labelProp, ...resProps } = props

  const theme = useTheme()
  const { styleOverrides = {}, defaultProps = {} } =
    theme.components[`LanguageButton`] || {}
  const {
    root = {},
    avatar = {},
    label = {},
    menuPaper = {},
    menuItem,
  } = styleOverrides
  const { MenuProps: defaultMenuProps } = defaultProps || {}

  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl]
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const switchLanguage = useCallback(
    (lang) => (e) => {
      i18n.changeLanguage(lang)
    },
    [i18n]
  )

  const activeLocale = useMemo(() => {
    if (!i18n.resolvedLanguage) return
    return locales.find((locale) => i18n.resolvedLanguage === locale.localeKey)
  }, [locales, i18n.resolvedLanguage])

  const {
    flagCode: activeLocaleFlagCode,
    localeKey: activeLocaleKey,
    localizedName: activeLocaleName,
  } = activeLocale || {}

  if (locales.length <= 0) return null

  return (
    <>
      {activeLocale && (
        <Tooltip title={t("Language Settings")}>
          <Button
            onClick={handleClick}
            variant="contained"
            size="small"
            aria-controls={open && "language-menu"}
            aria-haspopup="true"
            aria-expanded={open}
            sx={deepmerge(root, sx)}
            {...resProps}
          >
            <Avatar
              src={`https://flagcdn.com/h40/${activeLocaleFlagCode}.webp`}
              sx={avatar}
            />
            <Typography sx={label}>
              {labelProp
                ? t(labelProp)
                : t(
                    `localization.select.item.${activeLocaleKey}`,
                    activeLocaleName
                  )}
            </Typography>
          </Button>
        </Tooltip>
      )}

      <Menu
        anchorEl={anchorEl}
        id="language-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: menuPaper,
        }}
        {...defaultMenuProps}
      >
        {locales.map((locale) => (
          <MenuItem
            key={locale.localeKey}
            onClick={switchLanguage(locale.localeKey)}
            sx={menuItem}
            className={activeLocaleKey === locale.localeKey ? "active" : ""}
          >
            <Avatar
              src={`https://flagcdn.com/h40/${locale.flagCode}.webp`}
              sx={avatar}
            />
            {t(
              `localization.select.item.${locale.localeKey}`,
              locale.localizedName
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
