import { useEffect } from "react"
import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction,
  Box,
  useTheme,
} from "@mui/material"
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "providers/i18n"
import { MoreIcon } from "../icons"
import { Sidebar } from "components/Sidebar"
import { useAppUI } from "providers/ui"

export const MobileNavigation = ({
  value,
  bottomNavigations = [],
  navigations = [],
}) => {
  const location = useLocation()
  const { sidebarState, setSidebarState } = useAppUI()

  const { t } = useTranslation()

  const theme = useTheme()
  const styleOverrides =
    theme.components[`AppMobileNavigation`]?.styleOverrides || {}
  const { root, menuButton, moreButton } = styleOverrides

  // Close Drawer when url changed
  useEffect(() => {
    setSidebarState(false)
  }, [location.pathname, setSidebarState])

  return (
    <>
      <Box sx={root}>
        <MuiBottomNavigation value={value} showLabels>
          {bottomNavigations.map((nav) => (
            <BottomNavigationAction
              sx={menuButton}
              component={Link}
              key={nav.to}
              label={t([`navigation.${nav.label}`, nav.label])}
              to={nav.to}
              value={nav.value || nav.to}
              icon={nav.icon}
            />
          ))}
          <BottomNavigationAction
            sx={moreButton}
            label={t("navigation.More")}
            onClick={() => setSidebarState(true)}
            icon={<MoreIcon />}
          />
        </MuiBottomNavigation>
      </Box>

      <Sidebar
        open={sidebarState}
        onClose={() => setSidebarState(false)}
        anchor="left"
        navigations={navigations}
      />
    </>
  )
}
