import { useRef, useMemo, useCallback, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import _ from "lodash"

import {
  Q_SUB_CATEGORY_ID,
  Q_ORDINAL,
  Q_PARENT_CATEGORY_ID,
  Q_VENUE,
  GTM_VIEW_DIRECTORY_CATEGORY,
  DEFAULT_FILTER_VALUE,
} from "constant"
import config from "config"

import { sortFeatureByLocaleKey } from "../../utils/sortFeatureByLocaleKey"
import {
  createSortingConfigFeatureByName,
  createSortingFeaturedOccupantConfig,
  getFeatureDetail,
} from "utils/feature"
import { pushDataLayer } from "utils/googletagmanager/pushDataLayer"

import { useVenue } from "providers/venue"
import { useTranslation } from "../../providers/i18n/useTranslation"
import { useGeoLocation } from "providers/geolocation"
import { useGetOccupants } from "providers/venue/modules/data/occupants/useGetOccupants"
import { useVenueController } from "hooks/venue/useVenueController"
import { useInfiniteScroll } from "../useInfiniteScroll"
import { useInfiniteGetOccupants } from "providers/venue/modules/data/occupants/useInfiniteGetOccupants"

const DEFAULT_LEVEL_OBJECT = {
  id: "all",
  name: "All Floors",
  value: DEFAULT_FILTER_VALUE,
}

export const useDirectory = () => {
  const device = config("device")
  const isKiosk = /^kiosk/.test(device)
  const { locationVenue } = useGeoLocation()
  const locationVenueId = _.get(locationVenue, "id")
  const { viewingVenue: venueFilter } = useVenueController(locationVenueId)

  const navigate = useNavigate()
  const {
    occupants: venueOccupant,
    levels,
    venues,
    isMultiVenueProject,
    isMultiOrdinalVenue,
    findFeatureById,
    dataLoaded,
  } = useVenue()

  const {
    i18n: { language: chosenLanguage },
  } = useTranslation()
  const [searchParams] = useSearchParams()
  const ref = useRef()

  const defaultVenueFilter = useMemo(
    () => locationVenueId || DEFAULT_FILTER_VALUE,
    [locationVenueId]
  )

  // Query String filters
  const parentCategoryId = useMemo(
    () => searchParams.get(Q_PARENT_CATEGORY_ID) || DEFAULT_FILTER_VALUE,
    [searchParams]
  )

  const subCategoryId = useMemo(
    () => searchParams.get(Q_SUB_CATEGORY_ID) || DEFAULT_FILTER_VALUE,
    [searchParams]
  )

  const ordinalFilter = useMemo(() => {
    const ordinalParam = searchParams.get(Q_ORDINAL)
    const ordinal = Number(ordinalParam)
    return !_.isNil(ordinalParam) && !_.isNaN(ordinal)
      ? ordinal
      : DEFAULT_FILTER_VALUE
  }, [searchParams])

  // Filter occupants with an available anchor
  const { data: availableOccupants } = useGetOccupants({
    pagination: {
      perPage: _.size(venueOccupant),
    },
    filter: {
      properties: {
        anchor: {
          $exists: true,
        },
      },
    },
    enabled: dataLoaded,
  })

  // Filter occupants with a parentCategoryId.
  const { data: occupantsFilteredByParentCategory } = useGetOccupants({
    pagination: {
      perPage: _.size(availableOccupants),
    },
    filter: {
      properties: {
        local_parent_categories: {
          $elemMatch: {
            id: parentCategoryId,
          },
        },
      },
    },
    enabled: !!availableOccupants,
  })

  /**
   * Result All Filtered Occupants (without ordinal filter)
   * - Used to create an array of unique levels of occupants that match the category
   * - Used as the source data for the useInfiniteGetOccupants hook
   */
  const { data: allfilteredOccupantsSorted } = useGetOccupants({
    pagination: {
      perPage: _.size(availableOccupants),
    },
    sort: createSortingConfigFeatureByName(chosenLanguage),
    filter: {
      properties: {
        local_parent_categories: {
          $elemMatch: {
            id: parentCategoryId,
          },
        },
        local_categories: {
          $elemMatch: {
            id: subCategoryId,
          },
        },
        venue: {
          id: venueFilter,
        },
      },
    },
    enabled: !!occupantsFilteredByParentCategory,
  })

  /**
   * Result Infinite Filtered Non-Featured Occupants
   * - Used to display data in an infinite scroll version
   */
  const {
    data: filteredAllOccupantsSorted,
    hasNextPage: hasNextAllOccupantPage,
    fetchNextPage: fetchNextAllOccupantPage,
  } = useInfiniteGetOccupants({
    pagination: {
      perPage: 15,
    },
    sort: createSortingConfigFeatureByName(chosenLanguage),
    filter: {
      properties: {
        local_parent_categories: {
          $elemMatch: {
            id: parentCategoryId,
          },
        },
        local_categories: {
          $elemMatch: {
            id: subCategoryId,
          },
        },
        venue: {
          id: venueFilter,
        },
        ordinal: ordinalFilter,
      },
    },
    enabled: !!allfilteredOccupantsSorted,
  })

  /**
   * Result Featured Occupant
   * - Used to display data in an infinite scroll version
   */
  const {
    data: filteredFeaturedOccupantSorted,
    hasNextPage: hasNextFeaturedOccupantPage,
    fetchNextPage: fetchNextFeaturedOccupantPage,
  } = useInfiniteGetOccupants({
    pagination: {
      perPage: 15,
    },
    sort: createSortingFeaturedOccupantConfig(chosenLanguage),
    filter: {
      properties: {
        is_featured: true,
        local_parent_categories: {
          $elemMatch: {
            id: parentCategoryId,
          },
        },
        local_categories: {
          $elemMatch: {
            id: subCategoryId,
          },
        },
        venue: {
          id: venueFilter,
        },
        ordinal: ordinalFilter,
      },
    },
    // Disable querying featured occupants if not running in kiosk mode.
    enabled: isKiosk && !!allfilteredOccupantsSorted,
  })

  const onReachEndScroll = useCallback(
    (inView) => {
      if (inView && hasNextAllOccupantPage) fetchNextAllOccupantPage()
      if (inView && hasNextFeaturedOccupantPage) fetchNextFeaturedOccupantPage()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasNextAllOccupantPage, hasNextFeaturedOccupantPage]
  )

  const { endScrollRef } = useInfiniteScroll({
    onReachEndScroll,
  })

  // Prepare parent category by available occupants
  const parentCategoryWithOccupant = useMemo(
    () =>
      _(availableOccupants)
        .map("properties.local_parent_categories")
        .compact()
        .flatten()
        .uniqBy("id")
        .value(),
    [availableOccupants]
  )

  const sortedParentCategoryWithOccupant = useMemo(
    () =>
      _.orderBy(
        parentCategoryWithOccupant,
        ["properties.is_featured", "properties.order"],
        ["desc", "asc"]
      ),
    [parentCategoryWithOccupant]
  )

  // Prepare subcategories by filtering parent category occupants.
  const subCategoryWithActiveParentCategory = useMemo(
    () =>
      _.isNil(parentCategoryId)
        ? []
        : _(occupantsFilteredByParentCategory)
            .map("properties.local_categories")
            .compact()
            .flatten()
            .uniqBy("id")
            .filter(
              (category) =>
                _.get(category, "properties.parent_id") === parentCategoryId
            )
            .value(),
    [parentCategoryId, occupantsFilteredByParentCategory]
  )

  // Prepare venue options.
  const sortedVenues = useMemo(() => {
    return sortFeatureByLocaleKey(venues, "properties.name", chosenLanguage)
  }, [chosenLanguage, venues])

  const venueOptions = useMemo(() => {
    const defaultVenueObject = {
      id: "all-venues",
      name: "All Venues",
      value: DEFAULT_FILTER_VALUE,
    }
    const options = sortedVenues.map((venue) => {
      const { id, name } = getFeatureDetail(venue)
      return { id, name, value: id }
    })

    return [defaultVenueObject, ...options]
  }, [sortedVenues])

  // Array of unique levels of occupants who matched with category
  const filteredLevels = useMemo(
    () =>
      _(allfilteredOccupantsSorted)
        .uniqBy("properties.ordinal")
        .filter((item) => !_.isNil(item.properties.ordinal))
        .map(({ properties: { ordinal } }) =>
          levels.find((level) => level.properties.ordinal === ordinal)
        )
        .orderBy("properties.ordinal", "asc")
        .value(),
    [levels, allfilteredOccupantsSorted]
  )

  const levelOptions = useMemo(() => {
    const options = filteredLevels.map((level) => {
      const { id, name, ordinal } = getFeatureDetail(level)
      return { id, name, value: ordinal }
    })

    return [DEFAULT_LEVEL_OBJECT, ...options]
  }, [filteredLevels])

  const showSubCategoryFilter = useMemo(
    () =>
      subCategoryWithActiveParentCategory.length > 0 &&
      parentCategoryId !== DEFAULT_FILTER_VALUE,
    [subCategoryWithActiveParentCategory, parentCategoryId]
  )

  const filteredOccupantsSorted = useMemo(() => {
    const occupants = _(filteredAllOccupantsSorted?.pages)
      .map("data")
      .flatten()
      .value()

    // Return occupant results without transforming the list when not in kiosk mode.
    if (!isKiosk) return occupants

    // Flatten React Query featured occupant and all occupant result.
    const featuredOccupants = _(filteredFeaturedOccupantSorted?.pages)
      .map("data")
      .flatten()
      .map((f) => {
        f.properties.is_highlight = true
        return f
      })
      .value()

    // Chunk featured occupant list in a specific pattern
    const chunkFeaturedOccupantsToInsert = _(featuredOccupants).chunk(2).value()

    // Insert chunked featured occupants into the non-featured occupant list.
    return _(occupants)
      .flatMap((element, index) => {
        const featuredOccupants = chunkFeaturedOccupantsToInsert[index]

        if (!featuredOccupants) return element

        const rowPattern = (index + 1) % 3
        switch (rowPattern) {
          case 1:
            return _.compact([
              featuredOccupants[0],
              featuredOccupants[1],
              element,
            ])
          case 2:
            return _.compact([
              featuredOccupants[0],
              element,
              featuredOccupants[1],
            ])
          case 0:
          default:
            return _.compact([
              element,
              featuredOccupants[0],
              featuredOccupants[1],
            ])
        }
      })
      .value()
  }, [filteredAllOccupantsSorted, isKiosk, filteredFeaturedOccupantSorted])

  const hasData =
    _.size(filteredAllOccupantsSorted?.pages[0]?.data) > 0 ||
    _.size(filteredFeaturedOccupantSorted?.pages[0]?.data) > 0
  const hasParentCategory = _.size(sortedParentCategoryWithOccupant) > 0
  const hasNextPage = hasNextAllOccupantPage || hasNextFeaturedOccupantPage

  const scrollToRef = useCallback(
    () => ref.current?.scrollIntoView({ behavior: "smooth" }),
    [ref]
  )

  const handleChangeOrdinal = useCallback(
    (e, id) => {
      if (id === null) return
      searchParams.set(Q_ORDINAL, id)
      navigate(`/directory?${searchParams.toString()}`, { replace: true })
      scrollToRef()
    },
    [scrollToRef, searchParams, navigate]
  )

  const changeVenueFilter = useCallback(
    (venueId = defaultVenueFilter) => {
      searchParams.set(Q_VENUE, venueId)
      navigate(`/directory?${searchParams.toString()}`, { replace: true })
      scrollToRef()
    },
    [scrollToRef, searchParams, navigate, defaultVenueFilter]
  )

  const handleSelectLevelChange = useCallback(
    (e) => {
      const ordinal = e.target.value
      handleChangeOrdinal(e, ordinal)
    },
    [handleChangeOrdinal]
  )

  const handleChangeParentCategory = useCallback(
    (e, id) => {
      if (id === null) return
      searchParams.set(Q_PARENT_CATEGORY_ID, id)
      searchParams.set(Q_SUB_CATEGORY_ID, DEFAULT_FILTER_VALUE)
      searchParams.set(Q_ORDINAL, DEFAULT_FILTER_VALUE)
      navigate(`/directory?${searchParams.toString()}`, { replace: true })
    },
    [navigate, searchParams]
  )

  const handleChangeSubCategory = useCallback(
    (e, id) => {
      if (id === null) return
      searchParams.set(Q_SUB_CATEGORY_ID, id)
      searchParams.set(Q_ORDINAL, DEFAULT_FILTER_VALUE)
      navigate(`/directory?${searchParams.toString()}`, { replace: true })
    },
    [navigate, searchParams]
  )

  const resetFilter = useCallback(() => {
    searchParams.set(Q_PARENT_CATEGORY_ID, DEFAULT_FILTER_VALUE)
    searchParams.set(Q_SUB_CATEGORY_ID, DEFAULT_FILTER_VALUE)
    searchParams.set(Q_VENUE, DEFAULT_FILTER_VALUE)
    searchParams.set(Q_ORDINAL, DEFAULT_FILTER_VALUE)
    navigate(`/directory?${searchParams.toString()}`, { replace: true })
  }, [navigate, searchParams])

  const handleOnClick = useCallback(
    (feature) => {
      navigate(`/maps/place/${feature.id}`)
    },
    [navigate]
  )

  useEffect(() => {
    try {
      if (!!parentCategoryId && parentCategoryId !== DEFAULT_FILTER_VALUE) {
        const categoryName = parentCategoryWithOccupant.find(
          (taxonomy) => taxonomy.id === parentCategoryId
        )?.properties?.name?.en
        return pushDataLayer({
          event: GTM_VIEW_DIRECTORY_CATEGORY,
          directory_category_id: parentCategoryId,
          directory_category_name: categoryName,
          directory_subcategory_name:
            subCategoryId === DEFAULT_FILTER_VALUE
              ? DEFAULT_FILTER_VALUE
              : _.get(findFeatureById(subCategoryId), "properties.name.en"),
        })
      }
    } catch (err) {
      console.log(err)
    }
  }, [
    parentCategoryId,
    parentCategoryWithOccupant,
    subCategoryId,
    findFeatureById,
  ])

  return {
    handleChangeParentCategory,
    handleChangeSubCategory,
    handleChangeOrdinal,
    handleSelectLevelChange,
    handleOnClick,
    changeVenueFilter,
    resetFilter,
    endScrollRef,
    hasNextPage,
    filteredOccupantsSorted,
    filteredLevels,
    parentCategoryWithOccupant,
    sortedParentCategoryWithOccupant,
    subCategoryWithActiveParentCategory,
    parentCategoryId,
    subCategoryId,
    ordinalFilter,
    venueFilter,
    levelOptions,
    venueOptions,
    defaultOrdinalFilterValue: DEFAULT_FILTER_VALUE,
    defaultCategoryFilterValue: DEFAULT_FILTER_VALUE,
    defaultVenueFilterValue: DEFAULT_FILTER_VALUE,
    ref,
    venues,
    isMultiVenueProject,
    isMultiOrdinalVenue,
    locationVenueId,
    showSubCategoryFilter,
    hasData,
    hasParentCategory,
  }
}
