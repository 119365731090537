// TODO: Moved this config to app config and use it only as a default fall back
export const DEFAULT_FEATURE_QUICK_SEARCH_ORDER_CONFIG = [
  "amenity.information",
  "occupant.currencyexchange",
  "amenity.atm",
  "amenity.wheelchair",
  "amenity.restroom",
  "amenity.restroom.male",
  "amenity.restroom.female",
  "amenity.exhibit",
  "amenity.landmark",
  "amenity.parking",
  "amenity.parking.motorcycle",
  "amenity.taxi",
  "amenity.bus",
  "amenity.parking.bicycle",
  "amenity.smokingarea",
  "amenity.meetingpoint",
  "amenity.elevator",
  "amenity.escalator",
  "amenity.firstaid",
  "amenity.prayerroom",
  "amenity.stairs",
  "amenity.ticketing",
  "amenity.boardinggate.ferry",
  "amenity.powerchargingstation",
  "amenity.coinlocker",
]
