import React, { useCallback, useMemo } from "react"
import _ from "lodash"
import { useTheme, Avatar, ListItemButton, Box, Stack } from "@mui/material"
import clsx from "clsx"
import { YouAreHereChipIcon } from "../icons"
import { DEFAULT_VENUE_FILTER } from "../../constant"

export const VenueSelector = (props) => {
  const {
    venues = [],
    onClickVenue = () => {},
    selectedVenue,
    currentVenue,
  } = props
  const theme = useTheme()

  const allVenueFilterLogo = useMemo(
    () => theme?.assets?.venueFilter?.all?.url,
    [theme]
  )

  const { styleOverrides = {} } = theme.components[`VenueSelector`] || {}

  const isFilteredByAllVenue = useMemo(
    () => selectedVenue === DEFAULT_VENUE_FILTER,
    [selectedVenue]
  )

  const { root, buttonWrapper, button, icon, currentVenueChip } = styleOverrides
  const handleClick = useCallback(
    (venueId) => () => {
      onClickVenue(venueId)
    },
    [onClickVenue]
  )
  if (venues.length === 0) return null
  return (
    <Stack direction="row" sx={root}>
      <Box
        className={clsx("all", isFilteredByAllVenue ? "selected" : "")}
        key={"all"}
        sx={buttonWrapper}
      >
        <ListItemButton
          sx={button}
          onClick={handleClick(DEFAULT_VENUE_FILTER)}
          selected={isFilteredByAllVenue}
        >
          <Avatar alt="all-logo" src={allVenueFilterLogo} sx={icon} />
        </ListItemButton>
      </Box>
      {venues.map((venue) => {
        const { id } = venue
        const isSelectedVenue = selectedVenue === id
        const logo = _.get(venue, "properties.logo_simple.url", "")
        const ref = _.get(venue, "properties.reference", "")
        const isCurrentVenue = currentVenue === venue.id
        return (
          <Box
            key={id}
            className={clsx(ref, isSelectedVenue ? "selected" : "")}
            sx={buttonWrapper}
          >
            <ListItemButton
              selected={isSelectedVenue}
              sx={button}
              onClick={handleClick(id)}
            >
              <Avatar alt="venue-logo" src={logo} sx={icon} />
            </ListItemButton>
            {isCurrentVenue && <YouAreHereChipIcon sx={currentVenueChip} />}
          </Box>
        )
      })}
    </Stack>
  )
}
