import { useCallback, useEffect } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { useTheme, Box } from "@mui/material"
import _ from "lodash"

import { pluralize } from "utils/string"

import { useQuery as useQueryString } from "hooks"
import { useMap } from "hooks/map/useMap"
import { useOrdinalMap } from "hooks/ordinal/useOrdinalMap"
import { useVenue } from "providers/venue"
import { useGeoLocation } from "providers/geolocation"

import { FeatureChipToggleBar } from "components/domains/features/ChipToggleBar"
import { HeaderLogo } from "components/Header"

import { AppNavigation } from "extensions/one-siam/container/AppNavigation"
import {
  Header,
  HeaderMenuButton,
  HeaderSearchButton,
} from "extensions/one-siam/components/Header"
import { OrdinalSelector } from "extensions/one-siam/components/OrdinalSelector"

export const MapOrdinal = ({ Layout = Box }) => {
  const { isLoading, userLocation, locationVenue } = useGeoLocation()
  const navigate = useNavigate()
  const { ordinal } = useParams()
  const { search: searchQueryString } = useLocation()
  const { userLocation: userLocationParams } = useQueryString()
  const { isMultiOrdinalVenue } = useVenue()
  const { viewingVenue, viewingVenueId, levelInVenue, quickSearchCategories } =
    useMap()

  const parsedOrdinal = Number(ordinal)

  const enableMap = !_.isNil(viewingVenue) && !_.isNil(ordinal)
  useOrdinalMap(parsedOrdinal, viewingVenue, { enabled: enableMap })

  const theme = useTheme()
  const {
    quickLinkContainer, //TODO:: planning about quick search's working flow later
    ordinalSelectMenu,
    navigationWrapper,
    headerWrapper,
  } = theme.components[`AppMap`]?.styleOverrides || {}

  const onChangeOrdinal = useCallback(
    (ordinal) => {
      navigate(
        `/maps/${
          ordinal !== null ? `ordinal/${ordinal}${searchQueryString}` : ""
        }`,
        { replace: true }
      )
    },
    [navigate, searchQueryString]
  )

  const handleClickQuickSearch = useCallback(
    (featureType, amenityCategory) => {
      //This condition prevent navigation when ordinal or viewingVenueId is undefined
      if (_.isNil(ordinal) || _.isNil(viewingVenueId)) return
      const pluralType = pluralize(featureType)
      navigate(
        `/maps/${pluralType}/${amenityCategory}?from-ordinal=${ordinal}&venue=${viewingVenueId}`
      )
    },
    [navigate, ordinal, viewingVenueId]
  )

  useEffect(() => {
    if (
      !isLoading &&
      userLocationParams &&
      userLocation &&
      !_.isNil(locationVenue?.id)
    ) {
      const {
        properties: { ordinal: userOrdinal },
      } = userLocation
      navigate(`/maps/ordinal/${userOrdinal}?venue=${locationVenue?.id}`, {
        replace: true,
      })
    } else if (
      !isLoading &&
      userLocationParams &&
      !_.isNil(locationVenue?.id)
    ) {
      navigate(`/maps/ordinal/${ordinal}?venue=${locationVenue?.id}`, {
        replace: true,
      })
    }
  }, [
    navigate,
    ordinal,
    isLoading,
    userLocation,
    locationVenue,
    userLocationParams,
  ])

  return (
    <Layout
      header={
        <Box sx={headerWrapper}>
          <Header
            logo={<HeaderLogo />}
            menu={<HeaderMenuButton />}
            search={<HeaderSearchButton />}
          />
        </Box>
      }
    >
      {isMultiOrdinalVenue && (
        <Box sx={ordinalSelectMenu}>
          <OrdinalSelector
            selectedOrdinal={parsedOrdinal}
            levels={levelInVenue}
            onChangeOrdinal={onChangeOrdinal}
          />
        </Box>
      )}
      <Box sx={navigationWrapper}>
        <Box sx={quickLinkContainer}>
          <FeatureChipToggleBar
            categories={quickSearchCategories}
            onClick={handleClickQuickSearch}
          />
        </Box>
        <AppNavigation />
      </Box>
    </Layout>
  )
}
