import _ from "lodash"
import pointInPolygon from "@turf/boolean-point-in-polygon"
import {
  getCenterFromGeometry,
  isValidLineString,
  isValidPolygon,
  isValidMultiPolygon,
  isValidPoint,
} from "utils/geometry"
import {
  Amenity,
  AmenityData,
  Anchor,
  AnchorData,
  AnchorDataProperties,
  Fixture,
  FixtureData,
  Id,
  Kiosk,
  KioskData,
  Level,
  LevelData,
  OccupantData,
  OccupantFeature,
  Opening,
  OpeningData,
  Section,
  SectionData,
  Unit,
  UnitData,
  VenueData,
} from "../types"

// Used for clean up features removing missing feature from an Array of Features
const sanitizedFeatures = <T>(features: T[]): T[] => {
  return _.compact(features)
}

const bindAnchor = (feature, anchor: AnchorData) => {
  if (anchor) {
    feature.properties.anchor = anchor
    // TODO: Check if this is still used?
    feature.properties.ordinal = anchor?.properties?.unit?.properties?.ordinal
    feature.properties.level_name =
      anchor?.properties?.unit?.properties?.level?.properties?.name.en
    feature.properties.level_id =
      anchor?.properties?.unit?.properties?.level?.id
  }
}

const bindLevel = (feature, level: LevelData) => {
  if (level) {
    feature.properties.level = level
    feature.properties.ordinal = level.properties.ordinal
  }
}

const bindLocalCategories = (feature, localCategories) => {
  feature.properties.local_categories = localCategories

  // Attach Parent Categories
  const parentCategories = localCategories.filter(
    (cat) => cat?.properties.parent_id === null
  )
  const subCategories = localCategories.filter(
    (cat) => cat?.properties.parent_id !== null
  )
  feature.properties.local_parent_categories = parentCategories
  feature.properties.local_sub_categories = subCategories
}

const bindGroups = (feature, groups) => {
  feature.properties.groups = groups
}

const bindUnit = (feature, unit: UnitData) => {
  feature.properties.unit = unit
}

const bindKiosk = (feature, kiosk: KioskData) => {
  feature.properties.kiosk = kiosk
}

const bindUnits = (feature, units: UnitData[]) => {
  feature.properties.units = units

  // Default level at units[0]
  // TODO: Check if this is still used?
  const [defaultUnit] = units
  feature.properties.ordinal = defaultUnit.properties.ordinal
  feature.properties.level_name =
    defaultUnit.properties.level?.properties?.name.en
}

const bindOccupant = (feature, occupant) => {
  if (occupant) {
    feature.properties.occupant = occupant
  }
}

const bindOrigin = (feature, origin) => {
  feature.properties.origin = origin
}

const bindDestination = (feature, destination) => {
  if (destination) {
    feature.properties.destination = destination
  }
}

const bindIntermediary = (feature, intermediary = null) => {
  feature.properties.intermediary = intermediary
}

const bindPromotions = (feature, promotions) => {
  feature.properties.promotions = promotions
}

const bindPrivileges = (feature, privileges) => {
  feature.properties.privileges = privileges
}

const bindSection = (feature, section: SectionData) => {
  feature.properties.section = section
}

const bindVenue = (feature, venue: VenueData) => {
  feature.properties.venue = venue
}

const bindHours = (feature, hours: string | null) => {
  feature.properties.hours = hours
}

const findFeatureInSection = (
  feature,
  sections = []
): SectionData | undefined => {
  const { geometry } = feature
  if (sections.length < 1) return
  const center = getCenterFromGeometry(geometry)
  return sections.find((section) => pointInPolygon(center, section))
}

const findAmenityKiosk = (feature, kiosks = []) => {
  const { geometry } = feature
  if (kiosks.length < 1) return
  const center = getCenterFromGeometry(geometry)
  return kiosks.find((kiosk) => pointInPolygon(center, kiosk))
}

const getFeaturesObj = (features) => {
  const featureObj = {}
  for (let index = 0; index < features.length; index++) {
    const feature = features[index]
    try {
      featureObj[feature?.id] = feature
    } catch (error) {
      console.warn("error creating feature object", feature?.id)
    }
  }
  return featureObj
}

export const prepareMapDecorations = (mapDecorations) => {
  const returnArray = mapDecorations.reduce((acc, decoration) => {
    const decorations = decoration.geometries.map(
      (decorationGeometry, index) => {
        const {
          geometry,
          options,
          properties: decorationProperties,
        } = decorationGeometry
        const { symbol } = options
        const id = decoration.id + `-${index}`
        const properties = { symbol, ...decorationProperties }

        return { id, geometry, properties }
      }
    )
    return [...acc, ...decorations]
  }, [])

  return returnArray
}

export const prepareFeatures = (venueData) => {
  const {
    amenities = [],
    anchors = [],
    details = [],
    fixtures = [],
    kiosks = [],
    occupants = [],
    openings = [],
    privileges = [],
    relationships = [],
    sections = [],
    levels = [],
    units = [],
    features: featureCollection = [],
  } = venueData

  // Store feature in objects for easy find by id
  const featuresObj = getFeaturesObj(featureCollection)

  // Functions
  const findOne = (id: Id) => {
    if (!id) return null
    try {
      const feature = featuresObj[id]
      if (!feature)
        throw new Error(`[preparing] Cannot find feature with id ${id}`)
      return feature
    } catch (err) {
      // console.warn(err.message)
      return null
    }
  }

  const prepareFixtures = (fixture: Fixture): FixtureData | null => {
    try {
      const { properties, geometry, id } = fixture

      const fixtureData = {
        ...fixture,
        properties: { ...properties },
      }

      const { anchor_id, level_id, venue_id } = properties
      bindAnchor(fixtureData, findOne(anchor_id))

      const level = findOne(level_id) as LevelData | null
      if (_.isNil(level))
        throw new Error(`fixture does not have level (${id}).`)
      bindLevel(fixtureData, level)

      const venue = findOne(venue_id) as VenueData | null
      bindVenue(fixtureData, venue)

      if (!isValidMultiPolygon(geometry) && !isValidPolygon(geometry))
        throw new Error(
          `fixture has invalid coordinates it must be Polygon or MultiPolygon (${id}).`
        )

      featuresObj[id] = fixtureData
      return fixtureData as FixtureData
    } catch (error) {
      return null
    }
  }

  const prepareSection = (section: Section) => {
    try {
      const { geometry, properties, id } = section
      const { level_id, venue_id } = section.properties
      const sectionData = {
        ...section,
        properties: { ...properties },
      }
      const level = findOne(level_id)

      if (!level) throw new Error(`Can not find level for Section : ${id}`)
      bindLevel(sectionData, level)

      const venue = findOne(venue_id)
      bindVenue(sectionData, venue)

      if (!isValidMultiPolygon(geometry) && !isValidPolygon(geometry))
        throw new Error(
          `section has invalid coordinates it must be Polygon or MultiPolygon (${id}).`
        )
      // TODO: bindAddress()
      // TODO: bindCorrelation()
      // TODO: bindParent()
      featuresObj[id] = sectionData
      return sectionData as SectionData
    } catch (error) {
      return null
    }
  }

  const prepareKiosk = (kiosk: Kiosk): KioskData | null => {
    try {
      const { properties, geometry } = kiosk
      const { anchor_id, level_id, venue_id } = kiosk.properties
      const kioskData = {
        ...kiosk,
        properties: { ...properties },
      }

      if (!isValidMultiPolygon(geometry) && !isValidPolygon(geometry))
        throw new Error(
          `kiosk has invalid coordinates it must be Polygon or MultiPolygon (${kioskData?.id}).`
        )

      const level = findOne(level_id) as LevelData | null
      if (_.isNil(level))
        throw new Error(`kiosk does not have level (${kioskData?.id}).`)
      bindLevel(kioskData, level)

      const anchor = findOne(anchor_id) as AnchorData | null
      bindAnchor(kioskData, anchor)

      const venue = findOne(venue_id) as VenueData | null
      bindVenue(kioskData, venue)

      const section: SectionData | undefined = findFeatureInSection(
        kioskData,
        sectionsByOrdinal[kioskData.properties.ordinal]
      )
      bindSection(kioskData, section)
      featuresObj[kioskData.id] = kioskData

      return kioskData as KioskData
    } catch (error) {
      return null
    }
  }

  const prepareAnchor = (anchor: Anchor): AnchorData | null => {
    try {
      const { properties, geometry } = anchor
      const { unit_id } = properties
      const locatedUnit = findOne(unit_id) as UnitData | null

      const anchorData = {
        ...anchor,
        properties: { ...properties },
      }
      // Validate Coordinates
      if (!isValidPoint(geometry))
        throw new Error(
          `Invalid Point Coordinates for Anchor : ${anchorData.id}`
        )

      // Validate Properties
      if (!locatedUnit)
        throw new Error(`Invalid Anchor without unit : ${anchorData.id}`)
      bindUnit(anchorData, locatedUnit)

      const level = locatedUnit?.properties?.level
      if (!level)
        throw new Error(`Invalid Anchor without level : ${anchorData.id}`)
      bindLevel(anchorData, level)

      const section = findFeatureInSection(
        anchorData,
        sectionsByOrdinal[
          (anchorData.properties as AnchorDataProperties).ordinal
        ]
      )
      bindSection(anchor, section)

      featuresObj[anchorData.id] = anchorData

      return anchorData as AnchorData
    } catch (error) {
      console.warn(error.message)
      return null
    }
  }

  const prepareAmenity = (amenity: Amenity): AmenityData | null => {
    try {
      const { properties, geometry } = amenity
      const { unit_ids, venue_id } = amenity.properties
      const amenityData = {
        ...amenity,
        properties: { ...properties },
      }

      if (!isValidPoint(geometry))
        throw new Error(`Invalid Point Coordinates for Amenity : ${amenity.id}`)

      // TODO : Add type to Unit
      const units = sanitizedFeatures(unit_ids.map(findOne) as UnitData[])
      const venue: VenueData = findOne(venue_id)

      bindUnits(amenityData, units)

      if (_.isEmpty((amenityData as AmenityData).properties.units))
        throw new Error(`Invalid Amenity without unit : ${amenityData.id}`)

      bindVenue(amenityData, venue)

      const kiosksInSameOrdinal =
        kiosksByOrdinal[(amenityData as AmenityData).properties?.ordinal] || []

      const kiosk: KioskData = findAmenityKiosk(amenity, kiosksInSameOrdinal)
      bindKiosk(amenityData, kiosk)
      // TODO: bindAddress(feature)
      // TODO: bindCorrelation(feature)
      featuresObj[amenityData.id] = amenityData as AmenityData
      return amenityData as AmenityData
    } catch (error) {
      console.warn(error.message)
      return null
    }
  }

  const prepareRelationship = (relationship) => {
    const { properties } = relationship
    const { origin, destination, intermediary, category } =
      relationship.properties
    try {
      const relationshipData = {
        ...relationship,
        properties: { ...properties },
      }

      if (origin) {
        bindOrigin(relationshipData, findOne(origin.id))
      }
      if (destination) {
        bindDestination(relationshipData, findOne(destination.id))
      }

      if (
        !relationshipData.properties.origin &&
        !relationshipData.properties.destination
      )
        throw new Error("relationship does not have origin and destination")

      bindIntermediary(
        relationshipData,
        sanitizedFeatures(intermediary?.map((feature) => findOne(feature.id)))
      )

      switch (category) {
        case "traversal": {
          const intermediaryData = relationshipData.properties.intermediary
          if (!_.isArray(intermediaryData) || _.isEmpty(intermediaryData))
            throw new Error("traversal relationship should have intermediary")
          break
        }
        case "stairs":
        case "ramp":
        case "escalator":
        case "elevator": {
          if (
            !relationshipData.properties.origin ||
            !relationshipData.properties.destination
          )
            throw new Error(
              `${category} relationship does not have origin or destination`
            )
          break
        }
        default: {
          break
        }
      }
      featuresObj[relationship.id] = relationshipData
      return relationshipData
    } catch (error) {
      console.warn(error.message)
      return null
    }
  }

  const prepareDetail = (detail) => {
    try {
      const { properties, id } = detail
      const detailData = {
        ...detail,
        properties: { ...properties },
      }

      const { level_id } = properties
      bindLevel(detail, findOne(level_id))
      featuresObj[id] = detailData
      return detailData
    } catch (error) {
      return null
    }
  }

  const prepareOccupant = (occupant: OccupantFeature): OccupantData | null => {
    try {
      const { properties, id } = occupant
      const {
        anchor_id,
        local_category_ids,
        kiosk_id,
        unit_id,
        promotion_ids,
        privilege_ids,
        group_ids = [],
        venue_id,
      } = properties

      const occupantData = {
        ...occupant,
        properties: { ...properties },
      }

      if (!properties?.name?.en)
        throw new Error(`Occupant does not have name : ${id}`)

      const anchor = findOne(anchor_id) as AnchorData | null
      bindAnchor(occupantData, anchor)

      bindLocalCategories(
        occupantData,
        sanitizedFeatures(local_category_ids.map(findOne))
      )

      bindGroups(occupantData, sanitizedFeatures(group_ids.map(findOne)))

      const kiosk = findOne(kiosk_id) as KioskData | null
      bindKiosk(occupantData, kiosk)

      const unit = findOne(unit_id) as UnitData | null
      bindUnit(occupantData, unit)

      bindPromotions(
        occupantData,
        sanitizedFeatures(promotion_ids.map(findOne))
      )
      bindPrivileges(
        occupantData,
        sanitizedFeatures(privilege_ids.map(findOne))
      )

      const venue = findOne(venue_id) as VenueData
      if (venue) {
        bindVenue(occupantData, venue)
        const venueHours = venue.properties.hours
        const occupantHours = occupant.properties.hours
        if (!occupantHours) bindHours(occupantData, venueHours)
      }

      featuresObj[id] = occupantData
      // TODO: bindCorrelation(feature)
      return occupantData as OccupantData
    } catch (error) {
      console.warn(error.message)
      return null
    }
  }

  const prepareOpening = (opening: Opening): OpeningData | null => {
    try {
      const { properties, geometry } = opening
      const { level_id, venue_id } = opening.properties
      const openingData = {
        ...opening,
        properties: { ...properties },
      }
      if (!isValidLineString(geometry))
        throw new Error(
          `Invalid LineString Coordinates for Opening : ${opening.id}`
        )
      const level = findOne(level_id)
      if (!level)
        throw new Error(`Can not find level for Opening : ${opening.id}`)
      bindLevel(openingData, level)

      const venue: VenueData = findOne(venue_id)
      bindVenue(openingData, venue)

      featuresObj[openingData.id] = openingData

      return openingData as OpeningData
    } catch (error) {
      console.warn(error.message)
      return null
    }
  }

  const prepareLevel = (level: Level): LevelData | null => {
    try {
      const { venue_id } = level.properties
      const venue: VenueData | null = findOne(venue_id)
      bindVenue(level, venue)
      featuresObj[level.id] = level
      // TODO: bindAddress(level)
      // TODO: bindBuildings(level)
      return level as LevelData
    } catch (error) {
      return null
    }
  }

  const prepareUnit = (unit: Unit): UnitData | null => {
    try {
      const { geometry, properties, id } = unit
      const { level_id, venue_id } = properties

      const unitData = {
        ...unit,
        properties: { ...properties },
      }

      if (!isValidMultiPolygon(geometry) && !isValidPolygon(geometry))
        throw new Error(
          `unit has invalid coordinates it must be Polygon or MultiPolygon (${id}).`
        )

      const level = findOne(level_id)
      if (!level) throw new Error(`Can not find level for Opening : ${id}`)
      bindLevel(unitData, level)

      const venue = findOne(venue_id)
      bindVenue(unitData, venue)

      const section = findFeatureInSection(
        unitData,
        sectionsByOrdinal[(unitData as UnitData).properties.ordinal]
      )
      bindSection(unitData, section)

      featuresObj[id] = unitData

      return unitData as UnitData
    } catch (error) {
      console.warn(error.message)
      return null
    }
  }

  // Prepare Levels
  const levelDatas = _(levels).map(prepareLevel).compact().value()

  // Prepare Sections
  const sectionDatas = _(sections).map(prepareSection).compact().value()

  const sectionsByOrdinal = _(sections)
    .filter((feature) => feature.properties.category === "retail")
    .groupBy((feature) => feature.properties.ordinal)
    .value()

  // Prepare Units

  const unitDatas = _(units).map(prepareUnit).compact().value()

  // Prepare Anchors
  const anchorDatas = _(anchors).map(prepareAnchor).compact().value()

  // Prepare Details
  const detailDatas = _(details).map(prepareDetail).compact().value()

  // Prepare Fixtures
  const fixtureDatas = _(fixtures).map(prepareFixtures).compact().value()

  // Prepare Kiosks
  const kioskDatas = _(kiosks).map(prepareKiosk).compact().value()

  const kiosksByOrdinal = _(kioskDatas)
    .groupBy((feature) => feature.properties.ordinal)
    .value()

  // Prepare Amenities
  const amenityDatas = _(amenities).map(prepareAmenity).compact().value()

  // Prepare Occupants
  const occupantDatas = _(occupants).map(prepareOccupant).compact().value()

  // Prepare Relationships
  const openingDatas = _(openings).map(prepareOpening).compact().value()

  // Prepare Relationships
  const relationshipDatas = _(relationships)
    .map(prepareRelationship)
    .compact()
    .value()

  /**
   * Non- imdf
   */
  // Prepare Promotion
  privileges.forEach((feature) => {
    const { occupant_id, venue_id } = feature.properties
    const occupant = findOne(occupant_id)
    const venue = findOne(venue_id)
    bindOccupant(feature, occupant)
    bindVenue(feature, venue)
  })
  venueData.featuresObj = featuresObj
  venueData.features = Object.values(featuresObj)

  return {
    ...venueData,
    anchors: anchorDatas,
    amenities: amenityDatas,
    details: detailDatas,
    fixtures: fixtureDatas,
    kiosks: kioskDatas,
    levels: levelDatas,
    occupants: occupantDatas,
    openings: openingDatas,
    relationships: relationshipDatas,
    sections: sectionDatas,
    units: unitDatas,
  }
}
