import React from "react"
import {
  Box,
  Button,
  Typography,
  useTheme,
  FormControlLabel,
  Switch,
  Backdrop,
  CircularProgress,
} from "@mui/material"
import { deepmerge } from "@mui/utils"

import { useVenue } from "providers/venue"
import { useTranslation } from "providers/i18n"
import { useDirectionOverview } from "hooks/direction/useDirectionOverview"
import { useDirectionOverviewMap } from "hooks/direction/useDirectionOverviewMap"

import {
  MarkerIcon,
  PlayArrowIcon,
  YouAreHereMarkerIcon,
} from "components/icons"
import { OrdinalSelector } from "components/OrdinalSelector"
import { PageCard } from "components/card"
import { NavigationControl } from "components/kioskHorizontal/navigation"
import { VerticalLinearProgress } from "components/Progress/VerticalLinearProgress"
import { SwitchVenueStep } from "components/domains/direction/SwitchVenueStep"
import { Image } from "components/Image"

export const DirectionOverviewKioskHPage = ({ Layout = Box }) => {
  const {
    isCalculatingRoute,
    currentOrdinal,
    originName,
    originLevelName,
    originVenueName,
    originVenueData,
    destinationName,
    destinationLevelName,
    destinationVenueName,
    destinationVenueData,
    isGoingToOtherVenue,
    progressValue,
    route,
    elevatorPreferredMode,
    relatedLevelWithSteps,
    changeOrdinal,
    toggleElevatorPreference,
    startNavigate,
  } = useDirectionOverview()

  useDirectionOverviewMap(route, currentOrdinal)

  const { isMultiVenueProject, isMultiOrdinalVenue } = useVenue()
  const { t } = useTranslation()

  const theme = useTheme()

  const {
    root,
    stepContainer,
    stepTextContainer,
    stepItem,
    originIcon,
    destinationIcon,
    ordinalSelector,
    primaryText,
    secondaryText,
    headerWrapper,
    actionContainer,
    avoidStepButton,
    venueLogo,
    playerActionContainer,
    primaryButton,
  } = theme.components[`AppDirectionOverview`]?.styleOverrides || {}

  const sideBarStyleOverrides =
    theme.components[`AppSidebar`]?.styleOverrides || {}
  const { wrapper, body, footer, playerButton } = sideBarStyleOverrides

  const originFloorText = isMultiOrdinalVenue
    ? `${t("At Floor", {
        value: originLevelName,
      })} `
    : ""

  const destinationFloorText = isMultiOrdinalVenue
    ? `${t("At Floor", {
        value: destinationLevelName,
      })} `
    : ""

  const originVenueText = isMultiVenueProject ? t(originVenueName) : ""

  const destinationVenueText = isMultiVenueProject
    ? t(destinationVenueName)
    : ""

  return (
    <Layout
      aside={
        <PageCard>
          <Box sx={wrapper}>
            <Box sx={body}>
              <Box sx={root}>
                <Box sx={headerWrapper}>
                  {isGoingToOtherVenue ? (
                    <SwitchVenueStep
                      originVenueLogo={originVenueData?.logo}
                      destinationVenueLogo={destinationVenueData?.logo}
                    />
                  ) : (
                    <Image sx={venueLogo} src={originVenueData?.logo} />
                  )}
                </Box>
                <Box sx={stepContainer}>
                  <Backdrop open={isCalculatingRoute}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  <Box sx={stepItem}>
                    <MarkerIcon sx={destinationIcon} />
                    <Box sx={stepTextContainer}>
                      <Typography
                        variant="subTitle"
                        component="p"
                        sx={primaryText}
                      >
                        {t(destinationName)}
                      </Typography>
                      <Typography
                        variant="subTitle"
                        component="p"
                        sx={secondaryText}
                      >
                        {destinationFloorText || destinationVenueText
                          ? `${destinationFloorText}${destinationVenueText}`
                          : t("Destination")}
                      </Typography>
                    </Box>
                  </Box>
                  <VerticalLinearProgress value={progressValue} />
                  <Box sx={stepItem}>
                    <YouAreHereMarkerIcon sx={originIcon} />
                    <Box sx={stepTextContainer}>
                      <Typography
                        variant="subTitle"
                        component="p"
                        sx={primaryText}
                      >
                        {t(originName)}
                      </Typography>
                      <Typography
                        variant="subTitle"
                        component="p"
                        sx={secondaryText}
                      >
                        {originFloorText || originVenueText
                          ? `${originFloorText}${originVenueText}`
                          : t("Origin")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={actionContainer}>
                  <FormControlLabel
                    sx={avoidStepButton}
                    control={
                      <Switch
                        checked={elevatorPreferredMode}
                        onChange={toggleElevatorPreference}
                      />
                    }
                    label={t("Avoid Steps")}
                  />
                  <Box sx={playerActionContainer}>
                    <Button
                      variant="outlined"
                      sx={deepmerge(playerButton, primaryButton)}
                      startIcon={<PlayArrowIcon />}
                      onClick={startNavigate}
                    >
                      {t("play")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={footer}>
              <NavigationControl />
            </Box>
          </Box>
        </PageCard>
      }
    >
      {/* //TODO:: find a way for adding active ordinal (selectedOrdinal) */}
      {isMultiOrdinalVenue && (
        <Box sx={ordinalSelector}>
          <OrdinalSelector
            levels={relatedLevelWithSteps}
            onClickOrdinal={changeOrdinal}
            selectedOrdinal={currentOrdinal}
          />
        </Box>
      )}
    </Layout>
  )
}
