import { Box, Typography, useTheme } from "@mui/material"
import { useTranslation } from "providers/i18n"
import { getFeatureDescription } from "utils/feature"
import { StarIcon } from "components/icons"

export const ListPrivilegeItemCard = ({ icon = <StarIcon />, ...props }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const styleOverrides =
    theme.components[`ListPrivilegeItemCard`]?.styleOverrides || {}
  const { root, label } = styleOverrides

  return (
    <Box sx={root}>
      {icon}
      <Typography variant="subtitle2" sx={label}>
        {t(getFeatureDescription(props))}
      </Typography>
    </Box>
  )
}
