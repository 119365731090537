import { useMemo } from "react"
import { Box, ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material"
import { useTranslation } from "providers/i18n"
import { Link } from "react-router-dom"

import { getIcon } from "utils/icon"
import { getFeatureDetail } from "utils/feature"
import { useEvent } from "hooks/event/useEvent"
import { IFeature } from "interfaces"

import { Head } from "components/Head"
import { EmptyData } from "components/EmptyData"
import { CalendarStarIcon } from "components/icons"
import { Carousel, CarouselItem } from "components/Carousel"
import { MotionDiv, showContent } from "components/motions"

import { PageLayout } from "extensions/one-siam/layouts/PageLayout"
import {
  Header,
  HeaderMenuButton,
  HeaderSearchButton,
} from "extensions/one-siam/components/Header"
import { AppNavigation } from "extensions/one-siam/container/AppNavigation"
import { CarouselImageCard } from "extensions/one-siam/components/domains/carousel"

export const Events = ({ Layout = PageLayout }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { groupEvents, activeGroupEvent, handleChange } = useEvent()
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.events) || <CalendarStarIcon />,
    [theme]
  )

  const {
    root,
    eventGroupSelectorButton,
    emptyDataWrapper,
    navigationWrapper,
    toggleButtonGroup,
    toggleButton,
    headerWrapper,
    carouselContainer,
    eventCarousel,
    eventCarouselItem,
    eventImage,
  } = theme.components[`EventPage`]?.styleOverrides || {}

  const activeEvents = groupEvents[activeGroupEvent]
  const autoplay = activeEvents.length > 2

  return (
    <>
      <Head title={t("Events & Activities")} />
      <Layout
        header={
          <Box sx={headerWrapper}>
            <Header
              title={t("Events & Activities")}
              menu={<HeaderMenuButton />}
              search={<HeaderSearchButton />}
            />
          </Box>
        }
      >
        <MotionDiv
          animate="animate"
          initial="initial"
          variants={showContent}
          sx={root}
        >
          <Box sx={eventGroupSelectorButton}>
            <ToggleButtonGroup
              value={activeGroupEvent}
              onChange={handleChange}
              exclusive
              sx={toggleButtonGroup}
            >
              <ToggleButton value="current" sx={toggleButton}>
                {t("Current Events")}
              </ToggleButton>
              <ToggleButton value="upcoming" sx={toggleButton}>
                {t("Upcoming Events")}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {activeEvents && activeEvents.length > 0 ? (
            <Box sx={carouselContainer}>
              <Carousel
                sx={eventCarousel}
                options={{
                  fixedWidth: "auto",
                  fixedHeight: "auto",
                  pagination: true,
                  perPage: 1,
                  focus: "center",
                  trimSpace: false,
                  autoplay,
                  speed: 600,
                  gap: 16,
                }}
              >
                {activeEvents?.map((event) => {
                  const { id, properties } = event
                  const { isAd } = getFeatureDetail(event as IFeature)
                  const sponsoredId = properties.sponsoredId
                  return (
                    <CarouselItem
                      key={`${sponsoredId}event-${id}`}
                      sx={eventCarouselItem}
                    >
                      <Link to={`/events/${id}?redirectTo=-1`}>
                        <CarouselImageCard
                          src={properties?.cover_image?.url}
                          alt={`${properties?.cover_image?.name}`}
                          sx={eventImage}
                          isHighlight={isAd}
                        />
                      </Link>
                    </CarouselItem>
                  )
                })}
              </Carousel>
            </Box>
          ) : (
            <Box sx={emptyDataWrapper}>
              <EmptyData icon={emptyIcon} primary={t("No Event")} />
            </Box>
          )}
        </MotionDiv>

        <Box sx={navigationWrapper}>
          <AppNavigation />
        </Box>
      </Layout>
    </>
  )
}
