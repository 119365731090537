import React, { useEffect, useState } from "react"
import { Stack, Box, Divider, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import dayjs from "config/dayjs"
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined"
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined"

export const Clock = () => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()

  const [date, setDate] = useState(dayjs())
  const theme = useTheme()
  const { styleOverrides = {}, defaultProps = {} } =
    theme.components[`AppClock`] || {}
  const {
    dateFormat = "LLL",
    timeFormat = "hh:mm",
    timeUnitFormat = "A",
  } = defaultProps

  const {
    root,
    dateText,
    divider,
    timeWrapper,
    timeLabel,
    timeText,
    timeUnitText,
    icon,
    dateContainer,
    timeContainer,
  } = styleOverrides

  useEffect(() => {
    const timerId = setInterval(() => setDate(dayjs()), 1000)
    return () => clearInterval(timerId)
  }, [resolvedLanguage])

  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem sx={divider} />}
      alignItems="center"
      sx={root}
    >
      <Stack sx={dateContainer} alignItems="center" direction="row">
        <Box sx={icon}>
          <CalendarTodayOutlinedIcon color="primary" />
        </Box>
        <Box sx={dateText}>{date.format(dateFormat)}</Box>
      </Stack>
      <Box sx={timeWrapper}>
        <Box sx={timeLabel}>{t("Time")}</Box>
        <Stack sx={timeContainer} direction="row" alignItems="center">
          <Box sx={icon}>
            <AccessTimeOutlinedIcon color="primary" />
          </Box>
          <Stack direction="row" spacing={0.75} alignItems="center">
            <Box sx={timeText}>{date.format(timeFormat)}</Box>
            <Box sx={timeUnitText}>{date.format(timeUnitFormat)}</Box>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  )
}
