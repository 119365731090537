import { useCallback, useRef } from "react"
import { useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"

import { useTranslation } from "providers/i18n"
import { useAppUI } from "providers/ui"
import { useFeatureDetail } from "hooks/feature/useFeatureDetail"

import { MotionDiv } from "components/motions"
import { Head } from "components/Head"
import { FeatureCard } from "components/domains/features"

import { usePlaceDetailMap } from "hooks/place/usePlaceDetailMap"

const FADE_IN_TRANSITION = {
  initial: { opacity: 0 },
  enter: { opacity: 1 },
}

export const PlaceDetailKioskVPage = () => {
  const { dataLoaded, ...data } = useFeatureDetail()
  const { feature, name, id } = data
  const dialogRef = useRef(null)
  const dialogHeight = +dialogRef.current?.clientHeight || 0

  const theme = useTheme()
  const { styleOverrides = {} } = theme.components[`AppDetailPage`] || {}
  const { root } = styleOverrides

  const { goBack } = useAppUI()

  usePlaceDetailMap(feature, {
    enabled: !!feature,
    offset: { bottom: dialogHeight },
  })
  const navigate = useNavigate()
  const onNavigate = useCallback(() => {
    const locationId = feature?.properties?.feature?.id || id
    navigate(`/maps/dir/unknown/${locationId}`)
  }, [navigate, feature, id])

  const onViewPromotion = useCallback(() => {
    navigate(`/promotions/occupant/${id}`)
  }, [navigate, id])

  const { t } = useTranslation()

  const onClose = useCallback(() => {
    goBack()
  }, [goBack])
  return (
    <>
      <Head title={`${t(name)} (${t("Place Detail")})`} />
      <MotionDiv
        sx={root}
        ref={dialogRef}
        initial="initial"
        animate="enter"
        transition={{ duration: 0.25, ease: [0.51, 0.92, 0.24, 1.15] }}
        variants={FADE_IN_TRANSITION}
      >
        <FeatureCard
          open={true}
          onClose={onClose}
          loading={!dataLoaded}
          data={data}
          onNavigate={onNavigate}
          onClickPromotionButton={onViewPromotion}
        />
      </MotionDiv>
    </>
  )
}
