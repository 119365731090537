import Analytics from "electron-google-analytics4"
import _ from "lodash"
import { get as getPersistValue } from "utils/persist_store"
import config from "../../config"

declare global {
  interface Window {
    dataLayer: object[]
  }
}

interface IPushDataLayer {
  event: string
  keyword?: string
  promotion_id?: string | number
  promotion_name?: string
  event_id?: string | number
  event_name?: string
  search_category_name?: string
  selected_result_name?: string
  promotion_location_name?: string
  promotion_category?: string
  event_location_name?: string
  dining_category_id?: string
  dining_category_name?: string
  origin_category?: string
  origin_feature_type?: string
  origin_id?: string
  origin_name?: string
  origin_local_categories?: string
  destination_category?: string
  destination_feature_type?: string
  destination_id?: string
  destination_local_categories?: string
  destination_name?: string
  avoid_steps?: boolean
}

type PushDataLayer = (data: IPushDataLayer) => void

const trackingId = config("googleAnalyticsTrackingId")
const secretKey = config("googleAnalyticsSecretKey")
const appType = config("appType")
const analytics = new Analytics(trackingId, secretKey)

const setDeviceLocation = (data) => {
  const deviceLocation = getPersistValue("venue_device_location")
  if (deviceLocation) data["device_location"] = deviceLocation
}

const setUserLocation = (data) => {
  const userLocationJsonString = getPersistValue("user_location_value")
  const userLocation = _.get(JSON.parse(userLocationJsonString), "value")
  if (userLocation) data["user_location"] = userLocation
}

const setLocationName = (data) => {
  data["location_name"] = getPersistValue("venue_device_name") || ""
}

export const pushDataLayer: PushDataLayer = (data: IPushDataLayer) => {
  setLocationName(data)
  setDeviceLocation(data)
  setUserLocation(data)
  if (appType === "electron") {
    const { event, ...eventParameters } = data
    analytics.setParams(eventParameters).event(event)
    return
  }
  window.dataLayer.push(data)
}
