import React from "react"
import { Box, Stack, Chip, useTheme } from "@mui/material"
import SimpleBar from "simplebar-react"

export const SearchQuickLink = ({ items = [], onSelect }) => {
  const theme = useTheme()
  const quickLinkStyles =
    theme.components[`VuiSearchQuickLink`]?.styleOverrides || {}

  return (
    <Box sx={quickLinkStyles.root}>
      <SimpleBar>
        <Stack direction="row" spacing={1} sx={quickLinkStyles.stack}>
          {items.map((item) => (
            <Chip
              key={item.key}
              {...item}
              sx={quickLinkStyles.item}
              onClick={(e) => onSelect(item)}
            />
          ))}
        </Stack>
      </SimpleBar>
    </Box>
  )
}
