import React, { useCallback, useMemo } from "react"
import { Box, ToggleButtonGroup, useTheme } from "@mui/material"

import { getIcon } from "utils/icon"
import { useTranslation } from "providers/i18n"
import { useNavigate } from "react-router-dom"
import { useEvent } from "hooks/event/useEvent"

import { PageCard, PageCardContent } from "components/card"
import { CalendarStarIcon } from "components/icons"
import { AppNavigation } from "containers/kiosk-h/AppNavigation"
import { NavigationControl } from "components/kioskHorizontal/navigation"
import { VenueSelector } from "components/VenueSelector"
import { Carousel, CarouselItem } from "components/Carousel"
import { EmptyData } from "components/EmptyData"
import { VerticalEventCard } from "components/domains/events"
import { CategoryFilterChip } from "components/CategoryFilterBar"

export const EventsKioskHPage = ({ Layout = Box }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    isMultiVenueProject,
    venueFilter,
    venues,
    locationVenueId,
    groupEvents,
    activeGroupEvent,
    handleChange,
    changeVenueFilter,
  } = useEvent()
  const theme = useTheme()

  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.events) || <CalendarStarIcon />,
    [theme]
  )

  const { styleOverrides: bannerStyleOverrides = {} } =
    theme.components[`AppBanner`] || {}
  const sideBarStyleOverrides =
    theme.components[`AppSidebar`]?.styleOverrides || {}
  const { venueSelector } = theme.components[`AppMap`]?.styleOverrides || {}

  const { root: bannerRoot } = bannerStyleOverrides
  const { wrapper, body, footer } = sideBarStyleOverrides

  const { styleOverrides } = theme.components?.[`EventPage`] || {}
  const {
    carouselContainer,
    carousel,
    eventCard,
    emptyDataWrapper,
    eventGroupSelectorButton,
  } = styleOverrides

  const handleClickSlide = useCallback(
    (_splide, { slide }, _event) => {
      navigate(slide.getAttribute("link"))
    },
    [navigate]
  )

  return (
    <Layout
      aside={
        <PageCard>
          <Box sx={wrapper}>
            <Box sx={bannerRoot}></Box>
            <Box sx={body}>
              <AppNavigation />
            </Box>
            <Box sx={footer}>
              <NavigationControl BackButtonProps={{ hidden: true }} />
            </Box>
          </Box>
        </PageCard>
      }
    >
      <PageCard>
        <PageCardContent>
          <Box sx={carouselContainer}>
            {groupEvents[activeGroupEvent] &&
            groupEvents[activeGroupEvent].length > 0 ? (
              <Carousel
                onClick={handleClickSlide}
                options={{
                  type: "loop",
                  pagination: true,
                  focus: "center",
                  autoplay: true,
                  gap: "0",
                }}
                sx={carousel}
              >
                {groupEvents[activeGroupEvent].map((event) => (
                  <CarouselItem link={`/events/${event.id}`} key={event.id}>
                    <VerticalEventCard event={event} sx={eventCard} />
                  </CarouselItem>
                ))}
              </Carousel>
            ) : (
              <Box sx={emptyDataWrapper}>
                <EmptyData
                  icon={emptyIcon}
                  primary={
                    activeGroupEvent === "current"
                      ? t(`No Current Events`)
                      : t(`No Upcoming Events`)
                  }
                  secondary={t("Check Back Soon")}
                />
              </Box>
            )}
          </Box>

          {isMultiVenueProject && (
            <Box sx={venueSelector}>
              <VenueSelector
                selectedVenue={venueFilter}
                venues={venues}
                onClickVenue={changeVenueFilter}
                currentVenue={locationVenueId}
              />
            </Box>
          )}

          <Box sx={eventGroupSelectorButton}>
            <ToggleButtonGroup
              value={activeGroupEvent}
              onChange={handleChange}
              exclusive
            >
              <CategoryFilterChip value="current">
                {t("Current Events")}
              </CategoryFilterChip>
              <CategoryFilterChip value="upcoming">
                {t("Upcoming Events")}
              </CategoryFilterChip>
            </ToggleButtonGroup>
          </Box>
        </PageCardContent>
      </PageCard>
    </Layout>
  )
}
