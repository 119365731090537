import { Box, Stack, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"
import SimpleBar from "simplebar-react"
import _ from "lodash"

export const HorizontalScrollPane = ({
  children,
  sx = {},
  simpleBarProps = {},
  stackProps = {},
  ...props
}) => {
  const theme = useTheme()
  const styleOverrides = _.get(
    theme,
    "components.HorizontalScrollPane.styleOverrides",
    {}
  )
  const { root, listWrapper } = styleOverrides
  return (
    <Box
      className="horizontalScrollPane-root"
      sx={deepmerge(root, sx)}
      {...props}
    >
      <SimpleBar {...simpleBarProps}>
        <Stack
          className="horizontalScrollPane-list"
          direction="row"
          {...stackProps}
          sx={deepmerge(listWrapper, stackProps.sx || {})}
        >
          {children}
        </Stack>
      </SimpleBar>
    </Box>
  )
}
