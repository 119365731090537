import { useVenue } from "providers/venue"
import { useTranslation } from "providers/i18n"
import { Typography } from "@mui/material"
import { getFeatureDetail } from "../../../utils/feature"

export const LocationInfo = ({ feature, ...props }) => {
  const { isMultiOrdinalVenue } = useVenue()
  const { t } = useTranslation()
  const { level, location } = getFeatureDetail(feature)
  const locationText = isMultiOrdinalVenue
    ? t("Location Text", {
        location: t(location),
        level: t(level),
      })
    : t(location)

  return <Typography {...props}>{locationText}</Typography>
}
