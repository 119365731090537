import { useState, useEffect } from "react"
import { get } from "lodash"
import {
  isLatLngQueryString,
  createGeolocationFeature,
} from "utils/geolocation"
import { set as setPersistValue } from "utils/persist_store"
import { useElectronConfig } from "./useElectronConfig"
import {
  VENUE_DEVICE_LOCATION_KEY,
  VENUE_DEVICE_VIEW_POINT_KEY,
  VENUE_DEVICE_NAME_KEY,
} from "constant"

export const useElectronDeviceLocation = () => {
  const [deviceLocationState, setDeviceLocationState] = useState(null)
  const [deviceLocationNameState, setDeviceLocationNameState] = useState(null)
  const [viewPointState, setViewPointState] = useState(null)
  const { config } = useElectronConfig()

  useEffect(() => {
    if (config) {
      const locationPath = get(config, VENUE_DEVICE_LOCATION_KEY, "")
      const locationName = get(config, VENUE_DEVICE_NAME_KEY, "")
      if (isLatLngQueryString(locationPath)) {
        setPersistValue(VENUE_DEVICE_LOCATION_KEY, locationPath)
        setDeviceLocationState(
          createGeolocationFeature(locationPath, {
            name: "Your Location",
          })
        )
      }
      const viewPointPath = get(config, VENUE_DEVICE_VIEW_POINT_KEY, "")
      if (isLatLngQueryString(viewPointPath)) {
        setViewPointState(
          createGeolocationFeature(viewPointPath, {
            name: "View Point Location",
          })
        )
      }
      setDeviceLocationNameState(locationName)
    }
  }, [config])

  useEffect(() => {
    //TODO finding a better position for setPersistValue VENUE_DEVICE_NAME
    const locationName = get(config, VENUE_DEVICE_NAME_KEY, "")
    if (locationName) {
      setPersistValue(VENUE_DEVICE_NAME_KEY, locationName)
      setDeviceLocationNameState(locationName)
    }
  }, [config])

  return {
    deviceLocation: deviceLocationState,
    deviceLocationName: deviceLocationNameState,
    viewPoint: viewPointState,
  }
}
