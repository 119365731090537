import { useCallback } from "react"
import {
  Avatar,
  Box,
  Button,
  Card,
  Typography,
  useTheme,
  Stack,
  CardMedia,
} from "@mui/material"
import { Link, useNavigate } from "react-router-dom"

import { useTranslation } from "providers/i18n"
import { useSponsoredScreensaver } from "hooks/sponsored-content/useSponsoredScreensaver"
import { useHome } from "../../hooks/home/useHome"
import { useHomeMap } from "hooks/home/useHomeMap"

import { getFeatureName } from "utils/feature"
import { pushDataLayer } from "utils/googletagmanager/pushDataLayer"

import { FullScreenOverlayLayout } from "layouts/FullScreenOverlayLayout"

import { Head } from "components/Head"
import { Carousel, CarouselItem } from "components/Carousel"
import { HighlightFeatureCard } from "components/domains/events"
import { GTM_COUNT_KIOSK_USERS } from "constant"

export const HomePage = () => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { featuredEventPromotion, defaultItemsLinks, venues, locales } =
    useHome()

  useHomeMap(venues)

  const { screensavers, screensaverConfig } = useSponsoredScreensaver()
  const styleOverrides = theme.components[`HomePage`]?.styleOverrides || {}
  const {
    root,
    contentContainer,
    fullscreenLayout,
    featureCarouselWrapper,
    carouselWrapper,
    welcomeContainer,
    greetingText,
    localeListContainer,
    localeButton,
    actionContainer,
    actionButton,
    textCard,
    linkCardContainer,
    defaultCardPrimaryText,
    defaultCardSecondaryText,
    defaultCardIcon,
    carouselScreenSaver,
    carouselMediaItem,
  } = styleOverrides

  const switchLanguage = useCallback(
    (lang) => (e) => {
      i18n.changeLanguage(lang)
    },
    [i18n]
  )

  const handleClick = useCallback(() => {
    pushDataLayer({
      event: GTM_COUNT_KIOSK_USERS,
      count_by_event: GTM_COUNT_KIOSK_USERS,
    })
    navigate(`/maps`)
  }, [navigate])

  return (
    <>
      <Head title={t("Indoor map")} />
      <Box sx={root} className="page">
        <FullScreenOverlayLayout sx={fullscreenLayout}>
          <Box sx={contentContainer} className="contentContainer">
            <Box sx={carouselWrapper}>
              {screensavers.length > 0 && (
                <Carousel
                  options={{
                    type: "loop",
                    padding: 0,
                    gap: 0,
                    pagination: false,
                    drag: true,
                    ...screensaverConfig, // { autoplay: boolean , interval: number }
                  }}
                  sx={carouselScreenSaver}
                >
                  {screensavers.map((screenSaver) => (
                    <CarouselItem link={screenSaver.url} key={screenSaver.id}>
                      <CardMedia
                        sx={carouselMediaItem}
                        {...screenSaver?.props}
                      />
                    </CarouselItem>
                  ))}
                </Carousel>
              )}
            </Box>
            <Box sx={welcomeContainer}>
              <Typography variant="h3" sx={greetingText}>
                {t("Welcome to", { name: t(getFeatureName(venues[0])) })}
              </Typography>
              <Box sx={localeListContainer}>
                {locales.map((locale) => (
                  <Button
                    key={locale.localeKey}
                    onClick={switchLanguage(locale.localeKey)}
                    sx={localeButton}
                  >
                    <Avatar
                      src={`https://flagcdn.com/h40/${locale.flagCode}.webp`}
                    />
                  </Button>
                ))}
              </Box>
              <Box sx={actionContainer}>
                <Button
                  sx={actionButton}
                  variant="contained"
                  onClick={handleClick}
                >
                  {t("Touch to start")}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box sx={featureCarouselWrapper}>
            <Carousel
              options={{
                gap: theme.spacing(1.25),
                pagination: false,
                focus: "center",
                arrows: false,
                mediaQuery: "min",
                breakpoints: {
                  640: {
                    perPage: 2,
                  },
                  900: {
                    perPage: 3,
                  },
                },
              }}
            >
              {featuredEventPromotion.map((feature) => {
                const { feature_type, id } = feature
                return (
                  <CarouselItem key={id}>
                    {/*
                     * @Example: Event => events/event-id, Promotion => promotions/promotion-id
                     */}
                    <Link to={`${feature_type}s/${id}`}>
                      <HighlightFeatureCard feature={feature} />
                    </Link>
                  </CarouselItem>
                )
              })}
              {defaultItemsLinks && defaultItemsLinks.length > 0 ? (
                <CarouselItem>
                  <Box sx={linkCardContainer}>
                    {defaultItemsLinks.map(
                      ({ to, primaryText, secondaryText, icon }) => (
                        <Link key={to} to={to}>
                          <Card sx={textCard}>
                            <Stack spacing={1}>
                              <Box sx={defaultCardIcon}>{icon}</Box>
                              <Stack spacing={0.5}>
                                <Typography
                                  variant="title"
                                  sx={defaultCardPrimaryText}
                                >
                                  {t(primaryText)}
                                </Typography>
                                <Typography
                                  variant="subTitle"
                                  sx={defaultCardSecondaryText}
                                >
                                  {t(secondaryText)}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Card>
                        </Link>
                      )
                    )}
                  </Box>
                </CarouselItem>
              ) : null}
            </Carousel>
          </Box>
        </FullScreenOverlayLayout>
      </Box>
    </>
  )
}
