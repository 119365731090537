import { ToggleButtonGroup, ToggleButton, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"
import { useTranslation } from "providers/i18n"

export const LevelFilterBar = ({ levels, onChange, value, sx }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const styleOverrides =
    theme.components[`LevelFilterBar`]?.styleOverrides || {}
  const { root } = styleOverrides

  return (
    <ToggleButtonGroup
      onChange={onChange}
      value={value}
      exclusive
      sx={deepmerge(root, sx)}
    >
      <ToggleButton value={"ALL"} color="standard">
        {t("All Floors")}
      </ToggleButton>
      {levels.map((level) => (
        <ToggleButton
          key={`level-filter-${level.properties.ordinal}`}
          value={level.properties.ordinal}
        >
          {level.properties.name.en}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
