import { useMemo } from "react"

import { useVenue } from "providers/venue"

import { UseFeatureQuickSearch } from "./interfaces"

import { getCategoriesFromFeatures } from "./utils"

export const useFeatureQuickSearch: UseFeatureQuickSearch = (
  category,
  viewingVenueId
) => {
  const {
    occupants,
    amenities,
    featureQuickSearchOrderConfig,
    isMultiVenueProject,
  } = useVenue()

  const features = useMemo(
    () =>
      [...amenities, ...occupants].filter(
        (feature) =>
          !isMultiVenueProject ||
          feature?.properties?.venue?.id === viewingVenueId
      ),
    [occupants, amenities, viewingVenueId, isMultiVenueProject]
  )

  const categories = useMemo(() => {
    return getCategoriesFromFeatures(features, featureQuickSearchOrderConfig)
  }, [features, featureQuickSearchOrderConfig])

  const quicksearchFeatures = useMemo(
    () =>
      features.filter((amenity) => amenity.properties.category === category),
    [features, category]
  )

  return {
    features: quicksearchFeatures,
    categories,
  }
}
