import _ from "lodash"
import sift from "sift"

import { ClientQueryOptions } from "../types"
import { DEFAULT_PAGINATION } from "../constants"

import { traverseQueryFilters } from "./filter"
import { serializeSortQuery } from "./sort"
import { generatePageInfo, getSliceIndexesByPagination } from "./pagination"

export const clientQuery = <TRecord = any>(
  sourceData: TRecord[],
  options: ClientQueryOptions
) => {
  /**
   * Client Query Hook
   * @param {object} filter - Filter object using MongoDB query style, powered by the Sift.js library.
   * @source https://github.com/crcn/sift.js#readme
   */
  const { filter, filterOptions, pagination, sort } = options || {}
  // Create a Sift's query
  const sanitizeFilters = traverseQueryFilters(filter, filterOptions)
  const filterQuery = sift(sanitizeFilters)

  // Filter collection using the Sift's query.
  const results = sourceData.filter(filterQuery)
  const total = results.length

  // Preparing pagination
  const paginationData = _.merge({}, DEFAULT_PAGINATION, pagination)
  const pageInfo = generatePageInfo<TRecord>(results, paginationData)

  // Manage sorting and slicing of results.
  const { fields, orders } = serializeSortQuery(sort)

  const { startIndex, endIndex } = getSliceIndexesByPagination(paginationData)

  const data = _(results)
    .orderBy(fields, orders)
    .slice(startIndex, endIndex)
    .value()

  return {
    data,
    total,
    pageInfo,
  }
}

export const clientInfiniteQuery = <TRecord = any>(
  sourceData: TRecord[],
  options: ClientQueryOptions
) => {
  const { data, pageInfo } = clientQuery<TRecord>(sourceData, options)
  const { nextPage, prevPage } = pageInfo

  return { data, nextPage, prevPage }
}
