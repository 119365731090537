import React, { useMemo } from "react"

import { Box, useTheme } from "@mui/material"

import { getIcon } from "utils/icon"
import { useTranslation } from "providers/i18n"
import { useDirectory } from "hooks"

import { PageCard, PageCardContent } from "components/card"
import { AppNavigation } from "containers/kiosk-h/AppNavigation"
import { NavigationControl } from "components/kioskHorizontal/navigation"
import { DirectoryOccupantCard } from "components/domains/directory/DirectoryOccupantCard"
import { EmptyData } from "components/EmptyData"
import { StoreIcon } from "components/icons"
import { VenueSelector } from "components/VenueSelector"
import {
  CategoryFilterBar,
  CategoryFilterChip,
} from "components/CategoryFilterBar/index.ts"
import { HorizontalScrollPane } from "components/HorizontalScrollPane"

export const DirectoryKioskHPage = ({ Layout = Box }) => {
  const { t } = useTranslation()
  const {
    handleChangeSubCategory,
    changeVenueFilter,
    filteredOccupantsSorted,
    subCategoryWithActiveParentCategory,
    subCategoryId,
    venueFilter,
    venues,
    isMultiVenueProject,
    locationVenueId,
    showSubCategoryFilter,
  } = useDirectory()

  const theme = useTheme()

  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.directory) || <StoreIcon />,
    [theme]
  )
  const styleOverrides = theme.components[`DirectoryPage`]?.styleOverrides || {}
  const { venueSelector } = theme.components[`AppMap`]?.styleOverrides || {}
  const {
    pageCard,
    gridContainer,
    gridItem,
    subCategoryFilterBar,
    scrollPane,
    emptyDataWrapper,
  } = styleOverrides

  const { styleOverrides: bannerStyleOverrides = {} } =
    theme.components[`AppBanner`] || {}
  const sideBarStyleOverrides =
    theme.components[`AppSidebar`]?.styleOverrides || {}

  const { root } = bannerStyleOverrides
  const { wrapper, body, footer } = sideBarStyleOverrides

  return (
    <Layout
      aside={
        <PageCard>
          <Box sx={wrapper}>
            <Box sx={root}></Box>
            <Box sx={body}>
              <AppNavigation />
            </Box>
            <Box sx={footer}>
              <NavigationControl BackButtonProps={{ hidden: true }} />
            </Box>
          </Box>
        </PageCard>
      }
    >
      <PageCard sx={pageCard}>
        <PageCardContent>
          <Box sx={gridContainer}>
            {filteredOccupantsSorted?.length > 0 ? (
              filteredOccupantsSorted.map((occupant) => (
                <Box sx={gridItem} key={`directory-item-${occupant.id}`}>
                  <DirectoryOccupantCard data={occupant} />
                </Box>
              ))
            ) : (
              <Box sx={emptyDataWrapper}>
                <EmptyData
                  icon={emptyIcon}
                  primary={t("Coming soon")}
                  secondary={t("Stay tuned for the update")}
                />
              </Box>
            )}
          </Box>
          {isMultiVenueProject && (
            <Box sx={venueSelector}>
              <VenueSelector
                selectedVenue={venueFilter}
                venues={venues}
                onClickVenue={changeVenueFilter}
                currentVenue={locationVenueId}
              />
            </Box>
          )}
          {showSubCategoryFilter && (
            <Box sx={subCategoryFilterBar}>
              <HorizontalScrollPane
                stackProps={{
                  sx: scrollPane,
                }}
              >
                <CategoryFilterBar
                  activeCategory={subCategoryId}
                  categories={subCategoryWithActiveParentCategory}
                  renderFilterButton={CategoryFilterChip}
                  onChange={handleChangeSubCategory}
                />
              </HorizontalScrollPane>
            </Box>
          )}
        </PageCardContent>
      </PageCard>
    </Layout>
  )
}
