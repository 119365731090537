import _ from "lodash"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router"

import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import { useAppUI } from "providers/ui"

import {
  gtmPushDataLayerSearchKeyword,
  gtmPushDataLayerCategoryQuickSearch,
} from "utils/googletagmanager/searchEvent"
import { listItemPropsParserFactory } from "utils/feature"

import config from "config"
import { useSponsoredSuggestedBrand } from "hooks/sponsored-content/useSponsoredSuggestedBrand"
import { useSponsoredSearchResult } from "hooks/sponsored-content/useSponsoredSearchResult"
import { GTM_NO_RESULT_VALUE, DEFAULT_VENUE_FILTER } from "constant"

export const useSearchContainer = ({
  initialVenueFilter = DEFAULT_VENUE_FILTER,
  showOnscreenKeyboard: showOnscreenKeyboardProp = undefined,
} = {}) => {
  const { t } = useTranslation({ defaultValue: null })
  const navigate = useNavigate()
  const {
    search,
    getSuggestedSearchItems,
    isMultiOrdinalVenue,
    occupants: venueOccupant,
    isMultiVenueProject,
  } = useVenue()
  const { openSearchState, openSearch, closeSearch } = useAppUI()
  const { searchResults, searchResultConfig } = useSponsoredSearchResult()
  const { suggestedBrands } = useSponsoredSuggestedBrand()
  const [searchValue, setSearchValue] = useState<string>()
  const [venueFilter, setVenueFilter] = useState<string>(initialVenueFilter)

  const searchInputRef = useRef()
  const keyboardRef = useRef()

  // To open the On-Screen Keyboard
  const device = config("device")
  const isKiosk = /^kiosk/.test(device)
  const showOnscreenKeyboard = _.isBoolean(showOnscreenKeyboardProp)
    ? showOnscreenKeyboardProp
    : isKiosk

  // Implement Search With Custom Debounce function
  const debounceSearchOnChange = useMemo(
    () =>
      _.debounce((value) => {
        setSearchValue(value)
      }, 400), // Debounce for 400 milliseconds
    []
  )

  // Search Result
  const resultParser = listItemPropsParserFactory({
    t,
    hideLevel: !isMultiOrdinalVenue,
    hideVenueLogo: !isMultiVenueProject,
  })

  // Search Result Ads
  const sponsoredContentListItems = useMemo(
    () => searchResults.map(resultParser),
    [searchResults, resultParser]
  )
  //every xxx results has 1 sponsored-content
  const sponsoredContentFrequency = useMemo(
    () => _.get(searchResultConfig, "frequency", 2),
    [searchResultConfig]
  )

  const searchResultItems = useMemo(() => {
    const serializedVenueFilter =
      venueFilter === DEFAULT_VENUE_FILTER ? null : venueFilter
    //combine search result with Ads when searchValue isn't empty
    if (!_.isEmpty(searchValue)) {
      const results = search(searchValue, serializedVenueFilter).map(
        resultParser
      )
      return _(results)
        .chunk(sponsoredContentFrequency)
        .flatMap((chunk, index) =>
          sponsoredContentListItems[index]
            ? chunk.concat(sponsoredContentListItems[index])
            : chunk
        )
        .value()
    }
    return getSuggestedSearchItems(serializedVenueFilter).map(resultParser)
  }, [
    searchValue,
    search,
    getSuggestedSearchItems,
    resultParser,
    venueFilter,
    sponsoredContentListItems,
    sponsoredContentFrequency,
  ])

  // Events
  const onChange = useCallback(
    (event) => {
      const newValue = event.target.value
      if (searchInputRef.current)
        (searchInputRef.current as HTMLInputElement).value = newValue
      debounceSearchOnChange(newValue)
    },
    [debounceSearchOnChange]
  )

  const onChangeKeyboard = useCallback(
    (value) => {
      if (searchInputRef.current)
        (searchInputRef.current as HTMLInputElement).value = value
      debounceSearchOnChange(value)
    },
    [debounceSearchOnChange]
  )

  const clearSearchValue = useCallback(() => {
    if (searchInputRef.current)
      (searchInputRef.current as HTMLInputElement).value = ""
    if (keyboardRef.current) (keyboardRef.current as any).clearInput()
    setSearchValue("")
  }, [setSearchValue])

  const onClickClearSearchValue = useCallback(() => {
    gtmPushDataLayerSearchKeyword(searchValue, GTM_NO_RESULT_VALUE)
    clearSearchValue()
  }, [clearSearchValue, searchValue])

  // Filter occupants with an available anchor
  const availableOccupants = useMemo(() => {
    return venueOccupant.filter(({ properties }) => _.get(properties, "anchor"))
  }, [venueOccupant])

  const parentCategoryWithOccupant = useMemo(
    () =>
      _(availableOccupants)
        .map("properties.local_parent_categories")
        .compact()
        .flatten()
        .uniqBy("id")
        .value(),
    [availableOccupants]
  )
  const sortedParentCategoryWithOccupant = useMemo(
    () =>
      _.orderBy(parentCategoryWithOccupant, [
        "properties.is_featured",
        "properties.order",
        ["desc", "asc"],
      ]),
    [parentCategoryWithOccupant]
  )

  const reset = useCallback(() => {
    clearSearchValue()
    closeSearch()
  }, [closeSearch, clearSearchValue])

  const onClose = useCallback(() => {
    gtmPushDataLayerSearchKeyword(searchValue, GTM_NO_RESULT_VALUE)

    reset()
  }, [searchValue, reset])

  const onSelectResult = useCallback(
    (feature) => {
      if (feature) {
        gtmPushDataLayerSearchKeyword(
          searchValue,
          _.get(feature, "properties.name.en")
        )
      }
      reset()
      navigate(`/maps/place/${feature.id}`)
    },
    [navigate, reset, searchValue]
  )

  const onClickCategory = useCallback(
    (category) => {
      const categoryName = category?.properties?.name?.en
      if (searchInputRef.current)
        (searchInputRef.current as HTMLInputElement).value = categoryName
      gtmPushDataLayerCategoryQuickSearch(categoryName)
      setSearchValue(categoryName)
    },
    [setSearchValue]
  )

  const onClickSuggestedItem = useCallback(
    (_splide, { slide }, _event) => {
      reset()
      navigate(slide.getAttribute("link"))
    },
    [navigate, reset]
  )

  // Effect
  useEffect(() => {
    window.addEventListener("appreset", () => {
      reset()
    })
  }, [reset])

  useEffect(() => {
    setVenueFilter(initialVenueFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSearchState])

  return {
    // Input ref
    searchInputRef,
    keyboardRef,
    // States
    searchResultItems,
    suggestedListItems: suggestedBrands,
    searchValue,
    openSearchState,
    showOnscreenKeyboard,
    venueFilter,
    sortedParentCategoryWithOccupant,
    // Functions
    openSearch,
    setSearchValue,
    onSelectResult,
    onChange,
    onChangeKeyboard,
    onClickCategory,
    reset,
    onClose,
    clearSearchValue,
    onClickClearSearchValue,
    setVenueFilter,
    onClickSuggestedItem,
  }
}
