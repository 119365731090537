import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import _ from "lodash"

import {
  get as getPersistValue,
  set as setPersistValue,
} from "utils/persist_store"
import config from "config"
import dayjs from "config/dayjs"

import { useSponsoredContent } from "./useSponsoredContent"
import { IRecord } from "interfaces"

interface ISponsorContentConfig {
  disable?: boolean
  limit?: number
  frequency?: number
}

interface IUsePopupBannerResult {
  popupBanners: IRecord[]
  popupBannerConfig: ISponsorContentConfig
  popupOpen: boolean
  navigateToSponsoredLink: (link: string) => void
  closePopup: () => void
}

const POPUP_LAST_OPEN_TIME = "popupLastOpenTime"

export const useSponsoredPopupBanner = (): IUsePopupBannerResult => {
  const { popupBanner, navigateToSponsoredLink } = useSponsoredContent()
  const timer = useRef(null)
  const [popupOpen, setPopupOpen] = useState(false)
  const popupBannerConfig = useMemo(
    () => _.get(popupBanner, "config"),
    [popupBanner]
  )

  const isKioskMode = useMemo(
    () => ["kiosk-v", "kiosk-h"].includes(config("device")),
    []
  )

  const isPopupDisable = useMemo(
    () => _.get(popupBanner, "config.disable", false),
    [popupBanner]
  )

  // Popup banner
  const popupBanners = useMemo(
    () => _.get(popupBanner, "data", []),
    [popupBanner]
  )

  const openPopup = useCallback(() => {
    setPopupOpen(true)
    setPersistValue(POPUP_LAST_OPEN_TIME, new Date().toString())
  }, [])

  const setUpTimer = useCallback(() => {
    const popupBannerfrequency = _.get(popupBanner, "config.frequency", 1)
    if (popupBanners.length > 0 && !isPopupDisable && !isKioskMode) {
      const currentTime = dayjs()
      const lastOpenPopupTime = dayjs(getPersistValue(POPUP_LAST_OPEN_TIME))
      const nextTimeToOpenPopup = lastOpenPopupTime.isValid()
        ? lastOpenPopupTime.add(popupBannerfrequency, "hour")
        : currentTime
      const remainingTime = !currentTime.isSameOrAfter(nextTimeToOpenPopup)
        ? nextTimeToOpenPopup.diff(currentTime)
        : 0
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(openPopup, remainingTime)
    }
  }, [openPopup, popupBanners, popupBanner, isPopupDisable, isKioskMode])

  const closePopup = useCallback(() => {
    setPopupOpen(false)
    setUpTimer()
  }, [setUpTimer])

  useEffect(() => {
    setUpTimer()
  }, [setUpTimer])

  return {
    popupOpen,
    popupBanners,
    popupBannerConfig,
    closePopup,
    navigateToSponsoredLink,
  }
}
