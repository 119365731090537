import _ from "lodash"
import { useQuery } from "@tanstack/react-query"

import { IUseGetListProps, QueryFnData } from "../../client-query/types"
import { DEFAULT_PAGINATION, DEFAULT_SORT } from "../../client-query/constants"
import { DEFAULT_FILTER_OPTIONS } from "constant"
import { OccupantData } from "providers/venue/types"

import { useGetImdf } from "../useGetImdf"

import { createGetListQueryFn } from "../../client-query/factory"
import { createGetListQueryKey } from "../../client-query/factory/createGetListQueryKey"

type TQueryFnData = QueryFnData<OccupantData>

const DEFAULT_QUERY_KEY = ["occupants"]

export const useGetOccupants = ({
  pagination = DEFAULT_PAGINATION,
  sort = DEFAULT_SORT,
  filter = {},
  filterOptions = DEFAULT_FILTER_OPTIONS,
  queryKey = DEFAULT_QUERY_KEY,
  ...props
}: IUseGetListProps<OccupantData, TQueryFnData, OccupantData[]>) => {
  const { data: imdfData } = useGetImdf()
  const sourceData = _.get(imdfData, "occupants", [])

  return useQuery<TQueryFnData, unknown, OccupantData[]>({
    queryKey: createGetListQueryKey(queryKey, {
      filter,
      sort,
    }),
    queryFn: createGetListQueryFn<OccupantData, TQueryFnData>(sourceData, {
      pagination,
      sort,
      filter,
      filterOptions,
    }),
    refetchOnWindowFocus: false,
    select: ({ data }) => data,
    gcTime: 1000 * 30 * 5,
    ...props,
  })
}
