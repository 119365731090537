import React, { useCallback, useMemo } from "react"
import {
  Box,
  Button,
  CardMedia,
  CardContent,
  Typography,
  useTheme,
  List,
  ListItem,
  ListItemText,
  Avatar,
} from "@mui/material"
import { PageCard } from "components/card"
import { QRCode } from "components/QRCode"
import { CustomIcon, ShopIcon } from "components/icons"

import { useTranslation } from "providers/i18n"
import { useFeatureDetail } from "hooks/feature/useFeatureDetail"
import { Link, useNavigate } from "react-router-dom"
import { NavigationControl } from "components/kioskHorizontal/navigation"
import { Image } from "components/Image"
import { usePlaceDetailMap } from "hooks/place/usePlaceDetailMap"

export const PlaceDetailKioskHPage = ({ Layout = Box }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const theme = useTheme()
  const styleOverrides = theme.components[`AppDetailPage`]?.styleOverrides || {}
  const sideBarStyleOverrides =
    theme.components[`AppSidebar`]?.styleOverrides || {}
  const {
    root,
    mediaCover,
    titleContainer,
    titleActionContainer,
    headerContainer,
    contentContainer,
    descriptionContainer,
    descriptionText,
    locationContainer,
    locationDetail,
    actionContainer,
    buildingLogo,
    titleText,
    captionText,
    stretchButton,
    avatarRoot,
  } = styleOverrides
  const { wrapper, footer, body } = sideBarStyleOverrides
  const { dataLoaded, ...data } = useFeatureDetail()
  const {
    logo,
    category,
    level,
    name,
    hours,
    id,
    coverImage,
    venueLogo,
    description,
    promotions = [],
    placeDetailMobileUrl,
    categoryIconProp = {},
    feature,
  } = data
  usePlaceDetailMap(feature)

  const levelName = useMemo(
    () => (level ? `${t(level)} ${t("Floor")}` : "-"),
    [t, level]
  )
  const { iconPath, image } = categoryIconProp

  const showPromotionButton = promotions && promotions.length > 0

  const onNavigate = useCallback(() => {
    navigate(`/maps/dir/unknown/${id}`, {
      state: {
        prevDestinationId: id,
      },
    })
  }, [navigate, id])

  return (
    <Layout
      aside={
        <PageCard>
          <Box sx={wrapper}>
            <Box sx={body}>
              <Box sx={root}>
                <CardMedia
                  sx={mediaCover}
                  className={`${!coverImage ? "no-filter" : ""}`}
                  image={coverImage}
                  title={t(name)}
                />
                <CardContent sx={contentContainer}>
                  <Box sx={headerContainer}>
                    <Avatar src={logo || image} sx={avatarRoot} variant="logo">
                      {iconPath ? (
                        <CustomIcon path={iconPath} fontSize="inherit" />
                      ) : (
                        <ShopIcon fontSize="inherit" />
                      )}
                    </Avatar>
                    <Box sx={titleContainer}>
                      <Typography variant="h5" sx={titleText}>
                        {t(name)}
                      </Typography>
                      <Typography varaint="h6" sx={captionText}>
                        {t(category)}
                      </Typography>
                    </Box>
                    <Box sx={titleActionContainer}>
                      {showPromotionButton && (
                        <Button
                          LinkComponent={Link}
                          to={`/promotions/occupant/${id}`}
                          variant="outlined"
                          size="small"
                        >
                          {t("Promotions")}
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box sx={descriptionContainer}>
                    <Typography sx={descriptionText} varaint="body1">
                      {t(description)}
                    </Typography>
                  </Box>
                  <Box sx={locationContainer}>
                    <Box>
                      <QRCode
                        url={placeDetailMobileUrl}
                        caption={t("Send to Mobile")}
                      />
                    </Box>
                    <List disablePadding sx={locationDetail}>
                      <ListItem>
                        <ListItemText
                          primary={t("Location")}
                          secondary={levelName}
                        />
                        <Box sx={buildingLogo}>
                          {venueLogo && <Image src={venueLogo} />}
                        </Box>
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={t("Operation Time")}
                          secondary={hours || "-"}
                        />
                      </ListItem>
                    </List>
                  </Box>
                  <Box sx={actionContainer}>
                    <Button
                      sx={stretchButton}
                      onClick={onNavigate}
                      variant="contained"
                      size="medium"
                    >
                      {t("Directions")}
                    </Button>
                  </Box>
                </CardContent>
              </Box>
            </Box>
            <Box sx={footer}>
              <NavigationControl />
            </Box>
          </Box>
        </PageCard>
      }
    />
  )
}
