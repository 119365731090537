import { ReactNode } from "react"
import { get } from "lodash"

import * as icons from "components/icons"
import { SxProps } from "@mui/material"

export interface ConfigIcon {
  icon_comp?: string
  icon_path?: string
  image?: string
}
export type GetIcon = (config: ConfigIcon, sx?: SxProps) => ReactNode | null

export const getIcon: GetIcon = (config = {}, sx = {}) => {
  const { icon_comp = "", icon_path = null, image = null } = config
  const IconComp = get(icons, icon_comp)

  if (IconComp) return <IconComp sx={sx} />

  if (icon_path) return <icons.CustomIcon path={icon_path} sx={sx} />

  if (image) return <icons.CustomImageIcon src={image} sx={sx} />

  return null
}
