const REGEX_PATTERNS = {
  latlng: /^(-?\d+\.\d+),(-?\d+\.\d+),(-?\d+)+o$/,
}

type IsLatLngQueryString = (path: string | undefined) => boolean

type CreateGeolocationFeature = (
  path: string | null,
  properties: object
) => object | null

export const isLatLngQueryString: IsLatLngQueryString = (path) =>
  REGEX_PATTERNS["latlng"].test(path)

export const createGeolocationFeature: CreateGeolocationFeature = (
  path,
  properties = {}
) => {
  try {
    const [id, latitude, longtitude, ordinal] = path.match(
      REGEX_PATTERNS["latlng"]
    )
    return {
      type: "Feature",
      feature_type: "geolocation",
      id,
      geometry: {
        type: "Point",
        coordinates: [parseFloat(latitude), parseFloat(longtitude)],
      },
      properties: {
        ordinal: parseInt(ordinal),
        userLocationParam: path,
        queryString: `?user-location=${path}`,
        ...properties,
      },
    }
  } catch (error) {
    return null
  }
}
