import { Box, CardMedia, Stack, useTheme } from "@mui/material"
import { CompareArrowsIcon } from "components/icons"

export const SwitchVenueStep = ({
  originVenueLogo,
  destinationVenueLogo,
  acrossVenueStepicon = <CompareArrowsIcon />,
  ...props
}) => {
  const theme = useTheme()
  const styleOverrides =
    theme.components[`SwitchVenueStep`]?.styleOverrides || {}
  const { venueLogo, iconContainer, root } = styleOverrides
  return (
    <Stack sx={root} {...props}>
      <CardMedia component="img" image={originVenueLogo} sx={venueLogo} />
      <Box sx={iconContainer}>{acrossVenueStepicon}</Box>
      <CardMedia component="img" image={destinationVenueLogo} sx={venueLogo} />
    </Stack>
  )
}
