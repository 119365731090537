import { IconButton } from "@mui/material"
import { CloseIcon } from "../icons"

export const CloseIconButton = ({
  icon = <CloseIcon fontSize="inherit" />,
  ...props
}) => {
  return (
    <IconButton aria-label="close" color="inherit" size="small" {...props}>
      {icon}
    </IconButton>
  )
}
