import _ from "lodash"
import { IRecord } from "interfaces"
import { ISponsoredContent } from "providers/venue/types"

import {
  createVenueLink,
  parseEntries,
} from "providers/venue/utils/prepareEntry"
import { getFeatureDetail } from "utils/feature"
import { createCardMediaPropsFromFileField } from "utils/props"

export const prepareSponsoredContent = (
  entries,
  sponsoredContentConfig = {},
  findOne
): ISponsoredContent => {
  const initialData = {
    searchResult: {
      data: [],
      config: _.get(sponsoredContentConfig, "searchResult", {}),
    },
    suggestedBrand: {
      data: [],
      config: _.get(sponsoredContentConfig, "suggestedBrand", {}),
    },
    popupBanner: {
      data: [],
      config: _.get(sponsoredContentConfig, "popupBanner", {}),
    },
    screensaver: {
      data: [],
      config: _.get(sponsoredContentConfig, "screensaver", {}),
    },
    footerBanner: {
      data: [],
      config: _.get(sponsoredContentConfig, "footerBanner", {}),
    },
    recommendedEvent: {
      data: [],
      config: _.get(sponsoredContentConfig, "recommendedEvent", {}),
    },
    recommendedPromotion: {
      data: [],
      config: _.get(sponsoredContentConfig, "recommendedPromotion", {}),
    },
  }

  // Group and normalize the data
  const parsedEntries = parseEntries(_.get(entries, "data"))

  const preparedData = _(parsedEntries)
    .orderBy(["order"], ["asc"])
    .reduce((acc, entry) => {
      const camelCaseType = _.camelCase(_.get(entry, "type"))
      const limit = _.get(
        sponsoredContentConfig,
        `${camelCaseType}.limit`,
        Infinity
      )
      const isDisable = _.get(
        sponsoredContentConfig,
        `${camelCaseType}.disable`,
        false
      )

      const featureId = _.get(entry, "feature_id")
      const matchedFeature = findOne(featureId)
      const feature = getFeatureDetail(matchedFeature)

      const currentData = _.get(acc, `${camelCaseType}.data`) as IRecord[]

      if (isDisable) return acc

      if (_.isNil(currentData)) return acc

      if (_.isFinite(currentData) && currentData && currentData.length >= limit)
        return acc

      const mediaSrc =
        _.get(entry, `media.formats.medium.url`) || _.get(entry, `media.url`)
      const updatedEntry = {
        ...entry,
        type: camelCaseType,
        mediaSrc,
        feature,
        link: createVenueLink(featureId),
      }

      switch (camelCaseType) {
        case "suggestedBrand": {
          if (_.isNil(feature)) break
          acc[camelCaseType].data = [...currentData, updatedEntry]
          break
        }
        case "searchResult":
        case "recommendedEvent":
        case "recommendedPromotion": {
          if (_.isNil(feature)) break

          acc[camelCaseType].data = [
            ...currentData,
            {
              ...matchedFeature,
              properties: {
                ...matchedFeature.properties,
                isAd: true,
                sponsoredId: entry?.id,
              },
            },
          ]

          break
        }
        case "popupBanner":
          acc[camelCaseType].data = [...currentData, updatedEntry]
          break
        case "footerBanner":
        case "screensaver": {
          acc[camelCaseType].data = [
            ...currentData,
            {
              ...updatedEntry,
              props: createCardMediaPropsFromFileField(
                _.get(updatedEntry, "media")
              ),
            },
          ]
          break
        }
        default:
          break
      }
      return acc
    }, initialData)
  return preparedData
}
