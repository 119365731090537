import { Card, CardProps, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"

interface PageCardProps extends CardProps {}

export const PageCard: React.FC<PageCardProps> = ({
  children,
  sx,
  ...props
}) => {
  const theme = useTheme()
  const styleOverrides = theme.components[`AppPageCard`]?.styleOverrides || {}
  const { root } = styleOverrides

  return (
    <Card sx={deepmerge(root, sx)} {...props}>
      {children}
    </Card>
  )
}
