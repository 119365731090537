import { useMemo } from "react"
import { useTranslation } from "providers/i18n"
import {
  Typography,
  CardMedia,
  CardContent,
  Box,
  Stack,
  Card,
  useTheme,
} from "@mui/material"
import { deepmerge } from "@mui/utils"
import { getDurationDate } from "utils/date"
import { LocationIcon } from "components/icons"
import { getFeatureDetail } from "../../../utils/feature"
import { LocationInfo } from "../features/LocationInfo"

export const PromotionCard = ({ promotion, dense, sx }) => {
  const { t } = useTranslation()
  const { name, coverImage, venueLogo, startDate, endDate, hasLocation, isAd } =
    getFeatureDetail(promotion)

  const durationText = useMemo(
    () => getDurationDate(startDate, endDate),
    [startDate, endDate]
  )

  const theme = useTheme()
  const { styleOverrides } = theme.components?.[`PromotionCard`] || {}
  const {
    root,
    cardMedia,
    cardContent,
    venueLogoContainer,
    cardContentStack,
    cardTitle,
    cardInfoContainer,
    iconContainer,
    durationContainer,
    recommendText,
  } = styleOverrides
  return (
    <Card
      sx={deepmerge(root, sx)}
      className={dense ? "MuiCardContent-dense" : ""}
    >
      <CardMedia sx={cardMedia} image={coverImage}>
        {isAd && (
          <Typography variant="caption" component="p" sx={recommendText}>
            {t("Recommend")}
          </Typography>
        )}
      </CardMedia>
      <CardContent sx={cardContent}>
        <Stack spacing={1} sx={cardContentStack}>
          {venueLogo && (
            <Box sx={venueLogoContainer}>
              <CardMedia component="img" image={venueLogo} />
            </Box>
          )}
          <Box sx={durationContainer}>
            <Typography variant="subTitle" component="p">
              {durationText}
            </Typography>
          </Box>
          <Typography sx={cardTitle} variant="title" component="h6">
            {t(name)}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Box
            sx={{
              ...cardInfoContainer,
              visibility: hasLocation ? "visible" : "hidden",
            }}
          >
            <Box sx={iconContainer}>
              <LocationIcon />
            </Box>
            <LocationInfo
              feature={promotion}
              variant="subTitle"
              component="p"
            />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}
