import { useCallback, useEffect, useRef, useState } from "react"
import _ from "lodash"

import { useVenue } from "providers/venue"
import { useRealtime } from "providers/venue/modules/realtime/useRealtime"
import { INotification } from "providers/venue/modules/realtime/hooks/useNotificationSocketIO"

export interface IUseNotificationResult {
  activeNotification: IPreparedNotification
  historyNotifications: INotification[]
  open: boolean
  handleClose: () => void
}

export type UseNotification = (options?: {
  delayShowNotification?: number
}) => IUseNotificationResult

interface IPreparedNotification {
  title: string
  locationId?: string
  locationName?: {
    en: string
    [k: string]: string
  }
  image?: string
}

type PrepareNotificationData = (
  notificationData: INotification
) => IPreparedNotification

const DEFAULT_DELAY_DURATION = 6500 // 6.5s

export const useNotification: UseNotification = ({
  delayShowNotification = DEFAULT_DELAY_DURATION,
}) => {
  const totalQueue = useRef(0)
  const [open, setOpen] = useState(false)
  const { notification } = useRealtime()
  const { findFeatureById } = useVenue()
  const { newNotification, historyNotifications, moveNotificationToHistory } =
    notification
  const [activeNotification, setActiveNotification] =
    useState<IPreparedNotification>()

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const prepareNotificationData: PrepareNotificationData = useCallback(
    (notificationData) => {
      const locationId = _.get(notificationData, "extras.location.id")
      const locationName = _.get(findFeatureById(locationId), "properties.name")
      return {
        title: _.get(notificationData, "title"),
        locationId,
        locationName,
        image: _.get(notificationData, "image.url"),
      }
    },
    [findFeatureById]
  )

  useEffect(() => {
    if (newNotification) {
      totalQueue.current += 1
      setTimeout(() => {
        const preparedNotification = prepareNotificationData(newNotification)
        setActiveNotification(preparedNotification)
        moveNotificationToHistory(newNotification)
        setOpen(true)
        totalQueue.current -= 1
      }, delayShowNotification * totalQueue.current)
    }
  }, [
    newNotification,
    delayShowNotification,
    moveNotificationToHistory,
    prepareNotificationData,
  ])

  return {
    activeNotification,
    historyNotifications,
    open,
    handleClose,
  }
}
