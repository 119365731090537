import { Box, useTheme } from "@mui/material"
import React from "react"

export const Footer = () => {
  const theme = useTheme()
  const styleOverrides = theme.components[`AppFooter`]?.styleOverrides || {}
  const { root } = styleOverrides

  return <Box sx={root}></Box>
}
