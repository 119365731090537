import * as maptalks from "maptalks"
import { BaseObject } from "maptalks.three"
import * as THREE from "three"

const OPTIONS = {
  altitude: 0,
}

const DEFAULT_LINE_OPTION = {
  color: "#000",
  opacity: 1,
}
const DEFAULT_LINE_EFFECT_OPTION = {
  color: "#000",
  opacity: 1,
}

export class NavigationPath extends BaseObject {
  constructor(
    feature,
    layer,
    properties,
    options,
    lineOptions = DEFAULT_LINE_OPTION,
    outlineOption = DEFAULT_LINE_EFFECT_OPTION
  ) {
    options = maptalks.Util.extend({}, OPTIONS, options, {
      layer,
    })
    super()
    //Initialize internal configuration
    this._initOptions(options)
    const { altitude = OPTIONS.altitude } = options
    this.properties = { ...properties }
    this._createGroup()

    const { color: lineColor, opacity: lineOpacity } =
      lineOptions || DEFAULT_LINE_OPTION

    const material = new THREE.MeshBasicMaterial({
      transparent: true,
      color: lineColor || "#fff",
      opacity: lineOpacity >= 0 ? lineOpacity : 1,
      depthWrite: false,
    })

    const pathGeometry = maptalks.GeoJSON.toGeometry(feature)
    const line = layer.toPath(
      pathGeometry,
      {
        interactive: false,
        cornerRadius: 2,
        width: 0.5,
      },
      material
    )

    const { color: outlineColor, opacity: outlineOpacity } = outlineOption || {}
    const outlineMaterial = new THREE.MeshBasicMaterial({
      transparent: true,
      color: outlineColor || "#fff",
      opacity: outlineOpacity >= 0 ? outlineOpacity : 1,
    })

    const lineOutLine = layer.toPath(
      pathGeometry,
      {
        interactive: false,
        cornerRadius: 2,
        width: 1,
      },
      outlineMaterial
    )
    this.getObject3d().add(lineOutLine.getObject3d())
    this.getObject3d().add(line.getObject3d())
    // set object3d position
    const z = layer.altitudeToVector3(altitude + 0.25, altitude + 0.25).x
    const pos = this.getObject3d().position

    const position = layer.coordinateToVector3([pos.x, pos.y], z)
    // _.set(this.properties, "default.position", position)

    this.getObject3d().position.copy(position)
  }
}
