import { ReactElement, ReactNode } from "react"
import _ from "lodash"
import { ThemeOptions } from "@mui/material"
import { deepmerge } from "@mui/utils"
import { Route, RouteProps } from "react-router-dom"

enum RouteTypes {
  Map = "map",
  Page = "page",
  Base = "base",
}

interface IRouteConfig extends Omit<RouteProps, "children"> {
  key?: string
  type?: RouteTypes
  children?: IRouteConfig[]
}
interface IRouteGroups {
  mapRoutes: IRouteConfig[]
  pageRoutes: IRouteConfig[]
  baseRoutes: IRouteConfig[]
}

// The menu configuration utilized in the app's service configuration.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type MergeRoutes = (
  sourceRoutes: IRouteConfig[],
  extensionRoutes: IRouteConfig[]
) => IRouteGroups

type RegisterRouteByConfig = (
  routeConfig: IRouteConfig,
  index: number
) => ReactElement

type GroupRouteConfigByType = (routeConfig: IRouteConfig[]) => IRouteGroups

type MergeRouteConfig = (
  soruceRouteConfig: IRouteConfig[],
  extensionRouteConfig: IRouteConfig[]
) => IRouteConfig[]

type ThemeOptionFactory = (theme: ThemeOptions) => ThemeOptions

interface ThemeOptionFactories {
  mobile?: ThemeOptionFactory
  "kiosk-h"?: ThemeOptionFactory
  "kiosk-v"?: ThemeOptionFactory
}

interface ExtensionConfig {
  themeOptionFactories: ThemeOptionFactories
  ignoreAppThemeFactory?: boolean
  routes: IRouteConfig[]
}

type GetAllExtensionRoutes = (
  extensionList: string[],
  extensionConfigs: ExtensionConfig[] | object
) => IRouteConfig[]

export const getAllExtensionRoutes: GetAllExtensionRoutes = (
  extensionList = [],
  extensionConfigs = {},
  deviceConfig = "mobile"
) => {
  if (_.isEmpty(extensionList)) return []

  return _(extensionList)
    .map((extensionName) => {
      // Retrieve rotue config by extension name and the device mode. (kiosk-v, kiosk-h, mobile)
      const routes = _.get(extensionConfigs, [
        extensionName,
        "routes",
        deviceConfig,
      ])
      if (_.isNil(routes) || _.isEmpty(routes)) return null
      return routes
    })
    .compact()
    .flatten()
    .value()
}

export const getExtensionThemeFactories = (
  extensionList = [],
  extensionConfigs = {},
  deviceConfig = "mobile"
) => {
  const result = { themeFactories: [], ignoreAppThemeFactory: false }

  if (_.isEmpty(extensionList)) return result

  let ignoreAppThemeFactory = false

  const themeFactories = _(extensionList)
    .map((extensionName) => {
      const {
        themeOptionFactories,
        ignoreAppThemeFactory: shouldIgnoreAppThemeFactory,
      } = _.get(extensionConfigs, extensionName, {
        ignoreAppThemeFactory: false,
        themeOptionFactories: {},
      })
      const themeOptionFactory = _.get(themeOptionFactories, deviceConfig)
      if (shouldIgnoreAppThemeFactory)
        ignoreAppThemeFactory = shouldIgnoreAppThemeFactory

      if (_.isNil(themeOptionFactory) || !_.isFunction(themeOptionFactory))
        return null
      return themeOptionFactory
    })
    .compact()
    .value()

  return { themeFactories, ignoreAppThemeFactory }
}

const groupRouteConfigByType: GroupRouteConfigByType = (routeConfigs) => {
  return _.reduce(
    routeConfigs,
    (acc, { type = RouteTypes.Page, ...newRouteConfig }, key) => {
      const routeTypeKey = `${type}Routes`
      const prevRouteConfig = _.get(acc, routeTypeKey)
      if (prevRouteConfig)
        _.set(acc, `${type}Routes`, [...prevRouteConfig, newRouteConfig])
      return acc
    },
    { mapRoutes: [], pageRoutes: [], baseRoutes: [] }
  )
}

const parseRouteConfigToPathObj = (routeConfigs) =>
  _.keyBy(routeConfigs, "path")

// Convert Route Config Array -> Path Object (with 'children' property)
const parseRouteConfigsToPathObjs = (routeConfigs) => {
  const transformedChildren = routeConfigs.map(
    ({ children: childrenProp, ...rest }) => {
      return {
        ...rest,
        children: childrenProp
          ? parseRouteConfigsToPathObjs(childrenProp)
          : undefined,
      }
    }
  )

  return parseRouteConfigToPathObj(transformedChildren)
}

const parsePathObjToRouteConfig = (pathObj) => _.values(pathObj)

// Convert Path Object -> Route Config Array (with 'children' property)
const parsePathObjsToRouteConfigs = (pathObjs) => {
  return parsePathObjToRouteConfig(pathObjs).map(
    ({ children: childrenProp, ...rest }) => {
      return {
        ...rest,
        children: childrenProp
          ? parsePathObjsToRouteConfigs(childrenProp)
          : undefined,
      }
    }
  )
}

const mergeRouteConfigByPath: MergeRouteConfig = (
  sourceRouteConfig = [],
  extensionRouteConfig = []
) => {
  // Step 1: Convert route config to path object with nested routes in 'children' prop.
  const sourcePathObjs = parseRouteConfigsToPathObjs(sourceRouteConfig)
  const extensionPathObjs = parseRouteConfigsToPathObjs(extensionRouteConfig)

  // Step 2: Merge path objects.
  const mergedPathObjs = deepmerge(sourcePathObjs, extensionPathObjs)

  // Step 3: Convert merged path objects to route config array with nested routes in 'children' prop.
  return parsePathObjsToRouteConfigs(mergedPathObjs)
}

export const mergeRoutes: MergeRoutes = (
  sourceRoutes = [],
  extensionRoutes = []
) => {
  // # Step 1: Grouping `extension` route configurations by route type (map or page)
  const {
    mapRoutes: extensionMapRoutes,
    pageRoutes: extensionPageRoutes,
    baseRoutes: extensionBaseRoutes,
  } = groupRouteConfigByType(extensionRoutes)

  // # Step 2: Grouping `source` route configurations by route type (map or page)
  const {
    mapRoutes: sourceMapRoutes,
    pageRoutes: sourcePageRoutes,
    baseRoutes: sourceBaseRoutes,
  } = groupRouteConfigByType(sourceRoutes)

  // # Step 3: Override the 'source' route configurations with the 'extension' route configurations and then return the grouped route object.
  return {
    mapRoutes: mergeRouteConfigByPath(sourceMapRoutes, extensionMapRoutes),
    pageRoutes: mergeRouteConfigByPath(sourcePageRoutes, extensionPageRoutes),
    baseRoutes: mergeRouteConfigByPath(sourceBaseRoutes, extensionBaseRoutes),
  }
}

export const registerRouteByConfig: RegisterRouteByConfig = (
  { children, key: keyProp, path, ...props },
  index
) => {
  const key = keyProp || `extension-route-${path || index}`
  return (
    <Route
      {...props}
      path={path}
      key={key}
      children={
        (children
          ? (children as IRouteConfig[]).map(registerRouteByConfig)
          : children) as ReactNode
      }
    />
  )
}
