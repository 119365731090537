import { Fragment, useCallback, useMemo } from "react"
import { Link } from "react-router-dom"
import { Box, useTheme } from "@mui/material"

import { CategoryFilterBar } from "components/CategoryFilterBar"

import { getIcon } from "utils/icon"

import { SKELETON_COUNT, SKELETON_INDEX_TO_REF } from "constant"
import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import { useDining } from "hooks/dining/useDining"

import { AppNavigation } from "containers/kiosk-v"
import { Head } from "components/Head"
import { SearchToggleButton } from "components/domains/search"
import { HorizontalScrollPane } from "components/HorizontalScrollPane"
import { DiningIcon } from "components/icons"
import { EmptyData } from "components/EmptyData"
import { VenueSelector } from "components/VenueSelector"
import { MotionDiv, showContent } from "components/motions"
import { DiningCard } from "../../components/domains/dining/DiningCard"
import { FooterAction } from "extensions/one-siam/components/domains/navigation/FooterAction"
import { SearchIcon } from "extensions/one-siam/components/icons"
import { DiningSkeletonCard } from "extensions/one-siam/components/domains/dining"

export const DiningKioskVPage = ({ Layout = Box }) => {
  const { occupants } = useVenue()
  const {
    restaurants,
    currentCategory,
    categories,
    isMultiVenueProject,
    locationVenueId,
    venues,
    venueFilter,
    hasData,
    hasNextPage,
    endScrollRef,
    filterByCategoryId,
    filterByVenueId,
  } = useDining(occupants)

  const { t } = useTranslation({ defaultValue: null })

  const handleChangeCategory = useCallback(
    (e, id) => {
      if (id === null) return
      filterByCategoryId(id)
    },
    [filterByCategoryId]
  )

  const theme = useTheme()
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.dining) || <DiningIcon />,
    [theme]
  )

  const { styleOverrides = {} } = theme.components?.[`AppDining`] || {}
  const {
    root,
    filterSection,
    filterContainer,
    categoryFilterBar,
    gridContainer,
    gridItem,
    emptyDataContainer,
    navigationWrapper,
    actionContainer,
    searchBarContainer,
    toolbarContainer,
    mainMenuContainer,
    footerActionContainer,
  } = styleOverrides

  return (
    <>
      <Head title={t("Dining")} />
      <Layout>
        <Box sx={root}>
          {hasData ? (
            <MotionDiv
              variants={showContent}
              initial="initial"
              animate="animate"
              sx={gridContainer}
            >
              {restaurants.pages.map((page, pageNum) => {
                return (
                  <Fragment key={pageNum}>
                    {page.data.map((restaurant, index) => (
                      <DiningCard
                        key={`dining-${index}`}
                        sx={gridItem}
                        data={restaurant}
                      />
                    ))}
                  </Fragment>
                )
              })}
              {hasNextPage &&
                Array.from({ length: SKELETON_COUNT }).map((_, index) => (
                  <DiningSkeletonCard
                    ref={index === SKELETON_INDEX_TO_REF ? endScrollRef : null}
                    key={index}
                    sx={gridItem}
                  />
                ))}
            </MotionDiv>
          ) : (
            <MotionDiv
              variants={showContent}
              initial="initial"
              animate="animate"
              sx={emptyDataContainer}
            >
              <EmptyData icon={emptyIcon} primary={t("Coming soon")} />
            </MotionDiv>
          )}
          {categories.length > 0 && (
            <Box sx={filterSection}>
              <HorizontalScrollPane
                className="subCategory-filterbar"
                stackProps={{
                  sx: filterContainer,
                }}
              >
                <CategoryFilterBar
                  sx={categoryFilterBar}
                  activeCategory={currentCategory}
                  categories={categories}
                  onChange={handleChangeCategory}
                />
              </HorizontalScrollPane>
            </Box>
          )}
          <Box key="app-navigation" sx={navigationWrapper}>
            <Box sx={toolbarContainer}>
              <Box sx={actionContainer}>
                {isMultiVenueProject && (
                  <VenueSelector
                    selectedVenue={venueFilter}
                    venues={venues}
                    onClickVenue={filterByVenueId}
                    currentVenue={locationVenueId}
                  />
                )}
              </Box>
              <Box sx={searchBarContainer}>
                <SearchToggleButton
                  icon={SearchIcon}
                  placeholder={t("Search shops and more...")}
                  LinkComponent={Link}
                  to="/search"
                />
              </Box>
            </Box>
            <Box sx={mainMenuContainer}>
              <AppNavigation />
              <FooterAction sx={footerActionContainer} />
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  )
}
