interface ICoordinate {
  x: number
  y: number
  z?: number
}

type RadToDegree = (rad: number) => number

type GetBearingBetweenPoints = (
  origin: ICoordinate,
  destination: ICoordinate
) => number

type GetSuitablyValueBetweenBearings = (
  newBearing: number,
  currentBearing: number
) => number

const radToDegree: RadToDegree = (rad) => rad * (180 / Math.PI)

export const getBearingBetweenPoints: GetBearingBetweenPoints = (
  origin,
  destination
) => {
  const twoPI = Math.PI * 2

  if (origin.x === destination.x && origin.y === destination.y) return
  let theta = Math.atan2(destination.x - origin.x, destination.y - origin.y)

  if (theta < 0.0) theta += twoPI

  return Math.floor(radToDegree(theta))
}

export const getSuitablyValueBetweenBearings: GetSuitablyValueBetweenBearings =
  (newBearing, currentBearing) => {
    return Math.abs(newBearing - currentBearing) > 180
      ? newBearing - 360
      : newBearing
  }
