import { useMemo } from "react"
import _ from "lodash"
import { EXTENSION_NAME } from "../constant"

import { useExtension } from "hooks/extension/useExtension"
import { useVenue } from "providers/venue"

export interface ILocale {
  en: string
  [k: string]: string
}

export interface ILocation {
  id: string
  name: ILocale
}
export interface IServiceConfig {
  name: ILocale
  description: ILocale
  icon_path?: string
  cover_image?: string
  locations?: ILocation[]
}
interface IServiceResult {
  hasData: boolean
  services: IServiceConfig[]
  evenColServices: IServiceConfig[]
  oddColServices: IServiceConfig[]
}

type UseService = () => IServiceResult

export const useService: UseService = () => {
  const { defaultVenue } = useVenue()
  const { extensionConfig } = useExtension()

  // Get services based on default venue reference setting.
  const services = useMemo(() => {
    const referenceVenue = defaultVenue?.properties?.reference
    return _.get(
      extensionConfig,
      `${EXTENSION_NAME}.options.services.${referenceVenue}`,
      []
    ) as IServiceConfig[]
  }, [extensionConfig, defaultVenue])

  const [evenColServices, oddColServices] = useMemo(() => {
    return services.reduce(
      (accumulator, currentValue, index) => {
        accumulator[index % 2].push(currentValue)
        return accumulator
      },
      [[], []]
    )
  }, [services])

  const hasData = _.size(services) > 0
  return {
    services,
    evenColServices,
    oddColServices,
    hasData,
  }
}
