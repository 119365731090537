import React from "react"
import { Carousel } from "components/Carousel"
import { useTheme } from "@mui/material"

export const HorizontalSlide = ({ children }) => {
  const theme = useTheme()

  return (
    <Carousel
      options={{
        pagination: false,
        gap: theme.spacing(2),
        fixedWidth: 183,
        mediaQuery: "min",
      }}
    >
      {children}
    </Carousel>
  )
}
