import React from "react"
import { Box, Divider, useTheme, Typography, Button } from "@mui/material"
import { Link, useParams } from "react-router-dom"

import { useTranslation } from "providers/i18n"
import { useEventDetail } from "hooks/event/useEventDetail"

import { PageCard, PageCardContent } from "components/card"
import { AppNavigation } from "containers/kiosk-h/AppNavigation"
import { NavigationControl } from "components/kioskHorizontal/navigation"
import { QRCode } from "components/QRCode"
import { Image } from "components/Image"
import { MotionDiv } from "components/motions"
import { VerticalEventCard } from "components/domains/events"

export const EventDetailKioskHPage = ({ Layout = Box }) => {
  const params = useParams()
  const { eventId } = params

  const { t } = useTranslation({
    defaultValue: null,
  })
  const { event, navigateToLocation, eventDetailMobileUrl, moreEvents } =
    useEventDetail(eventId)

  const theme = useTheme()

  const styleOverrides =
    theme.components[`EventDetailPage`]?.styleOverrides || {}
  const {
    pageCardContent,
    divider,
    mainWrapper,
    secondaryWrapper,
    promotionImage,
    contentContainer,
    venueLogo,
    locationButton,
    headerContainer,
    titleContainer,
    titleText,
    descriptionContainer,
    descriptionText,
    locationContainer,
    infoContainer,
    timeContainer,
    infoLabel,
    infoText,
    infoDivider,
    qrCode,
    gridContainer,
  } = styleOverrides

  const { styleOverrides: bannerStyleOverrides = {} } =
    theme.components[`AppBanner`] || {}
  const { root } = bannerStyleOverrides

  const sideBarStyleOverrides =
    theme.components[`AppSidebar`]?.styleOverrides || {}
  const { wrapper, body, footer } = sideBarStyleOverrides

  if (!event) return null

  return (
    <Layout
      aside={
        <PageCard>
          <Box sx={wrapper}>
            <Box sx={root}></Box>
            <Box sx={body}>
              <AppNavigation />
            </Box>
            <Box sx={footer}>
              <NavigationControl />
            </Box>
          </Box>
        </PageCard>
      }
    >
      <PageCard>
        <PageCardContent sx={pageCardContent}>
          <MotionDiv
            /* sx={contentContainer} */
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.25 }}
          >
            <Box sx={mainWrapper}>
              <Image sx={promotionImage} src={event.coverImage} />
              <Box sx={contentContainer}>
                <Box sx={headerContainer}>
                  {event.venueLogo && (
                    <Image sx={venueLogo} src={event.venueLogo} />
                  )}
                  <Box sx={titleContainer}>
                    <Typography variant="h5" sx={titleText}>
                      {t(event.name)}
                    </Typography>
                    {event.hasLocation && (
                      <Button
                        sx={locationButton}
                        variant="contained"
                        onClick={navigateToLocation}
                      >
                        {t("View Location")}
                      </Button>
                    )}
                  </Box>
                </Box>
                <Box sx={descriptionContainer}>
                  <Typography
                    sx={descriptionText}
                    varaint="body1"
                    dangerouslySetInnerHTML={{
                      __html: event.descriptionText,
                    }}
                  />
                </Box>
                <Box sx={infoContainer}>
                  <Box sx={locationContainer}>
                    <Typography sx={infoLabel}>{t("Location")}</Typography>
                    <Typography sx={infoText}>{t(event.location)}</Typography>
                  </Box>
                  <Divider orientation="vertical" sx={infoDivider} />
                  <Box sx={timeContainer}>
                    <Typography sx={infoLabel}>{t("Open")}</Typography>
                    <Typography sx={infoText}>
                      {t(event.openingText)}
                    </Typography>
                  </Box>
                  <QRCode
                    sx={qrCode}
                    url={eventDetailMobileUrl}
                    caption={t("Send to Mobile")}
                  />
                </Box>
              </Box>
            </Box>
            <Divider sx={divider} />
            <Box sx={secondaryWrapper}>
              <Box sx={gridContainer}>
                {moreEvents.length > 0 &&
                  moreEvents.map((moreEvent, index) => (
                    <Link
                      to={`/events/${moreEvent.id}`}
                      replace={true}
                      key={moreEvent.id}
                    >
                      <VerticalEventCard event={moreEvent} />
                    </Link>
                  ))}
              </Box>
            </Box>
          </MotionDiv>
        </PageCardContent>
      </PageCard>
    </Layout>
  )
}
