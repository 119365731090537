import { AppBar, Box, Divider, Toolbar, useTheme } from "@mui/material"

export const Header = ({
  logo = null,
  title = null,
  backButton = null,
  clock = null,
}) => {
  const theme = useTheme()
  const styleOverrides = theme.components[`AppHeader`]?.styleOverrides || {}
  const {
    root,
    toolbar,
    backButtonContainer,
    logoContainer,
    titleContainer,
    line,
  } = styleOverrides

  return (
    <AppBar component="nav" sx={root}>
      <Toolbar sx={toolbar}>
        <Box sx={backButtonContainer}>{backButton}</Box>
        <Box sx={logoContainer}>{logo}</Box>
        <Box sx={titleContainer}>{title}</Box>
      </Toolbar>
      <Divider sx={line} />
    </AppBar>
  )
}
