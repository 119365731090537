import { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import {
  get as getPersistValue,
  remove as removePersistValue,
} from "utils/persist_store"
import { Q_VENUE } from "constant"

interface IUseVenueControllerResult {
  viewingVenue: string
  changeViewingVenue: (id: string) => void
  initVenueController: () => void
  resetViewingVenue: () => void
}

type UseVenueController = (initialVenueId?: string) => IUseVenueControllerResult

export const useVenueController: UseVenueController = (initialVenueId) => {
  const [searchParams] = useSearchParams()
  const [viewingVenue, setViewingVenue] = useState<string | null>()

  const changeViewingVenue = useCallback(
    (id: string) => {
      setViewingVenue(id)
    },
    [setViewingVenue]
  )

  const initVenueController = useCallback(() => {
    setViewingVenue(null)
    removePersistValue(Q_VENUE)
  }, [setViewingVenue])

  const resetViewingVenue = useCallback(() => {
    setViewingVenue(initialVenueId)
    removePersistValue(Q_VENUE)
  }, [initialVenueId])

  useEffect(() => {
    const qVenue = searchParams.get(Q_VENUE)
    if (qVenue) changeViewingVenue(qVenue)
  }, [searchParams, changeViewingVenue])

  useEffect(() => {
    setViewingVenue(getPersistValue(Q_VENUE) || initialVenueId || null)
  }, [initialVenueId])

  return {
    viewingVenue,
    changeViewingVenue,
    initVenueController,
    resetViewingVenue,
  }
}
