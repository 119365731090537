import React from "react"
import { Box, Container, useTheme } from "@mui/material"

export const PageLayout = ({
  children,
  header = null,
  footer = null,
  ...props
}) => {
  const theme = useTheme()
  const styleOverrides = theme.components[`AppPageLayout`]?.styleOverrides || {}
  const { root, container, headerWrapper } = styleOverrides

  return (
    <Box sx={root} {...props} data-name="PageLayout">
      <Box sx={headerWrapper}>{header}</Box>
      <Container maxWidth="md" sx={container}>
        {children}
      </Container>
    </Box>
  )
}
