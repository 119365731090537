import { useCallback } from "react"
import { Box, Card, Typography, useTheme, Stack } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"

import { homeNavigations } from "standard"

import { useTranslation } from "providers/i18n"
import { useHome } from "../../hooks/home/useHome"
import { useHomeMap } from "hooks/home/useHomeMap"

import { FullScreenOverlayLayout } from "layouts/FullScreenOverlayLayout"

import { AppNavigation } from "containers/AppNavigation"

import { WelcomePanel } from "components/WelcomePanel"
import { Head } from "components/Head"
import { Carousel, CarouselItem } from "components/Carousel"
import { HighlightFeatureCard } from "components/domains/events"
import { FullScreenStart } from "components/domains/home"

export const HomePage = ({ navigations = homeNavigations }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { featuredEventPromotion, defaultItemsLinks, venues } = useHome()
  useHomeMap(venues)

  const {
    root,
    contentContainer,
    featureCarouselWrapper,
    welcomePanelWrapper,
    textCard,
    linkCardContainer,
    defaultCardPrimaryText,
    defaultCardSecondaryText,
    defaultCardIcon,
    navigationWrapper,
    carousel,
    carouselItem,
  } = theme.components[`HomePage`]?.styleOverrides || {}

  const handleClickNav = useCallback(
    (to) => {
      navigate(to)
    },
    [navigate]
  )

  return (
    <>
      <Head title={t("Indoor map")} />
      <FullScreenOverlayLayout sx={root}>
        <Box sx={contentContainer}>
          <FullScreenStart />
          <Box sx={welcomePanelWrapper}>
            <WelcomePanel
              navigations={navigations}
              onClickNav={handleClickNav}
            />
          </Box>
        </Box>
        <Box sx={featureCarouselWrapper}>
          <Carousel
            options={{
              gap: theme.spacing(1.25),
              pagination: false,
              focus: "center",
              arrows: false,
              mediaQuery: "min",
              breakpoints: {
                640: {
                  perPage: 2,
                },
                900: {
                  perPage: 3,
                },
              },
            }}
            sx={carousel}
          >
            {featuredEventPromotion.map((feature) => {
              const { feature_type, id } = feature
              return (
                <CarouselItem key={id} sx={carouselItem}>
                  {/*
                   * @Example: Event => events/event-id, Promotion => promotions/promotion-id
                   */}
                  <Link to={`${feature_type}s/${id}`}>
                    <HighlightFeatureCard feature={feature} />
                  </Link>
                </CarouselItem>
              )
            })}
            {defaultItemsLinks && defaultItemsLinks.length > 0 ? (
              <CarouselItem>
                <Box sx={linkCardContainer}>
                  {defaultItemsLinks.map(
                    ({ to, primaryText, secondaryText, icon }) => (
                      <Link key={to} to={to}>
                        <Card sx={textCard}>
                          <Stack spacing={1}>
                            <Box sx={defaultCardIcon}>{icon}</Box>
                            <Stack spacing={0.5}>
                              <Typography
                                variant="title"
                                sx={defaultCardPrimaryText}
                              >
                                {t(primaryText)}
                              </Typography>
                              <Typography
                                variant="subTitle"
                                sx={defaultCardSecondaryText}
                              >
                                {t(secondaryText)}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Card>
                      </Link>
                    )
                  )}
                </Box>
              </CarouselItem>
            ) : null}
          </Carousel>
        </Box>
        <Box sx={navigationWrapper}>
          <AppNavigation />
        </Box>
      </FullScreenOverlayLayout>
    </>
  )
}
