import { useQuery } from "@tanstack/react-query"

import { IAppConfig, fetchAppConfig } from "services/venue"

const DEFAULT_APP_CONFIG = {
  map: {},
} as IAppConfig

export const useGetAppConfig = () => {
  return useQuery<IAppConfig | undefined, unknown, IAppConfig>({
    queryKey: ["app-config"],
    queryFn: async () => {
      const appConfig = await fetchAppConfig()
      return appConfig || DEFAULT_APP_CONFIG
    },
    initialData: DEFAULT_APP_CONFIG,
    retry: true,
    refetchOnWindowFocus: false,
  })
}
