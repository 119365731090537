import { useEffect, useMemo } from "react"
import { Feature, featureCollection } from "@turf/helpers"
import transformScale from "@turf/transform-scale"
import { useVenue } from "providers/venue/useVenue"
import { useIndoorMap } from "./useIndoorMap"

const DEFAULT_OPTIONS = {
  scale: 1.25,
}

type Options = {
  scale?: number
}
type UseLimitMapView = (features: Feature[], options: Options) => void

export const useLimitMapView: UseLimitMapView = (
  features: Feature[] = [],
  options = DEFAULT_OPTIONS
) => {
  const { indoorRef, mapLoaded } = useIndoorMap()
  const { dataLoaded } = useVenue()

  const { scale } = useMemo(
    () => ({ ...options, ...DEFAULT_OPTIONS }),
    [options]
  )

  const mapReady = indoorRef.current && mapLoaded && dataLoaded

  useEffect(() => {
    const map = indoorRef.current
    try {
      if (map && mapReady && scale !== 0) {
        const scaledFeatures = features.map((feature) =>
          transformScale(feature, scale)
        )
        const extent = map.getFeatureExtent(featureCollection(scaledFeatures))
        map.setMaxExtent(extent)
      }
    } catch (error) {
      console.log(`error:unable to limit map view`)
    }
    return () => {
      try {
        if (map && mapReady) {
          map.setMaxExtent(null)
        }
      } catch (error) {
        console.log(`error:unable to clean up map view`)
      }
    }
  }, [mapReady, indoorRef, features, scale])
}
