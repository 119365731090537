import { ReactNode } from "react"
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  SxProps,
} from "@mui/material"

interface IInfoListItemProps {
  icon?: ReactNode
  primary: ReactNode
  secondary?: string
  onClick?: () => void
  sx?: SxProps
}

export const InfoListItemWithNavigation = ({
  icon,
  primary,
  secondary,
  onClick,
  sx,
}) => (
  <ListItem disableGutters disablePadding sx={sx}>
    <ListItemButton onClick={onClick}>
      <ListItemAvatar>
        <Avatar color="primary" variant="rounded">
          {icon}
        </Avatar>
      </ListItemAvatar>
      <ListItemText color="primary" primary={primary} secondary={secondary} />
    </ListItemButton>
  </ListItem>
)

export const InfoListItem: React.FC<IInfoListItemProps> = ({
  icon,
  primary,
  secondary,
  sx,
}) => (
  <ListItem disableGutters disablePadding sx={sx}>
    <ListItemAvatar>
      <Avatar color="primary" variant="rounded">
        {icon}
      </Avatar>
    </ListItemAvatar>
    <ListItemText color="primary" primary={primary} secondary={secondary} />
  </ListItem>
)
