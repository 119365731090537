import { useTranslation } from "providers/i18n"
import {
  Typography,
  CardMedia,
  CardContent,
  Stack,
  Box,
  useTheme,
  Card,
} from "@mui/material"
import { deepmerge } from "@mui/utils"
import { LocationIcon } from "components/icons"
import { getDisplayDateTime } from "../../../utils/date"
import { getFeatureDetail } from "../../../utils/feature"
import { LocationInfo } from "../features/LocationInfo"
import { useMemo } from "react"

export const VerticalEventCard = ({ event, dense, sx }) => {
  const { t } = useTranslation()
  const { name, coverImage, venueLogo, startDate, endDate, hasLocation, isAd } =
    getFeatureDetail(event)

  const durationText = useMemo(
    () => getDisplayDateTime(startDate, endDate),
    [startDate, endDate]
  )

  const theme = useTheme()
  const { styleOverrides } = theme.components?.[`VerticalEventCard`] || {}
  const {
    root,
    cardContent,
    cardMedia,
    cardTitle,
    iconContainer,
    venueLogoContainer,
    cardContentStack,
    durationContainer,
    cardInfoContainer,
    recommendText,
  } = styleOverrides

  return (
    <Card
      sx={deepmerge(root, sx)}
      className={dense ? "MuiCardContent-dense" : ""}
    >
      <CardMedia sx={cardMedia} image={coverImage}>
        {isAd && (
          <Typography variant="caption" component="p" sx={recommendText}>
            {t("Recommend")}
          </Typography>
        )}
      </CardMedia>

      <CardContent sx={cardContent}>
        <Stack spacing={1} sx={cardContentStack}>
          {venueLogo && (
            <Box sx={venueLogoContainer}>
              <CardMedia component="img" image={venueLogo} />
            </Box>
          )}
          <Box sx={durationContainer}>
            <Typography component="p" variant="subTitle">
              {durationText}
            </Typography>
          </Box>
          <Typography
            sx={cardTitle}
            variant="subTitle"
            component="div"
            color="text.primary"
          >
            {t(name)}
          </Typography>
        </Stack>
        {hasLocation && (
          <Box sx={cardInfoContainer}>
            <Box sx={iconContainer} color="text.secondary">
              <LocationIcon />
            </Box>
            <LocationInfo
              feature={event}
              component="p"
              variant="subTitle"
              color="text.secondary"
            />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
