import { useMemo, useCallback } from "react"
import { IFeature, IRoute, IStep } from "interfaces"
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom"
import _, { get, isNil, groupBy } from "lodash"

import { getFeatureDetail } from "utils/feature"
import { useVenue } from "providers/venue"
import { Q_AUTOPLAY } from "constant"
interface OutletContext {
  route: IRoute
  steps: IStep[]
  origin?: IFeature
  destination?: IFeature
  elevatorPreferredMode?: boolean
  duration: number
  distance: number
  scannedLocations: IFeature[]
  userLocation: IFeature
  isCalculatingRoute: boolean
  toggleElevatorPreference: () => void
}

export const useDirectionOverview = () => {
  const navigate = useNavigate()
  const { levels, stepAutoplayConfig, mapTheme } = useVenue()
  const [searchParams] = useSearchParams()
  const {
    steps = [],
    origin,
    destination,
    duration,
    distance,
    route,
    scannedLocations,
    userLocation,
    isCalculatingRoute,
    elevatorPreferredMode = false,
    toggleElevatorPreference,
  } = useOutletContext<OutletContext>()

  const {
    step = "1",
    origin: originParam,
    destination: destinationParam,
  } = useParams()

  const isReady = useMemo(() => steps.length > 0, [steps])

  const currentStepNumber = Number(step)

  const originData = useMemo(() => getFeatureDetail(origin), [origin])
  const originVenueData = useMemo(
    () => getFeatureDetail(get(origin, "properties.venue")),
    [origin]
  )

  const originName = useMemo(() => get(originData, "name"), [originData])
  const originVenueName = useMemo(
    () => get(originVenueData, "name"),
    [originVenueData]
  )
  const originLevelName = useMemo(() => get(originData, "level"), [originData])

  const originLogo = useMemo(() => get(originData, "logo"), [originData])

  const originCategoryIconPath = useMemo(() => {
    const category = get(originData, "category", "")
    const categoryName =
      typeof category === "string" ? category : get(category, "en")
    const iconPath = get(
      mapTheme,
      `${categoryName}.label.marker.symbol.markerPath`
    )
    return get(originData, "categoryIconPath", iconPath)
  }, [originData, mapTheme])

  const destinationData = useMemo(
    () => getFeatureDetail(destination),
    [destination]
  )
  const destinationVenueData = useMemo(
    () => getFeatureDetail(get(destination, "properties.venue")),
    [destination]
  )

  const destinationName = useMemo(
    () => get(destinationData, "name"),
    [destinationData]
  )
  const destinationVenueName = useMemo(
    () => get(destinationVenueData, "name"),
    [destinationVenueData]
  )
  const destinationLevelName = useMemo(
    () => get(destinationData, "level"),
    [destinationData]
  )

  const destinationLogo = useMemo(
    () => get(destinationData, "logo"),
    [destinationData]
  )

  const destinationCategoryIconPath = useMemo(() => {
    const category = get(destinationData, "category", "")
    const categoryName =
      typeof category === "string" ? category : get(category, "en")
    const iconPath = get(
      mapTheme,
      `${categoryName}.label.marker.symbol.markerPath`
    )
    return get(destinationData, "categoryIconPath", iconPath)
  }, [destinationData, mapTheme])

  const currentOrdinal = Number(
    searchParams.get("ordinal") || origin?.properties?.ordinal
  )

  //TODO: Find a way to handle in case ordinal is the same but the name of ordinal are different
  const relatedLevelWithSteps = useMemo(() => {
    const groupedStepsByLevel = groupBy(steps, (step) =>
      get(step, "origin.properties.ordinal")
    )
    return _(levels)
      .filter((level) => !!groupedStepsByLevel[level.properties.ordinal])
      .orderBy(["properties.ordinal"], ["desc"])
      .uniqBy("properties.ordinal")
      .value()
  }, [levels, steps])

  const progressValue = (currentStepNumber * 100) / steps.length

  const isGoingToOtherVenue = useMemo(() => {
    const originVenueId = get(originVenueData, "id")
    const destinationVenueId = get(destinationVenueData, "id")
    return (
      !isNil(originVenueId) &&
      !isNil(originVenueId) &&
      originVenueId !== destinationVenueId
    )
  }, [originVenueData, destinationVenueData])

  const startNavigate = useCallback(() => {
    if (!isNil(originParam) && !isNil(destinationParam)) {
      searchParams.set(Q_AUTOPLAY, stepAutoplayConfig.enable)
      navigate(
        `/maps/dir/${originParam}/${destinationParam}/navigate/steps/1?${searchParams.toString()}`,
        { replace: true }
      )
    }
  }, [
    navigate,
    originParam,
    destinationParam,
    searchParams,
    stepAutoplayConfig,
  ])

  const changeOrdinal = useCallback(
    (ordinal: number) => {
      searchParams.set("ordinal", String(ordinal))
      navigate(`?${searchParams.toString()}`, { replace: true })
    },
    [navigate, searchParams]
  )

  return {
    isCalculatingRoute,
    steps,
    duration,
    distance,
    scannedLocations,
    userLocation,
    isReady,
    progressValue,
    originParam,
    destinationParam,
    currentStepNumber,
    currentOrdinal,
    originData,
    originLevelName,
    originName,
    originVenueName,
    originLogo,
    originCategoryIconPath,
    originVenueData,
    destinationData,
    destinationName,
    destinationLevelName,
    destinationVenueName,
    destinationLogo,
    destinationCategoryIconPath,
    destinationVenueData,
    isGoingToOtherVenue,
    relatedLevelWithSteps,
    route,
    elevatorPreferredMode,
    toggleElevatorPreference,
    startNavigate,
    changeOrdinal,
  }
}
