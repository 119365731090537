import { useCallback, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Box,
  Stack,
  Typography,
  IconButton,
  Slide,
  Zoom,
  Button,
  Fab,
  useTheme,
  Paper,
} from "@mui/material"

import { Trans } from "react-i18next"
import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import {
  BackIcon,
  PrevStepIcon,
  NextStepIcon,
  MarkerIcon,
  OriginMarkerIcon,
} from "components/icons"
import { BorderLinearProgress } from "components/Progress/BorderLinearProgress"
import { StepProgressBar } from "components/Progress/StepProgressBar"
import { OrdinalSelector } from "components/OrdinalSelector"
import { ExitIcon } from "components/icons"
import { useStepByStep } from "hooks/direction/useStepByStep"
import { useStepByStepMap } from "hooks/direction/useStepByStepMap"
import { getIcon } from "utils/icon"

export const StepByStepKioskVPage = () => {
  const [animateScroll, setAnimateScroll] = useState(false)
  const navigate = useNavigate()
  const { dataLoaded, isMultiOrdinalVenue } = useVenue()

  const {
    steps,
    destinationParam,
    originParam,
    nextStepNumber,
    prevStepNumber,
    currentStepNumber,
    currentStep,
    progressValue,
    currentLevel,
    currentOrdinal,
    originLevelName,
    originName,
    route,
    destinationLevelName,
    destinationName,
  } = useStepByStep()

  useStepByStepMap(route, currentStepNumber, {
    offset: { bottom: 411 },
    enabled: dataLoaded && steps.length > 0,
  })

  const { t } = useTranslation()

  const theme = useTheme()

  const {
    container,
    primaryText,
    title,
    secondaryText,
    locationText,
    originIcon,
    destinationIcon,
    navButtonWrapper,
    headerWrapper,
    navButton,
    iconNavButton,
    overviewWrapper,
    ordinalSelectorWrapper,
    wrapper,
    kioskBackButton,
    highlightedText,
    stepTextContainer,
    exitButtonWrapper,
    exitButton,
    borderLinearProgressBar,
    stepProgressBar,
    originIconContainer,
    destinationIconContainer,
  } = theme.components[`AppNavigationStep`]?.styleOverrides || {}

  const originFloorText = isMultiOrdinalVenue
    ? `(${t("At Floor", {
        value: originLevelName,
      })})`
    : ""
  const destinationFloorText = isMultiOrdinalVenue
    ? `(${t("At Floor", {
        value: destinationLevelName,
      })})`
    : ""
  const highlightedStepText = (
    <Typography variant="subTitle" component="span" sx={highlightedText} />
  )
  const handleClickStep = useCallback(
    (index) => {
      navigate(
        `/maps/dir/${originParam}/${destinationParam}/navigate/steps/${
          index + 1
        }`,
        { replace: true }
      )
    },
    [originParam, destinationParam, navigate]
  )
  const backIcon = useMemo(
    () => getIcon(theme?.assets?.backIcon) || <BackIcon />,
    [theme]
  )

  const originIconComp = useMemo(
    () =>
      getIcon(theme?.assets?.navigation?.origin, originIcon) || (
        <OriginMarkerIcon sx={originIcon} />
      ),
    [theme, originIcon]
  )

  const destinationIconComp = useMemo(
    () =>
      getIcon(theme?.assets?.navigation?.destination, destinationIcon) || (
        <MarkerIcon sx={destinationIcon} />
      ),
    [theme, destinationIcon]
  )

  const handleEnteredSlide = useCallback(() => {
    setAnimateScroll(true)
  }, [])

  return (
    <>
      <Slide
        direction="up"
        in={dataLoaded && steps.length > 0}
        onEntered={handleEnteredSlide}
        mountOnEnter
        unmountOnExit
      >
        <Box sx={wrapper}>
          {isMultiOrdinalVenue && (
            <Box sx={ordinalSelectorWrapper}>
              <OrdinalSelector
                levels={currentLevel}
                selectedOrdinal={currentOrdinal}
              />
            </Box>
          )}
          <Fab
            component={Link}
            variant="rounded"
            replace={true}
            to={`/maps/dir/${originParam}/${destinationParam}`}
            sx={kioskBackButton}
          >
            {backIcon}
          </Fab>
          <Paper sx={container} spacing={1}>
            <Box sx={exitButtonWrapper}>
              <Button
                component={Link}
                to="/maps"
                startIcon={<ExitIcon />}
                sx={exitButton}
                variant="contained"
              >
                Exit
              </Button>
            </Box>
            <Stack width="100%" spacing={0} direction="column">
              <Box sx={headerWrapper}>
                <Stack alignItems="center" spacing={1}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Zoom in={currentStepNumber > 1}>
                      <IconButton
                        color="inherit"
                        component={Link}
                        sx={iconNavButton}
                        replace={true}
                        to={`/maps/dir/${originParam}/${destinationParam}/navigate/steps/${prevStepNumber}`}
                      >
                        <PrevStepIcon fontSize="inherit" />
                      </IconButton>
                    </Zoom>

                    <Typography component={"h1"} textAlign="center" sx={title}>
                      {t("Process step", {
                        currentStep: currentStepNumber,
                        totalSteps: steps.length,
                      })}
                    </Typography>

                    <Zoom in={currentStepNumber < steps.length}>
                      <IconButton
                        color="inherit"
                        component={Link}
                        sx={iconNavButton}
                        replace={true}
                        to={`/maps/dir/${originParam}/${destinationParam}/navigate/steps/${nextStepNumber}`}
                      >
                        <NextStepIcon fontSize="inherit" />
                      </IconButton>
                    </Zoom>
                  </Stack>
                  <Box sx={stepTextContainer}>
                    <Typography
                      variant="subTitle"
                      component="p"
                      sx={primaryText}
                    >
                      <Trans
                        components={{
                          category: highlightedStepText,
                          destination: highlightedStepText,
                          landmark: highlightedStepText,
                        }}
                      >
                        {t(currentStep?.description.text, {
                          category: t(
                            currentStep?.description.options?.category
                          ),
                          destination: t(
                            currentStep?.description.options?.destination
                          ),
                          landmark: t(
                            currentStep?.description.options?.landmark
                          ),
                        })}
                      </Trans>
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              {/* kiosk-v progress bar */}
              <Stack
                width="100%"
                direction="row"
                alignItems={"flex-end"}
                sx={borderLinearProgressBar}
              >
                <Box sx={originIconContainer}>{originIconComp}</Box>
                <BorderLinearProgress
                  variant="determinate"
                  value={progressValue}
                />
                <Box sx={destinationIconContainer}>{destinationIconComp}</Box>
              </Stack>
              {/* end of kiosk-v progress bar */}
              {/* mobile progress bar */}
              <StepProgressBar
                steps={steps}
                currentStep={currentStepNumber}
                sx={stepProgressBar}
                handleClickStep={handleClickStep}
                enableAnimateScroll={animateScroll}
              />
              {/* end of kiosk-v progress bar */}
              {/* Location and overview section */}
              <Stack sx={overviewWrapper}>
                <Typography
                  textAlign="start"
                  variant="caption"
                  sx={locationText}
                >
                  {t(originName)}
                </Typography>
                <Typography variant="caption" sx={secondaryText}>
                  {t("From To", {
                    origin: `${t(originName)} ${originFloorText}`,
                    destination: `${t(
                      destinationName
                    )} ${destinationFloorText}`,
                  })}
                </Typography>
                <Typography textAlign="end" variant="caption" sx={locationText}>
                  {t(destinationName)}
                </Typography>
              </Stack>
              <Stack sx={navButtonWrapper}>
                <Button
                  variant="outlined"
                  startIcon={<PrevStepIcon />}
                  disabled={currentStepNumber <= 1}
                  component={Link}
                  sx={navButton}
                  replace={true}
                  to={`/maps/dir/${originParam}/${destinationParam}/navigate/steps/${prevStepNumber}`}
                >
                  {t("Previous Step")}
                </Button>
                <Button
                  variant="outlined"
                  disabled={currentStepNumber === steps.length}
                  component={Link}
                  sx={navButton}
                  endIcon={<NextStepIcon />}
                  replace={true}
                  to={`/maps/dir/${originParam}/${destinationParam}/navigate/steps/${nextStepNumber}`}
                >
                  {t("Next Step")}
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Box>
      </Slide>
    </>
  )
}
