import { useCallback, useMemo } from "react"
import {
  Box,
  Fab,
  InputAdornment,
  InputBase,
  ToggleButtonGroup,
  useTheme,
} from "@mui/material"
import _ from "lodash"

import { useSearchContainer } from "hooks/search/useSearchContainer"
import { useAppUI } from "providers/ui"
import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import { useGeoLocation } from "providers/geolocation"

import { getIcon } from "utils/icon"
import { getFeatureName } from "utils/feature"

import { BackIcon } from "components/icons"
import { CategoryFilterButton } from "components/CategoryFilterBar"
import { HorizontalScrollPane } from "components/HorizontalScrollPane"
import { SearchKeyboard } from "components/domains/search/SearchKeyboard"
import { EmptyData } from "components/EmptyData"
import { VenueSelector } from "components/VenueSelector"
import { MotionDiv, showContent } from "components/motions"

import {
  CancelRoundedIcon,
  SearchIcon,
  ShopIcon,
} from "extensions/one-siam/components/icons"
import { DirectoryOccupantCard } from "extensions/one-siam/components/domains/directory"
import { AdSuggestList } from "extensions/one-siam/components/domains/ads/kiosk-v/suggested-brand/AdSuggestList"

export const SearchKioskVPage = () => {
  const { t } = useTranslation()
  const { goBack } = useAppUI()
  //TODO:: move to new hook
  const { locationVenue } = useGeoLocation()
  const locationVenueId = _.get(locationVenue, "id")
  const { isMultiVenueProject, venues } = useVenue()

  const theme = useTheme()
  const styleOverrides = theme.components[`SearchPage`]?.styleOverrides || {}
  const {
    // Search Result
    root,
    listContainer,
    gridContainer,
    gridItem,
    emptyDataWrapper,
    emptyData,
    suggestedList,
    // Toolbar
    venueFilterContainer,
    toolbarContainer,
    categoryFilterBarContainer,
    scrollPane,
    categoryFilterBar,
    categoryFilterButtonGroup,
    categoryFilterButton,
    searchBarContainer,
    searchInput,
    backButtonWrapper,
    backButton,
  } = styleOverrides

  const {
    searchInputRef,
    keyboardRef,
    searchResultItems,
    searchValue,
    venueFilter,
    sortedParentCategoryWithOccupant,
    suggestedListItems,
    /* onClose,*/
    onChange,
    onChangeKeyboard,
    onClickCategory,
    onSelectResult,
    onClickClearSearchValue,
    setVenueFilter,
    onClickSuggestedItem,
  } = useSearchContainer({ showOnscreenKeyboard: true })
  const hasSuggestedList = suggestedListItems && suggestedListItems?.length > 0
  const shouldShowSuggestedList = !searchValue && hasSuggestedList
  const backIcon = useMemo(
    () => getIcon(theme?.assets?.backIcon) || <BackIcon />,
    [theme]
  )

  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.search) || <ShopIcon />,
    [theme]
  )

  const handleClickBack = useCallback(() => {
    goBack()
  }, [goBack])

  return (
    <MotionDiv
      variants={showContent}
      initial="initial"
      animate="animate"
      sx={root}
    >
      <Box sx={listContainer}>
        {searchResultItems?.length > 0 ? (
          <Box sx={gridContainer}>
            {shouldShowSuggestedList ? (
              <AdSuggestList
                sx={suggestedList}
                suggestedListItems={suggestedListItems}
                onClickSuggestedItem={onClickSuggestedItem}
              />
            ) : null}
            {searchResultItems.map((occupant) => (
              <Box sx={gridItem} key={`directory-item-${occupant.id}`}>
                <DirectoryOccupantCard
                  data={occupant}
                  onClick={onSelectResult}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Box sx={emptyDataWrapper}>
            <EmptyData
              sx={emptyData}
              icon={emptyIcon}
              primary={t("No result")}
              secondary={t(
                "Please refine your search criteria or filters for desired results."
              )}
            />
          </Box>
        )}
      </Box>
      <Box sx={toolbarContainer}>
        {/* Venue Selector */}
        {isMultiVenueProject && (
          <Box sx={venueFilterContainer}>
            <VenueSelector
              selectedVenue={venueFilter}
              venues={venues}
              onClickVenue={setVenueFilter}
              currentVenue={locationVenueId}
            />
          </Box>
        )}
        {/* End of Venue Selector */}
        {/* Search Input */}
        <Box sx={searchBarContainer}>
          <Box sx={backButtonWrapper}>
            <Fab variant="rounded" sx={backButton} onClick={handleClickBack}>
              {backIcon}
            </Fab>
          </Box>
          <InputBase
            fullWidth
            placeholder={t("Search shops and more...")}
            onChange={onChange}
            inputRef={searchInputRef}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <CancelRoundedIcon onClick={onClickClearSearchValue} />
              </InputAdornment>
            }
            sx={searchInput}
          />
        </Box>
        {/* End of Search Input */}
        {/* Category Quick Search */}
        <Box sx={categoryFilterBarContainer}>
          <HorizontalScrollPane
            sx={categoryFilterBar}
            stackProps={{
              sx: scrollPane,
            }}
          >
            <ToggleButtonGroup
              sx={categoryFilterButtonGroup}
              exclusive
              variant="borderSeperate"
            >
              {sortedParentCategoryWithOccupant.map((category) => (
                <CategoryFilterButton
                  className={`${
                    category?.properties?.is_featured ? "highlight" : ""
                  }`}
                  value={category.id}
                  key={`category-filter-${category.id}`}
                  Icon={null}
                  onClick={() => onClickCategory(category)}
                  sx={categoryFilterButton}
                >
                  {t(getFeatureName(category))}
                </CategoryFilterButton>
              ))}
            </ToggleButtonGroup>
          </HorizontalScrollPane>
        </Box>
        {/* End of Category Quick Search */}
        <SearchKeyboard
          keyboardRef={keyboardRef}
          value={searchValue}
          onChange={onChangeKeyboard}
        />
      </Box>
    </MotionDiv>
  )
}
