/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useMemo } from "react"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
  matchPath,
} from "react-router-dom"
import { Box, useTheme } from "@mui/material"
import _ from "lodash"

import config from "../config"

import { useVenue } from "providers/venue"
import { useGeoLocation } from "providers/geolocation"
import { useAppUI } from "../providers/ui"
import { useVenueController } from "hooks/venue/useVenueController"

import { getSuitablyValueBetweenBearings } from "components/IndoorMap"
import { useIndoorMap } from "providers/venue/modules/indoormap/hooks/useIndoorMap"
import { useLimitMapView } from "providers/venue/modules/indoormap/hooks/useLimitMapView"

const FALLBACK_CENTER = [100.5017051, 13.7572619]
const DEFAULT_ZOOM = 18.5

export const IndoorMapContainer = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    isLoading,
    userLocation,
    scannedLocations,
    deviceLocation,
    locationVenue,
  } = useGeoLocation()

  const {
    venues,
    features,
    labels,
    decorations,
    findFeatureById,
    dataLoaded,
    transformLocationToFeature,
    relationshipGraphLoaded,
    mapTheme,
    defaultVenue,
  } = useVenue()

  const locationVenueId = locationVenue?.id || defaultVenue?.id
  const { viewingVenue: viewingVenueId } = useVenueController(locationVenueId)
  const { indoorRef, mapLoaded, mapLoading, mapCreated } = useIndoorMap()
  const { mapReady } = useAppUI()
  const [mapLayout, setMapLayout] = useState("full")
  const theme = useTheme()
  const styleOverrides = theme.components[`AppMap`]?.styleOverrides || {}
  const { indoorMapContainer } = styleOverrides

  const { origin: originParams, destination: destinationParams } = useParams()
  const [searchParams] = useSearchParams()

  const defaultCenter = useMemo(
    () =>
      _.get(venues, "[0].properties.display_point.coordinates") ||
      FALLBACK_CENTER,
    [venues]
  )

  const targetOrigin = useMemo(
    () => transformLocationToFeature(originParams),
    [originParams, transformLocationToFeature]
  )

  const targetDestination = useMemo(
    () => transformLocationToFeature(destinationParams),
    [destinationParams, transformLocationToFeature]
  )
  const isKioskMode = useMemo(
    () => ["kiosk-v", "kiosk-h"].includes(config("device")),
    []
  )

  useLimitMapView(venues)

  useEffect(() => {
    /* Handle User location
     * @Eample: /maps/ordinal/2?user-location=98.29877730421258,7.892412617738349,2o
     */
    if (!indoorRef.current) return

    if (mapLoaded && !mapLoading && isKioskMode && deviceLocation) {
      indoorRef.current?.addUserLocation(deviceLocation)
    } else if (mapLoaded && !mapLoading && userLocation) {
      indoorRef.current?.addUserLocation(userLocation)
    } else {
      indoorRef.current?.removeUserLocation()
    }
  }, [userLocation, mapLoaded, mapLoading, deviceLocation, indoorRef.current])

  useEffect(() => {
    /**
     * Handle last scanned locations
     */
    if (!indoorRef.current) return

    //TODO:: make this logic supports multiple locations?
    const scannedLocation = _.last(scannedLocations)
    if (mapReady && !mapLoading && scannedLocation) {
      indoorRef.current?.removeLastUserLocation()
      indoorRef.current?.addLastUserLocation(scannedLocation)
    } else {
      indoorRef.current?.removeLastUserLocation()
    }
  }, [
    scannedLocations,
    mapReady,
    mapLoading,
    deviceLocation,
    indoorRef.current,
  ])

  useEffect(() => {
    if (
      !indoorRef.current ||
      !mapLoaded ||
      !dataLoaded ||
      !mapTheme ||
      !relationshipGraphLoaded
    )
      return

    if (location.pathname !== "/" && location.pathname.indexOf("/maps") !== 0) {
      // Hide Map
      setMapLayout("hidden")
      return
    }

    const [action] = location.pathname.replace("/maps/", "").split("/")

    setMapLayout("full")

    // Handle the map's direction and the map's interactive
    switch (action) {
      case "ordinal":
        break
      case "place":
        break
      case "dir":
        break
      case "":
        indoorRef.current.clearNavigationGeometries()
        indoorRef.current.disableClick()
        break
      default:
        indoorRef.current.clearNavigationGeometries()
        indoorRef.current.enableClick()
        indoorRef.current.setFeatureObject3DsOpacity(1)
        break
    }

    // Handle the map's rendering
    switch (action) {
      case "place": {
        break
      }
      case "ordinal": {
        break
      }
      case "dir": {
        if (!targetOrigin && targetDestination && userLocation) {
          navigate(
            `/maps/dir/${userLocation.properties.userLocationParam}/${targetDestination?.id}`,
            { replace: true }
          )
          return
        }

        if (
          isKioskMode &&
          !targetOrigin &&
          targetDestination &&
          deviceLocation
        ) {
          navigate(
            `/maps/dir/${deviceLocation.properties.userLocationParam}/${targetDestination?.id}`,
            { replace: true }
          )
          return
        }
        break
      }
      case "occupants":
      case "amenities": {
        break
      }
      case "": {
        setMapLayout("bottom-half")
        break
      }
      default: {
        indoorRef.current.showVenueObjects()
        indoorRef.current.changeLevelByOrdinal(0)
        const currentBearing = indoorRef.current.getBearing()
        const bearing = getSuitablyValueBetweenBearings(0, currentBearing)
        indoorRef.current.flyTo(defaultCenter, {
          zoom: DEFAULT_ZOOM,
          bearing,
        })
        indoorRef.current.clearHighlightElements()
        indoorRef.current.clearHighlightObject()
        break
      }
    }
  }, [
    indoorRef,
    location.pathname,
    findFeatureById,
    navigate,
    isLoading,
    userLocation,
    relationshipGraphLoaded,
    searchParams,
    mapLoaded,
    viewingVenueId,
  ])

  const handleClickElement = useCallback(
    (params) => {
      const replace = matchPath("/maps/place/:placeId", location.pathname)
      const { id, feature_type, category } = params.target.properties
      switch (`${feature_type}-${category}`) {
        case "unit-room":
        case "unit-unenclosedarea":
        case "unit-unspecified":
        case "unit-recreation":
        case "kiosk-kiosk":
          // STEP: find unit's occupant or kiosk's occupant
          const occupant = features.find((f) => {
            return (
              f.properties.anchor?.properties?.unit_id === id ||
              f.properties?.kiosk_id === id
            )
          })
          if (occupant) navigate(`/maps/place/${occupant.id}`, { replace })
          break
        case "amenity-atm":
        case "amenity-babychanging":
        case "amenity-strollerrental":
        case "amenity-boardinggate.ferry":
        case "amenity-elevator":
        case "amenity-escalator":
        case "amenity-stairs":
        case "amenity-information":
        case "amenity-information.transit":
        case "amenity-wheelchair":
        case "amenity-restroom":
        case "amenity-restroom.male":
        case "amenity-restroom.female":
        case "amenity-restroom.wheelchair":
        case "amenity-taxi":
        case "amenity-bus":
        case "amenity-parking":
        case "amenity-parking.bicycle":
        case "amenity-parking.motorcycle":
        case "amenity-privatelounge":
        case "amenity-landmark":
        case "amenity-rail.muni":
        case "amenity-service":
        case "amenity-smokingarea":
        case "amenity-ticketing":
        case "amenity-meetingpoint":
        case "amenity-prayerroom":
        case "amenity-firstaid":
        case "amenity-ticketing.rail":
        case "amenity-exhibit":
        case "amenity-mothersroom":
        case "amenity-checkin.desk":
        case "amenity-baggagestorage":
        case "amenity-baggagecarts":
        case "amenity-powerchargingstation":
        case "amenity-coinlocker":
        case "occupant-currencyexchange":
        case "occupant-bank":
        case "occupant-books":
          navigate(`/maps/place/${id}`, { replace })
          break
        default:
          break
      }
    },
    /** Remove navigate because it trigger unneccessary re-render & createFeatures in Indoormap */
    [features, navigate, location]
  )

  useEffect(() => {
    if (indoorRef.current) {
      indoorRef.current.onClickElement = handleClickElement
    }
  }, [indoorRef, dataLoaded, mapCreated, handleClickElement])

  /** Init with features  */
  useEffect(() => {
    if (
      indoorRef.current &&
      mapCreated &&
      // !indoorRef.current.elementsLoaded &&
      mapTheme &&
      dataLoaded
    ) {
      indoorRef.current.mapDecorations = decorations
      indoorRef.current.features = features
      indoorRef.current.groundLabels = labels
      indoorRef.current.mapTheme = mapTheme
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    indoorRef.current,
    features,
    mapTheme,
    labels,
    decorations,
    dataLoaded,
    mapCreated,
  ])

  return (
    <Box sx={indoorMapContainer} className={mapLayout}>
      <div id="map"></div>
    </Box>
  )
}
