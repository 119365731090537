import { QueryKey } from "@tanstack/react-query"
import { CreateGetListQueryKeyOptions } from "../types"

export const createGetListQueryKey = (
  queryKey: QueryKey,
  options?: CreateGetListQueryKeyOptions
) => {
  const { filter = {}, sort = {}, fallbackQuerykey } = options || {}
  const key = queryKey || fallbackQuerykey
  return [...key, { filter, sort }]
}
