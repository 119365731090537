import { useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Avatar, Box, Button, useTheme, CardMedia } from "@mui/material"
import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import { useHome } from "../../hooks/home/useHome"
import { useHomeMap } from "hooks/home/useHomeMap"

import { FullScreenOverlayLayout } from "layouts/FullScreenOverlayLayout"
import { QRCode } from "components/QRCode"
import { Head } from "components/Head"
import { Image } from "components/Image"

import { pushDataLayer } from "utils/googletagmanager/pushDataLayer"

import config from "config"
import { GTM_COUNT_KIOSK_USERS } from "constant"

export const HomePage = () => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { venues } = useVenue()
  const { locales } = useHome()
  useHomeMap(venues)
  const styleOverrides = theme.components[`HomePage`]?.styleOverrides || {}
  const {
    root,
    contentContainer,
    welcomePanelWrapper,
    welcomeContainer,
    localeListContainer,
    localeButton,
    actionContainer,
    actionButton,
    logoContainer,
    logoImage,
    qrContainer,
    qrCode,
    videoContainer,
  } = styleOverrides
  const { logo, video } = theme.assets || {}
  const switchLanguage = useCallback(
    (lang) => (e) => {
      i18n.changeLanguage(lang)
    },
    [i18n]
  )
  const mobileBaseURL = config("mobileBaseUrl")
  const currentLanguage = useMemo(() => i18n.language, [i18n.language])

  const handleClick = useCallback(() => {
    pushDataLayer({
      event: GTM_COUNT_KIOSK_USERS,
      count_by_event: GTM_COUNT_KIOSK_USERS,
    })
    navigate(`/maps`)
  }, [navigate])

  return (
    <>
      <Head title={t("Indoor map")} />
      <FullScreenOverlayLayout sx={root}>
        <Box sx={contentContainer}>
          <Box sx={videoContainer}>
            <CardMedia component="video" src={video?.url} autoPlay muted loop />
          </Box>
          <Box sx={welcomePanelWrapper}>
            <Box sx={welcomeContainer}>
              <Box sx={logoContainer}>
                <Image src={logo?.url} sx={logoImage} />
              </Box>
              <Box sx={localeListContainer}>
                {locales.map((locale) => {
                  const isCurrentLanguage =
                    currentLanguage === locale.localeKey.toString()
                  return (
                    <Button
                      key={locale.localeKey}
                      onClick={switchLanguage(locale.localeKey)}
                      sx={{
                        ...localeButton,
                        opacity: isCurrentLanguage ? 1 : 0.5,
                      }}
                    >
                      <Avatar
                        src={`https://flagcdn.com/h40/${locale.flagCode}.webp`}
                      />
                    </Button>
                  )
                })}
              </Box>
              <Box sx={actionContainer}>
                <Button
                  sx={actionButton}
                  variant="contained"
                  onClick={handleClick}
                >
                  {t("Touch to start")}
                </Button>
              </Box>
              <Box sx={qrContainer}>
                <QRCode
                  url={mobileBaseURL}
                  reactQRCodeProps={{ bgColor: "transparent" }}
                  sx={qrCode}
                  caption={t("Scan to get navigation map on your phone")}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </FullScreenOverlayLayout>
    </>
  )
}
