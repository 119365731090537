import { useMemo } from "react"
import { Box, useTheme } from "@mui/material"
import { useDirectory } from "hooks"
import {
  CategoryFilterBar,
  CategoryFilterChip,
} from "components/CategoryFilterBar"

import { getIcon } from "utils/icon"
import { useAppUI } from "providers/ui"
import { useTranslation } from "providers/i18n"

import { LanguageButton } from "components/Language"
import { SearchToggleButton } from "components/domains/search"
import { EmptyData } from "components/EmptyData"
import { VenueSelector } from "components/VenueSelector"
import { AppNavigation } from "containers/kiosk-v"
import { HorizontalScrollPane } from "components/HorizontalScrollPane"
import { Head } from "components/Head"
import { DirectoryOccupantCard } from "components/domains/directory/DirectoryOccupantCard"
import { StoreIcon } from "components/icons"
import { MotionDiv, showContent } from "components/motions"

export const DirectoryKioskVPage = ({ Layout = Box }) => {
  const { t } = useTranslation()
  const { openSearch } = useAppUI()

  const {
    handleChangeParentCategory,
    handleChangeSubCategory,
    changeVenueFilter,
    filteredOccupantsSorted,
    sortedParentCategoryWithOccupant,
    subCategoryWithActiveParentCategory,
    parentCategoryId,
    subCategoryId,
    venueFilter,
    ref,
    showSubCategoryFilter,
    venues,
    isMultiVenueProject,
    locationVenueId,
  } = useDirectory()

  const theme = useTheme()
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.directory) || <StoreIcon />,
    [theme]
  )
  const styleOverrides = theme.components[`DirectoryPage`]?.styleOverrides || {}
  const {
    root,
    filterSection,
    scrollPane,
    gridContainer,
    gridItem,
    categoryFilterBar,
    subCategoryFilterBar,
    emptyDataWrapper,
    venueSelector,
    navigationWrapper,
    actionButton,
    actionContainer,
    toolbarContainer,
    searchBarContainer,
  } = styleOverrides

  return (
    <>
      <Head title={t("Directory")} />
      <Layout>
        <Box ref={ref} sx={root}>
          {filteredOccupantsSorted?.length > 0 ? (
            <MotionDiv
              sx={gridContainer}
              variants={showContent}
              initial="initial"
              animate="animate"
            >
              {filteredOccupantsSorted.map((occupant) => (
                <Box sx={gridItem} key={`directory-item-${occupant.id}`}>
                  <DirectoryOccupantCard data={occupant} />
                </Box>
              ))}
            </MotionDiv>
          ) : (
            <MotionDiv
              sx={emptyDataWrapper}
              variants={showContent}
              initial="initial"
              animate="animate"
            >
              <EmptyData
                icon={emptyIcon}
                primary={t("Coming soon")}
                secondary={t("Stay tuned for the update")}
              />
            </MotionDiv>
          )}
          {filteredOccupantsSorted?.length > 0 && (
            <Box sx={filterSection}>
              <HorizontalScrollPane
                className="category-filterbar"
                stackProps={{
                  sx: scrollPane,
                }}
              >
                <CategoryFilterBar
                  sx={categoryFilterBar}
                  activeCategory={parentCategoryId}
                  categories={sortedParentCategoryWithOccupant}
                  onChange={handleChangeParentCategory}
                />
              </HorizontalScrollPane>
              {showSubCategoryFilter && (
                <HorizontalScrollPane
                  className="subCategory-filterbar"
                  stackProps={{
                    sx: scrollPane,
                  }}
                >
                  <CategoryFilterBar
                    sx={subCategoryFilterBar}
                    activeCategory={subCategoryId}
                    categories={subCategoryWithActiveParentCategory}
                    onChange={handleChangeSubCategory}
                    renderFilterButton={CategoryFilterChip}
                  />
                </HorizontalScrollPane>
              )}
            </Box>
          )}
          {isMultiVenueProject && (
            <Box sx={venueSelector}>
              <VenueSelector
                selectedVenue={venueFilter}
                venues={venues}
                onClickVenue={changeVenueFilter}
                currentVenue={locationVenueId}
              />
            </Box>
          )}
          <Box key="app-navigation" sx={navigationWrapper}>
            <Box sx={toolbarContainer}>
              <Box sx={actionContainer}>
                <LanguageButton variant="contained" sx={actionButton} />
                {/* //TODO:: Add a wheel chair's mode handler function later */}
                {/*  <Button
            variant="contained"
            sx={actionButton}
            onClick={() => {}}
            startIcon={<RestroomWheelchairIcon />}
          /> */}
              </Box>
              <Box sx={searchBarContainer}>
                <SearchToggleButton
                  placeholder={t("Search shops and more...")}
                  onClick={openSearch}
                />
              </Box>
            </Box>
            <AppNavigation />
          </Box>
        </Box>
      </Layout>
    </>
  )
}
