import React from "react"
import { Drawer, Dialog, DialogContent, Slide, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"

const DialogTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const SearchBasePopup = ({
  children,
  open,
  onClose,
  drawerProps = {},
  dialogProps = {},
  dialogContentProps = {},
  type,
}) => {
  const {
    sx: drawerSxProps,
    PaperProps: drawerPaperProps = {},
    ...restDrawerProps
  } = drawerProps
  const {
    sx: dialogSxProps,
    PaperProps: dialogPaperProps = {},
    ...restDialogProps
  } = dialogProps

  const theme = useTheme()
  const styleOverrides =
    theme.components[`VuiSearchBasePopup`]?.styleOverrides || {}
  const { drawer, drawerPaper, dialog, dialogPaper, dialogContent } =
    styleOverrides

  switch (type) {
    case "Drawer":
      return (
        <Drawer
          open={open}
          onClose={onClose}
          elevation={5}
          anchor="left"
          hideBackdrop={true}
          {...restDrawerProps}
          sx={deepmerge(drawer, drawerSxProps)}
          PaperProps={{
            ...drawerPaperProps,
            sx: deepmerge(drawerPaper, drawerPaperProps.sx),
          }}
        >
          {children}
        </Drawer>
      )
    case "Dialog":
    default:
      return (
        <Dialog
          open={open}
          onClose={onClose}
          TransitionComponent={DialogTransition}
          fullWidth
          hideBackdrop={true}
          transitionDuration={{ enter: 400, exit: 400 }}
          maxWidth="lg"
          {...restDialogProps}
          sx={deepmerge(dialog, dialogSxProps)}
          PaperProps={{
            ...dialogPaperProps,
            sx: deepmerge(dialogPaper, dialogPaperProps.sx),
          }}
        >
          <DialogContent
            {...dialogContentProps}
            sx={deepmerge(dialogContent, dialogContentProps.sx)}
          >
            {children}
          </DialogContent>
        </Dialog>
      )
  }
}
