import React, { useCallback } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"

import { useDirection } from "hooks/direction/useDirection"
import { Outlet } from "react-router-dom"
import { ErrorDialog } from "components/Dialog"
import { useTranslation } from "providers/i18n"
import { Head } from "../../../../../components/Head"
import get from "lodash/get"

export const DirectionLayoutKioskHPage = () => {
  const {
    route,
    isError,
    origin,
    destination,
    isCalculatingRoute,
    //
    elevatorPreferredMode,
    toggleElevatorPreference,
  } = useDirection()

  const { t } = useTranslation()

  const { scannedLocations } = useOutletContext()
  const navigate = useNavigate()

  const handleCloseDialog = useCallback(() => {
    navigate("/maps")
  }, [navigate])

  return (
    <>
      <Head
        title={`${t("title.direction", {
          origin: t(get(route, "origin.properties.name")),
          destination: t(get(route, "destination.properties.name")),
        })}`}
      />
      <ErrorDialog
        open={isError}
        title={t("Navigation Error", { ns: "error" })}
        description={t(
          "The system was unable navigate because either the origin or the destination was not found which you have chosen.",
          { ns: "error" }
        )}
        onClose={handleCloseDialog}
      />
      {
        <Outlet
          context={
            route
              ? {
                  ...route,
                  route,
                  scannedLocations,
                  elevatorPreferredMode,
                  toggleElevatorPreference,
                  isCalculatingRoute,
                }
              : {
                  route,
                  origin,
                  destination,
                  scannedLocations,
                  elevatorPreferredMode,
                  isCalculatingRoute,
                  toggleElevatorPreference,
                }
          }
        />
      }
    </>
  )
}
