import { useCallback, useMemo } from "react"
import { Avatar, Box, Typography, useTheme } from "@mui/material"
import { Link } from "react-router-dom"
import _ from "lodash"

import { getIcon } from "utils/icon"
import { getFeatureDetail } from "utils/feature"

import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import { useDining } from "hooks/dining/useDining"

import { PageLayout } from "layouts/PageLayout"

import {
  CategoryFilterBar,
  CategoryFilterChip,
} from "components/CategoryFilterBar"
import { Head } from "components/Head"

import { ImageCard } from "components/ImageCard"
import { HorizontalScrollPane } from "components/HorizontalScrollPane"
import { CustomIcon, DiningIcon } from "components/icons"
import { EmptyData } from "components/EmptyData"
import { VenueSelector } from "components/VenueSelector"

const DEFAULT_FILTER_ICON = <DiningIcon />

export const DiningPage = ({ Layout = PageLayout }) => {
  const { occupants, isMultiOrdinalVenue } = useVenue()
  const {
    restaurants,
    currentCategory,
    categories,
    isMultiVenueProject,
    locationVenueId,
    venues,
    venueFilter,
    filterByCategoryId,
    filterByVenueId,
  } = useDining(occupants)

  const { t } = useTranslation({ defaultValue: null })

  const filterItems = useMemo(
    () => [
      { value: "ALL", label: t("All"), icon: DEFAULT_FILTER_ICON },
      ...categories.map((category) => ({
        value: category.id,
        label: category.properties.name,
        icon: category.properties?.icon_path ? (
          <CustomIcon path={category.properties?.icon_path} />
        ) : (
          DEFAULT_FILTER_ICON
        ),
      })),
    ],
    [categories, t]
  )

  const handleChangeCategory = useCallback(
    (e, id) => {
      if (id === null) return
      filterByCategoryId(id)
    },
    [filterByCategoryId]
  )

  const theme = useTheme()
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.dining) || <DiningIcon />,
    [theme]
  )

  const showFilterBar = filterItems.length > 1

  const { styleOverrides = {} } = theme.components?.[`AppDining`] || {}
  const {
    root,
    filterSection,
    filterContainer,
    diningImageCard,
    filterButton,
    diningAvatar,
    diningAvatarFallBack,
    gridContainer,
    emptyDataContainer,
    venueSelector,
  } = styleOverrides

  return (
    <>
      <Head title={t("Dining")} />
      <Layout>
        <Box sx={root}>
          {showFilterBar && (
            <Box sx={filterSection}>
              <HorizontalScrollPane
                className="subCategory-filterbar"
                stackProps={{
                  sx: filterContainer,
                }}
              >
                <CategoryFilterBar
                  sx={filterButton}
                  activeCategory={currentCategory}
                  categories={categories}
                  onChange={handleChangeCategory}
                  renderFilterButton={CategoryFilterChip}
                />
              </HorizontalScrollPane>
            </Box>
          )}
          {restaurants.length > 0 ? (
            <Box sx={gridContainer}>
              {restaurants.map((restaurant) => {
                const {
                  group,
                  level,
                  logo,
                  featuredImage,
                  name,
                  isFeatured,
                  boothName,
                } = getFeatureDetail(restaurant) || {}
                const levelText = isMultiOrdinalVenue
                  ? t("Level Text", { level: t(level) })
                  : ""
                const groupText = t(group)
                const fallBackIcon = _.first(
                  restaurant?.properties?.local_parent_categories
                )?.properties?.icon_path
                const subcategory = _.first(
                  restaurant?.properties?.local_sub_categories
                )
                const subcategoryName = _.get(subcategory, "properties.name")
                return (
                  <ImageCard
                    key={restaurant?.id}
                    sx={diningImageCard}
                    component={Link}
                    to={`/maps/place/${restaurant?.id}`}
                    title={t(name)}
                    subheader={
                      <>
                        <Typography
                          component="span"
                          variant="subtitle"
                          className="sub-category-text"
                        >
                          {t(subcategoryName)}
                        </Typography>
                        {boothName && (
                          <Typography
                            component="span"
                            variant="subtitle"
                            className="booth-name-text"
                          >
                            {`${t("Booth")} ${t(boothName)}`}
                          </Typography>
                        )}

                        <Typography
                          className="location-text"
                          component="span"
                          variant="subtitle"
                        >
                          {_.compact([levelText, groupText]).join(" ")}
                        </Typography>
                      </>
                    }
                    image={featuredImage}
                    avatar={
                      <Avatar src={logo} sx={diningAvatar}>
                        <CustomIcon
                          path={fallBackIcon}
                          sx={diningAvatarFallBack}
                        />
                      </Avatar>
                    }
                    tag={isFeatured ? t("Highlight") : null}
                  />
                )
              })}
            </Box>
          ) : (
            <Box sx={emptyDataContainer}>
              <EmptyData
                icon={emptyIcon}
                primary={t("Coming soon")}
                secondary={t("Stay tuned for the update")}
              />
            </Box>
          )}
          {isMultiVenueProject && (
            <Box sx={venueSelector}>
              <VenueSelector
                selectedVenue={venueFilter}
                venues={venues}
                onClickVenue={filterByVenueId}
                currentVenue={locationVenueId}
              />
            </Box>
          )}
        </Box>
      </Layout>
    </>
  )
}
