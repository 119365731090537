import React, { useCallback } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import _ from "lodash"
import { useTheme, Box } from "@mui/material"

import { useMap } from "hooks/map/useMap"
import { useOrdinalMap } from "hooks/ordinal/useOrdinalMap"
import { useTranslation } from "providers/i18n"
import { useAppUI } from "providers/ui"
import { useVenue } from "providers/venue"
import { pluralize } from "utils/string"

import { FeatureChipToggleBar } from "components/domains/features/ChipToggleBar"
import { PageCard } from "components/card"
import { NavigationControl } from "components/kioskHorizontal/navigation"
import { SearchToggleButton } from "components/domains/search/SearchToggleButton"
import { VenueSelector } from "components/VenueSelector"
import { OrdinalSelectMenu } from "components/OrdinalSelector/OrdinalSelectMenu"
import { AppNavigation } from "containers/kiosk-h/AppNavigation"

export const MapOrdinalKioskHPage = ({ Layout = Box }) => {
  const navigate = useNavigate()
  const { ordinal } = useParams()
  const { search: searchQueryString } = useLocation()
  const { t } = useTranslation()
  const { isMultiOrdinalVenue, isMultiVenueProject, venues } = useVenue()
  const {
    viewingVenue,
    viewingVenueId,
    levelInVenue,
    quickSearchCategories,
    onChangeVenue,
    locationVenueId,
  } = useMap()

  const { openSearch, isKioskMode } = useAppUI()

  const parsedOrdinal = Number(ordinal)

  useOrdinalMap(parsedOrdinal, viewingVenue)

  const theme = useTheme()
  const { styleOverrides = {} } = theme.components[`AppMap`] || {}
  const { styleOverrides: bannerStyleOverrides = {} } =
    theme.components[`AppBanner`] || {}
  const sideBarStyleOverrides =
    theme.components[`AppSidebar`]?.styleOverrides || {}

  const {
    searchBarContainer,
    toolbarContainer,
    quickLinkContainer, //TODO:: planning about quick search's working flow later
    venueSelector,
    ordinalSelectMenu,
  } = styleOverrides

  const { root } = bannerStyleOverrides
  const { wrapper, body, footer } = sideBarStyleOverrides

  const onClickOrdinal = useCallback(
    (ordinal) => {
      navigate(
        `/maps/${
          ordinal !== null ? `ordinal/${ordinal}${searchQueryString}` : ""
        }`,
        { replace: true }
      )
    },
    [navigate, searchQueryString]
  )

  const handleClickQuickSearch = useCallback(
    (type, category) => {
      //This condition prevent navigation when ordinal or viewingVenueId is undefined
      if (_.isNil(ordinal) || _.isNil(viewingVenueId)) return
      const pluralType = pluralize(type)
      navigate(
        `/maps/${pluralType}/${category}?from-ordinal=${ordinal}&venue=${viewingVenueId}`
      )
    },
    [navigate, ordinal, viewingVenueId]
  )

  return (
    <Layout
      aside={
        <PageCard>
          <Box sx={wrapper}>
            <Box sx={root}></Box>
            <Box sx={body}>
              <AppNavigation />
            </Box>
            <Box sx={footer}>
              <NavigationControl BackButtonProps={{ hidden: true }} />
            </Box>
          </Box>
        </PageCard>
      }
    >
      <Box sx={toolbarContainer}>
        {!isKioskMode && (
          <Box sx={searchBarContainer}>
            <SearchToggleButton
              placeholder={t("Search for your destination")}
              onClick={openSearch}
            />
          </Box>
        )}
        <Box sx={quickLinkContainer}>
          <FeatureChipToggleBar
            categories={quickSearchCategories}
            onClick={handleClickQuickSearch}
          />
        </Box>
      </Box>
      {isMultiOrdinalVenue && (
        <Box sx={ordinalSelectMenu}>
          <OrdinalSelectMenu
            selectedOrdinal={parsedOrdinal}
            levels={levelInVenue}
            onChangeOrdinal={onClickOrdinal}
          />
        </Box>
      )}
      {isMultiVenueProject && (
        <Box sx={venueSelector}>
          <VenueSelector
            selectedVenue={viewingVenueId}
            venues={venues}
            onClickVenue={onChangeVenue}
            currentVenue={locationVenueId}
          />
        </Box>
      )}
    </Layout>
  )
}
