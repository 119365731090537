import { DEFAULT_SEARCH_LIST_ITEM_SECONDARY_TEXT_KEY } from "constant"
import _ from "lodash"

type PrepareSearchResultSecondaryText = (
  categoryText?: string,
  boothNameText?: string,
  floorText?: string,
  groupText?: string,
  zoneText?: string,
  secondaryTextKeyArray?: string[]
) => string

export const prepareSearchResultSecondaryText: PrepareSearchResultSecondaryText =
  (
    categoryText,
    boothNameText,
    floorText,
    groupText,
    zoneText,
    secondaryTextKeys = DEFAULT_SEARCH_LIST_ITEM_SECONDARY_TEXT_KEY
  ) => {
    const secondaryData = {
      categoryText,
      boothNameText,
      floorText,
      groupText,
    }
    const pickedSecondaryDataValues = secondaryTextKeys.map((key) =>
      _.get(secondaryData, key)
    )
    const compactedSecondaryValue = _.compact(pickedSecondaryDataValues).join(
      " - "
    )
    const secondaryText = _.compact([compactedSecondaryValue, zoneText]).join(
      " | "
    )

    return secondaryText
  }
