import React from "react"

import { MobileNavigation } from "components/navigation"
import { useAppUI } from "providers/ui"

export const AppNavigation = () => {
  const { mobileNavigations, mobileMainNavigations, matchedPath } = useAppUI()

  return (
    <MobileNavigation
      value={matchedPath?.to}
      bottomNavigations={mobileMainNavigations}
      navigations={mobileNavigations}
    />
  )
}
