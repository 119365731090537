import React, { useMemo } from "react"
import {
  Box,
  Typography,
  Skeleton,
  useTheme,
  CardMedia,
  Button,
  Avatar,
} from "@mui/material"
import { deepmerge } from "@mui/utils"

import { createOpeningHours } from "utils/date"
import { useTranslation } from "providers/i18n"

import { CustomIcon } from "components/icons"
import {
  ClockIcon,
  LayerIcon,
  ShopIcon,
} from "extensions/one-siam/components/icons"

const LoadingFeature = () => {
  const theme = useTheme()
  const {
    root,
    titleContainer,
    actionContainer,
    headerContainer,
    infoContainer,
    infoList,
    infoListItem,
  } = theme.components[`FeatureSummary`]?.styleOverrides || {}
  return (
    <Box sx={root}>
      <Box sx={headerContainer}>
        <Box sx={titleContainer}>
          <Skeleton variant="rectangular" width={32} height={32} />
          <Skeleton variant="text" width={120} />
        </Box>
        <Box sx={actionContainer}>
          <Skeleton variant="rounded" width={74} height={32} />
        </Box>
      </Box>

      <Box sx={infoContainer}>
        <Box sx={infoList}>
          <Box sx={infoListItem}>
            <Skeleton variant="text" width={70} />
          </Box>
          <Box sx={infoListItem}>
            <Skeleton variant="text" width={115} />
          </Box>
          <Box sx={infoListItem}>
            <Skeleton variant="text" width={115} />
          </Box>
        </Box>
        <Skeleton variant="rounded" width={60} height={40} />
      </Box>
    </Box>
  )
}

export const FeatureSummary = ({
  zone,
  logo,
  venueLogo,
  category,
  phone,
  level,
  name,
  hours,
  id,
  loading,
  group,
  onNavigate,
  categoryIconProp = {},
  sx = {},
  ...props
}) => {
  const { t } = useTranslation({ defaultValue: null })
  const {
    close = "",
    open = "",
    isValid,
  } = useMemo(() => createOpeningHours(hours), [hours])

  const theme = useTheme()
  const {
    root,
    title,
    titleContainer,
    venueLogoContainer,
    venueLogoImage,
    logoContainer,
    logoImage,
    categoryIcon,
    actionContainer,
    navigationButton,
    headerContainer,
    infoContainer,
    infoList,
    infoListItem,
    infoText,
    iconWrapper,
  } = theme.components[`FeatureSummary`]?.styleOverrides || {}

  const categoryText = t(category)
  const levelText = level ? t("Level Text", { level: t(level) }) : "-"
  const nameText = t(name)
  const openingHoursText = isValid ? `${open} - ${close}` : "-"
  const { iconPath, image } = categoryIconProp

  if (loading) return <LoadingFeature />

  return (
    <Box sx={deepmerge(root, sx)} {...props}>
      <Box sx={headerContainer}>
        <Box sx={titleContainer}>
          <Box sx={logoContainer}>
            <Avatar sx={logoImage} src={logo || image}>
              {iconPath ? (
                <CustomIcon path={iconPath} sx={categoryIcon} />
              ) : (
                <ShopIcon />
              )}
            </Avatar>
          </Box>
          <Typography variant="title" component="h3" sx={title}>
            {nameText}
          </Typography>
        </Box>
        <Box sx={actionContainer}>
          <Button
            variant="contained"
            onClick={onNavigate}
            sx={navigationButton}
          >
            {t("Get direction")}
          </Button>
        </Box>
      </Box>
      <Box sx={infoContainer}>
        <Box sx={infoList}>
          <Box sx={infoListItem}>
            <Typography variant="subtitle2" sx={infoText}>
              {categoryText}
            </Typography>
          </Box>
          <Box sx={infoListItem}>
            <Box sx={iconWrapper}>
              <LayerIcon />
            </Box>
            <Typography variant="subtitle2" sx={infoText}>
              {levelText}
            </Typography>
          </Box>
          <Box sx={infoListItem}>
            <Box sx={iconWrapper}>
              <ClockIcon />
            </Box>
            <Typography variant="subtitle2" sx={infoText}>
              {openingHoursText}
            </Typography>
          </Box>
        </Box>
        {venueLogo && (
          <Box sx={venueLogoContainer}>
            <CardMedia component="img" image={venueLogo} sx={venueLogoImage} />
          </Box>
        )}
      </Box>
    </Box>
  )
}
