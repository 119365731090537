import { useCallback, useEffect, useState } from "react"
import { Socket, io } from "socket.io-client"

import config from "config"
import _ from "lodash"

const SERVER_URL = config("host")
const apiKey = config("realtimeApiKey")
const { origin } = new URL(SERVER_URL)

const echoerSocket = io(`${origin}/echoer`, {
  path: `/ws`,
  auth: {
    token: apiKey,
  },
  autoConnect: false,
  reconnection: Boolean(apiKey),
})

export interface INotification {
  id: string
  title: string
  body?: string
  extras: {
    [k: string]: any
  }
  image: {
    [k: string]: any
  }
}

export interface IUseNotificationSocketIOResult {
  isConnected: boolean
  echoerSocket: Socket
  newNotification: INotification
  historyNotifications: INotification[]
  subscribe: (event: any, callback: any) => void
  unsubscribe: (event: any, callback: any) => void
  moveNotificationToHistory: (notification: INotification) => void
}

export type UseNotificationSocketIO = (options?: {
  enable?: boolean
}) => IUseNotificationSocketIOResult

const LIMIT_HISTORY = 5
export const useNotificationSocketIO: UseNotificationSocketIO = ({
  enable = true,
} = {}) => {
  const [isConnected, setIsConnected] = useState(echoerSocket.connected)
  const [newNotification, setNewNotification] = useState<INotification>()
  const [historyNotifications, setHistoryNotifications] = useState<
    INotification[]
  >([])

  const subscribe = useCallback((event, callback) => {
    echoerSocket.on(event, callback)
  }, [])

  const unsubscribe = useCallback((event, callback) => {
    echoerSocket.off(event, callback)
  }, [])

  const moveNotificationToHistory = useCallback((notification) => {
    if (notification) {
      setHistoryNotifications((prevNotifications) =>
        _.slice(
          [...prevNotifications, notification],
          prevNotifications.length === LIMIT_HISTORY ? 1 : 0
        )
      )
      setNewNotification(null)
    }
  }, [])

  useEffect(() => {
    const onConnect = () => {
      setIsConnected(true)
      console.log(`[NotificationSocketIO]: connected`)
    }

    const onDisconnect = () => {
      setIsConnected(false)
      console.log(`[NotificationSocketIO]: disconnected`)
    }

    const onConnectError = () => {
      console.warn(
        '[NotificationSocketIO] Fail to connect realtime service, please check if REACT_APP_REALTIME_API_KEY exists and support "realtime"'
      )
    }

    const onNotificationUpdate = (newNotification: INotification) => {
      setNewNotification(newNotification)
    }

    echoerSocket.on("connect", onConnect)
    echoerSocket.on("connect_error", onConnectError)
    echoerSocket.on("disconnect", onDisconnect)

    // Listening to event from server
    echoerSocket.on("notification:update", onNotificationUpdate)

    return () => {
      echoerSocket.off("connect", onConnect)
      echoerSocket.off("connect_error", onConnectError)
      echoerSocket.off("disconnect", onDisconnect)

      echoerSocket.off("notification:update", onNotificationUpdate)
    }
  }, [])

  useEffect(() => {
    if (!enable) {
      echoerSocket.disconnect()
    } else {
      echoerSocket.connect()
    }
  }, [enable])

  return {
    isConnected,
    echoerSocket,
    newNotification,
    historyNotifications,
    subscribe,
    unsubscribe,
    moveNotificationToHistory,
  }
}
