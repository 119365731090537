import { Box, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"

export const PlaceDetailTabPanel = ({
  children,
  value,
  index,
  sx = {},
  ...props
}) => {
  const theme = useTheme()
  const styleOverrides =
    theme.components[`PlaceDetailTabPanel`]?.styleOverrides || {}
  const { root } = styleOverrides
  return (
    <Box
      sx={deepmerge(root, sx)}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {children}
    </Box>
  )
}
