import { useMemo } from "react"
import { Box, useTheme } from "@mui/material"

import { getIcon } from "utils/icon"

import { useTranslation } from "providers/i18n"

import { Head } from "components/Head"
import { EmptyData } from "components/EmptyData"
import { MotionDiv, showContent } from "components/motions"

import {
  Header,
  HeaderMenuButton,
  HeaderSearchButton,
} from "extensions/one-siam/components/Header"
import { PageLayout } from "extensions/one-siam/layouts/PageLayout"
import { AppNavigation } from "extensions/one-siam/container/AppNavigation"
import { useService } from "extensions/one-siam/hooks/useService"
import { ServiceIcon } from "extensions/one-siam/components/icons"
import { ServiceCard } from "extensions/one-siam/components/domains/service/ServiceCard"

export const ServicePage = ({ Layout = PageLayout }) => {
  const { t } = useTranslation({ defaultValue: null })
  const { services, hasData } = useService()

  const theme = useTheme()

  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.service) || <ServiceIcon />,
    [theme]
  )

  const {
    root,
    gridItem,
    gridContainer,
    emptyDataContainer,
    headerWrapper,
    navigationWrapper,
  } = theme.components?.[`ServicePage`]?.styleOverrides || {}

  return (
    <>
      <Head title={t("Service & Privilege")} />
      <Layout
        header={
          <Box sx={headerWrapper}>
            <Header
              title={t("Service & Privilege")}
              menu={<HeaderMenuButton />}
              search={<HeaderSearchButton />}
            />
          </Box>
        }
      >
        <MotionDiv
          variants={showContent}
          initial="initial"
          animate="animate"
          sx={root}
        >
          {hasData ? (
            <Box sx={gridContainer}>
              {services.map((service, index) => (
                <ServiceCard
                  sx={gridItem}
                  key={`service-item-${index}`}
                  data={service}
                />
              ))}
            </Box>
          ) : (
            <Box sx={emptyDataContainer}>
              <EmptyData
                icon={emptyIcon}
                primary={t("Coming soon")}
                secondary={t("Stay tuned for the update")}
              />
            </Box>
          )}
        </MotionDiv>
        <Box sx={navigationWrapper}>
          <AppNavigation />
        </Box>
      </Layout>
    </>
  )
}
