import React, { useState, useEffect, useCallback, useRef, useMemo } from "react"
import _ from "lodash"
import { Box, Avatar, List, useTheme } from "@mui/material"
import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import { DirectionSearch } from "components/domains/direction/DirectionSearch"
import { FeatureListItem } from "../components/domains/features/ListItem"
import {
  CustomIcon,
  KioskIcon,
  ScanQRCodeIcon,
  ShopIcon,
} from "../components/icons"
import { listItemPropsParserFactory } from "utils/feature"
import { prepareSearchResultSecondaryText } from "utils/search"

const ListItemAvatar = ({ icon, path, ...props }) => {
  return (
    <Avatar variant="logo" {...props}>
      {icon ? icon : path ? <CustomIcon path={path} /> : <ShopIcon />}
    </Avatar>
  )
}

const SuggestionListItem = ({ listItemProps, onClick }) => {
  const {
    lastScanPrimaryText,
    lastScanSecondaryText,
    categoryText,
    floorText,
    zoneText,
    boothNameText,
    groupText,
    name,
    avatarProps,
    venueLogo,
    venueRef,
  } = listItemProps
  const { t } = useTranslation({ defaultValue: null })
  const theme = useTheme()
  const { defaultProps = {}, styleOverrides = {} } =
    theme.components[`SuggestionListItem`] || {}
  const { root } = styleOverrides
  const primaryText = useMemo(
    () => lastScanPrimaryText || t(name),
    [lastScanPrimaryText, name, t]
  )

  //Prepare secondary text
  const { secondaryTextKeys } = defaultProps

  const secondaryText = useMemo(
    () =>
      lastScanSecondaryText ||
      prepareSearchResultSecondaryText(
        categoryText,
        boothNameText,
        floorText,
        zoneText,
        groupText,
        secondaryTextKeys
      ),
    [
      categoryText,
      boothNameText,
      floorText,
      zoneText,
      groupText,
      secondaryTextKeys,
      lastScanSecondaryText,
    ]
  )

  return (
    <FeatureListItem
      className={venueRef}
      divider
      primary={primaryText}
      secondary={secondaryText}
      onClick={onClick}
      avatar={<ListItemAvatar {...avatarProps} />}
      sx={root}
      endAdornment={venueLogo}
    />
  )
}

const WAIT_TIME = 400

export const DirectionSearchContainer = (props) => {
  const {
    origin,
    destination,
    onBack = () => {},
    onChange = () => {},
    userLocation = null,
    lastScannedLocation = null,
  } = props
  const { t } = useTranslation({ defaultValue: "" })
  const theme = useTheme()
  const styleOverrides =
    theme.components[`DirectionSearchContainer`]?.styleOverrides || {}
  const { wrapper, suggestionList } = styleOverrides

  const styleUserLocationListItem = useMemo(
    () => theme.components[`UserLocationListItem`]?.styleOverrides || {},
    [theme]
  )

  const {
    search,
    getSuggestedSearchItems,
    isMultiOrdinalVenue,
    isMultiVenueProject,
  } = useVenue()

  const [originValue, setOriginValue] = useState("")
  const [destinationValue, setDestinationValue] = useState("")
  const [focusingInput, setFocusingInput] = useState(null)

  const resultParser = listItemPropsParserFactory({
    t,
    hideLevel: !isMultiOrdinalVenue,
    hideVenueLogo: !isMultiVenueProject,
  })

  const originSuggestions = useMemo(() => {
    const features = !_.isEmpty(originValue)
      ? search(originValue)
      : getSuggestedSearchItems()
    return features.map(resultParser)
  }, [originValue, search, getSuggestedSearchItems, resultParser])

  const destinationSuggestions = useMemo(() => {
    const features = !_.isEmpty(destinationValue)
      ? search(destinationValue)
      : getSuggestedSearchItems()
    return features.map(resultParser)
  }, [destinationValue, search, getSuggestedSearchItems, resultParser])

  const userLocations = useMemo(() => {
    // TODO:: move this logic handle by listItemPropsParserFactory function (TBD)
    return _.compact([
      userLocation && {
        id: userLocation?.id,
        primary: t("Your Location"),
        secondary: t("Location of area that you scan QR code"),
        avatarProps: {
          sx: styleUserLocationListItem.icon,
          icon: <KioskIcon />,
        },
      },
      lastScannedLocation && {
        id: lastScannedLocation?.id,
        lastScanPrimaryText: t("Last Scanned"),
        lastScanSecondaryText: t("Your previous location"),
        avatarProps: {
          sx: styleUserLocationListItem.icon,
          icon: <ScanQRCodeIcon />,
        },
      },
    ])
  }, [userLocation, lastScannedLocation, t, styleUserLocationListItem])

  const showUserLocationSuggestion =
    userLocations &&
    userLocations.length > 0 &&
    ((focusingInput === "origin" && originValue === "") ||
      (focusingInput === "destination" && destinationValue === ""))

  const onSelectSuggestion = (target, location) => {
    onChange({
      // Original value
      origin,
      destination,
      // Override with selected value
      [target]: location,
    })
    setFocusingInput(null)
  }

  const handleSwitch = useCallback(() => {
    onChange({
      origin: destination,
      destination: origin,
    })
  }, [onChange, origin, destination])

  const originInputRef = useRef()
  const destinationInputRef = useRef()

  const debounceOriginOnChange = useMemo(
    () =>
      _.debounce((value) => {
        setOriginValue(value)
      }, WAIT_TIME),
    []
  )

  const debounceDestinationOnChange = useMemo(
    () =>
      _.debounce((value) => {
        setDestinationValue(value)
      }, WAIT_TIME),
    []
  )

  const handleChangeOrigin = useCallback(
    (e) => {
      const newValue = e.target.value
      originInputRef.current.value = newValue
      debounceOriginOnChange(newValue)
    },
    [debounceOriginOnChange]
  )

  const handleChangeDestination = useCallback(
    (e) => {
      const newValue = e.target.value
      destinationInputRef.current.value = newValue
      debounceDestinationOnChange(newValue)
    },
    [debounceDestinationOnChange]
  )
  const handleClearOrigin = useCallback(
    (e) => {
      originInputRef.current.value = ""
      setOriginValue("")
      onChange({
        origin: "",
        destination,
      })
    },
    [onChange, destination]
  )

  const handleClearDestination = useCallback(
    (e) => {
      destinationInputRef.current.value = ""
      setDestinationValue("")
      onChange({
        origin,
        destination: "",
      })
    },
    [onChange, origin]
  )

  const originSuggestionAvialable =
    originSuggestions.length > 0 && focusingInput === "origin"
  /**
   * Set originValue|destinationValue with origin's|destination's name
   * Note: Add focusingInput to handle case that input doesnt update when chose the old suggest choice
   */
  useEffect(() => {
    const originValue = t(origin?.name)
    setOriginValue(originValue)
    originInputRef.current.value = originValue
  }, [origin, focusingInput, t])

  useEffect(() => {
    const destinationValue = t(destination?.name)
    setDestinationValue(destinationValue)
    destinationInputRef.current.value = destinationValue
  }, [destination, focusingInput, t])

  return (
    <Box sx={wrapper}>
      <DirectionSearch
        originInputRef={originInputRef}
        destinationInputRef={destinationInputRef}
        onOriginChange={handleChangeOrigin}
        onDestinationChange={handleChangeDestination}
        onOriginFocus={() => setFocusingInput("origin")}
        onDestinationFocus={() => setFocusingInput("destination")}
        onBack={onBack}
        onSwitch={handleSwitch}
        onClearOrigin={handleClearOrigin}
        onClearDestination={handleClearDestination}
      />

      {(originSuggestionAvialable || showUserLocationSuggestion) && (
        <List sx={suggestionList}>
          {showUserLocationSuggestion &&
            userLocations.map((listItemProps) => (
              <SuggestionListItem
                key={listItemProps.id}
                listItemProps={listItemProps}
                onClick={() => onSelectSuggestion(focusingInput, listItemProps)}
              />
            ))}
          {originSuggestionAvialable &&
            originSuggestions.map((listItemProps) => (
              <SuggestionListItem
                key={listItemProps.id}
                listItemProps={listItemProps}
                onClick={() => onSelectSuggestion(focusingInput, listItemProps)}
              />
            ))}
        </List>
      )}
      {destinationSuggestions.length > 0 && focusingInput === "destination" && (
        <List sx={suggestionList}>
          {destinationSuggestions.map((listItemProps) => (
            <SuggestionListItem
              key={listItemProps.id}
              listItemProps={listItemProps}
              onClick={() => onSelectSuggestion(focusingInput, listItemProps)}
            />
          ))}
        </List>
      )}
    </Box>
  )
}
