import React from "react"

import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
} from "@mui/material"

const noBoxShadow = {
  boxShadow: "none",
}

const titleOverflow = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}

export const OccupantPictureCard = (props) => {
  const {
    maxWidth,
    title = "Restaurant",
    subtitle,
    isShadow = true,
    imageUrl = "https://loremflickr.com/320/240",
    imageHeight = 140,
  } = props

  const boxShadowStyle = isShadow ? {} : noBoxShadow

  return (
    <Card sx={{ ...boxShadowStyle, maxWidth: maxWidth }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height={imageHeight}
          image={imageUrl}
          alt={title}
        />
        <CardContent>
          <Typography variant="h6" component="div" sx={titleOverflow}>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="body2" color="text.secondary">
              {subtitle}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
