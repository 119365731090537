import { useTranslation } from "providers/i18n"
import {
  Typography,
  CardMedia,
  CardContent,
  Box,
  useTheme,
  Card,
} from "@mui/material"
import { deepmerge } from "@mui/utils"

import dayjs from "config/dayjs"

import { getFeatureName } from "utils/feature"
import { getDisplayDateTime } from "utils/date"
import { getFeatureDetail } from "utils/feature"

import { LocationInfo } from "components/domains/features/LocationInfo"
import {
  CalendarIcon,
  LocationIcon,
} from "extensions/one-siam/components/icons"

export const FeatureCard = ({ feature, sx = {}, ...props }) => {
  const { t } = useTranslation()
  const { properties } = feature
  const startDate = dayjs(properties?.start_date)
  const endDate = dayjs(properties?.end_date)

  const theme = useTheme()
  const { styleOverrides } = theme.components?.[`FeatureCard`] || {}
  const {
    root,
    cardMedia,
    cardContent,
    titleText,
    infoItem,
    iconWrapper,
    infoContainer,
  } = styleOverrides
  const { location } = getFeatureDetail(feature)

  return (
    <Card sx={deepmerge(root, sx)} {...props}>
      <CardMedia
        sx={cardMedia}
        component="img"
        image={properties.cover_image?.url}
      />
      <CardContent sx={cardContent}>
        <Typography sx={titleText} variant="title" component="div">
          {t(getFeatureName(feature))}
        </Typography>
        <Box sx={infoContainer}>
          <Box
            sx={deepmerge(infoItem, {
              visibility: location ? "visible" : "hidden",
            })}
          >
            <Box sx={iconWrapper}>
              <LocationIcon />
            </Box>
            <LocationInfo feature={feature} variant="subtitle1" component="p" />
          </Box>
          <Box sx={infoItem}>
            <Box sx={iconWrapper}>
              <CalendarIcon />
            </Box>
            <Typography variant="subtitle1" component="p">
              {getDisplayDateTime(startDate, endDate)}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
