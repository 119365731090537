/**
 * Checks if a point is a valid coordinate pair.
 *
 * @param point - The point to be checked.
 * @return True if the point is a valid coordinate pair, false otherwise.
 */
export const isValidCoordinate = (point: [number, number]): boolean => {
  return point.length === 2 && point.every((coord) => typeof coord === "number")
}

/**
 * Checks if a set of points forms a valid linear ring (closed and simple polygon).
 *
 * @param ring - The array of points representing a linear ring.
 * @return True if the linear ring is valid, false otherwise.
 */
function isValidLinearRingCoordinates(ring: [number, number][]): boolean {
  if (ring.length < 4) {
    return false
  }

  return (
    ring.every(isValidCoordinate) &&
    ring[0][0] === ring[ring.length - 1][0] &&
    ring[0][1] === ring[ring.length - 1][1]
  )
}

/**
 * Checks if an input represents a valid polygon, which may include holes.
 * The input can be a single array of coordinates for simple polygons, or
 * an array of arrays of coordinates for polygons with holes.
 *
 * @param polygon - The input representing a polygon.
 * @return True if the polygon is valid, false otherwise.
 */
export const isValidPolygonCoordinates = (
  polygon: [number, number][] | [number, number][][]
): boolean => {
  // Check if it's a simple polygon (without holes)
  if (
    Array.isArray(polygon[0]) &&
    (polygon[0].length === 0 || typeof polygon[0][0] === "number")
  ) {
    return isValidLinearRingCoordinates(polygon as [number, number][])
  }

  // Check if it's a polygon with holes
  if (
    Array.isArray(polygon) &&
    polygon.length > 0 &&
    Array.isArray(polygon[0])
  ) {
    // Check the outer ring
    if (!isValidLinearRingCoordinates(polygon[0] as [number, number][])) {
      return false
    }

    // Check each hole
    for (let i = 1; i < polygon.length; i++) {
      if (!isValidLinearRingCoordinates(polygon[i] as [number, number][])) {
        return false
      }
    }

    return true
  }

  return false
}

/**
 * Checks if an array of polygons forms a valid MultiPolygon.
 *
 * @param multipolygon - The array of polygons.
 * @return True if the multipolygon is valid, false otherwise.
 */
export const isValidMultiPolygonCoordinates = (
  multipolygon: [number, number][][][]
): boolean => {
  return multipolygon.every(isValidPolygonCoordinates)
}

/**
 * Checks if a set of points forms a valid LineString.
 * A valid LineString must have at least two points, each point must be a valid coordinate,
 * and it should not form a closed loop.
 *
 * @param lineString - The array of points representing a LineString.
 * @return True if the LineString is valid, false otherwise.
 */
export const isValidLineStringCoordinates = (
  lineString: [number, number][]
): boolean => {
  if (!Array.isArray(lineString) || lineString.length < 2) {
    return false
  }

  // Check if the first and last points are the same, which would make it a polygon
  const firstPoint = lineString[0]
  const lastPoint = lineString[lineString.length - 1]
  if (firstPoint[0] === lastPoint[0] && firstPoint[1] === lastPoint[1]) {
    return false
  }

  return lineString.every(isValidCoordinate)
}
