import axios from "../config/connections"
import { sendCaptureExceptionToSentry } from "../utils/sentry"
import config from "../config"
import { IGeometry } from "interfaces"

interface IFavIcon {
  size: string
  src: string
  type: string
}

interface IMenuConfig {
  to: string
  label: string
  order?: number
  icon_comp?: string
  icon_path?: string
  image?: string
  hidden?: boolean
}

interface IMapExtrudeConfig {
  height: number
  category: string
}

interface IMapLightConfig {
  color: string
  position?: number[]
  intensity: number
}

interface IMapConfig {
  default_zoom: number
  extrude: {
    kiosk: IMapExtrudeConfig[]
    unit: IMapExtrudeConfig[]
  }
  light: {
    ambientLight: IMapLightConfig
    directionalLight: IMapLightConfig
  }
}

interface ISponsorContentConfig {
  disable?: boolean
  limit?: number
  frequency?: number
}

interface IDeviceConfig {
  navigation: {
    main: IMenuConfig[]
    menus: IMenuConfig[]
  }
  search: {
    featureTypes: string[]
    key: {
      name: string
      weight: number
    }
  }
  autoplay?: {
    step: {
      delay: number
      enable: boolean
    }
  }
  sponsor: {
    [k: string]: ISponsorContentConfig
  }
  featureQuickSearchOrder: string[]
}

export interface IAppConfig {
  homeNavigations: IMenuConfig[]
  kiosk: IDeviceConfig
  kioskNavigations: IMenuConfig[]
  map: IMapConfig
  mobile: IDeviceConfig
}

interface IAppManifest {
  icons: IFavIcon[]
  name: string
  theme_color?: string
}

type Coordinate = [number, number]
type LinearRingCoordinates = Coordinate[]
type PolygonCoordinates = LinearRingCoordinates[]
type MultiPolygonCoordinates = PolygonCoordinates[]

interface IMapLabel {
  name: string
  geometry: {
    coordinates: PolygonCoordinates | MultiPolygonCoordinates
  }
  properties: Record<string, any>
}

interface IMapDecoration {
  name: string
  geometry: IGeometry
}

const get = async <T>(endpoint: string): Promise<T> => {
  try {
    const res = await axios.get(endpoint)
    if (res.status !== 200) {
      throw new Error()
    }
    return res.data as T
  } catch (err) {
    throw err.message
  }
}

export const fetchAppManifest = async () => {
  try {
    const path = `/manifest.json`
    const jsonRes = await get<IAppManifest>(path)
    return jsonRes
  } catch (error) {
    sendCaptureExceptionToSentry({
      error,
      level: "error",
      transactionName: `Failed to fetch data from /manifest.json endpoint.`,
    })

    // Return empty object if error
    return {}
  }
}

export const fetchSponsoredContent = async <T>() => {
  try {
    const path = `/sponsored-content.json`
    const jsonRes = await get<T>(path)
    return jsonRes
  } catch (error) {
    sendCaptureExceptionToSentry({
      error,
      level: "error",
      transactionName: `Failed to fetch data from /sponsored-content.json endpoint.`,
    })

    throw error
  }
}

export const fetchMapLabels = async () => {
  const path = `/labels.geojson`
  try {
    const jsonRes = await get<IMapLabel[]>(path)
    return jsonRes
  } catch (error) {
    sendCaptureExceptionToSentry({
      error,
      level: "error",
      transactionName: `Failed to fetch data from ${path} endpoint.`,
    })
    // Return empty array if error
    return { data: [] }
  }
}

export const fetchAppConfig = async () => {
  try {
    const path = `${config("host")}/delivery/config/${config("appConfigId")}`
    const jsonRes = await get<IAppConfig>(path)
    return jsonRes
  } catch (error) {
    sendCaptureExceptionToSentry({
      error,
      level: "error",
      transactionName: `Failed to fetch App config endpoint.`,
    })

    // Return null if error
    return null
  }
}

export const fetchMapDecorations = async () => {
  const path = `/elements`
  try {
    const jsonResponse = await get<IMapDecoration[]>(path)
    return jsonResponse
  } catch (error) {
    sendCaptureExceptionToSentry({
      error,
      level: "error",
      transactionName: `Failed to fetch data from ${path} endpoint.`,
    })
    return { data: [] }
  }
}
