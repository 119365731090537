import axios from "axios"
import config from "./index"

const ConnectionInstance = axios.create({
  baseURL: `${config("host")}/delivery/projects/${config("projectId")}`,
})

ConnectionInstance.interceptors.request.use(
  async (requestConfig) => {
    // Add api-key as query string for every the request
    requestConfig.params = {
      ...requestConfig.params,
      "api-key": config("apiKey"),
    }
    return requestConfig
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default ConnectionInstance
