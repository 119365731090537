import { Box, Paper, Skeleton, useTheme } from "@mui/material"

export const SkeletonPlaceDetail = () => {
  const theme = useTheme()
  const { styleOverrides = {} } = theme.components[`AppDetailPage`] || {}
  const {
    container,
    primarySection,
    secondarySection,
    contentContainer,
    // Tabs
    placeDetailTabContainer,
  } = styleOverrides

  return (
    <Paper sx={container} spacing={1}>
      <Box sx={primarySection}>
        <Box sx={contentContainer}>
          <Skeleton variant="rounded" width="100%" height="100%" />
        </Box>
        <Box sx={placeDetailTabContainer}>
          <Skeleton variant="rounded" width="100%" height="100%" />
        </Box>
      </Box>
      <Box sx={secondarySection}>
        <Skeleton variant="rounded" width="100%" height="100%" />
      </Box>
    </Paper>
  )
}
