import React, { useCallback, useMemo, useState } from "react"
import {
  Typography,
  Stack,
  Card,
  Box,
  Divider,
  useTheme,
  Button,
} from "@mui/material"
import { useTranslation } from "providers/i18n"
import { Carousel, CarouselItem } from "components/Carousel"
import { PromotionCard } from "../promotions"
import { ListPrivilegeItemCard } from "../privilege"
import { WebsiteIcon } from "components/icons"
import EmailIcon from "@mui/icons-material/Email"
import LocalPhoneIcon from "@mui/icons-material/LocalPhone"
import { Link } from "react-router-dom"
import { Image, ImageModal } from "components/Image"
import { HorizontalSlide } from "../events"
import _ from "lodash"
import config from "config"

export const FeatureDetail = ({ feature, loading, ...props }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const styleOverrides =
    theme.components[`AppFeatureDetail`]?.styleOverrides || {}
  const {
    root,
    section,
    galleryImage,
    divider,
    contactButton,
    sectionTitle,
    descriptionText,
    promotionCarouselItem,
    contactWrapper,
  } = styleOverrides
  const [openModal, setOpenModal] = useState(false)
  const [activeImage, setActiveImage] = useState()

  const {
    websiteLink,
    phone,
    email,
    promotions,
    description,
    gallery,
    privileges,
  } = props

  const isKioskMode = useMemo(
    () => ["kiosk-v", "kiosk-h"].includes(config("device")),
    []
  )

  const featurePromotions = useMemo(
    () =>
      promotions?.filter(({ properties }) =>
        _.includes(["store", "shop"], properties.category)
      ) || [],
    [promotions]
  )

  const handleClose = useCallback(() => {
    setOpenModal(false)
  }, [])

  const handleImage = useCallback((value) => {
    setActiveImage(value)
    setOpenModal(true)
  }, [])

  const handleClickContact = useCallback(
    (contact) => {
      if (isKioskMode) return
      window.open(contact)
    },
    [isKioskMode]
  )

  if (loading) return null

  return (
    <>
      <Card sx={root}>
        {gallery && (
          <Box sx={section}>
            <Carousel
              options={{
                fixedWidth: "182px",
                fixedHeight: "130px",
                perPage: 2,
                gap: 12,
                padding: { left: 20, right: 20 },
              }}
            >
              {gallery?.map((image, idx) => (
                <CarouselItem key={`${image.name}-${idx}`}>
                  <Image
                    onClick={() => handleImage(image.url)}
                    src={image.url}
                    alt={`${image.name}-${idx}`}
                    sx={galleryImage}
                  />
                </CarouselItem>
              ))}
            </Carousel>
          </Box>
        )}
        {description && (
          <>
            <Box sx={section}>
              <Typography component="h2" sx={sectionTitle}>
                {t("Description")}
              </Typography>
              <Typography variant="body2" sx={descriptionText}>
                {t(description)}
              </Typography>
            </Box>
            <Divider sx={divider} />
          </>
        )}
        {(websiteLink || phone || email) && (
          <>
            <Box sx={contactWrapper}>
              {websiteLink && (
                <Button
                  variant="text"
                  onClick={() => handleClickContact(websiteLink)}
                  startIcon={<WebsiteIcon />}
                  sx={contactButton}
                >
                  {websiteLink}
                </Button>
              )}
              {phone && (
                <Button
                  variant="text"
                  onClick={() => handleClickContact(`tel:${phone}`)}
                  startIcon={<LocalPhoneIcon />}
                  sx={contactButton}
                >
                  {phone}
                </Button>
              )}
              {email && (
                <Button
                  variant="text"
                  onClick={() => handleClickContact(`mailto:${email}`)}
                  startIcon={<EmailIcon />}
                  sx={contactButton}
                >
                  {email}
                </Button>
              )}
            </Box>
            <Divider sx={divider} />
          </>
        )}
        {featurePromotions?.length > 0 && (
          <>
            <Box sx={section}>
              <Typography component="h2" sx={sectionTitle}>
                {t("Promotions")}
              </Typography>
              <Stack spacing={2}>
                <HorizontalSlide>
                  {featurePromotions?.length > 0 &&
                    featurePromotions.map((featurePromotion) => (
                      <CarouselItem
                        key={featurePromotion.id}
                        className={featurePromotion.properties?.category}
                        sx={promotionCarouselItem}
                      >
                        <Link
                          to={`/promotions/${featurePromotion.id}?redirectTo=-1`}
                          key={featurePromotion.id}
                        >
                          <PromotionCard
                            promotion={featurePromotion}
                            dense={true}
                          />
                        </Link>
                      </CarouselItem>
                    ))}
                </HorizontalSlide>
              </Stack>
            </Box>
            <Divider sx={divider} />
          </>
        )}
        {privileges?.length > 0 && (
          <>
            <Box sx={section}>
              <Typography component="h2" sx={sectionTitle}>
                {t("Tourist Privileges")}
              </Typography>
              <Stack spacing={2}>
                {privileges.map((privilege) => (
                  <ListPrivilegeItemCard key={privilege.id} {...privilege} />
                ))}
              </Stack>
            </Box>
          </>
        )}
      </Card>
      <ImageModal
        open={openModal}
        imageSrc={activeImage}
        onClose={handleClose}
      />
    </>
  )
}
