import { useCallback, useMemo } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import _, { get } from "lodash"

import { useVenue } from "providers/venue"
import { useGeoLocation } from "providers/geolocation"

import { useFeatureQuickSearch } from "hooks/feature/useFeatureQuickSearch"

import { Q_VENUE } from "constant"

import { useVenueController } from "hooks/venue/useVenueController"

export const useMap = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { defaultVenue, isMultiVenueProject, levels, findFeatureById } =
    useVenue()
  const { locationVenue: userLocationVenue } = useGeoLocation()
  const locationVenueId = userLocationVenue?.id || defaultVenue?.id
  const { viewingVenue: viewingVenueId } = useVenueController(locationVenueId)
  const navigate = useNavigate()
  const { category } = useParams()
  const viewingOrdinal = useMemo(
    () => searchParams.get("ordinal"),
    [searchParams]
  )

  const comingFromOrdinal = useMemo(
    () => searchParams.get("from-ordinal"),
    [searchParams]
  )

  const { categories: quickSearchCategories, features: quickSearchFeatures } =
    useFeatureQuickSearch(category, viewingVenueId)

  const sortedLevels = useMemo(
    () => _.orderBy(levels, "properties.ordinal", "desc"),
    [levels]
  )

  const levelInVenue = useMemo(
    () =>
      // Temporary condition should later be changed to properties.venue.id when prepareFeature has an ability to bindVenue
      isMultiVenueProject
        ? sortedLevels.filter(
            (level) => get(level, "properties.venue_id") === viewingVenueId
          )
        : sortedLevels,
    [isMultiVenueProject, sortedLevels, viewingVenueId]
  )

  const findNearestOrdinal = useCallback(
    (viewingOrdinal) => {
      const parsedOrdinal = !_.isNil(viewingOrdinal)
        ? Number(viewingOrdinal)
        : 0
      let nearestOrdinal = parsedOrdinal
      const nearestFeature = _.minBy(quickSearchFeatures, (amenity) =>
        Math.abs(amenity.properties.ordinal - parsedOrdinal)
      )
      if (nearestFeature) {
        nearestOrdinal = nearestFeature.properties.ordinal
      }

      return nearestOrdinal
    },
    [quickSearchFeatures]
  )

  const onChangeVenue = useCallback(
    (venueId) => {
      searchParams.set(Q_VENUE, venueId)
      navigate(
        `/maps/ordinal/0${
          venueId !== null ? `?${searchParams.toString()}` : ""
        }`,
        {
          replace: true,
        }
      )
    },
    [navigate, searchParams]
  )
  const toggleQuickSearch = useCallback(
    (type, featureCategory) => {
      //This condition prevent navigation when viewingOrdinal or viewingVenueId is undefined
      if (_.isNil(viewingVenueId) || _.isNil(viewingOrdinal)) {
        return
      }
      if (featureCategory === category) {
        return navigate(
          `/maps/ordinal/${viewingOrdinal}?venue=${viewingVenueId}`
        )
      }
      navigate(
        `/maps/${type}/${featureCategory}?venue=${viewingVenueId}&from-ordinal=${viewingOrdinal}`
      )
    },
    [navigate, viewingOrdinal, category, viewingVenueId]
  )

  const viewingVenue = useMemo(
    () => findFeatureById(viewingVenueId),
    [viewingVenueId, findFeatureById]
  )

  return {
    locationVenueId,
    viewingVenue,
    viewingVenueId,
    sortedLevels,
    levelInVenue,
    quickSearchFeatures,
    quickSearchCategories,
    viewingOrdinal,
    searchParams,
    category,
    comingFromOrdinal,
    // Function
    findNearestOrdinal,
    onChangeVenue,
    setSearchParams,
    toggleQuickSearch,
  }
}
