import _ from "lodash"
import { PromotionData } from "providers/venue/types"

export const preparePromotion = (promotion, findOne): PromotionData => {
  const { feature_id, venue_id, local_category_ids = [] } = promotion.properties
  const feature = findOne(feature_id)
  const venue = findOne(venue_id)
  const localCategories = _(local_category_ids).map(findOne).compact().value()
  const [parentCategories, subCategories] = _.partition(
    localCategories,
    (cat) => cat?.properties.parent_id === null
  )

  return {
    ...promotion,
    properties: {
      ...promotion.properties,
      feature,
      venue,
      local_categories: localCategories,
      local_parent_categories: parentCategories,
      local_sub_categories: subCategories,
    },
  }
}
