import React from "react"
import InfoRoundedIcon from "@mui/icons-material/InfoRounded"
import { Box, Stack, Typography, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"

export const EmptyData = (props) => {
  const {
    icon = <InfoRoundedIcon />,
    primary = "",
    secondary = "",
    sx = {},
  } = props
  const theme = useTheme()
  const { styleOverrides } = theme.components?.[`EmptyData`] || {}
  const { root, primaryText, secondaryText, iconWrapper } = styleOverrides
  return (
    <Stack sx={deepmerge(root, sx)}>
      {icon && <Box sx={iconWrapper}>{icon}</Box>}
      {primary && (
        <Typography variant="title" sx={primaryText} component="div">
          {primary}
        </Typography>
      )}
      {secondary && (
        <Typography variant="subtitle2" sx={secondaryText} component="div">
          {secondary}
        </Typography>
      )}
    </Stack>
  )
}
