import { useCallback, useMemo } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "providers/i18n"

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  List,
  Typography,
  useTheme,
  Divider,
  Stack,
  Button,
} from "@mui/material"

import { useEventDetail } from "hooks/event/useEventDetail"

import { AppNavigation } from "containers/kiosk-v"

import { CalendarIcon, LocationIcon } from "components/icons"
import { Head } from "components/Head"
import { InfoListItem } from "components/ListItem"
import { LanguageButton } from "components/Language"
import { SearchToggleButton } from "components/domains/search"
import { LocationInfo } from "components/domains/features/LocationInfo"
import { getIcon } from "utils/icon"
import { getDurationDate } from "utils/date"
import { QRCode } from "components/QRCode"

import { useAppUI } from "providers/ui"
import { Carousel, CarouselItem } from "components/Carousel"

export const EventDetailKioskVPage = ({ Layout = Box }) => {
  const params = useParams()
  const { eventId } = params
  const { t } = useTranslation()
  const { event, eventDetailMobileUrl, navigateToLocation } =
    useEventDetail(eventId)
  const { goBack, openSearch } = useAppUI()

  const theme = useTheme()

  const calendarIcon = useMemo(
    () => getIcon(theme?.assets?.calendarIcon) || <CalendarIcon />,
    [theme]
  )
  const styleOverrides = theme.components[`VuiDetailCard`]?.styleOverrides || {}
  const {
    card,
    imageWithCarousel,
    infoListItem,
    cardContent,
    cardHeader,
    cardContentPrimaryText,
    cardContentSecondaryText,
    navigationWrapper,
    divider,
    infoContainer,
    qrCodeContainer,
    qrCode,
    qrcodePrimaryText,
    qrCodeSecondaryText,
    buttonContainer,
    button,
    cardContentTextWrapper,
    cardMediaWrapper,
    galleryCarouselWrapper,
    galleryCarousel,
    galleryCarouselItem,
    toolbarContainer,
    actionButton,
    actionContainer,
    searchBarContainer,
  } = styleOverrides

  const handleClickBack = useCallback(() => {
    goBack()
  }, [goBack])

  if (!event) return null

  return (
    <>
      <Head title={`${t(event.name)} (${t("Event Detail")})`} />
      <Layout>
        {!event ? null : (
          <Card elevation={0} sx={card}>
            <Box sx={cardMediaWrapper}>
              <CardMedia
                component="img"
                sx={imageWithCarousel}
                src={event.coverImage}
              />
              {event.gallery && (
                <Box sx={galleryCarouselWrapper}>
                  <Carousel
                    options={{
                      type: "fade",
                      padding: 0,
                      gap: 8,
                      pagination: true,
                      autoplay: true,
                    }}
                    sx={galleryCarousel}
                  >
                    {event.gallery.map(({ id, url }) => {
                      return (
                        <CarouselItem
                          key={`slide-${id}`}
                          sx={galleryCarouselItem}
                        >
                          <CardMedia
                            component="img"
                            sx={galleryCarouselItem}
                            src={url}
                          />
                        </CarouselItem>
                      )
                    })}
                  </Carousel>
                </Box>
              )}
            </Box>
            <CardHeader title={t(event.name)} sx={cardHeader} />
            <Divider sx={divider} />

            <Stack direction="row">
              <List sx={infoContainer}>
                <InfoListItem
                  sx={infoListItem}
                  icon={calendarIcon}
                  primary={getDurationDate(event.startDate, event.endDate)}
                  secondary={event.openingText}
                />
                {event.hasLocation && (
                  <InfoListItem
                    sx={infoListItem}
                    icon={<LocationIcon />}
                    primary={
                      <LocationInfo feature={event.feature} component="span" />
                    }
                    secondary={t(event.boothName)}
                  />
                )}
              </List>
              <Box sx={qrCodeContainer}>
                <QRCode sx={qrCode} url={eventDetailMobileUrl} />
                <Stack>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    sx={qrcodePrimaryText}
                  >
                    {t("Scan Here")}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="p"
                    sx={qrCodeSecondaryText}
                  >
                    {t("For Mobile")}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
            <Divider sx={divider} />

            <CardContent sx={cardContent}>
              <Box sx={cardContentTextWrapper}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={cardContentPrimaryText}
                >
                  {t("Event Detail")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={cardContentSecondaryText}
                  dangerouslySetInnerHTML={{
                    __html: event.descriptionText,
                  }}
                ></Typography>
              </Box>
              <Box sx={buttonContainer}>
                <Button
                  variant="outlined"
                  sx={button}
                  onClick={handleClickBack}
                >
                  {t("Back")}
                </Button>
                {event.hasLocation && (
                  <Button
                    variant="contained"
                    sx={button}
                    onClick={navigateToLocation}
                  >
                    {t("View Location")}
                  </Button>
                )}
              </Box>
            </CardContent>
          </Card>
        )}
        <Box key="app-navigation" sx={navigationWrapper}>
          <Box sx={toolbarContainer}>
            <Box sx={actionContainer}>
              <LanguageButton variant="contained" sx={actionButton} />
              {/* //TODO:: Add a wheel chair's mode handler function later */}
              {/*  <Button
            variant="contained"
            sx={actionButton}
            onClick={() => {}}
            startIcon={<RestroomWheelchairIcon />}
          /> */}
            </Box>
            <Box sx={searchBarContainer}>
              <SearchToggleButton
                placeholder={t("Search shops and more...")}
                onClick={openSearch}
              />
            </Box>
          </Box>
          <AppNavigation />
        </Box>
      </Layout>
    </>
  )
}
