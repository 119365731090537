import _ from "lodash"
import { getFeatureDetail } from "utils/feature"
import { VERTICAL_TRAVERSAL_CATEGORY } from "constant"

export const createDescription = (
  comingFrom,
  goingTo,
  intermediary,
  nearestLandmark,
  isLastStep?: boolean
) => {
  if (
    (intermediary.every((unit) => unit.properties.category === "escalator") ||
      intermediary.every((unit) => unit.properties.category === "stairs") ||
      intermediary.every((unit) => unit.properties.category === "elevator")) &&
    comingFrom.properties.ordinal !== goingTo.properties.ordinal
  ) {
    const [intermediate] = intermediary
    return {
      text:
        comingFrom.properties.ordinal < goingTo.properties.ordinal
          ? "To upper level"
          : "To lower level",
      options: {
        category: `${intermediate.feature_type}.${intermediate.properties.category}`,
        destination:
          goingTo.properties?.level?.properties.name ||
          goingTo.properties?.level_name,
      },
    }
  }

  if (isLastStep) {
    return {
      text: "To destination",
    }
  }

  if (goingTo.feature_type === "occupant") {
    return {
      text: "To traverse",
      options: {
        destination: _.get(getFeatureDetail(goingTo), "name"),
      },
    }
  }
  if (
    goingTo.feature_type === "unit" &&
    VERTICAL_TRAVERSAL_CATEGORY.includes(goingTo.properties.category)
  ) {
    return {
      text: "To traverse with landmark",
      options: {
        category: `${goingTo.feature_type}.${goingTo.properties.category}`,
        landmark: _.get(getFeatureDetail(nearestLandmark), "name"),
      },
    }
  }
  return {
    text: "To traverse",
    options: {
      destination: _.get(getFeatureDetail(goingTo), "name"),
    },
  }
}
