import symbolLayout from "./symbol"

/**
 * Layout: English
 */
const layout = {
  default: [
    "q w e r t y u i o p",
    "a s d f g h j k l",
    "{shift} z x c v b n m {bksp}",
    "{number} {lang} {space} {enter}",
  ],
  shift: [
    "Q W E R T Y U I O P",
    "A S D F G H J K L",
    "{shift} Z X C V B N M {bksp}",
    "{number} {lang} {space} {enter}",
  ],
  ...symbolLayout,
}
export default layout
