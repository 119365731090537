import { useMemo, useCallback } from "react"
import {
  Box,
  Divider,
  List,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material"

import { useDirectory } from "hooks"
import { getIcon } from "utils/icon"
import { useTranslation } from "providers/i18n"

import { PageLayout } from "layouts/PageLayout"

import { EmptyData } from "components/EmptyData"
import { VenueSelector } from "components/VenueSelector"
import { HorizontalScrollPane } from "components/HorizontalScrollPane"
import { LevelFilterBar, ShopListItem } from "components/domains/directory"
import { Head } from "components/Head"
import { StoreIcon } from "components/icons"
import {
  CategoryFilterBar,
  CategoryFilterChip,
} from "components/CategoryFilterBar"

export const Directory = ({ Layout = PageLayout }) => {
  const { t } = useTranslation()

  const {
    handleChangeParentCategory,
    handleChangeSubCategory,
    handleChangeOrdinal,
    handleSelectLevelChange,
    changeVenueFilter,
    resetFilter,
    filteredOccupantsSorted,
    filteredLevels,
    sortedParentCategoryWithOccupant,
    subCategoryWithActiveParentCategory,
    parentCategoryId,
    subCategoryId,
    ordinalFilter,
    venueFilter,
    levelOptions,
    venueOptions,
    defaultVenueFilterValue,
    ref,
    showSubCategoryFilter,
    venues,
    isMultiOrdinalVenue,
    isMultiVenueProject,
    locationVenueId,
  } = useDirectory()
  const theme = useTheme()
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.directory) || <StoreIcon />,
    [theme]
  )
  const styleOverrides = theme.components[`DirectoryPage`]?.styleOverrides || {}
  const {
    root,
    filterSection,
    scrollPane,
    categoryFilterBar,
    subCategoryFilterBar,
    list,
    divider,
    buildingFilterSelect,
    levelFilterSelect,
    buildingWithLevelFilterSection,
    selectMenuItem,
    resetButton,
    emptyDataWrapper,
    venueSelector,
  } = styleOverrides

  const disableFloorSelect = venueFilter === defaultVenueFilterValue

  const handleChangeVenue = useCallback(
    (e) => {
      changeVenueFilter(e?.target?.value)
    },
    [changeVenueFilter]
  )
  return (
    <>
      <Head title={t("Directory")} />
      <Layout>
        <Box ref={ref} sx={root}>
          <Box sx={filterSection}>
            <HorizontalScrollPane
              className="category-filterbar"
              stackProps={{
                sx: scrollPane,
              }}
            >
              <CategoryFilterBar
                sx={categoryFilterBar}
                activeCategory={parentCategoryId}
                categories={sortedParentCategoryWithOccupant}
                onChange={handleChangeParentCategory}
              />
            </HorizontalScrollPane>
            {showSubCategoryFilter && (
              <HorizontalScrollPane
                className="subCategory-filterbar"
                stackProps={{
                  sx: scrollPane,
                }}
              >
                <CategoryFilterBar
                  sx={subCategoryFilterBar}
                  activeCategory={subCategoryId}
                  categories={subCategoryWithActiveParentCategory}
                  onChange={handleChangeSubCategory}
                  renderFilterButton={CategoryFilterChip}
                />
              </HorizontalScrollPane>
            )}
            {!isMultiVenueProject && isMultiOrdinalVenue && (
              <HorizontalScrollPane
                stackProps={{
                  sx: scrollPane,
                }}
              >
                <LevelFilterBar
                  levels={filteredLevels}
                  onChange={handleChangeOrdinal}
                  value={ordinalFilter}
                />
              </HorizontalScrollPane>
            )}
            {/* Temporary Hide New Multi building filter */}
            {isMultiVenueProject && (
              <Box sx={buildingWithLevelFilterSection}>
                <FormControl sx={buildingFilterSelect}>
                  <InputLabel>{t("Venue")}</InputLabel>
                  <Select
                    onChange={handleChangeVenue}
                    label="venue"
                    value={venueFilter}
                  >
                    {venueOptions.map(({ id, name, value }) => (
                      <MenuItem
                        value={value}
                        sx={selectMenuItem}
                        key={`venue-filter-${id}`}
                      >
                        {t(name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {isMultiOrdinalVenue && (
                  <FormControl sx={levelFilterSelect}>
                    <InputLabel>{t("Floor")}</InputLabel>
                    <Select
                      label={"Floor"}
                      value={ordinalFilter}
                      onChange={handleSelectLevelChange}
                      disabled={disableFloorSelect}
                    >
                      {levelOptions.map(({ value, id, name }) => (
                        <MenuItem
                          value={value}
                          key={`select-floor-${id}`}
                          sx={selectMenuItem}
                        >
                          {t(name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <Button variant="text" onClick={resetFilter} sx={resetButton}>
                  {t("Clear")}
                </Button>
              </Box>
            )}
            <Divider sx={divider} />
          </Box>
          <List sx={list}>
            {filteredOccupantsSorted?.length > 0 ? (
              filteredOccupantsSorted.map((occupant) => (
                <ShopListItem
                  key={`directory-item-${occupant.id}`}
                  occupant={occupant}
                  hideLevel={!isMultiOrdinalVenue}
                />
              ))
            ) : (
              <Box sx={emptyDataWrapper}>
                <EmptyData
                  icon={emptyIcon}
                  primary={t("Coming soon")}
                  secondary={t("Stay tuned for the update")}
                />
              </Box>
            )}
          </List>
          {isMultiVenueProject && (
            <Box sx={venueSelector}>
              <VenueSelector
                selectedVenue={venueFilter}
                venues={venues}
                onClickVenue={changeVenueFilter}
                currentVenue={locationVenueId}
              />
            </Box>
          )}
        </Box>
      </Layout>
    </>
  )
}
