import React, { useMemo } from "react"
import { IconButton, useTheme } from "@mui/material"

import { getIcon } from "utils/icon"

import { useAppUI } from "providers/ui"
import { BackIcon } from "components/icons"

export const HeaderBackButton = (props) => {
  const { goBack } = useAppUI()
  const theme = useTheme()
  const styleOverrides =
    theme.components[`AppHeaderBackButton`]?.styleOverrides || {}

  const { root, icon } = styleOverrides

  const backIcon = useMemo(
    () => getIcon(theme?.assets?.backIcon, icon) || <BackIcon />,
    [theme, icon]
  )

  return (
    <IconButton
      size={props.size || "large"}
      edge="start"
      color="inherit"
      aria-label="back"
      sx={root}
      onClick={goBack}
      {...props}
    >
      {backIcon}
    </IconButton>
  )
}
