import React, { useMemo, useCallback } from "react"
import {
  Avatar,
  Box,
  Card,
  CardMedia,
  Divider,
  Typography,
  useTheme,
} from "@mui/material"
import { deepmerge } from "@mui/utils"
import get from "lodash/get"

import { getFeatureDetail } from "utils/feature"
import { useTranslation } from "providers/i18n"

import { CustomIcon } from "components/icons"
import { ShopIcon } from "../../icons"

export const DirectoryOccupantCard = ({
  data,
  onClick,
  sx,
  endAdornment,
  ...props
}) => {
  const { t } = useTranslation()
  const categoryIcon = useMemo(
    () => get(data, "properties.local_categories[0].properties.icon_path"),
    [data]
  )
  const { name, logo, level, venueLogo, venueRef, isHighlight, extendedLogo } =
    useMemo(() => getFeatureDetail(data), [data])
  const handleClick = useCallback(() => onClick(data), [onClick, data])

  const theme = useTheme()
  const { styleOverrides = {} } =
    theme.components?.[`DirectoryOccupantCard`] || {}
  const {
    root,
    occupantLogo,
    cardContent,
    levelContainer,
    divider,
    venueLogo: venueLogoStyle,
    titleText,
    titleContainer,
    detailWrapper,
    infoContainer,
  } = styleOverrides
  const coverCardImage = isHighlight ? extendedLogo : logo

  return (
    <Card sx={deepmerge(root, sx)} onClick={handleClick} {...props}>
      <Box sx={occupantLogo}>
        <Avatar src={coverCardImage}>
          {!coverCardImage &&
            (categoryIcon ? <CustomIcon path={categoryIcon} /> : <ShopIcon />)}
        </Avatar>
      </Box>
      <Box sx={cardContent}>
        <Box sx={titleContainer}>
          <Typography sx={titleText}>{t(name)}</Typography>
        </Box>
        <Box sx={detailWrapper}>
          <Divider sx={divider} />
          <Box sx={infoContainer}>
            <Box sx={levelContainer} className={venueRef}>
              <Typography variant="h6">{t(level)}</Typography>
            </Box>
            <CardMedia component="img" image={venueLogo} sx={venueLogoStyle} />
          </Box>
        </Box>
      </Box>
    </Card>
  )
}
