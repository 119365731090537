import { AppBar, Box, Toolbar, useTheme } from "@mui/material"

export const Header = ({
  logo = null,
  title = null,
  backButton = null,
  menu = null,
  search = null,
}) => {
  const theme = useTheme()
  const styleOverrides = theme.components[`AppHeader`]?.styleOverrides || {}
  const {
    root,
    toolbar,
    backButtonContainer,
    logoContainer,
    titleContainer,
    menuButtonContainer,
    searchButtonContainer,
  } = styleOverrides

  return (
    <AppBar component="nav" sx={root}>
      <Toolbar sx={toolbar}>
        {backButton && <Box sx={backButtonContainer}>{backButton}</Box>}
        {menu && <Box sx={menuButtonContainer}>{menu}</Box>}
        {logo && <Box sx={logoContainer}>{logo}</Box>}
        {title && <Box sx={titleContainer}>{title}</Box>}
        {search && <Box sx={searchButtonContainer}>{search}</Box>}
      </Toolbar>
    </AppBar>
  )
}
