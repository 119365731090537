import { Box, Fade, Modal, useTheme } from "@mui/material"
import { CloseIconButton } from "../Button"

export const ImageModal = ({
  open = false,
  imageSrc,
  alt = "image",
  onClose,
  fadeProps = {},
  ...props
}) => {
  const theme = useTheme()
  const styleOverrides = theme.components[`AppImageModal`]?.styleOverrides || {}
  const { root, imageWrapper, closeButton } = styleOverrides

  return (
    <Modal sx={root} open={open} onClose={onClose} {...props}>
      <Fade in={open} timeout={500} {...fadeProps}>
        <Box sx={imageWrapper}>
          <CloseIconButton sx={closeButton} size="small" onClick={onClose} />
          <img src={imageSrc} alt={alt} />
        </Box>
      </Fade>
    </Modal>
  )
}
