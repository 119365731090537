import { useCallback, useMemo } from "react"
import { Routes, Route } from "react-router-dom"
import _ from "lodash"
import { useIdleTimer } from "react-idle-timer"
import { useNavigate } from "react-router-dom"
import { AnimatePresence } from "framer-motion"

import config from "config"
import { DEVICE_KIOSK_V } from "constant"

import { useVenue } from "providers/venue"
import { useAppUI } from "providers/ui"

// Extensions
import { extensionConfigs } from "extensions"
import {
  getAllExtensionRoutes,
  getExtensionThemeFactories,
  mergeRoutes,
  registerRouteByConfig,
} from "utils/extension"

// Theme
import { generateTheme } from "theme/generateTheme"
import { appThemeOptionFactory as directoryThemeOptionFactory } from "theme/app/directory"
import { ThemeProvider } from "providers/mui-extended/ThemeProvider"

// Routes
import { standardRoutes } from "standard"

// Pages
import { Layout as MapLayout } from "pages/maps/_layout.kiosk-v"

// Components, Layouts
import { AppLayout, PageLayout } from "layouts/kiosk-v"
import { ScrollToTop } from "components/ScrollToTop"
import { LoadingScreen, MapUpdatingScreen } from "components/LoadingScreen"
import { MotionDiv, showContent } from "components/motions"

const resetEvent = new Event("appreset")

// Extension routes
const extensionRoutes = getAllExtensionRoutes(
  config("venueExtension"),
  extensionConfigs,
  DEVICE_KIOSK_V
)

// Merge Standard + Extension routes
const { pageRoutes, mapRoutes, baseRoutes } = mergeRoutes(
  _.get(standardRoutes, DEVICE_KIOSK_V),
  extensionRoutes
)

// Extension Themes
const extensionThemeConfig = getExtensionThemeFactories(
  config("venueExtension"),
  extensionConfigs,
  DEVICE_KIOSK_V
)

// App Theme Factory
const appThemeOptionFactory = _.get(directoryThemeOptionFactory, DEVICE_KIOSK_V)

const onAppIdle = () => {
  if (window.electron) {
    const sendMessage = window.electron.sendMessage
    sendMessage("setHomeScreenMode", { enable: true })
  }
}

export const KioskVApp = () => {
  // Generate a venue theme
  const { themeOptions: venueThemeOptions, dataLoaded } = useVenue()
  const { mapReady, mapLoading } = useAppUI()
  const theme = generateTheme(venueThemeOptions, appThemeOptionFactory, {
    extendThemeFactories: extensionThemeConfig.themeFactories,
    ignoreAppThemeFactory: extensionThemeConfig.ignoreAppThemeFactory,
  })
  const navigate = useNavigate()
  const isAppReady = useMemo(
    () => mapReady && dataLoaded,
    [mapReady, dataLoaded]
  )
  const onIdle = useCallback(async () => {
    navigate("/")
    window.dispatchEvent(resetEvent)
    onAppIdle()
  }, [navigate])

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 2,
    throttle: 500,
  })

  return (
    <ThemeProvider theme={theme}>
      <ScrollToTop />
      <AnimatePresence mode="sync">
        {!isAppReady && <LoadingScreen key="loading-screen" />}
        <MapUpdatingScreen key="map-updating" open={mapLoading} />
        <MotionDiv
          key="content"
          variants={showContent}
          initial="initial"
          animate="animate"
        >
          <Routes>
            <Route path="/" element={<AppLayout />}>
              {baseRoutes.map(registerRouteByConfig)}
              <Route path="/*" element={<PageLayout />}>
                {/* Add or override specific route extension pages here. */}
                {pageRoutes.map(registerRouteByConfig)}
              </Route>

              <Route path="/maps/*" element={<MapLayout />}>
                {/* The indoorPage parent router treats the route child parameter as the same parameter as its own. */}
                {mapRoutes.map(registerRouteByConfig)}
              </Route>
            </Route>
          </Routes>
        </MotionDiv>
      </AnimatePresence>
    </ThemeProvider>
  )
}
