import "./wdyr" // why did you render config
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import React from "react"
import ReactDOM from "react-dom/client"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import Main from "./Main"
import reportWebVitals from "./reportWebVitals"
import i18n from "./i18n"
import { I18nextProvider } from "react-i18next"

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <QueryClientProvider client={queryClient}>
    <I18nextProvider i18n={i18n}>
      <Main />
      {process.env.NODE_ENV === "development" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </I18nextProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
