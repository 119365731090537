import React, { ReactNode, useMemo } from "react"
import {
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  ToggleButtonProps,
  useTheme,
} from "@mui/material"
import { deepmerge } from "@mui/utils"

import { useTranslation } from "providers/i18n"

import { IFeature } from "interfaces"
import { getFeatureName } from "utils/feature"
import { getIcon } from "utils/icon"

import { CategoryFilterButton } from "./CategoryFilterButton"
import { ShopIcon, CustomIcon } from "components/icons"
import StarIcon from "@mui/icons-material/Star"

interface ICategoryFilterButtonProps extends ToggleButtonProps {
  Icon?: ReactNode
}

interface ICategoryFilterBarProps extends ToggleButtonGroupProps {
  activeCategory?: string
  categories?: IFeature[]
  allButtonIcon?: ReactNode
  defaultButtonIcon?: ReactNode
  renderFilterButton?: React.FunctionComponent<ICategoryFilterButtonProps>
}

export const CategoryFilterBar: React.FC<ICategoryFilterBarProps> = ({
  activeCategory,
  categories = [],
  onChange = () => {},
  allButtonIcon: allButtonIconProp,
  defaultButtonIcon = undefined,
  sx = {},
  renderFilterButton: RenderFilterButton = CategoryFilterButton,
  ...props
}) => {
  const { t } = useTranslation()

  const theme = useTheme()
  const allIcon = useMemo(
    //@ts-ignore
    () =>
      allButtonIconProp ||
      getIcon(theme?.assets?.categoryFilter?.all) || <ShopIcon />,
    [theme, allButtonIconProp]
  )
  const { styleOverrides = {} } =
    theme.components?.[`AppCategoryFilterBar`] || {}
  const { root, filterButton } = styleOverrides
  return (
    <ToggleButtonGroup
      value={activeCategory}
      onChange={onChange}
      exclusive
      // TODO:: Explore a solution to incorporate custom variants into the theme (TypeScript version).
      // @ts-ignore
      variant="borderSeperate"
      sx={deepmerge(root, sx)}
      {...props}
    >
      <RenderFilterButton
        onClick={onChange}
        className="allFilter-button"
        value={"ALL"}
        key={`category-filter-all`}
        Icon={allIcon}
        sx={filterButton}
      >
        {t("All Categories")}
      </RenderFilterButton>
      {categories.map((category) => {
        const { is_featured, icon_path } = category?.properties || {}
        return (
          <RenderFilterButton
            className={`${is_featured ? "highlight" : ""}`}
            value={category.id}
            key={`category-filter-${category.id}`}
            Icon={
              is_featured ? (
                <StarIcon />
              ) : icon_path ? (
                <CustomIcon path={icon_path} />
              ) : (
                defaultButtonIcon
              )
            }
          >
            {t(getFeatureName(category))}
          </RenderFilterButton>
        )
      })}
    </ToggleButtonGroup>
  )
}
