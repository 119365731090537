import React, { useMemo } from "react"
import { IconButton, useTheme } from "@mui/material"
import { BackIcon } from "components/icons"
import { getIcon } from "utils/icon"

export const BackIconButton = (props) => {
  const theme = useTheme()
  const styleOverrides =
    theme.components[`BackIconButton`]?.styleOverrides || {}

  const { root, icon } = styleOverrides

  const backIcon = useMemo(
    () => getIcon(theme?.assets?.backIcon, icon) || <BackIcon />,
    [theme, icon]
  )

  return (
    <IconButton
      size={props.size || "large"}
      edge="start"
      color="inherit"
      aria-label="back"
      sx={root}
      {...props}
    >
      {backIcon}
    </IconButton>
  )
}
