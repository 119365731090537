/**
 * @Srouce: https://github.com/Turfjs/turf/tree/master/packages/turf-shortest-path
 **/
import bbox from "@turf/bbox"
import booleanPointInPolygon from "@turf/boolean-point-in-polygon"
import distance from "@turf/distance"
import scale from "@turf/transform-scale"
import union from "@turf/union"
import bboxPolygon from "@turf/bbox-polygon"
import { getCoord, getType, getGeom } from "@turf/invariant"
import {
  point,
  isNumber,
  lineString,
  isObject,
  featureCollection,
  feature,
} from "@turf/helpers"
import PF from "pathfinding"
import set from "lodash/set"
/**
 * Returns the shortest {@link LineString|path} from {@link Point|start} to {@link Point|end} without colliding with
 * any {@link Feature} in {@link FeatureCollection<Polygon>| obstacles}
 *
 * @name shortestPath
 * @param {Coord} start point
 * @param {Coord} end point
 * @param {Object} [options={}] optional parameters
 * @param {Geometry|Feature|FeatureCollection<Polygon>} [options.obstacles] areas which path cannot travel
 * @param {number} [options.minDistance] minimum distance between shortest path and obstacles
 * @param {string} [options.units='kilometers'] unit in which resolution & minimum distance will be expressed in; it can be degrees, radians, miles, kilometers, ...
 * @param {number} [options.resolution=100] distance between matrix points on which the path will be calculated
 * @returns {Feature<LineString>} shortest path between start and end
 * @example
 * const start = [-5, -6];
 * const end = [9, -6];
 * const options = {
 *   obstacles: turf.polygon([[[0, -7], [5, -7], [5, -3], [0, -3], [0, -7]]])
 * };
 *
 * const path = turf.shortestPath(start, end, options);
 *
 * //addToMap
 * const addToMap = [start, end, options.obstacles, path];
 */
function shortestPath(start, end, options) {
  // Optional parameters
  options = options || {}
  if (!isObject(options)) throw new Error("options is invalid")
  let resolution = options.resolution
  const smoothenPath = options.smoothenPath
  let obstacles = options.obstacles || featureCollection([])

  // validation
  if (!start) throw new Error("start is required")
  if (!end) throw new Error("end is required")
  if ((resolution && !isNumber(resolution)) || resolution <= 0)
    throw new Error("options.resolution must be a number, greater than 0")

  // Normalize Inputs
  const startCoord = getCoord(start)
  const endCoord = getCoord(end)
  start = point(startCoord)
  end = point(endCoord)

  // Handle obstacles
  switch (getType(obstacles)) {
    case "FeatureCollection":
      if (obstacles.features.length === 0)
        return lineString([startCoord, endCoord])
      break
    case "Polygon":
      obstacles = featureCollection([feature(getGeom(obstacles))])
      break
    default:
      throw new Error("invalid obstacles")
  }

  // define path grid area
  const collection = obstacles
  collection.features.push(start, end)
  const box = bbox(scale(bboxPolygon(bbox(collection)), 1.15)) // extend 15%

  if (!resolution) {
    // Calculate resolution from bbox width distance if config undefined.
    const width = distance([box[0], box[1]], [box[2], box[1]], options)
    resolution = width / 100
  }

  collection.features.pop()
  collection.features.pop()

  const [west, south, east, north] = box
  const xFraction = resolution / distance([west, south], [east, south], options)
  const cellWidth = xFraction * (east - west)
  const yFraction = resolution / distance([west, south], [west, north], options)
  const cellHeight = yFraction * (north - south)

  const bboxHorizontalSide = east - west
  const bboxVerticalSide = north - south
  const columns = Math.floor(bboxHorizontalSide / cellWidth)
  const rows = Math.floor(bboxVerticalSide / cellHeight)
  // adjust origin of the grid
  const deltaX = (bboxHorizontalSide - columns * cellWidth) / 2
  const deltaY = (bboxVerticalSide - rows * cellHeight) / 2

  // loop through points only once to speed up process
  // define matrix grid for A-star algorithm
  let closestToStart = null,
    closestToEnd = null,
    minDistStart = Infinity,
    minDistEnd = Infinity,
    currentY = north - deltaY,
    currentX = west + deltaX,
    row = 0,
    column = 0,
    distStart,
    distEnd,
    pt,
    isInsideObstacle

  const roundLoopY = Math.ceil((currentY - south) / cellHeight)
  const roundLoopX = Math.ceil((east - currentX) / cellWidth)
  let totalRounds = roundLoopX * roundLoopY
  const pointMatrix = []
  const matrix = []

  /**
   * Combine obstacle polygons
   */

  const obstacleTotal = collection.features.length
  const obstacleFeatures = collection.features
  let combinedObstacle = obstacleFeatures[0]
  let obstacleIndex = 0
  for (obstacleIndex = 0; obstacleIndex < obstacleTotal; obstacleIndex++) {
    const nextObstacleFeature = obstacleFeatures[obstacleIndex + 1]

    if (!nextObstacleFeature) continue

    combinedObstacle = union(combinedObstacle, nextObstacleFeature)
  }

  /*
   * Optimize the loop for faster creation of a matrix grid.
   * Original Source: https://github.com/Turfjs/turf/blob/88dfdbb2617b70c833e30923590400dc0189e601/packages/turf-shortest-path/index.js#L119
   */
  while (totalRounds--) {
    pt = point([currentX, currentY])
    isInsideObstacle = booleanPointInPolygon(pt, combinedObstacle)
    // feed obstacles matrix
    set(matrix, `[${row}][${column}]`, isInsideObstacle ? 1 : 0)

    // map point's coords
    set(pointMatrix, `[${row}][${column}]`, `${currentX}|${currentY}`)

    // set closest points
    distStart = distance(pt, start)
    // if (distStart < minDistStart) {
    if (!isInsideObstacle && distStart < minDistStart) {
      minDistStart = distStart
      closestToStart = { x: column, y: row }
    }

    distEnd = distance(pt, end)
    if (!isInsideObstacle && distEnd < minDistEnd) {
      minDistEnd = distEnd
      closestToEnd = { x: column, y: row }
    }

    if (column < roundLoopX) {
      currentX += cellWidth
      column++
      continue
    }

    if (row < roundLoopY) {
      currentY -= cellHeight
      currentX = west + deltaX
      column = 0
      row++
    }
  }

  // javascript PathFinding ----------------------
  // @Ref https://github.com/qiao/PathFinding.js
  const finder = new PF.AStarFinder({
    allowDiagonal: true,
    dontCrossCorners: true,
  })
  const grid = new PF.Grid(matrix)
  const startOnMatrix = [closestToStart.x, closestToStart.y]
  const endOnMatrix = [closestToEnd.x, closestToEnd.y]
  let result = finder.findPath(...startOnMatrix, ...endOnMatrix, grid)

  if (smoothenPath) {
    result = PF.Util.smoothenPath(grid, result)
  }

  // Remove first and last coordinates to remove unusual curve path which cause from first and last coordinate
  // Which will be too close to startCoord and endCoord and create a curved path between first and startCoord
  result.pop()
  result.shift()

  const path = [startCoord]
  result.forEach((coord) => {
    const coords = pointMatrix[coord[1]][coord[0]].split("|")
    path.push([+coords[0], +coords[1]]) // make sure coords are numbers
  })
  path.push(endCoord)
  return /* cleanCoords(lineString(path)) */ lineString(path)
}

export default shortestPath
