import { useMemo } from "react"
import _ from "lodash"

import { IRecord } from "interfaces"

import { useSponsoredContent } from "./useSponsoredContent"

interface IFooterBannerConfig {
  disable?: boolean
  limit?: number
  autoplay?: boolean
  interval?: number
}

interface IUseSponsoredFooterBannerResult {
  footerBanners: IRecord[]
  footerBannerConfig: IFooterBannerConfig
}
export const useSponsoredFooterBanner = (): IUseSponsoredFooterBannerResult => {
  const { footerBanner } = useSponsoredContent()

  const footerBannerConfig = useMemo(
    () => _.get(footerBanner, "config"),
    [footerBanner]
  )

  const footerBanners = useMemo(
    () => _.get(footerBanner, "data", []),
    [footerBanner]
  )

  return {
    footerBanners,
    footerBannerConfig,
  }
}
