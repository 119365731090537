import { Box, useTheme, Avatar, Typography } from "@mui/material"
import { deepmerge } from "@mui/utils"

import { useTranslation } from "providers/i18n"

import { Carousel, CarouselItem } from "components/Carousel"
import { ShopIcon } from "components/icons"

export const AdSuggestList = ({
  suggestedListItems = [],
  onClickSuggestedItem,
  sx = {},
}) => {
  const { t } = useTranslation({ defaultValue: null })
  // Theme & Styling
  const theme = useTheme()
  const style = theme.components[`VuiAdSuggestList`]?.styleOverrides || {}
  const {
    root,
    suggestTitle,
    suggestList,
    suggestListItem,
    suggestItemAvatar,
    suggestItemContent,
  } = style
  return (
    <Box sx={deepmerge(root, sx)}>
      <Typography sx={suggestTitle} component="h6">
        {t("Suggested Shops")}
      </Typography>
      <Carousel
        onClick={onClickSuggestedItem}
        options={{
          type: "slide",
          pagination: true,
          autoplay: true,
          interval: 5000,
          gap: "1rem",
          padding: 0,
          perPage: 4,
        }}
        sx={suggestList}
      >
        {suggestedListItems.map((item) => (
          <CarouselItem link={`/maps/place/${item.feature_id}`} key={item.id}>
            <Box sx={suggestListItem}>
              <Avatar
                src={item.feature?.logo}
                variant="logo"
                sx={suggestItemAvatar}
              >
                {/* if there is no avatar, will fall back to ShopIcon */}
                <ShopIcon />
              </Avatar>
              <Box sx={suggestItemContent}>
                <Typography variant="title" component="h6">
                  {t(item.feature.name)}
                </Typography>
              </Box>
            </Box>
          </CarouselItem>
        ))}
      </Carousel>
    </Box>
  )
}
