import isString from "lodash/isString"
import { i18n } from "i18next"

const defaultOption = { fallbackLng: "en", defaultValue: "-" }

interface IInitialOptions {
  defaultValue?: string
  fallbackLng?: string
}

interface IObjectLocale {
  [key: string]: string
}

interface IOption {
  ns: string
  [key: string]: string
}

type GenerateLocalizeValueFn = (
  i18n: i18n,
  initialOptions: IInitialOptions
) => (objectLocale: IObjectLocale, options?: IOption) => string

export const generateLocalizeValueFn: GenerateLocalizeValueFn =
  (i18n, initialOptions = {}) =>
  (objectLocale = {}, options) => {
    const { fallbackLng, defaultValue } = {
      ...defaultOption,
      ...initialOptions,
      ...options,
    }

    const value = objectLocale[i18n.resolvedLanguage]
    return isString(value)
      ? value
      : objectLocale[fallbackLng]
      ? objectLocale[fallbackLng]
      : defaultValue
  }
