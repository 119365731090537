import React from "react"
import { Box, useTheme } from "@mui/material"

export const MapPageLayout = ({ children, header = null, ...props }) => {
  const theme = useTheme()
  const { root, headerWrapper } =
    theme.components[`AppMapPageLayout`]?.styleOverrides || {}

  return (
    <Box sx={root} {...props} data-name="MapPageLayout">
      <Box sx={headerWrapper}>{header}</Box>
      {children}
    </Box>
  )
}
