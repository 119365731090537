export const svgToPng = (svgString: string, scaleFactor: number = 1) => {
  const svgBlob = new Blob([svgString], { type: "image/svg+xml" })
  const url = URL.createObjectURL(svgBlob)
  const img = new Image()
  return new Promise((resolve, reject) => {
    img.onload = function () {
      // Calculate the new dimensions
      const newWidth = img.width * scaleFactor
      const newHeight = img.height * scaleFactor

      // Create a canvas with the new dimensions
      const canvas = document.createElement("canvas")
      canvas.width = newWidth
      canvas.height = newHeight
      const ctx = canvas.getContext("2d")

      // Draw the SVG image onto the canvas with the scaled dimensions
      ctx.drawImage(img, 0, 0, newWidth, newHeight)

      // Convert canvas to PNG
      const pngDataUrl = canvas.toDataURL("image/png")

      // Resolve the Promise with the PNG data URL
      resolve(pngDataUrl)
    }

    // Handle image load errors
    img.onerror = function (error) {
      reject(error)
    }

    img.src = url
  })
}

export const createSVGPathFromMarkerSymbol = (style) => {
  const {
    markerWidth = 24,
    markerDx = 0,
    markerDy = 0,
    markerFill,
    markerPath,
  } = style
  const scale = markerWidth / 24
  return `<path d="${markerPath}" style="transform:translate(${markerDx}px, ${markerDy}px) scale(${scale})" fill="${markerFill}"/>`
}
