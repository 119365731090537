import _ from "lodash"

import { ReactNode } from "react"
import { ThemeOptions } from "@mui/material"
import { getIcon } from "utils/icon"

interface MenuConfig {
  to: string
  label: string // Translation key
  order?: number
  icon: ReactNode | null
  icon_comp?: string // Icon Component Name
  icon_path?: string // Svg Path
  image?: string // image url
  hidden?: boolean
}

// The menu configuration utilized in the app's service configuration.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface GroupMenuConfigs {
  navigations: MenuConfig[]
  homeNavigations: MenuConfig[]
  kioskNavigations: MenuConfig[]
  bottomNavigations: MenuConfig[]
}

type GenerateMenuConfig = (
  sourceMenus: MenuConfig[],
  newMenus: MenuConfig[]
) => MenuConfig[]

type PrepareMenu = (menuConfig: MenuConfig) => MenuConfig
type BindProperty = (menuConfig: MenuConfig) => void
type IsMenuHidden = (menuConfig: MenuConfig) => boolean
type GroupMenuByPath = (
  menuConfig: MenuConfig[],
  field?: string
) => { [k: string]: MenuConfig }

interface RouteConfig {
  path: string
  element: ReactNode
}

type MergeRoutes = (
  sourceRoutes: RouteConfig[],
  extensionRoutes: RouteConfig[]
) => RouteConfig[]

interface ExtensionConfig {
  themeOptionFactory: (theme: ThemeOptions) => ThemeOptions
  ignoreAppThemeFactory?: boolean
  routes: RouteConfig[]
}

type GetAllExtensionRoutes = (
  extensionList: string[],
  extensionConfigs: ExtensionConfig[] | object
) => RouteConfig[]

export const getAllExtensionRoutes: GetAllExtensionRoutes = (
  extensionList = [],
  extensionConfigs = {},
  deviceConfig = "mobile"
) => {
  if (_.isEmpty(extensionList)) return []

  return _(extensionList)
    .map((extensionName) => {
      // Retrieve rotue config by extension name and the device mode. (kiosk-v, kiosk-h, mobile)
      const routes = _.get(extensionConfigs, [
        extensionName,
        "routes",
        deviceConfig,
      ])
      if (_.isNil(routes) || _.isEmpty(routes)) return null
      return routes
    })
    .compact()
    .flatten()
    .value()
}

export const mergeRoutes: MergeRoutes = (
  sourceRoutes = [],
  extensionRoutes = []
) => {
  const mergedRouteConfigs = _.merge(
    _.keyBy(sourceRoutes, "path"),
    _.keyBy(extensionRoutes, "path")
  )
  return _.values(mergedRouteConfigs)
}

export const groupMenuByPath: GroupMenuByPath = (config, field = "to") => {
  return _.keyBy(config, field)
}

export const isMenuHidden: IsMenuHidden = (config) => !config?.hidden

export const bindIconMenu: BindProperty = (config) => {
  config.icon = getIcon(config)
}

export const prepareMenu: PrepareMenu = (config) => {
  bindIconMenu(config)
  return { ...config }
}

export const generateMenuConfig: GenerateMenuConfig = (
  sourceMenus = [],
  newMenus = []
) => {
  const groupedSourceMenus = groupMenuByPath(sourceMenus)
  const groupedNewMenus = groupMenuByPath(newMenus)

  // Merge sourceConfigs + newConfigs and then sorting menu by order field.
  return _(groupedSourceMenus)
    .merge(groupedNewMenus)
    .values()
    .filter(isMenuHidden)
    .map(prepareMenu)
    .sortBy("order")
    .value()
}
