import React from "react"
import { useTranslation } from "providers/i18n"
import { OpeningInfo } from "../occupants/OpeningInfo"
import { FeatureActionContainer } from "containers/FeatureActionContainer"
import _ from "lodash"

import {
  Card,
  styled,
  Box,
  Typography,
  Stack,
  Skeleton,
  useTheme,
  CardMedia,
} from "@mui/material"
import { CustomIcon, StoreIcon } from "components/icons"

const ShortInfoWrapper = styled(Card)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  boxShadow: "none",
}))

const ShortInfoActionWrapper = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  paddingTop: theme.spacing(1),
}))

const LoadingFeature = () => {
  return (
    <Box>
      <ShortInfoWrapper>
        <Skeleton variant="circular" width={80} height={80} />
        <Box>
          <Skeleton variant="text" width={180} />
          <Stack spacing={0.5} sx={{ mb: 1 }}>
            <Skeleton variant="text" width={80} />
            <Skeleton variant="text" width={80} />
          </Stack>
          <Skeleton variant="text" width={150} />
        </Box>
      </ShortInfoWrapper>
      <ShortInfoActionWrapper direction={"row"} spacing={2}>
        <Skeleton variant="text" width={70} height={40} />
        <Skeleton variant="text" width={70} height={40} />
      </ShortInfoActionWrapper>
    </Box>
  )
}

export const FeatureSummary = ({ DirectionButtonProps, endA, ...props }) => {
  const { t } = useTranslation({ defaultValue: null })
  const theme = useTheme()
  const styleOverrides =
    theme.components[`FeatureSummary`]?.styleOverrides || {}
  const {
    root,
    container,
    infoSection,
    title,
    infoContainer,
    logoContainer,
    venueLogoContainer,
    logoImage,
    venueLogoImage,
    locationText,
    categoryIcon,
  } = styleOverrides
  const {
    zone,
    logo,
    venueLogo,
    category,
    phone,
    level,
    name,
    hours,
    id,
    loading,
    group,
    onNavigate,
    categoryIconProp = {},
  } = props
  const categoryText = t(category)
  const zoneText = zone && `${t("Zone")} ${t(zone)}`
  const groupText = group && t(group)
  const levelText = level && t("Level Text", { level: t(level) })
  const nameText = t(name)
  const { iconPath, image } = categoryIconProp
  if (loading) return <LoadingFeature />
  return (
    <Stack justifyContent="space-between" sx={root}>
      <Card sx={container}>
        {
          <Stack spacing={1} direction="column">
            <Box sx={logoContainer}>
              {logo ? (
                <CardMedia
                  component="img"
                  image={logo || image}
                  sx={logoImage}
                />
              ) : iconPath ? (
                <CustomIcon path={iconPath} sx={categoryIcon} />
              ) : (
                <StoreIcon />
              )}
            </Box>
            {venueLogo && (
              <Box sx={venueLogoContainer}>
                <CardMedia
                  component="img"
                  image={venueLogo}
                  sx={venueLogoImage}
                />
              </Box>
            )}
          </Stack>
        }
        <Stack direction="row" sx={infoContainer}>
          <Box sx={infoSection}>
            <Typography variant="title" component="h3" sx={title}>
              {nameText}
            </Typography>
            <Stack columnGap={0.5} flexWrap="wrap" direction="row">
              <Typography
                color="text.secondary"
                variant="body2"
                component={"h3"}
                sx={locationText}
              >
                {_.compact([
                  _.compact([categoryText, levelText, groupText]).join(" - "),
                  zoneText,
                ]).join(" | ")}
              </Typography>
            </Stack>
            {hours && <OpeningInfo hours={hours} />}
          </Box>
        </Stack>
      </Card>
      <FeatureActionContainer
        destinationId={id}
        phone={phone}
        onNavigate={onNavigate}
        ShareProps={{ title: t(name) }}
        DirectionButtonProps={DirectionButtonProps}
      />
    </Stack>
  )
}
