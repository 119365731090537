import React from "react"

import { KioskNavigation } from "components/navigation"
import { useAppUI } from "providers/ui"

export const AppNavigation = () => {
  const { kioskNavigation, matchedPath } = useAppUI()

  return (
    <KioskNavigation value={matchedPath?.to} navigations={kioskNavigation} />
  )
}
