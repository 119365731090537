import { GeoLocationContext } from "./GeoLocationContext"
import {
  useUserLocation,
  useDeviceLocation as useBrowserDeviceLocation,
} from "hooks"
import config from "config"
import { useLocationHistory } from "./hooks"
import { useCallback, useMemo } from "react"
import { useVenue } from "providers/venue"
import _ from "lodash"
import { prepareGeolocationFeature } from "./utils/prepareGeolocationFeature.ts"
import { useElectronDeviceLocation } from "hooks/useElectronDeviceLocation"

const userLocationStaleTime = config("userLocationStaleTime")
const isKioskMode = ["kiosk-v", "kiosk-h"].includes(config("device"))

const useDeviceLocation =
  config("appType") === "webapp"
    ? useBrowserDeviceLocation
    : useElectronDeviceLocation

export const GeoLocationProvider = ({ children }) => {
  const { levels, venues, units, dataLoaded, defaultVenue } = useVenue()
  const { deviceLocation, deviceLocationName, viewPoint } = useDeviceLocation()
  const { scannedLocations: scannedLocationsState, addScannedLocationHistory } =
    useLocationHistory()
  const { isLoading, userLocation: userLocationState } = useUserLocation(
    deviceLocation,
    {
      onUpdate: addScannedLocationHistory,
      staleTime: userLocationStaleTime,
      update: !isKioskMode,
      enable: dataLoaded,
    }
  )
  const userLocation = useMemo(
    () =>
      prepareGeolocationFeature(userLocationState, { venues, levels, units }),
    [userLocationState, venues, levels, units]
  )

  const locationVenue = useMemo(() => {
    return _.get(userLocation, "properties.venue", defaultVenue)
  }, [userLocation, defaultVenue])

  const scannedLocations = useMemo(
    () =>
      scannedLocationsState.map((location) =>
        prepareGeolocationFeature(location, { venues, levels, units })
      ),
    [scannedLocationsState, levels, venues, units]
  )

  const findGeoLocationFeatureById = useCallback(
    (id) =>
      _.compact([userLocation, ...scannedLocations]).find(
        ({ id: geoLocationId }) => geoLocationId === id
      ),
    [userLocation, scannedLocations]
  )

  const value = {
    isLoading,
    userLocation,
    scannedLocations,
    deviceLocation,
    deviceLocationName,
    locationVenue,
    viewPoint,
    // Function
    findGeoLocationFeatureById,
  }

  return (
    <GeoLocationContext.Provider value={value}>
      {children}
    </GeoLocationContext.Provider>
  )
}
