import { Link, useNavigate } from "react-router-dom"
import {
  Box,
  Stack,
  Typography,
  IconButton,
  Slide,
  Zoom,
  Button,
  Fab,
  useTheme,
  Paper,
} from "@mui/material"

import { Trans } from "react-i18next"
import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import { TopBarContainer } from "containers/TopBarContainer"
import { BackIcon, PrevStepIcon, NextStepIcon } from "components/icons"

import { StepProgressBar } from "components/Progress/StepProgressBar"
import { OrdinalSelector } from "components/OrdinalSelector"
import { ExitIcon } from "components/icons"
import { useStepByStep } from "hooks/direction/useStepByStep"
import { useCallback, useRef, useState } from "react"
import { useStepByStepMap } from "hooks/direction/useStepByStepMap"

export const StepByStep = () => {
  const navigate = useNavigate()
  const { isMultiOrdinalVenue } = useVenue()
  const contentRef = useRef(null)
  const [slideEntered, setSlideEntered] = useState(false)
  const offsetBottom = +contentRef.current?.clientHeight || 0
  const {
    route,
    steps,
    destinationParam,
    originParam,
    nextStepNumber,
    prevStepNumber,
    currentStepNumber,
    currentStep,
    currentLevel,
    currentOrdinal,
    originLevelName,
    originName,
    destinationLevelName,
    destinationName,
    isReady,
  } = useStepByStep()

  useStepByStepMap(route, currentStepNumber, {
    offset: { bottom: offsetBottom },
    enabled: isReady && slideEntered,
  })

  const { t } = useTranslation()

  const theme = useTheme()

  const {
    container,
    primaryText,
    title,
    secondaryText,
    locationText,
    navButtonWrapper,
    headerWrapper,
    navButton,
    iconNavButton,
    overviewWrapper,
    wrapper,
    kioskBackButton,
    highlightedText,
    stepTextContainer,
    exitButtonWrapper,
    exitButton,
    stepProgressBar,
  } = theme.components[`AppNavigationStep`]?.styleOverrides || {}
  const {
    root: topBarRoot,
    ordinalSelector,
    backButton,
  } = theme.components[`AppTopBar`]?.styleOverrides || {}

  const originFloorText = isMultiOrdinalVenue
    ? `(${t("At Floor", {
        value: originLevelName,
      })})`
    : ""
  const destinationFloorText = isMultiOrdinalVenue
    ? `(${t("At Floor", {
        value: destinationLevelName,
      })})`
    : ""
  const highlightedStepText = (
    <Typography variant="subTitle" component="span" sx={highlightedText} />
  )
  const handleClickStep = useCallback(
    (index) => {
      navigate(
        `/maps/dir/${originParam}/${destinationParam}/navigate/steps/${
          index + 1
        }`,
        { replace: true }
      )
    },
    [originParam, destinationParam, navigate]
  )
  const handleSlideEnter = useCallback(
    () => !slideEntered && setSlideEntered(true),
    [slideEntered]
  )
  return (
    <>
      {/* Header Section */}
      <TopBarContainer sx={topBarRoot}>
        <Stack spacing={5}>
          {/* Back Button */}
          <Fab
            sx={backButton}
            size="medium"
            component={Link}
            replace={true}
            to={`/maps/dir/${originParam}/${destinationParam}`}
          >
            <BackIcon />
          </Fab>
          {isMultiOrdinalVenue && (
            <OrdinalSelector
              sx={ordinalSelector}
              levels={currentLevel}
              selectedOrdinal={currentOrdinal}
            />
          )}
        </Stack>
      </TopBarContainer>
      {/* End of Header Section */}
      <Slide direction="up" in={true} onEnter={handleSlideEnter}>
        <Box sx={wrapper}>
          <Fab
            component={Link}
            variant="rounded"
            replace={true}
            to={`/maps/dir/${originParam}/${destinationParam}`}
            sx={kioskBackButton}
          >
            <BackIcon />
          </Fab>
          <Paper sx={container} spacing={1} ref={contentRef}>
            <Box sx={exitButtonWrapper}>
              <Button
                component={Link}
                to="/maps"
                startIcon={<ExitIcon />}
                sx={exitButton}
                variant="contained"
              >
                Exit
              </Button>
            </Box>
            <Stack width="100%" spacing={0} direction="column">
              <Box sx={headerWrapper}>
                <Stack alignItems="center" spacing={1}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Zoom in={currentStepNumber > 1}>
                      <IconButton
                        color="inherit"
                        component={Link}
                        sx={iconNavButton}
                        replace={true}
                        to={`/maps/dir/${originParam}/${destinationParam}/navigate/steps/${prevStepNumber}`}
                      >
                        <PrevStepIcon fontSize="inherit" />
                      </IconButton>
                    </Zoom>

                    <Typography component={"h1"} textAlign="center" sx={title}>
                      {t("Process step", {
                        currentStep: currentStepNumber,
                        totalSteps: steps.length,
                      })}
                    </Typography>

                    <Zoom in={currentStepNumber < steps.length}>
                      <IconButton
                        color="inherit"
                        component={Link}
                        sx={iconNavButton}
                        replace={true}
                        to={`/maps/dir/${originParam}/${destinationParam}/navigate/steps/${nextStepNumber}`}
                      >
                        <NextStepIcon fontSize="inherit" />
                      </IconButton>
                    </Zoom>
                  </Stack>
                  <Box sx={stepTextContainer}>
                    <Typography
                      variant="subTitle"
                      component="p"
                      sx={primaryText}
                    >
                      <Trans
                        components={{
                          category: highlightedStepText,
                          destination: highlightedStepText,
                          landmark: highlightedStepText,
                        }}
                      >
                        {t(currentStep?.description.text, {
                          category: t(
                            currentStep?.description.options?.category
                          ),
                          destination: t(
                            currentStep?.description.options?.destination
                          ),
                          landmark: t(
                            currentStep?.description.options?.landmark
                          ),
                        })}
                      </Trans>
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              {/* mobile progress bar */}
              <StepProgressBar
                steps={steps}
                currentStep={currentStepNumber}
                sx={stepProgressBar}
                handleClickStep={handleClickStep}
                enableAnimateScroll={slideEntered}
              />
              {/* end of mobile progress bar */}
              {/* Location and overview section */}
              <Stack sx={overviewWrapper}>
                <Typography
                  textAlign="start"
                  variant="caption"
                  sx={locationText}
                >
                  {t(originName)}
                </Typography>
                <Typography variant="caption" sx={secondaryText}>
                  {t("From To", {
                    origin: `${t(originName)} ${originFloorText}`,
                    destination: `${t(
                      destinationName
                    )} ${destinationFloorText}`,
                  })}
                </Typography>
                <Typography textAlign="end" variant="caption" sx={locationText}>
                  {t(destinationName)}
                </Typography>
              </Stack>
              <Stack sx={navButtonWrapper}>
                <Button
                  variant="outlined"
                  startIcon={<PrevStepIcon />}
                  disabled={currentStepNumber <= 1}
                  component={Link}
                  sx={navButton}
                  replace={true}
                  to={`/maps/dir/${originParam}/${destinationParam}/navigate/steps/${prevStepNumber}`}
                >
                  {t("Previous Step")}
                </Button>
                <Button
                  variant="outlined"
                  disabled={currentStepNumber === steps.length}
                  component={Link}
                  sx={navButton}
                  endIcon={<NextStepIcon />}
                  replace={true}
                  to={`/maps/dir/${originParam}/${destinationParam}/navigate/steps/${nextStepNumber}`}
                >
                  {t("Next Step")}
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Box>
      </Slide>
    </>
  )
}
