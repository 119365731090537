import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import updateLocale from "dayjs/plugin/updateLocale"
import buddhistEra from "dayjs/plugin/buddhistEra"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"
import isToday from "dayjs/plugin/isToday"
import "dayjs/locale/th"

dayjs.extend(buddhistEra)
dayjs.extend(localizedFormat)
dayjs.extend(updateLocale)
dayjs.extend(isSameOrAfter)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(isToday)

dayjs.updateLocale("en", {
  formats: {
    LLL: "ddd, DD\nMMM YYYY",
  },
})

dayjs.updateLocale("th", {
  formats: {
    LLL: "ddd, DD\nMMM BBBB",
  },
})

export default dayjs
