import { useEffect, useCallback, useRef, useMemo } from "react"
import Fuse from "fuse.js"
import _ from "lodash"

import {
  filterSearchResult,
  filterOccupant,
  createSearchConfig,
  createSearchPattern,
} from "../utils/search"
import configEnv from "config"

type GetSuggestedSearchItems = (venueFilter: string) => Array<object>

const DEFAULT_SEARCH_CONFIG = {
  keys: [
    {
      name: "name",
    },
    {
      name: "category",
    },
    {
      name: "groups",
    },
    {
      name: "local_categories",
    },
    {
      name: "properties.keyword",
    },
    {
      name: "feature_type",
    },
    {
      name: "kiosk.name",
    },
    {
      name: "unit.name",
    },
  ],
  featureTypes: ["=amenity", "=occupant | =amenity"],
}

const DEFAULT_VENUE_ID = configEnv("defaultVenueId")

const sortFeatureByDefaultVenueId = (a) =>
  a?.properties?.venue?.id === DEFAULT_VENUE_ID ? -1 : 1

export const useSearch = (imdfData, { config, enable }) => {
  const searchConfig = useMemo(() => {
    try {
      if (enable) return createSearchConfig(config)
    } catch (error) {
      return createSearchConfig(DEFAULT_SEARCH_CONFIG)
    }
  }, [config, enable])

  const fuseRef = useRef(null)

  const search = useCallback(
    (value, venueFilter: string) => {
      let result = []
      if (!value || !fuseRef.current) return result

      const searchPatterns = createSearchPattern(value)

      for (const logicName of searchConfig.featureTypes) {
        const searchLogic = searchPatterns[logicName]
        if (!searchLogic) continue

        const matched = fuseRef.current.search(searchLogic)
        if (matched.length > 0) {
          result = matched
            .map((result) => result.item)
            .filter(filterSearchResult)
            .filter(
              (result) =>
                !venueFilter || result.properties?.venue_id === venueFilter
            )
            // Ensure result records with DEFAULT_VENUE_ID are always listed first.
            .sort(sortFeatureByDefaultVenueId)
          break
        }
      }
      return result
    },
    [fuseRef, searchConfig]
  )

  /** Effects */
  // Config Fuse.js instance
  useEffect(() => {
    if (enable && searchConfig && !fuseRef.current) {
      fuseRef.current = new Fuse([], {
        useExtendedSearch: true,
        ignoreLocation: true,
        threshold: 0.2,
        keys: searchConfig.keys,
      })
    }
  }, [searchConfig, enable])

  // Set data collection to the Fuse instance
  useEffect(() => {
    if (fuseRef.current && imdfData && imdfData.length > 0) {
      fuseRef.current.setCollection(imdfData)
    }
  }, [fuseRef, imdfData])

  /** Initial suggested Search List
   * First 50 occupants
   * order by: is_featured = true
   * then name.en
   */

  const getSuggestedSearchItems: GetSuggestedSearchItems = useCallback(
    (venueFilter = "") => {
      return (
        _(imdfData)
          .filter(filterOccupant)
          .filter(
            (result) =>
              !venueFilter || result.properties?.venue_id === venueFilter
          )
          .orderBy(
            ["properties.is_featured", "properties.name.en"],
            ["desc", "asc"]
          )
          // Ensure result records with DEFAULT_VENUE_ID are always listed first.
          .sort(sortFeatureByDefaultVenueId)
          .take(50)
          .value()
      )
    },
    [imdfData]
  )

  return {
    search,
    getSuggestedSearchItems,
  }
}
