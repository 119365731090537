import React, { useCallback, useMemo } from "react"
import { Routes, Route, useNavigate } from "react-router-dom"
import { AnimatePresence } from "framer-motion"
import { useIdleTimer } from "react-idle-timer"
import _ from "lodash"

import config from "config"
import { DEVICE_KIOSK_H } from "constant"

import { useVenue } from "providers/venue"
import { useAppUI } from "providers/ui"

// Extensions
import {
  getAllExtensionRoutes,
  getExtensionThemeFactories,
  mergeRoutes,
  registerRouteByConfig,
} from "utils/extension"
import { extensionConfigs } from "extensions"

// Theme
import { generateTheme } from "theme/generateTheme"
import { appThemeOptionFactory as directoryThemeOptionFactory } from "theme/app/directory"

import { ThemeProvider } from "providers/mui-extended/ThemeProvider"

// Routes
import { standardRoutes } from "standard"

// Pages
import { Layout as MapLayout } from "pages/maps/_layout.kiosk-h"

// Components, Layouts
import { AppLayout, PageLayout } from "layouts/kiosk-h"
import { ScrollToTop } from "components/ScrollToTop"
import { MotionDiv, showContent } from "components/motions"
import { LoadingScreen, MapUpdatingScreen } from "components/LoadingScreen"

const resetEvent = new Event("appreset")

// Extension routes
const extensionRoutes = getAllExtensionRoutes(
  config("venueExtension"),
  extensionConfigs,
  DEVICE_KIOSK_H
)

// Merge Standard + Extension routes
const { baseRoutes, pageRoutes, mapRoutes } = mergeRoutes(
  _.get(standardRoutes, DEVICE_KIOSK_H),
  extensionRoutes
)

// Extension Themes
const extensionThemeConfig = getExtensionThemeFactories(
  config("venueExtension"),
  extensionConfigs,
  DEVICE_KIOSK_H
)

// App Theme Factory
const appThemeOptionFactory = _.get(directoryThemeOptionFactory, DEVICE_KIOSK_H)

export const KioskHApp = () => {
  // Generate a venue theme
  const {
    themeOptions: venueThemeOptions,
    refetchImdfData,
    dataLoaded,
  } = useVenue()
  const { mapReady, mapLoading } = useAppUI()
  const theme = generateTheme(venueThemeOptions, appThemeOptionFactory, {
    extendThemeFactories: extensionThemeConfig.themeFactories,
    ignoreAppThemeFactory: extensionThemeConfig.ignoreAppThemeFactory,
  })

  const navigate = useNavigate()
  const isAppReady = useMemo(
    () => mapReady && dataLoaded,
    [mapReady, dataLoaded]
  )
  const onIdle = useCallback(async () => {
    navigate("/")
    window.dispatchEvent(resetEvent)
    // Refetch IMDF data on idle
    await refetchImdfData()
  }, [navigate, refetchImdfData])

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 5,
    throttle: 500,
    disabled: false,
  })

  return (
    <ThemeProvider theme={theme}>
      <ScrollToTop />
      <AnimatePresence mode="sync">
        {!isAppReady && <LoadingScreen key="loading-screen" />}
        <MapUpdatingScreen key="map-updating" open={mapLoading} />
        <MotionDiv
          key="content"
          variants={showContent}
          initial="initial"
          animate="animate"
        >
          <Routes>
            <Route path="/" element={<AppLayout />}>
              {baseRoutes.map(registerRouteByConfig)}
              <Route path="/*" element={<PageLayout />}>
                {/* Add or override specific route extension pages here. */}
                {pageRoutes.map(registerRouteByConfig)}
              </Route>
              <Route path="/maps/*" element={<MapLayout />}>
                {/* The indoorPage parent router treats the route child parameter as the same parameter as its own. */}
                {mapRoutes.map(registerRouteByConfig)}
              </Route>
            </Route>
          </Routes>
        </MotionDiv>
      </AnimatePresence>
    </ThemeProvider>
  )
}
