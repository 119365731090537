import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { KeyboardReact } from "react-simple-keyboard"
import { keyboardLayouts } from "./keyboardLayouts"
import "react-simple-keyboard/build/css/index.css"
import { Box, useTheme } from "@mui/material"

/*
 * ==== Simple Keyboard ====
 * - [Doc](https://hodgef.com/simple-keyboard/documentation)
 * - [Options](https://hodgef.com/simple-keyboard/documentation/options)
 * - [Methods](https://hodgef.com/simple-keyboard/documentation/methods)
 * - [Layouts](https://github.com/simple-keyboard/simple-keyboard-layouts)
 * =========================
 */

/**
 * How to Add an Icon to a Keyboard Butto
 * https://github.com/hodgef/react-simple-keyboard/issues/6
 */
const outlinedArrowUpSvg = `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.0453 2.78842C11.8824 2.02033 13.1719 2.03343 13.9932 2.81846L22.1309 10.5971C23.5366 11.9408 22.5854 14.3121 20.6409 14.3121H18.768V19.9168C18.768 21.1076 17.8025 22.073 16.6117 22.073H8.39907C7.20826 22.073 6.2428 21.1078 6.2428 19.9168V14.3121H4.02642C2.06046 14.3121 1.12 11.8964 2.56853 10.5671L11.0453 2.78842ZM12.3975 4.26206C12.4582 4.20638 12.5517 4.20734 12.6112 4.26422L20.7489 12.0429C20.7851 12.0774 20.7939 12.1038 20.7973 12.1219C20.8018 12.1456 20.8002 12.1785 20.786 12.214C20.7717 12.2495 20.7502 12.2743 20.7305 12.2884C20.7155 12.2991 20.6909 12.3121 20.6409 12.3121H16.768V19.9168C16.768 20.003 16.6981 20.073 16.6117 20.073H8.39907C8.31267 20.073 8.2428 20.003 8.2428 19.9168V12.3121H4.02642C3.97538 12.3121 3.95081 12.2987 3.93616 12.2882C3.91659 12.274 3.89491 12.2488 3.88079 12.2125C3.86668 12.1763 3.8656 12.143 3.87045 12.1194C3.87408 12.1017 3.88315 12.0752 3.92076 12.0407L12.3975 4.26206Z" fill="currentColor"/>
</svg>
`
const filledArrowUpSvg = `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.0453 2.78842C11.8824 2.02033 13.1719 2.03343 13.9932 2.81846L22.1309 10.5971C23.5366 11.9408 22.5854 14.3121 20.6409 14.3121H18.768V19.9168C18.768 21.1076 17.8025 22.073 16.6117 22.073H8.39907C7.20826 22.073 6.2428 21.1078 6.2428 19.9168V14.3121H4.02642C2.06046 14.3121 1.12 11.8964 2.56853 10.5671L11.0453 2.78842ZM12.3975 4.26206C12.4582 4.20638 12.5517 4.20734 12.6112 4.26422L20.7489 12.0429C20.7851 12.0774 20.7939 12.1038 20.7973 12.1219C20.8018 12.1456 20.8002 12.1785 20.786 12.214C20.7717 12.2495 20.7502 12.2743 20.7305 12.2884C20.7155 12.2991 20.6909 12.3121 20.6409 12.3121H16.768V19.9168C16.768 20.003 16.6981 20.073 16.6117 20.073H8.39907C8.31267 20.073 8.2428 20.003 8.2428 19.9168V12.3121H4.02642C3.97538 12.3121 3.95081 12.2987 3.93616 12.2882C3.91659 12.274 3.89491 12.2488 3.88079 12.2125C3.86668 12.1763 3.8656 12.143 3.87045 12.1194C3.87408 12.1017 3.88315 12.0752 3.92076 12.0407L12.3975 4.26206Z" fill="currentColor"/>
</svg>
`

const bkspSvg = `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.0088 7.67753L14.6571 11.1521L18.3054 7.67753L19.4088 8.83615L15.8171 12.2568L19.4088 15.6775L18.3054 16.8361L14.6571 13.3616L11.0088 16.8361L9.90538 15.6775L13.4971 12.2568L9.90538 8.83615L11.0088 7.67753Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.73536 4.25684C7.43911 4.25684 7.15946 4.39361 6.97758 4.62745L1.50203 11.6675C1.2324 12.0141 1.2324 12.4996 1.50203 12.8462L6.97758 19.8862C7.15946 20.1201 7.43911 20.2568 7.73536 20.2568H22.6971C23.2273 20.2568 23.6571 19.827 23.6571 19.2968V5.21684C23.6571 4.68664 23.2273 4.25684 22.6971 4.25684H7.73536ZM3.0706 12.2568L8.04837 5.85684H22.0571V18.6568H8.04837L3.0706 12.2568Z" fill="currentColor"/>
</svg>
`

const DEFAULT_DISPLAY = {
  "{bksp}": bkspSvg,
  "{tab}": "Tab",
  "{lock}": "Caps",
  "{enter}": "Enter",
  "{shift}": outlinedArrowUpSvg,
  "{space}": "⏘",
  "{number}": "&123",
  "{symbol}": "#+=",
  "{abc}": "ABC",
}

export const Keyboard = forwardRef(
  (
    {
      onChange: onChangeProps = () => {},
      onKeyPress: onKeyPressProps = () => {},
      onEnter: onEnterProps = () => {},
      defaultLanguage = "en",
    },
    ref
  ) => {
    const [visible, setVisible] = useState(true)
    const theme = useTheme()
    const styleOverrides =
      theme.components[`VuiOnscreenKeyboard`]?.styleOverrides || {}
    const { root } = styleOverrides

    const [activeLanguage, setActiveLanguage] = useState(defaultLanguage)

    const [layout, setLayout] = useState("default")
    const keyboard = useRef()

    const handleShift = useCallback(() => {
      const newLayoutName = layout === "default" ? "shift" : "default"
      setLayout(newLayoutName)
    }, [layout])

    const onKeyPress = useCallback(
      (button, ...rest) => {
        switch (button) {
          case "{shift}": // Handle the shift and caps lock buttons
          case "{lock}":
            handleShift()
            break
          case "{enter}": // Handle the enter buttons
            onEnterProps()
            break
          case "{lang}": // Handle the switch language button
            setActiveLanguage((prev) => (prev === "en" ? "th" : "en"))
            break
          case "{number}": // Handle the number button
            setLayout("number")
            break
          case "{symbol}": // Handle the symbol button
            setLayout("symbol")
            break
          case "{abc}": // Handle the alphabet button
            setLayout("default")
            break
          default:
            break
        }

        onKeyPressProps(button)
      },
      [handleShift, onKeyPressProps, onEnterProps]
    )

    const onChange = useCallback(
      (input) => {
        onChangeProps(input)
      },
      [onChangeProps]
    )

    const hide = useCallback(() => setVisible(false), [])
    const show = useCallback(() => setVisible(true), [])
    const toggle = useCallback(() => setVisible((prev) => !prev), [])
    const clearInput = useCallback(() => {
      if (keyboard.current) keyboard.current.clearInput()
    }, [])

    // The parent used functions
    useImperativeHandle(ref, () => ({
      hide,
      show,
      toggle,
      clearInput,
    }))

    return (
      <Box sx={{ ...root, display: visible ? "black" : "none" }}>
        <KeyboardReact
          keyboardRef={(ref) => (keyboard.current = ref)}
          layoutName={layout}
          onChange={onChange}
          onKeyPress={onKeyPress}
          layout={keyboardLayouts[activeLanguage || "en"]}
          autoUseTouchEvents={false}
          buttonTheme={[
            { class: "hg-primary-button", buttons: "{enter}" },
            {
              class: "hg-secondary-button",
              buttons: "{shift} {lock} {number} {symbol} {abc} {bksp}",
            },
          ]}
          display={{
            ...DEFAULT_DISPLAY,
            "{lang}": activeLanguage === "en" ? "THAI" : "ENGLISH",
            "{abc}": activeLanguage === "en" ? "ABC" : "กขค",
            "{shift}":
              layout === "shift" ? filledArrowUpSvg : outlinedArrowUpSvg,
          }}
        />
      </Box>
    )
  }
)
