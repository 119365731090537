import { Button, Modal, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"

import { useTranslation } from "providers/i18n"

export const AdModal = ({
  open = false,
  onClose,
  sx = {},
  children,
  closeButton: CloseButton = Button,
  closeButtonProps = {},
  ...props
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const styleOverrides = theme.components[`VuiAdModal`]?.styleOverrides || {}
  const { root, closeButton } = styleOverrides

  return (
    <Modal
      disableAutoFocus
      sx={deepmerge(root, sx)}
      open={open}
      onClose={onClose}
      {...props}
    >
      <>
        {children}
        <CloseButton
          onClick={onClose}
          sx={closeButton}
          variant="contained"
          {...closeButtonProps}
        >
          {t("Close")}
        </CloseButton>
      </>
    </Modal>
  )
}
