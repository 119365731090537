import React from "react"
import { useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import _ from "lodash"
import get from "lodash/get"
import { Avatar, Typography, useTheme } from "@mui/material"
import clsx from "clsx"

import { getFeatureDetail } from "utils/feature"
import { CustomIcon } from "components/icons"
import { FeatureListItem } from "components/domains/features"
import { useVenue } from "providers/venue"
import { useTranslation } from "providers/i18n"
import { ShopIcon } from "extensions/one-siam/components/icons"

export const ShopListItem = ({ occupant, hideLevel = false, ...props }) => {
  const theme = useTheme()
  const { t } = useTranslation({ defaultValue: null })
  const { isMultiVenueProject } = useVenue()
  const navigate = useNavigate()

  const styleOverrides =
    theme.components[`FeatureListItem`]?.styleOverrides || {}
  const { boothNameText } = styleOverrides

  const {
    name,
    logo,
    level,
    group,
    zone,
    venueLogo,
    boothName,
    venueRef,
    isFeatured,
  } = useMemo(() => getFeatureDetail(occupant), [occupant])
  const handleClick = useCallback(
    () => navigate(`/maps/place/${occupant.id}`),
    [navigate, occupant?.id]
  )

  const zoneText = useMemo(() => t(zone), [t, zone])

  const categoryIcon = useMemo(
    () => get(occupant, "properties.local_categories[0].properties.icon_path"),
    [occupant]
  )

  const groupText = useMemo(() => t(group), [t, group])

  return (
    <FeatureListItem
      divider
      className={clsx(venueRef, isFeatured ? "is-highlight" : "")}
      onClick={handleClick}
      primary={t(name)}
      secondary={
        <React.Fragment>
          <Typography component="span" variant="subtitle" sx={boothNameText}>
            {_.compact([
              boothName && `${t("Booth")} ${t(boothName)} `,
              boothName && ((!hideLevel && level) || zoneText) && "| ",
            ])}
          </Typography>
          {_.compact([
            !hideLevel && level && `${level} Floor `,
            !hideLevel && level && zoneText && "| ",
            zoneText && `${t("Zone")} ${zoneText} `,
            group && groupText,
          ])}
        </React.Fragment>
      }
      avatar={
        <Avatar src={logo} variant="logo">
          {!logo &&
            (categoryIcon ? <CustomIcon path={categoryIcon} /> : <ShopIcon />)}
        </Avatar>
      }
      endAdornment={isMultiVenueProject && venueLogo}
      {...props}
    />
  )
}

export default ShopListItem
