import { useCallback, useMemo } from "react"
import { Avatar, Box, Typography, useTheme } from "@mui/material"
import { Link } from "react-router-dom"
import _ from "lodash"

import {
  CategoryFilterBar,
  CategoryFilterChip,
} from "components/CategoryFilterBar"

import { getIcon } from "utils/icon"
import { getFeatureDetail } from "utils/feature"

import { useAppUI } from "providers/ui"
import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import { useDining } from "hooks/dining/useDining"

import { AppNavigation } from "containers/kiosk-v"
import { Head } from "components/Head"
import { SearchToggleButton } from "components/domains/search"
import { LanguageButton } from "components/Language"
import { ImageCard } from "components/ImageCard"
import { HorizontalScrollPane } from "components/HorizontalScrollPane"
import { CustomIcon, DiningIcon } from "components/icons"
import { EmptyData } from "components/EmptyData"
import { VenueSelector } from "components/VenueSelector"
import { MotionDiv, showContent } from "components/motions"

const DEFAULT_FILTER_ICON = <DiningIcon />
const ALL_FILTER_ICON = DEFAULT_FILTER_ICON

export const DiningKioskVPage = ({ Layout = Box }) => {
  const { occupants, isMultiOrdinalVenue } = useVenue()
  const { openSearch } = useAppUI()
  const {
    restaurants,
    currentCategory,
    categories,
    isMultiVenueProject,
    locationVenueId,
    venues,
    venueFilter,
    filterByCategoryId,
    filterByVenueId,
  } = useDining(occupants)

  const { t } = useTranslation({ defaultValue: null })

  const handleChangeCategory = useCallback(
    (e, id) => {
      if (id === null) return
      filterByCategoryId(id)
    },
    [filterByCategoryId]
  )

  const theme = useTheme()
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.dining) || <DiningIcon />,
    [theme]
  )

  const { styleOverrides = {} } = theme.components?.[`AppDining`] || {}
  const {
    root,
    filterSection,
    filterContainer,
    diningImageCard,
    filterButton,
    diningAvatar,
    diningAvatarFallBack,
    gridContainer,
    emptyDataContainer,
    navigationWrapper,
    venueSelector,
    toolbarContainer,
    actionContainer,
    actionButton,
    searchBarContainer,
  } = styleOverrides

  return (
    <>
      <Head title={t("Dining")} />
      <Layout>
        <Box sx={root}>
          {restaurants.length > 0 ? (
            <MotionDiv
              animate="animate"
              initial="initial"
              variants={showContent}
              sx={gridContainer}
            >
              {restaurants.map((restaurant) => {
                const {
                  group,
                  level,
                  logo,
                  featuredImage,
                  name,
                  isFeatured,
                  boothName,
                } = getFeatureDetail(restaurant) || {}

                const levelText = isMultiOrdinalVenue
                  ? t("Level Text", { level: t(level) })
                  : ""
                const groupText = t(group)
                const fallBackIcon = _.first(
                  restaurant?.properties?.local_parent_categories
                )?.properties?.icon_path
                const subcategory = _.first(
                  restaurant?.properties?.local_sub_categories
                )
                const subcategoryName = _.get(subcategory, "properties.name")
                return (
                  <ImageCard
                    key={restaurant?.id}
                    sx={diningImageCard}
                    component={Link}
                    to={`/maps/place/${restaurant?.id}`}
                    title={t(name)}
                    subheader={
                      <>
                        <Typography
                          component="span"
                          variant="subtitle"
                          className="sub-category-text"
                        >
                          {t(subcategoryName)}
                        </Typography>
                        {boothName && (
                          <Typography
                            component="span"
                            variant="subtitle"
                            className="booth-name-text"
                          >
                            {`${t("Booth")} ${t(boothName)}`}
                          </Typography>
                        )}
                        <Typography
                          className="location-text"
                          component="span"
                          variant="subtitle"
                        >
                          {_.compact([levelText, groupText]).join(" ")}
                        </Typography>
                      </>
                    }
                    image={featuredImage}
                    avatar={
                      <Avatar src={logo} sx={diningAvatar}>
                        <CustomIcon
                          path={fallBackIcon}
                          sx={diningAvatarFallBack}
                        />
                      </Avatar>
                    }
                    tag={isFeatured ? t("Highlight") : null}
                  />
                )
              })}
            </MotionDiv>
          ) : (
            <MotionDiv
              animate="animate"
              initial="initial"
              variants={showContent}
              sx={emptyDataContainer}
            >
              <EmptyData
                icon={emptyIcon}
                primary={t("Coming soon")}
                secondary={t("Stay tuned for the update")}
              />
            </MotionDiv>
          )}
          {categories.length > 0 && (
            <Box sx={filterSection}>
              <HorizontalScrollPane
                className="subCategory-filterbar"
                stackProps={{
                  sx: filterContainer,
                }}
              >
                <CategoryFilterBar
                  sx={filterButton}
                  activeCategory={currentCategory}
                  categories={categories}
                  allButtonIcon={ALL_FILTER_ICON}
                  defaultButtonIcon={DEFAULT_FILTER_ICON}
                  onChange={handleChangeCategory}
                  renderFilterButton={CategoryFilterChip}
                />
              </HorizontalScrollPane>
            </Box>
          )}
          {isMultiVenueProject && (
            <Box sx={venueSelector}>
              <VenueSelector
                selectedVenue={venueFilter}
                venues={venues}
                onClickVenue={filterByVenueId}
                currentVenue={locationVenueId}
              />
            </Box>
          )}
          <Box key="app-navigation" sx={navigationWrapper}>
            <Box sx={toolbarContainer}>
              <Box sx={actionContainer}>
                <LanguageButton variant="contained" sx={actionButton} />
                {/* //TODO:: Add a wheel chair's mode handler function later */}
                {/*  <Button
            variant="contained"
            sx={actionButton}
            onClick={() => {}}
            startIcon={<RestroomWheelchairIcon />}
          /> */}
              </Box>
              <Box sx={searchBarContainer}>
                <SearchToggleButton
                  placeholder={t("Search shops and more...")}
                  onClick={openSearch}
                />
              </Box>
            </Box>
            <AppNavigation />
          </Box>
        </Box>
      </Layout>
    </>
  )
}
