import { Box, Button, useTheme } from "@mui/material"
import Backdrop from "@mui/material/Backdrop"
import { useNavigate } from "react-router-dom"
import { useCallback, useMemo } from "react"

import { getIcon } from "utils/icon"

import { useTranslation } from "providers/i18n"

import { TabIcon } from "components/icons"

export const FullScreenStart = ({
  path = "/maps",
  label = "Start Explore",
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const styleOverrides =
    theme.components[`AppFullScreenStart`]?.styleOverrides || {}
  const { root, actionButton, actionButtonWrapper, startIconnWrapper } =
    styleOverrides
  const navigate = useNavigate()

  const handleClose = useCallback(() => {
    navigate(path)
  }, [navigate, path])

  const startButtonIcon = useMemo(
    () => getIcon(theme?.assets?.startIcon) || <TabIcon />,
    [theme]
  )

  return (
    <Backdrop sx={root} open={true} onClick={handleClose}>
      <Box sx={actionButtonWrapper}>
        <Box sx={startIconnWrapper}>{startButtonIcon}</Box>
        <Button to={path} variant="contained" sx={actionButton}>
          {t(label)}
        </Button>
      </Box>
    </Backdrop>
  )
}
