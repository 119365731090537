import { styled, Box } from "@mui/material"

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 2),
  top: 0,
  position: "absolute",
  width: "fit-content",
  left: 0,
  right: 0,
  zIndex: theme.zIndex.modal,
}))

export const TopBarContainer = ({ children, sx }) => {
  return <StyledContainer sx={sx}>{children}</StyledContainer>
}
