import { useEffect, useMemo } from "react"
import _ from "lodash"
import { getLocationIdByFeature } from "components/IndoorMap"
import { useIndoorMap } from "providers/venue/modules/indoormap/hooks/useIndoorMap"
import { useVenue } from "providers/venue"
import { ImdfFeatureData } from "providers/venue/types"

type Options = {
  enabled?: boolean
  offset?: {
    bottom: number
    left: number
    right: number
    top: number
  }
}

type UsePlaceDetailMapType = (
  feature: ImdfFeatureData,
  options?: Options
) => void

export const usePlaceDetailMap: UsePlaceDetailMapType = (
  feature: ImdfFeatureData,
  options = {
    enabled: true,
    offset: { top: 0, left: 0, right: 0, bottom: 195 },
  }
) => {
  const { dataLoaded } = useVenue()
  const { indoorRef, mapLoaded } = useIndoorMap()
  const { offset, enabled = true } = options

  const { top, bottom, left, right } = offset

  const offsetOptions = useMemo(
    () => ({ top, bottom, left, right }),
    [top, bottom, left, right]
  )
  const ready = indoorRef.current && mapLoaded && dataLoaded && enabled

  const element_id = useMemo(() => getLocationIdByFeature(feature), [feature])
  const occupant_id = useMemo(
    () => (feature?.feature_type === "occupant" ? feature?.id : null),
    [feature]
  )
  const ordinal = _.get(feature, "properties.ordinal", 0)

  useEffect(() => {
    if (ready) indoorRef.current.changeLevelByOrdinal(Number(ordinal))
  }, [indoorRef, ready, ordinal])

  useEffect(() => {
    const indoorMap = indoorRef.current
    if (ready && indoorMap) {
      indoorMap.clearAnimations()
      indoorMap.clearNavigationGeometries()
      indoorMap.enableClick()
      indoorMap.setFeatureObject3DsOpacity(1)
      indoorMap.showVenueObjects()
    }
    return () => {
      if (ready && indoorMap) {
        indoorMap.clearHighlightElements()
        indoorMap.clearHighlightObject()
      }
    }
  }, [indoorRef, ready])

  useEffect(() => {
    if (indoorRef.current && ready && element_id) {
      const indoorMap = indoorRef.current
      indoorMap.clearHighlightElements()
      indoorMap.clearHighlightObject()
      const elementIdsToHighlight = _.compact([element_id, occupant_id])
      const highlightedFeature = indoorMap.setHighlightElementIds(
        elementIdsToHighlight,
        { defaultMarker: !occupant_id }
      )
      try {
        const extent = indoorMap.getFeatureExtent(highlightedFeature)
        const center = indoorMap.getExtentCenter(extent)
        const zoom = indoorMap.getExtentZoom(extent)
        const pitch = 40
        const newCenter = indoorMap.getTargetViewCenter(
          {
            center,
            zoom,
            pitch,
          },
          { offset: offsetOptions }
        )
        indoorMap.flyTo(newCenter, {
          zoom,
          pitch,
        })
      } catch (error) {
        console.warn("Can not highligh the given elements")
      }
    }
  }, [element_id, occupant_id, indoorRef, ready, offsetOptions])
}
