import { useCallback, useState } from "react"
import { Box, CardMedia, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"
import { Carousel, CarouselItem } from "components/Carousel"
import { ImageModal } from "components/Image"

export const GalleryCarousel = ({
  sx = {},
  data = [],
  carouselOptions = {},
  ...props
}) => {
  const theme = useTheme()
  const { styleOverrides = {} } = theme.components[`GalleryCarousel`] || {}
  const { root, galleryCarousel, galleryCarouselItem } = styleOverrides

  const [openModal, setOpenModal] = useState(false)
  const [activeImage, setActiveImage] = useState()
  const handleClose = useCallback(() => {
    setOpenModal(false)
  }, [])

  const handleImage = useCallback((value) => {
    setActiveImage(value)
    setOpenModal(true)
  }, [])

  return (
    <>
      <Box sx={deepmerge(root, sx)} {...props}>
        <Carousel
          options={{
            type: "loop",
            padding: 0,
            gap: 8,
            pagination: true,
            autoplay: true,
            ...carouselOptions,
          }}
          sx={galleryCarousel}
        >
          {data.map(({ id, url }) => {
            return (
              <CarouselItem key={`slide-${id}`} sx={galleryCarouselItem}>
                <CardMedia
                  component="img"
                  sx={galleryCarouselItem}
                  src={url}
                  onClick={() => handleImage(url)}
                />
              </CarouselItem>
            )
          })}
        </Carousel>
      </Box>
      <ImageModal
        open={openModal}
        imageSrc={activeImage}
        onClose={handleClose}
      />
    </>
  )
}
