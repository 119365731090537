import _ from "lodash"
import { deepmerge } from "@mui/utils"
import { point, Units as DistanceUnits } from "@turf/helpers"
import findDistance from "@turf/distance"

import { getCenterFromGeometry } from "utils/geometry"
import { getUnitConnection } from "./graph"
import {
  ElevatorRelationship,
  EscalatorRelationship,
  Opening,
  RampRelationship,
  Relationship,
  StairsRelationship,
  TraversalRelationship,
  Unit,
} from "providers/venue/types"
import { IStep } from "../type"

const NEARBY_DISTANCE = 0.03 //30 meter

const connectOpeningInWalkway = (
  openings: Opening[] | undefined,
  unit: Unit
): Record<string, Record<string, Unit[]>> => {
  if (!Array.isArray(openings) || openings.length < 2) {
    return {}
  }
  const connections = {}
  for (let i = 0; i < openings.length - 1; i++) {
    const mainOpening = openings[i]
    const mainOpeningId = mainOpening?.id || null
    for (let j = i + 1; j < openings.length; j++) {
      const opening = openings[j]
      const openingId = opening?.id || null
      _.set(connections, `${mainOpeningId}.${openingId}`, [unit])
      _.set(connections, `${openingId}.${mainOpeningId}`, [unit])
    }
  }
  return connections
}

const createTraversalLikeConnection = (
  relationships: TraversalRelationship[],
  units: Unit[]
) => {
  const unitConnects = getUnitConnection(units, relationships)
  const unitObj = {}
  for (const unit of units) {
    unitObj[unit.id] = unit
  }
  const openingUnitConnection = {}
  for (const [unitId, openings] of Object.entries(unitConnects)) {
    const connection = connectOpeningInWalkway(openings, unitObj[unitId])
    for (const originOpeningId in connection) {
      if (connection.hasOwnProperty(originOpeningId)) {
        if (!openingUnitConnection[originOpeningId]) {
          openingUnitConnection[originOpeningId] = {}
        }
        for (const destinationOpeningId in connection[originOpeningId]) {
          if (
            connection[originOpeningId].hasOwnProperty(destinationOpeningId)
          ) {
            const connectionRecord =
              openingUnitConnection[originOpeningId][destinationOpeningId]
            if (!connectionRecord) {
              openingUnitConnection[originOpeningId][destinationOpeningId] = []
            }
            openingUnitConnection[originOpeningId][destinationOpeningId].push(
              ...connection[originOpeningId][destinationOpeningId]
            )
          }
        }
      }
    }
  }
  return openingUnitConnection
}

const createEscalatorLikeConnection = (
  relationships: (EscalatorRelationship | RampRelationship)[]
) => {
  return relationships.reduce((acc, relationship) => {
    try {
      const { properties } = relationship
      const { origin, destination, intermediary, direction } = properties
      const originId = origin.id
      const destinationId = destination.id
      _.set(acc, `${originId}.${destinationId}`, intermediary)
      if (direction === "undirected") {
        _.set(acc, `${destinationId}.${originId}`, [...intermediary].reverse())
      }
      return acc
    } catch (error) {
      return acc
    }
  }, {})
}

const createElevatorLikeConnection = (
  elevationRelationships: (ElevatorRelationship | StairsRelationship)[],
  traversalRelationships: TraversalRelationship[]
) => {
  const elevatorsRelationObject: Record<string, Unit[]> =
    elevationRelationships.reduce((acc, relationship) => {
      const { properties, id } = relationship
      const { origin, intermediary, destination } = properties
      return { ...acc, [id]: [origin, ...intermediary, destination] }
    }, {})

  const elevatorsConnectedOpenings = _(elevatorsRelationObject).reduce(
    (acc, units, relationshipId) => {
      const elevatorOpenings = _(units)
        .map((elevator) => {
          const relationshipFound = traversalRelationships.find(
            (relationship) => {
              const originId = _.get(relationship, "properties.origin.id")
              const destinationId = _.get(
                relationship,
                "properties.destination.id"
              )
              return [originId, destinationId].includes(elevator.id)
            }
          )
          return _.get(relationshipFound, "properties.intermediary[0]")
        })
        .value()

      return { ...acc, [relationshipId]: elevatorOpenings }
    },
    {}
  )

  return elevationRelationships.reduce((acc, relationship) => {
    const {
      id,
      properties: { direction },
    } = relationship
    const unitSequences: Unit[] = _.get(elevatorsRelationObject, id, [])
    const openingSequences: Opening[] = _.get(
      elevatorsConnectedOpenings,
      id,
      []
    )

    if (openingSequences.length <= 1) return acc
    const intermediaryWithinRelationship: Record<
      string,
      Record<string, Unit[]>
    > = openingSequences.reduce((accum, originOpening, originIndex) => {
      if (!originOpening) return accum
      if (originIndex === openingSequences.length - 1) return accum

      const openingsArr = openingSequences.slice(originIndex + 1)
      const testConnect = openingsArr.reduce(
        (elevatorConnect, destinationOpening, destinationIndex) => {
          if (!destinationOpening) return elevatorConnect
          // TODO: Rewrite to check ordinal in range might be more readable
          const elevatorsArr = unitSequences.slice(
            originIndex + destinationIndex
          )
          //
          _.set(
            elevatorConnect,
            `${originOpening.id}.${destinationOpening.id}`,
            elevatorsArr
          )
          if (direction === "undirected") {
            _.set(
              elevatorConnect,
              `${destinationOpening.id}.${originOpening.id}`,
              elevatorsArr.slice().reverse()
            )
          }

          return elevatorConnect
        },
        {}
      )
      return deepmerge(accum, testConnect)
    }, {})
    return deepmerge(acc, intermediaryWithinRelationship)
  }, {})
}

export const groupOpeningConnect = (relationships: Relationship[], units) => {
  const {
    traversal: traversalRelationships = [],
    elevator: elevatorRelationships = [],
    stairs: stairsRelationships = [],
    escalator: escalatorRelationships = [],
    ramp: rampRelationships = [],
  } = _.groupBy(relationships, "properties.category") as {
    traversal?: TraversalRelationship[]
    elevator?: ElevatorRelationship[]
    stairs?: StairsRelationship[]
    escalator?: EscalatorRelationship[]
    ramp?: RampRelationship[]
  }

  const traversalWithIntermediary = createTraversalLikeConnection(
    traversalRelationships,
    units
  )

  // Case Elevator
  const elevatorConnectedIntermediary = createElevatorLikeConnection(
    elevatorRelationships,
    traversalRelationships
  )

  // Case Stairs
  const stairsConnectedIntermediary = createElevatorLikeConnection(
    stairsRelationships,
    traversalRelationships
  )

  // Case Escalator
  const escalatorConnectedIntermediary = createEscalatorLikeConnection(
    escalatorRelationships
  )

  // Case Ramp
  const rampConnectedIntermediary =
    createEscalatorLikeConnection(rampRelationships)

  return [
    traversalWithIntermediary,
    rampConnectedIntermediary,
    escalatorConnectedIntermediary,
    elevatorConnectedIntermediary,
    stairsConnectedIntermediary,
  ].reduce((acc, curr) => deepmerge(acc, curr))
}

export const findLocatedUnit = (feature, units = []) => {
  const { feature_type, properties } = feature
  switch (feature_type) {
    case "kiosk": {
      return properties.anchor.properties.unit
    }
    case "amenity": {
      return properties.units?.[0]
    }
    case "geolocation":
    case "anchor": {
      return properties.unit
    }
    case "occupant": {
      const { anchor } = properties
      return anchor.properties.unit
    }
    case "unit": {
      return feature
    }
    default: {
      return null
    }
  }
}

export const createArrivalStep = (steps: IStep[]): IStep => {
  const lastStep = _.last(steps)
  const path = point(_.last(lastStep.path.geometry.coordinates))

  return {
    goingTo: lastStep.destination,
    comingFrom: lastStep.destination,
    destination: lastStep.destination,
    origin: lastStep.destination,
    intermediary: [],
    path,
    description: { text: "Arrived at destination" },
  }
}

export const groupFeatureByOrdinal = (features) => {
  return _.groupBy(features, (feature) => {
    switch (feature.feature_type) {
      case "occupant": {
        // Temporary Fix Should find a better way to filter occupant without anchor
        return feature?.properties?.anchor?.properties?.unit?.properties
          ?.ordinal
      }
      default: {
        return feature.properties.ordinal
      }
    }
  })
}

export const calculateDisplacement = (
  originGeometry,
  destinationGeometry,
  options: { units: DistanceUnits } = {
    units: "kilometers",
  }
) => {
  const originationPoint = getCenterFromGeometry(originGeometry)
  const destinationPoint = getCenterFromGeometry(destinationGeometry)
  return findDistance(originationPoint, destinationPoint, options)
}

export const findNearestFeature = (features = [], originateFeature) => {
  return _.minBy(features, (landmark) => {
    const {
      feature_type,
      properties: { anchor },
    } = landmark

    let geometry

    switch (feature_type) {
      case "occupant":
        geometry = anchor.geometry
        break
      default:
        geometry = landmark?.geometry
        break
    }

    if (geometry) {
      const distance = calculateDisplacement(
        geometry,
        originateFeature.geometry
      )
      return distance
    }
  })
}

const getFeatureLocatedGeometry = (feature) => {
  if (!feature) return null
  const {
    feature_type,
    properties: { anchor },
  } = feature
  switch (feature_type) {
    case "occupant":
      return anchor.geometry

    default:
      return feature.geometry
  }
}

const calculateDistanceBetweenFeatures = (first, second) => {
  const firstGeometry = getFeatureLocatedGeometry(first)
  const secondGeometry = getFeatureLocatedGeometry(second)
  if (firstGeometry && secondGeometry) {
    const distance = calculateDisplacement(firstGeometry, secondGeometry)
    return distance
  }
  return null
}

export const findNearbyPointOfInterest = (
  primary,
  secondary,
  originateFeature
) => {
  if (!primary) return secondary
  const primaryDistance = calculateDistanceBetweenFeatures(
    primary,
    originateFeature
  )
  const secondaryDistance = calculateDistanceBetweenFeatures(
    secondary,
    originateFeature
  )
  // If none of them consider nearby use primary feature
  return primaryDistance < NEARBY_DISTANCE
    ? primary
    : secondaryDistance < NEARBY_DISTANCE
    ? secondary
    : primary
}
