import { useEffect, useState } from "react"

interface ElectronWindow extends Window {
  electron?: {
    sendMessage?: Function
    invoke?: Function
  }
}

type ElectronConfig = {
  device_location?: string
  device_name?: string
  header_image?: string
}

type IUseElectronConfig = () => {
  config: ElectronConfig | null
}

export const useElectronConfig: IUseElectronConfig = () => {
  const [config, setConfig] = useState<ElectronConfig | null>(null)
  // To remember the latest callback .
  useEffect(() => {
    const electron = (window as ElectronWindow).electron
    if (electron) {
      const initSetting = async () => {
        const setting: ElectronConfig | null = await (
          window as ElectronWindow
        ).electron?.invoke("getSettingsData")
        if (setting) {
          setConfig(setting)
          console.debug("react setting", setting)
        }
      }
      initSetting()
    }
  }, [])

  return { config }
}
