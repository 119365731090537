import config from "config"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

/**
 * Instruction from mui doc
 * ==============================
 * Ref: https://mui.com/material-ui/customization/theming/
 * Theme composition: using theme options to define other options
 * When the value for a theme option is dependent on another theme option, you should compose the theme in steps.
 */

export const venueMobileUIThemeOptionFactory = (theme) => ({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          userSelect:
            config("env") === "production" &&
            ["kiosk-v", "kiosk-h"].includes(config("device"))
              ? "none"
              : "auto",
        },
        "@keyframes pulse": {
          "0%": {
            opacity: 1,
          },
          "50%": {
            opacity: 0.6,
          },
          "100%": {
            opacity: 1,
          },
        },
        "@keyframes ripple": {
          from: {
            opacity: 1,
            transform: "scale3d(0.75,0.75,1)",
          },
          to: {
            opacity: 0,
            transform: "scale3d(1.6,1.6,1)",
          },
        },
        "@keyframes slideUp": {
          from: {
            transform: "translateY(100%)",
          },
          to: {
            transform: "translateY(0%)",
          },
        },
        ".mtk-occupant-text-marker": {},
        /**
         * "mtk-ui-fadein" is the build class name for handling UIComponent fade-in animation in maptalks.js.
         * [@Soruce](https://github.com/maptalks/maptalks.js/blob/2a4ab207619543237a2943bbb1c293863c6a98bd/src/ui/UIComponent.ts#L201)
         * [@Style](https://github.com/maptalks/maptalks.js/blob/2a4ab207619543237a2943bbb1c293863c6a98bd/assets/maptalks.css#L202)
         */
        ".mtk-ui-fadein": {
          animation: "maptalksfadeIn 0.5s",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius: 12,
        },
      },
      variants: [
        {
          props: { variant: "logo" },
          style: {
            borderRadius: 8,
            width: 55,
            height: 55,
            marginRight: theme.spacing(2.25),
            "& .MuiAvatar-img": {
              objectFit: "contain",
            },
          },
        },
        {
          props: { variant: "circular-logo" },
          style: {
            borderRadius: "50%",
            width: 80,
            height: 80,
            marginRight: theme.spacing(2.25),
            "& .MuiAvatar-img": {
              objectFit: "cover",
            },
          },
        },
        {
          props: { color: "primary" },
          style: {
            background: theme.palette.primary.main,
            borderRadius: 5,
            "& .MuiSvgIcon-root": {
              width: 20,
            },
          },
        },
      ],
    },
    MuiCard: {
      defaultProps: {
        elevation: 15,
      },
      styleOverrides: {
        root: {
          borderRadius: "0",
          color: "#000000",
        },
      },
      variants: [
        {
          props: { variant: "image" },
          style: {
            borderRadius: `${(theme.shape.borderRadius * 2) / 3}px`,
            "& .MuiCardActionArea-root:after": {
              content: '""',
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.84))",
            },
            "& .MuiCardMedia-root": {
              height: "140px",
            },
            "& .MuiCardHeader-root": {
              position: "absolute",
              padding: theme.spacing(0, 1),
              minHeight: "60px",
              width: "100%",
              bottom: "0px",
              zIndex: 1,
              "& .MuiCardHeader-content": {
                color: "white",
              },
              "& .MuiCardHeader-title": {
                lineHeight: 1,
              },
              "& .MuiAvatar-root": {
                borderRadius: `${(theme.shape.borderRadius * 2) / 3}px`,
                width: "45px",
                height: "45px",
              },
              "& .MuiCardHeader-avatar": {
                marginRight: theme.spacing(1),
              },
              "& .MuiCardHeader-subheader": {
                fontSize: "0.75rem",
                color: "white",
              },
            },
          },
        },
      ],
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          whiteSpace: "pre",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      variants: [
        {
          props: { variant: "medium" },
          style: {
            "& .MuiListItemText-primary": {
              fontSize: theme.typography.body2.fontSize,
            },
            "& .MuiListItemText-secondary": {
              fontSize: theme.typography.caption.fontSize,
            },
          },
        },
        {
          props: { color: "primary" },
          style: {
            "& .MuiListItemText-primary": {
              color: theme.palette.primary.main,
            },
          },
        },
      ],
    },
    MuiRadio: {
      defaultProps: {
        icon: <CheckCircleIcon />,
        checkedIcon: <CheckCircleIcon />,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          boxShadow: "none",
        },
        startIcon: {
          marginRight: theme.spacing(1.25),
        },
        endIcon: {
          marginLeft: theme.spacing(1.25),
        },
        sizeSmall: {
          padding: theme.spacing(1, 3),
        },
        sizeMedium: {},
        sizeLarge: {},
        outlinedSizeLarge: {
          borderWidth: "2px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderBottomWidth: theme.spacing(0.25),
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        default: {
          background: "#fff",
        },
        sizeLarge: {},
      },
      variants: [
        {
          props: { variant: "rounded" },
          style: {
            borderRadius: 12,
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          "&:hover": {
            background: theme.palette.primary.dark,
          },
          "& .MuiSvgIcon-root": {
            "& path": { fill: theme.palette.primary.contrastText },
          },
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            borderRadius: 5,
          },
        },
      ],
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: { gap: theme.spacing(1.25), paddingRight: theme.spacing(2) },
        grouped: {
          "&:not(:last-of-type)": {
            borderRadius: (theme.shape.borderRadius * 2) / 3,
          },
          "&:not(:first-of-type)": {
            marginLeft: 1,
            borderLeft: `1px solid rgba(203, 203, 203, 0.5)`,
            borderRadius: (theme.shape.borderRadius * 2) / 3,
          },
        },
      },
      variants: [
        {
          props: { variant: "borderSeperate" },
          style: {
            "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
              borderLeft: `1px solid transparent`,
            },
          },
        },
      ],
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: (theme.shape.borderRadius * 2) / 3,
          color: theme.palette.primary.main,
          borderColor: "rgba(203, 203, 203, 0.5)",
          "&.Mui-selected, &.Mui-selected:hover": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: theme.spacing(2),
        },
      },
    },

    /**
     * VenueUI (Vui)
     * Extra Component for venue.in.th projects
     */
    VuiDescriptionList: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        labelText: {
          fontWeight: 700,
          fontSize: "12px",
          textTransform: "uppercase",
        },
        contentText: { fontWeight: 300 },
      },
    },
    VuiOnscreenKeyboard: {
      styleOverrides: {
        root: {
          // [Example] (https://hodgef.com/simple-keyboard/editor/?d=hodgef/react-simple-keyboard-demos/tree/uc-customization)
          // Add your custom style here
          "& .hg-theme-default": {
            fontFamily: "inherit",
          },
          "& .simple-keyboard": {
            maxWidth: "100%",
            padding: theme.spacing(8, 11.75),
            borderRadius: "20px",
            backgroundColor: theme.palette.grey[200],
            "&.hg-theme-default .hg-row": {
              marginBottom: theme.spacing(1.75),
              "&:last-child": {
                marginBottom: 0,
              },
              "& .hg-button:not(:last-child)": {
                marginRight: theme.spacing(1.75),
              },
            },
            "& .hg-button": {
              height: 65,
              backgroundColor: "#333333",
              color: theme.palette.common.white,
              fontSize: "32px",
              fontWeight: 500,
              borderRadius: "8px",
              "&>span": {
                display: "flex",
                fontSize: "inherit",
              },
            },
            "& .hg-activeButton": {
              backgroundColor: theme.palette.grey[300],
              color: theme.palette.text.primary,
            },
            "& .hg-button-number,& .hg-button-symbol, & .hg-button-abc,& .hg-button-lang":
              {
                fontSize: "12px",
                flexGrow: 1,
              },
            "& .hg-button-lang, & .hg-button-number, & .hg-button-abc": {
              flexGrow: 0.5,
            },
            "& .hg-button-shift,& .hg-button-bksp": {
              fontSize: "20px",
            },
            "& .hg-button-shift": {
              fontSize: "20px",
              flexGrow: 2,
              flexShrink: 0,
            },
            "& .hg-button-enter": {
              flexGrow: 2,
            },
            "& .hg-button-space": {
              flexGrow: 8,
            },
            "& .hg-primary-button": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
            "& .hg-secondary-button": {},
            "& .hg-small-button": {},
          },
        },
      },
    },
    VuiSearchToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: `9999px`,
          background: theme.palette.background.paper,
          width: "100%",
          padding: theme.spacing(1, 2.5),
          justifyContent: "flex-start",
          border: `1px solid #ececec`,
        },
        searchIcon: {
          color: theme.palette.common.black,
          fontSize: "1.125rem",
          mr: 2.5,
        },
        label: {
          color: theme.palette.text.secondary,
        },
      },
    },
    VuiSearchBasePopup: {
      styleOverrides: {
        drawer: {
          width: 350,
          display: "flex",
          flexDirection: "column",
        },
        drawerPaper: {
          overflow: "hidden",
          width: "100%",
          [theme.breakpoints.up("sm")]: {
            width: 400,
          },
        },
        dialog: {
          "& .MuiDialogContent-root": {
            [theme.breakpoints.down("sm")]: {
              padding: theme.spacing(1.25, 2),
            },
          },
        },
        dialogPaper: {
          alignSelf: "flex-end",
        },
        dialogContent: {},
      },
    },
    VuiSearchPopupContent: {
      styleOverrides: {
        root: {
          display: "flex",
          padding: theme.spacing(3.125, 3.125, 1.5, 3.125),
          width: "auto",
          gap: theme.spacing(2.5),
          alignItems: "center",
          backgroundColor: theme.palette.grey[50],
          color: theme.palette.text.primary,
        },
        closeButton: {
          padding: 0,
          width: "32px",
          height: "32px",
        },
        closeButtonIcon: {
          color: "inherit",
        },
        searchInput: {
          backgroundColor: theme.palette.common.white,
          borderRadius: 9999,
          padding: theme.spacing(1.25, 2),
          fontSize: "1rem",
          gap: theme.spacing(2),
          fontWeight: 700,
          "& input": {
            padding: 0,
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1.125rem",
            color: theme.palette.text.primary,
          },
          "& .MuiInputAdornment-root": {
            "&.MuiInputAdornment-positionEnd": {
              "& .MuiSvgIcon-root": {
                width: 8,
                height: 8,
              },
            },
          },
        },
        suggestedList: {
          marginTop: theme.spacing(2),
        },
      },
    },
    VuiAdSuggestList: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1, 2),
          backgroundColor: theme.palette.background.paper,
          marginBottom: theme.spacing(1),
          borderRadius: "4px",
        },
        suggestTitle: {
          fontSize: "1rem",
          fontWeight: 700,
        },
        suggestList: {
          "& .splide__track": {
            margin: 0,
            padding: theme.spacing(2, 0, 0.5),
          },
        },
        suggestListItem: {
          display: "flex",
          flexDirection: "column",
          padding: 0,
          justifyContent: "center",
          alignItems: "center",
        },
        suggestItemAvatar: {
          height: 48,
          width: 48,
          marginRight: 0,
        },
        suggestItemContent: {
          marginTop: theme.spacing(0.5),
          width: 48,
          "& .MuiTypography-root": {
            fontSize: "0.625rem",
            width: 48,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            textAlign: "center",
          },
        },
      },
    },
    VuiSearchQuickLink: {
      styleOverrides: {
        root: {
          pb: 1,
          mb: 1,
          mx: -2,
          px: 2,
        },
        stack: {
          pb: 2,
        },
        item: {
          boxShadow: "rgb(0 0 0 / 10%) 0px 2px 10px",
          border: "none",
          background: "white",
          "& .MuiSvgIcon-root": {
            width: "16px",
            height: "16px",
          },
        },
      },
    },
    VuiSearchResult: {
      styleOverrides: {
        root: {
          flexGrow: 1,
          width: "100%",
          overflow: "hidden",
        },
        rootDialog: {
          height: "300px",
        },
        list: {
          padding: theme.spacing(2.75, 0),
        },
        listItem: {
          padding: theme.spacing(1, 3.125),
        },
      },
    },
    VuiSearchResultItem: {
      styleOverrides: {
        chip: {
          borderRadius: "4px",
          height: "18px",
          fontWeight: 400,
          backgroundColor: theme.palette.grey[400],
          color: theme.palette.common.white,
          fontSize: "0.625rem",
          "& .MuiChip-label": {
            padding: theme.spacing(0, 1),
          },
        },
        primaryWrapper: {
          display: "flex",
          alignItems: "flex-start",
          gap: theme.spacing(0.75),
        },
      },
    },
    VuiNoSearchResult: {
      styleOverrides: {
        root: {
          display: "flex",
          height: "300px",
          justifyContent: "center",
          alignItems: "center",
        },
        noResultText: {},
      },
    },
    VuiSearchKeyboard: {
      styleOverrides: {
        root: {},
      },
    },
    VuiDetailCard: {
      styleOverrides: {
        card: {},
        image: {
          marginBottom: theme.spacing(3),
          borderRadius: "10px",
          [theme.breakpoints.up("sm")]: {
            width: "80%",
            margin: "0 auto",
            marginBottom: theme.spacing(3),
          },
        },
        infoListItem: {},
        infoListItemWithNavigation: {
          padding: 0,
          "& .MuiListItemButton-root": {
            padding: 0,
          },
        },
        cardHeader: {
          padding: 0,
          paddingBottom: theme.spacing(2),
          "& .MuiCardHeader-title": {
            fontSize: "20px",
            fontWeight: 700,
          },
          "& .MuiCardHeader-action": {
            marginRight: 0,
          },
        },
        cardHeaderAction: {},
        cardHeaderActionButton: {
          "& .MuiSvgIcon-root": {
            width: "15px",
          },
        },
        cardContent: {
          padding: 0,
          paddingTop: theme.spacing(1),
        },
        cardContentPrimaryText: {
          color: theme.palette.text.primary,
          fontWeight: 700,
        },
        cardContentSecondaryText: {
          color: theme.palette.text.secondary,
        },
        galleryCarouselWrapper: {
          margin: "0 auto",
          width: "100%",
          [theme.breakpoints.up("sm")]: {
            width: "80%",
          },
          marginBottom: theme.spacing(3),
        },
        galleryCarousel: {
          "& .splide__track": {
            padding: 0,
            margin: 0,
          },
          "& .splide__pagination": {
            marginTop: theme.spacing(1),
          },
        },
        galleryCarouselItem: {
          width: "100%",
          height: 196,
          objectFit: "cover",
          borderRadius: "5px",
          [theme.breakpoints.up("sm")]: {
            height: 250,
          },
          [theme.breakpoints.up("md")]: {
            height: 300,
          },
        },
      },
    },
    VuiCarousel: {
      styleOverrides: {
        root: {
          "& .splide__pagination__page": {
            background: theme.palette.grey[500],
          },
          "& .splide__pagination__page.is-active": {
            background: theme.palette.primary.main,
            opacity: 1,
          },
          "& .splide__pagination": {
            position: "static",
          },
          "& .splide__track": {
            overflow: "hidden",
            margin: theme.spacing(-3),
            padding: theme.spacing(3),
          },
          "& .splide__arrow": {
            background: "none",
            margin: theme.spacing(0, -5),
            "& svg": {
              fill: theme.palette.grey[500],
            },
          },
        },
      },
    },
    HorizontalScrollPane: {
      styleOverrides: {
        root: {
          width: "100%",
          "& .simplebar-track.simplebar-horizontal": {
            display: "none",
          },
        },
        listWrapper: {
          width: "100%",
          padding: theme.spacing(1),
        },
      },
    },
    LoadingScreen: {
      styleOverrides: {
        root: {
          position: "fixed",
          width: "100dvw",
          height: `100dvh`,
          backgroundColor: theme.palette.common.white,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10000,
        },
        logo: {
          maxWidth: "70dvw",
        },
      },
    },
    FeatureChipToggleBar: {
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: 0,
        },
        button: {
          padding: theme.spacing(0.5),
          borderRadius: "16px",
          boxShadow: "none",
          background: theme.palette.common.white,
          border: `1px solid ${theme.palette.grey[200]}`,
          whiteSpace: "nowrap",
          height: "32px",
          color: theme.palette.text.primary,
          fontWeight: 400,
          "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
            borderRadius: "16px",
            margin: 0,
          },
          "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
            border: `1px solid ${theme.palette.grey[200]}`,
            margin: 0,
            borderRadius: "16px",
          },
          "&.MuiToggleButtonGroup-grouped:first-of-type": {
            marginLeft: theme.spacing(3),
          },
          "&.Mui-selected": {
            "& svg": {
              fill: theme.palette.primary.contrastText,
            },
          },
          ":hover": {
            background: theme.palette.common.white,
          },
          "& svg": {
            width: 16,
            height: 16,
            fill: theme.palette.primary.main,
            marginLeft: theme.spacing(0.5),
            marginRight: "-6px",
          },
        },
        text: {
          fontSize: "0.8125rem",
          padding: theme.spacing(0, 1.5),
          letterSpacing: "0.00938em",
        },
      },
    },
    VuiAdModal: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        },
        closeButton: {
          padding: theme.spacing(0.5, 2),
          marginTop: theme.spacing(2),
        },
      },
    },
    VuiAdImageCarousel: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        carousel: {
          "& .splide__pagination": {
            marginTop: theme.spacing(0.5),
          },
          "& .splide__pagination__page": {
            width: "8px",
            height: "8px",
          },
          "& .splide__pagination__page.is-active": {
            backgroundColor: theme.palette.primary.main,
            transition: `width ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}, borderRadius ${theme.transitions.duration.standard}s ${theme.transitions.easing.easeInOut}`,
            transform: "none",
            width: "24px",
            borderRadius: "30px",
          },
        },
        carouselItem: {
          display: "flex",
          justifyContent: "center",
        },
        image: {
          width: "320px",
          aspectRatio: "3 / 4",
          objectFit: "cover",
          borderRadius: "16px",
        },
      },
    },
    VuiAnnoucementCard: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(1),
        },
        closeButtonContainer: {
          display: "flex",
          justifyContent: "flex-end",
        },
        closeButton: {
          backgroundColor: "rgba(255, 255, 255, 0.50)",
          borderRadius: "50%",
          padding: 0,
          minWidth: "24px",
          "& .MuiSvgIcon-root": {
            width: "50%",
          },
          cursor: "pointer",
          "&.MuiButtonBase-root:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.50)",
          },
        },
        detailCard: {
          display: "flex",
          padding: theme.spacing(2),
          gap: theme.spacing(1),
          borderRadius: "16px",
        },
        cardContent: {
          padding: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          "&.MuiCardContent-root:last-child": {
            paddingBottom: 0,
          },
        },
        cardMedia: {
          aspectRatio: "1/1",
          width: "30%",
          borderRadius: "8px",
          objectFit: "cover",
        },
        titleContainer: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          color: theme.palette.primary.main,
        },
        locationContainer: {
          display: "flex",
          gap: theme.spacing(1),
        },
        iconWrapper: {
          width: "16px",
          height: "16px",
          "& .MuiSvgIcon-root": {
            height: "100%",
            width: "100%",
          },
        },
      },
    },
    VuiNotificationContainer: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            minWidth: "200px",
          },
        },
        snackbarContent: {
          backgroundColor: "transparent",
          boxShadow: "none",
          flexGrow: "unset",
        },
        annoucementCard: {
          maxWidth: "250px",
        },
      },
    },
  },
})
