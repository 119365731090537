import React from "react"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import capitalize from "lodash/capitalize"

export const HeaderTitle = () => {
  // NOTE: Temporary solution for specify title header on each page
  const location = useLocation()
  const { t } = useTranslation()
  const pathName = location.pathname.split("/")
  const title = pathName[1].split("-").map(capitalize).join(" ")
  //i18n translation fallback
  return <>{t([`navigation.${title}`, title])}</>
}
