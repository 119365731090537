import { RealtimeContext } from "./RealtimeContext"

import { useNotificationSocketIO } from "./hooks/useNotificationSocketIO"
import { useDeliveryContentSocketIO } from "./hooks/useDeliveryContentSocketIO"

export const RealtimeProvider = ({ children }) => {
  const notification = useNotificationSocketIO()
  const deliveryContent = useDeliveryContentSocketIO()

  const value = {
    notification,
    deliveryContent,
  }

  return (
    <RealtimeContext.Provider value={value}>
      {children}
    </RealtimeContext.Provider>
  )
}
