import { useMemo, useCallback } from "react"
import { Avatar, Box, Typography, useTheme } from "@mui/material"
import { Link } from "react-router-dom"
import _ from "lodash"

import { getIcon } from "utils/icon"
import { getFeatureDetail } from "utils/feature"

import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import { useDining } from "hooks/dining/useDining"

import { AppNavigation } from "containers/kiosk-h/AppNavigation"
import { PageCard, PageCardContent } from "components/card"
import { VenueSelector } from "components/VenueSelector"
import { HorizontalScrollPane } from "components/HorizontalScrollPane"
import { NavigationControl } from "components/kioskHorizontal/navigation"
import {
  CategoryFilterBar,
  CategoryFilterChip,
} from "components/CategoryFilterBar"
import { MotionDiv } from "components/motions"
import { EmptyData } from "components/EmptyData"
import { CustomIcon, DiningIcon } from "components/icons"
import { ImageCard } from "components/ImageCard"

export const DiningKioskHPage = ({ Layout = Box }) => {
  const { occupants, isMultiOrdinalVenue } = useVenue()
  const {
    restaurants,
    currentCategory,
    categories,
    isMultiVenueProject,
    locationVenueId,
    venues,
    venueFilter,
    filterByCategoryId,
    filterByVenueId,
  } = useDining(occupants)
  const theme = useTheme()
  const { t } = useTranslation()

  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.dining) || <DiningIcon />,
    [theme]
  )
  const handleChangeCategory = useCallback(
    (e, id) => {
      if (id === null) return
      filterByCategoryId(id)
    },
    [filterByCategoryId]
  )

  const showSubCategoryFilter = categories.length > 0
  const { styleOverrides = {} } = theme.components?.[`AppDining`] || {}
  const { styleOverrides: bannerStyleOverrides = {} } =
    theme.components[`AppBanner`] || {}
  const sideBarStyleOverrides =
    theme.components[`AppSidebar`]?.styleOverrides || {}

  const {
    pageCard,
    contentWrapper,
    gridContainer,
    gridItem,
    venueSelector,
    subCategoryFilterBar,
    scrollPane,
    emptyDataWrapper,
    diningAvatarFallBack,
  } = styleOverrides

  const { root } = bannerStyleOverrides
  const { wrapper, body, footer } = sideBarStyleOverrides

  return (
    <Layout
      aside={
        <PageCard>
          <Box sx={wrapper}>
            <Box sx={root}></Box>
            <Box sx={body}>
              <AppNavigation />
            </Box>
            <Box sx={footer}>
              <NavigationControl BackButtonProps={{ hidden: true }} />
            </Box>
          </Box>
        </PageCard>
      }
    >
      <PageCard sx={pageCard}>
        <PageCardContent>
          <MotionDiv
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.15 }}
            sx={contentWrapper}
          >
            {restaurants.length > 0 ? (
              <Box sx={gridContainer}>
                {restaurants.map((restaurant) => {
                  const { level, logo, featuredImage, name, isFeatured } =
                    getFeatureDetail(restaurant) || {}
                  const levelText = isMultiOrdinalVenue
                    ? t("Level Text", { level: t(level) })
                    : ""
                  const fallBackIcon = _.first(
                    restaurant?.properties?.local_parent_categories
                  )?.properties?.icon_path
                  const subcategory = _.first(
                    restaurant?.properties?.local_sub_categories
                  )
                  const subcategoryName = _.get(subcategory, "properties.name")
                  return (
                    <ImageCard
                      key={restaurant?.id}
                      component={Link}
                      sx={gridItem}
                      to={`/maps/place/${restaurant?.id}`}
                      title={t(name)}
                      subheader={
                        <>
                          <Typography
                            component="span"
                            variant="subtitle"
                            className="sub-category-text"
                          >
                            {t(subcategoryName)}
                          </Typography>
                          <Typography
                            className="location-text"
                            component="span"
                            variant="subtitle"
                          >
                            {levelText}
                          </Typography>
                        </>
                      }
                      image={featuredImage}
                      avatar={
                        <Avatar src={logo}>
                          <CustomIcon
                            path={fallBackIcon}
                            sx={diningAvatarFallBack}
                          />
                        </Avatar>
                      }
                      tag={isFeatured ? t("Highlight") : null}
                    />
                  )
                })}
              </Box>
            ) : (
              <Box sx={emptyDataWrapper}>
                <EmptyData
                  icon={emptyIcon}
                  primary={t("Coming soon")}
                  secondary={t("Stay tuned for the update")}
                />
              </Box>
            )}

            {isMultiVenueProject && (
              <Box sx={venueSelector}>
                <VenueSelector
                  selectedVenue={venueFilter}
                  venues={venues}
                  onClickVenue={filterByVenueId}
                  currentVenue={locationVenueId}
                />
              </Box>
            )}
            {showSubCategoryFilter && (
              <Box sx={subCategoryFilterBar}>
                <HorizontalScrollPane
                  stackProps={{
                    sx: scrollPane,
                  }}
                >
                  <CategoryFilterBar
                    activeCategory={currentCategory}
                    categories={categories}
                    renderFilterButton={CategoryFilterChip}
                    onChange={handleChangeCategory}
                  />
                </HorizontalScrollPane>
              </Box>
            )}
          </MotionDiv>
        </PageCardContent>
      </PageCard>
    </Layout>
  )
}
