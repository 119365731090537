import React from "react"
import { Box, useTheme } from "@mui/material"
import {
  CurrentEventsList,
  UpcomingEventsSlide,
} from "components/domains/events"
import { Head } from "../../components/Head"
import { VenueSelector } from "components/VenueSelector"

import { useTranslation } from "providers/i18n"
import { useEvent } from "hooks/event/useEvent"
import { PageLayout } from "layouts/PageLayout"

export const Events = ({ Layout = PageLayout }) => {
  const { t } = useTranslation()
  const {
    isMultiVenueProject,
    venueFilter,
    venues,
    locationVenueId,
    groupEvents,
    changeVenueFilter,
  } = useEvent()

  const theme = useTheme()

  const { styleOverrides } = theme.components?.[`EventPage`] || {}
  const { root, cardListContainer, venueSelector } = styleOverrides

  return (
    <>
      <Head title={t("Events & Activities")} />
      <Layout>
        <Box sx={root}>
          <Box sx={cardListContainer}>
            <CurrentEventsList events={groupEvents["current"]} />
            <UpcomingEventsSlide events={groupEvents["upcoming"]} />
          </Box>
          {isMultiVenueProject && (
            <Box sx={venueSelector}>
              <VenueSelector
                selectedVenue={venueFilter}
                venues={venues}
                onClickVenue={changeVenueFilter}
                currentVenue={locationVenueId}
              />
            </Box>
          )}
        </Box>
      </Layout>
    </>
  )
}
