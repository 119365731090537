import { Box, Dialog, DialogContent, Typography, useTheme } from "@mui/material"
import EastRoundedIcon from "@mui/icons-material/EastRounded"
import { Image } from "components/Image"
import { useTranslation } from "providers/i18n"
import { Trans } from "react-i18next"

export const VerticalDirectionImageDialog = ({
  imageSrc,
  description,
  originLevelName,
  destinationLevelName,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    root,
    container,
    highlightedText,
    image,
    descriptionContainer,
    headerContainer,
    primaryText,
    secondaryText,
    arrowIcon,
  } = theme.components[`VerticalDirectionImageDialog`]?.styleOverrides || {}
  const highlightedStepText = (
    <Typography variant="subTitle" component="span" sx={highlightedText} />
  )

  return (
    <Dialog sx={root} open={!!imageSrc}>
      <DialogContent sx={container}>
        {imageSrc ? <Image src={imageSrc} sx={image} /> : <Box sx={image} />}
        <Box sx={descriptionContainer}>
          <Box sx={headerContainer}>
            <Typography sx={primaryText} component="span">
              {t(originLevelName)}
            </Typography>
            <EastRoundedIcon sx={arrowIcon} fontSize="large" />
            <Typography sx={primaryText} component="span">
              {t(destinationLevelName)}
            </Typography>
          </Box>
          <Typography variant="caption" sx={secondaryText}>
            <Trans
              components={{
                category: highlightedStepText,
                destination: highlightedStepText,
              }}
            >
              {t(description?.text, {
                category: t(description?.options?.category),
                destination: t(description?.options?.destination),
              })}
            </Trans>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
