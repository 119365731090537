import { useSponsoredContent } from "../useSponsoredContent"
import { ISponsoredContentConfig } from "providers/venue/types"

interface IUseRecommendedPromotionResult {
  recommendedPromotions: ISponsoredContentConfig
}

type IUseRecommendedPromotion = () => IUseRecommendedPromotionResult

export const useRecommendedPromotion: IUseRecommendedPromotion =
  (): IUseRecommendedPromotionResult => {
    const { recommendedPromotion: recommendedPromotions } =
      useSponsoredContent()

    return {
      recommendedPromotions,
    }
  }
