import React from "react"
import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction,
  Box,
  Slide,
  useTheme,
} from "@mui/material"
import { Link } from "react-router-dom"

import { useTranslation } from "providers/i18n"
import { useAppUI } from "providers/ui"

import { LanguageButton } from "components/Language"
import { SearchToggleButton } from "components/domains/search/SearchToggleButton"

export const KioskNavigation = ({ value, navigations = [], show = true }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const styleOverrides =
    theme.components[`AppKioskNavigation`]?.styleOverrides || {}
  const {
    root,
    menuButton,
    navigationContainer,
    toolbarContainer,
    searchBarContainer,
    actionContainer,
    actionButton,
    coverImage,
  } = styleOverrides

  const { openSearch } = useAppUI()

  return (
    <Slide direction="up" appear={false} mountOnEnter unmountOnExit in={show}>
      <Box sx={root}>
        <Box sx={coverImage}></Box>
        {/* Toolbar */}
        <Box sx={toolbarContainer}>
          <Box sx={actionContainer}>
            <LanguageButton variant="contained" sx={actionButton} />
            {/* //TODO:: Add a wheel chair's mode handler function later */}
            {/*  <Button
            variant="contained"
            sx={actionButton}
            onClick={() => {}}
            startIcon={<RestroomWheelchairIcon />}
          /> */}
          </Box>
          <Box sx={searchBarContainer}>
            <SearchToggleButton
              placeholder={t("Search shops and more...")}
              onClick={openSearch}
            />
          </Box>
        </Box>
        {/* Navigation */}
        <Box>
          <MuiBottomNavigation
            value={value}
            showLabels
            sx={navigationContainer}
          >
            {navigations.map(({ to, label, icon, disabled = false }) => (
              <BottomNavigationAction
                sx={menuButton}
                component={Link}
                key={to}
                label={t([`navigation.${label}`, label])}
                to={to}
                value={to}
                icon={icon}
                disabled={disabled}
              />
            ))}
          </MuiBottomNavigation>
        </Box>
      </Box>
    </Slide>
  )
}
