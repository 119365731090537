import { useState, useCallback } from "react"
import {
  Box,
  SwipeableDrawer as MuiSwipeableDrawer,
  styled,
} from "@mui/material"
import { deepmerge } from "@mui/utils"
import { Global } from "@emotion/react"
import SimpleBar from "simplebar-react"

const BleedWrapper = styled(Box, {
  shouldForwardProp: (propName) => propName !== "drawerBleeding",
})(({ theme, drawerBleeding }) => ({
  position: "absolute",
  visibility: "visible",
  right: 0,
  left: 0,
  top: -drawerBleeding,
  boxShadow: theme.shadows[21],
  // กำหนด height ของ BleedWrapper ให้สูง 100% + ความสูงของ drawerBleeding (190px)
  height: drawerBleeding ? `calc(100% + ${drawerBleeding}px)` : "",
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  background: theme.palette.background.paper,
}))

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 4,
  backgroundColor: theme.palette.grey[400],
  borderRadius: 3,
  position: "absolute",
  top: 15,
  left: "calc(50% - 15px)",
}))

export const SwipeableDrawer = ({
  drawerBleeding,
  children,
  contentProps = {},
  globalProps = {},
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const [BleedComponent, HiddenComponent] = children

  const {
    onTouchStart = (event) => {
      event.nativeEvent.defaultMuiPrevented = true
    },
  } = contentProps
  const { sx = {} } = globalProps
  const handleOpenDrawer = useCallback((e) => {
    setOpen(true)
  }, [])

  const handleCloseDrawer = useCallback((e) => {
    setOpen(false)
  }, [])
  return (
    <>
      <Global
        styles={deepmerge(
          {
            ".MuiDrawer-root > .MuiPaper-root": {
              height: drawerBleeding
                ? `calc(80% - ${drawerBleeding}px)`
                : "auto",
              overflow: "visible",
            },
          },
          sx
        )}
      />
      <MuiSwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={handleCloseDrawer}
        onOpen={handleOpenDrawer}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        hideBackdrop
        disableEnforceFocus
        ModalProps={{
          keepMounted: true,
        }}
        {...props}
      >
        <BleedWrapper
          onClick={handleCloseDrawer}
          drawerBleeding={drawerBleeding}
        >
          <Puller className="MuiSwipeableDrawer-puller" />
          {BleedComponent}
        </BleedWrapper>
        <SimpleBar
          style={{ height: "100%", overflow: "auto" }}
          onTouchStart={onTouchStart}
          //from https://github.com/mui/material-ui/issues/18884#issuecomment-1523981868
        >
          {HiddenComponent}
        </SimpleBar>
      </MuiSwipeableDrawer>
    </>
  )
}

export default SwipeableDrawer
