import { useParams } from "react-router-dom"
import { useTranslation } from "providers/i18n"
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  IconButton,
  List,
  Typography,
  useTheme,
} from "@mui/material"
import LanguageIcon from "@mui/icons-material/Language"

import { useEventDetail } from "hooks/event/useEventDetail"

import { ShareIconButton } from "components/Button"
import { CalendarIcon, LocationIcon } from "components/icons"
import { Head } from "components/Head"
import { InfoListItem, InfoListItemWithNavigation } from "components/ListItem"
import { LocationInfo } from "components/domains/features/LocationInfo"
import { Carousel, CarouselItem } from "components/Carousel"
import { PageLayout } from "layouts/PageLayout"

export const EventDetail = ({ Layout = PageLayout }) => {
  const params = useParams()
  const { eventId } = params
  const { t } = useTranslation()
  const { event, navigateToLocation, openWebsiteLink } = useEventDetail(eventId)
  const theme = useTheme()
  const styleOverrides = theme.components[`VuiDetailCard`]?.styleOverrides || {}
  const {
    card,
    image,
    infoListItem,
    infoListItemWithNavigation,
    cardContent,
    cardHeader,
    cardHeaderAction,
    cardHeaderActionButton,
    cardContentPrimaryText,
    cardContentSecondaryText,
    galleryCarouselWrapper,
    galleryCarousel,
    galleryCarouselItem,
  } = styleOverrides

  if (!event) return null

  return (
    <>
      <Head title={`${t(event.name)} (${t("Event Detail")})`} />
      <Layout>
        <Card elevation={0} sx={card}>
          <CardMedia component="img" sx={image} src={event.coverImage} />
          {event.gallery && (
            <Box sx={galleryCarouselWrapper}>
              <Carousel
                options={{
                  type: "loop",
                  padding: 0,
                  gap: 8,
                  pagination: true,
                  autoplay: true,
                }}
                sx={galleryCarousel}
              >
                {event.gallery.map(({ id, url }) => {
                  return (
                    <CarouselItem key={`slide-${id}`} sx={galleryCarouselItem}>
                      <CardMedia
                        component="img"
                        sx={galleryCarouselItem}
                        src={url}
                      />
                    </CarouselItem>
                  )
                })}
              </Carousel>
            </Box>
          )}
          <CardHeader
            title={t(event.name)}
            action={
              <Box sx={cardHeaderAction}>
                {!!event.websiteLink && (
                  <IconButton
                    className="website-button"
                    color="primary"
                    // * URL should be prepended with protocol (e.g. "https://") for the URL to be replaced
                    onClick={openWebsiteLink}
                    sx={cardHeaderActionButton}
                  >
                    <LanguageIcon />
                  </IconButton>
                )}
                <ShareIconButton
                  className="share-button"
                  url={window.location.href}
                  sx={cardHeaderActionButton}
                />
              </Box>
            }
            sx={cardHeader}
          />
          <List>
            <InfoListItem
              sx={infoListItem}
              icon={<CalendarIcon />}
              primary={event.durationDate}
              secondary={event.openingText}
            />
            {event.hasLocation && (
              <InfoListItemWithNavigation
                sx={infoListItemWithNavigation}
                icon={<LocationIcon />}
                primary={t("Navigate")}
                secondary={
                  <LocationInfo feature={event.feature} component="span" />
                }
                onClick={navigateToLocation}
              />
            )}
          </List>
          <CardContent sx={cardContent}>
            <Typography
              variant="body1"
              gutterBottom
              sx={cardContentPrimaryText}
            >
              {t("Event Detail")}
            </Typography>
            <Typography
              variant="body2"
              sx={cardContentSecondaryText}
              dangerouslySetInnerHTML={{
                __html: event.descriptionText,
              }}
            ></Typography>
          </CardContent>
        </Card>
      </Layout>
    </>
  )
}
