import { useTranslation } from "providers/i18n"
import {
  Typography,
  CardMedia,
  CardContent,
  Box,
  Stack,
  useTheme,
  Card,
} from "@mui/material"
import { CalendarIcon } from "components/icons"
import { getFeatureName } from "utils/feature"
import dayjs from "config/dayjs"
import { getDisplayDateTime } from "../../../utils/date"

export const ListPromotionItemCard = (props) => {
  const { feature } = props
  const { t } = useTranslation()
  const { properties } = feature
  const startDate = dayjs(properties?.start_date)
  const endDate = dayjs(properties?.end_date)

  const theme = useTheme()
  const { styleOverrides } = theme.components?.[`ListPromotionItemCard`] || {}
  const {
    root,
    cardMedia,
    cardContent,
    contentWrapper,
    cardTitle,
    promotionInfoItem,
    iconWrapper,
  } = styleOverrides

  return (
    <Card sx={root}>
      <CardMedia
        sx={cardMedia}
        component="img"
        image={properties.cover_image?.url}
      />
      <CardContent sx={cardContent}>
        <Stack spacing={0} sx={contentWrapper}>
          <Typography sx={cardTitle} variant="title" component="div">
            {t(getFeatureName(feature))}
          </Typography>
          <Box sx={promotionInfoItem}>
            <Box sx={iconWrapper}>
              <CalendarIcon />
            </Box>
            <Typography variant="subtitle1" component="p">
              {getDisplayDateTime(startDate, endDate)}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}
