import { useEffect, useMemo } from "react"
import dayjs from "config/dayjs"
import _ from "lodash"

import { useGeoLocation } from "providers/geolocation"
import { useVenue } from "providers/venue"
import { useVenueController } from "hooks/venue/useVenueController"

import { CalendarStarIcon, PercentIcon } from "../../components/icons"

export const useHome = () => {
  const { promotions, events, venues, locales, dataLoaded } = useVenue()
  const { locationVenue } = useGeoLocation()
  const locationVenueId = _.get(locationVenue, "id")
  const { resetViewingVenue } = useVenueController(locationVenueId)

  // Prepare promotion and event list for showing at the carousel
  const featuredEventPromotion = useMemo(
    () =>
      _.compact([...promotions, ...events])
        .filter(({ properties }) => properties.is_featured)
        .sort(
          (a, b) =>
            dayjs(a.properties.start_date) - dayjs(b.properties.start_date)
        ),
    [promotions, events]
  )

  const defaultItemsLinks = [
    {
      to: "/events",
      primaryText: "View All Event",
      secondaryText: "See more events that match your lifestyle",
      icon: <CalendarStarIcon />,
    },
    {
      to: "/promotions",
      primaryText: "View All Promotion",
      secondaryText: "See more special deals and get special offers",
      icon: <PercentIcon />,
    },
  ]

  useEffect(() => {
    resetViewingVenue()
  }, [resetViewingVenue])

  return {
    featuredEventPromotion,
    defaultItemsLinks,
    venues,
    locales,
    dataLoaded,
  }
}
