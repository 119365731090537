import React, { useCallback, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { Box, Slide, useMediaQuery, styled, Button } from "@mui/material"

import { FeatureDetail } from "components/domains/features/Detail"
import { FeatureSummary } from "components/domains/features/Summary"
import { TopBarContainer } from "containers/TopBarContainer"
import { SwipeableDrawer } from "components/SwipeableDrawer"
import { KeyboardBackspaceIcon } from "components/icons"
import { Head } from "components/Head"
import { DesktopFeatureDialog } from "components/domains/features/DesktopFeatureDialog"
import { useVenue } from "providers/venue"
import { useTranslation } from "providers/i18n"
import { useAppUI } from "providers/ui"
import { useFeatureDetail } from "hooks/feature/useFeatureDetail"
import { usePlaceDetailMap } from "hooks/place/usePlaceDetailMap"

const DRAWER_BLEEDING = 220
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.common.white,
  minWidth: "unset",
  borderRadius: "50%",
  padding: theme.spacing(1.5),
  boxShadow: theme.shadows[15],
  zIndex: 1300,
  "&:hover": {
    background: theme.palette.grey[200],
  },
}))

const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  background: theme.palette.common.white,
}))

const ContentWrapper = styled(Wrapper)(({ theme }) => ({
  boxShadow: "none",
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(1.25),
  },
}))

const BleedWrapper = styled(Wrapper)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  paddingTop: theme.spacing(5.25),
  paddingBottom: theme.spacing(2),
  height: DRAWER_BLEEDING,
}))

export const PlaceDetailPage = ({ DirectionButtonProps, BackButtonProps }) => {
  const { dataLoaded, ...data } = useFeatureDetail()
  const bleederRef = useRef()
  const bleederHeight = +bleederRef.current?.clientHeight || 0

  const {
    feature,
    zone,
    logo,
    venueLogo,
    category,
    level,
    name,
    hours,
    id,
    websiteLink,
    gallery,
    promotions,
    privileges,
    description,
    group,
    categoryIconProp,
  } = data

  usePlaceDetailMap(feature, {
    offset: { bottom: bleederHeight },
    enabled: !!feature,
  })

  const { isMultiOrdinalVenue, isMultiVenueProject } = useVenue()
  const { goBack } = useAppUI()
  const navigate = useNavigate()

  const onNavigate = useCallback(() => {
    const locationId = feature?.properties?.feature?.id || id
    navigate(`/maps/dir/unknown/${locationId}`)
  }, [navigate, feature, id])
  const isMobile = useMediaQuery("(max-width: 767px)")

  const { t } = useTranslation()
  const handleClickBack = useCallback(() => {
    goBack()
  }, [goBack])

  const onClose = useCallback(() => {
    goBack()
  }, [goBack])

  const {
    icon = (
      <KeyboardBackspaceIcon sx={{ width: 20, height: 20 }} color="primary" />
    ),
  } = BackButtonProps || {}

  return (
    <>
      <Head title={`${t(name)} (${t("Place Detail")})`} />
      {isMobile ? (
        <Box>
          <TopBarContainer>
            <StyledButton variant="contained" onClick={handleClickBack}>
              {icon}
            </StyledButton>
          </TopBarContainer>
          <SwipeableDrawer drawerBleeding={DRAWER_BLEEDING}>
            <BleedWrapper ref={bleederRef}>
              <FeatureSummary
                id={id}
                name={name}
                hours={hours}
                level={isMultiOrdinalVenue && level}
                category={category}
                logo={logo}
                venueLogo={isMultiVenueProject && venueLogo}
                zone={zone}
                group={group}
                onNavigate={onNavigate}
                DirectionButtonProps={DirectionButtonProps}
                loading={!dataLoaded}
                categoryIconProp={categoryIconProp}
              />
            </BleedWrapper>
            <ContentWrapper>
              <FeatureDetail
                feature={feature}
                websiteLink={websiteLink}
                gallery={gallery}
                promotions={promotions}
                privileges={privileges}
                description={description}
                loading={!dataLoaded}
              />
            </ContentWrapper>
          </SwipeableDrawer>
        </Box>
      ) : (
        //TODO:: Temporary solution for preventing the tourist friendly theme shows unexpected ui
        // Desktop Viewport
        <>
          <TopBarContainer>
            <StyledButton variant="contained" onClick={handleClickBack}>
              {icon}
            </StyledButton>
          </TopBarContainer>
          <DesktopFeatureDialog
            open={true}
            onClose={onClose}
            TransitionComponent={Transition}
            /* Allow user to click behind modal */
            hideBackdrop
            anchor="left"
            disableEnforceFocus
            onNavigate={onNavigate}
            /* End of Allow user to click behind modal */
            directionButtonProps={DirectionButtonProps}
            isMultiOrdinalVenue={isMultiOrdinalVenue}
            loading={!dataLoaded}
            data={data}
            categoryIconProp={categoryIconProp}
          />
        </>
      )}
    </>
  )
}
