const defaultLayerOption = { enableAltitude: true }

const VENUE = "venue"
const LEVEL = "level"
const UNIT = "unit"
const FIXTURE = "fixture"
const KIOSK = "kiosk"
const OPENING = "opening"
const SECTION = "section"
const FOOTPRINT = "footprint"

//
const AMENITY = "amenity"
const OCCUPANT = "occupant"

export const BASE_LAYER_NAME = "base"
export const POI_MARKER_LAYER_NAME = "poi"
export const MARKER_LAYER_NAME = "marker"
export const HIGHLIGHT_LAYER_NAME = "highlight"
export const USER_LOCATION_LAYER_NAME = "user_location"

export const LAYERS = [
  BASE_LAYER_NAME,
  POI_MARKER_LAYER_NAME,
  HIGHLIGHT_LAYER_NAME,
  USER_LOCATION_LAYER_NAME,
]

export const LAYER_OPTIONS = {
  [BASE_LAYER_NAME]: {
    ...defaultLayerOption,
    forceRenderOnMoving: true,
    forceRenderOnRotating: true,
    forceRenderOnZooming: true,
  },
  [POI_MARKER_LAYER_NAME]: {
    ...defaultLayerOption,
    forceRenderOnMoving: true,
    forceRenderOnRotating: true,
    forceRenderOnZooming: true,
    zIndex: 10,
    collision: true,
    collisionDelay: 1200,
  },
  [MARKER_LAYER_NAME]: { ...defaultLayerOption },
  [HIGHLIGHT_LAYER_NAME]: { ...defaultLayerOption, zIndex: 98 },
  [USER_LOCATION_LAYER_NAME]: { ...defaultLayerOption, zIndex: 99 },
}

export const LAYER_FEATURE_TYPE_OBJ = {
  [VENUE]: BASE_LAYER_NAME,
  [LEVEL]: BASE_LAYER_NAME,
  [UNIT]: BASE_LAYER_NAME,
  [FIXTURE]: BASE_LAYER_NAME,
  [KIOSK]: BASE_LAYER_NAME,
  [OPENING]: BASE_LAYER_NAME,
  [SECTION]: BASE_LAYER_NAME,
  [FOOTPRINT]: BASE_LAYER_NAME,
  [AMENITY]: MARKER_LAYER_NAME,
  [OCCUPANT]: POI_MARKER_LAYER_NAME,
}
