import { CardContent, CardProps, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"

interface PageCardContentProps extends CardProps {}

export const PageCardContent: React.FC<PageCardContentProps> = ({
  children,
  sx,
  ...props
}) => {
  const theme = useTheme()
  const styleOverrides =
    theme.components[`AppPageCardContent`]?.styleOverrides || {}
  const { root } = styleOverrides

  return (
    <CardContent sx={deepmerge(root, sx)} {...props}>
      {children}
    </CardContent>
  )
}
