import React, { forwardRef } from "react"
import {
  AppBar,
  Dialog as MuiDialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material"
import { deepmerge } from "@mui/utils"
import CloseIcon from "@mui/icons-material/Close"

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const Dialog = ({ children, sx, ...props }) => {
  const theme = useTheme()
  const { open, title, onClose = () => {}, ...restProps } = props
  const styleOverrides = theme.components[`Dialog`]?.styleOverrides || {}
  const { root, titleText, closeButton, appBar, toolbar } = styleOverrides
  return (
    <MuiDialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      {...restProps}
      sx={deepmerge(root, sx)}
    >
      <AppBar sx={appBar}>
        <Toolbar sx={toolbar}>
          <IconButton
            sx={closeButton}
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={titleText}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      {children}
    </MuiDialog>
  )
}
