import _ from "lodash"
import { Event, EventData } from "../../../../types"
import { Feature } from "@turf/helpers"

export const prepareEvent = (
  event: Event,
  findOne: (id: string) => Feature
): EventData => {
  // Prepare Event
  const { feature_id, venue_id, local_category_ids } = event.properties
  const feature = findOne(feature_id)
  const venue = findOne(venue_id)
  const localCategories = _(local_category_ids).map(findOne).compact().value()

  const [parentCategories, subCategories] = _.partition(
    localCategories,
    (cat) => cat?.properties.parent_id === null
  )

  return {
    ...event,
    properties: {
      ...event.properties,
      feature,
      venue,
      local_categories: localCategories,
      local_parent_categories: parentCategories,
      local_sub_categories: subCategories,
    },
  }
}
