import _ from "lodash"
import { IFeature } from "interfaces"

export const transformGTMDirectionData = (feature: IFeature) => {
  const {
    name,
    category,
    local_categories = [],
  } = _.pick(feature?.properties, ["name", "category", "local_categories"])
  const id = _.get(feature, "id")
  const pickedOrigin = _.pick(feature, ["id", "feature_type"])
  const localCategoryNames = local_categories
    ?.map((taxonomy) => _.get(taxonomy, "properties.name.en"))
    .join(", ")

  return {
    ...pickedOrigin,
    category,
    id,
    name: _.get(name, "en"),
    local_categories: localCategoryNames,
  }
}
