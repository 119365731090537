import { useEffect, useMemo } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import _ from "lodash"
import { featureCollection } from "@turf/helpers"

import { useVenue } from "providers/venue"
import { useIndoorMap } from "providers/venue/modules/indoormap/hooks/useIndoorMap"
import {
  getSuitablyValueBetweenBearings,
  getLocationIdByFeature,
} from "components/IndoorMap"

import { IRoute } from "interfaces"

const DEFAULT_OPTIONS = {
  offset: { top: 0, left: 0, right: 0, bottom: 0 },
  enabled: true,
}

export const useDirectionOverviewMap = (
  route: IRoute | null,
  ordinal: number,
  options = DEFAULT_OPTIONS
): void => {
  const { indoorRef, mapLoaded } = useIndoorMap()
  const {
    origin: originParams,
    destination: destinationParams,
    step,
  } = useParams()
  const [searchParams] = useSearchParams()
  const { offset, enabled } = { ...DEFAULT_OPTIONS, ...options }
  const { bottom, top, left, right } = offset

  const offsetOption = useMemo(
    () => ({ bottom, top, left, right }),
    [bottom, top, left, right]
  )

  const {
    relationshipGraphLoaded,
    dataLoaded,
    transformStepsToGeometries,
    transformLocationToFeature,
  } = useVenue()

  const targetOrigin = useMemo(
    () => transformLocationToFeature(originParams),
    [originParams, transformLocationToFeature]
  )

  const targetDestination = useMemo(
    () => transformLocationToFeature(destinationParams),
    [destinationParams, transformLocationToFeature]
  )

  const mapReady =
    indoorRef?.current && mapLoaded && dataLoaded && relationshipGraphLoaded

  const navigationGeometries = useMemo(
    () => route && route.steps && transformStepsToGeometries(route.steps),
    [route, transformStepsToGeometries]
  )

  useEffect(() => {
    const indoorMap = indoorRef?.current
    if (indoorMap && mapReady && navigationGeometries && route) {
      //Clear path in the route path Layer
      indoorMap.clearNavigationGeometries()
      // Create route path on map
      indoorMap.createNavigationGeometries(
        navigationGeometries,
        route.destination
      )
    }
  }, [indoorRef, navigationGeometries, mapReady, route])

  useEffect(() => {
    const indoorMap = indoorRef?.current
    if (
      indoorMap &&
      mapReady &&
      enabled &&
      (!targetOrigin || !targetDestination)
    ) {
      // Clear path in the route path Layer when the origin or destination input was empty
      indoorMap.clearNavigationGeometries()

      // Add highlight to the origin or detination element (if exist)
      const targetFeature = targetOrigin || targetDestination
      const elementId = getLocationIdByFeature(
        targetOrigin || targetDestination
      )
      indoorMap.clearHighlightElements()
      indoorMap.clearHighlightObject()
      if (elementId) {
        const elementIdsToHighlight = _.compact([elementId])
        indoorMap.changeLevelByOrdinal(
          Number(targetFeature?.properties?.ordinal || 0)
        )
        const highlightedFeature = indoorMap.setHighlightElementIds(
          elementIdsToHighlight
        )

        // Handle Fly to animation
        const extent = indoorMap.getFeatureExtent(highlightedFeature)
        const center = indoorMap.getExtentCenter(extent)
        const zoom = indoorMap.getExtentZoom(extent)
        indoorMap.flyTo(center, { zoom })

        return () => {
          indoorMap.clearHighlightElements()
          indoorMap.clearHighlightObject()
        }
      }
    }
  }, [enabled, indoorRef, mapReady, targetDestination, targetOrigin])

  useEffect(() => {
    const indoorMap = indoorRef?.current
    if (mapReady && enabled && route) {
      indoorMap.changeLevelByOrdinal(ordinal)

      // Highlight the destination 3D object if it has a located unit.
      indoorMap.clearHighlightObject()
      const destElementId = getLocationIdByFeature(targetDestination)
      if (destElementId) indoorMap.setHighlightedObject([destElementId])

      // Find Navigation Geometries in this ordinal
      const navGeoInOrdinal = navigationGeometries.filter(
        (geojson) => geojson?.properties?.ordinal === ordinal
      )
      const navigationCollection = featureCollection(navGeoInOrdinal)
      const extent = indoorMap.getFeatureExtent(navigationCollection)
      const center = indoorMap.getExtentCenter(extent)
      const zoom = indoorMap.getExtentZoom(extent)
      const currentBearing = indoorMap.getBearing()
      const defaultBearing = indoorMap.defaultBearing
      const bearing = getSuitablyValueBetweenBearings(
        defaultBearing,
        currentBearing
      )

      const newCenter = indoorMap.getTargetViewCenter(
        {
          bearing,
          center,
          pitch: 0,
          zoom: zoom - 0.5,
        },
        { offset: offsetOption }
      )

      // Fly to and display overview step path by current ordinal
      indoorMap.flyTo(newCenter, {
        bearing,
        duration: 600,
        easing: "out",
        pitch: 0,
        zoom: zoom - 0.5,
      })
    }
  }, [
    enabled,
    indoorRef,
    mapReady,
    navigationGeometries,
    ordinal,
    route,
    searchParams,
    step,
    targetDestination,
    targetOrigin,
    transformStepsToGeometries,
    offsetOption,
  ])

  useEffect(() => {
    const indoorMap = indoorRef?.current
    if (indoorMap && mapLoaded) {
      indoorMap.hideVenueObjects()
      indoorMap.disableClick()
      indoorMap.hideUserLocationMarker()
      indoorMap.hideLastUserLocationMarker()
      indoorMap.setFeatureObject3DsOpacity(1)
    }
  }, [indoorRef, mapLoaded])
}
