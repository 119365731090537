import { useCallback, useMemo } from "react"
import { useQuery } from "@tanstack/react-query"

import config from "config"
import { IAppConfig, fetchSponsoredContent } from "services/venue"
import { IFeature, IRecord } from "interfaces"

import { prepareSponsoredContentConfig } from "providers/venue/utils/prepareConfig"
import { prepareSponsoredContent } from "./utils/prepareSponsoredContent"
import {
  EventData,
  ISponsoredContent,
  PromotionData,
} from "providers/venue/types"

interface IuseGetSponsoredContentsProps {
  appConfig: IAppConfig
  imdfFeatures: IFeature[]
  promotions: PromotionData[]
  events: EventData[]
}

interface IFetchSponsoredContentResult {
  data: IRecord[]
  meta: {
    total: number
  }
}

const device = config("device")
const deviceType = /^kiosk/.test(device) ? "kiosk" : "mobile"

const placeholderData: IFetchSponsoredContentResult = {
  data: [],
  meta: {
    total: 0,
  },
}

export const useGetSponsoredContents = ({
  appConfig,
  imdfFeatures = [],
  promotions = [],
  events = [],
}: IuseGetSponsoredContentsProps) => {
  const sponsoredContentConfig = useMemo(() => {
    return prepareSponsoredContentConfig(appConfig, deviceType)
  }, [appConfig])

  const findOne = useCallback(
    (id) =>
      [...imdfFeatures, ...promotions, ...events].find((f) => f.id === id),
    [imdfFeatures, promotions, events]
  )

  return useQuery<IFetchSponsoredContentResult, unknown, ISponsoredContent>({
    queryKey: ["sponsored-contents"],
    queryFn: async () => {
      const sponsoredContents =
        await fetchSponsoredContent<IFetchSponsoredContentResult>()
      return sponsoredContents
    },
    placeholderData,
    select: (data: IFetchSponsoredContentResult): ISponsoredContent =>
      prepareSponsoredContent(data, sponsoredContentConfig, findOne),
    retry: true,
    refetchOnWindowFocus: false,
  })
}
