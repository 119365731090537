import { ReactNode, useCallback, FC } from "react"
import { Link } from "react-router-dom"
import {
  useTheme,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  DialogProps,
} from "@mui/material"

import { useTranslation } from "providers/i18n"

// import { LanguageSelector } from "./LanguageSelector"
import { Dialog } from "../../Dialog"
import { LanguageButton } from "../../Language"

const ACTIVE_CLASS_PREFIX = "active"

interface IMenus {
  navigations?: { to: string; icon: ReactNode; label: string }[]
  onClose?: () => void
  open?: boolean
  value?: string
  dialogProps?: DialogProps
}

export const Menus: FC<IMenus> = ({
  navigations = [],
  dialogProps,
  onClose = () => {},
  open = false,
  value = "",
}) => {
  const { sx: dialogSx = {} } = dialogProps || {}
  const theme = useTheme()
  const { t } = useTranslation()
  const { styleOverrides = {} } = theme.components[`OSMenus`]
  const {
    body,
    navList,
    navListItem,
    navListWrapper,
    navListItemIcon,
    navListItemText,
    languageSelectorWrapper,
  } = styleOverrides

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={t("Menu")}
      sx={dialogSx}
      {...dialogProps}
    >
      <Box sx={body}>
        {/** Navigation */}
        <List sx={navList} disablePadding>
          {navigations.map((nav) => {
            const isActive = nav.to === value
            const activeClass = isActive ? ACTIVE_CLASS_PREFIX : null
            return (
              <Box key={nav.to} sx={navListWrapper}>
                <ListItemButton
                  component={Link}
                  to={nav.to}
                  sx={navListItem}
                  className={activeClass}
                  onClick={handleClose}
                >
                  {nav.icon && (
                    <ListItemIcon sx={navListItemIcon} className={activeClass}>
                      {nav.icon}
                    </ListItemIcon>
                  )}
                  <ListItemText
                    sx={navListItemText}
                    className={activeClass}
                    primary={t([`navigation.${nav.label}`, nav.label])}
                  />
                </ListItemButton>
              </Box>
            )
          })}
          <Box sx={languageSelectorWrapper}>
            <LanguageButton />
          </Box>
        </List>
      </Box>
    </Dialog>
  )
}
