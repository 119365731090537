import { useRef, useEffect, useState, useMemo, useCallback } from "react"
import _ from "lodash"
import Graph from "node-dijkstra"
import difference from "@turf/difference"
import envelope from "@turf/envelope"
import booleanOverlap from "@turf/boolean-overlap"
import booleanIntersects from "@turf/boolean-intersects"
import { Id, featureCollection, isNumber, point, polygon } from "@turf/helpers"
import { captureException } from "@sentry/react"

import {
  prepareSteps,
  getUnitConnection,
} from "providers/venue/modules/shortestPath/utils/graph"
import {
  calculateDisplacement,
  createArrivalStep,
  findLocatedUnit,
  findNearestFeature,
  findNearbyPointOfInterest,
  groupOpeningConnect,
  groupFeatureByOrdinal,
} from "./utils/step"

import { createDescription } from "./utils/description"

import {
  calculateTotalDistance,
  calculateRoundedDistance,
  calculateTravelingDuration,
  findPathOnArea,
  transformMultiPolygonToPolygons,
} from "./utils/routePath"
import { getCenterFromGeometry } from "utils/geometry"

import { IStepElement, ISequence } from "interfaces"
import {
  AnchorData,
  AmenityData,
  KioskData,
  Opening,
  Relationship,
  FixtureData,
  OccupantData,
  TraversalRelationship,
  OpeningData,
  UnitData,
} from "providers/venue/types"
import { NavigatableFeature, IRoute, IStep, GeolocationFeature } from "./type"

import {
  OBSTACLE_FEATURE_TYPES,
  OBSTACLE_CATEGORIES,
  WALKABLE_CATEGORY,
  BASE_POI_DISTANCE,
} from "./constants"

type Feature =
  | AmenityData
  | AnchorData
  | Relationship
  | UnitData
  | OpeningData
  | KioskData
  | FixtureData
  | OccupantData

type FeaturePropType = {
  amenities: AmenityData[]
  anchors: AnchorData[]
  relationships: Relationship[]
  features: Feature[]
  units: UnitData[]
  openings: Opening[]
  kiosks: KioskData[]
  fixtures: FixtureData[]
  occupants: OccupantData[]
  featuresObj: Record<string, Feature>
}

type Option = {
  enabled?: boolean
}

const VERTICAL_TRAVERSAL_CATEGORY = ["elevator", "escalator", "stairs", "ramp"]

const featureIsVerticalTraversalUnit = (feature) => {
  if (!feature || !("category" in feature.properties)) return false
  return VERTICAL_TRAVERSAL_CATEGORY.indexOf(feature.properties.category) > -1
}

const featureIsOccupantWithUnit = (feature: NavigatableFeature) => {
  const { feature_type, properties } = feature
  return (
    feature_type === "occupant" && "unit_id" in properties && properties.unit_id
  )
}

const parseGeolocationId = (id: string) => id.replaceAll(".", "|")

const addGeolocationPOINode = (
  feature: GeolocationFeature,
  openings: Opening[],
  relationshipGraph: Graph
) => {
  const acc = {}
  const geolocationId = parseGeolocationId(String(feature.id))
  for (let i = 0; i < openings.length; i++) {
    const opening = openings[i]
    try {
      const { geometry: featureCenterGeometry } = point(
        getCenterFromGeometry(feature.geometry)
      )
      const { geometry: openingCenterGeometry } = point(
        getCenterFromGeometry(opening.geometry)
      )
      const distance =
        calculateDisplacement(featureCenterGeometry, openingCenterGeometry, {
          units: "meters",
        }) + BASE_POI_DISTANCE
      _.set(acc, `${opening.id}.${geolocationId}`, distance)
      _.set(acc, `${geolocationId}.${opening.id}`, distance)
    } catch (error) {
      console.log("error calculating distance for poi id: " + feature.id)
      continue
    }
  }

  const graph = relationshipGraph.graph
  _.forEach(acc, (value, key) => {
    const previousValue = graph.get(key) || []
    const newMapValue = Object.entries(value)
    // Merged previous nodes with newly added nodes connections
    relationshipGraph.addNode(key, new Map([...previousValue, ...newMapValue]))
  })
}

const getGeolocationShortestPath = (
  feature: GeolocationFeature,
  connectedOpenings: OpeningData[],
  relationshipGraph: Graph,
  compareOpeningId: Id,
  isOrigin: boolean
): string[] | null => {
  const geolocationId = parseGeolocationId(feature.id as string)
  const graph = relationshipGraph.graph

  if (!graph.has(geolocationId))
    addGeolocationPOINode(feature, connectedOpenings, relationshipGraph)

  const shortestPath = isOrigin
    ? relationshipGraph.path(geolocationId, compareOpeningId)
    : relationshipGraph.path(compareOpeningId, geolocationId)
  return shortestPath
}

const findLocatedFeature = (
  feature: NavigatableFeature
): NavigatableFeature | AnchorData | undefined => {
  const { feature_type } = feature
  switch (feature_type) {
    case "geolocation":
      const geolocationUnit = feature.properties.unit
      return geolocationUnit as UnitData
    case "occupant":
      const occupantAnchor = feature.properties.anchor
      return occupantAnchor as AnchorData
    default:
      return feature
  }
}

const findShortestPath = (
  routeOrigin: NavigatableFeature,
  routeDestination: NavigatableFeature,
  relationshipGraph: Graph,
  unitConnections
): string[] | null => {
  try {
    const originUnitOpenings = findLocatedFeature(routeOrigin)
    const destinationUnitOpenings = findLocatedFeature(routeDestination)
    if (routeOrigin.feature_type === "geolocation") {
      const originUnit = findLocatedFeature(routeOrigin)
      const originUnitId = originUnit.id
      const connectedOpenings = _.get(unitConnections, originUnitId, [])
      return getGeolocationShortestPath(
        routeOrigin,
        connectedOpenings,
        relationshipGraph,
        destinationUnitOpenings.id,
        true
      )
    }
    if (routeDestination.feature_type === "geolocation") {
      const destinationUnit = findLocatedFeature(routeDestination)
      const destinationUnitId = destinationUnit.id
      const connectedOpenings = _.get(unitConnections, destinationUnitId, [])
      return getGeolocationShortestPath(
        routeDestination,
        connectedOpenings,
        relationshipGraph,
        originUnitOpenings.id,
        false
      )
    }
    return relationshipGraph.path(
      originUnitOpenings.id,
      destinationUnitOpenings.id
    )
  } catch (error) {
    console.log({ error })
  }
}
/**
 *
 * routeOrigin: "kiosk1"
 * routeDest: "occupant2"
 * dijkstra ["ww1", "ww2", "es1", "es2", "ww3"]
 * sequence ["ww1 (origin: kiosk1)", "ww2", ["es1", "es2"], "ww3", "unit2 (dest: occupant2)",]
 */

export const useShortestPath = (
  {
    amenities = [],
    anchors = [],
    relationships = [],
    features = [],
    units = [],
    openings = [],
    kiosks = [],
    fixtures = [],
    occupants = [],
    featuresObj = {},
  }: FeaturePropType,
  { enabled = true, ...options }: Option
) => {
  const relationshipV2GraphRef = useRef<Graph | null>(null)
  const elevatorPreferredRelationshipV2GraphRef = useRef<Graph | null>(null)
  const [relationshipGraphLoaded, setRelationshipGraphLoaded] =
    useState<boolean>(false)
  const possibleObstacleFeatures = useMemo(
    () => [...units, ...kiosks, ...fixtures],
    [units, kiosks, fixtures]
  )

  const landmarks = useMemo(
    () =>
      features.filter(
        ({ properties }) =>
          "is_landmark" in properties && properties?.is_landmark
      ),
    [features]
  )

  useEffect(() => {
    // initiate graph
    if (enabled) {
      const { base, elevator } = prepareSteps({
        relationships,
        units,
        openings,
        anchors,
        amenities,
        featuresObj,
      })
      relationshipV2GraphRef.current = new Graph(base)
      elevatorPreferredRelationshipV2GraphRef.current = new Graph(elevator)

      setRelationshipGraphLoaded(true)
    }
  }, [relationships, openings, units, anchors, amenities, featuresObj, enabled])

  const groupedOccupantsByOrdinal = useMemo(
    () =>
      _(occupants)
        .filter(
          (occupant) =>
            !_.isNil(
              occupant.properties.anchor?.properties.unit?.properties.ordinal
            )
        )
        .groupBy(
          (occupant) =>
            occupant.properties.anchor?.properties.unit?.properties.ordinal
        )
        .value(),
    [occupants]
  )

  const groupedLandmarksByOrdinal = useMemo(
    () => groupFeatureByOrdinal(landmarks),
    [landmarks]
  )

  // grouping the other obstacle category by ordinal (except the categoris in the relationship)
  const groupedOtherObstacleByOrdinal = useMemo(
    () =>
      _(possibleObstacleFeatures)
        .filter(
          ({ feature_type, properties }) =>
            (isNumber(properties.ordinal) &&
              OBSTACLE_FEATURE_TYPES.includes(feature_type)) ||
            ("category" in properties &&
              OBSTACLE_CATEGORIES.includes(properties.category))
        )
        .map(({ geometry, properties = {}, id, feature_type }) => {
          const obstacleProperties = { id, feature_type, ...properties }
          return geometry.type === "Polygon"
            ? polygon(geometry.coordinates, obstacleProperties)
            : transformMultiPolygonToPolygons(geometry, obstacleProperties)
        })
        .flatten()
        .groupBy((feature) => feature.properties.ordinal)
        .value(),
    [possibleObstacleFeatures]
  )

  const findObstaclesByOrdinal = useCallback(
    (ordinal: number) => groupedOtherObstacleByOrdinal[ordinal],
    [groupedOtherObstacleByOrdinal]
  )

  const findObstaclesFromWalkway = useCallback(
    (intermediaryUnit: UnitData, exceptionIds: string[] = []) => {
      const result = featureCollection([])

      if (!intermediaryUnit) return result

      const walkwayOrdinal = intermediaryUnit.properties.ordinal
      const obstacleOnOrdinal = findObstaclesByOrdinal(walkwayOrdinal).filter(
        (obstacle) => !exceptionIds.includes(obstacle.properties.id)
      )

      const relatedObstacleWithIntermediary = obstacleOnOrdinal.reduce(
        (obstacles, feature) => {
          if (
            // Prevent detecting itself as an obstacle
            // Ex. Unable to draw a line to amenity located with in a room as room is also consider as obstacle
            feature.properties.id !== intermediaryUnit.id &&
            (booleanOverlap(intermediaryUnit, feature) ||
              booleanIntersects(intermediaryUnit, feature))
          )
            obstacles.push(polygon(feature.geometry.coordinates))
          return obstacles
        },
        []
      )

      const intermediaryExtends = envelope(intermediaryUnit)
      const walkwayPerimeter = difference(intermediaryExtends, intermediaryUnit)

      result.features.push(...relatedObstacleWithIntermediary, walkwayPerimeter)
      return result
    },
    [findObstaclesByOrdinal]
  )

  const findStepPath = useCallback(
    (
      originStep: NavigatableFeature,
      destinationStep: NavigatableFeature,
      intermediaries: UnitData[],
      routeOrigin: NavigatableFeature,
      routeDestination: NavigatableFeature
    ) => {
      // find the walkway relates the step's opening
      const relatedWalkablePlatform = intermediaries.find((feature) =>
        WALKABLE_CATEGORY.includes(feature.properties.category)
      )
      // TODO: Temporary solution as an urgent fix may be this should be fix later
      const exceptionFeatureIds = []
      if (routeOrigin.feature_type === "occupant") {
        const occupantKiosk = routeOrigin.properties.kiosk
        if (!!occupantKiosk) exceptionFeatureIds.push(occupantKiosk?.id)
      }
      if (routeDestination.feature_type === "occupant") {
        const occupantKiosk = routeDestination.properties.kiosk
        if (!!occupantKiosk) exceptionFeatureIds.push(occupantKiosk?.id)
      }
      // find obstacles from the unit relates the walkway and the kiosk on target level
      const obstacles = findObstaclesFromWalkway(
        relatedWalkablePlatform,
        _.compact(exceptionFeatureIds)
      )

      // set origin and destination point
      const originPoint = getCenterFromGeometry(originStep?.geometry)
      const destinationPoint = getCenterFromGeometry(destinationStep?.geometry)

      // find path and return LineString
      return findPathOnArea(originPoint, destinationPoint, {
        ...options,
        obstacles,
      })
    },
    [findObstaclesFromWalkway, options]
  )

  const openingIntermediariesObj = useMemo(
    () => groupOpeningConnect(relationships, units),
    [relationships, units]
  )

  const findIntermediary = useCallback(
    (origin, destination) => {
      return _.get(
        openingIntermediariesObj,
        `${origin.id}.${destination.id}`,
        []
      )
    },
    [openingIntermediariesObj]
  )

  const unitConnections = useMemo(() => {
    if (!relationshipGraphLoaded) return {}
    const traversals = relationships.filter(
      (relationship) => relationship.properties.category === "traversal"
    ) as TraversalRelationship[]
    return getUnitConnection(units, traversals)
  }, [relationshipGraphLoaded, units, relationships])

  const createSequencesFromDijkstraPath = useCallback(
    (
      pathOpenings: Feature[],
      routeOrigin: NavigatableFeature,
      routeDestination: NavigatableFeature
    ): ISequence[] => {
      const originIsOccupantWithUnit = featureIsOccupantWithUnit(routeOrigin)
      const destinationIsOccupantWithUnit =
        featureIsOccupantWithUnit(routeDestination)
      const sequences = pathOpenings.reduce(
        (sequences, currentOpening, index, openings) => {
          const isLastSequence = index === openings.length - 1
          const nextOpening = openings[index + 1]
          if (isLastSequence) return sequences

          let intermediary = []
          const origin = currentOpening
          const destination = nextOpening
          if (origin.feature_type !== "opening") {
            intermediary = [findLocatedUnit(origin, units)]
          } else if (destination.feature_type !== "opening") {
            intermediary = [findLocatedUnit(destination, units)]
          } else {
            intermediary = findIntermediary(origin, destination)
          }
          return [
            ...sequences,
            {
              origin,
              destination,
              intermediary,
            },
          ]
        },
        []
      )
      if (routeOrigin.feature_type === "geolocation") {
        sequences.unshift({
          origin: routeOrigin,
          destination: _.first(sequences)?.origin,
          intermediary: [findLocatedUnit(routeOrigin, units)],
        })
      }
      if (routeDestination.feature_type === "geolocation") {
        sequences.push({
          destination: routeDestination,
          origin: _.last(sequences)?.destination,
          intermediary: [findLocatedUnit(routeDestination, units)],
        })
      }
      // Remove The Sequences of entering / leaving occupant's room
      if (originIsOccupantWithUnit) {
        sequences.shift()
      }
      if (destinationIsOccupantWithUnit) {
        sequences.pop()
      }

      return sequences
    },
    [findIntermediary, units]
  )
  const createSteps = useCallback(
    (
      sequences: ISequence[],
      routeOrigin: NavigatableFeature,
      routeDestination: NavigatableFeature
    ): IStep[] => {
      const steps = sequences.reduce((acc, sequence, index) => {
        const { origin, destination, intermediary } = sequence
        const isFirstStep = index === 0
        const isLastStep = index === sequences.length - 1
        const previousStep = _.last(acc)
        const nextStep = sequences[index + 1]

        const comingFrom = isFirstStep
          ? origin
          : _.get(previousStep, "intermediary[0]")
        const goingTo = isLastStep
          ? destination
          : _.get(nextStep, "intermediary[0]")

        // Description
        const occupantInSameOrdinal =
          groupedOccupantsByOrdinal[destination.properties.ordinal]

        const landmarkInSameOrdinal =
          groupedLandmarksByOrdinal[destination.properties.ordinal]
        const nearestLandmark = findNearestFeature(
          landmarkInSameOrdinal,
          destination
        )

        const nearestShop = findNearestFeature(
          occupantInSameOrdinal,
          destination
        )

        const nearestPointOfInterest =
          // Added fallback in case there is nothing available
          findNearbyPointOfInterest(
            nearestLandmark,
            nearestShop,
            destination
          ) || goingTo

        const isGoingToVerticalTraversal =
          featureIsVerticalTraversalUnit(goingTo)

        return [
          ...acc,
          {
            origin,
            destination,
            comingFrom,
            goingTo,
            intermediary,
            category: _.get(intermediary, "[0].properties.category", ""),
            description: createDescription(
              comingFrom,
              isGoingToVerticalTraversal ? goingTo : nearestPointOfInterest,
              intermediary,
              nearestPointOfInterest,
              isLastStep
            ),
            path: findStepPath(
              origin,
              destination,
              intermediary,
              routeOrigin,
              routeDestination
            ),
          },
        ]
      }, [])
      steps.push(createArrivalStep(steps))
      return steps
    },
    [findStepPath, groupedOccupantsByOrdinal, groupedLandmarksByOrdinal]
  )

  const createRouteForSameWalkway = useCallback(
    (
      routeOrigin: NavigatableFeature,
      routeDestination: NavigatableFeature,
      walkwayUnit: UnitData
    ): IRoute => {
      const org =
        routeOrigin.feature_type === "occupant"
          ? routeOrigin.properties?.anchor
          : routeOrigin
      const dest =
        routeDestination.feature_type === "occupant"
          ? routeDestination.properties?.anchor
          : routeDestination

      const steps: IStep[] = [
        {
          origin: org,
          comingFrom: routeOrigin,
          goingTo: routeDestination,
          intermediary: [walkwayUnit],
          description: createDescription(
            routeOrigin,
            routeDestination,
            [walkwayUnit],
            true
          ),
          destination: dest,
          path: findStepPath(
            org,
            dest,
            [walkwayUnit],
            routeOrigin,
            routeDestination
          ),
        },
      ]
      steps.push(createArrivalStep(steps))
      const totalDistance = calculateTotalDistance(steps) // distance in meters
      const roundedDistance = calculateRoundedDistance(totalDistance)
      const duration = calculateTravelingDuration(totalDistance)
      return {
        origin: routeOrigin,
        destination: routeDestination,
        description: null,
        duration,
        steps,
        distance: roundedDistance,
      }
    },
    [findStepPath]
  )

  const calculateRoute = useCallback(
    (
      routeOrigin: NavigatableFeature,
      routeDestination: NavigatableFeature,
      relationshipGraph: Graph
    ): IRoute => {
      // If relationshipGraph is not loaded, return null
      if (!relationshipGraphLoaded || !routeOrigin || !routeDestination)
        return null

      try {
        const originUnit = findLocatedUnit(routeOrigin, units)
        const destinationUnit = findLocatedUnit(routeDestination, units)

        if (originUnit.id === destinationUnit.id) {
          return createRouteForSameWalkway(
            routeOrigin,
            routeDestination,
            originUnit
          )
        }
        const path = findShortestPath(
          routeOrigin,
          routeDestination,
          relationshipGraph,
          unitConnections
        )

        const dijkstraPathFeatures = _(path)
          .map((id) => featuresObj[id])
          .compact()
          .value()

        const sequences: ISequence[] = createSequencesFromDijkstraPath(
          dijkstraPathFeatures,
          routeOrigin,
          routeDestination
        )

        const steps: IStep[] = createSteps(
          sequences,
          routeOrigin,
          routeDestination
        )

        const totalDistance = calculateTotalDistance(steps) // distance in meters
        const roundedDistance = calculateRoundedDistance(totalDistance)
        const duration = calculateTravelingDuration(totalDistance)
        return {
          origin: routeOrigin,
          destination: routeDestination,
          description: null,
          distance: roundedDistance,
          duration,
          steps,
        }
      } catch (error) {
        console.log(error)
        captureException(error, (scope) => {
          scope.setTransactionName(`Error: Unable to find route.`)
          return scope
        })
        throw error
      }
    },
    [
      createSteps,
      createSequencesFromDijkstraPath,
      createRouteForSameWalkway,
      units,
      featuresObj,
      unitConnections,
      relationshipGraphLoaded,
    ]
  )

  const findRoute = useCallback(
    (
      routeOrigin: NavigatableFeature,
      routeDestination: NavigatableFeature
    ): IRoute => {
      return calculateRoute(
        routeOrigin,
        routeDestination,
        relationshipV2GraphRef.current
      )
    },
    [calculateRoute]
  )

  const findElevatorPreferredRoute = (
    routeOrigin: NavigatableFeature,
    routeDestination: NavigatableFeature
  ): IRoute => {
    return calculateRoute(
      routeOrigin,
      routeDestination,
      elevatorPreferredRelationshipV2GraphRef.current
    )
  }

  const getOrdinalFromStep = useCallback(({ properties }): number | null => {
    const { ordinal } = properties
    return _.isNumber(ordinal) ? ordinal : null
  }, [])

  const transformStepsToGeometries = useCallback(
    (steps = []) => {
      const firstStep = steps[0]
      const lastStep = steps[steps.length - 1]
      const routeOrigin: IStepElement = {
        id: "master-origin-marker",
        element_type: "origin-marker",
        geometry: point(getCenterFromGeometry(firstStep.origin.geometry))
          ?.geometry,
        properties: {
          ordinal: getOrdinalFromStep(firstStep.origin),
        },
      }
      const routeDestination: IStepElement = {
        id: "master-destination-marker",
        element_type: "destination-marker",
        geometry: point(getCenterFromGeometry(lastStep.origin.geometry))
          ?.geometry,
        properties: {
          ordinal: getOrdinalFromStep(lastStep.destination),
        },
      }

      const stepGeometries: IStepElement[] = steps.map((step, index) => {
        const { origin, intermediary, destination } = step
        // check the step is the vertical step or not
        const isVerticalStep =
          origin.properties.ordinal !== destination.properties.ordinal &&
          intermediary.length > 0 &&
          intermediary.every((feature) =>
            VERTICAL_TRAVERSAL_CATEGORY.includes(feature.properties.category)
          )
        // get unit ids which are intermediaries
        const intermediaryIds = intermediary.map((unit) => unit.id)

        let stepPath = step.path
        // transform Line String apth to point if the step is the vertical step
        if (isVerticalStep) stepPath = point(step.path.geometry.coordinates[0])

        return {
          ...stepPath,
          isVerticalStep: isVerticalStep,
          isLastStep: steps.length - 1 === index,
          properties: {
            ordinal: getOrdinalFromStep(origin),
            intermediaryUnits: intermediaryIds,
          },
          id: `step-${index + 1}`,
        }
      })
      stepGeometries.unshift(routeOrigin)
      stepGeometries.push(routeDestination)
      return stepGeometries
    },
    [getOrdinalFromStep]
  )

  return {
    relationshipGraphLoaded,
    findRoute: _.memoize(
      findRoute,
      (origin, destination) => `${origin.id} ${destination.id}`
    ),
    findElevatorPreferredRoute: _.memoize(
      findElevatorPreferredRoute,
      (origin, destination) => `${origin.id} ${destination.id}`
    ),
    transformStepsToGeometries,
  }
}
