import * as maptalks from "maptalks"
import { BaseObject } from "maptalks.three"
import * as THREE from "three"
import _ from "lodash"

const OPTIONS = {
  showMarkerLeg: true,
  altitude: 25,
  scale: 0.00015,
}

const getImgDimension = async (url) => {
  const img = new Image()
  img.src = url
  await img.decode()
  return { naturalWidth: img.naturalWidth, naturalHeight: img.naturalHeight }
}

export class Billboard extends BaseObject {
  #layer = null
  constructor(coordinate, options, src, layer, properties) {
    options = maptalks.Util.extend({}, OPTIONS, options, {
      layer,
      coordinate,
    })
    super()
    //Initialize internal configuration
    this.#layer = layer
    this._initOptions(options)
    const {
      altitude = OPTIONS.altitude,
      scale = OPTIONS.scale,
      showMarkerLeg = OPTIONS.showMarkerLeg,
    } = options
    this.properties = { ...properties }
    this._createGroup()

    // Screen size / base mobile screen size ratio => 375
    const divider = _.clamp(window.innerWidth / 375 / 1.75, 1, 1.7)

    // Leg
    if (showMarkerLeg) {
      const legColor = 0xff0400
      const lineMaterial = new THREE.LineBasicMaterial({
        color: legColor,
        transparent: true,
        opacity: 1,
      })
      const lineHeight = layer.altitudeToVector3(altitude, altitude).x
      const lineGeometry = new THREE.BufferGeometry()
      const positions = [0, 0, 0, 0, 0, -lineHeight]
      lineGeometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(positions, 3)
      )
      const line = new THREE.Line(lineGeometry, lineMaterial)
      this.getObject3d().add(line)
    }

    getImgDimension(src).then(({ naturalWidth, naturalHeight }) => {
      // Image
      const map = new THREE.TextureLoader().load(src)
      const material = new THREE.SpriteMaterial({ map: map, color: 0xffffff })
      material.needsUpdate = true
      const sprite = new THREE.Sprite(material)
      sprite.material.sizeAttenuation = false
      sprite.scale.set(
        (scale * naturalWidth) / divider,
        (scale * naturalHeight) / divider,
        1
      )
      this.getObject3d().add(sprite)
    })

    // set object3d position
    const z = layer.altitudeToVector3(altitude, altitude).x
    const position = layer.coordinateToVector3(coordinate, z)
    _.set(this.properties, "default.position", position)
    _.set(this.properties, "default.altitude", altitude)
    _.set(this.properties, "default.scale", scale)
    this.getObject3d().position.copy(position)
  }

  setLineHeight(altitude) {
    const lineHeight = this.#layer.altitudeToVector3(altitude, altitude).x
    const geometry = this.getObject3d().children[0].geometry
    const positionAttribute = geometry.getAttribute("position")
    positionAttribute.setZ(1, -lineHeight)
    positionAttribute.needsUpdate = true

    this.getObject3d().position.z = lineHeight
  }
}
