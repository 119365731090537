import { useCallback } from "react"
import { Box, Button, Divider, useTheme } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "providers/i18n"
import { useAppUI } from "providers/ui"
import { HorizontalScrollPane } from "components/HorizontalScrollPane"
import { MoreIcon } from "components/icons"
import { SearchToggleButton } from "components/domains/search/SearchToggleButton"
import { FeatureChipToggleBar } from "../domains/features/ChipToggleBar"
import { useFeatureQuickSearch } from "hooks/feature/useFeatureQuickSearch"
import { pluralize } from "utils/string"

export const WelcomePanel = ({ navigations = [] }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { defaultProps, styleOverrides = {} } =
    theme.components[`WelcomePanel`] || {}
  const {
    root,
    content,
    logoContainer,
    searchBarContainer,
    navContainer,
    navItem,
    line,
    panelContainer,
    horizontalMenuContainer, //TODO:: planning about quick search's working flow later
  } = styleOverrides
  const navigate = useNavigate()
  const { logo } = theme.assets || {}
  const { showNavigation, showQuickSearch } = defaultProps

  const { categories: quickSearchCategories = [] } = useFeatureQuickSearch()

  const { openSearch, setSidebarState } = useAppUI()

  //TODO:: planning about quick search's working flow later

  const handleClickQuickSearch = useCallback(
    (featureType, category) => {
      const pluralType = pluralize(featureType)
      navigate(`/maps/${pluralType}/${category}`)
    },
    [navigate]
  )

  return (
    <Box sx={root}>
      <Box sx={panelContainer}>
        <Box sx={content}>
          {logo && (
            <Box sx={logoContainer}>
              <img src={logo.url} alt="Logo" />
            </Box>
          )}
          <Box sx={searchBarContainer}>
            <SearchToggleButton
              placeholder={t("Search")}
              onClick={openSearch}
            />
          </Box>
          {!!showNavigation && navigations.length > 0 && (
            <Box sx={navContainer} direction="row" spacing={2}>
              {navigations.map((nav) => (
                <Button
                  sx={navItem}
                  startIcon={nav.icon}
                  key={nav.to}
                  to={nav.to}
                  component={Link}
                >
                  {t(nav.label)}
                </Button>
              ))}
              <Button
                sx={navItem}
                startIcon={<MoreIcon />}
                onClick={() => setSidebarState(true)}
              >
                {t("More")}
              </Button>
            </Box>
          )}
          {/* TODO:: planning about quick search's working flow later */}
          {!!showQuickSearch && quickSearchCategories.length > 0 && (
            <HorizontalScrollPane
              stackProps={{
                sx: horizontalMenuContainer,
                spacing: 1.2,
              }}
            >
              <FeatureChipToggleBar
                categories={quickSearchCategories}
                onClick={handleClickQuickSearch}
              />
            </HorizontalScrollPane>
          )}
        </Box>
        <Divider sx={line} />
      </Box>
    </Box>
  )
}
