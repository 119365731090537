import React from "react"
import {
  styled,
  CardMedia,
  Box,
  Chip,
  CardContent,
  Typography,
  Stack,
  useTheme,
  Card,
} from "@mui/material"
import { useTranslation } from "providers/i18n"
import _ from "lodash"
import { getFeatureDetail } from "../../../utils/feature"
import { LocationInfo } from "../features/LocationInfo"

const Tag = styled(Chip, {
  shouldForwardProp: (props) =>
    props !== "tagBackground" && props !== "tagColor",
})(({ theme, tagBackground, tagColor }) => ({
  position: "absolute",
  right: 12,
  top: 12,
  backgroundColor: tagBackground,
  color: tagColor,
  fontWeight: 500,
  fontSize: theme.typography.htmlFontSize,
}))

export const OccupantCard = (props) => {
  const {
    occupant = {},
    tag = "",
    tagColor = "",
    tagBackground = "",
    onClick = () => {},
  } = props
  const { t } = useTranslation()

  const { properties } = occupant

  const thumbnail = React.useMemo(
    () => _.first(properties.gallery),
    [properties.gallery]
  )

  const { name, zone, location } = getFeatureDetail(occupant)

  const theme = useTheme()
  const { styleOverrides } = theme.components?.[`OccupantCard`] || {}
  const { root, cardMediaWrapper, cardMedia, cardContent, cardTitle } =
    styleOverrides

  return (
    <Card sx={root} onClick={onClick}>
      <Box sx={cardMediaWrapper}>
        <CardMedia sx={cardMedia} component="img" image={thumbnail?.url} />
        <Tag
          label={tag}
          size="small"
          tagBackground={tagBackground}
          tagColor={tagColor}
        />
      </Box>
      <CardContent sx={cardContent}>
        <Stack spacing={0.5}>
          <Typography sx={cardTitle} variant="title" component="h6">
            {t(name)}
          </Typography>
          {zone && (
            <Typography variant="body2" color="text.secondary">
              {`${t("Zone")} ${t(zone)}`}
            </Typography>
          )}
          <Box sx={{ visibility: location ? "" : "hidden" }}>
            <LocationInfo
              feature={occupant}
              variant="body2"
              color="text.secondary"
            />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}
