import { CardActions, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"
import { StartButton, StepButton } from "components/Button"

export const DirectionCardActions = ({
  onStart = () => {},
  onStep = () => {},
  startButtonProps = {},
  stepButtonProps = {},
  sx = {},
  ...props
}) => {
  const { sx: startButtonSx = {} } = startButtonProps
  const { sx: stepButtonSx = {} } = stepButtonProps
  const theme = useTheme()
  const { root, actionButton } =
    theme.components[`DirectionCardActions`]?.styleOverrides || {}

  return (
    <CardActions sx={deepmerge(root, sx)} {...props}>
      <StartButton
        {...startButtonProps}
        sx={deepmerge(actionButton, startButtonSx)}
        onClick={onStart}
      />
      <StepButton
        {...stepButtonProps}
        sx={deepmerge(actionButton, stepButtonSx)}
        onClick={onStep}
      />
    </CardActions>
  )
}
