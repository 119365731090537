import { Box, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"
import { Keyboard } from "components/Keyboard"

export const SearchKeyboard = ({
  value = "",
  onChange,
  topKeywordAdornment = null,
  bottomKeywordAdornment = null,
  keyboardRef,
  sx = {},
}) => {
  const theme = useTheme()
  const { root } = theme.components[`VuiSearchKeyboard`]?.styleOverrides || {}

  return (
    <Box className="VuiSearchKeyboard-root" sx={deepmerge(root, sx)}>
      {topKeywordAdornment}
      <Keyboard inputValue={value} ref={keyboardRef} onChange={onChange} />
      {bottomKeywordAdornment}
    </Box>
  )
}
