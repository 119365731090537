import { polygon } from "@turf/helpers"
import shortestPath from "./shortestPath"
import calculateLength from "@turf/length"
import dayjs from "config/dayjs"

const WALKING_SPEED = 1.4 // meters per min

export const transformMultiPolygonToPolygons = (
  geometry = {},
  properties = {}
) => {
  const { type = null, coordinates = null } = geometry
  return type === "MultiPolygon"
    ? coordinates.map((coord) => polygon(coord, properties))
    : null
}

export const findPathOnArea = (originPoint, destinationPoint, options = {}) => {
  const { obstacles = [], resolution, properties } = options
  console.time("PF Create Path")
  const stepPath = shortestPath(originPoint, destinationPoint, {
    obstacles,
    smoothenPath: true,
    resolution,
  })
  console.timeEnd("PF Create Path")
  stepPath.properties = properties

  return stepPath
}

export const calculatePathLength = (feature) =>
  calculateLength(feature, { units: "kilometers" }) * 1000 // length in meters

export const calculateTravelingDuration = (distance) => {
  const duration = distance / WALKING_SPEED // duration in seconds
  const minutes = Math.round(dayjs.duration({ seconds: duration }).asMinutes())
  return minutes > 0 ? minutes : 1
}

export const calculateTotalDistance = (steps = []) => {
  return steps.reduce((acc, { path }) => acc + calculatePathLength(path), 0)
}

export const calculateRoundedDistance = (distance) => {
  return Math.round(distance - (distance % 25))
}
