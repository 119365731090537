import { useMemo, useCallback } from "react"
import { Box, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"

import { usePromotion } from "hooks/promotion/usePromotion"

import { useVenue } from "providers/venue"
import { useAppUI } from "providers/ui"
import { useTranslation } from "providers/i18n"

import { AppNavigation } from "containers/kiosk-v"
import { PromotionCard } from "components/domains/promotions"
import { EmptyData } from "components/EmptyData"
import { PercentIcon } from "components/icons"
import { LanguageButton } from "components/Language"
import { Carousel } from "components/Carousel/Carousel"
import { SearchToggleButton } from "components/domains/search"
import { CarouselItem } from "components/Carousel"
import { Head } from "components/Head"
import { VenueSelector } from "components/VenueSelector"
import { MotionDiv, showContent } from "components/motions"
import {
  CategoryFilterBar,
  CategoryFilterChip,
} from "components/CategoryFilterBar"

import { getIcon } from "utils/icon"

export const PromotionsKioskVPage = ({ Layout = Box }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { openSearch } = useAppUI()
  const {
    filteredPromotions,
    changeVenueFilter,
    locationVenueId,
    venueFilter,
  } = usePromotion()
  const { venues, isMultiVenueProject } = useVenue()

  const theme = useTheme()
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.promotions) || <PercentIcon />,
    [theme]
  )

  const { styleOverrides } = theme.components?.[`PromotionPage`] || {}
  const {
    root,
    carouselContainer,
    carousel,
    categoryFilterBarContainer,
    emptyDataContainer,
    navigationWrapper,
    venueSelector,
    toolbarContainer,
    actionContainer,
    actionButton,
    searchBarContainer,
  } = styleOverrides

  const handleClickSlide = useCallback(
    (_splide, { slide }, _event) => {
      navigate(slide.getAttribute("link"))
    },
    [navigate]
  )

  return (
    <>
      <Head title={t("Promotions")} />
      <Layout>
        <MotionDiv
          animate="animate"
          initial="initial"
          variants={showContent}
          sx={root}
        >
          {!filteredPromotions || filteredPromotions?.length === 0 ? (
            <Box sx={emptyDataContainer}>
              <EmptyData
                icon={emptyIcon}
                primary={t("Coming soon")}
                secondary={t("Check Back Soon")}
              />
            </Box>
          ) : (
            <>
              <Box sx={categoryFilterBarContainer}>
                {
                  // TODO: Add a condition
                  false && (
                    <CategoryFilterBar
                      renderFilterButton={CategoryFilterChip}
                    />
                  )
                }
              </Box>
              <Box sx={carouselContainer}>
                {filteredPromotions && filteredPromotions.length > 0 ? (
                  <Carousel
                    onClick={handleClickSlide}
                    options={{
                      type: "loop",
                      pagination: true,
                      focus: "center",
                      autoplay: true,
                      gap: "0",
                    }}
                    sx={carousel}
                  >
                    {filteredPromotions.map((promotion) => {
                      const sponsoredId =
                        promotion?.properties?.sponsoredId || ""
                      return (
                        <CarouselItem
                          link={`/promotions/${promotion.id}`}
                          key={`${sponsoredId}${promotion.id}`}
                        >
                          <PromotionCard promotion={promotion} />
                        </CarouselItem>
                      )
                    })}
                  </Carousel>
                ) : (
                  ""
                )}
              </Box>
            </>
          )}
          {isMultiVenueProject && (
            <Box sx={venueSelector}>
              <VenueSelector
                selectedVenue={venueFilter}
                venues={venues}
                onClickVenue={changeVenueFilter}
                currentVenue={locationVenueId}
              />
            </Box>
          )}
        </MotionDiv>
        <Box key="app-navigation" sx={navigationWrapper}>
          <Box sx={toolbarContainer}>
            <Box sx={actionContainer}>
              <LanguageButton variant="contained" sx={actionButton} />
              {/* //TODO:: Add a wheel chair's mode handler function later */}
              {/*  <Button
            variant="contained"
            sx={actionButton}
            onClick={() => {}}
            startIcon={<RestroomWheelchairIcon />}
          /> */}
            </Box>
            <Box sx={searchBarContainer}>
              <SearchToggleButton
                placeholder={t("Search shops and more...")}
                onClick={openSearch}
              />
            </Box>
          </Box>
          <AppNavigation />
        </Box>
      </Layout>
    </>
  )
}
