import { useState, useCallback } from "react"

export const useLocationHistory = () => {
  const [scannedLocations, setScannedLocations] = useState([])

  const addScannedLocationHistory = useCallback((value) => {
    setScannedLocations((prev) => [...prev, value])
  }, [])

  return {
    scannedLocations,
    addScannedLocationHistory,
  }
}
