import { useMemo, useCallback } from "react"
import { IFeature, IRoute, IStep } from "interfaces"
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { compact, get, first, clamp, isNil, isEmpty } from "lodash"

import { getFeatureDetail } from "utils/feature"
import { useVenue } from "providers/venue"
import { Q_AUTOPLAY } from "constant"
import { useInterval } from "hooks/useInterval"

interface OutletContext {
  steps: IStep[]
  route: IRoute
  origin?: IFeature
  destination?: IFeature
  elevatorPreferredMode?: boolean
  toggleElevatorPreference: () => void
}

export const useStepByStep = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { stepAutoplayConfig, mapTheme } = useVenue()

  const isAutoPlayMode = useMemo(
    () => searchParams.get(Q_AUTOPLAY) === "true",
    [searchParams]
  )

  const {
    route,
    steps = [],
    origin,
    destination,
    elevatorPreferredMode = false,
    toggleElevatorPreference,
  } = useOutletContext<OutletContext>()

  const {
    step = "1",
    origin: originParam,
    destination: destinationParam,
  } = useParams()

  const isReady = useMemo(() => steps.length > 0, [steps])

  const currentStepNumber = Number(step)
  const currentStep = steps[currentStepNumber - 1]

  const nextStepNumber = useMemo(
    () => clamp(currentStepNumber + 1, 1, steps.length),
    [currentStepNumber, steps.length]
  )
  const prevStepNumber = useMemo(
    () => clamp(currentStepNumber - 1, 1, steps.length),
    [currentStepNumber, steps.length]
  )

  const hasPrevStep = useMemo(
    () => isReady && currentStepNumber > 1,
    [isReady, currentStepNumber]
  )

  const hasNextStep = useMemo(
    () => isReady && currentStepNumber < steps.length,
    [isReady, currentStepNumber, steps]
  )

  const nextStepPath = useMemo(() => {
    if (isNil(originParam) || isNil(destinationParam)) return
    return `/maps/dir/${originParam}/${destinationParam}/navigate/steps/${nextStepNumber}`
  }, [originParam, destinationParam, nextStepNumber])

  const prevStepPath = useMemo(() => {
    if (isNil(originParam) || isNil(destinationParam)) return
    return `/maps/dir/${originParam}/${destinationParam}/navigate/steps/${prevStepNumber}`
  }, [originParam, destinationParam, prevStepNumber])

  const currentStepPath = useMemo(() => {
    if (isNil(originParam) || isNil(destinationParam)) return
    return `/maps/dir/${originParam}/${destinationParam}/navigate/steps/${currentStepNumber}`
  }, [originParam, destinationParam, currentStepNumber])

  const originData = useMemo(() => getFeatureDetail(origin), [origin])
  const originVenueData = useMemo(
    () => getFeatureDetail(get(origin, "properties.venue")),
    [origin]
  )

  const originName = useMemo(() => get(originData, "name"), [originData])
  const originVenueName = useMemo(
    () => get(originVenueData, "name"),
    [originVenueData]
  )
  const originLevelName = useMemo(() => get(originData, "level"), [originData])

  const originLogo = useMemo(() => get(originData, "logo"), [originData])

  const originCategoryIconPath = useMemo(() => {
    const category = get(originData, "category", "")
    const categoryName =
      typeof category === "string" ? category : get(category, "en")
    const iconPath = get(
      mapTheme,
      `${categoryName}.label.marker.symbol.markerPath`
    )
    return get(originData, "categoryIconPath", iconPath)
  }, [originData, mapTheme])

  const destinationData = useMemo(
    () => getFeatureDetail(destination),
    [destination]
  )
  const destinationVenueData = useMemo(
    () => getFeatureDetail(get(destination, "properties.venue")),
    [destination]
  )

  const destinationName = useMemo(
    () => get(destinationData, "name"),
    [destinationData]
  )
  const destinationVenueName = useMemo(
    () => get(destinationVenueData, "name"),
    [destinationVenueData]
  )
  const destinationLevelName = useMemo(
    () => get(destinationData, "level"),
    [destinationData]
  )

  const destinationLogo = useMemo(
    () => get(destinationData, "logo"),
    [destinationData]
  )

  const destinationCategoryIconPath = useMemo(() => {
    const category = get(destinationData, "category", "")
    const categoryName =
      typeof category === "string" ? category : get(category, "en")
    const iconPath = get(
      mapTheme,
      `${categoryName}.label.marker.symbol.markerPath`
    )
    return get(destinationData, "categoryIconPath", iconPath)
  }, [destinationData, mapTheme])

  const currentLevel = useMemo(
    () => compact([get(currentStep, "origin.properties.level")]),
    [currentStep]
  )

  const currentOrdinal = useMemo(
    () => get(first(currentLevel), "properties.ordinal"),
    [currentLevel]
  )

  const progressValue = (currentStepNumber * 100) / steps.length

  const isGoingToOtherVenue = useMemo(() => {
    const originVenueId = get(originVenueData, "id")
    const destinationVenueId = get(destinationVenueData, "id")
    return (
      !isNil(originVenueId) &&
      !isNil(originVenueId) &&
      originVenueId !== destinationVenueId
    )
  }, [originVenueData, destinationVenueData])

  /**
   * Step Navigation functions
   */
  const goToNextStep = useCallback(() => {
    if (isNil(nextStepPath)) return
    searchParams.set(Q_AUTOPLAY, "false")
    navigate(`${nextStepPath}?${searchParams.toString()}`, { replace: true })
  }, [navigate, nextStepPath, searchParams])

  const goToPrevStep = useCallback(() => {
    if (isNil(prevStepPath)) return
    searchParams.set(Q_AUTOPLAY, "false")
    navigate(`${prevStepPath}?${searchParams.toString()}`, { replace: true })
  }, [navigate, prevStepPath, searchParams])

  /**
   * Autoplay setting
   */

  const isPlaying = useMemo(
    () => isAutoPlayMode && hasNextStep,
    [isAutoPlayMode, hasNextStep]
  )

  const autoplayCallback = useCallback(() => {
    if (isNil(nextStepPath)) return
    navigate(`${nextStepPath}?${searchParams.toString()}`, { replace: true })
  }, [navigate, nextStepPath, searchParams])

  useInterval({
    delay: stepAutoplayConfig.delay,
    options: {
      enable: isAutoPlayMode && hasNextStep,
    },
    callback: autoplayCallback,
  })

  const resumeAutoPlay = useCallback(() => {
    if (isNil(currentStepPath)) return
    searchParams.set(Q_AUTOPLAY, stepAutoplayConfig.enable)
    navigate(`${currentStepPath}?${searchParams.toString()}`, { replace: true })
  }, [navigate, stepAutoplayConfig, currentStepPath, searchParams])

  const pauseAutoPlay = useCallback(() => {
    if (!isNil(originParam) && !isNil(destinationParam)) {
      searchParams.set(Q_AUTOPLAY, "false")
      navigate(
        `/maps/dir/${originParam}/${destinationParam}/navigate/steps/${currentStepNumber}?${searchParams.toString()}`,
        { replace: true }
      )
    }
  }, [navigate, originParam, destinationParam, currentStepNumber, searchParams])

  const restartAutoPlay = useCallback(() => {
    if (!isNil(originParam) && !isNil(destinationParam)) {
      searchParams.set(Q_AUTOPLAY, stepAutoplayConfig.enable)
      navigate(
        `/maps/dir/${originParam}/${destinationParam}/navigate/steps/1?${searchParams.toString()}`,
        { replace: true }
      )
    }
  }, [
    navigate,
    originParam,
    destinationParam,
    stepAutoplayConfig,
    searchParams,
  ])

  // Current Step Traverse Method
  const currentStepCategory = useMemo(
    () => get(currentStep, "category", ""),
    [currentStep]
  )

  const currentStepDirection = useMemo(() => {
    const currentStepOriginOrdinal = get(
      currentStep,
      "origin.properties.ordinal",
      null
    )
    const currentStepDestinationOrdinal = get(
      currentStep,
      "destination.properties.ordinal",
      null
    )
    if (isNil(currentStepOriginOrdinal) || isNil(currentStepDestinationOrdinal))
      return ""
    if (currentStepOriginOrdinal > currentStepDestinationOrdinal) return "down"
    if (currentStepOriginOrdinal < currentStepDestinationOrdinal) return "up"
    return ""
  }, [currentStep])

  const currentVerticalStepMethod = useMemo(() => {
    if (isEmpty(currentStepCategory) || isEmpty(currentStepDirection)) return ""
    return `${currentStepCategory}-${currentStepDirection}`
  }, [currentStepCategory, currentStepDirection])

  return {
    route,
    steps,
    isPlaying,
    isReady,
    hasPrevStep,
    hasNextStep,
    progressValue,
    originParam,
    destinationParam,
    nextStepNumber,
    prevStepNumber,
    currentStep,
    currentStepNumber,
    currentLevel,
    currentOrdinal,
    originLevelName,
    originName,
    originVenueName,
    originVenueData,
    originLogo,
    originCategoryIconPath,
    destinationName,
    destinationLevelName,
    destinationVenueName,
    destinationVenueData,
    destinationLogo,
    destinationCategoryIconPath,
    isGoingToOtherVenue,
    elevatorPreferredMode,
    currentVerticalStepMethod,
    toggleElevatorPreference,
    goToNextStep,
    goToPrevStep,
    restartAutoPlay,
    resumeAutoPlay,
    pauseAutoPlay,
  }
}
