import React, { useMemo } from "react"
import { Box, IconButton, InputBase, Divider, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"
import ImportExportIcon from "@mui/icons-material/ImportExport"
import MoreVertIcon from "@mui/icons-material/MoreVert"

import { useTranslation } from "providers/i18n"
import { getIcon } from "utils/icon"

import {
  ArrowLeftIcon,
  DestinationMarkerIcon,
  OriginMarkerIcon,
  CloseIcon,
} from "components/icons"

const ClearButton = ({
  sx,
  onClick = () => {},
  show = false,
  children,
  ...props
}) => {
  return (
    <IconButton
      size="small"
      sx={{ ...sx, visibility: show ? "visible" : "hidden" }}
      onClick={onClick}
      {...props}
    >
      {children}
    </IconButton>
  )
}
export const DirectionSearch = ({
  onBack = () => {},
  onSwitch = () => {},
  onOriginChange = () => {},
  onDestinationChange = () => {},
  onOriginFocus = () => {},
  onDestinationFocus = () => {},
  onClick = () => {},
  originInputRef,
  destinationInputRef,
  onClearDestination = () => {},
  onClearOrigin = () => {},
  ...props
}) => {
  const theme = useTheme()
  const {
    directionSearchWrapper,
    backIconWrapper,
    directionWrapper,
    switchIconWrapper,
    directionInputWrapper,
    directionIconWrapper,
    directionInput,
    iconButton,
    backIconButton,
    switchIconButton,
    connectorIcon,
    originIcon,
    destinationIcon,
    divider,
    clearIconButton,
  } = theme.components[`DirectionSearchInput`]?.styleOverrides || {}
  const { t } = useTranslation()

  const showOriginClearButton = originInputRef?.current?.value
  const showDestinationClearButton = destinationInputRef?.current?.value

  const backIcon = useMemo(
    () => getIcon(theme?.assets?.backIcon) || <ArrowLeftIcon />,
    [theme]
  )

  const clearIcon = useMemo(
    () => getIcon(theme?.assets?.directionSearch?.clear) || <CloseIcon />,
    [theme]
  )

  const originMarkerIcon = useMemo(
    () =>
      getIcon(theme?.assets?.directionSearch?.origin, originIcon) || (
        <OriginMarkerIcon fontSize="small" sx={originIcon} />
      ),
    [theme, originIcon]
  )

  const destinationMarkerIcon = useMemo(
    () =>
      getIcon(theme?.assets?.directionSearch?.destination, destinationIcon) || (
        <DestinationMarkerIcon
          color="error"
          fontSize="small"
          sx={destinationIcon}
        />
      ),
    [theme, destinationIcon]
  )

  const locationConnectorIcon = useMemo(
    () =>
      getIcon(theme?.assets?.directionSearch?.connector, connectorIcon) || (
        <MoreVertIcon sx={connectorIcon} color="disabled" fontSize="small" />
      ),
    [theme, connectorIcon]
  )

  return (
    <Box sx={directionSearchWrapper} onClick={onClick}>
      <Box sx={backIconWrapper}>
        <IconButton
          aria-label="back"
          size="small"
          onClick={onBack}
          sx={deepmerge(iconButton, backIconButton)}
        >
          {backIcon}
        </IconButton>
      </Box>

      <Box sx={directionWrapper}>
        <Box sx={directionIconWrapper}>
          {originMarkerIcon}
          {locationConnectorIcon}
          {destinationMarkerIcon}
        </Box>
        <Box sx={directionInputWrapper}>
          <InputBase
            placeholder={t("Choose your origin")}
            onChange={onOriginChange}
            onFocus={onOriginFocus}
            inputRef={originInputRef}
            sx={directionInput}
            {...props}
            endAdornment={
              <ClearButton
                aria-label="clear-origin"
                show={showOriginClearButton}
                sx={clearIconButton}
                onClick={onClearOrigin}
              >
                {clearIcon}
              </ClearButton>
            }
          />

          <Divider sx={divider} />
          <InputBase
            placeholder={t("Choose your destination")}
            onChange={onDestinationChange}
            onFocus={onDestinationFocus}
            inputRef={destinationInputRef}
            sx={directionInput}
            {...props}
            endAdornment={
              <ClearButton
                aria-label="clear-destination"
                show={showDestinationClearButton}
                sx={clearIconButton}
                onClick={onClearDestination}
              />
            }
          />
        </Box>
      </Box>

      <Box sx={switchIconWrapper}>
        <IconButton
          aria-label="switch"
          size="small"
          onClick={onSwitch}
          sx={deepmerge(iconButton, switchIconButton)}
        >
          <ImportExportIcon fontSize="inherit" />
        </IconButton>
      </Box>
    </Box>
  )
}
