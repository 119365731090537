import React from "react"
import { Box, styled } from "@mui/material"

const FullScreenWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "fixed",
  flexBasis: 0,
  top: 0,
  left: 0,
  right: 0,
  height: "100%",
})

export const FullScreenOverlayLayout = ({ children, ...props }) => {
  return <FullScreenWrapper {...props}>{children}</FullScreenWrapper>
}
