import { useQuery } from "@tanstack/react-query"
import { Feature } from "@turf/helpers"
import { prepareFeatures } from "../../utils/prepareFeature"
import { fetchGeojson } from "../../utils/fetchUtils"
import { Imdf, ImdfData } from "../../types"
import { pluralize } from "utils/string"

const FEATURES = [
  "venue",
  "unit",
  "section",
  "fixture",
  "level",
  "amenity",
  "taxonomy",
  "anchor",
  "occupant",
  "kiosk",
  "opening",
  "relationship",
  "privilege",
  "footprint",
]

const imdfInitialData = {
  amenities: [],
  anchors: [],
  categories: [],
  events: [],
  features: [],
  levels: [],
  units: [],
  kiosks: [],
  fixtures: [],
  occupants: [],
  openings: [],
  relationships: [],
  sections: [],
  venues: [],
  privileges: [],
  footprints: [],
}

export const useGetImdf = () => {
  return useQuery<Imdf, unknown, ImdfData>({
    queryKey: ["imdf"],
    queryFn: async () => {
      const geojsonResult = await Promise.allSettled(
        FEATURES.map(fetchGeojson<Feature>)
      )
      const isIncludeRejected = geojsonResult.find(
        (result) => result.status === "rejected"
      )
      if (isIncludeRejected) throw new Error("Unable To Fetch All IMDF data")
      return geojsonResult.reduce((acc, feature) => {
        if (feature.status === "fulfilled") {
          const { type, data } = feature.value
          return {
            ...acc,
            [pluralize(type)]: data,
            features: [...acc.features, ...data],
          }
        }
        return acc
      }, imdfInitialData)
    },
    select: prepareFeatures,
    staleTime: Infinity,
    placeholderData: imdfInitialData,
    retry: true,
    refetchOnWindowFocus: false,
  })
}
