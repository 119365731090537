import { useEffect, useCallback, useState } from "react"
import {
  useLocation,
  useNavigate,
  matchPath,
  useNavigationType,
  NavigateFunction,
  Location,
  NavigationType,
} from "react-router-dom"
import { last } from "lodash"

const NO_REPEAT_PATHS = ["/maps/amenities/*"]

export const useNavigationHistory = () => {
  const location: Location = useLocation()
  const navigationType: NavigationType = useNavigationType()
  //NOTE: add pathname and searchParam to navigationStack to prevent some error when user clicks back
  const currentPath: string = `${location.pathname + location.search}`
  const [navigationStack, setNavigationStack] = useState<string[]>([
    currentPath,
  ])
  const navigate: NavigateFunction = useNavigate()

  useEffect(() => {
    const latestPath = last(navigationStack)
    if (currentPath !== latestPath) {
      switch (navigationType) {
        // Remove the last path from history stack
        case "POP": {
          setNavigationStack((prevStack) => {
            prevStack.pop()
            return [...prevStack]
          })
          break
        }
        // ​Replace the path current location with last path changes
        case "REPLACE": {
          setNavigationStack((prevStack) => {
            prevStack.pop()
            return [...prevStack, currentPath]
          })
          break
        }
        // Add the current location to the navigation stack when it changes
        case "PUSH": {
          setNavigationStack((prevStack) => [...prevStack, currentPath])
          break
        }
        default:
          break
      }
    }
  }, [currentPath, navigationType, navigationStack])

  const goBack = useCallback((): void => {
    // Check if the navigation stack contains more than one entry and if the current path matches the repeat path pattern.
    // Prevent the page from navigating back repeatedly to previously visited place.
    const matchedNoRepeatPath = NO_REPEAT_PATHS.find((pattern) =>
      matchPath(pattern, location.pathname)
    )
    if (navigationStack.length > 1 && matchedNoRepeatPath) {
      const reversedStack = navigationStack.slice().reverse()
      const removedConsecutiveStack = reversedStack
        .filter((value, index) => {
          const isFirstItem = index === 0
          if (isFirstItem) return false
          const prevItem = reversedStack[index - 1]
          return !(
            matchPath(matchedNoRepeatPath, value) &&
            matchPath(matchedNoRepeatPath, prevItem)
          )
        })
        .reverse()
      navigate(last(removedConsecutiveStack))
      setNavigationStack(removedConsecutiveStack)
    } else if (navigationStack.length > 1) {
      // Navigate to the previous location
      navigate(-1)
    } else {
      // If there's only one entry, navigate to the home page or another default route
      navigate("/")
    }
  }, [navigationStack, location.pathname, navigate])

  return {
    goBack,
  }
}
