import { useTheme, Box, CardMedia } from "@mui/material"
import { deepmerge } from "@mui/utils"

import { Carousel, CarouselItem } from "components/Carousel"

export const AdMediaFooterCarousel = ({
  data = [],
  sx = {},
  carouselOptions = {},
  ...props
}) => {
  const theme = useTheme()
  const styleOverrides =
    theme.components[`VuiAdMediaCarouselFooter`]?.styleOverrides || {}
  const { root, carousel, carouselItem } = styleOverrides

  return (
    <Box sx={deepmerge(root, sx)} {...props}>
      <Carousel
        options={{
          type: "loop",
          padding: 0,
          gap: 0,
          pagination: false,
          drag: false,
          ...carouselOptions,
        }}
        sx={carousel}
      >
        {data.map(({ id, props = {} }) => {
          return (
            <CarouselItem key={`slide-${id}`} sx={carouselItem}>
              <CardMedia sx={carouselItem} {...props} />
            </CarouselItem>
          )
        })}
      </Carousel>
    </Box>
  )
}
