import { useTheme, Box } from "@mui/material"
import { deepmerge } from "@mui/utils"

import { Carousel, CarouselItem } from "components/Carousel"
import { Image } from "../../Image/Image"

export const AdImageCarousel = ({
  data = [],
  onClickSlide = () => {},
  carouselOptions = {},
  sx = {},
}) => {
  const theme = useTheme()
  const styleOverrides =
    theme.components[`VuiAdImageCarousel`]?.styleOverrides || {}
  const { root, carousel, carouselItem, image } = styleOverrides

  return (
    <Box sx={deepmerge(root, sx)}>
      <Carousel
        onClick={onClickSlide}
        options={{
          type: "loop",
          pagination: true,
          autoplay: true,
          perPage: 1,
          interval: 5000,
          focus: "center",
          ...carouselOptions,
        }}
        sx={carousel}
      >
        {data.map(({ id, mediaSrc, url, link }) => {
          return (
            <CarouselItem
              link={url || link}
              key={`slide-${id}`}
              sx={carouselItem}
            >
              <Image sx={image} src={mediaSrc} />
            </CarouselItem>
          )
        })}
      </Carousel>
    </Box>
  )
}
