import React, { useMemo } from "react"
import { IconButton, useTheme } from "@mui/material"
import { getIcon } from "utils/icon"
import { useAppUI } from "providers/ui"
import { SearchIcon } from "../icons"

export const HeaderSearchButton = ({ size = "large", ...props }) => {
  const theme = useTheme()
  const styleOverrides =
    theme.components[`AppHeaderSearchButton`]?.styleOverrides || {}
  const { root, icon } = styleOverrides
  const { openSearch } = useAppUI()
  const searchIcon = useMemo(
    () => getIcon(theme?.assets?.search, icon) || <SearchIcon />,
    [theme, icon]
  )

  return (
    <IconButton
      size={size}
      edge="start"
      color="inherit"
      aria-label="back"
      sx={root}
      onClick={openSearch}
      {...props}
    >
      {searchIcon}
    </IconButton>
  )
}
