import React, { useCallback } from "react"
import {
  Avatar,
  Box,
  Card,
  CardMedia,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"
import { deepmerge } from "@mui/utils"

import { useMemo } from "react"
import get from "lodash/get"
import { CustomIcon } from "components/icons"
import { getFeatureDetail } from "utils/feature"
import { useTranslation } from "providers/i18n"
import { useNavigate } from "react-router-dom"
import { useVenue } from "providers/venue"

export const DirectoryOccupantCard = ({ data, sx, endAdornment, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isMultiVenueProject } = useVenue()
  const categoryIcon = useMemo(
    () => get(data, "properties.local_categories[0].properties.icon_path"),
    [data]
  )
  const { name, logo, group, category, level, venueLogo, boothName } = useMemo(
    () => getFeatureDetail(data),
    [data]
  )

  const handleClick = useCallback(
    () => navigate(`/maps/place/${data.id}`),
    [navigate, data?.id]
  )

  const theme = useTheme()
  const { styleOverrides = {} } =
    theme.components?.[`DirectoryOccupantCard`] || {}
  const {
    root,
    occupantLogo,
    cardContent,
    levelContainer,
    divider,
    venueLogo: venueLogoStyle,
    titleText,
    groupText,
    categoryText,
    titleContainer,
    detailWrapper,
    levelContainerWrapper,
    locationInfoContainer,
    boothText,
    infoContainer,
  } = styleOverrides

  return (
    <Card sx={deepmerge(root, sx)} onClick={handleClick} {...props}>
      <Box sx={occupantLogo}>
        <Avatar src={logo}>
          {logo ? "" : <CustomIcon path={categoryIcon} />}
        </Avatar>
      </Box>
      <Box sx={cardContent}>
        <Box sx={titleContainer}>
          <Typography sx={titleText}>{t(name)}</Typography>
          {boothName && (
            <Typography sx={boothText}>
              {`${t("Booth")} ${t(boothName)}`}
            </Typography>
          )}
        </Box>
        <Stack spacing={1} sx={detailWrapper}>
          <Divider sx={divider} />
          <Stack
            direction="row"
            sx={infoContainer}
            justifyContent="space-between"
          >
            <Stack justifyContent="end" spacing={1} sx={locationInfoContainer}>
              {isMultiVenueProject && venueLogo && (
                <CardMedia
                  component="img"
                  image={venueLogo}
                  sx={venueLogoStyle}
                />
              )}
              <Typography variant="body2" color="grey" sx={groupText}>
                {t(group)}
              </Typography>
              <Typography variant="body2" sx={categoryText}>
                {t(category)}
              </Typography>
            </Stack>
            <Box sx={levelContainerWrapper}>
              <Box sx={levelContainer}>
                <Typography variant="h6">{t(level)}</Typography>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Card>
  )
}
