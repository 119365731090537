import { Box, StepIconProps, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"

export const DirectionStepIcon: React.FC<StepIconProps> = ({
  sx = {},
  icon: iconNumber,
}) => {
  const theme = useTheme()
  const { root } = theme.components[`DirectionStepIcon`]?.styleOverrides || {}
  return <Box sx={deepmerge(root, sx)}>{iconNumber}</Box>
}
