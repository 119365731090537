import { useCallback, useState } from "react"
import {
  Box,
  Stepper,
  Typography,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  useTheme,
  Backdrop,
  CircularProgress,
} from "@mui/material"

import { useNavigate } from "react-router-dom"
import { useTranslation } from "providers/i18n"
import _ from "lodash"
import { Trans } from "react-i18next"

import { useVenue } from "providers/venue"
import { useDirectionOverview } from "hooks/direction/useDirectionOverview"
import { useDirectionOverviewMap } from "hooks/direction/useDirectionOverviewMap"
import { SwipeableDrawer } from "components/SwipeableDrawer"
import { OrdinalSelector } from "components/OrdinalSelector"
import { DirectionSearchContainer } from "containers/DirectionSearchContainer"
import { OriginMarkerIcon } from "components/icons"
import {
  DirectionSummary,
  DirectionStepItem,
  DirectionCardActions,
} from "components/domains/direction"
import { DestinationMarkerIcon } from "../../../../../components/icons"
import { useAppUI } from "providers/ui"

export const DirectionOverviewPage = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const { isMultiOrdinalVenue } = useVenue()

  const {
    isCalculatingRoute,
    steps,
    duration,
    distance,
    currentOrdinal,
    originData,
    originName,
    destinationData,
    destinationName,
    destinationLevelName,
    elevatorPreferredMode,
    relatedLevelWithSteps,
    scannedLocations,
    userLocation,
    route,
    changeOrdinal,
    toggleElevatorPreference,
    startNavigate,
  } = useDirectionOverview()

  useDirectionOverviewMap(route, currentOrdinal)

  const { t } = useTranslation()

  const { goBack } = useAppUI()

  const theme = useTheme()
  const { drawer: drawerProps } =
    theme.components[`AppDirectionOverview`]?.defaultProps || {}

  const { headerWrapper, paper, formControlLabel } =
    theme.components[`AppDirectionSearch`]?.styleOverrides || {}
  const {
    bleedWrapper,
    drawer,
    root,
    stepper,
    stepOverviewContainer,
    numberStepsText,
  } = theme.components[`AppDirectionOverview`]?.styleOverrides || {}

  const { originIcon, destinationIcon, highlightedText } =
    theme.components[`DirectionStep`]?.styleOverrides || {}

  const destinationFloorText = isMultiOrdinalVenue
    ? `(${t("At Floor", {
        value: destinationLevelName,
      })})`
    : ""
  const highlightedStepText = (
    <Typography variant="subTitle" component="span" sx={highlightedText} />
  )

  const handleClickBack = useCallback(() => {
    goBack()
  }, [goBack])

  const handleOpenDrawer = useCallback(() => {
    setOpen(true)
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setOpen(false)
  }, [])

  const handleChange = useCallback(
    ({ origin, destination }) => {
      const originParam = origin?.id || "unknown"
      const destinationParam = destination?.id || "unknown"
      navigate(`/maps/dir/${originParam}/${destinationParam}`, {
        replace: true,
      })
    },
    [navigate]
  )

  const handleClickStep = useCallback(() => setOpen((prev) => !prev), [])
  const stepsAvailable = steps && steps.length > 0
  return (
    <>
      <Backdrop open={isCalculatingRoute}>
        <CircularProgress />
      </Backdrop>
      <Box sx={root}>
        <Box sx={headerWrapper}>
          <Stack spacing={2} alignItems="flex-start">
            <DirectionSearchContainer
              origin={originData}
              destination={destinationData}
              onChange={handleChange}
              onBack={handleClickBack}
              userLocation={userLocation}
              lastScannedLocation={_.last(scannedLocations)}
            />
            <Paper elevation={1} sx={paper}>
              <FormControlLabel
                control={
                  <Switch
                    checked={elevatorPreferredMode}
                    onChange={toggleElevatorPreference}
                  />
                }
                label={t("Avoid Steps")}
                componentsProps={{ typography: { variant: "body2" } }}
                sx={formControlLabel}
              />
            </Paper>
          </Stack>
        </Box>

        {/* //TODO:: find a way for adding active ordinal (selectedOrdinal) */}
        {isMultiOrdinalVenue && (
          <OrdinalSelector
            levels={relatedLevelWithSteps}
            onClickOrdinal={changeOrdinal}
            selectedOrdinal={currentOrdinal}
          />
        )}
      </Box>

      {stepsAvailable && (
        <>
          {/* Direction Summary (mobile) */}
          <SwipeableDrawer
            {...drawerProps}
            open={open}
            onClose={handleCloseDrawer}
            onOpen={handleOpenDrawer}
            contentProps={{ onTouchStart: () => {} }}
            sx={drawer}
          >
            <Box sx={bleedWrapper}>
              <DirectionSummary
                primary={t("Travel", {
                  duration,
                  distance,
                })}
                secondary={
                  <Trans
                    components={{
                      origin: highlightedStepText,
                      destination: highlightedStepText,
                    }}
                  >
                    {t("Origin To Destination", {
                      origin: `${t(originName)}`,
                      destination: `${t(
                        t(destinationName)
                      )} ${destinationFloorText}`,
                    })}
                  </Trans>
                }
              />
              <DirectionCardActions
                onStart={startNavigate}
                onStep={handleClickStep}
              />
            </Box>

            <Box sx={stepOverviewContainer}>
              <Typography sx={numberStepsText} variant="body2">
                {t("NumberSteps", {
                  count: steps?.length || 0,
                })}
              </Typography>
              <Stepper sx={stepper} orientation="vertical" connector={null}>
                {originData && (
                  <DirectionStepItem
                    primaryLabel={
                      <Trans
                        components={{
                          location: highlightedStepText,
                        }}
                      >
                        {isMultiOrdinalVenue
                          ? t("Location Floor", {
                              location: t(originName),
                              level: originData.level,
                            })
                          : t(originName)}
                      </Trans>
                    }
                    secondaryLabel={t("Your last scanned location")}
                    stepLabelProps={{
                      icon: <OriginMarkerIcon sx={originIcon} />,
                    }}
                  />
                )}
                {steps.map((step, index) => {
                  const { description } = step
                  return (
                    <DirectionStepItem
                      index={index}
                      navigateTo={`navigate/steps/${index + 1}`}
                      primaryLabel={
                        <Trans
                          components={{
                            category: highlightedStepText,
                            destination: highlightedStepText,
                            landmark: highlightedStepText,
                          }}
                        >
                          {t(description.text, {
                            category: t(description.options?.category),
                            destination: t(description.options?.destination),
                            landmark: t(description.options?.landmark),
                          })}
                        </Trans>
                      }
                      key={`step-description-${index}`}
                    />
                  )
                })}
                {destinationData && (
                  <DirectionStepItem
                    primaryLabel={
                      <Trans
                        components={{
                          location: highlightedStepText,
                        }}
                      >
                        {isMultiOrdinalVenue
                          ? t("Location Floor", {
                              location: t(destinationName),
                              level: destinationData.level,
                            })
                          : t(destinationName)}
                      </Trans>
                    }
                    secondaryLabel={t("Your Destination")}
                    stepLabelProps={{
                      icon: <DestinationMarkerIcon sx={destinationIcon} />,
                    }}
                  />
                )}
              </Stepper>
            </Box>
          </SwipeableDrawer>
        </>
      )}
    </>
  )
}
