import React, { useCallback, useMemo, useRef } from "react"
import {
  Box,
  useTheme,
  Paper,
  Fab,
  Avatar,
  Typography,
  Stack,
  Divider,
  Button,
  Tabs,
  Tab,
  Slide,
} from "@mui/material"
import { useNavigate } from "react-router-dom"

import { useTranslation } from "providers/i18n"
import { useAppUI } from "providers/ui"
import { useFeatureDetail } from "hooks/feature/useFeatureDetail"
import { usePlaceDetailMap } from "hooks/place/usePlaceDetailMap"
import { useDetailTab } from "extensions/one-siam/hooks/useDetailTab"

import { getIcon } from "utils/icon"

import { InfoItem } from "extensions/one-siam/components/InfoItem"
import {
  ClockIcon,
  LayerIcon,
  ShopIcon,
} from "extensions/one-siam/components/icons"
import {
  PlaceDetailTabPanel,
  SkeletonPlaceDetail,
  GalleryCarousel,
  PromotionList,
} from "extensions/one-siam/components/domains/features"

import { Head } from "components/Head"
import { BackIcon, CustomIcon } from "components/icons"
import { Image } from "components/Image"
import { QRCode } from "components/QRCode"

import { createOpeningHours } from "utils/date"

const TAB_INDEXES = {
  GALLERY: "gallery-tab",
  PROMOTION: "promotion-tab",
}

export const PlaceDetailKioskVPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const dialogRef = useRef(null)

  const { goBack } = useAppUI()
  const { dataLoaded, categoryIconProp = {}, ...data } = useFeatureDetail()
  const {
    category,
    description,
    feature,
    gallery = [],
    hours,
    id,
    level,
    logo,
    name,
    placeDetailMobileUrl,
    promotions,
    venueLogo,
  } = data
  const { iconPath } = categoryIconProp
  const { open = "", close = "" } = useMemo(
    () => createOpeningHours(hours),
    [hours]
  )

  const {
    showGallery,
    showPromotion,
    showDetailTabs,
    detailTabProps,
    selectedDetailTab,
    changeDetailTab,
  } = useDetailTab({
    data,
    enabled: dataLoaded,
  })

  usePlaceDetailMap(feature, {
    enabled: !!feature,
    offset: { bottom: 315 },
  })

  const theme = useTheme()
  const { styleOverrides = {} } = theme.components[`AppDetailPage`] || {}
  const {
    root,
    wrapper,
    container,
    kioskBackButton,
    primarySection,
    secondarySection,
    contentContainer,
    avatarContainer,
    occupantLogo,
    venueLogoContainer,
    venueLogoImage,
    detailContainer,
    listInfoContainer,
    listInfoDivider,
    listInforItem,
    divider,
    titleText,
    descriptionText,
    qrCode,
    qrCodeContainer,
    qrCodeHelperText,
    actionContainer,
    actionButton,
    // Tabs
    placeDetailTabContainer,
    placeDetailTabs,
    placeDetailTabPanel,
    placeDetailTab,
  } = styleOverrides

  const backIcon = useMemo(
    () => getIcon(theme?.assets?.backIcon) || <BackIcon />,
    [theme]
  )

  const onNavigate = useCallback(() => {
    const locationId = feature?.properties?.feature?.id || id
    navigate(`/maps/dir/unknown/${locationId}`)
  }, [navigate, feature, id])

  const onClose = useCallback(() => {
    goBack()
  }, [goBack])

  return (
    <>
      <Head title={`${t(name)} (${t("Place Detail")})`} />
      <Box sx={root}>
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
          <Box sx={wrapper} ref={dialogRef}>
            <Fab variant="rounded" sx={kioskBackButton} onClick={onClose}>
              {backIcon}
            </Fab>
            {!dataLoaded ? (
              <SkeletonPlaceDetail />
            ) : (
              <Paper sx={container} spacing={1}>
                <Box sx={primarySection}>
                  <Box sx={contentContainer}>
                    <Box sx={avatarContainer}>
                      <Avatar sx={occupantLogo} src={logo}>
                        {iconPath ? (
                          <CustomIcon path={iconPath} />
                        ) : (
                          <ShopIcon />
                        )}
                      </Avatar>
                      {venueLogo && (
                        <Box sx={venueLogoContainer}>
                          <Image sx={venueLogoImage} src={venueLogo} />
                        </Box>
                      )}
                    </Box>
                    <Box sx={detailContainer}>
                      <Typography sx={titleText} variant="h5">
                        {t(name)}
                      </Typography>
                      <Stack
                        sx={listInfoContainer}
                        direction="row"
                        spacing={2}
                        divider={
                          <Divider
                            sx={listInfoDivider}
                            orientation="vertical"
                            flexItem
                          />
                        }
                      >
                        {category && (
                          <InfoItem
                            className="category"
                            sx={listInforItem}
                            label={t(category)}
                          />
                        )}
                        <InfoItem
                          sx={listInforItem}
                          icon={<LayerIcon />}
                          label={t(level)}
                        />
                        <InfoItem
                          sx={listInforItem}
                          icon={<ClockIcon />}
                          label={`${open} - ${close}`}
                        />
                      </Stack>
                      <Divider sx={divider} />
                      <Typography variant="body1" sx={descriptionText}>
                        {t(description)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={placeDetailTabContainer}>
                    {showDetailTabs && (
                      <>
                        <Tabs
                          sx={placeDetailTabs}
                          value={selectedDetailTab}
                          onChange={changeDetailTab}
                          indicatorColor="primary"
                          variant="fullWidth"
                        >
                          <Tab
                            sx={placeDetailTab}
                            className={showGallery ? "" : "tab-hidden"}
                            label={t("Gallery")}
                            value={TAB_INDEXES.GALLERY}
                          />
                          <Tab
                            sx={placeDetailTab}
                            className={showPromotion ? "" : "tab-hidden"}
                            label={t("Promotion")}
                            value={TAB_INDEXES.PROMOTION}
                          />
                        </Tabs>
                        {showGallery && (
                          <PlaceDetailTabPanel
                            sx={placeDetailTabPanel}
                            value={selectedDetailTab}
                            {...detailTabProps.gallery}
                          >
                            <GalleryCarousel data={gallery} />
                          </PlaceDetailTabPanel>
                        )}
                        {showPromotion && (
                          <PlaceDetailTabPanel
                            sx={placeDetailTabPanel}
                            value={selectedDetailTab}
                            {...detailTabProps.promotion}
                          >
                            <PromotionList data={promotions} />
                          </PlaceDetailTabPanel>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
                <Box sx={secondarySection}>
                  {placeDetailMobileUrl && (
                    <Box sx={qrCodeContainer}>
                      <QRCode sx={qrCode} url={placeDetailMobileUrl} />
                      <Stack>
                        <Typography sx={qrCodeHelperText}>
                          {t("Scan for direction")}
                        </Typography>
                        <Typography sx={qrCodeHelperText}>
                          {t("On mobile")}
                        </Typography>
                      </Stack>
                    </Box>
                  )}
                  <Box sx={actionContainer}>
                    <Button
                      variant="contained"
                      onClick={onNavigate}
                      sx={actionButton}
                    >
                      {t("Get direction")}
                    </Button>
                  </Box>
                </Box>
              </Paper>
            )}
          </Box>
        </Slide>
      </Box>
    </>
  )
}
