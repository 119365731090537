import React, { useRef, useEffect, useCallback } from "react"
import {
  Stepper,
  Step,
  StepIcon,
  styled,
  StepConnector,
  useTheme,
  StepLabel,
  Stack,
} from "@mui/material"
import { OriginMarkerIcon } from "components/icons"
import { StepByStepIcon } from "./StepIcon"

const StepByStepConnector = styled(StepConnector)(({ theme }) => ({}))

export const StepProgressBar = (props) => {
  const {
    currentStep = 1,
    steps = [],
    sx = {},
    handleClickStep = () => {},
    enableAnimateScroll = false,
  } = props
  const lastStep = steps.length - 1
  const destinationStep = steps.length

  const theme = useTheme()
  const { stepper, originIcon, connector, wrapper } =
    theme.components[`StepProgressBar`]?.styleOverrides || {}

  const scrollRef = useRef([])
  const previousStepRef = useRef(0)

  const scrollToSection = useCallback((index) => {
    if (scrollRef.current.length) {
      /**
       * ScrollIntoView() causing the whole page to move
       * https://stackoverflow.com/questions/11039885/scrollintoview-causing-the-whole-page-to-move
       */
      scrollRef.current[index]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      })
    }
  }, [])

  useEffect(() => {
    if (!enableAnimateScroll) return

    //scroll to origin marker if currentStep is 1
    if (currentStep === 1) {
      scrollToSection(0)
    }
    //scroll to destination marker if currentStep is the arriving destination step
    else if (currentStep === destinationStep) {
      scrollToSection(destinationStep)
    }
    //focus to lesser step if user is going to previous step to avoid currentStep element clipping to container
    else if (currentStep < previousStepRef.current) {
      scrollToSection(currentStep - 1)
    }
    //focus to further step if user is going to next step to avoid currentStep element clipping to container
    else if (currentStep > previousStepRef.current) {
      scrollToSection(currentStep + 1)
    }
    previousStepRef.current = currentStep
  }, [currentStep, destinationStep, scrollToSection, enableAnimateScroll])

  return (
    <Stack sx={{ ...wrapper, ...sx }}>
      <Stepper
        activeStep={currentStep}
        connector={<StepByStepConnector sx={connector} />}
      >
        <Step sx={stepper} ref={(ref) => (scrollRef.current[0] = ref)}>
          <StepIcon icon={<OriginMarkerIcon sx={originIcon} />} />
        </Step>
        {steps.map((step, index) => {
          return (
            <Step
              key={index}
              //ref for step 1 onwards
              ref={(ref) => (scrollRef.current[index + 1] = ref)}
              sx={stepper}
              onClick={() => handleClickStep(index)}
            >
              <StepLabel
                StepIconComponent={(labelProps) => (
                  <StepByStepIcon
                    {...labelProps}
                    step={step}
                    lastStep={lastStep}
                    index={index}
                  />
                )}
              />
            </Step>
          )
        })}
      </Stepper>
    </Stack>
  )
}
