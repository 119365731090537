import React from "react"
import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction,
  Box,
  Slide,
  useTheme,
} from "@mui/material"

import { useTranslation } from "providers/i18n"
import { NavigationLink } from "./NavigationLink"

export const Navigation = ({ value, navigations = [], show = true }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const styleOverrides =
    theme.components[`AppKioskNavigation`]?.styleOverrides || {}
  const { root, menuButton, navigationContainer } = styleOverrides

  return (
    <Slide direction="up" appear={false} mountOnEnter unmountOnExit in={show}>
      <Box sx={root}>
        <MuiBottomNavigation value={value} showLabels sx={navigationContainer}>
          {navigations.map(({ to, label, icon, disabled = false }) => (
            <BottomNavigationAction
              sx={menuButton}
              component={NavigationLink}
              key={to}
              label={t([`navigation.${label}`, label])}
              to={to}
              value={to}
              icon={icon}
              disabled={disabled}
              backSideProps={{
                children: icon,
              }}
            />
          ))}
        </MuiBottomNavigation>
      </Box>
    </Slide>
  )
}
