import {
  MapIcon,
  DirectoryIcon,
  HomeIcon,
  PercentIcon,
  StoreIcon,
  CalendarStarIcon,
  DiningIcon,
} from "../components/icons"

export const navigations = [
  {
    label: "Home",
    to: "/",
    icon: <HomeIcon />,
    order: 1,
  },
  {
    label: "Map",
    to: "/maps",
    icon: <MapIcon />,
    order: 2,
  },
  {
    label: "Directory",
    to: "/directory",
    icon: <DirectoryIcon />,
    order: 3,
  },
  {
    label: "Dining",
    to: "/dining",
    icon: <DiningIcon />,
    order: 4,
  },
  {
    label: "Events & Activities",
    to: "/events",
    icon: <CalendarStarIcon />,
    order: 5,
  },
  {
    label: "Promotions",
    to: "/promotions",
    icon: <PercentIcon />,
    order: 6,
  },
  // Not Available in this phase
  {
    label: "New Shops",
    to: "/new-shops",
    icon: <StoreIcon />,
    order: 7,
  },
  /*   {
  label: "Tourist Privileges",
  to: "/tourist-privileges",
  icon: <DiamondIcon />,
}, */
]

export const kioskNavigations = [
  {
    label: "Home",
    to: "/",
    icon: <HomeIcon />,
  },
  {
    label: "Map",
    to: "/maps",
    icon: <MapIcon />,
  },
  {
    label: "Directory",
    to: "/directory",
    icon: <DirectoryIcon />,
  },
  {
    label: "Events",
    to: "/events",
    icon: <CalendarStarIcon />,
  },
  {
    label: "Dining",
    to: "/dining",
    icon: <DiningIcon />,
  },
  {
    label: "Promotions",
    to: "/promotions",
    icon: <PercentIcon />,
  },
]

export const kioskMainNavigations = [
  {
    label: "Home",
    to: "/",
    icon: <HomeIcon />,
  },
]

export const bottomNavigations = [
  {
    label: "Map",
    to: "/maps",
    icon: <MapIcon />,
  },
  {
    label: "Directory",
    to: "/directory",
    icon: <DirectoryIcon />,
  },
]

export const homeNavigations = [
  {
    label: "Map",
    to: "/maps",
    icon: <MapIcon />,
  },
  {
    label: "Directory",
    to: "/directory",
    icon: <DirectoryIcon />,
  },
  {
    label: "Events",
    to: "/events",
    icon: <CalendarStarIcon />,
  },
]
