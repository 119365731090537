import { useMemo } from "react"
import _ from "lodash"

import { IRecord } from "interfaces"

import { useSponsoredContent } from "./useSponsoredContent"

interface ISponsorContentConfig {
  disable?: boolean
  limit?: number
  frequency?: number
}

interface IUseSponsoredScreensaverResult {
  screensavers: IRecord[]
  screensaverConfig: ISponsorContentConfig
}
export const useSponsoredScreensaver = (): IUseSponsoredScreensaverResult => {
  const { screensaver } = useSponsoredContent()

  const screensaverConfig = useMemo(
    () => _.get(screensaver, "config"),
    [screensaver]
  )

  const screensavers = useMemo(
    () => _.get(screensaver, "data", []),
    [screensaver]
  )

  return {
    screensavers,
    screensaverConfig,
  }
}
