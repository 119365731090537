import { Fragment, useMemo } from "react"
import { Box, List, useTheme } from "@mui/material"
import { useTranslation } from "providers/i18n"
import { useDirectory } from "hooks"
import { SKELETON_COUNT, SKELETON_INDEX_TO_REF } from "constant"
import { getIcon } from "utils/icon"
import { Head } from "components/Head"
import { VenueSelector } from "components/VenueSelector"
import { HorizontalScrollPane } from "components/HorizontalScrollPane"
import { CategoryFilterBar } from "components/CategoryFilterBar"
import { ShopListItem } from "components/domains/directory"
import { EmptyData } from "components/EmptyData"
import { MotionDiv, showContent } from "components/motions"
import { FeatureSkeletonListItem } from "components/domains/features"
import { PageLayout } from "extensions/one-siam/layouts/PageLayout"
import {
  Header,
  HeaderMenuButton,
  HeaderSearchButton,
} from "extensions/one-siam/components/Header"
import { AppNavigation } from "extensions/one-siam/container/AppNavigation"
import { ShopIcon } from "extensions/one-siam/components/icons"

export const Directory = ({ Layout = PageLayout }) => {
  const theme = useTheme()
  const {
    handleChangeParentCategory,
    changeVenueFilter,
    filteredOccupantsSorted,
    sortedParentCategoryWithOccupant,
    parentCategoryId,
    venueFilter,
    venues,
    isMultiOrdinalVenue,
    locationVenueId,
    hasData,
    endScrollRef,
    hasNextPage,
  } = useDirectory()

  const { t } = useTranslation()
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.directory) || <ShopIcon />,
    [theme]
  )
  const {
    root,
    headerWrapper,
    navigationWrapper,
    filterSection,
    scrollPane,
    categoryFilterBar,
    list,
    emptyDataWrapper,
  } = theme.components[`DirectoryPage`]?.styleOverrides || {}

  return (
    <>
      <Head title={t("Directory")} />
      <Layout
        header={
          <Box sx={headerWrapper}>
            <Header
              title={t("Directory")}
              menu={<HeaderMenuButton />}
              search={<HeaderSearchButton />}
            />
          </Box>
        }
      >
        <MotionDiv
          variants={showContent}
          initial="initial"
          animate="animate"
          sx={root}
        >
          <Box sx={filterSection}>
            <HorizontalScrollPane
              className="category-filterbar"
              stackProps={{
                sx: scrollPane,
              }}
            >
              <CategoryFilterBar
                sx={categoryFilterBar}
                activeCategory={parentCategoryId}
                categories={sortedParentCategoryWithOccupant}
                onChange={handleChangeParentCategory}
              />
            </HorizontalScrollPane>
          </Box>
          <List sx={list}>
            {hasData ? (
              <>
                {filteredOccupantsSorted.map((occupant, index) => (
                  <ShopListItem
                    key={`directory-item-${index}`}
                    occupant={occupant}
                    hideLevel={!isMultiOrdinalVenue}
                  />
                ))}
                {hasNextPage &&
                  Array.from({ length: SKELETON_COUNT }).map((_, index) => (
                    <FeatureSkeletonListItem
                      ref={
                        index === SKELETON_INDEX_TO_REF ? endScrollRef : null
                      }
                      key={index}
                      divider
                    />
                  ))}
              </>
            ) : (
              <Box sx={emptyDataWrapper}>
                <EmptyData
                  icon={emptyIcon}
                  primary={t("Coming soon")}
                  secondary={t("Stay tuned for the update")}
                />
              </Box>
            )}
          </List>
        </MotionDiv>
        <Box sx={navigationWrapper}>
          <VenueSelector
            selectedVenue={venueFilter}
            venues={venues}
            onClickVenue={changeVenueFilter}
            currentVenue={locationVenueId}
          />
          <AppNavigation />
        </Box>
      </Layout>
    </>
  )
}
