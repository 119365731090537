import React, { forwardRef } from "react"
import {
  ListItem,
  ListItemAvatar,
  useTheme,
  Box,
  Skeleton,
  ListItemProps,
} from "@mui/material"
import { deepmerge } from "@mui/utils"

export const FeatureSkeletonListItem = forwardRef<HTMLLIElement, ListItemProps>(
  ({ sx = {}, divider, ...props }, ref) => {
    const theme = useTheme()
    const {
      root,
      bottomDivider,
      listItemAvatar,
      avatar,
      listItemContent,
      listItemTextPrimary,
      listItemTextSecondary,
    } = theme.components[`FeatureSkeletonListItem`]?.styleOverrides || {}

    return (
      <>
        <ListItem disableGutters sx={deepmerge(root, sx)} ref={ref} {...props}>
          <ListItemAvatar sx={listItemAvatar}>
            <Skeleton sx={avatar} variant="rectangular" />
          </ListItemAvatar>
          <Box sx={listItemContent}>
            <Skeleton sx={listItemTextPrimary} variant="text" width="60%" />
            <Skeleton sx={listItemTextSecondary} variant="text" width="30%" />
          </Box>
        </ListItem>
        {divider && <Skeleton sx={bottomDivider} variant="rectangular" />}
      </>
    )
  }
)
