import React, { useMemo, useCallback } from "react"
import { useParams } from "react-router-dom"

import { useTranslation } from "providers/i18n"
import { useAppUI } from "providers/ui"

import { getDurationDate } from "utils/date"

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  List,
  Typography,
  useTheme,
  Divider,
  Stack,
  Button,
} from "@mui/material"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"

import { usePromotionDetail } from "hooks/promotion/usePromotionDetail"

import { AppNavigation } from "containers/kiosk-v"

import { LocationIcon } from "components/icons"
import { LanguageButton } from "components/Language"
import { SearchToggleButton } from "components/domains/search"
import { Head } from "components/Head"
import { InfoListItem } from "components/ListItem"
import { LocationInfo } from "components/domains/features/LocationInfo"
import { QRCode } from "components/QRCode"
import { getIcon } from "utils/icon"

export const PromotionDetailKioskVPage = ({ Layout = Box }) => {
  const params = useParams()
  const { promotionId } = params
  const { promotion, promotionDetailMobileUrl, navigateToLocation } =
    usePromotionDetail(promotionId)
  const { goBack, openSearch } = useAppUI()

  const theme = useTheme()

  const calendarIcon = useMemo(
    () => getIcon(theme?.assets?.calendarIcon) || <CalendarTodayIcon />,
    [theme]
  )

  const styleOverrides = theme.components[`VuiDetailCard`]?.styleOverrides || {}
  const {
    card,
    image,
    infoListItem,
    cardContent,
    cardHeader,
    cardContentPrimaryText,
    cardContentSecondaryText,
    divider,
    infoContainer,
    qrCode,
    qrCodeContainer,
    qrcodePrimaryText,
    qrCodeSecondaryText,
    buttonContainer,
    button,
    navigationWrapper,
    actionButton,
    actionContainer,
    searchBarContainer,
    toolbarContainer,
  } = styleOverrides

  const { t } = useTranslation()

  const handleClickBack = useCallback(() => {
    goBack()
  }, [goBack])
  if (!promotion) return null

  return (
    <>
      <Head title={`${t(promotion.name)} (${t("Promotion Detail")})`} />
      <Layout>
        {!promotion ? null : (
          <Card elevation={0} sx={card}>
            <CardMedia component="img" sx={image} src={promotion.coverImage} />
            <CardHeader title={t(promotion.name)} sx={cardHeader} />
            <Divider sx={divider} />
            <Stack direction="row">
              <List sx={infoContainer}>
                <InfoListItem
                  sx={infoListItem}
                  icon={calendarIcon}
                  primary={getDurationDate(
                    promotion.startDate,
                    promotion.endDate
                  )}
                  secondary={promotion.openingText}
                />
                {promotion.hasLocation && (
                  <InfoListItem
                    sx={infoListItem}
                    icon={<LocationIcon />}
                    primary={
                      <LocationInfo
                        feature={promotion.feature}
                        component="span"
                      />
                    }
                    secondary={t(promotion.boothName)}
                  />
                )}
              </List>
              <Box sx={qrCodeContainer}>
                <QRCode sx={qrCode} url={promotionDetailMobileUrl} />
                <Stack>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    sx={qrcodePrimaryText}
                  >
                    {t("Scan Here")}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="p"
                    sx={qrCodeSecondaryText}
                  >
                    {t("For Mobile")}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
            <Divider sx={divider} />
            <CardContent sx={cardContent}>
              <Typography
                variant="body1"
                gutterBottom
                sx={cardContentPrimaryText}
              >
                {t("Promotion Detail")}
              </Typography>
              <Typography
                variant="body2"
                sx={cardContentSecondaryText}
                dangerouslySetInnerHTML={{
                  __html: promotion.descriptionText,
                }}
              ></Typography>

              <Box sx={buttonContainer}>
                <Button
                  variant="outlined"
                  sx={button}
                  onClick={handleClickBack}
                >
                  {t("Back")}
                </Button>
                {promotion.hasLocation && (
                  <Button
                    variant="contained"
                    sx={button}
                    onClick={navigateToLocation}
                  >
                    {t("View Location")}
                  </Button>
                )}
              </Box>
            </CardContent>
          </Card>
        )}
        <Box key="app-navigation" sx={navigationWrapper}>
          <Box sx={toolbarContainer}>
            <Box sx={actionContainer}>
              <LanguageButton variant="contained" sx={actionButton} />
              {/* //TODO:: Add a wheel chair's mode handler function later */}
              {/*  <Button
            variant="contained"
            sx={actionButton}
            onClick={() => {}}
            startIcon={<RestroomWheelchairIcon />}
          /> */}
            </Box>
            <Box sx={searchBarContainer}>
              <SearchToggleButton
                placeholder={t("Search shops and more...")}
                onClick={openSearch}
              />
            </Box>
          </Box>
          <AppNavigation />
        </Box>
      </Layout>
    </>
  )
}
