import { useQuery } from "@tanstack/react-query"
import { useGetImdf } from "./useGetImdf"

export const useGetOccupant = (id) => {
  const { data: imdfData, isFetched } = useGetImdf()
  return useQuery({
    queryKey: ["imdf", "occupants", id],
    queryFn: async () => {
      const occupant = imdfData.occupants.find((occupant) => occupant.id === id)
      return Promise.resolve(occupant)
    },
    staleTime: 0,
    enabled: isFetched,
  })
}
