import React, { useMemo } from "react"
import { Box, Grid, useTheme } from "@mui/material"
import { Link } from "react-router-dom"

import { getIcon } from "utils/icon"
import { useNewShops } from "hooks/new-shop/useNewShops"
import { useTranslation } from "providers/i18n"

import { EmptyData } from "components/EmptyData"
import { OccupantCard } from "components/domains/occupants"
import { Head } from "components/Head"
import { StoreIcon } from "components/icons"

const GridItem = ({ occupant, tag, tagBackground, tagColor }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Link to={`/maps/place/${occupant.id}`}>
      <OccupantCard
        occupant={occupant}
        tag={tag}
        tagBackground={tagBackground}
        tagColor={tagColor}
      />
    </Link>
  </Grid>
)

export const NewShopsKioskVPage = ({ Layout = Box }) => {
  const { t } = useTranslation()
  const { newOccupants, comingSoonOccupants } = useNewShops()

  const theme = useTheme()
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.newShops) || <StoreIcon />,
    [theme]
  )
  const { styleOverrides } = theme.components?.[`NewShopsPage`] || {}
  const { emptyDataContainer } = styleOverrides
  return (
    <>
      <Head title={t("New Shops")} />
      <Layout>
        <Box>
          {newOccupants.length > 0 || comingSoonOccupants.length > 0 ? (
            <>
              <Grid container spacing={2} sx={{ mt: 0 }}>
                {newOccupants.map((newOccupant) => (
                  <GridItem
                    occupant={newOccupant}
                    tag="New Shop"
                    tagBackground={theme.palette.grey[100]}
                    tagColor={theme.palette.common.black}
                    key={`new-shop-${newOccupant.id}`}
                  />
                ))}
              </Grid>
              <Grid container spacing={2} sx={{ mt: 0 }}>
                {comingSoonOccupants.map((comingSoonOccupant) => (
                  <GridItem
                    occupant={comingSoonOccupant}
                    tag="Coming Soon"
                    tagBackground={theme.palette.grey[100]}
                    tagColor={theme.palette.grey[600]}
                    key={`coming-soon-${comingSoonOccupant.id}`}
                  />
                ))}
              </Grid>
            </>
          ) : (
            <Box sx={emptyDataContainer}>
              <EmptyData
                icon={emptyIcon}
                primary={t("Coming soon")}
                secondary={t("Stay tuned for the update")}
              />
            </Box>
          )}
        </Box>
      </Layout>
    </>
  )
}
