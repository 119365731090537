import { Box, Button, useTheme } from "@mui/material"
import Backdrop from "@mui/material/Backdrop"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "providers/i18n"
import { useCallback, useMemo } from "react"
import { TabIcon } from "../../icons"
import { getIcon } from "utils/icon"

export const FullScreenStart = ({
  path = "/maps",
  label = "Start Explore",
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const styleOverrides =
    theme.components[`FullScreenStart`]?.styleOverrides || {}
  const { root, actionButton, actionButtonWrapper } = styleOverrides
  const navigate = useNavigate()

  const handleClose = useCallback(() => {
    navigate(path)
  }, [navigate, path])

  const startButtonIcon = useMemo(
    () => getIcon(theme?.assets?.startIcon) || <TabIcon />,
    [theme]
  )

  return (
    <Backdrop sx={root} open={true} onClick={handleClose}>
      <Box sx={actionButtonWrapper}>
        <Button
          to={path}
          variant="contained"
          sx={actionButton}
          startIcon={startButtonIcon}
        >
          {t(label)}
        </Button>
      </Box>
    </Backdrop>
  )
}
