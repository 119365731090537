import React from "react"
import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction,
  Box,
  Slide,
  useTheme,
} from "@mui/material"

import { useTranslation } from "providers/i18n"
import { useAppUI } from "providers/ui"
import { NavigationLink } from "components/navigation/NavigationLink"

import { Menus } from "./Menus"

const MenuTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

export const Navigation = ({
  value,
  bottomNavigations = [],
  navigations = [],
  show = true,
}) => {
  const { sidebarState, setSidebarState } = useAppUI()

  const { t } = useTranslation()
  const theme = useTheme()
  const styleOverrides = theme.components[`AppNavigation`]?.styleOverrides || {}
  const { root, menuButton, navigationContainer } = styleOverrides

  return (
    <>
      <Slide direction="up" appear={false} mountOnEnter unmountOnExit in={show}>
        <Box sx={root}>
          <MuiBottomNavigation
            value={value}
            showLabels
            sx={navigationContainer}
          >
            {bottomNavigations.map(({ to, label, icon, disabled = false }) => (
              <BottomNavigationAction
                sx={menuButton}
                component={NavigationLink}
                key={to}
                label={t([`navigation.${label}`, label])}
                to={to}
                value={to}
                icon={icon}
                disabled={disabled}
                backSideProps={{
                  children: icon,
                }}
              />
            ))}
          </MuiBottomNavigation>
        </Box>
      </Slide>
      {/* //TODO:: move sidebar to new place? */}
      <Menus
        open={sidebarState}
        dialogProps={{
          TransitionComponent: MenuTransition,
        }}
        onClose={() => setSidebarState(false)}
        anchor="left"
        value={value}
        navigations={navigations}
      />
    </>
  )
}
