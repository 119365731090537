import { forwardRef } from "react"
import { Box, Card, CardProps, Skeleton, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"

export const DiningSkeletonCard = forwardRef<HTMLDivElement, CardProps>(
  ({ sx = {}, ...props }, ref) => {
    const theme = useTheme()
    const { styleOverrides = {} } =
      theme.components?.[`DiningSkeletonCard`] || {}
    const {
      root,
      avatar,
      cardContent,
      headerContainer,
      infoContainer,
      primaryText,
      venueLogo,
      logo,
      secondaryText,
    } = styleOverrides
    return (
      <Card sx={deepmerge(root, sx)} ref={ref} {...props}>
        <Skeleton sx={avatar} variant="rectangular" />
        <Box sx={cardContent}>
          <Box sx={headerContainer}>
            <Skeleton sx={logo} variant="rectangular" />
            <Skeleton sx={primaryText} variant="text" width="90%" />
          </Box>
          <Box sx={infoContainer}>
            <Skeleton sx={secondaryText} variant="text" width="20%" />
            <Skeleton sx={venueLogo} variant="rectangular" />
          </Box>
        </Box>
      </Card>
    )
  }
)
