import { BaseObject, ThreeLayer } from "maptalks.three"
import { Sprite, SpriteMaterial } from "three"
import _ from "lodash"

const DEFAULT_SCALE = 0.05
const DEFAULT_ALTITUDE = 0
const DEFAULT_OPTIONS = {
  scale: DEFAULT_SCALE,
  altitude: DEFAULT_ALTITUDE,
  highlight: {
    options: {
      scale: DEFAULT_SCALE * 1.75,
    },
    material: null,
  },
}

interface IOptions {
  scale: number
  altitude: number
  highlight?: {
    options: {
      scale?: number
    }
    material?: SpriteMaterial
  }
}
export class SpriteMarker extends BaseObject {
  #default = null
  #highlight = null
  constructor(
    coordinate,
    options: IOptions,
    material: SpriteMaterial,
    layer: ThreeLayer,
    properties: object
  ) {
    super()
    //Initialize internal configuration
    this._initOptions(options)
    this._createGroup()
    const {
      altitude = DEFAULT_OPTIONS.altitude,
      scale = DEFAULT_OPTIONS.scale,
      highlight = DEFAULT_OPTIONS.highlight,
    } = options
    this.properties = { ...properties }
    this.#default = { options: { scale }, material }
    this.#highlight = _.merge({}, DEFAULT_OPTIONS.highlight, highlight)
    const sprite = new Sprite(material)
    sprite.scale.set(scale, scale, scale) // Set sprite scale

    const obj3d = this.getObject3d()
    obj3d.add(sprite)
    // set object3d position
    const modifiedAltitude = altitude + 2
    const z = layer.altitudeToVector3(modifiedAltitude, modifiedAltitude).x
    const position = layer.coordinateToVector3(coordinate, z)
    _.set(this.properties, "default.position", position)
    this.getObject3d().position.copy(position)
  }

  // Different objects need to implement their own methods
  setSymbol(material: SpriteMaterial) {
    if (material && material instanceof SpriteMaterial) {
      const sprite = (this.getObject3d() as any).children[0]

      if (!sprite) return this

      sprite.material = material
      sprite.material.needsUpdate = true
    }
    return this
  }

  setScale(scaleX: number, scaleY: number = scaleX, scaleZ: number = scaleX) {
    const sprite = (this.getObject3d() as any).children[0]
    if (!sprite) return this

    sprite.scale.set(scaleX, scaleY, scaleZ)

    return this
  }

  // Different objects need to implement their own methods
  getSymbol(): SpriteMaterial {
    return (this.getObject3d() as any)?.children[0]?.material
  }

  highlight() {
    const { material, options } = this.#highlight
    if (material) this.setSymbol(material)
    if (options.scale) this.setScale(options.scale)
    return this
  }

  removeHighlight() {
    const { material, options } = this.#default
    if (material) this.setSymbol(material)
    if (options.scale) this.setScale(options.scale)
    return this
  }
}
