import { useMemo } from "react"
import _ from "lodash"

import { IRecord } from "interfaces"

import { useSponsoredContent } from "./useSponsoredContent"

interface ISponsorContentConfig {
  disable?: boolean
  limit?: number
  frequency?: number
}

interface IUseSponsoredSuggestedBrandResult {
  suggestedBrands: IRecord[]
  suggestedBrandConfig: ISponsorContentConfig
}
export const useSponsoredSuggestedBrand =
  (): IUseSponsoredSuggestedBrandResult => {
    const { suggestedBrand } = useSponsoredContent()

    const suggestedBrandConfig = useMemo(
      () => _.get(suggestedBrand, "config"),
      [suggestedBrand]
    )

    const suggestedBrands = useMemo(
      () => _.get(suggestedBrand, "data", []),
      [suggestedBrand]
    )

    return {
      suggestedBrands,
      suggestedBrandConfig,
    }
  }
