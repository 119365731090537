import React from "react"
import { Box, Container, useTheme } from "@mui/material"

import { AppNavigation } from "containers/AppNavigation"

import { Header, HeaderLogo, HeaderTitle } from "components/Header"
import { Clock } from "components/Clock"
import { BackIconButton } from "components/Button"
import { useAppUI } from "providers/ui"

export const PageLayout = ({
  children,
  header = null,
  footer = null,
  ...props
}) => {
  const { goBack } = useAppUI()

  const theme = useTheme()
  const styleOverrides = theme.components[`AppPageLayout`]?.styleOverrides || {}
  const { root, container, headerBackIconContainer, navigationContainer } =
    styleOverrides

  return (
    <Box sx={root} {...props} data-name="PageLayout">
      <Header
        logo={<HeaderLogo />}
        title={<HeaderTitle />}
        clock={<Clock />}
        backButton={
          <Box sx={headerBackIconContainer}>
            <BackIconButton onClick={goBack} />
          </Box>
        }
      />
      <Container maxWidth="md" sx={container}>
        {children}
      </Container>
      <Box sx={navigationContainer}>
        <AppNavigation />
      </Box>
    </Box>
  )
}
