import { Box, Typography, useTheme } from "@mui/material"

export const DirectionSummary = ({ primary, secondary }) => {
  const theme = useTheme()
  const { primaryText, secondaryText, directionSummaryContainer } =
    theme.components[`AppDirectionOverview`]?.styleOverrides || {}
  return (
    <Box sx={directionSummaryContainer}>
      <Typography variant="title" component="h1" sx={primaryText} gutterBottom>
        {primary}
      </Typography>
      <Typography color="text.secondary" variant="body2" sx={secondaryText}>
        {secondary}
      </Typography>
    </Box>
  )
}
