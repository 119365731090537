import center from "@turf/center"
import { Geometry } from "@turf/helpers"
import _ from "lodash"

import {
  isValidLineStringCoordinates,
  isValidMultiPolygonCoordinates,
  isValidPolygonCoordinates,
  isValidCoordinate,
} from "./coordinate"

// Calculates the center point of a GeoJSON geometry and Return as a Coordinates.
export const getCenterFromGeometry = (geometry?: Geometry): number[] | null => {
  try {
    const { type = null, coordinates = null } = geometry
    if (!type || !coordinates) return null
    const centerPoint = center(geometry)
    return _.get(centerPoint, "geometry.coordinates") // Coordinates
  } catch (error) {
    return null
  }
}

export const isValidMultiPolygon = (geometry): boolean => {
  const { type, coordinates } = geometry
  return type === "MultiPolygon" && isValidMultiPolygonCoordinates(coordinates)
}

export const isValidPolygon = (geometry): boolean => {
  const { type, coordinates } = geometry
  return type === "Polygon" && isValidPolygonCoordinates(coordinates)
}

export const isValidLineString = (geometry): boolean => {
  const { type, coordinates } = geometry
  return type === "LineString" && isValidLineStringCoordinates(coordinates)
}

export const isValidPoint = (geometry): boolean => {
  const { type, coordinates } = geometry
  return type === "Point" && isValidCoordinate(coordinates)
}
