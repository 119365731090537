import { Fragment, useCallback, useMemo } from "react"
import { Box, useTheme } from "@mui/material"

import { getIcon } from "utils/icon"

import { SKELETON_COUNT, SKELETON_INDEX_TO_REF } from "constant"
import { useTranslation } from "providers/i18n"
import { useVenue } from "providers/venue"
import { useDining } from "hooks/dining/useDining"

import {
  CategoryFilterBar,
  CategoryFilterChip,
} from "components/CategoryFilterBar"
import { Head } from "components/Head"
import { HorizontalScrollPane } from "components/HorizontalScrollPane"
import { CustomIcon, DiningIcon } from "components/icons"
import { EmptyData } from "components/EmptyData"
import { VenueSelector } from "components/VenueSelector"
import { MotionDiv, showContent } from "components/motions"

import {
  Header,
  HeaderMenuButton,
  HeaderSearchButton,
} from "extensions/one-siam/components/Header"
import {
  DiningCard,
  DiningSkeletonCard,
} from "extensions/one-siam/components/domains/dining"
import { PageLayout } from "extensions/one-siam/layouts/PageLayout"
import { AppNavigation } from "extensions/one-siam/container/AppNavigation"

const DEFAULT_FILTER_ICON = <DiningIcon />

export const DiningPage = ({ Layout = PageLayout }) => {
  const { occupants } = useVenue()
  const {
    restaurants,
    currentCategory,
    categories,
    locationVenueId,
    venues,
    venueFilter,
    hasData,
    hasNextPage,
    endScrollRef,
    filterByCategoryId,
    filterByVenueId,
  } = useDining(occupants)

  const { t } = useTranslation({ defaultValue: null })

  const filterItems = useMemo(
    () => [
      { value: "ALL", label: t("All"), icon: DEFAULT_FILTER_ICON },
      ...categories.map((category) => ({
        value: category.id,
        label: category.properties.name,
        icon: category.properties?.icon_path ? (
          <CustomIcon path={category.properties?.icon_path} />
        ) : (
          DEFAULT_FILTER_ICON
        ),
      })),
    ],
    [categories, t]
  )

  const handleChangeCategory = useCallback(
    (e, id) => {
      if (id === null) return
      filterByCategoryId(id)
    },
    [filterByCategoryId]
  )

  const theme = useTheme()

  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.dining) || <DiningIcon />,
    [theme]
  )

  const showFilterBar = filterItems.length > 1

  const {
    root,
    filterSection,
    filterContainer,
    filterButton,
    gridItem,
    gridContainer,
    emptyDataContainer,
    headerWrapper,
    navigationWrapper,
  } = theme.components?.[`AppDining`]?.styleOverrides || {}

  return (
    <>
      <Head title={t("Dining")} />
      <Layout
        header={
          <Box sx={headerWrapper}>
            <Header
              title={t("Dining")}
              menu={<HeaderMenuButton />}
              search={<HeaderSearchButton />}
            />
          </Box>
        }
      >
        <MotionDiv
          variants={showContent}
          initial="initial"
          animate="animate"
          sx={root}
        >
          {showFilterBar && (
            <MotionDiv
              variants={showContent}
              initial="initial"
              animate="animate"
              sx={filterSection}
            >
              <HorizontalScrollPane
                className="subCategory-filterbar"
                stackProps={{
                  sx: filterContainer,
                }}
              >
                <CategoryFilterBar
                  sx={filterButton}
                  activeCategory={currentCategory}
                  categories={categories}
                  onChange={handleChangeCategory}
                  renderFilterButton={CategoryFilterChip}
                />
              </HorizontalScrollPane>
            </MotionDiv>
          )}
          {hasData ? (
            <Box sx={gridContainer}>
              {restaurants.pages.map((page, pageNum) => {
                return (
                  <Fragment key={pageNum}>
                    {page.data.map((restaurant, index) => (
                      <Box sx={gridItem} key={`dining-${index}`}>
                        <DiningCard data={restaurant} />
                      </Box>
                    ))}
                  </Fragment>
                )
              })}
              {hasNextPage &&
                Array.from({ length: SKELETON_COUNT }).map((_, index) => (
                  <DiningSkeletonCard
                    ref={index === SKELETON_INDEX_TO_REF ? endScrollRef : null}
                    key={index}
                    sx={gridItem}
                  />
                ))}
            </Box>
          ) : (
            <Box sx={emptyDataContainer}>
              <EmptyData
                icon={emptyIcon}
                primary={t("Coming soon")}
                secondary={t("Stay tuned for the update")}
              />
            </Box>
          )}
        </MotionDiv>
        <Box sx={navigationWrapper}>
          <VenueSelector
            venues={venues}
            onClickVenue={filterByVenueId}
            currentVenue={locationVenueId}
            selectedVenue={venueFilter}
          />
          <AppNavigation />
        </Box>
      </Layout>
    </>
  )
}
