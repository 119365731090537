import * as THREE from "three"
import _ from "lodash"
import TWEEN from "@tweenjs/tween.js"

import { BaseObject } from "maptalks.three"
import { Billboard } from "../object3d"

export const createHighlighBillboardController = (obj: Billboard) => {
  const controller = { start: () => {}, clear: () => {} }
  if (!(obj instanceof Billboard)) return controller

  const altitude = obj.properties.default.altitude
  const newAltitude = _.clamp(altitude * 0.05, 0, altitude)
  const tween = new TWEEN.Tween({ altitude })
    .to({ altitude: newAltitude }, 800)
    .easing(TWEEN.Easing.Quartic.Out)
    .onUpdate((newUpdate) => {
      obj.setLineHeight(newUpdate.altitude)
    })

  controller.start = () => {
    tween.start()
  }

  controller.clear = () => {
    tween.stop().to({ altitude }, 1600).startFromCurrentValues()
  }

  return controller
}

export const createHighlighExtrudeObjectController = (
  obj: BaseObject,
  { color }
) => {
  const controller = { start: () => {}, clear: () => {} }
  if (
    obj?.type !== "ExtrudePolygon" ||
    _.isNil(obj?.object3d?.material?.color) ||
    _.isNil(color)
  )
    return controller

  controller.start = () => {
    obj.object3d.material.color = new THREE.Color(color)
  }

  controller.clear = () => {
    const objectDefaultColor = _.get(obj, "properties.defaultColor")
    obj.object3d.material.color = new THREE.Color(objectDefaultColor)
  }

  return controller
}
