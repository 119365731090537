import { useMemo } from "react"
import _ from "lodash"

import { IRecord } from "interfaces"

import { useSponsoredContent } from "./useSponsoredContent"

interface ISponsorContentConfig {
  disable?: boolean
  limit?: number
  frequency?: number
}

interface IUseSponsoredSearchResult {
  searchResults: IRecord[]
  searchResultConfig: ISponsorContentConfig
}
export const useSponsoredSearchResult = (): IUseSponsoredSearchResult => {
  const { searchResult } = useSponsoredContent()

  const searchResultConfig = useMemo(
    () => _.get(searchResult, "config"),
    [searchResult]
  )

  const searchResults = useMemo(
    () => _.get(searchResult, "data", []),
    [searchResult]
  )

  return {
    searchResults,
    searchResultConfig,
  }
}
