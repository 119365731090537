import React, { useMemo } from "react"
import {
  Avatar,
  Box,
  Card,
  CardProps,
  Typography,
  useTheme,
} from "@mui/material"
import { deepmerge } from "@mui/utils"

import { useTranslation } from "providers/i18n"

import { CustomIcon } from "components/icons"
import { ShopIcon } from "extensions/one-siam/components/icons"
import { IOccupantDetail } from "utils/feature"

export interface IAdSuggestCardProps extends CardProps {
  data: IOccupantDetail
}

export const AdSuggestCard: React.FC<IAdSuggestCardProps> = ({
  data,
  sx,
  ...props
}) => {
  const { t } = useTranslation()

  const { name, logo, categoryIconPath } = useMemo(
    () => (data || {}) as IOccupantDetail,
    [data]
  )

  const theme = useTheme()
  const { styleOverrides = {} } = theme.components?.[`AdSuggestCard`] || {}
  const { root, occupantLogo, cardContent, titleText, titleContainer } =
    styleOverrides

  return (
    <Card sx={deepmerge(root, sx)} {...props}>
      <Box sx={occupantLogo}>
        <Avatar src={logo}>
          {!logo &&
            (categoryIconPath ? (
              <CustomIcon path={categoryIconPath} />
            ) : (
              <ShopIcon />
            ))}
        </Avatar>
      </Box>
      <Box sx={cardContent}>
        <Box sx={titleContainer}>
          <Typography sx={titleText}>{t(name)}</Typography>
        </Box>
      </Box>
    </Card>
  )
}
