import { useEffect, useCallback, useMemo } from "react"
import { Id, featureCollection } from "@turf/helpers"

import { useVenue } from "providers/venue"
import { AmenityData } from "providers/venue/types"
import { useIndoorMap } from "providers/venue/modules/indoormap/hooks/useIndoorMap"
import {
  getLocationIdByFeature,
  getSuitablyValueBetweenBearings,
} from "components/IndoorMap"
import { IRoute } from "interfaces"

const DEFAULT_OPTIONS = {
  offset: { top: 0, left: 0, right: 0, bottom: 0 },
  enabled: true,
}

type Options = {
  enabled?: boolean
  offset?: {
    bottom: number
    left: number
    right: number
    top: number
  }
}

type UseStepByStepType = (
  route: IRoute | null,
  stepNumber: number,
  options: Options
) => void

export const useStepByStepMap: UseStepByStepType = (
  route,
  stepNumber,
  options = DEFAULT_OPTIONS
) => {
  const { indoorRef, mapLoaded } = useIndoorMap()
  const {
    amenities,
    dataLoaded,
    transformStepsToGeometries,
    relationshipGraphLoaded,
    transformLocationToFeature,
  }: {
    amenities: AmenityData[]
    dataLoaded: boolean
    transformStepsToGeometries: Function
    relationshipGraphLoaded: boolean
    transformLocationToFeature: Function
  } = useVenue()

  const { offset, enabled } = options
  const { bottom, top, left, right } = offset
  const offsetOption = useMemo(
    () => ({ bottom, top, left, right }),
    [bottom, top, left, right]
  )

  const getAmenitiesFromUnitIds = useCallback(
    (unitIds: Id[]): AmenityData[] => {
      return amenities.filter((amenity) =>
        amenity.properties?.unit_ids.some((id) => unitIds.includes(id))
      )
    },
    [amenities]
  )

  const mapReady =
    indoorRef?.current && mapLoaded && dataLoaded && relationshipGraphLoaded

  const navigationGeometries = useMemo(
    () => (route?.steps ? transformStepsToGeometries(route?.steps) : []),
    [route?.steps, transformStepsToGeometries]
  )

  const targetDestination = useMemo(
    () => transformLocationToFeature(route?.destination.id),
    [route?.destination.id, transformLocationToFeature]
  )

  useEffect(() => {
    const indoormap = indoorRef?.current
    if (indoormap && mapLoaded) {
      indoormap.hideVenueObjects()
      indoormap.disableClick()
      indoormap.hideUserLocationMarker()
      indoormap.hideLastUserLocationMarker()
      //set opacity of extrudedUnits to 0.6
      indoormap.setFeatureObject3DsOpacity(0.6)
      return () => {
        indoormap.setFeatureObject3DsOpacity(1)
      }
    }
  }, [indoorRef, mapLoaded])

  useEffect(() => {
    const indoorMap = indoorRef?.current
    if (indoorMap && mapReady && enabled) {
      indoorMap.clearHighlightElements()
      indoorMap.clearHighlightObject()
    }
  }, [indoorRef, mapReady, enabled])

  useEffect(() => {
    const indoorMap = indoorRef.current
    if (indoorMap && mapReady && enabled) {
      // handle the step page
      const targetStepGeoJSON = navigationGeometries.find(
        ({ id }) => id === `step-${stepNumber}`
      )
      indoorMap.clearHighlightElements()
      indoorMap.clearHighlightObject()

      indoorMap.clearNavigationGeometries()
      indoorMap.createNavigationGeometries(
        navigationGeometries,
        route.destination
      )
      if (!targetStepGeoJSON) return

      const { properties, isVerticalStep } = targetStepGeoJSON
      const { ordinal: currentStepOrdinal, intermediaryUnits } = properties
      //only show geometry, path and maker in current ordinal
      indoorRef.current.changeLevelByOrdinal(currentStepOrdinal)

      // Highlight the destination unit if the current floor step has a destination unit.
      const hasLocatedDestination =
        currentStepOrdinal === targetDestination.properties?.ordinal
      const destElementId =
        hasLocatedDestination && getLocationIdByFeature(targetDestination)
      if (hasLocatedDestination && destElementId) {
        indoorRef.current.setHighlightedObject([destElementId])
      }

      if (isVerticalStep) {
        //verticalStep's intermediaries are elevators/escalators/stairs
        const amenitiesToHighlight = getAmenitiesFromUnitIds(intermediaryUnits)
          .filter(
            (amenity) => amenity.properties.ordinal === currentStepOrdinal
          )
          .map((amenity) => amenity.id)
        //highlight elevators/escalators/stairs markers
        indoorRef.current.setHighlightedObject(amenitiesToHighlight)
      }

      // Handle Zoom And Focusing
      const navigationCollection = featureCollection([targetStepGeoJSON])
      const extent = indoorRef.current.getFeatureExtent(navigationCollection)
      const center = indoorRef.current.getExtentCenter(extent)
      const zoom = indoorRef.current.getExtentZoom(extent)
      const pitch = 60
      const currentBearing = indoorRef.current.getBearing()
      const pathBearing =
        indoorRef.current.getLineStringBearing(targetStepGeoJSON)

      const bearing = getSuitablyValueBetweenBearings(
        pathBearing,
        currentBearing
      )

      const newCenter = indoorMap.getTargetViewCenter(
        {
          center,
          zoom,
          pitch,
          bearing,
        },
        { offset: offsetOption }
      )
      indoorRef.current.flyTo(newCenter, {
        //If this is vertical step or last step will animate fly to and zooming in on the geometry.
        zoom,
        bearing,
        pitch,
        duration: 600,
        easing: "out",
      })

      // hide origin and destination logo / text marker
      indoorRef.current.hideGeometryByElementId(route.origin.id)
      indoorRef.current.hideGeometryByElementId(route.destination.id)
    }
  }, [
    enabled,
    indoorRef,
    mapReady,
    navigationGeometries,
    offsetOption,
    targetDestination,
    route?.destination,
    route?.destination.id,
    route?.origin.id,
    stepNumber,
    getAmenitiesFromUnitIds,
  ])
}
