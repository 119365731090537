import { useTranslation } from "providers/i18n"
import {
  Typography,
  CardMedia,
  CardContent,
  Box,
  Stack,
  useTheme,
  Card,
} from "@mui/material"
import { CalendarIcon, LocationIcon } from "components/icons"
import { getFeatureName } from "utils/feature"
import dayjs from "config/dayjs"
import { getDisplayDateTime } from "../../../utils/date"
import { LocationInfo } from "../features/LocationInfo"
import { getFeatureDetail } from "../../../utils/feature"

export const HighlightFeatureCard = (props) => {
  const { feature } = props
  const { t } = useTranslation()
  const { properties } = feature
  const startDate = dayjs(properties?.start_date)
  const endDate = dayjs(properties?.end_date)

  const theme = useTheme()
  const { styleOverrides } = theme.components?.[`HighlightFeatureCard`] || {}
  const {
    root,
    cardMedia,
    cardContent,
    contentWrapper,
    cardTitle,
    eventInfoItem,
    iconWrapper,
  } = styleOverrides
  const { location } = getFeatureDetail(feature)

  return (
    <Card sx={root}>
      <CardMedia
        sx={cardMedia}
        component="img"
        image={properties.cover_image?.url}
      />
      <CardContent sx={cardContent}>
        <Stack spacing={0} sx={contentWrapper}>
          <Typography sx={cardTitle} variant="title" component="div">
            {t(getFeatureName(feature))}
          </Typography>
          <Stack>
            <Box sx={eventInfoItem}>
              <Box sx={iconWrapper}>
                <CalendarIcon />
              </Box>
              <Typography variant="subtitle1" component="p">
                {getDisplayDateTime(startDate, endDate)}
              </Typography>
            </Box>
            <Box
              sx={{
                ...eventInfoItem,
                visibility: location ? "visible" : "hidden",
              }}
            >
              <Box sx={iconWrapper}>
                <LocationIcon />
              </Box>
              <LocationInfo
                feature={feature}
                variant="subtitle1"
                component="p"
              />
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
