import React from "react"
import { LinearProgress, Box, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"

export const BorderLinearProgress = ({ sx, value, ...props }) => {
  const theme = useTheme()
  const { root, linearProgress } =
    theme.components["BorderLinearProgress"]?.styleOverrides || {}
  return (
    <Box sx={deepmerge(root, sx)}>
      <LinearProgress
        {...props}
        value={value}
        className={`${
          value > 0 ? (value >= 100 ? "Mui-complete" : "Mui-start") : ""
        }`}
        sx={linearProgress}
      />
    </Box>
  )
}
