import { ContentWithAside } from "./ContentWithAside"
import { useTheme } from "@mui/material"

export const MapPageLayout = (props) => {
  const theme = useTheme()
  const styleOverrides =
    theme.components[`AppMapPageLayout`]?.styleOverrides || {}
  const { root } = styleOverrides
  return <ContentWithAside sx={root} {...props} />
}
