import { PropsWithChildren, FC } from "react"
import {
  useTheme,
  BottomNavigationAction,
  Box,
  ButtonProps,
  BottomNavigationActionProps,
} from "@mui/material"
import { Link } from "react-router-dom"
import { useTranslation } from "providers/i18n"
import { SearchIcon, BackIcon } from "components/icons"
import { LanguageButton } from "components/Language"
import { deepmerge } from "@mui/utils"
import { useAppUI } from "providers/ui"

interface ControlBottomNavigationActionProps
  extends BottomNavigationActionProps {
  component?: FC
  hidden?: boolean
}
interface ControlButtonProps extends ButtonProps {
  hidden?: boolean
}

interface NavigationControlProps extends PropsWithChildren {
  BackButtonProps?: ControlBottomNavigationActionProps
  SearchButtonProps?: ControlBottomNavigationActionProps
  HomeButtonProps?: ControlBottomNavigationActionProps
  LanguageButtonProps?: ControlButtonProps
}

export const NavigationControl: FC<NavigationControlProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { toggleSearch, kioskMainNavigations = [], goBack } = useAppUI()
  const {
    BackButtonProps: { hidden: hideBack, ...BackButtonProps } = {
      hidden: false,
    },
    SearchButtonProps: { hidden: hideSearch, ...SearchButtonProps } = {
      hidden: false,
    },
    LanguageButtonProps: { hidden: hideLanguage, ...LanguageButtonProps } = {
      hidden: false,
    },
  } = props

  const { styleOverrides = {} } = theme.components[`VuiNavigationControl`] || {}
  const {
    wrapper = {},
    navigationAction = {},
    languageButton = {},
  } = styleOverrides

  return (
    <Box sx={wrapper}>
      {!hideBack && (
        <BottomNavigationAction
          sx={navigationAction}
          label={t("Back")}
          icon={<BackIcon />}
          showLabel
          {...BackButtonProps}
          onClick={goBack}
        />
      )}
      {kioskMainNavigations.map(({ icon, label, to, disabled = false }) => {
        return (
          <BottomNavigationAction
            key={`navigation-control-main-nav-${to}`}
            sx={navigationAction}
            icon={icon}
            label={t([label])}
            showLabel
            to={to}
            component={Link}
            disabled={disabled}
          />
        )
      })}
      {!hideSearch && (
        <BottomNavigationAction
          sx={navigationAction}
          label={t([`navigation.Search`, "Search"])}
          icon={<SearchIcon />}
          showLabel
          {...SearchButtonProps}
          onClick={toggleSearch}
        />
      )}
      {!hideLanguage && (
        <LanguageButton
          sx={deepmerge(navigationAction, languageButton)}
          {...LanguageButtonProps}
        />
      )}
    </Box>
  )
}
