import React, { useMemo } from "react"
import { Box, styled, useTheme } from "@mui/material"
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk"
import { useTranslation } from "providers/i18n"
import { CustomIcon, MarkerIcon } from "components/icons"
import { MotionDiv } from "../motions"
import { getIconPathString } from "components/icons/pathString"

const DestinationIconContainer = styled(Box)(({ theme, ownerState }) => ({
  color: theme.palette.grey[500],
  width: 28,
  height: 28,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  "& svg": {
    height: 28,
    width: 28,
  },
  ...(ownerState.active && {
    "& svg": {
      fill: theme.palette.primary.main,
    },
  }),
  ...(ownerState.completed && {
    "& svg": {
      fill: theme.palette.primary.main,
    },
  }),
}))

const IconContainer = styled(MotionDiv)(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.grey[500],
  color: "#fff",
  width: 28,
  height: 28,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 14,
  "& svg": {
    width: 16,
    height: 16,
  },
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
  }),
}))

export const StepByStepIcon = (props) => {
  const { active, completed, step, index, lastStep } = props
  const isVerticalStep =
    step.origin?.properties.ordinal !== step.destination?.properties.ordinal ||
    false
  const featureCategory = step.description?.options?.category || ""
  const theme = useTheme()
  const { t } = useTranslation()
  const animate = useMemo(() => {
    return {
      boxShadow: [
        `0 0 0 4px ${theme.palette.primary.main + "33"}`,
        `0 0 0 8px ${theme.palette.primary.main + "33"}`,
        `0 0 0 4px ${theme.palette.primary.main + "33"}`,
      ],
      transition: {
        duration: 1,
        repeat: Infinity,
        repeatType: "reverse",
      },
    }
  }, [theme])

  //if changing floor, use floor name icon
  if (isVerticalStep) {
    const floorText = step?.description?.options?.destination
    return (
      <IconContainer
        className="step"
        ownerState={{ completed, active }}
        animate={active ? animate : {}}
      >
        {t(floorText)}
      </IconContainer>
    )
  }

  //if category icon of this step exists, use category icon
  // TODO : Update this Icon with Map Theme
  if (getIconPathString(featureCategory)) {
    return (
      <IconContainer
        className="step"
        ownerState={{ completed, active }}
        animate={active ? animate : {}}
      >
        <CustomIcon path={getIconPathString(featureCategory)} />
      </IconContainer>
    )
  }

  //if last step, use destination icon
  if (index === lastStep) {
    return (
      <DestinationIconContainer
        className="destinationStep"
        ownerState={{ completed, active }}
      >
        <MarkerIcon />
      </DestinationIconContainer>
    )
  }

  // fallback icon
  return (
    <IconContainer
      className="step"
      ownerState={{ completed, active }}
      animate={active ? animate : {}}
    >
      <DirectionsWalkIcon />
    </IconContainer>
  )
}
