import { useMemo } from "react"
import { Box, ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material"
import { Link } from "react-router-dom"

import { getIcon } from "utils/icon"
import { getFeatureDetail } from "utils/feature"
import { IFeature } from "interfaces"
import { useTranslation } from "providers/i18n"
import { usePromotion } from "hooks/promotion/usePromotion"

import { Head } from "components/Head"
import { EmptyData } from "components/EmptyData"
import { PercentIcon } from "components/icons"
import { MotionDiv, showContent } from "components/motions"
import { Carousel, CarouselItem } from "components/Carousel"

import {
  Header,
  HeaderMenuButton,
  HeaderSearchButton,
} from "extensions/one-siam/components/Header"
import { PageLayout } from "extensions/one-siam/layouts/PageLayout"
import { AppNavigation } from "extensions/one-siam/container/AppNavigation"
import { CarouselImageCard } from "extensions/one-siam/components/domains/carousel"

export const Promotions = ({ Layout = PageLayout }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { handleChange, groupPromotions, activeGroupPromotion } = usePromotion()
  const {
    root,
    headerWrapper,
    navigationWrapper,
    emptyDataContainer,
    carouselContainer,
    promotionCarousel,
    promotionCarouselItem,
    promotionImage,
    toggleButton,
    promotionGroupSelectorButton,
    toggleButtonGroup,
  } = theme.components[`PromotionPage`]?.styleOverrides || {}
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.promotions) || <PercentIcon />,
    [theme]
  )

  const activePromotions = groupPromotions[activeGroupPromotion]
  const autoplay = activePromotions.length > 2

  return (
    <>
      <Head title={t("Promotions")} />
      <Layout
        header={
          <Box sx={headerWrapper}>
            <Header
              title={t("Promotions")}
              menu={<HeaderMenuButton />}
              search={<HeaderSearchButton />}
            />
          </Box>
        }
      >
        <MotionDiv
          animate="animate"
          initial="initial"
          variants={showContent}
          sx={root}
        >
          <Box sx={promotionGroupSelectorButton}>
            <ToggleButtonGroup
              value={activeGroupPromotion}
              onChange={handleChange}
              exclusive
              sx={toggleButtonGroup}
            >
              <ToggleButton value="current" sx={toggleButton}>
                {t("Current Promotions")}
              </ToggleButton>
              <ToggleButton value="upcoming" sx={toggleButton}>
                {t("Upcoming Promotions")}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {!activePromotions || activePromotions.length <= 0 ? (
            <Box sx={emptyDataContainer}>
              <EmptyData icon={emptyIcon} primary={t("No Promotion")} />
            </Box>
          ) : (
            <>
              <Box sx={carouselContainer}>
                <Carousel
                  sx={promotionCarousel}
                  options={{
                    fixedWidth: "auto",
                    fixedHeight: "auto",
                    pagination: true,
                    perPage: 1,
                    focus: "center",
                    trimSpace: false,
                    autoplay,
                    speed: 600,
                    gap: 16,
                  }}
                >
                  {activePromotions?.map((promotion) => {
                    const { id, properties } = promotion
                    const { isAd } = getFeatureDetail(promotion as IFeature)
                    const sponsoredId = properties.sponsoredId
                    return (
                      <CarouselItem
                        key={`${sponsoredId}promotion-${id}`}
                        sx={promotionCarouselItem}
                      >
                        <Link to={`/promotions/${id}?redirectTo=-1`}>
                          <CarouselImageCard
                            src={properties?.cover_image?.url}
                            alt={`${properties?.cover_image?.name}`}
                            sx={promotionImage}
                            isHighlight={isAd}
                          />
                        </Link>
                      </CarouselItem>
                    )
                  })}
                </Carousel>
              </Box>
            </>
          )}
        </MotionDiv>
        <Box sx={navigationWrapper}>
          <AppNavigation />
        </Box>
      </Layout>
    </>
  )
}
