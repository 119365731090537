import { useMemo } from "react"
import { Avatar, Box, Chip, Typography, useTheme } from "@mui/material"

import { CustomIcon } from "components/icons"
import { FeatureListItem } from "components/domains/features"
import { ShopIcon } from "../../icons"
import { useTranslation } from "providers/i18n"
import { prepareSearchResultSecondaryText } from "utils/search"

export const SearchListItem = ({
  data,
  primaryTypographyProps = {},
  secondaryTypographyProps = {},
  ...props
}) => {
  const { t } = useTranslation({ defaultValue: null })
  const {
    categoryText,
    floorText,
    zoneText,
    boothNameText,
    groupText,
    avatarProps,
    venueRef,
    isAd = false,
    name,
  } = data
  const theme = useTheme()

  const { styleOverrides = {}, defaultProps = {} } =
    theme.components[`VuiSearchResultItem`] || {}
  const { chip, primaryWrapper } = styleOverrides

  const primaryText = t(name)

  //Prepare secondary text
  const { secondaryTextKeys } = defaultProps

  const secondaryText = useMemo(
    () =>
      prepareSearchResultSecondaryText(
        categoryText,
        boothNameText,
        floorText,
        zoneText,
        groupText,
        secondaryTextKeys
      ),
    [
      categoryText,
      boothNameText,
      floorText,
      zoneText,
      groupText,
      secondaryTextKeys,
    ]
  )

  return (
    <FeatureListItem
      divider
      disableTypography
      className={venueRef}
      primary={
        <Box sx={primaryWrapper}>
          <Typography
            variant="h6"
            className="MuiListItemText-primary"
            {...primaryTypographyProps}
          >
            {primaryText}
          </Typography>
          {isAd && (
            <Chip
              variant="contained"
              label={t("sponsored.search_result")}
              sx={chip}
            />
          )}
        </Box>
      }
      secondary={
        <Typography
          variant="subtitle1"
          className="MuiListItemText-secondary"
          {...secondaryTypographyProps}
        >
          {secondaryText}
        </Typography>
      }
      avatar={
        <Avatar src={avatarProps.src} variant="logo">
          {/* if there is no avatar, will fall back to categoryIconPath > ShopIcon */}
          {avatarProps.path ? (
            <CustomIcon path={avatarProps.path} />
          ) : (
            <ShopIcon />
          )}
        </Avatar>
      }
      //TODO: add Adornment prop
      {...props}
    />
  )
}
