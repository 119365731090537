import { Link } from "react-router-dom"
import _ from "lodash"
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material"
import { deepmerge } from "@mui/utils"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded"

import { getIcon } from "utils/icon"
import { useTranslation } from "providers/i18n"
import { ServiceIcon } from "../../icons"
import { IServiceConfig } from "extensions/one-siam/hooks/useService"

export interface IServiceCardProps extends AccordionProps {
  data: IServiceConfig
  sx?: SxProps
}

export const ServiceCard: React.FC<IServiceCardProps> = ({
  data,
  sx = {},
  ...props
}) => {
  const { t } = useTranslation()
  const { locations = [], name, description, cover_image } = data
  const hasLocations = _.size(locations) > 0

  const theme = useTheme()
  const {
    root,
    accordionSummary,
    summaryContainer,
    titleText,
    descriptionText,
    serviceIconContainer,
    serviceIcon,
    infoContainer,
    accordionDetails,
    locationList,
    locationListItem,
    locationNavigateButton,
    locationTitleText,
    coverImage,
  } = theme.components[`ServiceCard`]?.styleOverrides || {}

  return (
    <Accordion sx={deepmerge(root, sx)} {...props}>
      <AccordionSummary
        expandIcon={hasLocations && <ExpandMoreRoundedIcon />}
        sx={accordionSummary}
      >
        <Box sx={summaryContainer}>
          <Avatar sx={serviceIconContainer}>
            {getIcon(data, serviceIcon) || <ServiceIcon sx={serviceIcon} />}
          </Avatar>
          <Box sx={infoContainer}>
            <Typography variant="h4" sx={titleText}>
              {t(name)}
            </Typography>
            <Typography variant="body1" sx={descriptionText}>
              {" "}
              {t(description)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={deepmerge(coverImage, {
            backgroundImage: `url(${cover_image})`,
          })}
        />
      </AccordionSummary>
      {hasLocations && (
        <AccordionDetails sx={accordionDetails}>
          <List disablePadding sx={locationList}>
            {locations.map((location, index) => (
              <ListItem
                key={`location-item-${index}`}
                sx={locationListItem}
                secondaryAction={
                  location?.id && (
                    <IconButton
                      component={Link}
                      to={`/maps/place/${location?.id}`}
                      edge="end"
                      aria-label="navigate"
                      sx={locationNavigateButton}
                    >
                      <ChevronRightRoundedIcon />
                    </IconButton>
                  )
                }
              >
                <ListItemText
                  sx={locationTitleText}
                  primary={t(location?.name)}
                />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      )}
    </Accordion>
  )
}
