import { useEffect, useCallback, useState, useMemo } from "react"
import _trim from "lodash/trim"
import { ThemeOptions } from "@mui/material"
import { TypographyOptions } from "@mui/material/styles/createTypography"
import standardMapTheme from "theme/map"
import WebFont from "webfontloader"
import _ from "lodash"
import config from "config"
import { fetchPublicData } from "../utils/fetchUtils"

const SYSTEM_FONT_FAMILIES = ["sans-serif"]
const appType = config("appType")
const isWebApp = appType === "webapp"
const fetchDeviceThemeOptions = async (
  themeName: string,
  device?: string
): Promise<ThemeOptions> => {
  try {
    console.log(`fetch device theme ${themeName} (${device})`)
    const themeOptions = await fetchPublicData(
      `themes/${themeName}/${device}.json`
    )
    return themeOptions
  } catch (err) {
    console.log(err)
    console.log(
      `Cannot get theme ${themeName} (${device}), use empty theme instead`
    )
    return {}
  }
}

// Should Later be update to fetch by VENUE_ID to get current active map theme
const fetchMapTheme = async (themeName: string): Promise<any> => {
  try {
    if (themeName === "standard") return standardMapTheme
    const mapTheme = await fetchPublicData(`themes/${themeName}/map.json`)

    return [...standardMapTheme, ...mapTheme]
  } catch (err) {
    console.log(`Cannot get map theme ${themeName}, use standard theme instead`)
    return standardMapTheme
  }
}

export const useTheme = (themeName = "standard", mapThemeName = "standard") => {
  const [themeOptions, setThemeOptions] = useState<ThemeOptions | null>()
  const [mapTheme, setMapTheme] = useState<any>(null)

  const customWebfontUrls = useMemo(
    () => _.get(themeOptions, "assets.fonts.urls", []),
    [themeOptions]
  )

  const initVenueTheme = useCallback(async () => {
    const deviceThemeOptions = await fetchDeviceThemeOptions(
      themeName,
      config("device", "mobile")
    )
    setThemeOptions(_.merge({}, deviceThemeOptions))
  }, [themeName])

  const initMapTheme = useCallback(async () => {
    const mapThemeArr = await fetchMapTheme(mapThemeName)
    const mapTheme = mapThemeArr?.reduce((acc, current) => {
      const { featureType, elementType, symbol, options } = current
      if (options) {
        _.set(acc, `${featureType}.${elementType}.options`, options)
      }
      if (symbol) {
        _.set(acc, `${featureType}.${elementType}.symbol`, symbol)
      }
      return acc
    }, {})
    setMapTheme(mapTheme)
  }, [mapThemeName])

  useEffect(() => {
    if (!themeOptions?.typography) return

    // Load web font families via google font and import to the project
    //TODO: font weight import need to be improved
    interface VuiTypographyOptions extends TypographyOptions {
      fontWeightSemiBold?: number
    }
    const typography = themeOptions.typography as VuiTypographyOptions
    const {
      fontWeightLight = 300,
      fontWeightRegular = 400,
      fontWeightMedium = 500,
      fontWeightSemiBold = 600,
      fontWeightBold = 700,
    } = typography

    const webFontFamily = typography.fontFamily
      .split(",")
      .map(_trim)
      .filter((fontName: string) => !SYSTEM_FONT_FAMILIES.includes(fontName))
      .map(
        (fontName) =>
          `${fontName}:${[
            fontWeightLight,
            fontWeightRegular,
            fontWeightMedium,
            fontWeightSemiBold,
            fontWeightBold,
          ].join(",")}`
      )
    if (webFontFamily && webFontFamily.length > 0) {
      WebFont.load({
        custom: {
          families: webFontFamily,
          urls: customWebfontUrls.map((url: string) =>
            isWebApp ? url : "./" + url
          ),
        },
        google: {
          families: webFontFamily,
        },
      })
    }
  }, [themeOptions?.typography, customWebfontUrls])
  return { initVenueTheme, initMapTheme, themeOptions, mapTheme }
}
