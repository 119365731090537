import { getIconPathString } from "components/icons/pathString"

const BASE_FONT_FAMILY = "Sukhumvit Set"

const defaultMapTheme = [
  {
    featureType: "origin-marker",
    elementType: "geometry",
    symbol: [
      {
        markerType: "ellipse",
        markerFill: "#FF3A75",
        markerLineWidth: 0,
        markerFillOpacity: 0.2,
        markerWidth: 30,
        markerHeight: 30,
      },
      {
        markerType: "ellipse",
        markerFill: "#FF3A75",
        markerLineColor: "#fff",
        markerLineWidth: 2,
        markerWidth: 15,
        markerHeight: 15,
      },
    ],
  },
  {
    featureType: "navigation-path",
    elementType: "geometry",
    symbol: [
      {
        lineColor: "#000",
        lineOpacity: 0,
        lineCap: "round",
        lineJoin: "round",
        lineWidth: {
          stops: [
            [18, 6],
            [20, 8],
          ],
        },
      },
      {
        lineColor: "#FF3A75",
        lineOpacity: 1,
        lineCap: "round",
        lineJoin: "round",
        lineWidth: {
          stops: [
            [18, 3],
            [20, 4],
          ],
        },
      },
    ],
  },
  {
    featureType: "navigation-path-start",
    elementType: "geometry",
    symbol: {
      markerType: "ellipse",
      markerWidth: {
        stops: [
          [17, 4],
          [18, 6],
        ],
      },
      markerHeight: {
        stops: [
          [17, 4],
          [18, 6],
        ],
      },
      markerLineWidth: 1,
      markerLineColor: "#6393f2",
      markerFill: "#fff",
      markerPlacement: "vertex-first",
    },
  },
  {
    featureType: "navigation-path-end",
    elementType: "geometry",
    symbol: {
      markerType: "ellipse",
      markerWidth: {
        stops: [
          [17, 4],
          [18, 6],
        ],
      },
      markerHeight: {
        stops: [
          [17, 4],
          [18, 6],
        ],
      },
      markerLineWidth: 1,
      markerLineColor: "#6393f2",
      markerFill: "#fff",
      markerPlacement: "vertex-last",
    },
  },
  {
    featureType: "pin-marker",
    elementType: "geometry",
    symbol: {
      markerFile:
        "https://api-directory.siampiwat.com/static/uploads/pin_0a7c02e1d7.svg",
      markerFill: "#FF0000",
      markerLineWidth: 0.5783,
      markerLineColor: "#fff",
      markerWidth: {
        stops: [
          [18, 24],
          [19, 48],
          [20, 72],
        ],
      },
      markerHeight: {
        stops: [
          [18, 25],
          [19, 50],
          [20, 75],
        ],
      },
    },
  },
  {
    featureType: "hilight-polygonal",
    elementType: "geometry",
    symbol: { polygonFill: "#787878" },
  },
  {
    featureType: "hilight-polygonal-siam-paragon",
    elementType: "geometry",
    symbol: { polygonFill: "#c69f51" },
  },
  {
    featureType: "hilight-polygonal-siam-center",
    elementType: "geometry",
    symbol: { polygonFill: "#FFD601" },
  },
  {
    featureType: "hilight-polygonal-siam-discovery",
    elementType: "geometry",
    symbol: { polygonFill: "#39499C" },
  },
  {
    featureType: "hilight-marker",
    elementType: "geometry",
    symbol: [
      {
        markerWidth: {
          stops: [
            [18, 5],
            [19, 25],
            [20, 45],
          ],
        },
        markerHeight: {
          stops: [
            [18, 5],
            [19, 25],
            [20, 45],
          ],
        },
      },
      {
        markerWidth: {
          stops: [
            [19, 10],
            [20, 35],
          ],
        },
        markerHeight: {
          stops: [
            [19, 10],
            [20, 35],
          ],
        },
        markerDy: -8,
      },
    ],
  },
  {
    featureType: "highlight-amenity-marker",
    elementType: "geometry",
    symbol: {
      markerFile:
        "https://api-directory.siampiwat.com/static/uploads/pin_0a7c02e1d7.svg",
      markerFill: "#FF0000",
      markerLineWidth: 0.5783,
      markerLineColor: "#fff",
      markerWidth: {
        stops: [
          [18, 24],
          [19, 48],
          [20, 72],
        ],
      },
      markerHeight: {
        stops: [
          [18, 25],
          [19, 50],
          [20, 75],
        ],
      },
    },
  },
  {
    featureType: "user-location",
    elementType: "geometry",
    symbol: {
      start: [
        {
          markerType: "ellipse",
          markerFill: "#077dff",
          markerLineWidth: 0,
          markerFillOpacity: 0.2,
          markerWidth: 30,
          markerHeight: 30,
        },
        {
          markerType: "ellipse",
          markerFill: "#077dff",
          markerLineColor: "#ffffff",
          markerLineWidth: 2,
          markerWidth: 20,
          markerHeight: 20,
        },
      ],
      end: [
        {
          markerFillOpacity: 0.4,
          markerWidth: 40,
          markerHeight: 40,
        },
        {
          markerWidth: 20,
          markerHeight: 20,
        },
      ],
    },
  },
  {
    featureType: "last-user-location",
    elementType: "geometry",
    options: {
      text: "Last\nScanned",
    },
    symbol: [
      {
        markerType: "ellipse",
        markerFill: "#fff",
        markerLineWidth: 0,
        markerFillOpacity: 0.2,
        markerWidth: 30,
        markerHeight: 30,
      },
      {
        markerType: "ellipse",
        markerFill: "#A4A4A4",
        markerLineColor: "#ffffff",
        markerLineWidth: 2,
        markerWidth: 20,
        markerHeight: 20,
      },
      {
        textFaceName: BASE_FONT_FAMILY,
        textName: "{text}",
        textWeight: "normal",
        textStyle: "normal",
        textFill: "#666666",
        textHaloFill: "#ffffff",
        textLineSpacing: 0,
        textHaloRadius: {
          stops: [
            [20, 1],
            [21, 2],
          ],
        },
        textDy: {
          stops: [
            [19, -15],
            [20, -35],
          ],
        },
        textSize: {
          stops: [
            [19, 0],
            [20, 16],
            [22, 18],
          ],
        },
      },
    ],
  },
  {
    featureType: "venue",
    elementType: "geometry",
    symbol: {
      polygonFill: "rgba(213, 213, 213, 0.5)",
      lineWidth: 0,
    },
  },
  {
    featureType: "level",
    elementType: "geometry",
    symbol: {
      lineColor: "#E7E7ED",
      polygonFill: "#F5F7F9",
      lineWidth: 2,
      shadowBlur: 4,
      shadowColor: "rgba(198,199,200, 0.5)",
      shadowOffsetX: 2,
      shadowOffsetY: 6,
    },
  },
  {
    featureType: "unit",
    elementType: "geometry",
    symbol: {
      lineColor: "#969696",
      lineWidth: 2,
      polygonFill: "#CECECE",
    },
  },
  {
    featureType: "unit.elevator",
    elementType: "geometry",
    symbol: {
      lineColor: "#b3aca9",
      lineWidth: 1,
      polygonFill: "#636363",
    },
  },
  {
    featureType: "unit.restroom",
    elementType: "geometry",
    symbol: {
      lineColor: "#b3aca9",
      lineWidth: 1,
      polygonFill: "#636363",
    },
  },
  {
    featureType: "unit.restroom.female",
    elementType: "geometry",
    symbol: {
      lineColor: "#b3aca9",
      lineWidth: 1,
      polygonFill: "#636363",
    },
  },
  {
    featureType: "unit.restroom.male",
    elementType: "geometry",
    symbol: {
      lineColor: "#b3aca9",
      lineWidth: 1,
      polygonFill: "#636363",
    },
  },
  {
    featureType: "unit.mothersroom",
    elementType: "geometry",
    symbol: {
      lineColor: "#b3aca9",
      lineWidth: 1,
      polygonFill: "#636363",
    },
  },
  {
    featureType: "unit.privatelounge",
    elementType: "geometry",
    symbol: {
      lineColor: "#b3aca9",
      lineWidth: 1,
      polygonFill: "#636363",
    },
  },
  {
    featureType: "footprint",
    elementType: "material",
    symbol: {
      color: "#b3aca9",
      transparent: true,
    },
  },
  {
    featureType: "footprint",
    elementType: "geometry",
    symbol: {
      lineWidth: 1,
      polygonFill: "rgba(213, 213, 213, 0.5)",
    },
  },
  {
    featureType: "footprint.aerial",
    elementType: "geometry",
    symbol: {
      lineWidth: 0,
      polygonFill: "rgba(213, 213, 213, 0.5)",
    },
  },
  {
    featureType: "footprint.ground",
    elementType: "geometry",
    symbol: {
      lineWidth: 1,
      polygonFill: "rgba(213, 213, 213, 0.5)",
    },
  },
  {
    featureType: "footprint.subterranean",
    elementType: "geometry",
    symbol: {
      lineWidth: 1,
      polygonFill: "rgba(213, 213, 213, 0.5)",
    },
  },
  {
    featureType: "unit.escalator",
    elementType: "geometry",
    symbol: {
      lineColor: "#b3aca9",
      lineWidth: 1,
      polygonFill: "#636363",
    },
  },
  {
    featureType: "unit.elevator",
    elementType: "geometry",
    symbol: {
      lineColor: "#b3aca9",
      lineWidth: 1,
      polygonFill: "#636363",
    },
  },
  {
    featureType: "unit.stairs",
    elementType: "geometry",
    symbol: {
      lineColor: "#b3aca9",
      lineWidth: 1,
      polygonFill: "#636363",
    },
  },
  {
    featureType: "unit.walkway",
    elementType: "geometry",
    symbol: {
      lineColor: "#969696",
      polygonFill: "#969696",
    },
  },
  {
    featureType: "unit.footbridge",
    elementType: "geometry",
    symbol: {
      lineColor: "#969696",
      polygonFill: "#969696",
    },
  },
  {
    featureType: "unit.room",
    elementType: "geometry",
    symbol: {
      lineColor: "#969696",
      lineWidth: 2,
      polygonFill: "#CECECE",
      polygonOpacity: 0.7,
    },
  },
  {
    featureType: "unit.parking",
    elementType: "geometry",
    symbol: {
      lineColor: "#b3aca9",
      lineWidth: 1,
      polygonFill: "#f0f0f0",
    },
  },
  {
    featureType: "unit.nonpublic",
    elementType: "geometry",
    symbol: {
      lineWidth: 1,
      lineColor: "#D7D7D7",
      polygonFill: "#F7F7F7",
    },
  },
  {
    featureType: "unit.opentobelow",
    elementType: "geometry",
    symbol: {
      lineWidth: 0,
      lineColor: "transparent",
      polygonFill: "transparent",
    },
  },
  {
    featureType: "kiosk",
    elementType: "geometry",
    symbol: {
      lineColor: "#969696",
      polygonFill: "#CECECE",
      polygonOpacity: 0.7,
      lineWidth: 2,
    },
  },
  {
    featureType: "fixture",
    elementType: "geometry",
    symbol: {
      lineColor: "#969696",
      polygonFill: "#CECECE",
      polygonOpacity: 0.7,
      lineWidth: 2,
    },
  },
  {
    featureType: "fixture.vegetation",
    elementType: "geometry",
    symbol: {
      polygonFill: "#A3D220",
      polygonOpacity: 0.7,
      lineWidth: 0,
    },
  },
  {
    featureType: "fixture.water",
    elementType: "geometry",
    symbol: {
      lineWidth: 0,
      polygonFill: "#56ACDF",
      polygonOpacity: 0.7,
    },
  },
  {
    featureType: "opening",
    elementType: "geometry",
    symbol: {
      lineWidth: {
        stops: [
          [18, 0],
          [19, 1],
          [20, 5],
        ],
      },
    },
  },
  {
    featureType: "opening.pedestrian",
    elementType: "geometry",
    symbol: {
      lineColor: "#CECECE",
    },
  },
  {
    featureType: "opening.pedestrian.principal",
    elementType: "geometry",
    symbol: {
      lineColor: "#333333",
    },
  },
  {
    featureType: "section",
    elementType: "geometry",
    symbol: {
      textFaceName: BASE_FONT_FAMILY,
      lineColor: "#EAD7D7",
      lineWidth: 1,
      polygonFill: "#F7F0F0",
    },
  },
  {
    featureType: "section.eatingdrinking",
    elementType: "geometry",
    symbol: {
      polygonFill: "#FFBF7F",
    },
  },
  {
    featureType: "section.eatingdrinking",
    elementType: "geometry",
    symbol: {
      lineWidth: 0,
      polygonFill: "#FFBF7F",
    },
  },
  {
    featureType: "section.vegetation",
    elementType: "geometry",
    symbol: {
      lineColor: "#A4B494",
      polygonFill: "#A4B494",
    },
  },
  {
    featureType: "section.seating",
    elementType: "geometry",
    symbol: {
      lineColor: "#A4B494",
      polygonFill: "#A4B494",
    },
  },
  {
    featureType: "amenity",
    elementType: "label.base",
    symbol: {
      markerType: "path",
      markerPath: "M21.75 0H0.25V21.5H8.35L11.3 24L14.2 21.5H21.75V0Z",
      markerPathWidth: 24,
      markerPathHeight: 24,
      markerWidth: 30,
      markerHeight: 30,
      markerFill: "#044B7F",
    },
  },
  {
    featureType: "amenity",
    elementType: "label.marker",
    symbol: {
      markerType: "path",
      markerFill: "#FFFFFF",
      markerPathWidth: 24,
      markerPathHeight: 24,
      markerWidth: 20,
      markerHeight: 20,
      markerDy: 5,
      markerDx: 8,
    },
  },
  {
    featureType: "highlighted-amenity",
    elementType: "label.base",
    symbol: {
      markerFill: "#f65751",
    },
  },
  {
    featureType: "highlighted-amenity",
    elementType: "label.marker",
    symbol: {
      markerFill: "#FFFFFF",
    },
  },
  {
    featureType: "highlighted-occupant",
    elementType: "label.marker",
    symbol: {
      markerFill: "#f65751",
    },
  },
  {
    featureType: "highlighted-occupant",
    elementType: "label.base",
    symbol: {
      markerFill: "#FFFFFF",
    },
  },
  {
    featureType: "occupant",
    elementType: "label.base",
    symbol: {
      markerType: "path",
      markerPath: "M45.5 0h-43v43h16.2l5.9 5 5.8-5h15.1z",
      markerPathWidth: 48,
      markerPathHeight: 48,
      markerWidth: {
        stops: [
          [18, 0],
          [19, 15],
          [20, 30],
        ],
      },
      markerHeight: {
        stops: [
          [18, 0],
          [19, 15],
          [20, 30],
        ],
      },
      markerFill: "#044B7F",
    },
  },
  {
    featureType: "amenity.elevator",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.elevator"),
    },
  },
  {
    featureType: "amenity.escalator",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.escalator"),
    },
  },
  {
    featureType: "amenity.information",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.information"),
    },
  },
  {
    featureType: "amenity.atm",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.atm"),
    },
  },
  {
    featureType: "amenity.stairs",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.stairs"),
    },
  },
  {
    featureType: "amenity.currencyexchange",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.currencyexchange"),
    },
  },
  {
    featureType: "amenity.restroom",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.restroom"),
    },
  },
  {
    featureType: "amenity.restroom.male",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.restroom.male"),
    },
  },
  {
    featureType: "amenity.restroom.female",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.restroom.female"),
    },
  },
  {
    featureType: "amenity.restroom.wheelchair",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.restroom.wheelchair"),
    },
  },
  {
    featureType: "amenity.parking",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.parking"),
    },
  },
  {
    featureType: "amenity.prayerroom",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.prayerroom"),
    },
  },
  {
    featureType: "amenity.firstaid",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.firstaid"),
    },
  },
  {
    featureType: "amenity.smokingarea",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.smokingarea"),
    },
  },
  {
    featureType: "amenity.bus",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.bus"),
    },
  },
  {
    featureType: "amenity.taxi",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.taxi"),
    },
  },
  {
    featureType: "amenity.parking.bicycle",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.parking.bicycle"),
    },
  },
  {
    featureType: "amenity.parking.motorcycle",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.parking.motorcycle"),
    },
  },
  {
    featureType: "amenity.wheelchair",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.wheelchair"),
    },
  },
  {
    featureType: "amenity.landmark",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.landmark"),
    },
  },
  {
    featureType: "amenity.rail.muni",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.rail.muni"),
    },
  },

  {
    featureType: "amenity.boardinggate.ferry",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.boardinggate.ferry"),
    },
  },
  {
    featureType: "amenity.ticketing",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.ticketing"),
    },
  },
  {
    featureType: "amenity.meetingpoint",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.meetingpoint"),
    },
  },
  {
    featureType: "amenity.exhibit",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.exhibit"),
    },
  },
  {
    featureType: "amenity.powerchargingstation",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.powerchargingstation"),
    },
  },
  {
    featureType: "amenity.coinlocker",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.coinlocker"),
    },
  },
  {
    featureType: "amenity.babychanging",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.babychanging"),
    },
  },
  {
    featureType: "amenity.mothersroom",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.mothersroom"),
    },
  },
  {
    featureType: "amenity.checkin.desk",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.checkin.desk"),
    },
  },
  {
    featureType: "amenity.strollerrental",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.strollerrental"),
    },
  },
  {
    featureType: "amenity.privatelounge",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.privatelounge"),
    },
  },
  {
    featureType: "amenity.service",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.service"),
    },
  },
  {
    featureType: "amenity.ticketing.rail",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.ticketing.rail"),
    },
  },
  {
    featureType: "amenity.baggagestorage",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("amenity.baggagestorage"),
    },
  },
  {
    featureType: "occupant.bank",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("occupant.bank"),
    },
  },
  {
    featureType: "occupant.books",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("occupant.books"),
    },
  },
  {
    featureType: "occupant.currencyexchange",
    elementType: "label.marker",
    symbol: {
      markerPath: getIconPathString("occupant.currencyexchange"),
    },
  },
  {
    featureType: "highlight-occupant-logo",
    elementType: "label.base",
    symbol: {
      markerType: "path",
      markerPath:
        "M7.37608 0C3.30239 0 0 3.30239 0 7.37608V36.0859C0 40.1596 3.30239 43.4621 7.37608 43.4621H15.6185L23.0053 54.4285L30.392 43.4621H38.6238C42.6975 43.4621 46 40.1596 46 36.0859V7.37608C46 3.30239 42.6975 0 38.6238 0H7.37608Z",
      markerPathWidth: 46,
      markerPathHeight: 54,
      markerWidth: {
        stops: [
          [18, 69],
          [20, 87],
          [22, 108],
        ],
      },
      markerHeight: {
        stops: [
          [18, 81],
          [20, 104],
          [22, 126],
        ],
      },
      markerFill: "#fff",
      shadowOffsetY: 8,
      shadowBlur: 20,
      shadowColor: "rgb(35 31 32 / 30%)",
    },
  },
  {
    featureType: "highlight-occupant-logo",
    elementType: "label.marker",
    symbol: {
      markerWidth: {
        stops: [
          [18, 51],
          [20, 63],
          [22, 84],
        ],
      },
      markerHeight: {
        stops: [
          [18, 51],
          [20, 63],
          [22, 84],
        ],
      },
      markerDy: {
        stops: [
          [18, -24],
          [20, -30],
          [22, -33],
        ],
      },
    },
  },
  {
    featureType: "occupant-logo-1",
    elementType: "label.base",
    symbol: {
      markerType: "path",
      markerPath: "M45.5 0h-43v43h16.2l5.9 5 5.8-5h15.1z",
      markerPathWidth: 48,
      markerPathHeight: 48,
      markerWidth: {
        stops: [
          [18, 30],
          [19, 32],
          [20, 36],
          [21, 36],
          [22, 40],
        ],
      },
      markerHeight: {
        stops: [
          [18, 30],
          [19, 32],
          [20, 36],
          [21, 36],
          [22, 40],
        ],
      },
      markerFill: "#044B7F",
    },
  },
  {
    featureType: "occupant-logo-1",
    elementType: "label.marker",
    symbol: {
      markerWidth: {
        stops: [
          [18, 24],
          [19, 26],
          [20, 28],
          [21, 28],
          [22, 32],
        ],
      },
      markerHeight: {
        stops: [
          [18, 24],
          [19, 26],
          [20, 28],
          [21, 28],
          [22, 32],
        ],
      },
      markerDy: -6,
    },
  },
  {
    featureType: "occupant-logo-2",
    elementType: "label.base",
    symbol: {
      markerType: "path",
      markerPath: "M45.5 0h-43v43h16.2l5.9 5 5.8-5h15.1z",
      markerPathWidth: 48,
      markerPathHeight: 48,
      markerWidth: {
        stops: [
          [18, 0],
          [19, 32],
          [20, 36],
          [21, 36],
          [22, 40],
        ],
      },
      markerHeight: {
        stops: [
          [18, 0],
          [19, 32],
          [20, 36],
          [21, 36],
          [22, 40],
        ],
      },
      markerFill: "#044B7F",
    },
  },
  {
    featureType: "occupant-logo-2",
    elementType: "label.marker",
    symbol: {
      markerWidth: {
        stops: [
          [18, 0],
          [19, 26],
          [20, 28],
          [21, 28],
          [22, 32],
        ],
      },
      markerHeight: {
        stops: [
          [18, 0],
          [19, 26],
          [20, 28],
          [21, 28],
          [22, 32],
        ],
      },
      markerDy: -6,
    },
  },
  {
    featureType: "occupant-logo-3",
    elementType: "label.base",
    symbol: {
      markerType: "path",
      markerPath: "M45.5 0h-43v43h16.2l5.9 5 5.8-5h15.1z",
      markerPathWidth: 48,
      markerPathHeight: 48,
      markerWidth: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 36],
          [21, 36],
          [22, 40],
        ],
      },
      markerHeight: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 36],
          [21, 36],
          [22, 40],
        ],
      },
      markerFill: "#044B7F",
    },
  },
  {
    featureType: "occupant-logo-3",
    elementType: "label.marker",
    symbol: {
      markerWidth: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 28],
          [21, 28],
          [22, 32],
        ],
      },
      markerHeight: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 28],
          [21, 28],
          [22, 32],
        ],
      },
      markerDy: -6,
    },
  },
  {
    featureType: "occupant-logo-4",
    elementType: "label.base",
    symbol: {
      markerType: "path",
      markerPath: "M45.5 0h-43v43h16.2l5.9 5 5.8-5h15.1z",
      markerPathWidth: 48,
      markerPathHeight: 48,
      markerWidth: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 0],
          [21, 36],
          [22, 40],
        ],
      },
      markerHeight: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 0],
          [21, 36],
          [22, 40],
        ],
      },
      markerFill: "#044B7F",
    },
  },
  {
    featureType: "occupant-logo-4",
    elementType: "label.marker",
    symbol: {
      markerWidth: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 0],
          [21, 28],
          [22, 32],
        ],
      },
      markerHeight: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 0],
          [21, 28],
          [22, 32],
        ],
      },
      markerDy: -6,
    },
  },
  {
    featureType: "occupant-logo-5",
    elementType: "label.base",
    symbol: {
      markerType: "path",
      markerPath: "M45.5 0h-43v43h16.2l5.9 5 5.8-5h15.1z",
      markerPathWidth: 48,
      markerPathHeight: 48,
      markerWidth: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 0],
          [21, 0],
          [22, 40],
        ],
      },
      markerHeight: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 0],
          [21, 0],
          [22, 40],
        ],
      },
      markerFill: "#044B7F",
    },
  },
  {
    featureType: "occupant-logo-5",
    elementType: "label.marker",
    symbol: {
      markerWidth: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 0],
          [21, 0],
          [22, 32],
        ],
      },
      markerHeight: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 0],
          [21, 0],
          [22, 32],
        ],
      },
      markerDy: -6,
    },
  },
  // === UI Marker ===
  {
    featureType: "highlight-occupant-logo",
    elementType: "ui.marker",
    symbol: {
      position: "relative",
      bottom: "56px",
      width: "91px",
      height: "96px",
      display: "flex",
      justifyContent: "center",
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundColor: "transparent",
      backgroundRepeat: "no-repeat",
      backgroundImage:
        "url(https://api-directory.siampiwat.com/static/uploads/logo_marker_current_1_a12d556d1b.svg)",
    },
    options: {
      maxZoom: null,
      minZoom: 20,
    },
  },
  {
    featureType: "highlight-occupant-logo",
    elementType: "ui.marker.logo",
    symbol: {
      margin: "18px",
      borderRadius: "6px",
      objectFit: "cover",
      width: "44px",
      height: "44px",
    },
  },
  {
    featureType: "highlighted-logo-marker",
    elementType: "ui.marker",
    symbol: {
      position: "relative",
      bottom: "56px",
      width: "91px",
      height: "96px",
      display: "flex",
      justifyContent: "center",
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundColor: "transparent",
      backgroundRepeat: "no-repeat",
      backgroundImage:
        "url(https://api-directory.siampiwat.com/static/uploads/logo_marker_current_1_a12d556d1b.svg)",
    },
    options: {
      maxZoom: null,
      minZoom: 18,
    },
  },
  {
    featureType: "highlighted-logo-marker",
    elementType: "ui.marker.logo",
    symbol: {
      margin: "18px",
      borderRadius: "6px",
      objectFit: "cover",
      width: "44px",
      height: "44px",
    },
  },
  {
    featureType: "occupant-name",
    elementType: "ui.marker",
    symbol: {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 3,
      overflow: "hidden",
      WebkitBoxPack: "center",
      WebkitBoxAlign: "center",
      width: "180px",
      textOverflow: "ellipsis",
      paintOrder: "stroke",
      WebkitTextStrokeWidth: "5px",
      WebkitTextStrokeColor: "#fff",
      fontFamily: BASE_FONT_FAMILY,
      textAlign: "center",
    },
  },
  {
    featureType: "occupant-name-siam-center",
    elementType: "ui.marker",
    symbol: {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 3,
      overflow: "hidden",
      WebkitBoxPack: "center",
      WebkitBoxAlign: "center",
      width: "180px",
      textOverflow: "ellipsis",
      paintOrder: "stroke",
      WebkitTextStrokeWidth: "3px",
      WebkitTextStrokeColor: "#fff",
      fontFamily: BASE_FONT_FAMILY,
      textAlign: "center",
      color: "#000",
    },
  },
  {
    featureType: "occupant-name-siam-discovery",
    elementType: "ui.marker",
    symbol: {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 3,
      overflow: "hidden",
      WebkitBoxPack: "center",
      WebkitBoxAlign: "center",
      width: "180px",
      textOverflow: "ellipsis",
      paintOrder: "stroke",
      WebkitTextStrokeWidth: "3px",
      WebkitTextStrokeColor: "#fff",
      fontFamily: BASE_FONT_FAMILY,
      textAlign: "center",
      color: "#39499C",
    },
  },
  {
    featureType: "occupant-name-siam-paragon",
    elementType: "ui.marker",
    symbol: {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 3,
      overflow: "hidden",
      WebkitBoxPack: "center",
      WebkitBoxAlign: "center",
      width: "180px",
      textOverflow: "ellipsis",
      paintOrder: "stroke",
      WebkitTextStrokeWidth: "3px",
      WebkitTextStrokeColor: "#fff",
      fontFamily: BASE_FONT_FAMILY,
      textAlign: "center",
      color: "#A3873E",
    },
  },
  {
    featureType: "occupant-name-1",
    elementType: "ui.marker",
    symbol: {
      fontSize: "14px",
    },
    options: {
      maxZoom: null,
      minZoom: 0,
      collision: false,
    },
  },
  {
    featureType: "occupant-name-2",
    elementType: "ui.marker",
    symbol: {
      fontSize: "14px",
    },
    options: {
      maxZoom: null,
      minZoom: 19,
      collision: false,
    },
  },
  {
    featureType: "occupant-name-3",
    elementType: "ui.marker",
    symbol: {
      fontSize: "14px",
    },
    options: {
      maxZoom: null,
      minZoom: 20,
      collision: false,
    },
  },
  {
    featureType: "occupant-name-4",
    elementType: "ui.marker",
    symbol: {
      fontSize: "14px",
    },
    options: {
      maxZoom: null,
      minZoom: 21,
      collision: false,
    },
  },
  {
    featureType: "occupant-name-5",
    elementType: "ui.marker",
    symbol: {
      fontSize: "12px",
    },
    options: {
      maxZoom: null,
      minZoom: 22,
      collision: false,
    },
  },
  // === End UI Marker ===
  {
    featureType: "occupant-logo + name",
    elementType: "label.marker",
    symbol: {
      markerHorizontalAlignment: "left",
      markerVerticalAlignment: "middle",
      textHorizontalAlignment: "right",
      textVerticalAlignment: "middle",
      textDx: 5,
      textFaceName: BASE_FONT_FAMILY,
      textWeight: "bold",
      textStyle: "normal",
      textFill: "#666666",
      textHaloFill: "#ffffff",
      textLineSpacing: 0,
    },
  },
  {
    featureType: "occupant-logo + name-1",
    elementType: "label.marker",
    symbol: {
      textSize: {
        stops: [
          [18, 12],
          [19, 14],
          [20, 16],
          [21, 16],
          [22, 18],
        ],
      },
      markerHeight: {
        stops: [
          [18, 28],
          [19, 32],
          [20, 36],
          [21, 36],
          [22, 40],
        ],
      },
      markerWidth: {
        stops: [
          [18, 28],
          [19, 32],
          [20, 36],
          [21, 36],
          [22, 40],
        ],
      },
    },
  },
  {
    featureType: "occupant-logo + name-2",
    elementType: "label.marker",
    symbol: {
      textSize: {
        stops: [
          [18, 0],
          [19, 14],
          [20, 16],
          [21, 16],
          [22, 18],
        ],
      },
      markerHeight: {
        stops: [
          [18, 0],
          [19, 32],
          [20, 36],
          [21, 36],
          [22, 40],
        ],
      },
      markerWidth: {
        stops: [
          [18, 0],
          [19, 32],
          [20, 36],
          [21, 36],
          [22, 40],
        ],
      },
    },
  },
  {
    featureType: "occupant-logo + name-3",
    elementType: "label.marker",
    symbol: {
      textSize: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 16],
          [21, 16],
          [22, 18],
        ],
      },
      markerHeight: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 35],
          [21, 35],
          [22, 40],
        ],
      },
      markerWidth: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 35],
          [21, 35],
          [22, 40],
        ],
      },
    },
  },
  {
    featureType: "occupant-logo + name-4",
    elementType: "label.marker",
    symbol: {
      textSize: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 0],
          [21, 16],
          [22, 18],
        ],
      },
      markerHeight: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 0],
          [21, 36],
          [22, 40],
        ],
      },
      markerWidth: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 0],
          [21, 36],
          [22, 40],
        ],
      },
    },
  },
  {
    featureType: "occupant-logo + name-5",
    elementType: "label.marker",
    symbol: {
      textSize: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 0],
          [21, 0],
          [22, 18],
        ],
      },
      markerHeight: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 0],
          [21, 0],
          [22, 40],
        ],
      },
      markerWidth: {
        stops: [
          [18, 0],
          [19, 0],
          [20, 0],
          [21, 0],
          [22, 40],
        ],
      },
    },
  },
  {
    featureType: "highlighted-logo-marker",
    elementType: "label.base",
    symbol: {
      markerType: "path",
      markerPath:
        "M10.5049 0C6.91234 0 4 2.91234 4 6.5049V31.8239C4 35.4164 6.91234 38.3288 10.5049 38.3288H17.7738L24.2881 48L30.8024 38.3288H38.0621C41.6546 38.3288 44.567 35.4164 44.567 31.8239V6.5049C44.567 2.91234 41.6546 0 38.0621 0H10.5049Z",
      markerPathWidth: 48,
      markerPathHeight: 48,
      markerWidth: {
        stops: [
          [18, 24],
          [19, 48],
          [20, 72],
        ],
      },
      markerHeight: {
        stops: [
          [18, 24],
          [19, 48],
          [20, 72],
        ],
      },
      markerFill: "#FFF",
      shadowBlur: 20,
      shadowColor: "rgba(0, 0, 0, 0.2)",
      shadowOffsetY: 8,
    },
  },
  {
    featureType: "highlighted-logo-marker",
    elementType: "label.marker",
    symbol: {
      markerWidth: {
        stops: [
          [18, 17],
          [19, 34],
          [20, 50],
        ],
      },
      markerHeight: {
        stops: [
          [18, 17],
          [19, 34],
          [20, 50],
        ],
      },
      markerDy: {
        stops: [
          [18, -7],
          [19, -12],
          [20, -18],
        ],
      },
    },
  },
  {
    featureType: "highlighted-marker",
    elementType: "geometry",
    symbol: [
      {
        markerWidth: {
          stops: [
            [18, 30],
            [19, 35],
            [20, 45],
          ],
        },
        markerHeight: {
          stops: [
            [18, 30],
            [19, 35],
            [20, 45],
          ],
        },
        markerFill: "#C60000",
      },
      {
        markerWidth: {
          stops: [
            [18, 20],
            [19, 25],
            [20, 35],
          ],
        },
        markerHeight: {
          stops: [
            [18, 20],
            [19, 25],
            [20, 35],
          ],
        },
        markerDy: -7,
        markerDx: -2,
      },
    ],
  },
]

export default defaultMapTheme
