import React, { useMemo } from "react"
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  useTheme,
  Box,
  CardMedia,
} from "@mui/material"
import StarIcon from "@mui/icons-material/Star"
import { deepmerge } from "@mui/utils"
import { getIcon } from "utils/icon"

export const FeatureListItem = (props) => {
  const {
    primary = "",
    secondary = "",
    onClick = () => {},
    avatar = null,
    divider,
    primaryTypographyProps = {},
    secondaryTypographyProps = {},
    endAdornment = null,
    disableTypography,
    sx,
    ...restProps
  } = props
  const theme = useTheme()
  const styleOverrides =
    theme.components[`FeatureListItem`]?.styleOverrides || {}
  const {
    root,
    adornmentDivider,
    bottomDivider,
    adornmentWrapper,
    listItemText,
    listItemAvatar,
    listItemContent,
    highlightBanner,
  } = styleOverrides
  const highlightIcon = useMemo(
    () => getIcon(theme?.assets?.feature?.highlight) || <StarIcon />,
    [theme]
  )
  return (
    <>
      <ListItem
        button
        onClick={onClick}
        {...restProps}
        disableGutters
        sx={deepmerge(root, sx)}
      >
        <ListItemAvatar sx={listItemAvatar}>{avatar}</ListItemAvatar>
        <Box sx={listItemContent}>
          <ListItemText
            primaryTypographyProps={{
              variant: "title",
              component: "h6",
              ...primaryTypographyProps,
            }}
            secondaryTypographyProps={{
              variant: "subTitle",
              component: "p",
              ...secondaryTypographyProps,
            }}
            sx={listItemText}
            primary={primary}
            secondary={secondary}
            disableTypography={disableTypography}
          />
          {endAdornment && (
            <Box sx={adornmentWrapper}>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={adornmentDivider}
              />
              <CardMedia component="img" image={endAdornment} />
            </Box>
          )}
        </Box>
        <Box className="FeatureListItem-highlightBanner" sx={highlightBanner}>
          {highlightIcon}
        </Box>
      </ListItem>
      {divider && <Divider sx={bottomDivider} />}
    </>
  )
}
