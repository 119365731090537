import config from "config"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

/**
 * Instruction from mui doc
 * ==============================
 * Ref: https://mui.com/material-ui/customization/theming/
 * Theme composition: using theme options to define other options
 * When the value for a theme option is dependent on another theme option, you should compose the theme in steps.
 */

//TODO:: custom venue horizontal kiosk style later
export const venueKioskHUIThemeOptionFactory = (theme) => ({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          userSelect:
            config("env") === "production" &&
            ["kiosk-v", "kiosk-h"].includes(config("device"))
              ? "none"
              : "auto",
        },
        "@keyframes pulse": {
          "0%": {
            opacity: 1,
          },
          "50%": {
            opacity: 0.6,
          },
          "100%": {
            opacity: 1,
          },
        },
        "@keyframes ripple": {
          from: {
            opacity: 1,
            transform: "scale3d(0.75,0.75,1)",
          },
          to: {
            opacity: 0,
            transform: "scale3d(1.6,1.6,1)",
          },
        },
        "@keyframes slideUp": {
          from: {
            transform: "translateY(100%)",
          },
          to: {
            transform: "translateY(0%)",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius: 12,
        },
      },
      variants: [
        {
          props: { variant: "logo" },
          style: {
            borderRadius: 8,
            width: 55,
            height: 55,
            marginRight: theme.spacing(2.25),
            "& .MuiAvatar-img": {
              objectFit: "contain",
            },
          },
        },
        {
          props: { variant: "circular-logo" },
          style: {
            borderRadius: "50%",
            width: 80,
            height: 80,
            marginRight: theme.spacing(2.25),
            "& .MuiAvatar-img": {
              objectFit: "cover",
            },
          },
        },
        {
          props: { color: "primary" },
          style: {
            background: theme.palette.primary.main,
            borderRadius: 5,
            "& .MuiSvgIcon-root": {
              width: 20,
            },
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "uppercase",
          lineHeight: 1.45,
          borderRadius: "8px",
          boxShadow: "none",
        },
        startIcon: {
          marginRight: theme.spacing(1.25),
        },
        endIcon: {
          marginLeft: theme.spacing(1.25),
        },
        sizeSmall: {
          fontSize: 18,
          padding: theme.spacing(1, 2),
        },
        sizeMedium: {
          fontSize: 20,
          padding: theme.spacing(2, 2.5),
        },
        sizeLarge: {
          fontSize: 24,
          padding: theme.spacing(2, 5),
        },
        outlinedSizeLarge: {
          borderWidth: "2px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderBottomWidth: theme.spacing(0.25),
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          whiteSpace: "pre",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      variants: [
        {
          props: { variant: "medium" },
          style: {
            "& .MuiListItemText-primary": {
              fontSize: theme.typography.body2.fontSize,
            },
            "& .MuiListItemText-secondary": {
              fontSize: theme.typography.caption.fontSize,
            },
          },
        },
        {
          props: { color: "primary" },
          style: {
            "& .MuiListItemText-primary": {
              color: theme.palette.primary.main,
            },
          },
        },
      ],
    },
    MuiRadio: {
      defaultProps: {
        icon: <CheckCircleIcon />,
        checkedIcon: <CheckCircleIcon />,
      },
    },
    MuiFab: {
      styleOverrides: {
        default: {
          background: "#fff",
        },
        sizeLarge: {
          padding: theme.spacing(5, 6),
          "& .MuiSvgIcon-root": {
            width: 40,
            height: 40,
          },
        },
      },
      variants: [
        {
          props: { variant: "rounded" },
          style: {
            borderRadius: 12,
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          "&:hover": {
            background: theme.palette.primary.dark,
          },
          "& .MuiSvgIcon-root": {
            "& path": { fill: theme.palette.primary.contrastText },
          },
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            borderRadius: 5,
          },
        },
      ],
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: { gap: theme.spacing(1.25), paddingRight: theme.spacing(2) },
        grouped: {
          "&:not(:last-of-type)": {
            borderRadius: 9999,
          },
          "&:not(:first-of-type)": {
            marginLeft: 1,
            borderLeft: `1px solid rgba(203, 203, 203, 0.5)`,
            borderRadius: 9999,
          },
        },
      },
      variants: [
        {
          props: { variant: "borderSeperate" },
          style: {
            "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
              borderLeft: `1px solid transparent`,
            },
          },
        },
      ],
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 9999,
          color: theme.palette.primary.main,
          borderColor: "rgba(203, 203, 203, 0.5)",
          "&.Mui-selected, &.Mui-selected:hover": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: theme.spacing(2),
        },
      },
    },
    /**
     * VenueUI (Vui) - Horizontal Kiosk
     * Extra Component for venue.in.th projects
     */
    VuiDescriptionList: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        labelText: {
          fontWeight: 700,
          fontSize: "12px",
          textTransform: "uppercase",
        },
        contentText: { fontWeight: 300 },
      },
    },
    VuiSearchToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: `9999px`,
          background: theme.palette.background.paper,
          width: "100%",
          padding: theme.spacing(1, 2.5),
          justifyContent: "flex-start",
          border: `1px solid #ececec`,
        },
        searchIcon: {
          color: theme.palette.common.black,
          fontSize: "1.125rem",
          mr: 2.5,
        },
        label: {
          color: theme.palette.text.secondary,
        },
      },
    },
    VuiNavigationControl: {
      styleOverrides: {
        wrapper: {
          height: 107,
          display: "flex",
          flexDirection: "row",
          width: "100%",
        },
        navigationAction: {
          padding: theme.spacing(3),
        },
      },
    },
    VuiSearchBasePopup: {
      styleOverrides: {
        drawer: {
          width: 350,
          display: "flex",
          flexDirection: "column",
        },
        drawerPaper: {
          overflow: "hidden",
          width: "100%",
          [theme.breakpoints.up("sm")]: {
            width: 400,
          },
        },
        dialog: {
          width: "65vw", //  Page content width
          top: theme.spacing(15.75), // Top padding for content page (20px) + Header height (106px)
          left: theme.spacing(4.125), // Content page padding: 33px
          bottom: theme.spacing(2.5), // Bottom padding for content page: 20px
          "& .MuiDialogContent-root": {
            [theme.breakpoints.down("sm")]: {
              padding: theme.spacing(1.25, 2),
            },
          },
          "& .MuiBackdrop-root": {
            position: "absolute",
            borderRadius: "15px",
            "&:after": {
              position: "fixed",
              display: "flex",
              content: "''",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            },
          },
        },
        dialogPaper: {
          margin: theme.spacing(1.75),
          maxWidth: "1086px",
          background: "transparent",
          boxShadow: "none",
          alignSelf: "flex-end",
        },
        dialogContent: {
          display: "flex",
          padding: 0,
          flexDirection: "column",
          gap: theme.spacing(2),
          // Apply a className to each section for ordering.
          "& .VuiSearchResult-root": {
            order: 0,
          },
          "& .VuiSearchPopupContent-searchBar": {
            order: 2,
          },
          "& .VuiSearchKeyboard-root": {
            order: 9999,
          },
        },
      },
    },
    VuiSearchResult: {
      styleOverrides: {
        root: {
          flexGrow: 1,
          width: "100%",
          overflow: "hidden",
        },
        rootDialog: {
          position: "relative",
          backgroundColor: theme.palette.background.paper,
          // TODO: Update the height to 413px when adding category quick search.
          height: "613px",
          borderRadius: "20px",
          padding: theme.spacing(9.75, 8.5, 0),
          "&:after": {
            display: "flex",
            pointerEvents: "none",
            content: "''",
            width: "100%",
            height: "147px",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            background: `linear-gradient(0deg, ${theme.palette.common.white} 9.97%, transparent 72.39%)`,
          },
        },
        list: {
          display: "flex",
          flexDirecton: "row",
          flexWrap: "wrap",
          margin: "0 auto",
          gap: theme.spacing(2),
          padding: theme.spacing(2.75, 0),
        },
        listItem: {
          padding: 0,
          width: "296px",
        },
      },
    },
    VuiSearchResultItem: {
      styleOverrides: {
        chip: {
          display: "none",
          borderRadius: "4px",
          height: "18px",
          fontWeight: 400,
          backgroundColor: theme.palette.grey[400],
          color: theme.palette.common.white,
          fontSize: "0.625rem",
          "& .MuiChip-label": {
            padding: theme.spacing(0, 1),
          },
        },
        primaryWrapper: {
          display: "flex",
          alignItems: "flex-start",
          gap: theme.spacing(0.75),
        },
      },
    },
    VuiNoSearchResult: {
      styleOverrides: {
        root: {
          display: "flex",
          height: "300px",
          justifyContent: "center",
          alignItems: "center",
        },
        noResultText: {},
      },
    },
    VuiSearchPopupContent: {
      styleOverrides: {
        root: {
          display: "flex",
          width: "auto",
          gap: theme.spacing(2.5),
          alignItems: "center",
          color: theme.palette.text.primary,
        },
        dialogPaper: {
          alignSelf: "center",
        },
        closeButton: {
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 1,
          marginTop: theme.spacing(3),
          marginRight: theme.spacing(3),
          padding: theme.spacing(2),
          width: "2.9rem",
          height: "2.9rem",
          color: theme.palette.primary.main,
        },
        closeButtonIcon: {
          width: "2rem",
          height: "2rem",
          color: "inherit",
        },
        searchInput: {
          fontSize: "1.25rem",
          gap: theme.spacing(10),
          borderRadius: "9999px",
          padding: theme.spacing(1, 6),
          backgroundColor: theme.palette.common.white,
          fontWeight: 700,
          "& .MuiInputAdornment-root": {
            "& .MuiSvgIcon-root": {
              color: "#cccccc",
            },
            "&.MuiInputAdornment-positionEnd": {
              "& .MuiSvgIcon-root": {
                width: 8,
                height: 8,
              },
            },
          },
          "& input": {
            padding: 0,
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1.125rem",
            color: theme.palette.text.primary,
          },
        },
        suggestedList: {
          marginTop: theme.spacing(2),
        },
      },
    },
    VuiAdSuggestList: {
      styleOverrides: {
        root: {
          display: "none",
          padding: theme.spacing(1, 2),
          backgroundColor: "#F2F2F2",
          margin: theme.spacing(0, 3),
          borderRadius: "16px",
        },
        suggestTitle: {
          fontSize: "1rem",
          fontWeight: 700,
        },
        suggestList: {
          "& .splide__track": {
            margin: 0,
            padding: theme.spacing(2, 0, 0.5),
          },
        },
        suggestListItem: {
          display: "flex",
          flexDirection: "column",
          padding: 0,
          justifyContent: "center",
        },
        suggestItemAvatar: {
          "& .MuiAvatar-root": {
            marginRight: 0,
          },
        },
        suggestItemContent: {
          marginTop: theme.spacing(0.5),
          "& .MuiTypography-root": {
            fontSize: "0.625rem",
            height: "42px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            textAlign: "center",
          },
        },
      },
    },
    VuiSearchKeyboard: {
      styleOverrides: {
        root: {
          margin: theme.spacing(0, 5.5),
          borderRadius: "20px",
          backgroundColor: theme.palette.background.paper,
        },
      },
    },
    VuiDetailCard: {
      styleOverrides: {
        card: {},
        image: {
          width: "50%",
          margin: "0 auto",
          marginBottom: theme.spacing(3),
          borderRadius: "10px",
          [theme.breakpoints.up("sm")]: {
            width: "50%",
            margin: "0 auto",
          },
        },
        infoListItem: {},
        infoListItemWithNavigation: {
          padding: 0,
          "& .MuiListItemButton-root": {
            padding: 0,
          },
        },
        cardHeaderAction: {},
        cardHeaderActionButton: {},
        cardHeader: {
          padding: 0,
          paddingBottom: theme.spacing(2),
          "& .MuiCardHeader-title": {
            fontSize: "20px",
            fontWeight: 700,
          },
          "& .MuiCardHeader-action": {
            marginRight: 0,
          },
        },
        cardContent: {
          padding: 0,
          paddingTop: theme.spacing(1),
        },
        cardContentPrimaryText: {
          color: theme.palette.text.primary,
          fontWeight: 700,
        },
        cardContentSecondaryText: {
          color: theme.palette.text.secondary,
        },
      },
    },
    VuiSearchQuickLink: {
      styleOverrides: {
        root: {
          pb: 1,
          mb: 1,
          mx: -2,
          px: 2,
        },
        stack: {
          pb: 2,
        },
        item: {
          boxShadow: "rgb(0 0 0 / 10%) 0px 2px 10px",
          border: "none",
          background: "white",
          "& .MuiSvgIcon-root": {
            width: "16px",
            height: "16px",
          },
        },
      },
    },
    VuiOnscreenKeyboard: {
      styleOverrides: {
        root: {
          // [Example] (https://hodgef.com/simple-keyboard/editor/?d=hodgef/react-simple-keyboard-demos/tree/uc-customization)
          // Add your custom style here
          "& .hg-theme-default": {
            fontFamily: "inherit",
          },
          "& .simple-keyboard": {
            maxWidth: "100%",
            padding: theme.spacing(1),
            borderRadius: "20px",
            backgroundColor: "transparent",
            "& .hg-rows": {
              width: "660px",
              margin: "0 auto",
            },
            "&.hg-theme-default .hg-row": {
              marginBottom: theme.spacing(0.75),
              "&:last-child": {
                marginBottom: 0,
              },
              "& .hg-button:not(:last-child)": {
                marginRight: theme.spacing(1.5),
              },
            },
            "& .hg-button": {
              height: 42,
              width: 50,
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
              fontSize: "25px",
              fontWeight: 500,
              borderRadius: "6px",
              "&>span": {
                display: "flex",
                fontSize: "inherit",
              },
              "&.hg-standardBtn": {
                width: 50,
              },
            },
            "& .hg-activeButton": {
              backgroundColor: theme.palette.grey[300],
              color: theme.palette.text.primary,
            },
            "& .hg-button-number,& .hg-button-symbol, & .hg-button-abc,& .hg-button-lang":
              {
                fontSize: "12px",
                flexGrow: 1,
              },
            "& .hg-button-lang, & .hg-button-number, & .hg-button-abc": {
              flexGrow: 0.5,
            },
            "& .hg-button-shift,& .hg-button-bksp": {
              fontSize: "20px",
            },
            "& .hg-button-shift": {
              fontSize: "20px",
              flexGrow: 2,
              flexShrink: 0,
            },
            "& .hg-button-enter": {
              flexGrow: 2,
            },
            "& .hg-button-space": {
              flexGrow: 8,
            },
            "& .hg-primary-button": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
            "& .hg-secondary-button": {
              backgroundColor: theme.palette.text.secondary,
              color: theme.palette.primary.contrastText,
            },
            "& .hg-small-button": {},
          },
        },
      },
    },
    VuiFilterVenueRadioButtons: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
          width: "100%",
          gap: theme.spacing(5),
          justifyContent: "center",
          textTransform: "uppercase",
          "& .MuiFormControlLabel-label": {
            display: "flex",
          },
        },
        formControlLabel: {},
        labelImage: {
          height: "32px",
        },
      },
    },
    VuiCarousel: {
      styleOverrides: {
        root: {
          "& .splide__pagination__page": {
            background: theme.palette.grey[500],
          },
          "& .splide__pagination__page.is-active": {
            background: theme.palette.primary.main,
          },
          "& .splide__pagination": {
            position: "static",
          },
          "& .splide__track": {
            overflow: "hidden",
            margin: theme.spacing(-3),
            padding: theme.spacing(3),
          },
          "& .splide__arrow": {
            background: "none",
            margin: theme.spacing(0, -5),
            "& svg": {
              fill: theme.palette.grey[500],
            },
          },
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 15,
      },
      styleOverrides: {
        root: {
          borderRadius: "0",
          color: "#000000",
        },
      },
      variants: [
        {
          props: { variant: "image" },
          style: {
            "& .MuiCardHeader-root": {
              "& .MuiCardHeader-subheader": {
                display: "flex",
                width: "100%",
              },
              "& .MuiCardHeader-subheader .sub-category-text": {
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            },
            "& .MuiCardHeader-content": {
              overflow: "hidden",
            },
            "& .MuiCardHeader-title": {
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              lineHeight: 1,
            },
            "& .MuiCardHeader-root .MuiCardHeader-avatar": {
              height: "100%",
            },
            "& .MuiCardActionArea-root": {
              width: "100%",
              height: "100%",
            },
          },
        },
      ],
    },
    HorizontalScrollPane: {
      styleOverrides: {
        root: {
          width: "100%",
          "& .simplebar-track.simplebar-horizontal": {
            display: "none",
          },
        },
        listWrapper: {
          width: "100%",
          padding: theme.spacing(1),
        },
      },
    },
    LoadingScreen: {
      styleOverrides: {
        root: {
          position: "fixed",
          width: "100dvw",
          height: `100dvh`,
          backgroundColor: theme.palette.common.white,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10000,
        },
        logo: {
          maxWidth: "70dvw",
        },
      },
    },
    FeatureChipToggleBar: {
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: 0,
        },
        button: {
          padding: theme.spacing(0.5),
          borderRadius: "16px",
          boxShadow: "none",
          background: theme.palette.common.white,
          border: `1px solid ${theme.palette.grey[200]}`,
          whiteSpace: "nowrap",
          height: "32px",
          color: theme.palette.text.primary,
          fontWeight: 400,
          "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
            borderRadius: "16px",
            margin: 0,
          },
          "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
            border: `1px solid ${theme.palette.grey[200]}`,
            margin: 0,
            borderRadius: "16px",
          },
          "&.MuiToggleButtonGroup-grouped:first-of-type": {
            marginLeft: theme.spacing(3),
          },
          "&.Mui-selected": {
            "& svg": {
              fill: theme.palette.primary.contrastText,
            },
          },
          ":hover": {
            background: theme.palette.common.white,
          },
          "& svg": {
            width: 16,
            height: 16,
            fill: theme.palette.primary.main,
            marginLeft: theme.spacing(0.5),
            marginRight: "-6px",
          },
        },
        text: {
          fontSize: "0.8125rem",
          padding: theme.spacing(0, 1.5),
          letterSpacing: "0.00938em",
        },
      },
    },
    VuiAdModal: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        },
        closeButton: {
          padding: theme.spacing(0.5, 2),
          marginTop: theme.spacing(2),
        },
      },
    },
    VuiAdImageCarousel: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        carousel: {
          "& .splide__pagination": {
            marginTop: theme.spacing(0.5),
          },
          "& .splide__pagination__page": {
            width: "8px",
            height: "8px",
          },
          "& .splide__pagination__page.is-active": {
            backgroundColor: theme.palette.primary.main,
            transition: `width ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}, borderRadius ${theme.transitions.duration.standard}s ${theme.transitions.easing.easeInOut}`,
            transform: "none",
            width: "24px",
            borderRadius: "30px",
          },
        },
        carouselItem: {
          display: "flex",
          justifyContent: "center",
        },
        image: {
          width: "320px",
          aspectRatio: "3 / 4",
          objectFit: "cover",
          borderRadius: "16px",
        },
      },
    },
    VuiAnnoucementCard: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(1),
        },
        closeButtonContainer: {
          display: "flex",
          justifyContent: "flex-end",
        },
        closeButton: {
          backgroundColor: "rgba(255, 255, 255, 0.50)",
          borderRadius: "50%",
          padding: 0,
          minWidth: "24px",
          "& .MuiSvgIcon-root": {
            width: "50%",
          },
          cursor: "pointer",
          "&.MuiButtonBase-root:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.50)",
          },
        },
        detailCard: {
          display: "flex",
          padding: theme.spacing(2),
          gap: theme.spacing(1),
          borderRadius: "16px",
        },
        cardContent: {
          padding: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          "&.MuiCardContent-root:last-child": {
            paddingBottom: 0,
          },
        },
        cardMedia: {
          aspectRatio: "1/1",
          width: "30%",
          borderRadius: "8px",
          objectFit: "cover",
        },
        titleContainer: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          color: theme.palette.primary.main,
        },
        locationContainer: {
          display: "flex",
          gap: theme.spacing(1),
        },
        iconWrapper: {
          width: "16px",
          height: "16px",
          "& .MuiSvgIcon-root": {
            height: "100%",
            width: "100%",
          },
        },
      },
    },
    VuiNotificationContainer: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            minWidth: "220px",
          },
        },
        snackbarContent: {
          backgroundColor: "transparent",
          boxShadow: "none",
          flexGrow: "unset",
        },
        annoucementCard: {
          maxWidth: "320px",
        },
      },
    },
  },
})
