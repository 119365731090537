import React from "react"
import { Outlet } from "react-router-dom"
import { styled, Box } from "@mui/material"

import { IndoorMapContainer } from "containers/IndoorMapContainer"
import Div100vh from "react-div-100vh"
import { NotificationContainer } from "containers/NotificationContainer"
import { SearchContainer } from "containers/SearchContainer"

const OutletWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
}))

export const AppLayout = () => {
  return (
    <Div100vh data-name="AppLayout">
      <IndoorMapContainer />
      <OutletWrapper>
        <Outlet />
      </OutletWrapper>
      <SearchContainer />
      <NotificationContainer />
    </Div100vh>
  )
}
