import { IntersectionOptions, useInView } from "react-intersection-observer"

interface IUseInfiniteScrollProps {
  /** Call this function whenever the in view state changes */
  onReachEndScroll: (inView: boolean, entry: IntersectionObserverEntry) => void
  options?: Omit<IntersectionOptions, "onChange">
}

export const useInfiniteScroll = ({
  onReachEndScroll,
  options = {},
}: IUseInfiniteScrollProps) => {
  const [endScrollRef, endScrollInView] = useInView({
    onChange: onReachEndScroll,
    ...options,
  })

  return {
    endScrollRef,
    endScrollInView,
  }
}
