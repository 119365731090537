import { useMemo } from "react"
import _ from "lodash"
import { useGetPromotions, useGetOccupant } from "providers/venue/modules/data"
import { getOccupantDetail } from "utils/feature"
import { IFeature } from "interfaces"

export const useOccupantPromotion = (occupantId: string) => {
  const { data: occupant } = useGetOccupant(occupantId)
  const { data: promotions } = useGetPromotions()

  const {
    name: occupantName,
    logo,
    venueLogo,
  } = useMemo(
    () =>
      occupant
        ? getOccupantDetail(occupant as unknown as IFeature)
        : { name: "", logo: "", venueLogo: "" },
    [occupant]
  )
  const occupantPromotions = useMemo(
    () =>
      promotions.filter(
        (promotion) => promotion.properties.feature?.id === occupantId
      ),
    [promotions, occupantId]
  )

  const parsedPromotions = useMemo(
    () =>
      occupantPromotions.map((promotion) =>
        _.omit(promotion, ["properties.venue"])
      ),
    [occupantPromotions]
  )

  const sortedPromotions = useMemo(
    () =>
      _.orderBy(
        parsedPromotions,
        [
          "properties.is_featured",
          "properties.start_date",
          "properties.end_date",
        ],
        ["desc", "asc", "asc"]
      ),
    [parsedPromotions]
  )

  return { occupantName, logo, venueLogo, promotions: sortedPromotions }
}
