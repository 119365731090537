import { ButtonBase, Typography, useTheme } from "@mui/material"
import { SearchIcon } from "../../icons"

export const SearchToggleButton = ({
  placeholder = "Search",
  onClick = () => {},
  icon: Icon = SearchIcon,
  ...props
}) => {
  const theme = useTheme()
  const styleOverrides =
    theme.components[`VuiSearchToggleButton`]?.styleOverrides || {}
  const { root, searchIcon, label } = styleOverrides
  return (
    <ButtonBase variant="outlined" sx={root} onClick={onClick} {...props}>
      <Icon sx={searchIcon} />
      <Typography sx={label}>{placeholder}</Typography>
    </ButtonBase>
  )
}
