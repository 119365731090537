import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query"
import _ from "lodash"

import {
  IUseInfiniteGetListProps,
  InfiniteQueryFnData,
} from "../../client-query/types"
import { OccupantData } from "providers/venue/types"

import { DEFAULT_PAGINATION, DEFAULT_SORT } from "../../client-query/constants"
import { DEFAULT_FILTER_OPTIONS } from "constant"

import { useGetImdf } from "../useGetImdf"

import {
  createGetListQueryKey,
  createInfiniteGetListQueryFn,
} from "../../client-query/factory"

type TQueryFnData = InfiniteQueryFnData<OccupantData>
type TInfiniteData = InfiniteData<TQueryFnData>

const DEFAULT_QUERY_KEY = ["occupants"]

export const useInfiniteGetOccupants = ({
  pagination = DEFAULT_PAGINATION,
  sort = DEFAULT_SORT,
  filter = {},
  filterOptions = DEFAULT_FILTER_OPTIONS,
  queryKey = DEFAULT_QUERY_KEY,
  ...props
}: IUseInfiniteGetListProps<OccupantData, TQueryFnData, TInfiniteData>) => {
  /**
   * Find cached non-infinite query data; otherwise, retrieve occupant data from useGetImdf.
   */
  const queryClient = useQueryClient()
  const cachedFilteredOccupants = queryClient.getQueryData(
    createGetListQueryKey(queryKey, {
      filter: _.omit(filter, "properties.ordinal"),
      sort,
    })
  )

  const { data: imdfData } = useGetImdf()
  const sourceData = _.isNil(cachedFilteredOccupants)
    ? _.get(imdfData, "occupants", [])
    : _.get(cachedFilteredOccupants, "data", [])
  const resourceKey = createGetListQueryKey([...queryKey, "getInfiniteList"])
  return useInfiniteQuery<TQueryFnData, unknown, TInfiniteData>({
    queryKey: createGetListQueryKey(resourceKey, {
      filter,
      sort,
    }),
    queryFn: createInfiniteGetListQueryFn<OccupantData, TQueryFnData>(
      sourceData,
      {
        pagination,
        sort,
        filter,
        filterOptions,
      }
    ),
    refetchOnWindowFocus: false,
    initialPageParam: 1,
    ...props,
    getPreviousPageParam: (firstPage, pages) => firstPage.prevPage,
    getNextPageParam: (lastPage, pages) => lastPage.nextPage,
  })
}
