import { useParams } from "react-router-dom"
import { Box, Button, useTheme, Typography, Divider, List } from "@mui/material"
import LanguageIcon from "@mui/icons-material/Language"

import { usePromotionDetail } from "hooks/promotion/usePromotionDetail"
import { useTranslation } from "providers/i18n"
import { useAppUI } from "providers/ui"

import { Head } from "components/Head"
import { BackIconButton } from "components/Button"
import { MotionDiv, showContent } from "components/motions"
import { InfoListItem } from "components/ListItem"
import { LocationInfo } from "components/domains/features/LocationInfo"
import { Image } from "components/Image"
import { CalendarIcon, LocationIcon } from "../../components/icons"

import { PageLayout } from "extensions/one-siam/layouts/PageLayout"
import {
  Header,
  HeaderSearchButton,
} from "extensions/one-siam/components/Header"
import { AppNavigation } from "extensions/one-siam/container/AppNavigation"
import { getDurationDate } from "utils/date"

export const PromotionDetail = ({ Layout = PageLayout }) => {
  const theme = useTheme()
  const { goBack } = useAppUI()
  const { t } = useTranslation()
  const params = useParams()
  const { promotionId } = params
  const { promotion, navigateToLocation, openWebsiteLink } =
    usePromotionDetail(promotionId)
  const {
    root,
    button,
    buttonContainer,
    card,
    detailContent,
    title,
    imageWrapper,
    detailWrapper,
    contentSecondaryText,
    contentTextWrapper,
    infoContainer,
    infoListItem,
    divider,
    headerWrapper,
    image,
    linkButton,
    pageWrapper,
    coverBanner,
    contentWrapper,
    navigationWrapper,
  } = theme.components[`PromotionDetailPage`]?.styleOverrides || {}

  if (!promotion) return null

  return (
    <>
      <Head title={`${t(promotion.name)} (${t("Promotion Detail")})`} />
      <Layout
        header={
          <Box sx={headerWrapper}>
            <Header
              title={t("Promotions")}
              backButton={<BackIconButton onClick={goBack} />}
              search={<HeaderSearchButton />}
            />
          </Box>
        }
      >
        <Box sx={root}>
          <Box sx={pageWrapper}>
            {!promotion ? null : (
              <MotionDiv
                animate="animate"
                initial="initial"
                variants={showContent}
              >
                <Box
                  sx={{
                    ...coverBanner,
                    backgroundImage: `url(${promotion.coverImage})`,
                  }}
                />
                <MotionDiv sx={contentWrapper}>
                  <Box sx={card}>
                    <Box sx={imageWrapper}>
                      <Image sx={image} src={promotion.coverImage} />
                    </Box>
                    <Box sx={detailWrapper}>
                      <Box sx={headerWrapper}>
                        <Typography sx={title} variant="title" component={"h2"}>
                          {t(promotion.name)}
                        </Typography>
                      </Box>
                      <Box sx={detailContent}>
                        <List sx={infoContainer}>
                          {promotion.hasLocation && (
                            <InfoListItem
                              sx={infoListItem}
                              icon={<LocationIcon />}
                              primary={
                                <LocationInfo
                                  feature={promotion.feature}
                                  component="span"
                                />
                              }
                            />
                          )}
                          <InfoListItem
                            sx={infoListItem}
                            icon={<CalendarIcon />}
                            primary={getDurationDate(
                              promotion.startDate,
                              promotion.endDate,
                              { monthFormat: "MMMM" }
                            )}
                          />
                        </List>
                        <Divider sx={divider} />

                        <Box sx={contentTextWrapper}>
                          <Typography
                            variant="body2"
                            sx={contentSecondaryText}
                            dangerouslySetInnerHTML={{
                              __html: promotion.descriptionText,
                            }}
                          ></Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </MotionDiv>
              </MotionDiv>
            )}
          </Box>
        </Box>
        <Box sx={navigationWrapper}>
          <Box sx={buttonContainer}>
            {!!promotion.websiteLink && (
              <Button
                variant="outlined"
                sx={linkButton}
                onClick={openWebsiteLink}
                startIcon={<LanguageIcon />}
              ></Button>
            )}
            {promotion.hasLocation && (
              <Button
                variant="contained"
                sx={button}
                onClick={navigateToLocation}
              >
                {t("View Location")}
              </Button>
            )}
          </Box>
          <AppNavigation />
        </Box>
      </Layout>
    </>
  )
}
