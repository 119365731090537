/**
 * Layout: Number & Symbols
 */

const layout = {
  number: [
    "1 2 3 4 5 6 7 8 9 0",
    '- / : ; ( ) ฿ & @ "',
    "{symbol} , . ? ! ' {bksp}",
    "{abc} {space} {enter}",
  ],
  symbol: [
    "[ ] { } # % ^ * + =",
    "_ \\ | ~ < > $ € ¥ ·",
    "{number} , . ? ! ' {bksp}",
    "{abc} {space} {enter}",
  ],
}

export default layout
