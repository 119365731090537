import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
} from "@mui/material"
import { deepmerge } from "@mui/utils"
import CloseIcon from "@mui/icons-material/Close"
import { useTranslation } from "providers/i18n"
import { LocationIcon } from "components/icons"

export const AnnoucementCard = ({
  title,
  image,
  location,
  locationId,
  onClose = () => {},
  sx = {},
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const theme = useTheme()
  const styleOverrides =
    theme.components[`VuiAnnoucementCard`]?.styleOverrides || {}
  const {
    root,
    titleContainer,
    locationContainer,
    cardContent,
    iconWrapper,
    cardMedia,
    detailCard,
    closeButton,
    closeButtonContainer,
  } = styleOverrides

  const handleClick = useCallback(() => {
    if (locationId) {
      navigate(`/maps/place/${locationId}`)
      onClose()
    }
  }, [navigate, locationId, onClose])

  return (
    <Box sx={deepmerge(root, sx)}>
      <Box sx={closeButtonContainer}>
        <Button sx={closeButton} onClick={onClose}>
          <CloseIcon />
        </Button>
      </Box>
      <Card onClick={handleClick} sx={detailCard}>
        {image && <CardMedia sx={cardMedia} component="img" image={image} />}
        <CardContent sx={cardContent}>
          <Box sx={titleContainer}>
            <Typography variant="title">{t(title)}</Typography>
          </Box>
          {location && (
            <Box sx={locationContainer}>
              <Box sx={iconWrapper} color="text.secondary">
                <LocationIcon />
              </Box>
              <Typography variant="subtitle2" color="text.secondary">
                {t(location)}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}
