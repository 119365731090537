import React, { useState, useCallback, useMemo, useRef } from "react"
import { Button, List, ListItemButton, useTheme, Box } from "@mui/material"

import { useTranslation } from "providers/i18n"
import { Dialog } from "../Dialog"

export const OrdinalSelector = (props) => {
  const { levels = [], onChangeOrdinal = () => {}, selectedOrdinal = 0 } = props
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const styleOverrides =
    theme.components[`OrdinalSelector`]?.styleOverrides || {}
  const { button, list, listItemButton, listContainer, root } = styleOverrides
  const { t } = useTranslation()

  const listItemRef = useRef(null)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleChange = useCallback(
    (ordinal) => () => {
      onChangeOrdinal(ordinal)
      setOpen(false)
    },
    [onChangeOrdinal]
  )

  const selectedLevel = useMemo(
    () => levels.find((level) => level.properties?.ordinal === selectedOrdinal),
    [levels, selectedOrdinal]
  )

  const scrollToRef = useCallback(() => {
    if (listItemRef.current)
      listItemRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
  }, [listItemRef])

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleOpen} sx={button}>
        {selectedLevel?.properties?.name?.en}
      </Button>
      <Dialog
        onTransitionEnter={scrollToRef}
        open={open}
        onClose={handleClose}
        title={t("Select Floor")}
      >
        <Box sx={root}>
          <Box sx={listContainer}>
            <List sx={list}>
              {levels.map((level) => (
                <ListItemButton
                  sx={listItemButton}
                  selected={level.properties?.ordinal === selectedOrdinal}
                  ref={
                    level.properties?.ordinal === selectedOrdinal
                      ? listItemRef
                      : null
                  }
                  key={`ordinal-selector-${level.id}`}
                  onClick={handleChange(level.properties.ordinal)}
                >
                  {level.properties.name?.en}
                </ListItemButton>
              ))}
            </List>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  )
}
