import { Snackbar, SnackbarContent, useTheme } from "@mui/material"
import { AnnoucementCard } from "components/domains/annoucement/AnnoucementCard"
import { useNotification } from "hooks/notification/useNotification"

const AUTO_HIDE_DURATION = 6000 // 6s
const DELAY_HIDE_DURATION = AUTO_HIDE_DURATION + 500 // 6.5s

export const NotificationContainer = () => {
  const { activeNotification, handleClose, open } = useNotification({
    delayShowNotification: DELAY_HIDE_DURATION,
  })

  const theme = useTheme()
  const styleOverrides =
    theme.components[`VuiNotificationContainer`]?.styleOverrides || {}
  const { root, snackbarContent, annoucementCard } = styleOverrides
  return (
    <Snackbar
      sx={root}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      onClose={handleClose}
      autoHideDuration={AUTO_HIDE_DURATION}
    >
      <SnackbarContent
        sx={snackbarContent}
        message={
          <AnnoucementCard
            title={activeNotification?.title}
            location={activeNotification?.locationName}
            locationId={activeNotification?.locationId}
            image={activeNotification?.image}
            sx={annoucementCard}
            onClose={handleClose}
          />
        }
      />
    </Snackbar>
  )
}
