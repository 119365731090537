import { useMemo } from "react"
import { Typography, Box, useTheme } from "@mui/material"
import { useTranslation } from "providers/i18n"
import { Link } from "react-router-dom"
import { EmptyData } from "components/EmptyData"
import { CarouselItem } from "components/Carousel"
import { HorizontalSlide, VerticalEventCard } from "./index"
import { CalendarStarIcon } from "../../icons"
import { getIcon } from "utils/icon"

export const CurrentEventsList = (props) => {
  const { events } = props
  const { t } = useTranslation()

  const theme = useTheme()
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.events) || <CalendarStarIcon />,
    [theme]
  )
  const { styleOverrides } = theme.components?.[`EventPage`] || {}
  const { currentEventsHeading, emptyDataWrapper } = styleOverrides
  return (
    <Box>
      <Box sx={currentEventsHeading}>
        <Typography variant="heading" component="div">
          {t("Current Events")}
        </Typography>
      </Box>
      {events && events.length > 0 ? (
        <HorizontalSlide>
          {events.map((event) => {
            const sponsoredId = event?.properties?.sponsoredId
            return (
              <CarouselItem key={`${sponsoredId || ""}${event.id}`}>
                <Link to={`/events/${event.id}`}>
                  <VerticalEventCard dense={true} event={event} />
                </Link>
              </CarouselItem>
            )
          })}
        </HorizontalSlide>
      ) : (
        <Box sx={emptyDataWrapper}>
          <EmptyData
            icon={emptyIcon}
            primary={t("No Current Events")}
            secondary={t("Check Back Soon")}
          />
        </Box>
      )}
    </Box>
  )
}
