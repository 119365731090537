import { useEffect, useRef } from "react"

interface IUseInterval {
  callback: () => void
  delay: number | null
  options?: {
    enable?: boolean
  }
}

export const useInterval = ({
  callback,
  delay,
  options = {},
}: IUseInterval) => {
  // Creating a ref
  const savedCallback = useRef<() => void | null>(null)
  const { enable = true } = options

  // To remember the latest callback .
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // combining the setInterval and
  //clearInterval methods based on delay.
  useEffect(() => {
    function func() {
      savedCallback.current()
    }
    if (enable && delay !== null) {
      const interval = setInterval(func, delay)
      return () => clearInterval(interval)
    }
  }, [enable, delay])
  return {}
}
