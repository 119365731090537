import React, { useCallback, useEffect } from "react"

import { useTheme, Box } from "@mui/material"
import _ from "lodash"

import { useVenue } from "providers/venue"
import { useTranslation } from "providers/i18n"
import { useAppUI } from "providers/ui"

import { OrdinalSelector } from "components/OrdinalSelector"
import { OrdinalSelectMenu } from "components/OrdinalSelector/OrdinalSelectMenu"
import { VenueSelector } from "components/VenueSelector"
import { SearchToggleButton } from "components/domains/search/SearchToggleButton"
import { FeatureChipToggleBar } from "../../../components/domains/features/ChipToggleBar"
import { PageCard } from "components/card"
import { AppNavigation } from "containers/kiosk-h/AppNavigation"
import { NavigationControl } from "components/kioskHorizontal/navigation"
import { useMap } from "hooks/map/useMap"
import { useHighLightFeaturesMap } from "hooks/feature/useHighLightFeaturesMap"

import { pushDataLayer } from "utils/googletagmanager/pushDataLayer"
import { GTM_AMENITY_SEARCH } from "constant"
import { pluralize } from "utils/string"

export const FeatureByCategoryKioskHPage = ({ Layout = Box }) => {
  const { t } = useTranslation()
  const { openSearch, isKioskMode } = useAppUI()

  const { venues, isMultiOrdinalVenue, isMultiVenueProject, dataLoaded } =
    useVenue()

  const {
    viewingVenueId,
    levelInVenue,
    quickSearchCategories,
    quickSearchFeatures,
    viewingOrdinal,
    findNearestOrdinal,
    searchParams,
    comingFromOrdinal,
    category,
    locationVenueId,
    onChangeVenue,
    setSearchParams,
    toggleQuickSearch,
  } = useMap()

  const theme = useTheme()
  const { styleOverrides = {} } = theme.components[`AppMap`] || {}
  const {
    searchBarContainer,
    quickLinkContainer, //TODO:: planning about quick search's working flow later
    venueSelector,
    ordinalSelectMenu,
    ordinalSelector,
  } = styleOverrides
  const { styleOverrides: bannerStyleOverrides = {} } =
    theme.components[`AppBanner`] || {}
  const { root } = bannerStyleOverrides
  const sideBarStyleOverrides =
    theme.components[`AppSidebar`]?.styleOverrides || {}

  const { wrapper, body, footer } = sideBarStyleOverrides

  const onClickOrdinal = useCallback(
    (ordinal) => {
      const currentParams = Object.fromEntries(searchParams.entries())
      setSearchParams({ ...currentParams, ordinal })
    },
    [setSearchParams, searchParams]
  )
  const handleClickQuickSearch = useCallback(
    (type, amenityCategory) => {
      const pluralType = pluralize(type)
      toggleQuickSearch(pluralType, amenityCategory)
    },
    [toggleQuickSearch]
  )

  useEffect(() => {
    // When ordinal navigate from different page
    if (viewingVenueId && dataLoaded && !_.isNil(comingFromOrdinal)) {
      const nearestOrdinal = findNearestOrdinal(comingFromOrdinal)
      setSearchParams(
        { venue: viewingVenueId, ordinal: nearestOrdinal },
        { replace: true }
      )
    } else if (viewingVenueId && dataLoaded && _.isNil(viewingOrdinal)) {
      // When ordinal is not provided
      const nearestOrdinal = findNearestOrdinal(0)
      setSearchParams(
        { venue: viewingVenueId, ordinal: nearestOrdinal },
        { replace: true }
      )
    }
  }, [
    dataLoaded,
    category,
    comingFromOrdinal,
    viewingVenueId,
    viewingOrdinal,
    findNearestOrdinal,
    setSearchParams,
  ])

  useHighLightFeaturesMap(quickSearchFeatures, Number(viewingOrdinal))

  useEffect(() => {
    if (category) {
      pushDataLayer({
        event: GTM_AMENITY_SEARCH,
        amenity_category: category,
      })
    }
  }, [category])

  return (
    <Layout
      aside={
        <PageCard>
          <Box sx={wrapper}>
            <Box sx={root}></Box>
            <Box sx={body}>
              <AppNavigation />
            </Box>
            <Box sx={footer}>
              <NavigationControl BackButtonProps={{ hidden: true }} />
            </Box>
          </Box>
        </PageCard>
      }
    >
      <Box sx={searchBarContainer}>
        {/* TODO: Find better solution for amenity quicksearch search drawer */}
        {!isKioskMode && (
          <SearchToggleButton
            placeholder={t("Search for your destination")}
            onClick={openSearch}
          />
        )}
      </Box>

      <Box sx={quickLinkContainer}>
        <FeatureChipToggleBar
          categories={quickSearchCategories}
          onClick={handleClickQuickSearch}
        />
      </Box>
      {isMultiOrdinalVenue && (
        <Box sx={ordinalSelector}>
          <OrdinalSelector
            selectedOrdinal={Number(viewingOrdinal)}
            levels={levelInVenue}
            onClickOrdinal={onClickOrdinal}
          />
        </Box>
      )}
      {isMultiOrdinalVenue && (
        <Box sx={ordinalSelectMenu}>
          <OrdinalSelectMenu
            selectedOrdinal={Number(viewingOrdinal)}
            levels={levelInVenue}
            onChangeOrdinal={onClickOrdinal}
          />
        </Box>
      )}

      {isMultiVenueProject && (
        <Box sx={venueSelector}>
          <VenueSelector
            selectedVenue={viewingVenueId}
            venues={venues}
            onClickVenue={onChangeVenue}
            currentVenue={locationVenueId}
          />
        </Box>
      )}
    </Layout>
  )
}
