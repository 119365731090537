import _ from "lodash"

const ALLOWED_OPERATIONS = [
  "$or",
  "$and",
  "$eq",
  "$ne",
  "$in",
  "$nin",
  "$lt",
  "$lte",
  "$gt",
  "$gte",
  "$exists",
  "$elemMatch",
]

const DEFAULT_OPTIONS = {
  parentKey: "",
  skipKeys: [],
}

export type FlattenObjectOptions = { parentKey?: string; skipKeys?: string[] }
export type FlattenObject = <TObj = any>(
  obj: TObj,
  options?: FlattenObjectOptions
) => { [k: string]: any }

export type TraverseQueryFilterOptions = {
  sanitizeValues?: string[]
}
/**
 * Flattens a nested object into a single-level object with concatenated keys.
 *
 * Example:
 * Input: { id: "111", properties: { location: { $in: ["Costa Rica", "Brazil"] } } }
 * Output: { id: "111", "properties.location": { $in: ["Costa Rica", "Brazil"] } }
 */
export const flattenObject: FlattenObject = (obj, options = {}) => {
  const { parentKey = "", skipKeys = [] } = _.merge(
    {},
    DEFAULT_OPTIONS,
    options
  )
  const flattenedObject = {}

  for (const key in obj) {
    const value = _.get(obj, key)

    // Skip inherited properties
    if (!obj.hasOwnProperty(key)) continue

    // Concatenate parent key and current key
    const flatKey = parentKey ? `${parentKey}.${key}` : key

    // Check if current key is a skip key; recursively flatten next nested level object (if any).
    if (skipKeys.includes(key)) {
      Object.assign(flattenedObject, {
        [parentKey]: {
          [key]: _.isPlainObject(value)
            ? flattenObject(value, { skipKeys })
            : value,
        },
      })
      return flattenedObject
    }

    // Check if current value is an object
    if (_.isPlainObject(value)) {
      Object.assign(
        flattenedObject,
        flattenObject(value, { parentKey: flatKey, skipKeys })
      )
    } else {
      flattenedObject[flatKey] = value
    }
  }

  return flattenedObject
}

/**
 * Issue with nested queries
 * Reference: https://github.com/crcn/sift.js/issues/245#issuecomment-1296023087
 * */
export const traverseQueryFilters = <TObj = any>(
  filters: TObj,
  options: TraverseQueryFilterOptions = {}
) => {
  const { sanitizeValues = [] } = options

  // # Step 1: Flatten nested filter object.
  const flattenFilterObject = flattenObject<TObj>(filters, {
    skipKeys: ALLOWED_OPERATIONS,
  })

  // # Step 2: Transform flattened object into array of conditions.
  const flattenedFilterConditions = Object.keys(flattenFilterObject).map(
    (key) => ({ [key]: flattenFilterObject[key] })
  )

  // # Step 3: Sanitize values from the array of conditions for use with $and operator.
  const sanitizedFilterConditions = flattenedFilterConditions.filter(
    (filterObj) => {
      const value = _.first(Object.values(flattenObject(filterObj)))
      const isNotSanitizeValue = !sanitizeValues.includes(`${value}`)
      return !_.isUndefined(value) && isNotSanitizeValue
    }
  )

  // Return an empty object if no filter is provided.
  if (sanitizedFilterConditions.length === 0) return {}

  return {
    $and: sanitizedFilterConditions,
  }
}
