import { Link } from "react-router-dom"
import {
  useTheme,
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material"

import { LanguageSelector } from "./LanguageSelector"
import { CloseIconButton } from "../Button"
import { useTranslation } from "providers/i18n"

export const Sidebar = ({
  navigations = [],
  onClose = () => {},
  open = false,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const logo = theme.assets?.[`sidebarLogo`] || theme.assets?.[`logo`]

  const { styleOverrides = {} } = theme.components[`AppSidebar`]
  const {
    root,
    header,
    closeButton,
    body,
    footer,
    footerLogo,
    navList,
    navListItem,
    navDivider,
    navListWrapper,
    languageSelectorWrapper,
  } = styleOverrides
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="left"
      PaperProps={{ sx: root }}
    >
      <CloseIconButton sx={closeButton} onClick={onClose} />

      {/** Header */}
      <Box sx={header}></Box>

      {/** Body */}
      <Box sx={body}>
        {/** Navigation */}
        <List sx={navList} disablePadding>
          {navigations.map((nav, index) => (
            <Box key={nav.to} sx={navListWrapper}>
              <ListItemButton component={Link} to={nav.to} sx={navListItem}>
                {nav.icon && <ListItemIcon>{nav.icon}</ListItemIcon>}
                {/* //i18n translation fallback */}
                <ListItemText
                  primary={t([`navigation.${nav.label}`, nav.label])}
                />
              </ListItemButton>
              <Divider sx={navDivider} />
            </Box>
          ))}
          <Box sx={languageSelectorWrapper}>
            <LanguageSelector onSwitchedLanguage={onClose} />
          </Box>
        </List>
      </Box>

      {/** Footer */}
      <Box sx={footer}>
        {logo && (
          <Box sx={footerLogo}>
            <img src={logo.url} alt={logo.caption} />
          </Box>
        )}
      </Box>
    </Drawer>
  )
}
