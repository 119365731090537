import { useEffect, useMemo } from "react"
import { Box, Button, IconButton, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { get } from "lodash"

import { GTM_COUNT_KIOSK_USERS } from "constant"
import { useTranslation } from "providers/i18n"
import { useHome } from "hooks/home/useHome"
import { useHomeMap } from "hooks/home/useHomeMap"
import { useElectronConfig } from "hooks/useElectronConfig"

import { FullScreenOverlayLayout } from "layouts/FullScreenOverlayLayout"
import { Head } from "components/Head"
import { Image } from "components/Image"
import { NearMeIcon, TabIcon } from "components/icons"

import { pushDataLayer } from "utils/googletagmanager/pushDataLayer"
import { getIcon } from "utils/icon"

export const HomePage = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { venues } = useHome()
  const navigate = useNavigate()
  useHomeMap(venues)

  const { config } = useElectronConfig()

  const footerImageSrc = get(config, "venue_home_footer_image", "")

  const startButtonIcon = useMemo(
    () => getIcon(theme?.assets?.startIcon) || <TabIcon />,
    [theme]
  )
  const styleOverrides = theme.components[`HomePage`]?.styleOverrides || {}
  const {
    root,
    actionContainer,
    actionButton,
    banner,
    contentContainer,
    fullscreenLayout,
    touchIconButton,
    welcomeContainer,
  } = styleOverrides

  const handleClickStart = () => {
    if (window.electron) {
      window.electron.sendMessage("setHomeScreenMode", { enable: false })
    }
    pushDataLayer({
      event: GTM_COUNT_KIOSK_USERS,
      count_by_event: GTM_COUNT_KIOSK_USERS,
    })
    navigate("/maps")
  }

  useEffect(() => {
    if (window.electron) {
      window.electron.sendMessage("setHomeScreenMode", { enable: true })
    }
  }, [])

  return (
    <>
      <Head title={t("Indoor map")} />
      <Box sx={root} className="home-page">
        <FullScreenOverlayLayout sx={fullscreenLayout}>
          <Box
            sx={contentContainer}
            className="contentContainer"
            onClick={handleClickStart}
          >
            <Box sx={welcomeContainer}>
              <Box sx={actionContainer}>
                <Button sx={actionButton} variant="contained">
                  <NearMeIcon />
                  {t("Touch to start")}
                </Button>
              </Box>
            </Box>
            {footerImageSrc ? (
              <Image src={footerImageSrc} sx={banner} />
            ) : (
              <Box sx={banner} />
            )}
            <IconButton sx={touchIconButton}>{startButtonIcon}</IconButton>
          </Box>
        </FullScreenOverlayLayout>
      </Box>
    </>
  )
}
