import { IPageInfo, IPaginationPayload } from "../types"

export type GeneratePageInfo = <TRecord = any>(
  data: TRecord[],
  pagination: IPaginationPayload
) => IPageInfo

export type GetSliceIndexesByPagination = (pagination: IPaginationPayload) => {
  startIndex: number
  endIndex: number
}

export const generatePageInfo: GeneratePageInfo = (
  data = [],
  pagination
): IPageInfo => {
  const { page, perPage } = pagination || {}
  const totalPage = Math.ceil(data.length / perPage)
  const nextPage = page + 1
  const prevPage = page - 1
  const hasPreviousPage = page > 1
  const hasNextPage = page < totalPage

  return {
    nextPage: hasNextPage ? nextPage : null,
    prevPage: hasPreviousPage ? prevPage : null,
    hasPreviousPage,
    hasNextPage,
    totalPage,
  }
}

export const getSliceIndexesByPagination: GetSliceIndexesByPagination = (
  pagination
) => {
  const { page, perPage } = pagination || {}
  const startIndex = (page - 1) * perPage
  const endIndex = page * perPage
  return {
    startIndex,
    endIndex,
  }
}
