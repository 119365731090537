import { PhoneIcon } from "components/icons"
import { Button } from "@mui/material"
import { useTranslation } from "providers/i18n"

export const CallButton = ({ phoneNumber, label = "Call", ...props }) => {
  const { t } = useTranslation()

  return (
    <Button
      variant="outlined"
      target="_top"
      rel="noopener noreferrer"
      href={`tel:${phoneNumber}`}
      startIcon={<PhoneIcon />}
      {...props}
    >
      {t(label)}
    </Button>
  )
}
