import { useMemo, useCallback } from "react"
import { Box, ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"

import { usePromotion } from "hooks/promotion/usePromotion"

import { useTranslation } from "providers/i18n"

import { AppNavigation } from "containers/kiosk-v"
import { EmptyData } from "components/EmptyData"
import { PercentIcon } from "components/icons"
import { Carousel } from "components/Carousel/Carousel"
import { SearchToggleButton } from "components/domains/search"
import { CarouselItem } from "components/Carousel"
import { Head } from "components/Head"
import { Image } from "components/Image"
import { MotionDiv, showContent } from "components/motions"

import { getIcon } from "utils/icon"
import { FooterAction } from "extensions/one-siam/components/domains/navigation/FooterAction"
import { SearchIcon } from "extensions/one-siam/components/icons"

export const PromotionsKioskVPage = ({ Layout = Box }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { groupPromotions, activeGroupPromotion, handleChange } = usePromotion()

  const theme = useTheme()
  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.promotions) || <PercentIcon />,
    [theme]
  )

  const { styleOverrides } = theme.components?.[`PromotionPage`] || {}
  const {
    root,
    carouselContainer,
    carousel,
    emptyDataContainer,
    navigationWrapper,
    toolbarContainer,
    searchBarContainer,
    promotionImage,
    mainMenuContainer,
    footerActionContainer,
    promotionGroupSelectorButton,
    toggleButton,
    toggleButtonGroup,
  } = styleOverrides

  const handleClickSlide = useCallback(
    (_splide, { slide }, _promotion) => {
      navigate(slide.getAttribute("link"))
    },
    [navigate]
  )
  const activePromotions = groupPromotions[activeGroupPromotion]
  const slidePerPage = 3
  const carouselType =
    activePromotions && activePromotions.length < slidePerPage
      ? "slide"
      : "loop"
  return (
    <>
      <Head title={t("Promotions")} />
      <Layout>
        <Box sx={root}>
          <Box>
            <MotionDiv
              variants={showContent}
              initial="initial"
              animate="animate"
              sx={carouselContainer}
            >
              {!activePromotions || activePromotions.length === 0 ? (
                <Box sx={emptyDataContainer}>
                  <EmptyData icon={emptyIcon} primary={t("No promotion")} />
                </Box>
              ) : (
                <Carousel
                  onClick={handleClickSlide}
                  options={{
                    type: carouselType,
                    perPage: slidePerPage,
                    pagination: true,
                    focus: "center",
                    autoplay: true,
                    gap: "0",
                  }}
                  sx={carousel}
                >
                  {groupPromotions[activeGroupPromotion].map((promotion) => {
                    const sponsoredId = promotion?.properties?.sponsoredId
                    const coverImageSrc = promotion.properties.cover_image?.url
                    return (
                      <CarouselItem
                        link={`/promotions/${promotion.id}`}
                        key={`${sponsoredId || ""}${promotion.id}`}
                      >
                        <Image src={coverImageSrc} sx={promotionImage} />
                      </CarouselItem>
                    )
                  })}
                </Carousel>
              )}
              <Box sx={promotionGroupSelectorButton}>
                <ToggleButtonGroup
                  value={activeGroupPromotion}
                  onChange={handleChange}
                  exclusive
                  sx={toggleButtonGroup}
                >
                  <ToggleButton value="current" sx={toggleButton}>
                    {t("Current Promotions")}
                  </ToggleButton>
                  <ToggleButton value="upcoming" sx={toggleButton}>
                    {t("Upcoming Promotions")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </MotionDiv>
          </Box>
          <Box key="app-navigation" sx={navigationWrapper}>
            <Box sx={toolbarContainer}>
              <Box sx={searchBarContainer}>
                <SearchToggleButton
                  icon={SearchIcon}
                  placeholder={t("Search shops and more...")}
                  LinkComponent={Link}
                  to="/search"
                />
              </Box>
            </Box>
            <Box sx={mainMenuContainer}>
              <AppNavigation />
              <FooterAction sx={footerActionContainer} />
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  )
}
