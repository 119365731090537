import { ListIcon } from "components/icons"
import { Button, useTheme } from "@mui/material"
import { useTranslation } from "providers/i18n"
import { useMemo } from "react"
import { getIcon } from "utils/icon"

export const StepButton = ({ label = "Step", ...props }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const icon = useMemo(
    () => getIcon(theme?.assets?.navigation?.step) || <ListIcon />,
    [theme]
  )

  return (
    <Button variant="outlined" startIcon={icon} {...props}>
      {t(label)}
    </Button>
  )
}
