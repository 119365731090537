import { FC, ReactNode } from "react"
import { useCallback } from "react"
import { ShareIcon } from "components/icons"
import { IconButton, IconButtonProps } from "@mui/material"

interface IShareIconButton extends IconButtonProps {
  title?: string
  text?: string
  url?: string
  onClick?: () => void
  shareIcon?: ReactNode
}

export const ShareIconButton: FC<IShareIconButton> = ({
  title,
  text,
  url,
  onClick = () => {},
  shareIcon = <ShareIcon />,
  ...props
}) => {
  const isSharebale = !!navigator.share
  const handleClick = useCallback(async () => {
    onClick()
    if (navigator.share) {
      try {
        await navigator.share({ title, text, url })
        alert("Share successfull")
      } catch (err) {
        console.log("Error: ", err)
      }
    } else {
      console.log("Native Web Sharing not supported")
    }
  }, [onClick, text, title, url])
  return (
    isSharebale && (
      <IconButton color="primary" onClick={handleClick} {...props}>
        {shareIcon}
      </IconButton>
    )
  )
}
