import React, { ReactNode } from "react"
import {
  Typography,
  useTheme,
  Box,
  ToggleButton,
  ToggleButtonProps,
} from "@mui/material"
import { deepmerge } from "@mui/utils"

interface ICategoryFilterButtonProps extends ToggleButtonProps {
  Icon?: ReactNode
}

export const CategoryFilterButton: React.FC<ICategoryFilterButtonProps> = ({
  children,
  Icon,
  value,
  sx = {},
  ...props
}) => {
  const theme = useTheme()
  const { styleOverrides = {} } =
    theme.components?.[`AppCategoryFilterButton`] || {}
  const { root, label, icon } = styleOverrides

  return (
    <ToggleButton sx={deepmerge(root, sx)} value={value} {...props}>
      {Icon && <Box sx={icon}>{Icon}</Box>}
      <Typography sx={label}>{children}</Typography>
    </ToggleButton>
  )
}
