import { useCallback, useMemo } from "react"
import { Link, useParams } from "react-router-dom"
import { useTranslation } from "providers/i18n"

import {
  Box,
  CardContent,
  Fab,
  List,
  Typography,
  useTheme,
  Divider,
  Stack,
  Button,
  CardMedia,
} from "@mui/material"

import { useEventDetail } from "hooks/event/useEventDetail"

import { AppNavigation } from "containers/kiosk-v"

import { MotionDiv, showContent } from "components/motions"
import { LanguageButton } from "components/Language"
import { SearchToggleButton } from "components/domains/search"
import { CalendarIcon, LocationIcon } from "../../components/icons"
import { Head } from "components/Head"
import { InfoListItem } from "components/ListItem"
import { LocationInfo } from "components/domains/features/LocationInfo"
import { getIcon } from "utils/icon"
import { getDurationDate } from "utils/date"
import { QRCode } from "components/QRCode"
import { BackIcon } from "components/icons"
import { useAppUI } from "providers/ui"
import { FooterAction } from "extensions/one-siam/components/domains/navigation/FooterAction"
import { SearchIcon } from "extensions/one-siam/components/icons"

export const EventDetailKioskVPage = ({ Layout = Box }) => {
  const params = useParams()
  const { eventId } = params
  const { t } = useTranslation()
  const { event, eventDetailMobileUrl, navigateToLocation } =
    useEventDetail(eventId)
  const { goBack } = useAppUI()

  const theme = useTheme()
  const calendarIcon = useMemo(
    () => getIcon(theme?.assets?.calendarIcon) || <CalendarIcon />,
    [theme]
  )
  const styleOverrides = theme.components[`VuiDetailCard`]?.styleOverrides || {}
  const {
    root,
    actionButton,
    actionContainer,
    backButton,
    backButtonWrapper,
    buttonContainer,
    button,
    cardContentTextWrapper,
    cardMediaWrapper,
    card,
    cardContent,
    cardHeader,
    cardContentSecondaryText,
    contentWrapper,
    coverBanner,
    detailWrapper,
    divider,
    image,
    infoContainer,
    infoListItem,
    headerWrapper,
    navigationWrapper,
    pageWrapper,
    qrCodeContainer,
    qrCode,
    qrcodePrimaryText,
    qrCodeSecondaryText,
    searchBarContainer,
    toolbarContainer,
    mainMenuContainer,
    footerActionContainer,
  } = styleOverrides

  const handleClickBack = useCallback(() => {
    goBack()
  }, [goBack])

  const backIcon = useMemo(
    () => getIcon(theme?.assets?.backIcon) || <BackIcon />,
    [theme]
  )

  return (
    <>
      <Head title={`${t(event?.name || "")} (${t("Event Detail")})`} />
      <Layout>
        <Box sx={root}>
          <MotionDiv
            variants={showContent}
            initial="initial"
            animate="animate"
            sx={pageWrapper}
          >
            {!event ? null : (
              <>
                <Box
                  sx={{
                    ...coverBanner,
                    backgroundImage: `url(${event.coverImage})`,
                  }}
                />
                <MotionDiv sx={contentWrapper}>
                  <Box sx={card}>
                    <Box sx={cardMediaWrapper}>
                      <CardMedia
                        component="img"
                        sx={image}
                        src={event.coverImage}
                      />
                    </Box>
                    <Box sx={detailWrapper}>
                      <Box sx={headerWrapper}>
                        <Typography
                          sx={cardHeader}
                          variant="title"
                          component={"h2"}
                        >
                          {t(event.name)}
                        </Typography>
                      </Box>
                      <CardContent sx={cardContent}>
                        <Stack direction="row">
                          <List sx={infoContainer}>
                            {event.hasLocation && (
                              <InfoListItem
                                sx={infoListItem}
                                icon={<LocationIcon />}
                                primary={
                                  <LocationInfo
                                    feature={event.feature}
                                    component="span"
                                  />
                                }
                              />
                            )}
                            <InfoListItem
                              sx={infoListItem}
                              icon={calendarIcon}
                              primary={getDurationDate(
                                event.startDate,
                                event.endDate,
                                { monthFormat: "MMMM" }
                              )}
                            />
                          </List>
                        </Stack>
                        <Divider sx={divider} />
                        <Box sx={cardContentTextWrapper}>
                          <Typography
                            variant="body2"
                            sx={cardContentSecondaryText}
                            dangerouslySetInnerHTML={{
                              __html: event.descriptionText,
                            }}
                          ></Typography>
                        </Box>
                        <Box sx={buttonContainer}>
                          {event.hasLocation && (
                            <Button
                              variant="contained"
                              sx={button}
                              onClick={navigateToLocation}
                            >
                              {t("View Location")}
                            </Button>
                          )}
                        </Box>
                      </CardContent>
                    </Box>
                  </Box>
                  <Box sx={qrCodeContainer} className="target">
                    <QRCode sx={qrCode} url={eventDetailMobileUrl} />
                    <Stack>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        sx={qrcodePrimaryText}
                      >
                        {t("Scan to view")}
                      </Typography>
                      <Typography
                        variant="caption"
                        component="p"
                        sx={qrCodeSecondaryText}
                      >
                        {t("On mobile")}
                      </Typography>
                    </Stack>
                  </Box>
                </MotionDiv>
              </>
            )}
          </MotionDiv>
          <Box key="app-navigation" sx={navigationWrapper}>
            <Box sx={backButtonWrapper}>
              <Fab variant="rounded" sx={backButton} onClick={handleClickBack}>
                {backIcon}
              </Fab>
            </Box>
            <Box sx={toolbarContainer}>
              <Box sx={actionContainer}>
                <LanguageButton variant="contained" sx={actionButton} />
                {/* //TODO:: Add a wheel chair's mode handler function later */}
                {/*  <Button
                  variant="contained"
                  sx={actionButton}
                  onClick={() => {}}
                  startIcon={<RestroomWheelchairIcon />}
                /> */}
              </Box>
              <Box sx={searchBarContainer}>
                <SearchToggleButton
                  icon={SearchIcon}
                  placeholder={t("Search shops and more...")}
                  LinkComponent={Link}
                  to="/search"
                />
              </Box>
            </Box>
            <Box sx={mainMenuContainer}>
              <AppNavigation />
              <FooterAction sx={footerActionContainer} />
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  )
}
