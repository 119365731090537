import { Box, useTheme, Typography } from "@mui/material"
import { deepmerge } from "@mui/utils"

import { useTranslation } from "providers/i18n"

import { Carousel, CarouselItem } from "components/Carousel"
import { AdSuggestCard } from "./AdSuggestCard"

export const AdSuggestList = ({
  suggestedListItems = [],
  onClickSuggestedItem,
  sx = {},
}) => {
  const { t } = useTranslation({ defaultValue: null })
  // Theme & Styling
  const theme = useTheme()
  const { root, title, carousel, carouselItem, card } =
    theme.components[`AdSuggestList`]?.styleOverrides || {}

  return (
    <Box sx={deepmerge(root, sx)}>
      <Typography sx={title} component="h6">
        {t("Recommend")}
      </Typography>
      <Carousel
        onClick={onClickSuggestedItem}
        options={{
          type: "slide",
          pagination: true,
          autoplay: true,
          interval: 5000,
          gap: "20px",
          padding: "auto",
          perPage: 5,
          autoWidth: true,
        }}
        sx={carousel}
      >
        {suggestedListItems.map((item) => (
          <CarouselItem
            sx={carouselItem}
            link={`/maps/place/${item.feature_id}`}
            key={item.id}
          >
            <AdSuggestCard sx={card} data={item.feature} />
          </CarouselItem>
        ))}
      </Carousel>
    </Box>
  )
}
