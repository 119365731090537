import { FeatureCollection } from "@turf/helpers"
import { sendCaptureExceptionToSentry } from "utils/sentry"
import axios from "../../../config/connections" // TODO: Should move to inside provider
import config from "config"

const appType = config("appType")

export const removeDoubleSlashes = (path: string) => path.replace("//", "/")

export const sanitizePublicPath = (path: string) => {
  if (!path) return
  const basePath = appType === "webapp" ? "" : "./"
  return removeDoubleSlashes(`${basePath}/${path}`)
}

export const fetchPublicData = (path: string) => {
  const sanitizedPath = sanitizePublicPath(path)
  return fetch(sanitizedPath).then((res) => res.json())
}

const get = async <T>(endpoint: string): Promise<T> => {
  try {
    const res = await axios.get(endpoint)
    if (res.status !== 200) {
      throw new Error()
    }
    return res.data as T
  } catch (err) {
    throw err.message
  }
}

export async function fetchGeojson<T>(type) {
  try {
    const path = `/imdf/${type}.geojson`
    const jsonRes = await get<FeatureCollection>(path)
    return { type, data: jsonRes.features as T[] }
  } catch (error) {
    sendCaptureExceptionToSentry({
      error,
      level: "error",
      transactionName: `Failed to fetch data from /imdf/${type}.geojson endpoint.`,
    })

    // Return empty array if error
    return { type, data: [] }
  }
}
