import React from "react"
import { Box, Slider, SliderProps, useTheme } from "@mui/material"

interface VerticalLinearProgressProps
  extends Pick<SliderProps, "sx" | "value"> {}

const preventHorizontalKeyboardNavigation = (event: React.KeyboardEvent) => {
  if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
    event.preventDefault()
  }
}

export const VerticalLinearProgress: React.FC<VerticalLinearProgressProps> = ({
  sx,
  ...props
}) => {
  const theme = useTheme()
  const { root, slider } =
    theme.components["VerticalLinearProgress"]?.styleOverrides || {}

  return (
    <Box sx={root}>
      <Slider
        sx={slider}
        color="primary"
        orientation="vertical"
        defaultValue={0}
        slotProps={{
          thumb: {
            className: "Mui-focusVisible",
          },
        }}
        onKeyDown={preventHorizontalKeyboardNavigation}
        {...props}
      />
    </Box>
  )
}
