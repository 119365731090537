import { useCallback, useEffect, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { Socket, io } from "socket.io-client"

import config from "config"
import { Promotion, Event } from "providers/venue/types"

const SERVER_URL = config("host")
const apiKey = config("realtimeApiKey")
const { origin } = new URL(SERVER_URL)

const deliveryContentSocket = io(`${origin}/broadcast`, {
  path: `/ws`,
  auth: {
    token: apiKey,
  },
  autoConnect: false,
  reconnection: Boolean(apiKey),
})

export interface IUseDeliveryContentSocketIOResult {
  isConnected: boolean
  subscribe: (event: any, callback: any) => void
  unsubscribe: (event: any, callback: any) => void
  deliveryContentSocket: Socket
}

export type UseDeliveryContentSocketIO = (options?: {
  enable?: boolean
}) => IUseDeliveryContentSocketIOResult

const updateEntries = (updatedEntry) => (oldEntries) => {
  let updatedRecordsTotal = 0
  const updatedEntries = oldEntries.map((oldEntry) => {
    if (oldEntry.id === updatedEntry.id) {
      updatedRecordsTotal++
      return updatedEntry
    }
    return oldEntry
  })
  return updatedRecordsTotal > 0
    ? updatedEntries
    : [...updatedEntries, updatedEntry]
}

const removeEntries = (updatedEntry) => (oldEntries) => {
  return oldEntries.filter((oldEntry) => oldEntry.id !== updatedEntry.id)
}

const isPublishedData = (updatedEntry) =>
  !!updatedEntry?.properties?.publishedAt

export const useDeliveryContentSocketIO: UseDeliveryContentSocketIO = ({
  enable = true,
} = {}) => {
  const queryClient = useQueryClient()

  const [isConnected, setIsConnected] = useState(
    deliveryContentSocket.connected
  )

  const subscribe = useCallback((event, callback) => {
    deliveryContentSocket.on(event, callback)
  }, [])

  const unsubscribe = useCallback((event, callback) => {
    deliveryContentSocket.off(event, callback)
  }, [])

  useEffect(() => {
    const onConnect = () => {
      setIsConnected(true)
      console.log(`[DeliveryContentSocket]: connected`)
    }

    const onDisconnect = () => {
      setIsConnected(false)
      console.log(`[DeliveryContentSocket]: disconnected`)
    }

    const onConnectError = () => {
      console.warn(
        '[DeliveryContentSocket] Fail to connect realtime service, please check if REACT_APP_REALTIME_API_KEY exists and support "realtime"'
      )
    }

    const onEventUpdate = (data) => {
      const isPublish = isPublishedData(data)
      if (isPublish) {
        queryClient.setQueryData<Event>(["events", data.id], data)
      } else {
        queryClient.removeQueries({
          queryKey: ["events", data.id],
        })
      }
      queryClient.setQueryData<Event[]>(
        ["events"],
        isPublish ? updateEntries(data) : removeEntries(data)
      )
    }
    const onPromotionUpdate = (data) => {
      const isPublish = isPublishedData(data)
      if (isPublish) {
        queryClient.setQueryData<Promotion>(["promotions", data.id], data)
      } else {
        queryClient.removeQueries({
          queryKey: ["promotions", data.id],
        })
      }
      queryClient.setQueryData<Promotion[]>(
        ["promotions"],
        isPublish ? updateEntries(data) : removeEntries(data)
      )
    }

    deliveryContentSocket.on("connect", onConnect)
    deliveryContentSocket.on("connect_error", onConnectError)
    deliveryContentSocket.on("disconnect", onDisconnect)

    // Listening to event from server
    deliveryContentSocket.on("event:update", onEventUpdate)
    deliveryContentSocket.on("promotion:update", onPromotionUpdate)

    return () => {
      deliveryContentSocket.off("connect", onConnect)
      deliveryContentSocket.off("connect_error", onConnectError)
      deliveryContentSocket.off("disconnect", onDisconnect)
    }
  }, [queryClient])

  useEffect(() => {
    if (!enable) {
      deliveryContentSocket.disconnect()
    } else {
      deliveryContentSocket.connect()
    }
  }, [enable])

  return {
    isConnected,
    subscribe,
    unsubscribe,
    deliveryContentSocket,
  }
}
