import { alpha } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

const mixins = {
  hKioskHeader: {
    height: 106, // kiosk header
  },
  hKioskFooter: {
    height: 0, // kiosk footer
  },
  kioskNavigation: {
    height: 240,
    width: "32dvw",
  },
  bottomNavigation: {
    height: 80,
  },
  mobileHeader: {
    height: 70,
  },
  mobileDirectionSearch: {
    width: 380,
  },
}

export const themeOptionFactory = (theme) => ({
  mixins,
  assets: {
    /**
     * The icon configuration format for pages with no data.
     * Example:
     * promotions: {
     *   "icon_comp": "_COMPONENT_NAME_", // optional
     *   "icon_path": "_SVG_PATH_", // optional
     *   "icon_img": "_IMG_SRC_", // optional
     * }
     */
    empty: {
      promotions: {},
      events: {},
      dining: {},
      newShops: {},
      directory: {},
      touristPrivilege: {},
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlinedSizeMedium: {
          fontSize: "1.25rem",
          fontWeight: 400,
        },
        startIcon: {
          marginRight: theme.spacing(0.75),
        },
        endIcon: {
          marginLeft: theme.spacing(0.75),
        },
        sizeMedium: {
          paddingTop: theme.spacing(2),
        },
      },
    },
    AppLayout: {
      styleOverrides: {
        headerWrapper: { height: mixins.hKioskHeader.height },
        indoorMapWrapper: {
          position: "absolute",
          width: "65dvw",
          margin: theme.spacing(2.5, 4),
          top: mixins.hKioskHeader.height,
          bottom: 0,
          maxWidth: `1260px`,
          overflow: "hidden",
          borderRadius: "24px",
        },
        contentWrapper: {
          position: "relative",
        },
      },
    },
    AppMapLayout: {
      styleOverrides: {},
    },
    AppPageLayout: {
      styleOverrides: {
        root: {
          height: `calc(100dvh - ${mixins.hKioskHeader.height}px)`,
          overflow: "hidden",
        },
        asideWrapper: { width: 520 },
      },
    },
    AppContentWithAside: {
      styleOverrides: {
        root: {
          height: `calc(100dvh - ${mixins.hKioskHeader.height}px)`,
          display: "flex",
          width: "100dvw",
          padding: theme.spacing(2.5, 4),
          gap: theme.spacing(2),
        },
        contentWrapper: {
          // -8px from half of the gap of root style
          width: "calc(68% - 8px)",
          position: "relative",
          flexShrink: 0,
        },
        asideWrapper: {
          // -8px from half of the gap of root style
          width: "calc(32% - 8px)",
          flexShrink: 0,
        },
      },
    },
    AppMapPageLayout: {
      styleOverrides: {
        root: {
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          pointerEvents: "none",

          "& .content": {
            pointerEvents: "inherit",
            "& > *": {
              pointerEvents: "all",
            },
          },
          "& .aside": { pointerEvents: "all" },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 50,
          height: 30,
          padding: 0,
          margin: theme.spacing(1),
          display: "flex",
          alignItems: "center",
          "& .MuiSwitch-thumb": {
            color: "white",
            boxShadow: "none",
            width: 20,
            height: 20,
            borderRadius: 9999,
            transition: theme.transitions.create(["width"], {
              duration: 200,
            }),
          },
          "& .MuiSwitch-track": {
            borderRadius: 9999,
            opacity: 1,
            backgroundColor: theme.palette.grey[100],
            boxSizing: "border-box",
          },
          "&:active": {
            "& .MuiSwitch-thumb": {
              width: 20,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
              transform: "translateX(9px)",
            },
          },
          "& .MuiSwitch-switchBase": {
            padding: 5,
            "&.Mui-checked": {
              transform: "translateX(20px)",
              "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: "#3DB357",
              },
            },
            "&.Mui-disabled": {
              "& .MuiSwitch-thumb": {
                backgroundColor: theme.palette.grey[100],
              },
              "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.grey[50],
              },
              "&.Mui-checked": {
                "& .MuiSwitch-thumb": {
                  backgroundColor: theme.palette.grey[300],
                },
                "& + .MuiSwitch-track": {
                  backgroundColor: theme.palette.grey[100],
                },
              },
            },
          },
        },
        sizeSmall: {
          width: 30,
          height: 18,
          "&:active": {
            "& .MuiSwitch-thumb": {
              width: 16,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
              transform: "translateX(9px)",
            },
          },
          "& .MuiSwitch-switchBase": {
            padding: 2,
            "&.Mui-checked": {
              transform: "translateX(12px)",
            },
          },
          "& .MuiSwitch-thumb": {
            width: 14,
            height: 14,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.grey[500],
          "&.Mui-disabled": {
            backgroundColor: "#ECECEC",
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreIcon,
      },
      styleOverrides: {
        select: {
          padding: theme.spacing(1.5, 4, 1.5, 2),
          borderRadius: "8px",
          fontSize: 12,
        },
        icon: {
          color: theme.palette.primary.main,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "0.75rem",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          fontSize: "0.75rem",
        },
      },
    },
    AppSidebar: {
      styleOverrides: {
        wrapper: {
          display: "flex",
          flexDirection: "column",
          height: "100%",
        },
        header: {},
        body: {
          flex: 1,
        },
        footer: { marginBottom: theme.spacing(5) },
      },
    },
    AppDining: {
      styleOverrides: {
        filterSection: {
          margin: theme.spacing(0, -3),
        },
        filterContainer: {
          paddingLeft: 0,
          paddingBottom: 3,
        },
        filterButton: {
          whiteSpace: "nowrap",
          "& .MuiSvgIcon-root": {
            marginRight: theme.spacing(1),
          },
          ":first-of-type": {
            marginLeft: theme.spacing(3),
          },
        },
        diningImageCard: {
          display: "block",
          "& .MuiCardHeader-root": {
            paddingBottom: theme.spacing(1),
          },
          "& .MuiCardHeader-root .MuiCardHeader-title": {
            lineHeight: 1.3,
          },
          "& .MuiCardHeader-content": {
            overflow: "hidden",
          },
          "& .MuiCardHeader-title": {
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            marginBottom: theme.spacing(0.1875),
          },
          "& .MuiCardHeader-avatar .MuiAvatar-root": {
            width: "50px",
            height: "50px",
          },
        },
        subCategoryFilterBar: {
          position: "absolute",
          left: "50%",
          zIndex: 2,
          transform: "translateX(-50%)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          bottom: 0,
          width: "100%",
          maxWidth: "84%",
          marginBottom: theme.spacing(5),
          "&:after": {
            content: "''",
            display: "inline-block",
            width: "20px",
            background:
              "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 10%, #fff 90%)",
            zIndex: 1,
            position: "absolute",
            right: "-2px",
            top: 0,
            bottom: 0,
          },
          "&:before": {
            content: "''",
            display: "inline-block",
            width: "20px",
            background:
              "linear-gradient(90deg, #fff 10%, rgba(255, 255, 255, 0.00) 90%)",
            zIndex: 1,
            position: "absolute",
            left: "-2px",
            top: 0,
            bottom: 0,
          },
          "& .MuiToggleButton-root": {
            "& .MuiSvgIcon-root": {
              display: "none",
            },
          },
          "& .MuiToggleButtonGroup-root": {
            gap: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            margin: "0 auto",
          },
          "& .MuiToggleButton-root:first-of-type": {
            marginLeft: 0,
          },
          "& .MuiToggleButton-root:last-of-type": {
            marginRight: 0,
          },
        },
        scrollPane: {
          padding: 0,
        },
        venueSelector: {
          position: "fixed",
          bottom: "175px",
          right: "690px",
          zIndex: 2,
        },
        contentWrapper: {
          height: "100%",
        },
        emptyDataWrapper: {
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: "250px",
        },
        gridContainer: {
          position: "relative",
          display: "grid",
          gridAutoFlow: "dense",
          gridAutoRows: "max-content",
          gridTemplateColumns: "repeat(auto-fill, minmax(390px, 1fr))",
          gap: theme.spacing(2),
          paddingBottom: theme.spacing(6.25),
          overflowY: "auto",
          height: "100%",
          //hide scrollbar
          "::-webkit-scrollbar": {
            display: "none",
          },
          MsOverflowStyle: "none",
          scrollbarWidth: "none",
        },
        gridItem: {
          height: "262px",
        },
        pageCard: {
          "&:after": {
            content: "''",
            pointerEvents: "none",
            display: "inline-block",
            position: "absolute",
            zIndex: 1,
            left: 0,
            right: 0,
            bottom: 0,
            minHeight: "180px",
            background:
              "linear-gradient(0deg, #FFF 37.46%, rgba(255, 255, 255, 0.00) 94.07%)",
          },
        },
      },
    },
    AppCategoryFilterButton: {
      styleOverrides: {
        root: {
          padding: 0,
          width: 85,
          flexDirection: "column",
          justifyContent: "start",
          gap: theme.spacing(0.75),
          border: "none",
          backgroundColor: theme.palette.primary.contrastText,
          color: theme.palette.grey[500],
          "&.Mui-selected": {
            "& .MuiSvgIcon-root": {
              color: theme.palette.primary.main,
            },
            "& .MuiTypography-root": {
              color: theme.palette.primary.main,
            },
          },
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: "unset",
          },
        },
        label: {
          fontSize: "0.75rem",
          textAlign: "center",
          lineHeight: 1.1,
          color: theme.palette.text.primary,
          fontWeight: theme.typography.fontWeightMedium,
        },
        icon: {
          width: 75,
          height: 75,
          padding: theme.spacing(1.25),
          borderRadius: `${(theme.shape.borderRadius * 2) / 3}px`,
          border: `1px solid rgba(203, 203, 203, 0.5)`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .MuiSvgIcon-root": {
            width: 40,
            height: 40,
          },
        },
      },
    },
    AppCategoryFilterChip: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0.5),
          borderRadius: "16px",
          boxShadow: "none",
          background: theme.palette.common.white,
          border: `1px solid ${theme.palette.grey[200]}`,
          whiteSpace: "nowrap",
          height: "32px",
          color: theme.palette.text.primary,
          fontWeight: 400,
          "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
            borderRadius: "16px",
            margin: 0,
          },
          "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
            border: `1px solid ${theme.palette.grey[200]}`,
            margin: 0,
            borderRadius: "16px",
          },
          "&.MuiToggleButtonGroup-grouped:first-of-type": {
            marginLeft: theme.spacing(3),
          },
          "&.Mui-selected": {
            "& svg": {
              fill: theme.palette.primary.contrastText,
            },
          },
          ":hover": {
            background: theme.palette.common.white,
          },
        },
        label: {
          fontSize: "0.8125rem",
          padding: theme.spacing(0, 1.5),
          letterSpacing: "0.00938em",
        },
        icon: {
          "& svg": {
            width: 16,
            height: 16,
            fill: theme.palette.primary.main,
            marginLeft: theme.spacing(0.5),
            marginRight: "-6px",
          },
        },
      },
    },
    AppCategoryFilterBar: {
      styleOverrides: {
        root: {},
        filterButton: {},
      },
    },
    WelcomePanel: {
      defaultProps: {
        showNavigation: false,
        showQuickSearch: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[50],
        },
        panelContainer: {
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
        },
        line: {
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          height: "3px",
          border: "none",
          background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.common.white} 100%)`,
        },
        content: {
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: theme.spacing(6.75, 3, 2),
          [theme.breakpoints.up("sm")]: {
            width: 460,
          },
        },
        logoContainer: {
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
          marginBottom: theme.spacing(3),
          "& img": {
            maxWidth: 130,
          },
        },
        searchBarContainer: {
          marginBottom: theme.spacing(2),
          width: "100%",
        },
        navItem: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          color: "white",
          flexBasis: 0,
          fontSize: theme.typography.subTitle.fontSize,
          fontWeight: theme.typography.fontWeightRegular,
          lineHeight: "19px",
          flexGrow: "1",
          textAlign: "center",
          "& .MuiButton-startIcon": {
            margin: theme.spacing(0, 0, 1.5),
            width: 20,
            height: 20,
          },
        },
        horizontalMenuContainer: {
          padding: 0,
        },
      },
    },
    AppHeader: {
      styleOverrides: {
        root: {
          position: "sticky",
          left: 0,
          top: 0,
          right: 0,
          justifyContent: "center",
          minHeight: mixins.hKioskHeader.height,
          backgroundColor: theme.palette.grey[50],
          boxShadow: "none",
          width: "100%",
          color: theme.palette.text.primary,
          fontWeight: 600,
        },
        toolbar: {
          padding: theme.spacing(0, 9),
          maxWidth: "none",
          minHeight: mixins.mobileHeader.height,
          justifyContent: "space-between",
          width: `100%`,
          [theme.breakpoints.up("md")]: {
            padding: theme.spacing(0, 5.25, 0, 4),
            margin: `0 auto`,
            maxWidth: "none",
          },
        },
        logoContainer: {
          display: "inline-block",
        },
        titleContainer: {
          display: "none",
        },
        clockContainer: {
          display: "inline-block",
        },
        backButtonContainer: {
          display: "none",
        },
        line: {
          display: "none",
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          height: "3px",
          border: "none",
          background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.common.white} 100%)`,
        },
      },
    },
    AppClock: {
      defaultProps: {
        dateFormat: "LLL",
        timeFormat: "hh:mm",
        timeUnitFormat: "A",
      },
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          textTransform: "uppercase",
          gap: theme.spacing(8),
          lineHeight: 1.2,
          "& .MuiDivider-root": {
            display: "none",
          },
        },
        timeWrapper: {
          fontWeight: 400,
          textAlign: "left",
          fontSize: "24px",
        },
        dateContainer: {
          gap: theme.spacing(1.5),
        },
        divider: {},
        timeContainer: {
          gap: theme.spacing(1.5),
        },
        dateText: {
          fontWeight: 400,
          fontSize: "24px",
        },
        timeLabel: {
          display: "none",
        },
        timeText: {},
        timeUnitText: {
          fontWeight: 400,
          fontSize: "24px",
          marginBottom: theme.spacing(2),
        },
        icon: {
          "& .MuiSvgIcon-root": {
            width: 45,
            height: 45,
          },
        },
      },
    },
    // Navigation
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          width: "100%",
          overflow: "hidden",
        },
      },
    },

    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          maxWidth: "unset",
          padding: theme.spacing(1, 5),
          "& .MuiSvgIcon-root": {
            marginBottom: 8,
            width: 20,
            height: 20,
          },
          "&.Mui-selected": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            "&:first-of-type": {
              borderTopLeftRadius: "inherit",
              borderBottomLeftRadius: "inherit",
            },
            "&:last-of-type": {
              borderTopRightRadius: "inherit",
              borderBottomRightRadius: "inherit",
            },
            "&:after": {
              display: "none",
            },
          },
          "&.Mui-disabled": {
            color: theme.palette.action.disabled,
            "& .MuiSvgIcon-root": {
              color: theme.palette.action.disabled,
            },
          },
        },
        label: {
          textAlign: "left",
          fontSize: "1rem",
          textTransform: "uppercase",
        },
      },
    },
    VuiNavigationControl: {
      styleOverrides: {
        wrapper: {
          "> *": {
            flex: 1,
          },
        },
        navigationAction: {
          display: "flex",
          gap: theme.spacing(2),
          "& .MuiSvgIcon-root": {
            width: 24,
            height: 24,
            color: theme.palette.text.secondary,
          },
        },
        languageButton: {
          flexDirection: "column",
          color: "text.secondary",
          background: "none",
          "&:hover": { background: "none" },
        },
      },
    },
    AppKioskNavigation: {
      styleOverrides: {
        root: {
          height: "100%",
          maxWidth: "100%",
        },
        menuButton: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          gap: theme.spacing(2),
          alignItems: "center",
          "& .MuiSvgIcon-root": {
            width: 24,
            height: 24,
            margin: 0,
          },
          flexBasis: "290px",
          height: "70px",
          "&:nth-of-type(odd)": {
            borderRight: `1px solid ${theme.palette.divider}`,
          },
        },
        navigationContainer: {
          display: "flex",
          flexWrap: "wrap",
          alignContent: "flex-start",
          padding: 0,
          height: "auto",
          width: "auto",
          gap: 0,
        },
        toolbarContainer: {
          display: "none",
        },
        searchBarContainer: {
          display: "none",
        },
        actionContainer: {
          display: "none",
        },
        actionButton: {
          minWidth: 128,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          "& .MuiSvgIcon-root": {
            marginRight: 0,
            width: 32,
            height: 32,
          },
        },
      },
    },
    AppFooter: {
      styleOverrides: {
        root: {
          height: mixins.hKioskFooter.height,
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1200,
        },
      },
    },
    AppTopBar: {
      styleOverrides: {
        root: {
          display: "none",
        },
        ordinalSelector: {
          position: "static",
          "&.MuiList-root": {
            borderRadius: 9999,
            width: "48px",
            height: "48px",
            padding: theme.spacing(0.4),
          },
          "& .MuiListItemButton-root": {
            height: "100%",
          },
        },
        backButton: {
          color: theme.palette.primary.main,
        },
      },
    },
    DirectionSearchInput: {
      styleOverrides: {
        directionSearchWrapper: {
          boxShadow: theme.shadows[1],
          background: theme.palette.common.white,
          padding: theme.spacing(3, 2),
          borderRadius: "10px",
          display: "flex",
        },
        backIconWrapper: {
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          marginRight: theme.spacing(1),
        },
        directionWrapper: {
          flexGrow: 1,
          display: "flex",
        },
        switchIconWrapper: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: theme.spacing(1),
        },
        directionInputWrapper: {
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        },
        directionIconWrapper: {
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "column",
          marginRight: theme.spacing(1.5),
        },
        directionInput: {
          fontSize: "0.875rem",
          fontWeight: 500,
          flexGrow: 1,
          padding: theme.spacing(0, 0.5),
          "& .MuiInputBase-input": {
            padding: 0,
          },
        },
        iconButton: {
          color: theme.palette.text.primary,
          fontSize: "24px",
          margin: "-5px",
        },
        divider: {
          margin: theme.spacing(1, 0),
          borderBottomWidth: "1px",
        },
        clearIconButton: {
          color: theme.palette.text.primary,
          fontSize: "24px",
          margin: "-5px",
          "& .MuiSvgIcon-root": {
            height: "8px",
            width: "8px",
          },
        },
      },
    },
    AppDirectionSearch: {
      styleOverrides: {
        headerWrapper: {
          display: "none",
        },
        paper: {
          borderRadius: "10px",
          padding: theme.spacing(0.75, 1.25),
          display: "flex",
          alignItems: "center",
        },
        formControlLabel: {
          margin: 0,
          "& .MuiFormControlLabel-label": {
            fontWeight: 500,
            marginLeft: theme.spacing(2),
          },
        },
      },
    },
    AppMap: {
      styleOverrides: {
        toolbarContainer: {
          position: "fixed",
          left: 0,
          right: 0,
          top: mixins.hKioskHeader?.height,
        },
        quickLinkContainer: {
          overflowX: "scroll",
          padding: 0,
          display: "flex",
          //hide scrollbar
          "::-webkit-scrollbar": {
            display: "none",
          },
          MsOverflowStyle: "none",
          scrollbarWidth: "none",
        },
        searchBarContainer: {
          padding: theme.spacing(3, 3, 1, 3),
          [theme.breakpoints.up("md")]: {
            maxWidth: "350px",
          },
        },
        ordinalSelectMenu: {
          position: "fixed",
          bottom: "310px",
          right: "690px",
        },
        indoorMapContainer: {
          height: "100%",
          borderRadius: `15px`,
          "& > #map": {
            width: "100%",
            // TODO: Implement a temporary solution to prevent the place modal from overlapping the route path on the map in certain cases.
            height: `100%`,
          },
        },
        venueSelector: {
          position: "fixed",
          bottom: "175px",
          right: "690px",
          zIndex: 2,
        },
      },
    },
    AppNavigationStep: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 5.5, 5, 5.5),
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        wrapper: {},
        container: {},
        kioskBackButton: {},
        title: {},
        primaryText: {
          fontSize: "1.25rem",
          color: theme.palette.text.primary,
          textAlign: "center",
          fontWeight: 700,
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
          overflow: "hidden",
          textTransform: "uppercase",
        },
        highlightedText: {},
        secondaryText: {
          fontSize: "1.25rem",
          color: theme.palette.text.primary,
          textAlign: "center",
          fontWeight: 400,
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
          overflow: "hidden",
          textTransform: "uppercase",
        },
        locationText: {},
        headerWrapper: {},
        actionContainer: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: theme.spacing(4),
          gap: theme.spacing(3),
          width: "100%",
        },
        avoidStepButton: {
          textTransform: "uppercase",
          fontSize: "1.25rem",
          fontWeight: 400,
          marginRight: 0,
          flexDirection: "row-reverse",
        },
        stepContainer: {
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: theme.spacing(1),
          marginTop: theme.spacing(2),
        },
        stepItem: {
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(1.25),
        },
        stepTextContainer: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: theme.spacing(1),
        },
        venueLogo: {
          height: "65px",
        },
        overviewWrapper: {},
        navButtonWrapper: {},
        iconNavButton: {},
        exitButtonWrapper: {},
        exitButton: {},
        ordinalSelector: {},
        playerActionContainer: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: theme.spacing(0.5),
          width: "100%",
        },
        primaryButton: {
          flex: 1,
        },
        secondaryButton: { flex: 0.5 },
        borderLinearProgressBar: {},
        stepProgressBar: {
          display: "none",
        },
      },
    },
    AppFeatureDetail: {
      styleOverrides: {
        root: {
          position: "relative",
          overflow: "initial",
          boxShadow: "none",
        },
        section: {
          margin: theme.spacing(3, 0),
          "&:first-of-type": {
            marginTop: 0,
          },
          color: theme.palette.text.secondary,
        },
        galleryImage: {
          objectFit: "cover",
          height: 130,
          width: 182,
          display: "block",
          boxShadow: theme.shadows[2],
          borderRadius: "6px",
          cursor: "pointer",
        },
        divider: {
          borderBottom: `1px solid #ECECEC`,
        },
        contactButton: {
          textTransform: "lowercase",
          display: "flex",
          marginBottom: theme.spacing(2.5),
          color: theme.palette.text.primary,
          "& .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
          },
        },
        contactWrapper: {
          marginBottom: theme.spacing(1.25),
        },
        sectionTitle: {
          fontSize: theme.typography.subtitle1.fontSize,
          fontWeight: theme.typography.fontWeightBold,
          marginBottom: theme.spacing(2),
          color: theme.palette.text.primary,
        },
      },
    },
    AppImageModal: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: theme.spacing(2),
        },
        imageWrapper: {
          position: "relative",
          outline: "none",
          "& img": {
            borderRadius: "8px",
            width: "100%",
          },
          [theme.breakpoints.up("sm")]: {
            width: "400px",
          },
        },
        closeButton: {
          color: theme.palette.common.white,
          position: "absolute",
          top: theme.spacing(-4.5),
          right: theme.spacing(-1),
          [theme.breakpoints.up("sm")]: {
            right: theme.spacing(-2),
          },
        },
      },
    },
    AppYouAreHereMarker: {
      styleOverrides: {
        root: {
          "& .primary": {
            fill: theme.palette.primary.main,
          },
          "& .primary-light": {
            fill: theme.palette.secondary.main,
          },
          "& .primary-border": {
            stroke: theme.palette.primary.main,
          },
          "& .text-primary": {
            fill: theme.palette.text.primary,
          },
          "& .secondary": {
            fill: theme.palette.secondary.main,
          },
        },
      },
    },
    DirectionStep: {
      styleOverrides: {
        originIcon: {
          fill: theme.palette.primary.main,
          width: 37,
          height: 37,
        },
        destinationIcon: {
          fill: theme.palette.error.main,
          width: 28,
          height: 47,
        },
        highlightedText: {
          color: theme.palette.primary.main,
          textAlign: "inherit",
          fontWeight: "inherit",
          lineHeight: "inherit",
          fontSize: "inherit",
        },
      },
    },
    AppDirectionOverview: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 5.5, 5, 5.5),
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        wrapper: {},
        container: {},
        kioskBackButton: {},
        title: {},
        primaryText: {
          fontSize: "1.25rem",
          color: theme.palette.text.primary,
          textAlign: "center",
          fontWeight: 700,
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
          overflow: "hidden",
          textTransform: "uppercase",
        },
        highlightedText: {},
        secondaryText: {
          fontSize: "1.25rem",
          color: theme.palette.text.primary,
          textAlign: "center",
          fontWeight: 400,
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
          overflow: "hidden",
          textTransform: "uppercase",
        },
        locationText: {},
        originIcon: {
          color: "#EB5757",
          fontSize: "5.75rem", // 92px
        },
        destinationIcon: {
          fontSize: "3.5rem",
          fill: theme.palette.text.primary,
        },
        headerWrapper: {},
        actionContainer: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: theme.spacing(4),
          gap: theme.spacing(3),
          width: "100%",
        },
        avoidStepButton: {
          textTransform: "uppercase",
          fontSize: "1.25rem",
          fontWeight: 400,
          marginRight: 0,
          flexDirection: "row-reverse",
        },
        stepContainer: {
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: theme.spacing(1),
          marginTop: theme.spacing(2),
        },
        stepItem: {
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(1.25),
        },
        stepTextContainer: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: theme.spacing(1),
        },
        venueLogo: {
          height: "65px",
        },
        overviewWrapper: {},
        navButtonWrapper: {},
        iconNavButton: {},
        exitButtonWrapper: {},
        exitButton: {},
        ordinalSelector: {},
        playerActionContainer: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: theme.spacing(0.5),
          width: "100%",
        },
        primaryButton: {
          flex: 0.5,
        },
        secondaryButton: {},
      },
    },
    DirectionCardActions: {
      styleOverrides: {
        root: {
          gap: theme.spacing(2),
          height: "auto",
          padding: 0,
          display: "flex",
          flexDirection: "row",
        },
        actionButton: {
          [theme.breakpoints.down("sm")]: {
            fontWeight: 700,
            "&.MuiButton-outlined": {
              border: `1px solid ${theme.palette.grey[400]}`,
            },
            "& .MuiButton-startIcon": {
              marginRight: theme.spacing(1.25),
            },
          },
        },
      },
    },
    HomePage: {
      styleOverrides: {
        root: {
          height: `calc(100% - ${mixins.hKioskHeader.height})`,
          top: mixins.hKioskHeader.height,
          padding: theme.spacing(11.75, 3.75, 8.75, 3.75),
          backgroundColor: "white",
        },
        contentContainer: {
          height: "810px",
          display: "flex",
          flexDirection: "row",
        },
        welcomePanelWrapper: {
          width: "420px",
          height: "810px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        welcomeContainer: {
          width: "400px",
          height: "465px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        },
        logoContainer: {
          margin: theme.spacing(4, 4.75),
        },
        logoImage: {
          height: 66,
        },
        qrContainer: {
          width: 300,
          paddingTop: theme.spacing(4),
        },
        qrCode: {
          paddingTop: theme.spacing(4),
          backgroundColor: "transparent",
          "& .AppQRCode-content": {
            width: 80,
            height: 80,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            border: "none",
            //create corner border
            //https://stackoverflow.com/questions/38850419/how-to-create-multi-color-border-with-css
            //http://www.java2s.com/example/html-css/css-widget/add-border-only-to-corner.html
            ":before": {
              content: "''",
              display: "block",
              position: "absolute",
              borderTop: "3px solid",
              borderBottom: "3px solid",
              borderImage:
                "linear-gradient(to right, black 25%, transparent 25%, transparent 50%,transparent 50%, transparent 75%, black 75%) 3",
              height: 80,
              width: 80,
            },
            ":after": {
              display: "block",
              position: "absolute",
              content: "''",
              borderRight: "3px solid",
              borderLeft: "3px solid",
              borderImage:
                "linear-gradient(to bottom, black 25%, transparent 25%, transparent 50%,transparent 50%, transparent 75%, black 75%) 3",
              height: 80,
              width: 80,
            },
          },
          "& .AppQRCode-qrcode": {
            height: 65,
            width: 65,
            border: "none",
          },
          "& .AppQRCode-caption": {
            paddingTop: theme.spacing(2),
            fontWeight: 500,
            fontSize: "0.875rem",
            color: theme.palette.text.secondary,
          },
        },
        localeListContainer: {
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          marginTop: theme.spacing(6),
        },
        localeButton: {
          borderRadius: "50%",
          height: "40px",
          width: "40px",
          padding: 0,
          minWidth: 40,
          boxShadow: theme.shadows[3],
          "& .MuiAvatar-circular": {
            height: "35px",
            width: "35px",
          },
        },
        actionContainer: {
          marginTop: theme.spacing(4),
        },
        actionButton: {
          borderRadius: "50px",
          width: "300px",
          height: "56px",
          textTransform: "capitalize",
        },
        videoContainer: {
          width: 1440,
          height: 810,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
    FullScreenStart: {
      styleOverrides: {
        root: {
          display: "none",
        },
        actionButtonWrapper: {
          position: "relative",
          display: "inline-block",
          "&:after, :before": {
            opacity: 0,
            display: "flex",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            content: '""',
            height: "100%",
            width: "100%",
            border: `5px solid ${alpha(theme.palette.common.white, 0.4)}`,
            borderRadius: 9999,
            zIndex: -1,
          },
          "&:after": {
            animation: `ripple 3s infinite ${theme.transitions.easing.easeInOut}`,
          },
          "&:before": {
            animation: `ripple 3s infinite ${theme.transitions.easing.easeInOut} 0.5s`,
          },
        },
        actionButton: {
          position: "relative",
          fontSize: "1rem",
          textTransform: "uppercase",
          color: theme.palette.text.secondary,
          fontWeight: 700,
          borderRadius: 999,
          backgroundColor: theme.palette.common.white,
          transition: theme.transitions.create("all"),
          boxShadow: theme.shadows[10],
          padding: theme.spacing(1.25, 3),
          "&:hover": {
            boxShadow: theme.shadows[20],
            backgroundColor: theme.palette.common.white,
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
          },
          "& .MuiButton-startIcon>svg": {
            fontSize: "1.5rem",
            color: theme.palette.primary.main,
          },
          [theme.breakpoints.up("md")]: {
            transform: "scale(1.2)",
          },
        },
      },
    },
    DirectoryPage: {
      styleOverrides: {
        root: {
          "& .MuiList-root": {
            display: "none",
          },
          display: "flex",
          flexDirection: "column-reverse",
          gap: theme.spacing(1),
          margin: theme.spacing(0, -3),
        },
        filterSection: {
          marginTop: 0,
          position: "sticky",
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
          top: 0,
          zIndex: 1,
          paddingTop: theme.spacing(3),
          background: theme.palette.background.paper,
        },
        categoryFilterBar: {
          position: "absolute",
          left: "50%",
          zIndex: 2,
          transform: "translateX(-50%)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          bottom: 0,
          width: "100%",
          maxWidth: "84%",
          marginBottom: theme.spacing(5),
          "&:after": {
            content: "''",
            display: "inline-block",
            width: "20px",
            background:
              "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 10%, #fff 90%)",
            zIndex: 1,
            position: "absolute",
            right: "-2px",
            top: 0,
            bottom: 0,
          },
          "&:before": {
            content: "''",
            display: "inline-block",
            width: "20px",
            background:
              "linear-gradient(90deg, #fff 10%, rgba(255, 255, 255, 0.00) 90%)",
            zIndex: 1,
            position: "absolute",
            left: "-2px",
            top: 0,
            bottom: 0,
          },
          "& .MuiToggleButtonGroup-root": {
            gap: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            margin: "0 auto",
          },
          "& .MuiToggleButton-root:first-of-type": {
            marginLeft: 0,
          },
          "& .MuiToggleButton-root:last-of-type": {
            marginRight: 0,
          },
        },
        subCategoryFilterBar: {
          position: "absolute",
          left: "50%",
          zIndex: 2,
          transform: "translateX(-50%)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          bottom: 0,
          width: "100%",
          maxWidth: "84%",
          marginBottom: theme.spacing(5),
          "&:after": {
            content: "''",
            display: "inline-block",
            width: "20px",
            background:
              "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 10%, #fff 90%)",
            zIndex: 1,
            position: "absolute",
            right: "-2px",
            top: 0,
            bottom: 0,
          },
          "&:before": {
            content: "''",
            display: "inline-block",
            width: "20px",
            background:
              "linear-gradient(90deg, #fff 10%, rgba(255, 255, 255, 0.00) 90%)",
            zIndex: 1,
            position: "absolute",
            left: "-2px",
            top: 0,
            bottom: 0,
          },
          "& .MuiToggleButton-root": {
            "& .MuiSvgIcon-root": {
              display: "none",
            },
          },
          "& .MuiToggleButtonGroup-root": {
            gap: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            margin: "0 auto",
          },
          "& .MuiToggleButton-root:first-of-type": {
            marginLeft: 0,
          },
          "& .MuiToggleButton-root:last-of-type": {
            marginRight: 0,
          },
        },
        scrollPane: {
          padding: 0,
        },
        buildingWithLevelFilterSection: {
          display: "none",
          "& .MuiToggleButtonGroup-root": {
            gap: theme.spacing(1),
          },
          "& .MuiDivider-root": {
            display: "none",
          },
        },
        buildingFilterSelect: {
          flex: "6 0 0",
        },
        levelFilterSelect: {
          flex: "4 0 0",
        },
        selectMenuItem: {
          fontSize: "0.75rem",
        },
        resetButton: {
          color: theme.palette.info.main,
          textDecoration: "underline",
          fontSize: "0.75rem",
        },
        divider: {
          display: "none",
        },
        list: {
          padding: theme.spacing(0, 1),
          paddingTop: theme.spacing(1),
        },
        gridContainer: {
          position: "relative",
          display: "grid",
          gridAutoFlow: "dense",
          gridTemplateColumns: "repeat(4,  minmax(100px,1fr))",
          gap: theme.spacing(2),
          paddingBottom: theme.spacing(6.25),
          overflowY: "auto",
          height: "818px",
          //hide scrollbar
          "::-webkit-scrollbar": {
            display: "none",
          },
          MsOverflowStyle: "none",
          scrollbarWidth: "none",
        },
        gridItem: {},
        emptyDataWrapper: {
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: "250px",
        },
        pageCard: {
          "&:after": {
            content: "''",
            pointerEvents: "none",
            display: "inline-block",
            position: "absolute",
            zIndex: 1,
            left: 0,
            right: 0,
            bottom: 0,
            minHeight: "180px",
            background:
              "linear-gradient(0deg, #FFF 37.46%, rgba(255, 255, 255, 0.00) 94.07%)",
          },
        },
      },
    },
    LevelFilterBar: {
      styleOverrides: {
        root: {
          padding: 0,
          "& .MuiToggleButton-root": {
            padding: theme.spacing(1, 3),
            flexShrink: 0,
          },
          "& .MuiToggleButton-root:first-of-type": {
            marginLeft: theme.spacing(3),
          },
          "& .MuiToggleButton-root:last-of-type": {
            marginRight: theme.spacing(3),
          },
        },
      },
    },
    DirectoryOccupantCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          display: "flex",
          height: "120px",
          borderRadius: `${(theme.shape.borderRadius * 2) / 3}px`,
        },
        occupantLogo: {
          boxShadow: theme.shadows[1],
          width: "100%",
          maxWidth: "120px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .MuiAvatar-root": {
            borderRadius: "0",
            width: "100%",
            height: "100%",
          },
          "& .MuiAvatar-img": {
            objectFit: "contain",
          },
          "& .MuiSvgIcon-root": {
            width: "48px",
            height: "48px",
          },
        },
        cardContent: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          "& .MuiTypography-root": {
            lineHeight: 1.25,
          },
        },
        levelContainer: {
          background: theme.palette.common.black,
          width: "42px",
          height: "38px",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "20px 0px 0px 0px",
        },
        divider: {
          margin: theme.spacing(0, 1.625),
        },
        venueLogo: {
          width: "60px",
          height: "20px",
          objectFit: "contain",
        },
        titleText: {
          fontSize: "0.875rem",
          fontWeight: 700,
          textTransform: "uppercase",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          overflow: "hidden",
        },
        groupText: {
          display: "none",
        },
        categoryText: {
          fontSize: "0.625rem",
          fontWeight: 400,
          color: theme.palette.text.primary,
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
          overflow: "hidden",
        },
        titleContainer: {
          height: "100%",
          padding: theme.spacing(0.875, 1.625, 0),
        },
        detailWrapper: {
          height: "100%",
          justifyContent: "space-between",
        },
        locationInfoContainer: {
          paddingLeft: theme.spacing(1.625),
          paddingBottom: theme.spacing(0.875),
        },
        boothText: {
          display: "none",
        },
      },
    },
    PromotionPage: {
      styleOverrides: {
        root: {
          position: "absolute",
          overflow: "hidden",
          left: 0,
          right: 0,
          height: "calc(100vh - 285px - 300px)", //100vh - (kiosk header) - (kiosk footer)
          top: "206px",
        },
        carouselContainer: {
          top: "156px",
          left: 0,
          right: 0,
          display: "block",
          position: "relative",
          width: "1210px",
        },
        categoryFilterBarContainer: {},
        cardListContainer: {
          display: "none",
        },
        carousel: {
          "& .splide": {
            margin: "0 auto",
          },
          "& .splide__pagination": {
            marginTop: "50px",
          },
          "& .splide__pagination__page": {
            width: "14px",
            height: "14px",
          },
          "& .splide__pagination__page.is-active": {
            backgroundColor: theme.palette.primary.main,
            transition: `width ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}, borderRadius ${theme.transitions.duration.standard}s ${theme.transitions.easing.easeInOut}`,
            transform: "none",
            width: 45,
            borderRadius: 30,
          },
          "& .splide__track": {
            padding: 0,
          },
          "& .splide__slide": {
            "&> *": {
              display: "inline-block",
              transform: "scale(0.8)",
              opacity: 0.4,
              transition: `all ${theme.transitions.duration.short}ms`,
            },
            "&.is-visible.is-active": {
              "&> *": {
                opacity: 1,
                transform: "scale(1)",
              },
            },
          },
        },
        promotionCard: {
          minHeight: "auto",
          width: "510px",
          borderRadius: "20px",
          aspectRatio: "1 / 1",
          "& .MuiCardContent-root": {
            display: "none",
          },
        },
        emptyDataWrapper: {
          position: "absolute",
          left: "50%",
          top: "160px",
          transform: "translateX(-50%)",
          width: "250px",
        },
        subCategoryFilterBar: {
          position: "absolute",
          left: "50%",
          zIndex: 2,
          transform: "translateX(-50%)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          bottom: 0,
          width: "100%",
          maxWidth: "84%",
          marginBottom: theme.spacing(5),
          "&:after": {
            content: "''",
            display: "inline-block",
            width: "20px",
            background:
              "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 10%, #fff 90%)",
            zIndex: 1,
            position: "absolute",
            right: "-2px",
            top: 0,
            bottom: 0,
          },
          "&:before": {
            content: "''",
            display: "inline-block",
            width: "20px",
            background:
              "linear-gradient(90deg, #fff 10%, rgba(255, 255, 255, 0.00) 90%)",
            zIndex: 1,
            position: "absolute",
            left: "-2px",
            top: 0,
            bottom: 0,
          },
          "& .MuiToggleButton-root": {
            "& .MuiSvgIcon-root": {
              display: "none",
            },
          },
          "& .MuiToggleButtonGroup-root": {
            gap: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            margin: "0 auto",
          },
          "& .MuiToggleButton-root:first-of-type": {
            marginLeft: 0,
          },
          "& .MuiToggleButton-root:last-of-type": {
            marginRight: 0,
          },
        },
        scrollPane: {
          padding: 0,
        },
      },
    },
    PromotionDetailPage: {
      styleOverrides: {
        pageCardContent: {
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(0, 8.75),
        },
        mainWrapper: {
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(6),
          padding: theme.spacing(6, 0, 4.25),
        },
        secondaryWrapper: {
          padding: theme.spacing(2.5, 0, 4.25),
        },
        gridContainer: {
          display: "grid",
          gridTemplateColumns: "repeat(4, 200px)",
          justifyContent: "space-evenly",
          gridColumnGap: theme.spacing(12.5),
        },
        divider: {
          borderColor: alpha(theme.palette.primary.main, 0.2),
          borderBottomWidth: "4px",
        },
        promotionImage: {
          objectFit: "cover",
          aspectRatio: "1 / 1",
          width: "413px",
          height: "413px",
          borderRadius: "20px",
          alignSelf: "center",
        },
        venueLogo: {
          height: "50px",
          marginBottom: theme.spacing(2),
        },
        detailContent: {
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        contentContainer: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        },
        titleText: {
          fontSize: "2rem",
          fontWeight: 700,
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 1,
          WebkitBoxOrient: "vertical",
        },
        headerContainer: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        titleContainer: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        descriptionContainer: {
          marginTop: theme.spacing(4),
          height: "70px",
        },
        descriptionText: {
          maxHeight: "80px",
          overflow: "hidden",
          textAlign: "center",
          color: theme.palette.text.secondary,
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
        },
        infoContainer: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "650px",
          marginTop: theme.spacing(5),
        },
        locationButton: {
          marginTop: theme.spacing(3),
          textTransform: "uppercase",
          padding: theme.spacing(1.75, 3.75),
        },
        locationContainer: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: 300,
        },
        timeContainer: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: 180,
        },
        infoLabel: {
          fontSize: "1.33rem",
          fontWeight: 700,
          textTransform: "uppercase",
        },
        infoText: {
          fontSize: "1.25rem",
          textTransform: "uppercase",
          color: theme.palette.text.secondary,
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 1,
          WebkitBoxOrient: "vertical",
        },
        infoDivider: {
          height: "66px",
          margin: theme.spacing(0, 1),
        },
        qrCode: {
          right: 0,
          "& .MuiTypography-root": {
            fontSize: "1.25rem",
            paddingTop: 0,
            color: theme.palette.text.primary,
          },
        },
      },
    },
    OccupantPromotionPage: {
      styleOverrides: {
        headerContainer: {
          display: "flex",
          flexDirection: "column",
          gap: 2.25,
        },
        headerLogoWrapper: { width: 120 },
        headerLogo: { width: "100%" },
        headerText: {
          fontWeight: 400,
          fontSize: "2rem",
        },
        contentContainer: {
          padding: theme.spacing(4, 10),
          display: "flex",
          flexDirection: "column",
          gap: 7.5,
          height: "100%",
        },
        gridContainer: {
          display: "grid",
          gridAutoRows: "320px",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          columnGap: 5,
          rowGap: theme.spacing(3),
          paddingBottom: theme.spacing(7.5),
          overflowY: "scroll",
          flex: "1 1 1",
          "&:after": {
            content: "''",
            pointerEvents: "none",
            display: "inline-block",
            position: "absolute",
            zIndex: 1,
            left: 0,
            right: 0,
            bottom: 0,
            minHeight: "180px",
            background:
              "linear-gradient(0deg, #FFF 37.46%, rgba(255, 255, 255, 0.00) 94.07%)",
          },
        },
      },
    },
    FeatureActionContainer: {
      styleOverrides: {
        shortInfoActionWrapper: {
          "button, a": {
            pointerEvents: "all",
          },
          [theme.breakpoints.up("sm")]: {
            paddingBottom: theme.spacing(3),
          },
        },
      },
    },
    AppDesktopFeatureDialog: {
      styleOverrides: {
        dialog: {
          height: "100%",
          maxWidth: "50vw",
          left: "unset",
          "& .MuiDialog-container": {
            justifyContent: "flex-end",
          },
        },
        contentContainer: {
          padding: theme.spacing(3),
          background: theme.palette.common.white,
          boxShadow: "none",
          [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(1.25),
          },
        },
      },
    },
    EventPage: {
      styleOverrides: {
        root: {
          position: "absolute",
          overflow: "hidden",
          left: 0,
          right: 0,
          height: `calc(100vh - ${mixins.hKioskHeader?.height + 60}px - ${
            mixins.hKioskFooter?.height
          }px - ${mixins.kioskNavigation?.height}px)`,
        },
        cardListContainer: {
          display: "none",
        },
        currentEventsHeading: {
          margin: theme.spacing(0.25, 0, 3, 0),
        },
        upcomingEventsHeading: {
          margin: theme.spacing(5, 0, 3, 0),
        },
        emptyDataWrapper: {
          position: "absolute",
          left: "50%",
          top: "160px",
          transform: "translateX(-50%)",
          width: "250px",
        },
        carouselContainer: {
          top: "156px",
          left: 0,
          right: 0,
          display: "block",
          position: "relative",
          width: "1210px",
        },
        carousel: {
          "& .splide": {
            margin: "0 auto",
          },
          "& .splide__pagination": {
            marginTop: "50px",
          },
          "& .splide__pagination__page": {
            width: "14px",
            height: "14px",
          },
          "& .splide__pagination__page.is-active": {
            backgroundColor: theme.palette.primary.main,
            transition: `width ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}, borderRadius ${theme.transitions.duration.standard}s ${theme.transitions.easing.easeInOut}`,
            transform: "none",
            width: 45,
            borderRadius: 30,
          },
          "& .splide__track": {
            padding: 0,
          },
          "& .splide__slide": {
            "&> *": {
              display: "inline-block",
              transform: "scale(0.8)",
              opacity: 0.4,
              transition: `all ${theme.transitions.duration.short}ms`,
            },
            "&.is-visible.is-active": {
              "&> *": {
                opacity: 1,
                transform: "scale(1)",
              },
            },
          },
        },
        imageItem: {
          height: "auto",
          minHeight: "754px",
          width: "100%",
          borderRadius: "14px",
        },
        eventGroupSelectorButton: {
          position: "absolute",
          left: "50%",
          zIndex: 2,
          transform: "translateX(-50%)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          bottom: 0,
          width: "100%",
          maxWidth: "84%",
          marginBottom: theme.spacing(5),
          "&:after": {
            content: "''",
            display: "inline-block",
            width: "20px",
            background:
              "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 10%, #fff 90%)",
            zIndex: 1,
            position: "absolute",
            right: "-2px",
            top: 0,
            bottom: 0,
          },
          "&:before": {
            content: "''",
            display: "inline-block",
            width: "20px",
            background:
              "linear-gradient(90deg, #fff 10%, rgba(255, 255, 255, 0.00) 90%)",
            zIndex: 1,
            position: "absolute",
            left: "-2px",
            top: 0,
            bottom: 0,
          },
          "& .MuiToggleButton-root": {
            "& .MuiSvgIcon-root": {
              display: "none",
            },
          },
          "& .MuiToggleButtonGroup-root": {
            gap: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            margin: "0 auto",
          },
          "& .MuiToggleButton-root:first-of-type": {
            marginLeft: 0,
          },
          "& .MuiToggleButton-root:last-of-type": {
            marginRight: 0,
          },
        },
        eventCard: {
          minHeight: "auto",
          width: "510px",
          borderRadius: "20px",
          aspectRatio: "1 / 1",
          "& .MuiCardContent-root": {
            display: "none",
          },
        },
      },
    },
    EventDetailPage: {
      styleOverrides: {
        pageCardContent: {
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(0, 8.75),
        },
        mainWrapper: {
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(6),
          padding: theme.spacing(6, 0, 4.25),
        },
        secondaryWrapper: {
          padding: theme.spacing(2.5, 0, 4.25),
        },
        gridContainer: {
          display: "grid",
          gridTemplateColumns: "repeat(4, 200px)",
          justifyContent: "space-evenly",
          gridColumnGap: theme.spacing(12.5),
        },
        divider: {
          borderColor: alpha(theme.palette.primary.main, 0.2),
          borderBottomWidth: "4px",
        },
        promotionImage: {
          objectFit: "cover",
          aspectRatio: "1 / 1",
          width: "413px",
          height: "413px",
          borderRadius: "20px",
          alignSelf: "center",
        },
        venueLogo: {
          height: "50px",
          marginBottom: theme.spacing(2),
        },
        detailContent: {
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        contentContainer: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        },
        titleText: {
          fontSize: "2rem",
          fontWeight: 700,
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 1,
          WebkitBoxOrient: "vertical",
        },
        headerContainer: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        titleContainer: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        descriptionContainer: {
          marginTop: theme.spacing(4),
          height: "70px",
        },
        descriptionText: {
          maxHeight: "80px",
          overflow: "hidden",
          textAlign: "center",
          color: theme.palette.text.secondary,
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
        },
        infoContainer: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "650px",
          marginTop: theme.spacing(5),
        },
        locationButton: {
          marginTop: theme.spacing(3),
          textTransform: "uppercase",
          padding: theme.spacing(1.75, 3.75),
        },
        locationContainer: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: 300,
        },
        timeContainer: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: 180,
        },
        infoLabel: {
          fontSize: "1.33rem",
          fontWeight: 700,
          textTransform: "uppercase",
        },
        infoText: {
          fontSize: "1.25rem",
          textTransform: "uppercase",
          color: theme.palette.text.secondary,
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 1,
          WebkitBoxOrient: "vertical",
        },
        infoDivider: {
          height: "66px",
          margin: theme.spacing(0, 1),
        },
        qrCode: {
          right: 0,
          "& .MuiTypography-root": {
            fontSize: "1.25rem",
            paddingTop: 0,
            color: theme.palette.text.primary,
          },
        },
      },
    },
    VerticalEventCard: {
      styleOverrides: {
        root: {
          minHeight: "880px",
          width: "520px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          boxShadow: theme.shadows[1],
        },
        cardMedia: {
          height: "758px",
          width: "100%",
          objectFit: "cover",
          position: "relative",
        },
        cardContent: {
          padding: theme.spacing(2, 3),
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          "&.MuiCardContent-root:last-child": {
            paddingBottom: theme.spacing(1),
          },
          "& .MuiTypography-root": {
            fontSize: "1rem",
          },
        },
        cardTitle: {
          fontWeight: 600,
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          "&.MuiTypography-root": {
            fontSize: "1.25rem",
          },
        },
        iconContainer: {
          width: "auto",
          marginRight: theme.spacing(1.5),
          "& .MuiSvgIcon-root": {
            height: 12,
            width: 10,
            color: theme.palette.primary.main,
          },
        },
        cardInfoContainer: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          "& .MuiTypography-root": {
            fontSize: "0.75rem",
          },
        },
        venueLogoContainer: {
          "& .MuiCardMedia-root": {
            height: "50px",
            width: "120px",
            objectFit: "contain",
          },
        },
        cardContentStack: {},
        durationContainer: {},
        recommendText: {
          width: "100%",
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          bottom: 0,
          left: 0,
          borderRadius: "4px 4px 0px 0px",
          textAlign: "center",
          color: theme.palette.primary.contrastText,
        },
      },
    },
    ImageCard: {
      styleOverrides: {
        root: {
          display: "block",
          overflow: "hidden",
          aspectRatio: "1 / 1",
          width: "100%",
          height: "100%",
          borderRadius: `${(theme.shape.borderRadius * 2) / 3}px`,
          boxShadow: "0px 8px 24px 0px rgba(155, 155, 155, 0.15)",
          "& .MuiCardActionArea-root:after": {
            content: '""',
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "65%",
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.84))",
          },
          "& .MuiCardHeader-subheader": {
            color: theme.palette.common.black,
            position: "relative",
            alignItems: "center",
            whiteSpace: "nowrap",
            paddingTop: theme.spacing(0.5),
            "&:before": {
              content: "''",
              width: "30%",
              position: "absolute",
              borderTop: `1px solid ${theme.palette.divider}`,
              marginTop: theme.spacing(0.5),
              top: 0,
            },
          },
          "& .MuiCardHeader-title": {
            fontWeight: 700,
            fontSize: "min(1.125rem, 3vw)",
            paddingRight: theme.spacing(0.5),
          },
          "& .MuiCardHeader-subheader .location-text": {
            background: theme.palette.common.black,
            color: theme.palette.common.white,
            marginLeft: "auto",
            width: "42px",
            height: "38px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "20px",
            overflow: "hidden",
            fontSize: "1.2rem",
          },
          "& .MuiCardHeader-subheader .sub-category-text": {
            fontSize: "min(1rem, 2.5vw)",
          },
          "& .MuiCardHeader-content": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          },
          "& .MuiAvatar-root": {
            width: "66px",
            height: "100%",
            borderRadius: `${(theme.shape.borderRadius * 2) / 3}px`,
          },
          "& .MuiCardHeader-root": {
            height: "35%",
            padding: theme.spacing(1, 0, 0, 2),
          },
          "& .MuiCardHeader-root .MuiCardHeader-avatar": {
            marginRight: theme.spacing(2),
            paddingBottom: theme.spacing(1),
          },
          "& .MuiCardMedia-img": {
            width: "100%",
            height: "65%",
          },
        },
        chipContainer: {
          position: "absolute",
          padding: "3%",
          left: 0,
          top: 0,
          zIndex: 1,
        },
        chip: {
          backgroundColor: theme.palette.error.main,
          borderRadius: "50px",
          color: theme.palette.error.contrastText,
        },
      },
    },
    PromotionCard: {
      styleOverrides: {
        root: {
          minHeight: "880px",
          width: "520px",
          borderRadius: "15px",
          boxShadow: theme.shadows[1],
          padding: 0,
          display: "flex",
          flexDirection: "column",
          height: "auto",
        },
        cardMedia: {
          height: "758px",
          maxHeight: "none",
          width: "100%",
          borderRadius: "none",
          objectFit: "cover",
          position: "relative",
        },
        cardContent: {
          padding: theme.spacing(1.5, 2),
          "&:last-child": {
            paddingBottom: theme.spacing(1),
          },
          [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2.5, 4.5),
          },
        },
        venueLogoContainer: {
          "& .MuiCardMedia-root": {
            maxWidth: "120px",
          },
        },
        cardContentStack: {},
        cardTitle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          color: theme.palette.text.primary,
        },
        cardInfoContainer: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: theme.palette.grey[700],
          fontSize: theme.typography.subTitle.fontSize,
        },
        iconContainer: {
          width: "auto",
          marginRight: theme.spacing(1),
          "& .MuiSvgIcon-root": {
            height: 14,
            color: theme.palette.primary.main,
          },
        },
        recommendText: {
          width: "100%",
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          bottom: 0,
          left: 0,
          borderRadius: "4px 4px 0px 0px",
          textAlign: "center",
          color: theme.palette.primary.contrastText,
        },
      },
    },
    HighlightFeatureCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: `${(theme.shape.borderRadius * 2) / 3}px`,
          display: "flex",
          flexDirection: "row",
          height: 136,
          padding: theme.spacing(1.5, 1.375),
        },
        cardMedia: {
          width: 74,
          maxHeight: 164,
          borderRadius: "5px",
          objectFit: "cover",
          [theme.breakpoints.up("sm")]: {
            width: 95,
          },
        },
        cardContent: {
          padding: theme.spacing(1.375, 0, 1.375, 3),
          "&:last-child": {
            paddingBottom: theme.spacing(1),
            [theme.breakpoints.up("sm")]: {
              padding: theme.spacing(1, 1, 1, 3),
            },
          },
        },
        contentWrapper: {
          height: "100%",
          justifyContent: "space-between",
        },
        cardTitle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          color: theme.palette.text.primary,
        },
        eventInfoItem: {
          display: "flex",
          flexDirection: "row",
          color: theme.palette.text.secondary,
          "& .MuiTypography-root": {
            fontSize: "0.875rem",
            fontWeight: 500,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
          },
        },
        iconWrapper: {
          width: "auto",
          marginRight: theme.spacing(1),
          "& .MuiSvgIcon-root": {
            height: 14,
            color: theme.palette.primary.main,
          },
        },
      },
    },
    ListPromotionItemCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: `${(theme.shape.borderRadius * 2) / 3}px`,
          display: "flex",
          flexDirection: "row",
          height: 104,
          padding: theme.spacing(1, 0.5),
        },
        cardMedia: {
          width: 88,
          maxHeight: 88,
          borderRadius: "5px",
          objectFit: "cover",
          [theme.breakpoints.up("sm")]: {
            width: 95,
          },
        },
        cardContent: {
          padding: theme.spacing(0.5, 1, 0.5, 2),
          "&:last-child": {
            paddingBottom: theme.spacing(0.5),
            [theme.breakpoints.up("sm")]: {
              padding: theme.spacing(0.5, 1, 0.5, 2),
            },
          },
        },
        contentWrapper: {
          height: "100%",
          gap: theme.spacing(2),
          justifyContent: "space-between",
        },
        cardTitle: {
          fontWeight: 600,
          fontSize: "14px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          color: theme.palette.text.primary,
        },
        promotionInfoItem: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: theme.palette.text.secondary,
          "& .MuiTypography-root": {
            fontSize: "0.75rem",
            fontWeight: 400,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
          },
        },
        iconWrapper: {
          width: "auto",
          marginRight: theme.spacing(1),
          "& .MuiSvgIcon-root": {
            height: 16,
            color: "inherit",
          },
        },
      },
    },
    ListPrivilegeItemCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: "8px",
          padding: theme.spacing(1),
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(2),
          "& .MuiSvgIcon-root": {
            color: theme.palette.grey[500],
            width: 16,
            height: 16,
          },
        },
        label: {
          color: theme.palette.text.secondary,
          fontWeight: 600,
        },
      },
    },
    FeatureSummary: {
      styleOverrides: {
        root: {},
        container: {
          display: "flex",
          gap: theme.spacing(2),
          boxShadow: "none",
        },
        logoContainer: {
          width: "60px",
          height: "60px",
        },
        infoSection: {},
        infoContainer: {},
        title: {
          marginBottom: theme.spacing(1),
        },
        endAdornment: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          width: "80px",
          height: "80px",
          paddingLeft: theme.spacing(2),
          "&:after": {
            content: "''",
            position: "absolute",
            width: "1px",
            top: 0,
            left: 0,
            bottom: 0,
            backgroundColor: theme.palette.grey[500],
            marginTop: theme.spacing(1.25),
            marginBottom: theme.spacing(1.25),
          },
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "subtitle1" },
          style: {
            lineHeight: 1.4,
          },
        },
      ],
    },
    DirectionStepItem: {
      styleOverrides: {
        root: {
          "& .MuiStepLabel-iconContainer": {
            paddingRight: theme.spacing(4),
          },
          "&:first-of-type": {
            "& .MuiListItemText-primary": {
              fontWeight: 700,
            },
          },
          "&:last-of-type": {
            "& .MuiListItemText-primary": {
              fontWeight: 600,
            },
            "& .MuiStepLabel-iconContainer": {
              paddingLeft: theme.spacing(0.5),
              paddingRight: theme.spacing(3),
            },
          },

          "& .MuiStepIcon-root": {
            width: 37,
            height: 37,
            "& .MuiStepIcon-text": {
              fontSize: theme.typography.caption.fontSize,
            },
          },
          "& .MuiStepLabel-vertical": {
            padding: 0,
          },
        },
        listItem: {
          "& .MuiListItemText-primary": {
            whiteSpace: "normal",
            color: theme.palette.text.primary,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.subtitle2.fontWeight,
          },
          "& .MuiListItemText-secondary": {
            fontSize: "0.75rem",
          },
          "& .MuiListItemSecondaryAction-root": {
            marginLeft: "auto",
            position: "static",
            transform: "none",
            color: theme.palette.text.secondary,
            right: 0,
          },
        },
      },
    },
    EmptyData: {
      styleOverrides: {
        root: {
          width: "100%",
          alignItems: "center",
          "& .MuiSvgIcon-root": {
            width: "120px",
            height: "120px",
            color: theme.palette.grey[200],
          },
        },
        secondaryText: {
          color: theme.palette.grey[600],
        },
        iconWrapper: {
          marginBottom: theme.spacing(1),
        },
      },
    },
    NewShopsPage: {
      styleOverrides: {
        emptyDataContainer: {
          height: ``,
          // Prevent content page overlap with footer shadow by adding a bottom space (30px)
          //72 px is container padding
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    },
    OccupantCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: "10px",
          width: "100%",
        },
        cardTitle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          color: theme.palette.text.primary,
        },
        cardMediaWrapper: {
          position: "relative",
        },
        cardMedia: {
          height: "180px",
        },
        cardContent: {
          padding: theme.spacing(1.5, 2),
          "&:last-child": {
            paddingBottom: theme.spacing(1),
          },
        },
      },
    },
    UserLocationListItem: {
      styleOverrides: {
        icon: {
          background: "transparent",
          color: theme.palette.text.primary,
          fontSize: "3rem",
          "& .MuiSvgIcon-root": {
            fontSize: "inherit",
          },
        },
      },
    },
    FeatureListItem: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "row",
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
        adornmentDivider: {
          marginLeft: theme.spacing(3),
          marginRight: theme.spacing(2),
          borderRight: "1px solid rgba(203, 203, 203, 0.5)",
        },
        adornmentWrapper: {
          display: "flex",
          flexDirection: "row",
          height: "55px",
          "& img": {
            objectFit: "contain",
            maxWidth: "60px",
            maxHeight: "55px",
          },
        },
        bottomDivider: {
          borderBottom: "1px solid rgba(203, 203, 203, 0.5)",
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
        },
        listItemText: {
          "& .MuiListItemText-primary": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            lineHeight: 1.4,
            wordBreak: "break-all",
          },
          "& .MuiListItemText-secondary": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            lineHeight: 1.4,
          },
        },
        listItemAvatar: {
          "& .MuiAvatar-root": {
            fontSize: "2.275rem",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "inherit",
          },
        },
        boothNameText: {
          display: "none",
        },
      },
    },
    DirectionSearchContainer: {
      styleOverrides: {
        wrapper: {
          width: "100%",
          [theme.breakpoints.down("sm")]: {
            maxWidth: "100%",
          },
        },
        suggestionList: {
          marginTop: theme.spacing(8),
          // direction search box = 108 px
          // avoid step button 32 px + top-bottom margin 16px * 2 = 64px
          // top-bottom page layout padding 24px * 2 = 48px
          maxHeight: `calc(100vh - 220px)`,
          overflow: "auto",
          background: theme.palette.background.paper,
          boxShadow: theme.shadows[1],
          position: "absolute",
          zIndex: theme.zIndex.drawer + 1,
          borderRadius: "10px",
          //left-right layout padding = 24 * 2 = 48px
          width: `calc(100vw - 48px)`,
          [theme.breakpoints.up("sm")]: {
            //left-right layout padding = 24 * 2 = 48px
            maxWidth: mixins.mobileDirectionSearch.width - 48,
          },
        },
      },
    },
    AppOrdinalSelector: {
      styleOverrides: {
        root: {
          position: "fixed",
          bottom: theme.spacing(27),
          left: theme.spacing(2),
          borderRadius: `${theme.shape.borderRadius * 1.33}px`,
          backgroundColor: theme.palette.background.default,
          boxShadow: theme.shadows[15],
          width: 40,
          padding: theme.spacing(0.25),
        },
        listItemButton: {
          justifyContent: "center",
          borderRadius: 9999,
          backgroundColor: theme.palette.background.default,
          fontSize: "0.875rem",
          fontWeight: theme.typography.fontWeightRegular,
          transition: `color ${theme.transitions.duration.short}ms ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut} , background-color ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
          paddingTop: theme.spacing(0.9375),
          paddingBottom: theme.spacing(0.9375),
          "&.Mui-selected": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
      },
    },
    AppPageCard: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          height: "100%",
          boxShadow: "none",
        },
      },
    },
    AppPageCardContent: {
      styleOverrides: {
        root: {
          height: "100%",
          padding: theme.spacing(2.5),
        },
      },
    },
    LanguageButton: {
      defaultProps: {
        MenuProps: {
          transformOrigin: { horizontal: "left", vertical: "bottom" },
          anchorOrigin: { horizontal: "left", vertical: "top" },
        },
      },
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
          },
        },
        menuPaper: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          marginTop: theme.spacing(1.5),
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            marginLeft: theme.spacing(-0.5),
            marginRight: theme.spacing(1),
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            bottom: 0,
            left: `50%`,
            width: `10px`,
            height: `10px`,
            backgroundColor: theme.palette.background.paper,
            transform: "translate(-50%, 50%) rotate(45deg)",
            zIndex: 0,
          },
        },
        label: {},
        avatar: {
          width: "24px",
          height: "24px",
          padding: "2px",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.10)",
          "&> img": {
            borderRadius: 9999,
          },
          marginBottom: "8px",
        },
      },
    },
    AppQRCode: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
          backgroundColor: "transparent",
          boxShadow: "none",
        },
        qrCodeContent: {
          display: "flex",
          borderRadius: "8px",
          border: `5px solid ${theme.palette.primary.main}`,
          width: "110px",
          padding: 0,
        },
        qrCodeCaption: {
          fontSize: "1rem",
          paddingTop: theme.spacing(1.5),
        },
        qrCode: {
          width: "100%",
          height: "100%",
          border: "4px solid #fff",
        },
      },
    },
    AppDetailPage: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          height: "100%",
        },
        mediaCover: {
          height: "159px",
          position: "relative",
          ":not(.no-filter):after": {
            content: "''",
            display: "flex",
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%)`,
          },
        },
        avatarRoot: {
          margin: 0,
          position: "absolute",
          width: "120px",
          height: "120px",
          borderRadius: "15px",
          top: "-80px",
          fontSize: "3rem",
          boxShadow: `0px 8px 24px 0px rgba(155, 155, 155, 0.15)`,
        },
        titleText: {
          fontWeight: 700,
        },
        captionText: {
          color: theme.palette.text.secondary,
          fontWeight: 700,
        },
        contentContainer: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: theme.spacing(0, 3.75),
          flexGrow: 1,
        },
        headerContainer: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          paddingTop: theme.spacing(7),
        },
        titleContainer: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(1),
        },
        titleActionContainer: {
          marginTop: theme.spacing(2.5),
        },
        descriptionContainer: {
          marginTop: theme.spacing(2.5),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          color: theme.palette.text.secondary,
          minHeight: "30px",
        },
        descriptionText: {
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 4,
          overflow: "hidden",
          maxHeight: "96px",
        },
        locationContainer: {
          marginTop: theme.spacing(4),
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          gap: theme.spacing(9),
        },
        locationDetail: {
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(3),
          "& .MuiListItem-root": {
            padding: 0,
          },
          "& .MuiListItemText-root": {
            gap: theme.spacing(1),
            display: "flex",
            flexDirection: "column",
            textTransform: "uppercase",
            margin: 0,
          },
          "& .MuiListItemText-primary": {
            color: theme.palette.text.secondary,
            fontWeight: 400,
            fontSize: "1rem",
          },
          "& .MuiListItemText-secondary": {
            color: theme.palette.text.primary,
            fontWeight: 700,
            fontSize: "1.125rem",
          },
        },
        buildingLogo: {
          height: "50px",
          marginLeft: theme.spacing(5),
          "& img": {
            width: "100%",
            height: "100%",
          },
        },
        actionContainer: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          paddingBottom: theme.spacing(3.75),
          marginTop: "auto",
        },
        stretchButton: {
          paddingLeft: theme.spacing(7.5),
          paddingRight: theme.spacing(7.5),
        },
      },
    },
    OrdinalSelectMenu: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          height: "56px",
          width: "171px",
          textAlign: "center",
          borderRadius: "9999px",
          boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.20)",
          "&.Mui-focused": {
            border: "none",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "& svg": {
              color: theme.palette.primary.contrastText,
            },
          },
          "& .MuiSelect-select.MuiInputBase-input": {
            paddingTop: theme.spacing(1.75),
            paddingBottom: theme.spacing(1.75),
            paddingLeft: theme.spacing(2.5),
            fontSize: "1.5rem",
            minHeight: "unset",
          },
        },
        paper: {
          backgroundColor: "transparent",
          boxShadow: "none",
          alignItems: "center",
          maxHeight: "35vh",
          //hide scrollbar
          "::-webkit-scrollbar": {
            display: "none",
          },
          MsOverflowStyle: "none",
          scrollbarWidth: "none",
        },
        menuList: {
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        },
        menuItem: {
          margin: theme.spacing(1),
          backgroundColor: theme.palette.background.paper,
          borderRadius: "50%",
          height: "48px",
          width: "48px",
          padding: 0,
          minHeight: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transformOrigin: "top center",
          animation: `slideUp 300ms ease-in-out forwards`,
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.contrastText} `,
            color: theme.palette.primary.contrastText,
            ":hover": {
              backgroundColor: theme.palette.primary.main,
            },
          },
          ":hover": {
            backgroundColor: theme.palette.primary.main,
          },
        },
        text: {
          fontSize: "1.25rem",
          textTransform: "uppercase",
          letterSpacing: "1px",
          color: theme.palette.text.secondary,
        },
      },
    },
    VenueSelector: {
      styleOverrides: {
        root: {
          gap: theme.spacing(3),
        },
        buttonWrapper: {
          display: "flex",
          justifyContent: "center",
          transition: `transform ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeIn}`,
          "&.selected": {
            transform: "scale(1.77)",
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
          },
        },
        button: {
          width: 70,
          height: 70,
          padding: 0,
          backgroundColor: theme.palette.primary.main,
          borderRadius: "50%",
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            ":hover": {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
        icon: {
          width: "100%",
          height: "100%",
        },
        currentVenueChip: {
          width: "48px",
          height: "12px",
          position: "absolute",
          bottom: "-4px",
        },
      },
    },
    SwitchVenueStep: {
      styleOverrides: {
        root: {
          gap: theme.spacing(5),
          flexDirection: "row",
          alignItems: "center",
        },
        venueLogo: { height: "65px", width: "143px", objectFit: "contain" },
        iconContainer: {
          height: "100%",
          display: "flex",
          alignItems: "center",
          "& .MuiSvgIcon-root": {
            width: "40px",
            height: "40px",
            "& .primary": {
              fill: theme.palette.primary.main,
            },
          },
        },
      },
    },
    BorderLinearProgress: {
      styleOverrides: {
        root: {
          position: "relative",
          width: "100%",
          "&:after": {
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            content: "''",
            display: "block",
            height: 4,
            width: "100%",
            borderRadius: 5,
            backgroundColor: theme.palette.grey[300],
            right: 0,
          },
        },
        linearProgress: {
          height: 20,
          borderRadius: 15,
          zIndex: 1,
          ":after": {
            position: "absolute",
            zIndex: 5,
            top: "50%",
            transform: "translateY(-50%)",
            height: 4,
            content: "''",
            display: "block",
          },
          "&.MuiLinearProgress-colorPrimary": {
            backgroundColor: "transparent",
            "&.Mui-start": {
              ":after": {
                backgroundColor: theme.palette.primary.main,
              },
            },
            ":after": {
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              content: "''",
              display: "inline-block",
              width: 14,
              height: 14,
              borderRadius: "50%",
              backgroundColor: theme.palette.grey[300],
              zIndex: 1,
              left: 0,
            },
            "&.Mui-complete": {
              ":before": {
                backgroundColor: theme.palette.primary.main,
              },
              ":after": {
                backgroundColor: theme.palette.primary.main,
              },
            },
            ":before": {
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              content: "''",
              display: "inline-block",
              width: 14,
              height: 14,
              borderRadius: "50%",
              backgroundColor: theme.palette.grey[300],
              zIndex: 1,
              right: 0,
            },
          },
          "& .MuiLinearProgress-bar": {
            margin: "auto 0",
            height: 4,
            borderRadius: 5,
            backgroundColor: theme.palette.primary.main,
            boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
          },
        },
      },
    },
    VerticalLinearProgress: {
      styleOverrides: {
        root: {
          height: "186px",
          margin: theme.spacing(1.25, 0),
        },
        slider: {
          color: theme.palette.primary.main,
          width: "3px",
          borderRadius: 0,
          "& .MuiSlider-thumb": {
            width: "21px",
            height: "21px",
            transition: `bottom ${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
            "&.Mui-focusVisible": {
              boxShadow: "none",
            },
          },
          "& .MuiSlider-rail": {
            color: theme.palette.grey[300],
            opacity: 1,
          },
          "& .MuiSlider-track": {
            boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
            borderWidth: 0,
            transition: `height ${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
          },
          '& input[type="range"]': {
            WebkitAppearance: "slider-vertical",
          },
        },
      },
    },
    CategoryQuickSearch: {
      styleOverrides: {
        categoryFilterBarContainer: {
          position: "relative",
          order: 1,
          "&:after": {
            display: "flex",
            pointerEvents: "none",
            content: "''",
            width: "80px",
            height: "100%",
            position: "absolute",
            top: 0,
            right: 0,
            background:
              "linear-gradient(-90deg ,rgba(0, 0, 0, 0.7) 10%, transparent 90%)",
          },
        },
        scrollPane: {
          paddingTop: theme.spacing(0),
          paddingBottom: theme.spacing(0),
        },
        categoryFilterBar: {
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
            display: "none",
          },
          "& .highlight .MuiSvgIcon-root": {
            display: "block",
          },
          "& .MuiButtonBase-root": {
            padding: theme.spacing(1.5, 1),
          },
          "& .MuiButtonBase-root.MuiToggleButtonGroup-grouped:first-of-type": {
            marginLeft: 0,
          },
        },
      },
    },
    MapUpdateLoadingScreen: {
      styleOverrides: {
        backdrop: {
          zIndex: theme.zIndex.modal,
        },
        container: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(1),
          color: theme.palette.primary.main,
        },
      },
    },
    VuiNotificationContainer: {
      styleOverrides: {
        root: {},
        snackbarContent: {
          marginTop: `${mixins.hKioskHeader?.height || 100}px`,
          marginRight: "calc(32vw - 8px)", //aside width,
        },
      },
    },
    SuggestionListItem: {
      defaultProps: {},
    },
  },
})
