import { useEffect, useMemo, useCallback } from "react"
import { useParams, useLocation } from "react-router-dom"
import _ from "lodash"
import { GTM_VIEW_FEATURE_DETAIL } from "constant"
import config from "config"

import { useVenue } from "providers/venue"
import { useGeoLocation } from "providers/geolocation"

import { getFeatureDetail } from "utils/feature"
import { pushDataLayer } from "utils/googletagmanager/pushDataLayer"

export const useFeatureDetail = () => {
  const { dataLoaded, findFeatureById, mapTheme, promotions } = useVenue()
  const { placeId } = useParams()

  const feature = useMemo(
    () => (dataLoaded ? findFeatureById(placeId) : null),
    [dataLoaded, placeId, findFeatureById]
  )

  const { deviceLocation, deviceLocationName } = useGeoLocation()
  const location = useLocation()
  const mobileBaseUrl = config("mobileBaseUrl")

  const featurePromotions = useMemo(
    () =>
      promotions.filter(
        (promotion) => promotion.properties?.feature?.id === placeId
      ),
    [placeId, promotions]
  )

  const placeDetailMobileUrl = useMemo(
    () =>
      `${mobileBaseUrl}${location.pathname}?user-location=${
        _.get(deviceLocation, "id") || ""
      }&location-name=${deviceLocationName || ""}`,
    [mobileBaseUrl, deviceLocation, location, deviceLocationName]
  )

  const featureDetail = useMemo(
    () => getFeatureDetail(feature) || {},
    [feature]
  )
  useEffect(() => {
    try {
      if (feature) {
        const localCategoriesName = _.map(
          feature?.properties?.local_categories,
          (taxonomy) => _.get(taxonomy, "properties.name.en")
        ).join(",")
        pushDataLayer({
          event: GTM_VIEW_FEATURE_DETAIL,
          feature_id: _.get(feature, "id"),
          feature_type: _.get(feature, "feature_type"),
          feature_name: _.get(feature, "properties.name.en"),
          feature_category: _.get(feature, "properties.category"),
          local_categories: localCategoriesName,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }, [feature])

  const getAmenityIconProp = useCallback(
    (amenityCategory) => {
      const iconPath = _.get(
        mapTheme,
        `${amenityCategory}.label.marker.symbol.markerPath`
      )
      const image = _.get(
        mapTheme,
        `${amenityCategory}.label.marker.symbol.markerFile`
      )
      return iconPath ? { iconPath } : { image }
    },
    [mapTheme]
  )

  const categoryIconProp = useMemo(() => {
    if (feature && featureDetail) {
      return feature.feature_type === "amenity"
        ? getAmenityIconProp(featureDetail.category)
        : { iconPath: featureDetail.categoryIconPath }
    }
  }, [getAmenityIconProp, featureDetail, feature])

  return {
    ...featureDetail,
    promotions: featurePromotions,
    feature,
    dataLoaded,
    placeDetailMobileUrl,
    categoryIconProp,
  }
}
