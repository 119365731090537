import _ from "lodash"
import { matchPath } from "react-router-dom"

export const parseEntry = ({ attributes, id, ...rest }) => {
  return { ...attributes, ...rest, id }
}

export const parseEntries = (entries = []) => {
  if (!_.isArray(entries)) return []
  return entries.map(parseEntry)
}

export const getMatchPathOptionByFeatureId = (featureId) => {
  const [featureType] = `${featureId}`.split("-")
  const routePatterns = {
    occupant: {
      pattern: "/maps/place/occupant-:id",
      path: `/maps/place/${featureId}`,
    },
    amenity: {
      pattern: "/maps/place/amenity-:id",
      path: `/maps/place/${featureId}`,
    },
    promotion: {
      pattern: "/promotions/promotion-:id",
      path: `/promotions/${featureId}`,
    },
    event: {
      pattern: "/events/event-:id",
      path: `/events/${featureId}`,
    },
  }
  return _.get(routePatterns, featureType, null)
}

export const createVenueLink = (featureId) => {
  const matchPathOption = getMatchPathOptionByFeatureId(featureId)
  return matchPathOption
    ? matchPath(matchPathOption.pattern, matchPathOption.path)?.pathname
    : matchPathOption
}
