import { CreateGetListQueryFnOptions } from "../types"

import { clientQuery } from "../utils"

export const createGetListQueryFn =
  <TRecord = any, TQueryFnData = any>(
    sourceData: TRecord[],
    options?: CreateGetListQueryFnOptions<TRecord>
  ) =>
  async (): Promise<TQueryFnData> =>
    clientQuery<TRecord>(sourceData, options) as TQueryFnData
