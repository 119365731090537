import { Link, useParams } from "react-router-dom"
import { Box, Typography, useTheme } from "@mui/material"

import { useOccupantPromotion } from "hooks/promotion/useOccupantPromotion"
import { useTranslation } from "providers/i18n"

import { AppNavigation } from "containers/kiosk-h/AppNavigation"
import { PageCard, PageCardContent } from "components/card"
import { MotionDiv } from "components/motions"
import { NavigationControl } from "components/kioskHorizontal/navigation"
import { PromotionCard } from "components/domains/promotions"
import { Image } from "components/Image"

export const OccupantPromotionKioskHPage = ({ Layout = Box }) => {
  const { occupantId } = useParams()
  const { t } = useTranslation()

  const { occupantName, venueLogo, promotions } =
    useOccupantPromotion(occupantId)

  const theme = useTheme()
  const { styleOverrides = {} } =
    theme.components[`OccupantPromotionPage`] || {}

  const sideBarStyleOverrides =
    theme.components[`AppSidebar`]?.styleOverrides || {}

  const { styleOverrides: bannerStyleOverrides = {} } =
    theme.components[`AppBanner`] || {}

  const {
    headerContainer,
    headerText,
    headerLogoWrapper,
    headerLogo,
    contentContainer,
    gridContainer,
  } = styleOverrides
  const { root } = bannerStyleOverrides
  const { wrapper, body, footer } = sideBarStyleOverrides

  return (
    <Layout
      aside={
        <PageCard>
          <Box sx={wrapper}>
            <Box sx={root}></Box>
            <Box sx={body}>
              <AppNavigation />
            </Box>
            <Box sx={footer}>
              <NavigationControl BackButtonProps={{ hidden: true }} />
            </Box>
          </Box>
        </PageCard>
      }
    >
      <PageCard>
        <PageCardContent>
          <MotionDiv
            sx={contentContainer}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.15 }}
          >
            {/* Header */}
            <Box sx={headerContainer}>
              <Box sx={headerLogoWrapper}>
                <Image src={venueLogo} sx={headerLogo} />
              </Box>
              <Box>
                <Typography component="h1" variant="heading" sx={headerText}>
                  {t("navigation.Promotions")} : {t(occupantName)}
                </Typography>
              </Box>
            </Box>
            {/* Content */}
            <Box sx={gridContainer}>
              {promotions.map((promotion) => (
                <Box
                  key={`occupant-promotion-${promotion?.id}`}
                  component={Link}
                  to={`/promotions/${promotion.id}`}
                >
                  <PromotionCard promotion={promotion} />
                </Box>
              ))}
            </Box>
          </MotionDiv>
        </PageCardContent>
      </PageCard>
    </Layout>
  )
}
