import { useState, useCallback, Fragment } from "react"
import LanguageIcon from "@mui/icons-material/Language"
import {
  useTheme,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Avatar,
} from "@mui/material"
import { useTranslation } from "react-i18next"

import { useVenue } from "../../providers/venue"

export const LanguageSelector = ({ onSwitchedLanguage }) => {
  const [open, setOpen] = useState(false)
  const { t, i18n } = useTranslation()
  const { locales } = useVenue()
  const theme = useTheme()
  const styleOverrides = theme.components[`AppSidebar`]?.styleOverrides || {}
  const {
    navList,
    navListItem,
    subListItem,
    subList,
    subDivider,
    flagIcon,
    navDivider,
  } = styleOverrides
  const switchLanguage = useCallback(
    (lang) => (e) => {
      i18n.changeLanguage(lang)
      onSwitchedLanguage(lang)
    },
    [i18n, onSwitchedLanguage]
  )

  return (
    <List sx={navList} disablePadding>
      <ListItemButton onClick={(e) => setOpen(!open)} sx={navListItem}>
        <ListItemIcon>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText primary={t("Languages")} />
      </ListItemButton>
      <Divider sx={navDivider} />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={subList}>
          {locales.map((locale, index) => (
            <Fragment key={locale.localeKey}>
              <ListItemButton
                sx={subListItem}
                onClick={switchLanguage(locale.localeKey)}
              >
                <ListItemIcon>
                  <Avatar
                    src={`https://flagcdn.com/h40/${locale.flagCode}.webp`}
                    sx={flagIcon}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t(
                    `localization.navigation.item.${locale.localeKey}`,
                    locale.localizedName
                  )}
                />
              </ListItemButton>
              <Divider sx={subDivider} />
            </Fragment>
          ))}
        </List>
      </Collapse>
    </List>
  )
}
