import { useMemo } from "react"
import { Box, useTheme } from "@mui/material"

import { getIcon } from "utils/icon"

import { useTranslation } from "providers/i18n"
import { useService } from "extensions/one-siam/hooks/useService"

import { AppNavigation } from "containers/kiosk-v"
import { Head } from "components/Head"
import { EmptyData } from "components/EmptyData"
import { MotionDiv, showContent } from "components/motions"
import { FooterAction } from "extensions/one-siam/components/domains/navigation/FooterAction"
import { ServiceIcon } from "extensions/one-siam/components/icons"
import { ServiceCard } from "extensions/one-siam/components/domains/service/ServiceCard"

export const ServicesKioskVPage = ({ Layout = Box }) => {
  const { t } = useTranslation({ defaultValue: null })
  const { evenColServices, oddColServices, hasData } = useService()
  const theme = useTheme()

  const emptyIcon = useMemo(
    () => getIcon(theme?.assets?.empty?.service) || <ServiceIcon />,
    [theme]
  )

  const { styleOverrides = {} } = theme.components?.[`ServicePage`] || {}
  const {
    root,
    gridContainer,
    gridItem,
    emptyDataContainer,
    navigationWrapper,
    mainMenuContainer,
    footerActionContainer,
  } = styleOverrides

  return (
    <>
      <Head title={t("Service & Privilege")} />
      <Layout>
        <Box sx={root}>
          {hasData ? (
            <MotionDiv
              variants={showContent}
              initial="initial"
              animate="animate"
              sx={gridContainer}
            >
              <Box sx={gridItem}>
                {evenColServices.map((service, index) => (
                  <ServiceCard
                    key={`service-even-item-${index}`}
                    data={service}
                  />
                ))}
              </Box>
              <Box sx={gridItem}>
                {oddColServices.map((service, index) => {
                  return (
                    <ServiceCard
                      key={`service-odd-item-${index}`}
                      data={service}
                    />
                  )
                })}
              </Box>
            </MotionDiv>
          ) : (
            <MotionDiv
              variants={showContent}
              initial="initial"
              animate="animate"
              sx={emptyDataContainer}
            >
              <EmptyData icon={emptyIcon} primary={t("Coming soon")} />
            </MotionDiv>
          )}
          <Box key="app-navigation" sx={navigationWrapper}>
            <Box sx={mainMenuContainer}>
              <AppNavigation />
              <FooterAction sx={footerActionContainer} />
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  )
}
