import { themeOptionFactories } from "./theme"
import { FullHeightLayout } from "layouts/kiosk-v"
import { PageLayout } from "./layouts/PageLayout"
import { MapPageLayout } from "./layouts/MapPageLayout"

import { DirectionLayoutKioskVPage } from "pages/maps/dir/[origin]/[destination]/_layout.kiosk-v"

// Mobile pages
import { HomePage } from "./pages/home"
import { Promotions } from "./pages/promotions/index"
import { PromotionDetail } from "./pages/promotions/[promotionId]"
import { Events } from "./pages/events/index"
import { EventDetail } from "./pages/events/[eventId]"
import { Directory } from "./pages/directory/index"
import { DiningPage } from "./pages/dining/index"
import { DirectionLayout } from "pages/maps/dir/[origin]/[destination]/_layout"
import { DirectionOverviewPage } from "./pages/maps/dir/[origin]/[destination]"
import { StepByStep } from "./pages/maps/dir/[origin]/[destination]/navigate/steps/[step]"
import { PlaceDetailPage } from "./pages/maps/place/[placeId]"
import { MapOrdinal } from "./pages/maps/[ordinal]"
import { FeatureByCategory } from "./pages/maps/[featureType]/[category]"

// Kiosk pages
import { HomePage as HomeKioskVPage } from "./pages/home/index.kiosk-v"
import { DiningKioskVPage } from "./pages/dining/index.kiosk-v"
import { DirectoryKioskVPage } from "./pages/directory/index.kiosk-v"
import { PlaceDetailKioskVPage } from "./pages/maps/place/[placeId]/index.kiosk-v"
import { EventDetailKioskVPage } from "./pages/events/[eventId].kiosk-v"
import { DirectionOverviewKioskVPage } from "./pages/maps/dir/[origin]/[destination]/index.kiosk-v"
import { EventsKioskVPage } from "./pages/events/index.kiosk-v"
import { StepByStepKioskVPage } from "./pages/maps/dir/[origin]/[destination]/navigate/steps/[step]/index.kiosk-v"
import { PromotionsKioskVPage } from "./pages/promotions/index.kiosk-v"
import { PromotionDetailKioskVPage } from "./pages/promotions/[promotionId].kiosk-v"
import { MapOrdinalKioskVPage } from "./pages/maps/[ordinal].kiosk-v"
import { SearchKioskVPage } from "./pages/search/index.kiosk-v"
import { FeatureByCategoryKioskVPage } from "./pages/maps/[featureType]/[category].kiosk-v"
import { ServicesKioskVPage } from "./pages/services/index.kiosk-v"
import { ServicePage } from "./pages/services"

export const oneSiam = {
  themeOptionFactories,
  ignoreAppThemeFactory: false,
  routes: {
    mobile: [
      /**
       * Page Routes (mobile)
       */
      { type: "base", index: true, element: <HomePage /> },
      {
        path: "dining",
        element: <DiningPage Layout={PageLayout} />,
      },
      { path: "events", element: <Events /> },
      { path: "events/:eventId", element: <EventDetail /> },
      { path: "promotions", element: <Promotions /> },
      { path: "promotions/:promotionId", element: <PromotionDetail /> },
      {
        path: "services",
        element: <ServicePage />,
      },
      { path: "directory", element: <Directory /> },
      /**
       * Map Routes (mobile)
       */
      {
        type: "map",
        path: "ordinal/:ordinal",
        element: <MapOrdinal Layout={MapPageLayout} />,
      },
      { type: "map", path: "place/:placeId", element: <PlaceDetailPage /> },
      {
        type: "map",
        path: "dir/:origin/:destination",
        element: <DirectionLayout />,
        children: [
          {
            index: true,
            element: <DirectionOverviewPage />,
          },
          {
            path: "navigate/steps/:step",
            element: <StepByStep />,
          },
        ],
      },
      {
        type: "map",
        path: ":featureType/:category",
        element: <FeatureByCategory Layout={MapPageLayout} />,
      },
    ],
    "kiosk-v": [
      /**
       * Page Routes (kiosk-v)
       */
      {
        path: "dining",
        element: <DiningKioskVPage Layout={FullHeightLayout} />,
      },
      {
        path: "directory",
        element: <DirectoryKioskVPage Layout={FullHeightLayout} />,
      },
      {
        path: "events",
        element: <EventsKioskVPage Layout={FullHeightLayout} />,
      },
      {
        path: "events/:eventId",
        element: <EventDetailKioskVPage Layout={FullHeightLayout} />,
      },
      {
        path: "promotions",
        element: <PromotionsKioskVPage Layout={FullHeightLayout} />,
      },
      {
        path: "promotions/:promotionId",
        element: <PromotionDetailKioskVPage Layout={FullHeightLayout} />,
      },
      {
        path: "services",
        element: <ServicesKioskVPage Layout={FullHeightLayout} />,
      },
      {
        type: "base",
        element: <HomeKioskVPage Layout={FullHeightLayout} />,
        index: true,
      },
      {
        type: "base",
        path: "search",
        element: <SearchKioskVPage Layout={FullHeightLayout} />,
      },
      /**
       * Map Routes (kiosk-v)
       */
      {
        type: "map",
        path: "ordinal/:ordinal",
        element: <MapOrdinalKioskVPage />,
      },
      {
        type: "map",
        path: "place/:placeId",
        element: <PlaceDetailKioskVPage />,
      },
      {
        type: "map",
        path: "dir/:origin/:destination",
        element: <DirectionLayoutKioskVPage />,
        children: [
          {
            index: true,
            element: <DirectionOverviewKioskVPage />,
          },
          {
            path: "navigate/steps/:step",
            element: <StepByStepKioskVPage />,
          },
        ],
      },
      {
        type: "map",
        path: ":featureType/:category",
        element: <FeatureByCategoryKioskVPage Layout={MapPageLayout} />,
      },
    ],
    "kiosk-h": [],
  },
}
