import _ from "lodash"

export const createCardMediaPropsFromFileField = (media) => {
  const isVideo = /^video\//.test(_.get(media, "mime"))
  const src = _.get(media, "url")
  return {
    src,
    component: isVideo ? "video" : "img",
    ...(isVideo ? { autoPlay: true, muted: true, loop: true } : {}),
  }
}
