import { Box, useTheme } from "@mui/material"
import { Outlet } from "react-router-dom"

export const PageLayout = ({
  children,
  header = null,
  footer = null,
  ...props
}) => {
  const theme = useTheme()
  const styleOverrides = theme.components[`AppPageLayout`]?.styleOverrides || {}
  const { root } = styleOverrides

  return (
    <Box sx={root} {...props} data-name="PageLayout">
      <Outlet />
    </Box>
  )
}
