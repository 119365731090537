import * as maptalks from "maptalks"
import range from "lodash/range"
import { BaseObject } from "maptalks.three"
import * as THREE from "three"
import { largestRect } from "d3plus-shape"
import { max, merge, isNumber } from "lodash"

const OPTIONS = {
  // Allowing click through and prevent interaction
  interactive: false,
  altitude: 0,
}

const defaultFlatLabelOptions = {
  fontSize: 14,
  fontFamily: "Arial",
  margin: 0,
  scaleMin: 0.5,
  lineHeight: 1.05,
  scaleStep: 0.05,
  textAlign: "center",
  textBaseline: "middle",
  fillStyle: "#000",
}

const defaultRectAngleToCalc = [-85, ...range(-80, 80, 10), 85]

export const getMaterial = (text, flatLabelOptions) => {
  const options = merge({}, defaultFlatLabelOptions, flatLabelOptions)

  const {
    fontSize: initialFontSize,
    fontFamily,
    margin,
    scaleMin,
    scaleStep,
    fillStyle,
    lineHeight,
    textAlign,
    textBaseline,
  } = options

  const pixelMultiplier = 4
  const SIZE = 100 * pixelMultiplier
  const fontSize = initialFontSize * (pixelMultiplier * 1.25)
  const canvas = document.createElement("canvas")
  canvas.width = canvas.height = SIZE
  const ctx = canvas.getContext("2d")

  ctx.font = `${fontSize}px ${fontFamily}`
  ctx.textAlign = textAlign
  ctx.textBaseline = textBaseline
  ctx.fillStyle = fillStyle

  /** Automatically recalculate font size to fit the bound */
  const texts = text.split(/\n/g)
  let textWidth = max(texts.map((text) => ctx.measureText(text).width))
  let scale = 1
  while (scale > 0 && textWidth + 2 * margin > SIZE) {
    scale -= scaleStep
    ctx.font = `${scale * fontSize}px ${fontFamily}`
    textWidth = ctx.measureText(text).width
  }

  // If the text is larger than scaleMin, then render it
  const center = { x: 0.5 * SIZE, y: 0.5 * SIZE }

  if (scale > scaleMin) {
    texts.forEach((text, index) => {
      ctx.fillText(text, center.x, center.y + index * (fontSize * lineHeight))
    })
  }

  const texture = new THREE.Texture(canvas)
  texture.needsUpdate = true

  const material = new THREE.MeshPhongMaterial({
    map: texture,
    transparent: true,
  })
  return material
}

export class GroundLabel extends BaseObject {
  #angle = 0
  #bearing = 0
  #text = ""

  constructor(bound, options, layer) {
    options = maptalks.Util.extend({}, OPTIONS, options, {
      layer,
      coordinate: bound,
    })

    const {
      altitude,
      text,
      fontSize,
      fillStyle,
      textAlign,
      textBaseline,
      ...properties
    } = options

    super()
    this._initOptions(options)
    this.properties = properties
    const material = getMaterial(text, {
      fillStyle,
      fontSize,
      textAlign,
      textBaseline,
    })
    const rectAngle = isNumber(options.angle)
      ? [options.angle]
      : defaultRectAngleToCalc
    material.needsUpdate = true
    const rect = largestRect(bound, {
      cache: true,

      /**
       * Black magic here:
       * For some reason if we allow angle -90 or 90, some polygon will use that angle even if it's wrong angle to use.
       * So we remove -90 and 90 from choices, and use -85 & 85 instead.
       */
      angle: rectAngle,
    })
    const { cx, cy, width, angle } = rect

    this.#text = text
    this.#angle = angle

    const geometry = new THREE.PlaneGeometry(1, 1)

    this._createMesh(geometry, material)

    // set object3d position
    const z = layer.altitudeToVector3(altitude, altitude).x
    const position = layer.coordinateToVector3({ x: cx, y: cy }, z)

    const scale = width / 0.0006456122659
    this.getObject3d().scale.set(scale, scale, scale)
    this.getObject3d().position.copy(position)
    this.getObject3d().rotation.z = (Math.PI / 180) * this.#angle
  }

  set bearing(value) {
    this.#bearing = value
    const degree = this.#angle + this.#bearing
    const angle = degree > 90 || degree < -90 ? this.#angle + 180 : this.#angle
    this.getObject3d().rotation.z = (Math.PI / 180) * angle
  }
}
