// Mobile
import { HomePage } from "pages/home"
import { Directory } from "pages/directory"
import { Events } from "pages/events"
import { EventDetail } from "pages/events/[eventId]"
import { Promotions } from "pages/promotions"
import { PromotionDetail } from "pages/promotions/[promotionId]"
import { DiningPage } from "pages/dining"
import { NewShops } from "pages/new-shops"
import { MapOrdinal } from "pages/maps/[ordinal]"
import { PlaceDetailPage } from "pages/maps/place/[placeId]"
import { DirectionLayout } from "pages/maps/dir/[origin]/[destination]/_layout"
import { DirectionOverviewPage } from "pages/maps/dir/[origin]/[destination]"
import { StepByStep } from "pages/maps/dir/[origin]/[destination]/navigate/steps/[step]"
import { FeatureByCategory } from "pages/maps/[featureType]/[category]"

// Kiosk-h
import { ContentWithAside, MapPageLayout } from "layouts/kiosk-h"
import { HomePage as HomePageKioskHPage } from "pages/home/index.kiosk-h"
import { PromotionDetailKioskHPage } from "pages/promotions/[promotionId].kiosk-h"
import { OccupantPromotionKioskHPage } from "pages/promotions/occupant/[occupantId].kiosk-h"
import { DiningKioskHPage } from "pages/dining/index.kiosk-h"
import { EventsKioskHPage } from "pages/events/index.kiosk-h"
import { EventDetailKioskHPage } from "pages/events/[eventId].kiosk-h"
import { PromotionsKioskHPage } from "pages/promotions/index.kiosk-h"
import { DirectoryKioskHPage } from "pages/directory/index.kiosk-h"
import { NewShopsKioskHPage } from "pages/new-shops/index.kiosk-h"
import { MapOrdinalKioskHPage } from "pages/maps/[ordinal].kiosk-h"
import { PlaceDetailKioskHPage } from "pages/maps/place/[placeId]/index.kiosk-h"
import { FeatureByCategoryKioskHPage } from "pages/maps/[featureType]/[category].kiosk-h"
import { DirectionLayoutKioskHPage } from "pages/maps/dir/[origin]/[destination]/_layout.kiosk-h"
import { DirectionOverviewKioskHPage } from "pages/maps/dir/[origin]/[destination]/index.kiosk-h"
import { StepByStepKioskHPage } from "pages/maps/dir/[origin]/[destination]/navigate/steps/[step]/index.kiosk-h"

// Kiosk-v
import { FullHeightLayout } from "layouts/kiosk-v"
import { HomePage as HomePageKioskVPage } from "pages/home/index.kiosk-v"
import { NewShopsKioskVPage } from "pages/new-shops/index.kiosk-v"
import { PromotionsKioskVPage } from "pages/promotions/index.kiosk-v"
import { PromotionDetailKioskVPage } from "pages/promotions/[promotionId].kiosk-v"
import { DiningKioskVPage } from "pages/dining/index.kiosk-v"
import { DirectoryKioskVPage } from "pages/directory/index.kiosk-v"
import { EventDetailKioskVPage } from "pages/events/[eventId].kiosk-v"
import { EventsKioskVPage } from "pages/events/index.kiosk-v"
import { OccupantPromotionKioskVPage } from "pages/promotions/occupant/[occupantId].kiosk-v"
import { MapOrdinalKioskVPage } from "pages/maps/[ordinal].kiosk-v"
import { PlaceDetailKioskVPage } from "pages/maps/place/[placeId]/index.kiosk-v"
import { FeatureByCategoryKioskVPage } from "pages/maps/[featureType]/[category].kiosk-v"
import { DirectionLayoutKioskVPage } from "pages/maps/dir/[origin]/[destination]/_layout.kiosk-v"
import { DirectionOverviewKioskVPage } from "pages/maps/dir/[origin]/[destination]/index.kiosk-v"
import { StepByStepKioskVPage } from "pages/maps/dir/[origin]/[destination]/navigate/steps/[step]/index.kiosk-v"

// Standard routes
export const standardRoutes = {
  mobile: [
    {
      type: "base",
      index: true,
      element: <HomePage />,
    },
    /**
     * Page Routes (mobile)
     */
    { path: "events", element: <Events /> },
    { path: "events/:eventId", element: <EventDetail /> },
    { path: "promotions", element: <Promotions /> },
    { path: "promotions/:promotionId", element: <PromotionDetail /> },
    { path: "directory", element: <Directory /> },
    { path: "new-shops", element: <NewShops /> },
    { path: "dining", element: <DiningPage /> },
    /**
     * Map Routes (mobile)
     */
    { type: "map", path: "ordinal/:ordinal", element: <MapOrdinal /> },
    { type: "map", path: "place/:placeId", element: <PlaceDetailPage /> },
    {
      type: "map",
      path: "dir/:origin/:destination",
      element: <DirectionLayout />,
      children: [
        {
          index: true,
          element: <DirectionOverviewPage />,
        },
        {
          path: "navigate/steps/:step",
          element: <StepByStep />,
        },
      ],
    },
    {
      type: "map",
      path: ":featureType/:category",
      element: <FeatureByCategory />,
    },
  ],
  "kiosk-h": [
    {
      type: "base",
      index: true,
      element: <HomePageKioskHPage />,
    },
    /**
     * Page Routes (kiosk-h)
     */
    { path: "events", element: <EventsKioskHPage Layout={ContentWithAside} /> },
    {
      path: "events/:eventId",
      element: <EventDetailKioskHPage Layout={ContentWithAside} />,
    },
    {
      path: "promotions",
      element: <PromotionsKioskHPage Layout={ContentWithAside} />,
    },
    {
      path: "promotions/occupant/:occupantId",
      element: <OccupantPromotionKioskHPage Layout={ContentWithAside} />,
    },
    {
      path: "promotions/:promotionId",
      element: <PromotionDetailKioskHPage Layout={ContentWithAside} />,
    },
    {
      path: "directory",
      element: <DirectoryKioskHPage Layout={ContentWithAside} />,
    },
    {
      path: "new-shops",
      element: <NewShopsKioskHPage Layout={ContentWithAside} />,
    },
    { path: "dining", element: <DiningKioskHPage Layout={ContentWithAside} /> },
    /**
     * Map Routes (kiosk-h)
     */
    {
      type: "map",
      path: "ordinal/:ordinal",
      element: <MapOrdinalKioskHPage Layout={MapPageLayout} />,
    },
    {
      type: "map",
      path: "place/:placeId",
      element: <PlaceDetailKioskHPage Layout={MapPageLayout} />,
    },
    {
      type: "map",
      path: "dir/:origin/:destination",
      element: <DirectionLayoutKioskHPage />,
      children: [
        {
          index: true,
          element: <DirectionOverviewKioskHPage Layout={MapPageLayout} />,
        },
        {
          path: "navigate/steps/:step",
          element: <StepByStepKioskHPage Layout={MapPageLayout} />,
        },
      ],
    },
    {
      type: "map",
      path: ":featureType/:category",
      element: <FeatureByCategoryKioskHPage Layout={MapPageLayout} />,
    },
  ],
  "kiosk-v": [
    {
      type: "base",
      index: true,
      element: <HomePageKioskVPage />,
    },
    /**
     * Page Routes (kiosk-v)
     */
    {
      path: "events",
      element: <EventsKioskVPage Layout={FullHeightLayout} />,
    },
    {
      path: "events/:eventId",
      element: <EventDetailKioskVPage Layout={FullHeightLayout} />,
    },
    {
      path: "promotions",
      element: <PromotionsKioskVPage Layout={FullHeightLayout} />,
    },
    {
      path: "promotions/occupant/:occupantId",
      element: <OccupantPromotionKioskVPage Layout={FullHeightLayout} />,
    },
    {
      path: "promotions/:promotionId",
      element: <PromotionDetailKioskVPage Layout={FullHeightLayout} />,
    },
    {
      path: "directory",
      element: <DirectoryKioskVPage Layout={FullHeightLayout} />,
    },
    {
      path: "new-shops",
      element: <NewShopsKioskVPage Layout={FullHeightLayout} />,
    },
    {
      path: "dining",
      element: <DiningKioskVPage Layout={FullHeightLayout} />,
    },
    /**
     * Map Routes (kiosk-v)
     */
    {
      type: "map",
      path: "ordinal/:ordinal",
      element: <MapOrdinalKioskVPage />,
    },
    { type: "map", path: "place/:placeId", element: <PlaceDetailKioskVPage /> },
    {
      type: "map",
      path: "dir/:origin/:destination",
      element: <DirectionLayoutKioskVPage />,
      children: [
        {
          index: true,
          element: <DirectionOverviewKioskVPage />,
        },
        {
          path: "navigate/steps/:step",
          element: <StepByStepKioskVPage />,
        },
      ],
    },
    {
      type: "map",
      path: ":featureType/:category",
      element: <FeatureByCategoryKioskVPage />,
    },
  ],
}
