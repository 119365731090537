import merge from "lodash/merge"
import { QueryFunction, QueryKey } from "@tanstack/react-query"

import { CreateGetListQueryFnOptions } from "../types"

import { clientInfiniteQuery } from "../utils"

export const createInfiniteGetListQueryFn =
  <TRecord = unknown, TQueryFnData = any>(
    sourceData: TRecord[],
    options?: CreateGetListQueryFnOptions<TRecord>
  ): QueryFunction<TQueryFnData, QueryKey, number> =>
  async ({ pageParam = 1 }): Promise<TQueryFnData> => {
    const pagination = merge({}, options?.pagination, { page: pageParam })
    return clientInfiniteQuery<TRecord>(sourceData, {
      ...options,
      pagination,
    }) as TQueryFnData
  }
