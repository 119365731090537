import capitalize from "lodash/capitalize"
import { captureException } from "@sentry/react"
import {
  IsendCaptureExceptionToSentry,
  IsendCaptureExceptionToSentryOptions,
} from "interfaces"

const defaultCaptureExceptionOptions: IsendCaptureExceptionToSentryOptions = {
  error: null,
  level: "error",
  transactionName: "Unknown Transaction",
}
export const sendCaptureExceptionToSentry: IsendCaptureExceptionToSentry = (
  options
) => {
  const { error, level, transactionName } = {
    ...defaultCaptureExceptionOptions,
    ...options,
  }

  if (!error) return

  error.name = capitalize(level)

  captureException(error, (scope) => {
    scope.setLevel(level)
    scope.setTransactionName(transactionName)
    return scope
  })
}
