import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  FC,
  ReactNode,
} from "react"
import _ from "lodash"
import { IndoorMap } from "components/IndoorMap"
import { useVenue } from "providers/venue/"
import { IndoorMapContext } from "./IndoorMapContext"

import config from "config"

const FALLBACK_CENTER: [number, number] = [100.5017051, 13.7572619]
const DEFAULT_ZOOM = 18.5
const DEFAULT_MIN_ZOOM: number | null = null
const DEFAULT_MAX_ZOOM = 22
const DEFAULT_MOBILE_PIXEL_RATIO = 1
const DEFAULT_KIOSK_PIXEL_RATIO = 2

type IndoorMapProviderProps = {
  children: ReactNode
}

export const IndoorMapProvider: FC<IndoorMapProviderProps> = ({ children }) => {
  const [mapLoaded, setMapLoaded] = useState<boolean>(false)
  const [mapLoading, setMapLoading] = useState<boolean>(false)
  const [mapCreated, setMapCreated] = useState<boolean>(false)
  const indoorRef = useRef<IndoorMap | null>(null)
  const { venues, mapTheme, mapConfig, configLoaded } = useVenue()

  const defaultCenter = useMemo(() => {
    return _.get(
      venues,
      "[0].properties.display_point.coordinates",
      FALLBACK_CENTER
    ) as [number, number]
  }, [venues])

  const defaultBearing = useMemo(() => {
    return _.get(mapConfig, "default_bearing", 0) as number
  }, [mapConfig])

  const defaultPitch = useMemo(() => {
    return _.get(mapConfig, "default_pitch", 45) as number
  }, [mapConfig])

  const zoomOptions = useMemo(() => {
    const minZoom = _.get(mapConfig, "min_zoom", DEFAULT_MIN_ZOOM) as
      | number
      | null
    const maxZoom = _.get(mapConfig, "max_zoom", DEFAULT_MAX_ZOOM) as number
    return minZoom < maxZoom
      ? { minZoom, maxZoom }
      : { minZoom: DEFAULT_MIN_ZOOM, maxZoom: DEFAULT_MAX_ZOOM }
  }, [mapConfig])

  const minZoom = useMemo(() => zoomOptions.minZoom, [zoomOptions])
  const maxZoom = useMemo(() => zoomOptions.maxZoom, [zoomOptions])
  const isKioskMode = useMemo(
    () => ["kiosk-v", "kiosk-h"].includes(config("device")),
    []
  )
  const pixelRatio = useMemo(() => {
    return isKioskMode
      ? (_.get(
          mapConfig,
          "kiosk_pixel_ratio",
          DEFAULT_KIOSK_PIXEL_RATIO
        ) as number)
      : (_.get(
          mapConfig,
          "mobile_pixel_ratio",
          DEFAULT_MOBILE_PIXEL_RATIO
        ) as number)
  }, [mapConfig, isKioskMode])

  useEffect(() => {
    const indoorMap = new IndoorMap("map", {
      center: FALLBACK_CENTER,
      defaultZoom: DEFAULT_ZOOM,
      onMapLoading: () => {
        setMapLoading(true)
      },
      onMapReady: (e) => {
        setMapLoaded(true)
        setMapLoading(false)
        // TODO : Replace with hide all geometry later
        indoorRef.current?.changeLevelByOrdinal(0)
      },
    })
    indoorRef.current = indoorMap
    if (indoorRef.current instanceof IndoorMap) setMapCreated(true)
    return () => {
      indoorRef.current = null
      indoorMap.map.remove()
    }
  }, [])

  useEffect(() => {
    const indoorMap = indoorRef.current
    if (configLoaded && mapCreated && indoorMap instanceof IndoorMap) {
      indoorMap.defaultBearing = defaultBearing
      indoorMap.defaultCenter = defaultCenter
      indoorMap.setCenter(defaultCenter)
      indoorMap.pixelRatio = pixelRatio
      indoorMap.mapConfig = mapConfig
      indoorMap.minZoom = minZoom
      indoorMap.maxZoom = maxZoom
    }
  }, [
    configLoaded,
    defaultCenter,
    defaultBearing,
    mapCreated,
    mapConfig,
    minZoom,
    maxZoom,
    pixelRatio,
  ])

  const value = {
    indoorRef,
    mapLoaded,
    mapLoading,
    mapCreated,
    mapTheme,
    defaultBearing,
    defaultPitch,
  }

  return (
    <IndoorMapContext.Provider value={value}>
      {children}
    </IndoorMapContext.Provider>
  )
}
