import React, { useEffect } from "react"
import { useParams, useNavigate, Outlet } from "react-router-dom"
import { Box } from "@mui/material"
import { Head } from "components/Head"
import { useTranslation } from "providers/i18n"
import { useGeoLocation } from "providers/geolocation"

export const Layout = () => {
  const {
    isLoading,
    userLocation,
    scannedLocations,
    deviceLocation,
    locationVenue,
  } = useGeoLocation()
  const { t } = useTranslation()
  const params = useParams()
  const path = params["*"]
  const navigate = useNavigate()

  // TODO: Consider moving this logic to hook for
  useEffect(() => {
    if (isLoading) return
    switch (path) {
      case "":
        if (userLocation) {
          const {
            properties: { ordinal },
          } = userLocation
          navigate(`/maps/ordinal/${ordinal}`, { replace: true })
        } else {
          /** If user enter /maps (without any user-location query string), redirect to /maps/ordinal/0
           * replace = true, so when user click "back", it will go back to previous page instead of `/maps`
           */
          navigate("/maps/ordinal/0", { replace: true })
        }
        break
      default:
        break
    }
  }, [path, navigate, userLocation, isLoading])

  return (
    <>
      <Head title={t("Map")} />
      <Box data-name="MapLayout">
        <Outlet
          context={{ scannedLocations, deviceLocation, locationVenue } || {}}
        />
      </Box>
    </>
  )
}
