import React, { useCallback, useEffect } from "react"
import _ from "lodash"

import { useTheme, Box } from "@mui/material"
import { useVenue } from "providers/venue"
import { useAppUI } from "providers/ui"
import { useTranslation } from "providers/i18n"

import { AppNavigation } from "containers/kiosk-v"
import { OrdinalSelector } from "components/OrdinalSelector"
import { OrdinalSelectMenu } from "components/OrdinalSelector/OrdinalSelectMenu"
import { VenueSelector } from "components/VenueSelector"
import { FeatureChipToggleBar } from "components/domains/features/ChipToggleBar"
import { LanguageButton } from "components/Language"
import { SearchToggleButton } from "components/domains/search"
import { useMap } from "hooks/map/useMap"
import { useHighLightFeaturesMap } from "hooks/feature/useHighLightFeaturesMap"

import { pushDataLayer } from "utils/googletagmanager/pushDataLayer"
import { pluralize } from "utils/string"
import { GTM_AMENITY_SEARCH } from "constant"

export const FeatureByCategoryKioskVPage = () => {
  const { venues, isMultiOrdinalVenue, isMultiVenueProject, dataLoaded } =
    useVenue()
  const { openSearch } = useAppUI()
  const { t } = useTranslation()
  const {
    category,
    viewingOrdinal,
    viewingVenueId,
    levelInVenue,
    quickSearchFeatures,
    quickSearchCategories,
    searchParams,
    comingFromOrdinal,
    locationVenueId,
    onChangeVenue,
    findNearestOrdinal,
    setSearchParams,
    toggleQuickSearch,
  } = useMap()

  const handleClickQuickSearch = useCallback(
    (featureType, category) => {
      const pluralType = pluralize(featureType)
      toggleQuickSearch(pluralType, category)
    },
    [toggleQuickSearch]
  )

  const onClickOrdinal = useCallback(
    (ordinal) => {
      const currentParams = Object.fromEntries(searchParams.entries())
      setSearchParams({ ...currentParams, ordinal })
    },
    [setSearchParams, searchParams]
  )

  useEffect(() => {
    // When ordinal navigate from different page
    if (viewingVenueId && dataLoaded && !_.isNil(comingFromOrdinal)) {
      const nearestOrdinal = findNearestOrdinal(comingFromOrdinal)
      setSearchParams(
        { venue: viewingVenueId, ordinal: nearestOrdinal },
        { replace: true }
      )
    } else if (viewingVenueId && dataLoaded && _.isNil(viewingOrdinal)) {
      // When ordinal is not provided
      const nearestOrdinal = findNearestOrdinal(0)
      setSearchParams(
        { venue: viewingVenueId, ordinal: nearestOrdinal },
        { replace: true }
      )
    }
  }, [
    dataLoaded,
    category,
    comingFromOrdinal,
    viewingVenueId,
    viewingOrdinal,
    findNearestOrdinal,
    setSearchParams,
  ])

  useHighLightFeaturesMap(quickSearchFeatures, Number(viewingOrdinal))

  useEffect(() => {
    if (category) {
      pushDataLayer({
        event: GTM_AMENITY_SEARCH,
        amenity_category: category,
      })
    }
  }, [category])

  const theme = useTheme()
  const { styleOverrides = {} } = theme.components[`AppMap`] || {}
  const {
    actionContainer,
    actionButton,
    searchBarContainer,
    toolbarContainer,
    quickLinkContainer, //TODO:: planning about quick search's working flow later
    venueSelector,
    navigationWrapper,
    ordinalSelectMenu,
    ordinalSelector,
  } = styleOverrides

  return (
    <>
      {isMultiOrdinalVenue && (
        <Box sx={ordinalSelector}>
          <OrdinalSelector
            selectedOrdinal={Number(viewingOrdinal)}
            levels={levelInVenue}
            onClickOrdinal={onClickOrdinal}
          />
        </Box>
      )}
      {isMultiOrdinalVenue && (
        <Box sx={ordinalSelectMenu}>
          <OrdinalSelectMenu
            selectedOrdinal={Number(viewingOrdinal)}
            levels={levelInVenue}
            onChangeOrdinal={onClickOrdinal}
          />
        </Box>
      )}

      {isMultiVenueProject && (
        <Box sx={venueSelector}>
          <VenueSelector
            selectedVenue={viewingVenueId}
            venues={venues}
            onClickVenue={onChangeVenue}
            currentVenue={locationVenueId}
          />
        </Box>
      )}
      <Box key="app-navigation" sx={navigationWrapper}>
        <Box sx={quickLinkContainer}>
          <FeatureChipToggleBar
            categories={quickSearchCategories}
            onClick={handleClickQuickSearch}
          />
        </Box>
        <Box sx={toolbarContainer}>
          <Box sx={actionContainer}>
            <LanguageButton variant="contained" sx={actionButton} />
            {/* //TODO:: Add a wheel chair's mode handler function later */}
            {/*  <Button
            variant="contained"
            sx={actionButton}
            onClick={() => {}}
            startIcon={<RestroomWheelchairIcon />}
          /> */}
          </Box>
          <Box sx={searchBarContainer}>
            <SearchToggleButton
              placeholder={t("Search shops and more...")}
              onClick={openSearch}
            />
          </Box>
        </Box>
        <AppNavigation />
      </Box>
    </>
  )
}
