import { SvgIcon } from "@mui/material"

import { ReactComponent as LayerSVG } from "../../assets/svg/layer.svg"
import { ReactComponent as ClockSVG } from "../../assets/svg/clock.svg"
import { ReactComponent as CalendarSVG } from "../../assets/svg/calendar.svg"
import { ReactComponent as LocationSVG } from "../../assets/svg/location.svg"
import { ReactComponent as ServiceSVG } from "../../assets/svg/service.svg"
import { ReactComponent as PauseRoundedSVG } from "../../assets/svg/pause-rounded.svg"
import { ReactComponent as ReplayRoundedSVG } from "../../assets/svg/replay-rounded.svg"
import { ReactComponent as CancelRoundedSVG } from "../../assets/svg/cancel-rounded.svg"
import { ReactComponent as SearchSVG } from "../../assets/svg/search.svg"
import { ReactComponent as MenuSVG } from "../../assets/svg/menu.svg"
import { ReactComponent as ShopSVG } from "../../assets/svg/shop.svg"
import { ReactComponent as RestaurantSVG } from "../../assets/svg/restaurant.svg"

export const LayerIcon = (props) => (
  <SvgIcon component={LayerSVG} inheritViewBox {...props} />
)

export const ClockIcon = (props) => (
  <SvgIcon component={ClockSVG} inheritViewBox {...props} />
)

export const CalendarIcon = (props) => (
  <SvgIcon component={CalendarSVG} inheritViewBox {...props} />
)

export const LocationIcon = (props) => (
  <SvgIcon component={LocationSVG} inheritViewBox {...props} />
)

export const ServiceIcon = (props) => (
  <SvgIcon component={ServiceSVG} inheritViewBox {...props} />
)

export const PauseRoundedIcon = (props) => (
  <SvgIcon component={PauseRoundedSVG} inheritViewBox {...props} />
)

export const ReplayRoundedIcon = (props) => (
  <SvgIcon component={ReplayRoundedSVG} inheritViewBox {...props} />
)

export const CancelRoundedIcon = (props) => (
  <SvgIcon component={CancelRoundedSVG} inheritViewBox {...props} />
)

export const SearchIcon = (props) => (
  <SvgIcon component={SearchSVG} inheritViewBox {...props} />
)

export const MenuIcon = (props) => (
  <SvgIcon component={MenuSVG} inheritViewBox {...props} />
)

export const ShopIcon = (props) => (
  <SvgIcon component={ShopSVG} inheritViewBox {...props} />
)

export const RestaurantIcon = (props) => (
  <SvgIcon component={RestaurantSVG} inheritViewBox {...props} />
)
