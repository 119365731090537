import React, { useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  Box,
  useTheme,
  Typography,
  FormControlLabel,
  Switch,
  Button,
} from "@mui/material"
import { deepmerge } from "@mui/utils"

import { useVenue } from "providers/venue"
import { useTranslation } from "providers/i18n"

import { useStepByStep } from "hooks/direction/useStepByStep"
import { useStepByStepMap } from "hooks/direction/useStepByStepMap"

import { OrdinalSelector } from "components/OrdinalSelector"
import { PageCard } from "components/card"
import { NavigationControl } from "components/kioskHorizontal/navigation"
import {
  ArrowNextIcon,
  ArrowPreviousIcon,
  MarkerIcon,
  PauseIcon,
  PlayArrowIcon,
  RefreshArrowIcon,
  YouAreHereMarkerIcon,
} from "components/icons"
import { VerticalLinearProgress } from "components/Progress/VerticalLinearProgress"
import { SwitchVenueStep } from "components/domains/direction/SwitchVenueStep"
import { Image } from "components/Image"

export const StepByStepKioskHPage = ({ Layout = Box }) => {
  const { t } = useTranslation()

  const { isMultiOrdinalVenue, isMultiVenueProject } = useVenue()
  const {
    currentOrdinal,
    currentLevel,
    currentStepNumber,
    originName,
    originLevelName,
    originVenueName,
    originVenueData,
    destinationName,
    destinationLevelName,
    destinationVenueName,
    destinationVenueData,
    isGoingToOtherVenue,
    progressValue,
    route,
    elevatorPreferredMode,
    // Auto play
    hasNextStep,
    hasPrevStep,
    isPlaying,
    goToNextStep,
    goToPrevStep,
    restartAutoPlay,
    resumeAutoPlay,
    pauseAutoPlay,
  } = useStepByStep()

  useStepByStepMap(route, currentStepNumber)

  const theme = useTheme()

  const {
    root,
    stepContainer,
    stepTextContainer,
    stepItem,
    originIcon,
    destinationIcon,
    ordinalSelector,
    primaryText,
    secondaryText,
    headerWrapper,
    actionContainer,
    avoidStepButton,
    venueLogo,
    playerActionContainer,
    secondaryButton,
    primaryButton,
  } = theme.components[`AppNavigationStep`]?.styleOverrides || {}

  const sideBarStyleOverrides =
    theme.components[`AppSidebar`]?.styleOverrides || {}
  const { wrapper, body, footer, playerButton } = sideBarStyleOverrides

  const originFloorText = isMultiOrdinalVenue
    ? `${t("At Floor", {
        value: originLevelName,
      })} `
    : ""

  const destinationFloorText = isMultiOrdinalVenue
    ? `${t("At Floor", {
        value: destinationLevelName,
      })} `
    : ""

  const originVenueText = isMultiVenueProject ? t(originVenueName) : ""

  const destinationVenueText = isMultiVenueProject
    ? t(destinationVenueName)
    : ""

  const navigate = useNavigate()
  const { origin, destination } = useParams()
  const handleToggle = useCallback(() => {
    navigate(
      `/maps/dir/${origin}/${destination}?avoidSteps=${!elevatorPreferredMode}`,
      { replace: true }
    )
  }, [origin, destination, navigate, elevatorPreferredMode])

  return (
    <Layout
      aside={
        <PageCard>
          <Box sx={wrapper}>
            <Box sx={body}>
              <Box sx={root}>
                <Box sx={headerWrapper}>
                  {isGoingToOtherVenue ? (
                    <SwitchVenueStep
                      originVenueLogo={originVenueData?.logo}
                      destinationVenueLogo={destinationVenueData?.logo}
                    />
                  ) : (
                    <Image sx={venueLogo} src={originVenueData?.logo} />
                  )}
                </Box>
                <Box sx={stepContainer}>
                  <Box sx={stepItem}>
                    <MarkerIcon sx={destinationIcon} />
                    <Box sx={stepTextContainer}>
                      <Typography
                        variant="subTitle"
                        component="p"
                        sx={primaryText}
                      >
                        {t(destinationName)}
                      </Typography>
                      <Typography
                        variant="subTitle"
                        component="p"
                        sx={secondaryText}
                      >
                        {destinationFloorText || destinationVenueText
                          ? `${destinationFloorText}${destinationVenueText}`
                          : t("Destination")}
                      </Typography>
                    </Box>
                  </Box>
                  <VerticalLinearProgress value={progressValue} />
                  <Box sx={stepItem}>
                    <YouAreHereMarkerIcon sx={originIcon} />
                    <Box sx={stepTextContainer}>
                      <Typography
                        variant="subTitle"
                        component="p"
                        sx={primaryText}
                      >
                        {t(originName)}
                      </Typography>
                      <Typography
                        variant="subTitle"
                        component="p"
                        sx={secondaryText}
                      >
                        {originFloorText || originVenueText
                          ? `${originFloorText}${originVenueText}`
                          : t("Origin")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={actionContainer}>
                  <FormControlLabel
                    sx={avoidStepButton}
                    control={
                      <Switch
                        checked={elevatorPreferredMode}
                        onChange={handleToggle}
                        disabled={isPlaying}
                      />
                    }
                    label={t("Avoid Steps")}
                  />
                  <Box sx={playerActionContainer}>
                    <Button
                      variant="outlined"
                      sx={deepmerge(playerButton, secondaryButton)}
                      startIcon={<ArrowPreviousIcon />}
                      onClick={goToPrevStep}
                      disabled={isPlaying || !hasPrevStep}
                    >
                      {t("prev")}
                    </Button>
                    {isPlaying ? (
                      <Button
                        variant="outlined"
                        sx={deepmerge(playerButton, primaryButton)}
                        startIcon={<PauseIcon />}
                        onClick={pauseAutoPlay}
                        disabled={!isPlaying}
                      >
                        {t("stop")}
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        sx={deepmerge(playerButton, primaryButton)}
                        startIcon={
                          hasNextStep ? <PlayArrowIcon /> : <RefreshArrowIcon />
                        }
                        onClick={hasNextStep ? resumeAutoPlay : restartAutoPlay}
                      >
                        {hasNextStep ? t("play") : t("play again")}
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      sx={deepmerge(playerButton, secondaryButton)}
                      endIcon={<ArrowNextIcon />}
                      onClick={goToNextStep}
                      disabled={isPlaying || !hasNextStep}
                    >
                      {t("next")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={footer}>
              <NavigationControl />
            </Box>
          </Box>
        </PageCard>
      }
    >
      {isMultiOrdinalVenue && (
        <Box sx={ordinalSelector}>
          <OrdinalSelector
            levels={currentLevel}
            selectedOrdinal={currentOrdinal}
          />
        </Box>
      )}
    </Layout>
  )
}
