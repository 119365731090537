import { useCallback } from "react"
import { Options } from "@splidejs/splide"
import {
  Splide,
  SplideEventHandlers,
  SplideProps,
} from "@splidejs/react-splide"
import { Box, SxProps, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"
import isFunction from "lodash/isFunction"

import "@splidejs/react-splide/css"

import { getVisiblePaginationIndexRange } from "utils/carousel"

export interface IExtendOptions extends Options {
  limitPaginations?: number
}
export interface ICarouselProps extends Omit<SplideProps, "options"> {
  children: React.ReactNode
  sx?: SxProps
  options?: IExtendOptions
}

export const Carousel: React.FC<ICarouselProps> = ({
  children,
  options: optionsProps = {},
  sx = {},
  onPaginationUpdated,
  ...restProps
}) => {
  const { limitPaginations, ...options } = optionsProps
  const theme = useTheme()
  const { root } = theme.components[`VuiCarousel`]?.styleOverrides || {}

  const handlePaginationUpdated: SplideEventHandlers["onPaginationUpdated"] =
    useCallback(
      (splide, ...rest) => {
        if (limitPaginations) {
          const allPaginationButton = splide.root.querySelectorAll(
            " .splide__pagination__page"
          )
          const currentIndex = splide.index
          const totalPaginations = splide.length

          const { startIndex, endIndex } = getVisiblePaginationIndexRange(
            currentIndex,
            totalPaginations,
            limitPaginations
          )

          allPaginationButton.forEach((dot, index) => {
            const shouldShow = index >= startIndex && index <= endIndex
            ;(dot as HTMLElement).style.display = shouldShow
              ? "inline-block"
              : "none"
          })
        }

        if (isFunction(onPaginationUpdated))
          onPaginationUpdated(splide, ...rest)
      },
      [limitPaginations, onPaginationUpdated]
    )

  return (
    <Box sx={deepmerge(root, sx)}>
      <Splide
        aria-label="Carousel"
        {...restProps}
        options={{
          rewind: true,
          perPage: 1,
          gap: "5rem",
          padding: { left: 24, right: 24 },
          pagination: false,
          arrows: false,
          ...options,
        }}
        onPaginationUpdated={handlePaginationUpdated}
      >
        {children}
      </Splide>
    </Box>
  )
}
