import _ from "lodash"
import booleanPointInPolygon from "@turf/boolean-point-in-polygon"
import { IFeature } from "interfaces"
import { MultiPolygon, Polygon } from "@turf/helpers"
import { Unit } from "providers/venue/types"

export const bindLevel = (feature: IFeature, groupOrdinals): IFeature => {
  if (!feature) return
  const geolocationFeatureOrdinal = _.get(feature, "properties.ordinal")
  const properties = _.get(feature, "properties", {})
  const level = _.get(groupOrdinals, geolocationFeatureOrdinal)
  const level_name = _.get(level, "properties.name.en")
  return {
    ...feature,
    properties: {
      ...properties,
      level_name,
      level,
    },
  }
}

export const bindVenue = (feature: IFeature, venues: IFeature[]): IFeature => {
  if (!feature) return
  const properties = _.get(feature, "properties", {})
  const geolocationCoordinates = _.get(feature, "geometry.coordinates", {})
  const venue = venues.find((venue) => {
    const { geometry: venueGeometry } = venue
    return booleanPointInPolygon(
      geolocationCoordinates,
      venueGeometry as Polygon | MultiPolygon
    )
  })

  return {
    ...feature,
    properties: {
      ...properties,
      venue,
    },
  }
}

const bindUnit = (feature: IFeature, units: Unit[]): IFeature => {
  if (!feature) return
  const properties = _.get(feature, "properties", {})
  const geolocationCoordinates = _.get(feature, "geometry.coordinates", {})
  const unit = units.find((unit) => {
    try {
      const { geometry: venueGeometry } = unit
      return (
        booleanPointInPolygon(
          geolocationCoordinates,
          venueGeometry as Polygon | MultiPolygon
        ) || null
      )
    } catch (error) {
      return null
    }
  })
  return {
    ...feature,
    properties: {
      ...properties,
      unit,
    },
  }
}
export const prepareGeolocationFeature = (
  geolocationFeature: IFeature,
  featureObj = { venues: [], levels: [], units: [] }
) => {
  const { venues = [], levels = [], units = [] } = featureObj
  const obj: IFeature = _.cloneDeep(geolocationFeature)
  const geolocationWithVenue = bindVenue(obj, venues)

  // Level Binding
  const locationVenueId = _.get(geolocationWithVenue, "properties.venue.id")
  const levelsInVenue = levels.filter(
    (level) => level?.properties?.venue?.id === locationVenueId
  )
  const groupedLevelByOrdinal = _.keyBy(levelsInVenue, "properties.ordinal")
  const geolocationWithLevel = bindLevel(
    geolocationWithVenue,
    groupedLevelByOrdinal
  )
  const locationLevel = geolocationWithLevel?.properties?.level

  if (!locationLevel) return geolocationWithLevel
  const unitInLevel = units.filter(
    (unit) =>
      unit.properties.level.id === geolocationWithLevel?.properties.level.id
  )
  const geolocationWithUnit = bindUnit(geolocationWithLevel, unitInLevel)
  return geolocationWithUnit
}
