import { pushDataLayer } from "./pushDataLayer"
import { GTM_GET_DIRECTION } from "constant"

interface GTMDirectionData {
  category: string
  feature_type: string
  id: string
  local_categories: string
  name: string
}

type GtmPushDataLayerGetDirection = (
  origin: GTMDirectionData,
  destination: GTMDirectionData,
  locationName: string,
  elevatorPreferredMode: boolean
) => void

export const gtmPushDataLayerGetDirection: GtmPushDataLayerGetDirection = (
  origin,
  destination,
  locationName,
  elevatorPreferredMode
) => {
  const {
    category: originCategory,
    feature_type: originFeatureType,
    id: originId,
    local_categories: originLocalCategories,
    name: originName,
  } = origin
  const {
    category: destinationCategory,
    feature_type: destinationFeatureType,
    id: destinationId,
    local_categories: destinationCategories,
    name: destinationName,
  } = destination
  pushDataLayer({
    event: GTM_GET_DIRECTION,
    origin_category: originCategory,
    origin_feature_type: originFeatureType,
    origin_id: originId,
    origin_name:
      originFeatureType === "geolocation" ? locationName : originName,
    origin_local_categories: originLocalCategories,
    destination_category: destinationCategory,
    destination_feature_type: destinationFeatureType,
    destination_id: destinationId,
    destination_local_categories: destinationCategories,
    destination_name: destinationName,
    avoid_steps: elevatorPreferredMode,
  })
}
