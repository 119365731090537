import { alpha } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

const mixins = {
  vKioskHeader: {
    height: 285, // kiosk header
  },
  vKioskFooter: {
    height: 300, // kiosk footer
  },
  kioskNavigation: {
    height: 234,
  },
  bottomNavigation: {
    height: 80,
  },
  kioskStepOverview: {
    height: 364,
  },
  mobileHeader: {
    height: 70,
  },
  mobileDirectionSearch: {
    width: 380,
  },
}

export const themeOptionFactory = (theme) => ({
  mixins,
  assets: {
    /**
     * The icon configuration format for pages with no data.
     * Example:
     * promotions: {
     *   "icon_comp": "_COMPONENT_NAME_", // optional
     *   "icon_path": "_SVG_PATH_", // optional
     *   "icon_img": "_IMG_SRC_", // optional
     * }
     */
    empty: {
      promotions: {},
      events: {},
      dining: {},
      newShops: {},
      directory: {},
      touristPrivilege: {},
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          width: "fit-content",
          height: "20px",
        },
        switchBase: {
          padding: 0,
          margin: theme.spacing(1.4),
          marginTop: theme.spacing(0.2),
          transitionDuration: theme.transitions.duration.standard,
          "&.MuiButtonBase-root:hover": {
            backgroundColor: "transparent",
          },
          "&.Mui-checked": {
            transform: "translateX(7px)",
            color: theme.palette.primary.contrastText,
            "& + .MuiSwitch-track": {
              backgroundColor: theme.palette.primary.main,
              opacity: 1,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.5,
            },
          },
          "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: theme.palette.primary.main,
            border: `6px solid ${theme.palette.primary.contrastText}`,
          },
          "&.Mui-disabled .MuiSwitch-thumb": {
            color: theme.palette.grey[100],
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.7,
          },
        },
        thumb: {
          boxSizing: "border-box",
          width: "16px",
          height: "16px",
          marginLeft: theme.spacing(-1),
        },
        track: {
          width: "30px",
          height: "20px",
          borderRadius: "10px",
          backgroundColor: theme.palette.grey[300],
          opacity: 1,
          transition: theme.transitions.create(["background-color"], {
            duration: 500,
          }),
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[10],
          height: mixins.bottomNavigation?.height,
          padding: theme.spacing(0, 6.75),
          width: "auto",
          gap: theme.spacing(5),
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 1.125, 1, 1.125),
          "& .MuiSvgIcon-root": {
            marginBottom: 8,
            width: 20,
            height: 20,
          },
          "&.Mui-selected": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            "&:first-of-type": {
              borderTopLeftRadius: "inherit",
              borderBottomLeftRadius: "inherit",
            },
            "&:last-of-type": {
              borderTopRightRadius: "inherit",
              borderBottomRightRadius: "inherit",
            },
            "&:after": {
              display: "none",
            },
          },
        },
        label: {
          textAlign: "center",
          fontSize: "1rem",
          textTransform: "uppercase",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.grey[500],
          "&.Mui-disabled": {
            backgroundColor: "#ECECEC",
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreIcon,
      },
      styleOverrides: {
        select: {
          padding: theme.spacing(1.5, 4, 1.5, 2),
          borderRadius: "8px",
          fontSize: 12,
        },
        icon: {
          color: theme.palette.primary.main,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "0.75rem",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          fontSize: "0.75rem",
        },
      },
    },
    AppSidebar: {
      styleOverrides: {
        root: {
          paddingTop: theme.spacing(3),
          backgroundColor: theme.palette.grey[50],
          width: "100%",
          [theme.breakpoints.up("sm")]: {
            maxWidth: "428px",
          },
        },
        header: {},
        body: {
          flexGrow: 1,
          overflowX: "hidden",
          overflowY: "auto",
        },
        footer: {},
        footerLogo: {},
        closeButton: {
          position: "absolute",
          right: "32px",
          top: "24px",
          zIndex: 1,
          "& .MuiSvgIcon-root": {
            width: "14px",
            height: "14px",
            fontSize: "14px",
            fill: theme.palette.text.primary,
          },
        },
        navList: {},
        navDivider: {
          borderBottomWidth: "1px",
          margin: theme.spacing(0, 3),
          "&+ .MuiDivider-root": {
            display: "none",
          },
        },
        navListItem: {
          padding: theme.spacing(1.375, 3),
          "& .MuiTypography-root": {
            fontWeight: 500,
            fontSize: "1rem",
          },
          "& .MuiListItemIcon-root": {
            color: theme.palette.primary.main,
            minWidth: 40,
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1.125rem",
          },
          [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2, 3),
            "& .MuiTypography-root": {
              fontSize: "1.125rem",
            },
            "& .MuiSvgIcon-root": {
              fontSize: "1.25rem",
            },
          },
        },
        subList: {},
        subDivider: {
          marginLeft: theme.spacing(8),
          borderBottomWidth: "1px",
        },
        subListItem: {
          paddingLeft: theme.spacing(8),
          color: "inherit",
          "& .MuiTypography-root": {
            fontWeight: 500,
            fontSize: "0.875rem",
          },
          "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall": {
            fontSize: "0.5rem",
          },
          "& .MuiListItemIcon-root": {
            color: theme.palette.primary.main,
            minWidth: "30px",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1rem",
          },
          "&:last-of-type": {
            borderBottom: "none",
          },
          "&:last-of-type + .MuiDivider-root": {
            display: "none",
          },
        },
      },
    },
    AppDining: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(1),
          margin: theme.spacing(0, -3),
          height: "100%",
          justifyContent: "space-between",
        },
        actionButton: {
          minWidth: 128,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          "& .MuiSvgIcon-root": {
            marginRight: 0,
            width: 32,
            height: 32,
          },
        },
        actionContainer: {
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(1.5),
        },
        toolbarContainer: {
          position: "relative",
          display: "flex",
          gap: theme.spacing(3),
        },
        searchBarContainer: {
          width: "100%",
          height: 48,
        },
        filterSection: {
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
          top: 0,
          zIndex: 1,
          paddingTop: theme.spacing(4),
          "& .subCategory-filterbar": {
            padding: theme.spacing(0, 1.5),
            position: "relative",
            maxWidth: "95%",
            margin: "0 auto",
            "&:after": {
              content: "''",
              display: "inline-block",
              width: "20px",
              background:
                "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 10%, #fff 90%)",
              zIndex: 1,
              position: "absolute",
              right: "2px",
              top: 0,
              bottom: 0,
            },
            "&:before": {
              content: "''",
              display: "inline-block",
              width: "20px",
              background:
                "linear-gradient(90deg, #fff 10%, rgba(255, 255, 255, 0.00) 90%)",
              zIndex: 1,
              position: "absolute",
              left: "2px",
              top: 0,
              bottom: 0,
            },
          },
        },
        filterContainer: {
          padding: 0,
        },
        filterButton: {
          gap: theme.spacing(3),
          width: "100%",
          "& .MuiToggleButton-root.MuiToggleButtonGroup-grouped": {
            borderRadius: "9999px",
            "& .MuiSvgIcon-root": {
              display: "none",
            },
          },
          "& .MuiToggleButton-root.MuiToggleButtonGroup-grouped:first-of-type":
            {
              marginLeft: 0,
            },
          "& .MuiToggleButton-root:last-of-type": {
            marginRight: 0,
          },
        },
        diningImageCard: {
          display: "block",
          width: "230px",
          height: "200px",
          "& .MuiCardActionArea-root, & .MuiCardMedia-root": {
            height: "100%",
            width: "100%",
          },
          "& .MuiCardHeader-root": {
            paddingBottom: theme.spacing(1),
          },
          "& .MuiCardHeader-root .MuiCardHeader-title": {
            lineHeight: 1.3,
          },
          "& .MuiCardHeader-content": {
            overflow: "hidden",
          },
          "& .MuiCardHeader-title": {
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            marginBottom: theme.spacing(0.1875),
          },
          "& .MuiCardHeader-avatar .MuiAvatar-root": {
            width: "50px",
            height: "50px",
          },
          "& .MuiCardHeader-subheader": {
            "& .booth-name-text": {
              display: "none",
            },
          },
        },
        diningAvatar: {},
        diningAvatarFallBack: {},
        emptyDataContainer: {
          minHeight: "680px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        gridContainer: {
          position: "relative",
          display: "grid",
          gridAutoFlow: "column",
          gridTemplateRows: "repeat(4, max-content)",
          gridTemplateColumns: "repeat(4, max-content)",
          gap: theme.spacing(2),
          padding: theme.spacing(0.375),
          overflowX: "auto",
          minHeight: "680px",
          paddingTop: 0,
        },
        venueSelector: {
          position: "absolute",
          bottom: `${mixins.kioskNavigation.height + 116}px`,
          right: "50px",
          zIndex: 2,
        },
        navigationWrapper: {
          zIndex: theme.zIndex.appBar,
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: theme.spacing(0, 6.25),
          gap: theme.spacing(3),
        },
        mainMenuContainer: {},
      },
    },
    AppCategoryFilterButton: {
      styleOverrides: {
        root: {
          padding: 0,
          width: 85,
          flexDirection: "column",
          justifyContent: "start",
          gap: theme.spacing(0.75),
          border: "none",
          color: theme.palette.grey[500],
          "&.Mui-selected": {
            "& .MuiSvgIcon-root": {
              color: theme.palette.primary.main,
            },
            "& .MuiTypography-root": {
              color: theme.palette.primary.main,
            },
          },
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: "unset",
          },
        },
        label: {
          fontSize: "0.75rem",
          textAlign: "center",
          lineHeight: 1.1,
          color: theme.palette.text.primary,
          fontWeight: theme.typography.fontWeightMedium,
        },
        icon: {
          width: 75,
          height: 75,
          padding: theme.spacing(1.25),
          borderRadius: `${(theme.shape.borderRadius * 2) / 3}px`,
          border: `1px solid rgba(203, 203, 203, 0.5)`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.10)",
          "& .MuiSvgIcon-root": {
            width: 40,
            height: 40,
          },
        },
      },
    },
    AppCategoryFilterChip: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0.5),
          borderRadius: "16px",
          boxShadow: "none",
          background: theme.palette.common.white,
          border: `1px solid ${theme.palette.grey[200]}`,
          whiteSpace: "nowrap",
          height: "32px",
          color: theme.palette.text.primary,
          fontWeight: 400,
          "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
            borderRadius: "16px",
            margin: 0,
          },
          "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
            border: `1px solid ${theme.palette.grey[200]}`,
            margin: 0,
            borderRadius: "16px",
          },
          "&.MuiToggleButtonGroup-grouped:first-of-type": {
            marginLeft: theme.spacing(3),
          },
          "&.Mui-selected": {
            "& svg": {
              fill: theme.palette.primary.contrastText,
            },
          },
          ":hover": {
            background: theme.palette.common.white,
          },
        },
        label: {
          fontSize: "0.8125rem",
          padding: theme.spacing(0, 1.5),
          letterSpacing: "0.00938em",
        },
        icon: {
          lineHeight: 1,
          "& svg": {
            width: 16,
            height: 16,
            fill: theme.palette.primary.main,
            marginLeft: theme.spacing(0.5),
            marginRight: "-6px",
          },
        },
      },
    },
    AppCategoryFilterBar: {
      styleOverrides: {
        root: {},
        filterButton: {},
      },
    },
    WelcomePanel: {
      defaultProps: {
        showNavigation: false,
        showQuickSearch: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[50],
        },
        panelContainer: {
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
        },
        line: {
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          height: "3px",
          border: "none",
          background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.common.white} 100%)`,
        },
        content: {
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: theme.spacing(6.75, 3, 2),
          [theme.breakpoints.up("sm")]: {
            width: 460,
          },
        },
        logoContainer: {
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
          marginBottom: theme.spacing(3),
          "& img": {
            maxWidth: 130,
          },
        },
        searchBarContainer: {
          marginBottom: theme.spacing(2),
          width: "100%",
        },
        navItem: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          color: "white",
          flexBasis: 0,
          fontSize: theme.typography.subTitle.fontSize,
          fontWeight: theme.typography.fontWeightRegular,
          lineHeight: "19px",
          flexGrow: "1",
          textAlign: "center",
          "& .MuiButton-startIcon": {
            margin: theme.spacing(0, 0, 1.5),
            width: 20,
            height: 20,
          },
        },
        horizontalMenuContainer: {
          padding: 0,
        },
      },
    },
    AppHeader: {
      styleOverrides: {
        root: {
          position: "sticky",
          left: 0,
          top: 0,
          right: 0,
          justifyContent: "center",
          height: "100%",
          backgroundColor: theme.palette.grey[50],
          boxShadow: "none",
          width: "100%",
          color: theme.palette.text.primary,
          fontWeight: 600,
        },
        toolbar: {
          padding: theme.spacing(0, 9),
          maxWidth: "none",
          minHeight: mixins.mobileHeader.height,
          justifyContent: "space-between",
          width: `100%`,
          [theme.breakpoints.up("md")]: {
            padding: theme.spacing(0, 9),
            margin: `0 auto`,
            maxWidth: "none",
          },
        },
        logoContainer: {
          display: "inline-block",
        },
        titleContainer: {
          display: "none",
        },
        clockContainer: {
          display: "inline-block",
        },
        backButtonContainer: {
          display: "none",
        },
        line: {
          display: "none",
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          border: "none",
        },
      },
    },
    AppPageLayout: {
      styleOverrides: {
        root: {
          height: `100%`,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          flex: 1,
        },
        navBuffer: {
          // Buffer to offset height of navbar to prevent navbar appear on top of the content
          height: "223px",
        },
        contentWrapper: {
          overflowY: "auto",
          padding: theme.spacing(4, 3),
          maxHeight: "100%",
          flex: 1,
        },
      },
    },
    AppFullHeightLayout: {
      styleOverrides: {
        root: {
          height: "100%",
        },
      },
    },
    AppLayout: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
        },
        headerWrapper: {
          height: `${mixins.vKioskHeader.height}px`,
        },
        contentWrapper: {
          flex: 1,
          position: "relative",
        },
        footerWrapper: {
          height: `${mixins.vKioskFooter.height}px`,
        },
      },
    },
    AppClock: {
      defaultProps: {
        dateFormat: "LLL",
        timeFormat: "hh:mm",
        timeUnitFormat: "A",
      },
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          textTransform: "uppercase",
          whiteSpace: "pre-wrap",
          lineHeight: 1.2,
          gap: theme.spacing(1.75),
        },
        timeWrapper: {
          textAlign: "left",
          fontSize: "50px",
        },
        dateText: {
          textAlign: "right",
          fontSize: "30px",
        },
        divider: {},
        timeLabel: {
          fontWeight: 300,
          fontSize: "16px",
        },
        timeText: {},
        timeUnitText: {
          fontWeight: 300,
          fontSize: "16px",
          marginBottom: theme.spacing(2),
        },
        icon: {
          display: "none",
        },
      },
    },
    AppKioskNavigation: {
      styleOverrides: {
        root: {},
        menuButton: {
          width: 138,
          "&>.MuiSvgIcon-root": {
            marginBottom: theme.spacing(2),
            width: 32,
            height: 32,
            zIndex: 1,
          },
          "&>.MuiBottomNavigationAction-label": {
            zIndex: 1,
          },
        },
        navigationContainer: {
          width: "auto",
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[6],
          marginBottom: theme.spacing(3),
          maxWidth: 979,
          height: 127,
          borderRadius: "20px",
          padding: 0,
          gap: 0,
        },
      },
    },
    AppNavigationLink: {
      styleOverrides: {
        root: {},
        backSide: {
          display: "none",
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 0,
        },
      },
    },
    AppMapLayout: {
      styleOverrides: {
        outletWrapper: {
          width: "100%",
          position: "relative",
          height: "100%",
          pointerEvents: "none",
          "> *": { pointerEvents: "all" },
        },
      },
    },
    AppFooter: {
      styleOverrides: {
        root: {
          height: "100%",
          width: "100%",
          objectFit: "cover",
        },
      },
    },
    DirectionSearchInput: {
      styleOverrides: {
        directionSearchWrapper: {
          boxShadow: theme.shadows[1],
          background: theme.palette.common.white,
          padding: theme.spacing(3, 2),
          borderRadius: "10px",
          display: "flex",
        },
        backIconWrapper: {
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          marginRight: theme.spacing(1),
        },
        directionWrapper: {
          flexGrow: 1,
          display: "flex",
        },
        switchIconWrapper: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: theme.spacing(1),
        },
        directionInputWrapper: {
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        },
        directionIconWrapper: {
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "column",
          marginRight: theme.spacing(1.5),
        },
        directionInput: {
          fontSize: "0.875rem",
          fontWeight: 500,
          flexGrow: 1,
          padding: theme.spacing(0, 0.5),
          "& .MuiInputBase-input": {
            padding: 0,
          },
        },
        iconButton: {
          color: theme.palette.text.primary,
          fontSize: "24px",
          margin: "-5px",
        },
        divider: {
          margin: theme.spacing(1, 0),
          borderBottomWidth: "1px",
        },
        clearIconButton: {
          color: theme.palette.text.primary,
          fontSize: "24px",
          margin: "-5px",
          "& .MuiSvgIcon-root": {
            height: "8px",
            width: "8px",
          },
        },
      },
    },
    AppDirectionSearch: {
      styleOverrides: {
        paper: {
          borderRadius: "10px",
          padding: theme.spacing(0.75, 1.25),
          display: "flex",
          alignItems: "center",
        },
        formControlLabel: {
          margin: 0,
          "& .MuiFormControlLabel-label": {
            fontWeight: 500,
            marginLeft: theme.spacing(2),
          },
        },
      },
    },
    AppMap: {
      styleOverrides: {
        actionContainer: {
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(1.5),
        },
        actionButton: {
          minWidth: 128,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          "& .MuiSvgIcon-root": {
            marginRight: 0,
            width: 32,
            height: 32,
          },
        },
        toolbarContainer: {
          position: "relative",
          display: "flex",
          gap: theme.spacing(3),
        },
        quickLinkContainer: {
          //hide scrollbar
          "::-webkit-scrollbar": {
            display: "none",
          },
          overflowX: "scroll",
          MsOverflowStyle: "none",
          scrollbarWidth: "none",
        },
        indoorMapContainer: {
          position: "absolute",
          zIndex: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
          "& > #map": {
            width: "100%",
            height: "100%",
          },
          "& > * > .maptalks-wrapper": {
            transition: "all 500ms ease-out",
            // * Fixing Map transition not working when navigate from Directory to Place Detail.
            WebkitTransition: "-webkit-transform 500ms ease-out",
          },
          "&.bottom-half": {
            overflow: "hidden",
            "& .maptalks-wrapper": {
              transform: `none`,
            },
          },
          "&.hidden": {
            opacity: 0,
            visibility: "hidden",
          },
        },
        navigationWrapper: {
          zIndex: theme.zIndex.appBar,
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: theme.spacing(3),
          padding: theme.spacing(0, 6.25),
        },
        mainMenuContainer: {},
        searchBarContainer: {
          width: "100%",
          height: 48,
        },
        ordinalSelectMenu: {
          display: "inline-block",
          position: "absolute",
          bottom: `${mixins.kioskNavigation.height + 246}px`,
          right: "50px",
        },
        venueSelector: {
          position: "absolute",
          bottom: `${mixins.kioskNavigation.height + 138}px`,
          right: "50px",
          zIndex: 2,
        },
        ordinalSelector: {
          display: "none",
        },
      },
    },
    AppNavigationStep: {
      styleOverrides: {
        wrapper: {
          position: "absolute",
          bottom: 0,
          zIndex: 1300,
          alignItems: "baseline",
          gap: theme.spacing(2),
          left: 0,
          right: 0,
          margin: theme.spacing(0, 4, 3),
          display: "flex",
          flexDirection: "column",
        },
        container: {
          width: "100%",
          margin: "auto",
          boxShadow: theme.shadows[15],
          position: "static",
          top: "unset",
          height: 350,
          maxWidth: "unset",
          zIndex: theme.zIndex.modal,
          bottom: "25px",
          display: "flex",
          right: 0,
          left: 0,
          background: theme.palette.background.paper,
          borderRadius: `10px`,
          padding: theme.spacing(2, 3),
          flexDirection: "column",
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
          [theme.breakpoints.up("sm")]: {
            maxWidth: "unset",
            margin: "auto",
          },
        },
        ordinalSelectorWrapper: {
          position: "absolute",
          left: 0,
          top: "-24px",
          transform: `translateY(-100%)`,
        },
        kioskBackButton: {
          display: "flex",
        },
        title: {
          fontSize: 24,
          fontWeight: 700,
        },
        primaryText: {
          fontSize: 24,
          color: theme.palette.text.primary,
          textAlign: "center",
          fontWeight: 600,
          lineHeight: 1.5,
        },
        highlightedText: {
          color: theme.palette.primary.main,
          textAlign: "inherit",
          fontWeight: "inherit",
          lineHeight: "inherit",
          fontSize: "inherit",
        },
        secondaryText: {
          maxWidth: "370px",
          fontSize: 22,
          color: theme.palette.text.secondary,
          textAlign: "center",
          lineHeight: 1.5,
          fontWeight: 600,
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          maxHeight: "66px",
          WebkitBoxOrient: "vertical",
        },
        locationText: {
          maxWidth: "250px",
          marginTop: theme.spacing(3),
          color: theme.palette.text.primary,
          fontSize: 24,
          lineHeight: "33px",
          paddingRight: "10px",
          maxHeight: "66px",
          fontWeight: 700,
          textTransform: "uppercase",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
        },
        originIcon: {
          display: "block",
          fontSize: "3.5rem",
        },
        destinationIcon: {
          display: "block",
          fontSize: "3.5rem",
          fill: theme.palette.primary.main,
        },
        headerWrapper: {
          position: "relative",
          top: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          color: theme.palette.common.black,
          fontSize: theme.typography.caption.fontSize,
          "& .MuiIconButton-root": {
            fontSize: "inherit",
          },
        },
        stepTextContainer: {
          height: "auto",
          display: "flex",
          alignItems: "center",
        },
        overviewWrapper: {
          justifyContent: "space-between",
          flexDirection: "row",
          gap: theme.spacing(1),
        },
        navButtonWrapper: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: theme.spacing(4),
        },
        iconNavButton: {
          display: "none",
        },
        exitButtonWrapper: {
          display: "none",
        },
        exitButton: {
          backgroundColor: theme.palette.background.default,
          border: `1px solid #ececec`,
          color: theme.palette.text.primary,
          fontWeight: 600,
          "& .MuiSvgIcon-root": {
            fill: theme.palette.primary.main,
          },
          "&:hover": {
            backgroundColor: theme.palette.grey[200],
          },
        },
        borderLinearProgressBar: {
          display: "flex",
        },
        stepProgressBar: {
          display: "none",
        },
        originIconContainer: {},
        destinationIconContainer: {},
      },
    },
    AppFeatureDetail: {
      styleOverrides: {
        root: {
          position: "relative",
          overflow: "initial",
          boxShadow: "none",
        },
        section: {
          margin: theme.spacing(3, 0),
          "&:first-of-type": {
            marginTop: 0,
          },
          color: theme.palette.text.secondary,
        },
        galleryImage: {
          objectFit: "cover",
          height: 130,
          width: 182,
          display: "block",
          boxShadow: theme.shadows[2],
          borderRadius: "6px",
          cursor: "pointer",
        },
        divider: {
          borderBottom: `1px solid #ECECEC`,
        },
        contactButton: {
          textTransform: "lowercase",
          display: "flex",
          marginBottom: theme.spacing(2.5),
          color: theme.palette.text.primary,
          "& .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
          },
        },
        contactWrapper: {
          marginBottom: theme.spacing(1.25),
        },
        sectionTitle: {
          fontSize: theme.typography.subtitle1.fontSize,
          fontWeight: theme.typography.fontWeightBold,
          marginBottom: theme.spacing(2),
          color: theme.palette.text.primary,
        },
      },
    },
    AppImageModal: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: theme.spacing(2),
        },
        imageWrapper: {
          position: "relative",
          outline: "none",
          "& img": {
            borderRadius: "8px",
            width: "100%",
          },
          [theme.breakpoints.up("sm")]: {
            width: "400px",
          },
        },
        closeButton: {
          color: theme.palette.common.white,
          position: "absolute",
          top: theme.spacing(-4.5),
          right: theme.spacing(-1),
          [theme.breakpoints.up("sm")]: {
            right: theme.spacing(-2),
          },
        },
      },
    },
    AppYouAreHereMarker: {
      styleOverrides: {
        root: {
          "& .primary": {
            fill: theme.palette.primary.main,
          },
          "& .primary-light": {
            fill: theme.palette.secondary.main,
          },
          "& .primary-border": {
            stroke: theme.palette.primary.main,
          },
          "& .text-primary": {
            fill: theme.palette.text.primary,
          },
          "& .secondary": {
            fill: theme.palette.secondary.main,
          },
        },
      },
    },
    DirectionStep: {
      styleOverrides: {
        originIcon: {
          fill: theme.palette.primary.main,
          width: 37,
          height: 37,
        },
        destinationIcon: {
          fill: theme.palette.error.main,
          width: 28,
          height: 47,
        },
        highlightedText: {
          color: theme.palette.primary.main,
          textAlign: "inherit",
          fontWeight: "inherit",
          lineHeight: "inherit",
          fontSize: "inherit",
        },
      },
    },
    AppDirectionOverview: {
      styleOverrides: {
        root: {},
        ordinalSelectorWrapper: {
          position: "absolute",
          left: 0,
          top: "-24px",
          transform: `translateY(-100%)`,
        },
        actionButtonWrapper: { display: "flex", gap: theme.spacing(3) },
        container: {
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
          alignItems: "start",
          position: "absolute",
          top: "unset",
          right: 0,
          left: 0,
          bottom: "24px",
          mx: "30px",
          minHeight: 320,
          zIndex: theme.zIndex.modal,
        },
        primaryText: {
          marginBottom: theme.spacing(2),
          fontSize: 24,
        },
        secondaryText: {
          fontSize: 24,
          fontWeight: 700,
        },
        wrapper: {
          gap: theme.spacing(4.5),
          padding: theme.spacing(8.25),
          "button, a": { pointerEvents: "all" },
          display: "flex",
          flexDirection: "column",
          borderRadius: `10px`,
        },
        stepper: {
          maxHeight: mixins.kioskStepOverview.height,
          overflowY: "auto",
          //hide scrollbar
          "::-webkit-scrollbar": {
            display: "none",
          },
          MsOverflowStyle: "none",
          scrollbarWidth: "none",
          "& .MuiStep-vertical": {
            ":not(:last-of-type)": {
              "& .MuiStepLabel-labelContainer": {
                borderBottom: `1px solid ${theme.palette.divider}`,
              },
            },
            "& .MuiStepLabel-labelContainer": {
              padding: theme.spacing(3.125, 0),
            },
          },
        },
        stepOverviewContainer: {
          position: "relative",
          padding: theme.spacing(4),
          "button, a": { pointerEvents: "all" },
          "&:after": {
            content: "''",
            display: "inline-block",
            height: "20px",
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 10%, #fff 90%)",
            zIndex: 1,
            position: "absolute",
            bottom: "30px",
            left: 0,
            right: 0,
          },
        },
        directionSummaryContainer: {},
      },
    },
    DirectionCardActions: {
      styleOverrides: {
        root: {
          gap: theme.spacing(2),
          height: "auto",
          padding: 0,
          display: "flex",
          flexDirection: "row",
        },
        actionButton: {
          [theme.breakpoints.down("sm")]: {
            fontWeight: 700,
            "&.MuiButton-outlined": {
              border: `1px solid ${theme.palette.grey[400]}`,
            },
            "& .MuiButton-startIcon": {
              marginRight: theme.spacing(1.25),
            },
          },
        },
      },
    },
    HomePage: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          height: "100%",
        },
        fullscreenLayout: {
          backgroundColor: theme.palette.grey[100],
          position: "relative",
          flex: 1,
          zIndex: theme.zIndex.modal - 1,
        },
        contentContainer: {
          display: "flex",
          flexDirection: "column",
        },
        featureCarouselWrapper: {
          display: "none",
        },
        carouselWrapper: {
          display: "flex",
          width: "100%",
        },
        carouselScreenSaver: {
          zIndex: 2,
          "& .splide__track": {
            padding: 0,
            margin: 0,
          },
        },
        carouselMediaItem: {
          width: "100%",
          height: "calc(100vh - 285px - 300px - 437px)", // 100% - (kiosk header) - (kiosk footer) + (header margin) + (welcome section height)
          objectFit: "cover",
        },
        welcomeContainer: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: 437,
          zIndex: 2,
        },
        greetingText: {
          display: "none",
          marginBottom: theme.spacing(3.125),
        },
        localeListContainer: {
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(5),
        },
        localeButton: {
          borderRadius: "50%",
          height: "80px",
          width: "80px",
          boxShadow: theme.shadows[3],
          "& .MuiAvatar-circular": {
            height: "70px",
            width: "70px",
          },
        },
        actionContainer: {
          marginTop: theme.spacing(8),
        },
        actionButton: {
          borderRadius: "50px",
          textTransform: "uppercase",
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
        },
        textCard: {
          borderRadius: "15px",
          height: 136,
          display: "flex",
          alignItems: "center",
          p: theme.spacing(1),
          justifyContent: "center",
          flex: 1,
        },
        linkCardContainer: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: 1.25,
          "&>*": {
            flex: 1,
          },
        },
        defaultCardIcon: {
          color: theme.palette.primary.main,
          display: "flex",
          justifyContent: "center",
        },
        defaultCardPrimaryText: {
          textAlign: "center",
          fontSize: "14px",
          lineHeight: 1.4,
        },
        defaultCardSecondaryText: {
          color: theme.palette.text.secondary,
          textAlign: "center",
          fontSize: "12px",
          lineHeight: 1.4,
        },
      },
    },
    FullScreenStart: {
      styleOverrides: {
        root: {
          display: "none",
        },
        actionButtonWrapper: {
          position: "relative",
          display: "inline-block",
          "&:after, :before": {
            opacity: 0,
            display: "flex",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            content: '""',
            height: "100%",
            width: "100%",
            border: `5px solid ${alpha(theme.palette.common.white, 0.4)}`,
            borderRadius: 9999,
            zIndex: -1,
          },
          "&:after": {
            animation: `ripple 3s infinite ${theme.transitions.easing.easeInOut}`,
          },
          "&:before": {
            animation: `ripple 3s infinite ${theme.transitions.easing.easeInOut} 0.5s`,
          },
        },
        actionButton: {
          position: "relative",
          fontSize: "1rem",
          textTransform: "uppercase",
          color: theme.palette.text.secondary,
          fontWeight: 700,
          borderRadius: 999,
          backgroundColor: theme.palette.common.white,
          transition: theme.transitions.create("all"),
          boxShadow: theme.shadows[10],
          padding: theme.spacing(1.25, 3),
          "&:hover": {
            boxShadow: theme.shadows[20],
            backgroundColor: theme.palette.common.white,
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
          },
          "& .MuiButton-startIcon>svg": {
            fontSize: "1.5rem",
            color: theme.palette.primary.main,
          },
          [theme.breakpoints.up("md")]: {
            transform: "scale(1.2)",
          },
        },
      },
    },
    DirectoryPage: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
          gap: theme.spacing(1),
          margin: theme.spacing(0, -3),
        },
        actionButton: {
          minWidth: 128,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          "& .MuiSvgIcon-root": {
            marginRight: 0,
            width: 32,
            height: 32,
          },
        },
        actionContainer: {
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(1.5),
        },
        toolbarContainer: {
          position: "relative",
          display: "flex",
          gap: theme.spacing(3),
        },
        searchBarContainer: {
          width: "100%",
          height: 48,
        },
        filterSection: {
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
          top: 0,
          zIndex: 1,
          paddingTop: theme.spacing(4),
          "& .category-filterbar,& .subCategory-filterbar": {
            padding: theme.spacing(0, 1.5),
            position: "relative",
            maxWidth: "95%",
            margin: "0 auto",
            "&:after": {
              content: "''",
              display: "inline-block",
              width: "20px",
              background:
                "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 10%, #fff 90%)",
              zIndex: 1,
              position: "absolute",
              right: "2px",
              top: 0,
              bottom: 0,
            },
            "&:before": {
              content: "''",
              display: "inline-block",
              width: "20px",
              background:
                "linear-gradient(90deg, #fff 10%, rgba(255, 255, 255, 0.00) 90%)",
              zIndex: 1,
              position: "absolute",
              left: "2px",
              top: 0,
              bottom: 0,
            },
          },
          "& .category-filterbar": {
            order: 0,
          },
          "& .subCategory-filterbar": {
            order: 1,
          },
        },
        categoryFilterBar: {
          gap: theme.spacing(3.75),
          padding: theme.spacing(0.25, 0),
          "& .MuiToggleButton-root:first-of-type": {
            marginLeft: theme.spacing(0),
          },
          "& .MuiToggleButton-root:last-of-type": {
            marginRight: theme.spacing(0),
          },
        },
        subCategoryFilterBar: {
          gap: theme.spacing(3),
          width: "100%",
          "& .MuiToggleButton-root.MuiToggleButtonGroup-grouped": {
            borderRadius: "9999px",
            "& .MuiSvgIcon-root": {
              display: "none",
            },
          },
          "& .MuiToggleButton-root.MuiToggleButtonGroup-grouped:first-of-type":
            {
              marginLeft: 0,
            },
          "& .MuiToggleButton-root:last-of-type": {
            marginRight: 0,
          },
        },
        scrollPane: {
          padding: 0,
          "& .MuiDivider-root": {
            display: "none",
          },
        },
        gridContainer: {
          position: "relative",
          display: "grid",
          gridAutoFlow: "column",
          gridTemplateRows: "repeat(7, max-content)",
          gridTemplateColumns: "repeat(4, max-content)",
          gap: theme.spacing(2),
          padding: theme.spacing(0.375),
          overflowX: "auto",
          minHeight: "680px",
          paddingTop: 0,
        },
        gridItem: {
          width: "210px",
        },
        emptyDataWrapper: {
          minHeight: "680px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        navigationWrapper: {
          zIndex: theme.zIndex.appBar,
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: theme.spacing(0, 6.25),
          gap: theme.spacing(3),
        },
        mainMenuContainer: {},
        venueSelector: {
          position: "absolute",
          bottom: `${mixins.kioskNavigation.height + 266}px`,
          right: "50px",
          zIndex: 2,
        },
      },
    },
    LevelFilterBar: {
      styleOverrides: {
        root: {
          padding: 0,
          "& .MuiToggleButton-root": {
            padding: theme.spacing(1, 3),
            flexShrink: 0,
          },
          "& .MuiToggleButton-root:first-of-type": {
            marginLeft: theme.spacing(3),
          },
          "& .MuiToggleButton-root:last-of-type": {
            marginRight: theme.spacing(3),
          },
        },
      },
    },
    DirectoryOccupantCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          display: "flex",
          height: "80px",
          borderRadius: "10px",
          backgroundColor: theme.palette.background.paper,
        },
        occupantLogo: {
          boxShadow: theme.shadows[2],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: "0 0 90px",
          "& .MuiAvatar-root": {
            borderRadius: "0",
            width: "100%",
            height: "100%",
          },
          "& .MuiAvatar-img": {
            objectFit: "cover",
          },
        },
        cardContent: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: theme.spacing(1, 1.4),
          "& .MuiTypography-root": {
            lineHeight: 1.15,
          },
        },
        levelContainer: {
          display: "none",
        },
        divider: {
          display: "none",
        },
        venueLogo: {},
        titleText: {
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          overflow: "hidden",
          wordBreak: "break-word",
          fontSize: "1.125rem",
        },
        boothText: {
          overflow: "hidden",
          fontSize: "1rem",
          display: "none",
        },
        groupText: {},
        categoryText: {
          display: "none",
        },
        titleContainer: {},
        locationInfoContainer: {},
        detailWrapper: {},
      },
    },
    PromotionPage: {
      styleOverrides: {
        root: {
          height: `100%`,
        },
        actionButton: {
          minWidth: 128,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          "& .MuiSvgIcon-root": {
            marginRight: 0,
            width: 32,
            height: 32,
          },
        },
        actionContainer: {
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(1.5),
        },
        toolbarContainer: {
          position: "relative",
          display: "flex",
          gap: theme.spacing(3),
        },
        searchBarContainer: {
          width: "100%",
          height: 48,
        },
        carouselContainer: {
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: theme.spacing(5),
          position: "absolute",
          left: 0,
          right: 0,
          overflow: "hidden",
        },
        categoryFilterBarContainer: {},
        cardListContainer: {
          display: "none",
        },
        carousel: {
          "& .splide": {
            margin: "0 auto",
            marginTop: theme.spacing(12.5), // Avoid cropping the top slide during scaling
          },
          "& .splide__pagination": {
            marginTop: theme.spacing(6.25),
          },
          "& .splide__pagination__page": {
            width: "14px",
            height: "14px",
          },
          "& .splide__pagination__page.is-active": {
            backgroundColor: theme.palette.primary.main,
            transition: `width ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}, borderRadius ${theme.transitions.duration.standard}s ${theme.transitions.easing.easeInOut}`,
            transform: "none",
            width: 45,
            borderRadius: 30,
          },
          "& .splide__track": {
            padding: 0,
          },
          "& .splide__slide": {
            "&> *": {
              display: "inline-block",
              transform: "scale(0.8)",
              opacity: 0.4,
              transition: `all ${theme.transitions.duration.short}ms`,
            },
            "&.is-visible.is-active": {
              "&> *": {
                opacity: 1,
                transform: "scale(1)",
              },
            },
          },
        },
        emptyDataContainer: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: 0,
          paddingBottom: 0,
          height: "calc(810px + 100px)", // Specifies the carousel height, inclusive of pagination + marign top
          position: "relative",
        },
        navigationWrapper: {
          zIndex: theme.zIndex.appBar,
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: theme.spacing(0, 6.25),
          gap: theme.spacing(3),
        },
        mainMenuContainer: {},
        venueSelector: {
          position: "absolute",
          bottom: `${mixins.kioskNavigation.height + 34}px`,
          right: "50px",
          zIndex: 2,
        },
      },
    },
    OccupantPromotionPage: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          height: "100%",
        },

        carouselContainer: {
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: theme.spacing(5),
          position: "absolute",
          left: 0,
          right: 0,
          overflow: "hidden",
        },
        carousel: {
          "& .splide": {
            margin: "0 auto",
            marginTop: theme.spacing(22),
          },
          "& .splide__pagination": {
            marginTop: theme.spacing(4),
          },
          "& .splide__pagination__page": {
            width: "14px",
            height: "14px",
          },
          "& .splide__pagination__page.is-active": {
            backgroundColor: theme.palette.primary.main,
            transition: `width ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}, borderRadius ${theme.transitions.duration.standard}s ${theme.transitions.easing.easeInOut}`,
            transform: "none",
            width: 45,
            borderRadius: 30,
          },
          "& .splide__track": {
            padding: 0,
          },
          "& .splide__slide": {
            "&> *": {
              display: "inline-block",
              transform: "scale(0.8)",
              opacity: 0.4,
              transition: `all ${theme.transitions.duration.short}ms`,
            },
            "& .MuiCardMedia-root": {
              height: "640px",
            },
            "& .MuiPaper-root": {
              minHeight: "640px",
            },
            "&.is-visible.is-active": {
              "&> *": {
                opacity: 1,
                transform: "scale(1)",
              },
            },
          },
        },
        emptyDataContainer: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: 0,
          paddingBottom: 0,
          height: "100%",
          position: "relative",
        },
        headerContainer: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: theme.spacing(1.25),
        },
        headerLogo: {
          background: theme.palette.common.white,
          color: theme.palette.grey[500],
          margin: 0,
          border: `1px solid #DBDBDB`,
          "& .MuiSvgIcon-root": {
            width: 40,
            height: 40,
          },
        },
        headerTitle: {
          fontWeight: 700,
        },
        navigationWrapper: {
          zIndex: theme.zIndex.appBar,
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: theme.spacing(0, 6.25),
          gap: theme.spacing(3),
        },
        mainMenuContainer: {},
        actionButton: {
          minWidth: 128,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          "& .MuiSvgIcon-root": {
            marginRight: 0,
            width: 32,
            height: 32,
          },
        },
        actionContainer: {
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(1.5),
        },
        toolbarContainer: {
          position: "relative",
          display: "flex",
          gap: theme.spacing(3),
        },
        searchBarContainer: {
          width: "100%",
          height: 48,
        },
        actionButtonGroupContainer: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: theme.spacing(3),
          marginTop: "auto",
          paddingTop: theme.spacing(2),
        },
        button: {},
      },
    },
    AppDetailPage: {
      styleOverrides: {
        root: {
          position: "absolute",
          bottom: 0,
          width: "100%",
          zIndex: theme.zIndex.modal,
        },
      },
    },
    FeatureCard: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "row",
          width: "100%",
          boxShadow: "none",
          background: theme.palette.background.paper,
        },
        primarySection: {
          position: "relative",
          display: "flex",
          flexDirection: "column",
          flex: "2 1 65%",
          padding: theme.spacing(4.75, 6.25),
          "&:after": {
            content: "''",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: 0,
            borderRight: `1px solid #EEEEEE`,
            height: 237,
          },
        },
        secondarySection: {
          display: "flex",
          flexDirection: "column",
          flex: "1 1 35%",
          padding: theme.spacing(14.25, 6.25, 15.75),
        },
        header: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: theme.spacing(0.625),
          marginBottom: theme.spacing(2.25),
        },
        content: {
          color: theme.palette.text.secondary,
          marginBottom: theme.spacing(3),
        },
        imageListContainer: {
          marginBottom: theme.spacing(0.5),
          overflowX: "auto",
        },
        imageItem: {
          width: 258,
          height: 147,
          minWidth: 258,
          borderRadius: "10px",
          "&:not(:last-child)": {
            marginRight: theme.spacing(1.75),
          },
        },
        imageList: {
          paddingBottom: theme.spacing(2.5),
        },
        actionContainer: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: theme.spacing(4),
        },
        avatar: {
          background: theme.palette.common.white,
          color: theme.palette.grey[500],
          margin: 0,
          border: `1px solid #DBDBDB`,
          "& .MuiSvgIcon-root": {
            width: 40,
            height: 40,
          },
        },
        listContainer: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(2),
        },
        qrCodeContainer: {
          /*   paddingTop: theme.spacing(4), */
          backgroundColor: "transparent",
          "& .AppQRCode-content": {
            width: 120,
            height: 120,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            border: "none",
            //create corner border
            //https://stackoverflow.com/questions/38850419/how-to-create-multi-color-border-with-css
            //http://www.java2s.com/example/html-css/css-widget/add-border-only-to-corner.html
            ":before": {
              content: "''",
              display: "block",
              position: "absolute",
              borderTop: "3px solid",
              borderBottom: "3px solid",
              borderImage:
                "linear-gradient(to right, black 25%, transparent 25%, transparent 50%,transparent 50%, transparent 75%, black 75%) 3",
              height: 120,
              width: 120,
            },
            ":after": {
              display: "block",
              position: "absolute",
              content: "''",
              borderRight: "3px solid",
              borderLeft: "3px solid",
              borderImage:
                "linear-gradient(to bottom, black 25%, transparent 25%, transparent 50%,transparent 50%, transparent 75%, black 75%) 3",
              height: 120,
              width: 120,
            },
          },
          "& .AppQRCode-qrcode": {
            height: 100,
            width: 100,
            border: "none",
          },
          "& .AppQRCode-caption": {
            paddingTop: theme.spacing(1),
            fontWeight: 500,
            fontSize: "0.875rem",
            color: theme.palette.text.secondary,
          },
        },
        closeButton: {
          position: "absolute",
          top: 33,
          right: 75,
        },
      },
    },
    FeatureActionContainer: {
      styleOverrides: {
        shortInfoActionWrapper: {
          "button, a": {
            pointerEvents: "all",
          },
          [theme.breakpoints.up("sm")]: {
            paddingBottom: theme.spacing(3),
          },
        },
      },
    },
    AppDesktopFeatureDialog: {
      styleOverrides: {
        dialog: {
          height: "100%",
          maxWidth: "50vw",
          left: "unset",
          "& .MuiDialog-container": {
            justifyContent: "flex-end",
          },
        },
        contentContainer: {
          padding: theme.spacing(3),
          background: theme.palette.common.white,
          boxShadow: "none",
          [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(1.25),
          },
        },
      },
    },
    EventPage: {
      styleOverrides: {
        root: {
          height: `100%`,
        },
        actionButton: {
          minWidth: 128,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          "& .MuiSvgIcon-root": {
            marginRight: 0,
            width: 32,
            height: 32,
          },
        },
        actionContainer: {
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(1.5),
        },
        toolbarContainer: {
          position: "relative",
          display: "flex",
          gap: theme.spacing(3),
        },
        searchBarContainer: {
          width: "100%",
          height: 48,
        },
        emptyDataWrapper: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: 0,
          paddingBottom: 0,
          height: "810px", // Specifies the carousel height, inclusive of pagination
          position: "relative",
        },
        carouselContainer: {
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: theme.spacing(5),
          position: "absolute",
          left: 0,
          right: 0,
          overflow: "hidden",
        },
        carousel: {
          "& .splide": {
            margin: "0 auto",
            marginTop: theme.spacing(3.75), // Avoid cropping the top slide during scaling
          },
          "& .splide__pagination": {
            marginTop: theme.spacing(6.25),
          },
          "& .splide__pagination__page": {
            width: "14px",
            height: "14px",
          },
          "& .splide__pagination__page.is-active": {
            backgroundColor: theme.palette.primary.main,
            transition: `width ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}, borderRadius ${theme.transitions.duration.standard}s ${theme.transitions.easing.easeInOut}`,
            transform: "none",
            width: 45,
            borderRadius: 30,
          },
          "& .splide__track": {
            padding: 0,
          },
          "& .splide__slide": {
            "&> *": {
              display: "inline-block",
              transform: "scale(0.8)",
              opacity: 0.4,
              transition: `all ${theme.transitions.duration.short}ms`,
            },
            "&.is-visible.is-active": {
              "&> *": {
                opacity: 1,
                transform: "scale(1)",
              },
            },
          },
        },
        eventGroupSelectorButton: {
          display: "flex",
          justifyContent: "center",
          gap: 3,
          "& .MuiToggleButton-root": {
            width: "244px",
            height: "68px",
            fontSize: "1rem",
            color: theme.palette.text.primary,
          },
        },
        navigationWrapper: {
          zIndex: theme.zIndex.appBar,
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: theme.spacing(0, 6.25),
          gap: theme.spacing(3),
        },
        mainMenuContainer: {},
        venueSelector: {
          position: "absolute",
          bottom: `${mixins.kioskNavigation.height + 34}px`,
          right: "50px",
          zIndex: 2,
        },
      },
    },
    VerticalEventCard: {
      styleOverrides: {
        root: {
          minHeight: "732px",
          width: "500px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          boxShadow: theme.shadows[1],
        },
        cardMedia: {
          height: "650px",
          width: "100%",
          objectFit: "cover",
          position: "relative",
        },
        cardContent: {
          padding: theme.spacing(2, 3),
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          "&.MuiCardContent-root:last-child": {
            paddingBottom: theme.spacing(1),
          },
          "& .MuiTypography-root": {
            fontSize: "1rem",
          },
        },
        cardTitle: {
          fontWeight: 600,
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          "&.MuiTypography-root": {
            fontSize: "1.25rem",
          },
        },
        iconContainer: {
          width: "auto",
          marginRight: theme.spacing(1.5),
          "& .MuiSvgIcon-root": {
            height: 12,
            width: 10,
            color: theme.palette.primary.main,
          },
        },
        cardInfoContainer: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          "& .MuiTypography-root": {
            fontSize: "0.75rem",
          },
        },
        venueLogoContainer: {
          display: "none",
          "& .MuiCardMedia-root": {
            maxWidth: "120px",
          },
        },
        cardContentStack: {},
        durationContainer: {},
        recommendText: {
          width: "100%",
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          bottom: 0,
          left: 0,
          borderRadius: "4px 4px 0px 0px",
          textAlign: "center",
          color: theme.palette.primary.contrastText,
        },
      },
    },
    ImageCard: {
      styleOverrides: {
        root: {},
        chipContainer: {
          position: "absolute",
          padding: "3%",
          right: 0,
          top: 0,
          zIndex: 1,
        },
        chip: {
          backgroundColor: theme.palette.error.main,
          borderRadius: "10px",
          color: theme.palette.error.contrastText,
        },
      },
    },
    PromotionCard: {
      styleOverrides: {
        root: {
          minHeight: "782px",
          width: "500px",
          borderRadius: "10px",
          boxShadow: theme.shadows[1],
          padding: 0,
          display: "flex",
          flexDirection: "column",
        },
        cardMedia: {
          height: "700px",
          maxHeight: "none",
          width: "100%",
          borderRadius: "none",
          objectFit: "cover",
          position: "relative",
        },
        cardContent: {
          padding: theme.spacing(1.5, 2),
          "&:last-child": {
            paddingBottom: theme.spacing(1),
          },
          [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2.5, 4.5),
          },
        },
        venueLogoContainer: {
          display: "none",
          "& .MuiCardMedia-root": {
            maxWidth: "120px",
          },
        },
        cardContentStack: {},
        cardTitle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          color: theme.palette.text.primary,
        },
        cardInfoContainer: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: theme.palette.grey[700],
          fontSize: theme.typography.subTitle.fontSize,
        },
        iconContainer: {
          width: "auto",
          marginRight: theme.spacing(1),
          "& .MuiSvgIcon-root": {
            height: 14,
            color: theme.palette.primary.main,
          },
        },
        recommendText: {
          width: "100%",
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          bottom: 0,
          left: 0,
          borderRadius: "4px 4px 0px 0px",
          textAlign: "center",
          color: theme.palette.primary.contrastText,
        },
      },
    },
    HighlightFeatureCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: `${(theme.shape.borderRadius * 2) / 3}px`,
          display: "flex",
          flexDirection: "row",
          height: 136,
          padding: theme.spacing(1.5, 1.375),
        },
        cardMedia: {
          width: 74,
          maxHeight: 164,
          borderRadius: "5px",
          objectFit: "cover",
          [theme.breakpoints.up("sm")]: {
            width: 95,
          },
        },
        cardContent: {
          padding: theme.spacing(1.375, 0, 1.375, 3),
          "&:last-child": {
            paddingBottom: theme.spacing(1),
            [theme.breakpoints.up("sm")]: {
              padding: theme.spacing(1, 1, 1, 3),
            },
          },
        },
        contentWrapper: {
          height: "100%",
          justifyContent: "space-between",
        },
        cardTitle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          color: theme.palette.text.primary,
        },
        eventInfoItem: {
          display: "flex",
          flexDirection: "row",
          color: theme.palette.text.secondary,
          "& .MuiTypography-root": {
            fontSize: "0.875rem",
            fontWeight: 500,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
          },
        },
        iconWrapper: {
          width: "auto",
          marginRight: theme.spacing(1),
          "& .MuiSvgIcon-root": {
            height: 14,
            color: theme.palette.primary.main,
          },
        },
      },
    },
    ListPromotionItemCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: `${(theme.shape.borderRadius * 2) / 3}px`,
          display: "flex",
          flexDirection: "row",
          height: 104,
          padding: theme.spacing(1, 0.5),
        },
        cardMedia: {
          width: 88,
          maxHeight: 88,
          borderRadius: "5px",
          objectFit: "cover",
          [theme.breakpoints.up("sm")]: {
            width: 95,
          },
        },
        cardContent: {
          padding: theme.spacing(0.5, 1, 0.5, 2),
          "&:last-child": {
            paddingBottom: theme.spacing(0.5),
            [theme.breakpoints.up("sm")]: {
              padding: theme.spacing(0.5, 1, 0.5, 2),
            },
          },
        },
        contentWrapper: {
          height: "100%",
          gap: theme.spacing(2),
          justifyContent: "space-between",
        },
        cardTitle: {
          fontWeight: 600,
          fontSize: "14px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          color: theme.palette.text.primary,
        },
        promotionInfoItem: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: theme.palette.text.secondary,
          "& .MuiTypography-root": {
            fontSize: "0.75rem",
            fontWeight: 400,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
          },
        },
        iconWrapper: {
          width: "auto",
          marginRight: theme.spacing(1),
          "& .MuiSvgIcon-root": {
            height: 16,
            color: "inherit",
          },
        },
      },
    },
    ListPrivilegeItemCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: "8px",
          padding: theme.spacing(1),
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(2),
          "& .MuiSvgIcon-root": {
            color: theme.palette.grey[500],
            width: 16,
            height: 16,
          },
        },
        label: {
          color: theme.palette.text.secondary,
          fontWeight: 600,
        },
      },
    },
    FeatureSummary: {
      styleOverrides: {
        root: {},
        container: {
          display: "flex",
          gap: theme.spacing(2),
          boxShadow: "none",
        },
        logoContainer: {
          width: "60px",
          height: "60px",
        },
        infoSection: {},
        infoContainer: {},
        title: {
          marginBottom: theme.spacing(1),
        },
        endAdornment: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          width: "80px",
          height: "80px",
          paddingLeft: theme.spacing(2),
          "&:after": {
            content: "''",
            position: "absolute",
            width: "1px",
            top: 0,
            left: 0,
            bottom: 0,
            backgroundColor: theme.palette.grey[500],
            marginTop: theme.spacing(1.25),
            marginBottom: theme.spacing(1.25),
          },
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "subtitle1" },
          style: {
            lineHeight: 1.4,
          },
        },
      ],
    },
    DirectionStepItem: {
      styleOverrides: {
        root: {
          "& .MuiStepLabel-iconContainer": {
            paddingRight: theme.spacing(4),
          },
          "&:first-of-type": {
            "& .MuiListItemText-primary": {
              fontWeight: 700,
            },
          },
          "&:last-of-type": {
            "& .MuiListItemText-primary": {
              fontWeight: 600,
            },
            "& .MuiStepLabel-iconContainer": {
              paddingLeft: theme.spacing(0.5),
              paddingRight: theme.spacing(3),
            },
          },

          "& .MuiStepIcon-root": {
            width: 37,
            height: 37,
            "& .MuiStepIcon-text": {
              fontSize: theme.typography.caption.fontSize,
            },
          },
          "& .MuiStepLabel-vertical": {
            padding: 0,
          },
        },
        listItem: {
          "& .MuiListItemText-primary": {
            whiteSpace: "normal",
            color: theme.palette.text.primary,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.subtitle2.fontWeight,
          },
          "& .MuiListItemText-secondary": {
            fontSize: "0.75rem",
          },
          "& .MuiListItemSecondaryAction-root": {
            marginLeft: "auto",
            position: "static",
            transform: "none",
            color: theme.palette.text.secondary,
            right: 0,
          },
        },
      },
    },
    EmptyData: {
      styleOverrides: {
        root: {
          width: "100%",
          alignItems: "center",
          "& .MuiSvgIcon-root": {
            width: "14rem",
            height: "9rem",
            color: theme.palette.grey[200],
          },
        },
        secondaryText: {
          color: theme.palette.grey[600],
        },
        iconWrapper: {
          marginBottom: theme.spacing(1),
        },
      },
    },
    NewShopsPage: {
      styleOverrides: {
        emptyDataContainer: {
          height: `calc(100vh - ${mixins.vKioskHeader?.height}px - ${mixins.vKioskFooter?.height}px - ${mixins.kioskNavigation?.height}px - 60px - 32px)`, // 60px, 32px (container padding)
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    },

    OccupantCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: "10px",
          width: "100%",
        },
        cardTitle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          color: theme.palette.text.primary,
        },
        cardMediaWrapper: {
          position: "relative",
        },
        cardMedia: {
          height: "180px",
        },
        cardContent: {
          padding: theme.spacing(1.5, 2),
          "&:last-child": {
            paddingBottom: theme.spacing(1),
          },
        },
      },
    },
    UserLocationListItem: {
      styleOverrides: {
        icon: {
          background: "transparent",
          color: theme.palette.text.primary,
          fontSize: "3rem",
          "& .MuiSvgIcon-root": {
            fontSize: "inherit",
          },
        },
      },
    },
    FeatureListItem: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "row",
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
        adornmentDivider: {
          marginLeft: theme.spacing(3),
          marginRight: theme.spacing(2),
          borderRight: "1px solid rgba(203, 203, 203, 0.5)",
        },
        listItemContent: {
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          justifyContent: "space-between",
        },
        adornmentWrapper: {
          display: "flex",
          flexDirection: "row",
          height: "55px",
          "& img": {
            objectFit: "contain",
            maxWidth: "60px",
            maxHeight: "55px",
          },
        },
        bottomDivider: {
          borderBottom: "1px solid rgba(203, 203, 203, 0.5)",
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
        },
        listItemText: {
          "& .MuiListItemText-primary": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            lineHeight: 1.4,
            wordBreak: "break-all",
          },
          "& .MuiListItemText-secondary": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            lineHeight: 1.4,
          },
        },
        listItemAvatar: {
          "& .MuiAvatar-root": {
            fontSize: "2.275rem",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "inherit",
          },
        },
        boothNameText: {
          display: "none",
        },
      },
    },
    DirectionSearchContainer: {
      styleOverrides: {
        wrapper: {
          width: "100%",
          [theme.breakpoints.down("sm")]: {
            maxWidth: "100%",
          },
        },
        suggestionList: {
          marginTop: theme.spacing(8),
          // direction search box = 108 px
          // avoid step button 32 px + top-bottom margin 16px * 2 = 64px
          // top-bottom page layout padding 24px * 2 = 48px
          maxHeight: `calc(100vh - 220px)`,
          overflow: "auto",
          background: theme.palette.background.paper,
          boxShadow: theme.shadows[1],
          position: "absolute",
          zIndex: theme.zIndex.drawer + 1,
          borderRadius: "10px",
          //left-right layout padding = 24 * 2 = 48px
          width: `calc(100vw - 48px)`,
          [theme.breakpoints.up("sm")]: {
            //left-right layout padding = 24 * 2 = 48px
            maxWidth: mixins.mobileDirectionSearch.width - 48,
          },
        },
      },
    },
    AppOrdinalSelector: {
      styleOverrides: {
        root: {
          borderRadius: `${theme.shape.borderRadius * 1.33}px`,
          backgroundColor: theme.palette.background.default,
          boxShadow: theme.shadows[15],
          width: 40,
          padding: theme.spacing(0.25),
        },
        listItemButton: {
          justifyContent: "center",
          borderRadius: 9999,
          backgroundColor: theme.palette.background.default,
          fontSize: "0.875rem",
          fontWeight: theme.typography.fontWeightRegular,
          transition: `color ${theme.transitions.duration.short}ms ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut} , background-color ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
          paddingTop: theme.spacing(0.9375),
          paddingBottom: theme.spacing(0.9375),
          "&.Mui-selected": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
      },
    },
    AppPageCard: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          height: "100%",
          boxShadow: "none",
        },
      },
    },
    AppPageCardContent: {
      styleOverrides: {
        root: {
          height: "100%",
          padding: theme.spacing(2.5),
        },
      },
    },
    LanguageButton: {
      defaultProps: {
        MenuProps: {
          transformOrigin: { horizontal: "right", vertical: "top" },
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        },
      },
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
          },
        },
        menuPaper: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          marginTop: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            marginLeft: -0.5,
            marginRight: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            backgroundColor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
        label: { display: "none" },
        avatar: { width: "32px", height: "32px" },
      },
    },
    AppQRCode: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: theme.spacing(5),
          borderRadius: "8px",
          width: 150,
          padding: theme.spacing(2, 2, 3),
        },
        qrCodeContent: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: theme.spacing(0),
        },
        qrCodeCaption: {
          textTransform: "uppercase",
          fontSize: "0.6875rem",
          paddingTop: theme.spacing(1),
        },
        qrCode: {
          width: "100%",
          height: "100%",
        },
      },
    },
    OrdinalSelectMenu: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          borderRadius: "9999px",
          height: "56px",
          width: "172px",
          textAlign: "center",
          "&.Mui-focused": {
            border: "none",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "& svg": {
              color: theme.palette.primary.contrastText,
            },
          },
          "& .MuiInputBase-input.MuiSelect-select": {
            fontSize: "1.5rem",
            minHeight: "unset",
          },
          "& .MuiSelect-icon": {
            color: "inherit",
          },
        },
        paper: {
          backgroundColor: "transparent",
          boxShadow: "none",
          alignItems: "center",
          maxHeight: "34vh",
          //hide scrollbar
          "::-webkit-scrollbar": {
            display: "none",
          },
          MsOverflowStyle: "none",
          scrollbarWidth: "none",
        },
        menuList: {
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        },
        menuItem: {
          margin: theme.spacing(1),
          backgroundColor: theme.palette.background.paper,
          fontSize: "1.25rem",
          borderRadius: "50%",
          height: "56px",
          width: "56px",
          padding: 0,
          minHeight: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transformOrigin: "top center",
          animation: `slideUp 300ms ease-in-out forwards`,
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.contrastText} `,
            color: theme.palette.primary.contrastText,
            ":hover": {
              backgroundColor: theme.palette.primary.main,
            },
          },
          ":hover": {
            backgroundColor: theme.palette.primary.main,
          },
        },
        text: {
          textAlign: "center",
          textTransform: "uppercase",
          fontSize: "1.25rem",
          color: theme.palette.text.secondary,
        },
      },
    },
    BorderLinearProgress: {
      styleOverrides: {
        root: {
          position: "relative",
          width: "100%",
          "&:after": {
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            content: "''",
            display: "block",
            height: 4,
            width: "100%",
            borderRadius: 5,
            backgroundColor: theme.palette.grey[300],
            right: 0,
          },
        },
        linearProgress: {
          height: 20,
          borderRadius: 15,
          zIndex: 1,
          ":after": {
            position: "absolute",
            zIndex: 5,
            top: "50%",
            transform: "translateY(-50%)",
            height: 4,
            content: "''",
            display: "block",
          },
          "&.MuiLinearProgress-colorPrimary": {
            backgroundColor: "transparent",
            "&.Mui-start": {
              ":after": {
                backgroundColor: theme.palette.primary.main,
              },
            },
            ":after": {
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              content: "''",
              display: "inline-block",
              width: 14,
              height: 14,
              borderRadius: "50%",
              backgroundColor: theme.palette.grey[300],
              zIndex: 1,
              left: 0,
            },
            "&.Mui-complete": {
              ":before": {
                backgroundColor: theme.palette.primary.main,
              },
              ":after": {
                backgroundColor: theme.palette.primary.main,
              },
            },
            ":before": {
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              content: "''",
              display: "inline-block",
              width: 14,
              height: 14,
              borderRadius: "50%",
              backgroundColor: theme.palette.grey[300],
              zIndex: 1,
              right: 0,
            },
          },
          "& .MuiLinearProgress-bar": {
            margin: "auto 0",
            height: 4,
            borderRadius: 5,
            backgroundColor: theme.palette.primary.main,
            boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
          },
        },
      },
    },
    MapUpdateLoadingScreen: {
      styleOverrides: {
        backdrop: {
          zIndex: theme.zIndex.modal,
        },
        container: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(1),
          color: theme.palette.primary.main,
        },
      },
    },
    StepProgressBar: {
      styleOverrides: {
        wrapper: {
          width: "100%",
          overflowX: "scroll",
          padding: theme.spacing(1),
          marginTop: 0,
          scrollbarWidth: "none", // Hide the scrollbar for firefox
          "&::-webkit-scrollbar": {
            display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
          },
          "&-ms-overflow-style:": {
            display: "none", // Hide the scrollbar for IE
          },
        },
        stepper: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
          "& .MuiStepLabel-iconContainer": {
            padding: 0,
          },
        },
        originIcon: {
          "&.MuiSvgIcon-root": {
            height: "28px",
            width: "28px",
            fill: theme.palette.primary.main,
          },
        },
        connector: {
          "& .MuiStepConnector-line": {
            height: 3,
            border: 0,
            minWidth: 36,
            backgroundColor: theme.palette.grey[200],
            borderRadius: 1,
          },
          "&.Mui-active": {
            "& .MuiStepConnector-line": {
              backgroundColor: theme.palette.primary.main,
            },
          },
          "&.Mui-completed": {
            "& .MuiStepConnector-line": {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
      },
    },
    VenueSelector: {
      styleOverrides: {
        root: {
          gap: theme.spacing(2),
        },
        buttonWrapper: {
          display: "flex",
          justifyContent: "center",
          transition: `transform ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeIn}`,
          "&.selected": {
            transform: "scale(1.5)",
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
          },
        },
        button: {
          width: 70,
          height: 70,
          padding: 0,
          backgroundColor: theme.palette.primary.main,
          borderRadius: "50%",
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            ":hover": {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
        icon: {
          width: "100%",
          height: "100%",
        },
        currentVenueChip: {
          width: "48px",
          height: "12px",
          position: "absolute",
          bottom: "-4px",
        },
      },
    },
    VuiNotificationContainer: {
      styleOverrides: {
        root: {},
        snackbarContent: {
          marginTop: `${mixins.vKioskHeader?.height || 350}px`,
        },
      },
    },
    VuiDetailCard: {
      styleOverrides: {
        navigationWrapper: {
          zIndex: theme.zIndex.appBar,
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: theme.spacing(0, 6.25),
          gap: theme.spacing(3),
        },
        actionButton: {
          minWidth: 128,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          "& .MuiSvgIcon-root": {
            marginRight: 0,
            width: 32,
            height: 32,
          },
        },
        actionContainer: {
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(1.5),
        },
        toolbarContainer: {
          position: "relative",
          display: "flex",
          gap: theme.spacing(3),
        },
        searchBarContainer: {
          width: "100%",
          height: 48,
        },
      },
    },
    SuggestionListItem: {
      defaultProps: {},
    },
  },
})
