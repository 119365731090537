import React from "react"
import { deepmerge } from "@mui/utils"
import { Link as BaseLink } from "react-router-dom"
import { Box, useTheme } from "@mui/material"

/**
 * NavigationLink is a component for attaching and styling any child component placed behind a menu item via the `backSideProps` prop.
 * The backSide component will be hidden by default.
 */
export const NavigationLink = React.forwardRef(
  ({ children, sx = {}, backSideProps = {}, ...props }, ref) => {
    const { children: backSideChildren, sx: backSideSx = {} } = backSideProps

    const theme = useTheme()
    const { root, backSide } =
      theme.components[`AppNavigationLink`]?.styleOverrides || {}
    return (
      <BaseLink sx={deepmerge(root, sx)} {...props} ref={ref}>
        {children}
        <Box
          className="MuiNavigationLink-backside"
          sx={deepmerge(backSide, backSideSx)}
        >
          {backSideChildren}
        </Box>
      </BaseLink>
    )
  }
)
