import { useCallback } from "react"
import { ShareIcon } from "components/icons"
import { Button } from "@mui/material"
import { useTranslation } from "providers/i18n"

export const ShareButton = ({
  title,
  text,
  url,
  onClick = () => {},
  label = "Share",
  ...props
}) => {
  const { t } = useTranslation()
  const isSharebale = !!navigator.share
  const handleClick = useCallback(async () => {
    onClick()
    if (isSharebale) {
      try {
        await navigator.share({ title, text, url })
        alert("Share successfull")
      } catch (err) {
        console.log("Error: ", err)
      }
    } else {
      console.log("Native Web Sharing not supported")
    }
  }, [onClick, text, title, url, isSharebale])

  return (
    isSharebale && (
      <Button
        variant="outlined"
        target="_top"
        rel="noopener noreferrer"
        startIcon={<ShareIcon />}
        onClick={handleClick}
        {...props}
      >
        {t(label)}
      </Button>
    )
  )
}
