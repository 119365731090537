import React, { useCallback, useEffect, useState } from "react"
import _ from "lodash"
export interface IUseDetailTab {
  data?: {
    [k: string]: any
  }
  enabled?: boolean
}

export type ChangeDetailTab = (
  event: React.SyntheticEvent,
  newValue: string
) => void

export interface IDetailTabProps {
  gallery: { index: string }
  promotion: { index: string }
}

export interface IUseDetailTabResult {
  showGallery: boolean
  showPromotion: boolean
  showDetailTabs: boolean
  selectedDetailTab: string | boolean
  detailTabProps: IDetailTabProps
  changeDetailTab: ChangeDetailTab
}

export type UseDetailTab = (props: IUseDetailTab) => IUseDetailTabResult

const detailTabProps: IDetailTabProps = {
  gallery: {
    index: "gallery-tab",
  },
  promotion: {
    index: "promotion-tab",
  },
}

export const useDetailTab: UseDetailTab = ({ data = {}, enabled = false }) => {
  const { gallery, promotions } = data
  const [selectedDetailTab, setSelectedDetailTab] = useState<string | boolean>(
    false
  )
  const showGallery = _.size(gallery) > 0
  const showPromotion = _.size(promotions) > 0
  const showDetailTabs = showGallery || showPromotion

  // Set default active tab
  useEffect(() => {
    if (enabled) {
      setSelectedDetailTab(
        showDetailTabs
          ? showGallery
            ? detailTabProps.gallery.index
            : detailTabProps.promotion.index
          : false
      )
    }
  }, [showDetailTabs, showGallery, enabled])

  const changeDetailTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setSelectedDetailTab(newValue)
    },
    []
  )

  return {
    showGallery,
    showPromotion,
    showDetailTabs,
    detailTabProps,
    selectedDetailTab,
    changeDetailTab,
  }
}
