import { Box, CardMedia, styled, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"

import { Link } from "react-router-dom"

const StyledLink = styled(Link)(() => ({}))

export const PromotionList = ({ sx = {}, data = [], ...props }) => {
  const theme = useTheme()
  const { styleOverrides = {} } = theme.components[`PromotionList`] || {}
  const { root, promotionListItem } = styleOverrides

  return (
    <Box sx={deepmerge(root, sx)} {...props}>
      {data.map((promotion) => (
        <StyledLink
          to={`/promotions/${promotion.id}?redirectTo=-1`}
          key={promotion.id}
          sx={promotionListItem}
        >
          <CardMedia
            component="img"
            sx={promotionListItem}
            src={promotion?.properties?.cover_image?.url}
          />
        </StyledLink>
      ))}
    </Box>
  )
}
