import { useVenue } from "providers/venue"

interface IConfig {
  options: {
    [k: string]: any
  }
  [k: string]: any
}

/**
 * Example Extension Config
 * {
 *  'em-transportation': { options: {....} },
 *  'em-kids-and-family': { options: {....} },
 *  'live-traffic': { options: {....} },
 * }
 */
interface IExtensionConfig {
  [k: string]: IConfig
}

interface IUseExtensionResult {
  extensionConfig: IExtensionConfig
}

export const useExtension = (): IUseExtensionResult => {
  const { extensionConfig } = useVenue()

  return {
    extensionConfig,
  }
}
