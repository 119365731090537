import { useCallback, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import _ from "lodash"

import { Q_PARENT_CATEGORY_ID, Q_VENUE, PROMOTION_TAXONOMY } from "constant"

import { useVenue } from "providers/venue"
import { useGetPromotions } from "providers/venue/modules/data"
import { useGeoLocation } from "providers/geolocation"
import { useVenueController } from "hooks/venue/useVenueController"
import { useRecommendedPromotion } from "hooks/sponsored-content/recommended"
import { filterCurrentFeature, filterUpcomingFeature } from "utils/feature"

const DEFAULT_FILTER_VALUE = "ALL"

export const usePromotion = () => {
  const { data: promotions = [] } = useGetPromotions()
  const { venues, isMultiVenueProject, taxonomies = [] } = useVenue()
  const { locationVenue } = useGeoLocation()
  const locationVenueId = _.get(locationVenue, "id")
  const { viewingVenue: venueFilter } = useVenueController(locationVenueId)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [activeGroupPromotion, setActiveGroupPromotion] = useState("current")
  const { recommendedPromotions } = useRecommendedPromotion()

  // Query String filters
  const parentCategoryId = useMemo(
    () => searchParams.get(Q_PARENT_CATEGORY_ID) || DEFAULT_FILTER_VALUE,
    [searchParams]
  )

  const promotionCategories = useMemo(
    () =>
      taxonomies.filter(
        ({ properties }) => properties.taxonomy === PROMOTION_TAXONOMY
      ),
    [taxonomies]
  )

  const showCategoryFilter = useMemo(
    () => promotionCategories.length > 0,
    [promotionCategories]
  )

  const changeVenueFilter = useCallback(
    (venueId) => {
      if (venueId) searchParams.set(Q_VENUE, venueId)
      navigate(`/promotions/?${searchParams.toString()}`, { replace: true })
    },
    [navigate, searchParams]
  )

  const handleChangeParentCategory = useCallback(
    (e, id) => {
      if (id === null) return
      searchParams.set(Q_PARENT_CATEGORY_ID, id)
      navigate(`/promotions?${searchParams.toString()}`, { replace: true })
    },
    [navigate, searchParams]
  )

  const storePromotions = useMemo(
    () =>
      promotions?.filter(({ properties }) => properties.category === "store"),
    [promotions]
  )

  const storePromotionsInParentCategory = useMemo(() => {
    return storePromotions.filter(({ properties }) => {
      const categories = _(properties).get("local_parent_categories", [])
      const isMatchedCategoryId = _(categories)
        .map("id")
        .includes(parentCategoryId)
      return (
        parentCategoryId === DEFAULT_FILTER_VALUE ||
        (parentCategoryId && isMatchedCategoryId)
      )
    })
  }, [storePromotions, parentCategoryId])

  const storePromotionInViewingVenue = useMemo(
    () =>
      storePromotions?.filter(
        ({ properties }) => properties.venue_id === venueFilter
      ),
    [storePromotions, venueFilter]
  )

  const filteredPromotions = useMemo(() => {
    const recommendedStorePromotions = _.intersectionBy(
      recommendedPromotions.data,
      storePromotionInViewingVenue,
      "id"
    )
    const storePromotions = _.intersectionBy(
      storePromotionsInParentCategory,
      storePromotionInViewingVenue,
      "id"
    )
    return _.compact([...recommendedStorePromotions, ...storePromotions])
  }, [
    recommendedPromotions,
    storePromotionInViewingVenue,
    storePromotionsInParentCategory,
  ])

  const currentFilteredPromotions = useMemo(
    () => filteredPromotions.filter(filterCurrentFeature),
    [filteredPromotions]
  )

  const upcomingFilteredPromotions = useMemo(
    () => filteredPromotions.filter(filterUpcomingFeature),
    [filteredPromotions]
  )

  const handleChange = useCallback((e, promotionGroup) => {
    if (promotionGroup === null) return
    setActiveGroupPromotion(promotionGroup)
  }, [])

  const groupPromotions = useMemo(
    () => ({
      current: currentFilteredPromotions,
      upcoming: upcomingFilteredPromotions,
    }),
    [currentFilteredPromotions, upcomingFilteredPromotions]
  )

  return {
    parentCategoryId,
    promotionCategories,
    storePromotions,
    filteredPromotions,
    locationVenueId,
    venueFilter,
    venues,
    isMultiVenueProject,
    showCategoryFilter,
    groupPromotions,
    activeGroupPromotion,
    changeVenueFilter,
    handleChangeParentCategory,
    handleChange,
  }
}
