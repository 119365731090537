import {
  Box,
  Backdrop,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material"
import { MotionDiv, loadingLogo, loadingScreen } from "../motions"
import { Image } from "components/Image"
import { useTranslation } from "providers/i18n"

export const MapUpdatingScreen = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { backdrop, container } =
    theme.components?.["MapUpdateLoadingScreen"].styleOverrides || {}
  return (
    <Backdrop {...props} sx={backdrop} className="map-updating">
      <Box sx={container}>
        <CircularProgress />
        <Typography>{t("Updating Map")}</Typography>
      </Box>
    </Backdrop>
  )
}

export const LoadingScreen = (props) => {
  const theme = useTheme()
  const { logo } = theme.assets || {}
  const styleOverrides = theme.components[`LoadingScreen`]?.styleOverrides || {}
  const { root, logo: logoStyle } = styleOverrides
  return (
    <MotionDiv
      variants={loadingScreen}
      initial="initial"
      animate="enter"
      exit="exit"
      sx={root}
      className="loading-screen"
      {...props}
    >
      <MotionDiv key="logo" variants={loadingLogo} animate="animate">
        {logo && <Image src={logo.url} alt="Logo" sx={logoStyle} />}
      </MotionDiv>
    </MotionDiv>
  )
}
