import { BaseObject } from "maptalks.three"
import * as THREE from "three"
import { createObject3dFromSvgPath } from "../utils/createElements"
import _ from "lodash"

const OPTIONS = {
  scale: 0.0015,
  altitude: 0,
}

const DEFAULT_PIN_MARKER_PATH = "M45.5 0h-43v43h16.2l5.9 5 5.8-5h15.1z"

export class Marker3d extends BaseObject {
  constructor(
    coordinate,
    options,
    iconPath,
    pinMarkerPath = DEFAULT_PIN_MARKER_PATH,
    layer,
    properties
  ) {
    super()
    //Initialize internal configuration
    this._initOptions(options)
    const {
      altitude = OPTIONS.altitude,
      scale = OPTIONS.scale,
      pinMarkerColor,
      iconColor,
    } = options
    this.properties = { ...properties }
    this._createGroup()

    const defaultExtrusion = 0

    const pinMarkerMaterial = new THREE.MeshBasicMaterial({
      color: pinMarkerColor,
      polygonOffset: true,
      polygonOffsetFactor: 1,
      polygonOffsetUnits: 1,
    })

    const pinMarker = createObject3dFromSvgPath(pinMarkerPath, {
      extrusion: defaultExtrusion,
      scale,
      material: pinMarkerMaterial,
    })

    const { x, y } = _.get(pinMarker, "children[0].position")
    const positionOfPinMarker = { x, y }
    const xOffsetIcon = positionOfPinMarker.x / 2 - 1
    const yOffsetIcon = positionOfPinMarker.y / 2 - 2

    if (iconPath) {
      const iconMaterial = new THREE.MeshBasicMaterial({
        color: iconColor,
      })
      const icon = createObject3dFromSvgPath(iconPath, {
        extrusion: defaultExtrusion,
        scale,
        xOffset: xOffsetIcon,
        yOffset: yOffsetIcon,
        material: iconMaterial,
      })
      this.getObject3d().add(icon)
    }

    this.getObject3d().add(pinMarker)
    // set object3d position
    const z = layer.altitudeToVector3(altitude + 2).x
    const position = layer.coordinateToVector3(coordinate, z)
    _.set(this.properties, "default.position", position)
    this.getObject3d().position.copy(position)
  }
}
