import React from "react"
import { useParams } from "react-router-dom"
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  IconButton,
  List,
  Typography,
  useTheme,
} from "@mui/material"
import LanguageIcon from "@mui/icons-material/Language"

import { getDurationDate } from "utils/date"
import { usePromotionDetail } from "hooks/promotion/usePromotionDetail"
import { useTranslation } from "providers/i18n"

import { PageLayout } from "layouts/PageLayout"

import { ShareIconButton } from "components/Button"
import { CalendarIcon, LocationIcon } from "components/icons"
import { Head } from "components/Head"
import { InfoListItemWithNavigation, InfoListItem } from "components/ListItem"
import { LocationInfo } from "components/domains/features/LocationInfo"

export const PromotionDetail = ({ Layout = PageLayout }) => {
  const params = useParams()
  const { promotionId } = params
  const { promotion, navigateToLocation, openWebsiteLink } =
    usePromotionDetail(promotionId)

  const theme = useTheme()
  const styleOverrides = theme.components[`VuiDetailCard`]?.styleOverrides || {}
  const {
    card,
    image,
    infoListItem,
    infoListItemWithNavigation,
    cardContent,
    cardHeader,
    cardHeaderAction,
    cardHeaderActionButton,
    cardContentPrimaryText,
    cardContentSecondaryText,
    infoListWrapper,
  } = styleOverrides

  const { t } = useTranslation()

  if (!promotion) return null

  return (
    <>
      <Head title={`${t(promotion.name)} (${t("Promotion Detail")})`} />
      <Layout>
        <Card elevation={0} sx={card}>
          <CardMedia component="img" sx={image} src={promotion.coverImage} />
          <CardHeader
            title={t(promotion.name)}
            action={
              <Box sx={cardHeaderAction}>
                {!!promotion.websiteLink && (
                  <IconButton
                    className="website-button"
                    color="primary"
                    // * URL should be prepended with protocol (e.g. "https://") for the URL to be replaced
                    onClick={openWebsiteLink}
                    sx={cardHeaderActionButton}
                  >
                    <LanguageIcon />
                  </IconButton>
                )}
                <ShareIconButton
                  className="share-button"
                  url={window.location.href}
                  sx={cardHeaderActionButton}
                />
              </Box>
            }
            sx={cardHeader}
          />
          <List sx={infoListWrapper}>
            <InfoListItem
              sx={infoListItem}
              icon={<CalendarIcon />}
              primary={getDurationDate(promotion.startDate, promotion.endDate)}
              secondary={promotion.openingText}
            />
            {promotion.hasLocation && (
              <InfoListItemWithNavigation
                sx={infoListItemWithNavigation}
                icon={<LocationIcon />}
                primary={t("Navigate")}
                secondary={
                  <LocationInfo feature={promotion.feature} component="span" />
                }
                onClick={navigateToLocation}
              />
            )}
          </List>
          <CardContent sx={cardContent}>
            <Typography
              variant="body1"
              gutterBottom
              sx={cardContentPrimaryText}
            >
              {t("Promotion Detail")}
            </Typography>
            <Typography
              variant="body2"
              sx={cardContentSecondaryText}
              dangerouslySetInnerHTML={{
                __html: promotion.descriptionText,
              }}
            ></Typography>
          </CardContent>
        </Card>
      </Layout>
    </>
  )
}
