import _ from "lodash"
import { pushDataLayer } from "./pushDataLayer"
import { GTM_SEARCH_KEYWORD, GTM_CATEGORY_QUICK_SEARCH } from "constant"

export const gtmPushDataLayerSearchKeyword = (
  searchValue: string,
  resultName: string
) => {
  if (_.trim(searchValue))
    pushDataLayer({
      event: GTM_SEARCH_KEYWORD,
      keyword: _.trim(searchValue),
      selected_result_name: resultName,
    })
}

export const gtmPushDataLayerCategoryQuickSearch = (categoryName: string) => {
  pushDataLayer({
    event: GTM_CATEGORY_QUICK_SEARCH,
    search_category_name: categoryName,
  })
}
