import _ from "lodash"
import { DEFAULT_FEATURE_QUICK_SEARCH_ORDER_CONFIG } from "hooks/feature/useFeatureQuickSearch/constant"

const prepareExtrudeConfig = (extrudeConfig) => {
  if (!extrudeConfig) return
  return _.reduce(
    extrudeConfig,
    (preparedExtrudeConfig, configArr, featureType) => {
      preparedExtrudeConfig[featureType] = _.reduce(
        configArr,
        (featureTypeConfig, configItem) => {
          const category = _.get(configItem, "category")
          const height = _.get(configItem, "height")

          if (category && height) _.set(featureTypeConfig, category, { height })

          return featureTypeConfig
        },
        {}
      )
      return preparedExtrudeConfig
    },
    {}
  )
}

export const prepareMapConfig = (mapConfig) => {
  if (!mapConfig) return
  const DEFAULT_MAP_CONFIG = {
    shortest_path: {
      resolution: 0.0015,
    },
  }
  const extrudeConfig = prepareExtrudeConfig(mapConfig.extrude)
  return _.merge({}, DEFAULT_MAP_CONFIG, {
    ...mapConfig,
    extrude: extrudeConfig,
  })
}

export const prepareStepAutoplayConfig = (appConfig, deviceType) => {
  if (!appConfig) return
  const DEFAULT_AUTOPLAY_CONFIG = { delay: 3000, enable: false }
  const stepConfig = _.get(appConfig, `${deviceType}.autoplay.step`, {})
  return _.merge({}, DEFAULT_AUTOPLAY_CONFIG, stepConfig)
}

export const prepareSponsoredContentConfig = (appConfig, deviceType) => {
  if (!appConfig) return
  const DEFAULT_SPONSORED_CONTENT_CONFIG = { limit: null, disable: false }
  const sponcerConfig = _.get(appConfig, `${deviceType}.sponsor`, {})
  const preparedConfig = _.mapValues(sponcerConfig, (config) =>
    _.merge({}, DEFAULT_SPONSORED_CONTENT_CONFIG, config)
  )
  return preparedConfig
}

export const prepareSearchConfig = (appConfig, deviceType) => {
  if (!appConfig) return
  return _.get(appConfig, `${deviceType}.search`, {})
}

export const prepareExtensionConfig = (appConfig, deviceType) => {
  if (!appConfig) return
  return _.get(appConfig, `${deviceType}.extensions`, {})
}

export const prepareFeatureQuickSearchOrderConfig = (appConfig, deviceType) => {
  if (!appConfig) return
  return _.get(
    appConfig,
    `${deviceType}.featureQuickSearchOrder`,
    DEFAULT_FEATURE_QUICK_SEARCH_ORDER_CONFIG
  )
}
