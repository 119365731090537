import React, { useMemo } from "react"
import { Typography } from "@mui/material"

import { useTranslation } from "providers/i18n"
import { createOpeningHours } from "utils/date"

export const OpeningInfo = ({ hours }) => {
  const { t } = useTranslation()
  const {
    openingStatus,
    nextOpeningStatus,
    dayToOpen,
    isOpening,
    close,
    open,
    isValid,
  } = useMemo(() => createOpeningHours(hours), [hours])

  return !isValid ? null : (
    <Typography variant="body2">
      {t(openingStatus)} ·{" "}
      <Typography variant="inherit" component="span" color="text.secondary">
        {t(nextOpeningStatus)} {dayToOpen} {isOpening ? close : open}
      </Typography>
    </Typography>
  )
}
