import React from "react"
import { useTheme } from "@mui/material"
import { Image } from "components/Image"

export const HeaderLogo = () => {
  const theme = useTheme()
  const { logo } = theme.assets || {}
  const styleOverrides = theme.components[`AppHeader`]?.styleOverrides || {}
  const { logoImage } = styleOverrides

  return logo && <Image src={logo.url} alt="Logo Header" sx={logoImage} />
}
