import React, { useCallback, useState } from "react"
import {
  MenuItem,
  Select,
  FormControl,
  Typography,
  Stack,
  useTheme,
} from "@mui/material"
import { useTranslation } from "providers/i18n"
import { getFeatureDetail } from "utils/feature"

export const OrdinalSelectMenu = (props) => {
  const {
    levels = [],
    onChangeOrdinal = () => {},
    selectedOrdinal,
    ...restProps
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const handleChange = useCallback(
    (event) => {
      onChangeOrdinal(event.target.value)
    },
    [onChangeOrdinal]
  )
  const handleOpen = useCallback(
    (e) => {
      setIsOpen(!isOpen)
    },
    [setIsOpen, isOpen]
  )
  const { t } = useTranslation()
  const theme = useTheme()
  const { styleOverrides = {} } = theme.components[`OrdinalSelectMenu`] || {}
  const { root, text, paper, menuList, menuItem } = styleOverrides
  if (levels.length === 0) return null

  return (
    <Stack spacing={0.25}>
      <Typography
        variant="subtitle2"
        component="p"
        sx={{ ...text, display: isOpen ? "none" : "block" }}
      >
        {t("Select Floor")}
      </Typography>
      <FormControl>
        <Select
          value={selectedOrdinal}
          onChange={handleChange}
          onClick={handleOpen}
          onClose={handleOpen}
          open={isOpen}
          sx={root}
          MenuProps={{
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            PaperProps: {
              sx: paper,
            },
            MenuListProps: {
              sx: menuList,
            },
          }}
          {...restProps}
        >
          {levels.map((level, index) => {
            const { id, ordinal, name } = getFeatureDetail(level)
            return (
              <MenuItem
                key={`ordinal-selector-${id}`}
                selected={ordinal === selectedOrdinal}
                value={ordinal}
                sx={{
                  ...menuItem,
                  animationDelay: `${index * 6}ms`,
                }}
              >
                {t(name)}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Stack>
  )
}
