import { alpha } from "@mui/material"

const transparentShadow = {
  a1: alpha("#000000", 0.14),
  a2: alpha("#000000", 0.2),
  a3: alpha("#000000", 0.3),
  a4: alpha("#000000", 0.4),
  a5: alpha("#000000", 0.5),
  a6: alpha("#000000", 0.6),
}

export const defaultThemeOptions = {
  typography: {
    fontFamily: ["Manrope", "Noto Sans Thai", "sans-serif"].join(","),
    heading: {
      fontSize: "1.25rem",
      lineHeight: 1.1,
      fontWeight: 700,
    },
    title: {
      fontSize: "1rem",
      lineHeight: 1.37,
      fontWeight: 700,
    },
    subTitle: {
      fontSize: "0.875rem",
      lineHeight: 1.1,
      fontWeight: 400,
    },
    button: {
      textTransform: "capitalize",
    },
    fontWeightSemiBold: 600,
  },
  shadows: [
    "none",
    `0px 1px 5px ${transparentShadow["a1"]}`,
    `0px 3px 11px ${transparentShadow["a1"]}`,
    `0px 3px 12px ${transparentShadow["a1"]}`,
    `0px 2px 13px ${transparentShadow["a1"]}`,
    `0px 3px 14px ${transparentShadow["a1"]}`,
    `0px 3px 15px ${transparentShadow["a1"]}`,
    `0px 4px 16px ${transparentShadow["a1"]}`,
    `0px 5px 17px ${transparentShadow["a1"]}`,
    `0px 5px 18px ${transparentShadow["a2"]}`,
    `0px 6px 19px ${transparentShadow["a2"]}`,
    `0px 6px 20px ${transparentShadow["a2"]}`,
    `0px 7px 21px ${transparentShadow["a2"]}`,
    `0px 7px 22px ${transparentShadow["a2"]}`,
    `0px 7px 23px ${transparentShadow["a2"]}`,
    `0px 8px 24px ${transparentShadow["a2"]}`,
    `0px 8px 25px ${transparentShadow["a2"]}`,
    `0px 8px 26px ${transparentShadow["a4"]}`,
    `0px 9px 27px ${transparentShadow["a4"]}`,
    `0px 9px 28px ${transparentShadow["a4"]}`,
    `0px 10px 29px ${transparentShadow["a4"]}`,
    `0px 10px 30px ${transparentShadow["a4"]}`,
    `0px 10px 31px ${transparentShadow["a4"]}`,
    `0px 11px 32px ${transparentShadow["a4"]}`,
    `0px 11px 33px ${transparentShadow["a4"]}`,
  ],
  shape: {
    borderRadius: 15,
  },
}
